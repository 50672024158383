import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { autoinject, TaskQueue } from 'aurelia-framework';
import {
  fetchingTankUsage,
  fetchingTankHistory,
  updateTankRefillPoint
} from '../actions';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { TankHistoryFilter, IDateRange } from '../interfaces';
import { getAsyncEntity, getLocalizedDateRange } from '../utility';

@autoinject()
export class TankService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(TankService.name), taskQueue);
  }

  getTankUsage = (tankId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getTankUsage(tankId),
      fetchingTankUsage,
      tankId,
      'An error occurred while trying to fetch the tankusage',
      state => getAsyncEntity(state.tankUsages.byId, tankId)
    );

  getTankHistory = (
    siteId: number,
    tankId: number,
    range: IDateRange,
    filter: TankHistoryFilter,
    fromTankGraph: boolean,
    timezone?: string
  ) => {
    const localizedDateRange = getLocalizedDateRange(range, timezone);

    return this.httpRequestWithDispatch(
      requests.getTankHistory(
        siteId,
        tankId,
        localizedDateRange.queryFrom,
        localizedDateRange.queryTo
      ),
      fetchingTankHistory,
      { tankId, localizedDateRange, filter, fromTankGraph },
      'Could not fetch tank history. Please try again in a while'
    );
  };

  getTankHistoryFromDateRange = (
    siteId: number,
    tankId: number,
    range: IDateRange,
    filter: TankHistoryFilter,
    fromTankGraph: boolean,
    timezone?: string
  ) => {
    const localizedDateRange = getLocalizedDateRange(range, timezone);

    return this.httpRequestWithDispatch(
      requests.getTankHistory(
        siteId,
        tankId,
        localizedDateRange.queryFrom,
        localizedDateRange.queryTo
      ),
      fetchingTankHistory,
      { tankId, localizedDateRange, filter, fromTankGraph },
      'Could not fetch tank history. Please try again in a while'
    );
  };

  orderTankRefill = async (
    siteId: number,
    tankId: number,
    contactIds: number[],
    language: string
  ) =>
    this.httpRequest(
      requests.orderSiteTankRefill(siteId, tankId, contactIds, language)
    );

  updateTankRefillPoint = (
    siteId: number,
    tankChannelId: number,
    timestamp: string,
    value: number,
    siteChannelId: number
  ) =>
    this.httpRequestWithDispatch(
      requests.updateTankRefillPoint(siteId, tankChannelId, timestamp, value),
      updateTankRefillPoint,
      { siteId, tankChannelId, siteChannelId },
      "Could not update the tank's last refill point. Please refresh the page."
    );

  getShipTos = (soldTo: string) =>
    this.httpRequest<string[]>(requests.getShipTosMatchingSoldTo(soldTo));
}
