import { customElement, bindable } from 'aurelia-framework';
import { orderByPredicate } from '../../../utility/sorting';
import { getNames } from '../../../utility/application/countryHelpers';

@customElement('country-dropdown')
export class CountryDropdown {
  constructor() {
    this.data = this.getData();
  }

  @bindable() alignTop = false;
  @bindable() valuePath: string;
  @bindable() selected: string;
  @bindable() itemClicked: undefined | ((params: { value: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;

  _itemClicked(value: string, text: string) {
    this.itemClicked && this.itemClicked({ value: value || text });
  }

  getData() {
    return orderByPredicate(getNames(), d => d, 'asc');
  }

  data: string[];
}
