import actionCreatorFactory from 'typescript-fsa';
import { IDeliveryTankErrorItem } from '../interfaces/index';

const actionCreator = actionCreatorFactory('planning');

export const createExportAction = actionCreator.async<
  {},
  boolean,
  IDeliveryTankErrorItem[]
>('createExportAction');

export const quantityChanged = actionCreator<{
  tankId: number;
  quantity: number;
}>('quantityChanged');

export const toggleTank = actionCreator<{ tankId: number; selected: boolean }>(
  'toggleTank'
);

export const routePlanExported = actionCreator('routePlanExported');
