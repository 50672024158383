import { customElement, bindable } from 'aurelia-framework';

@customElement('danger-button')
export class DangerButton {
  @bindable disabled: boolean;
  @bindable text: string;
  @bindable processing: boolean;

  @bindable icon: string;

  @bindable iconPlacement: 'right' | 'left' | undefined;

  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;

  @bindable size: string = 'medium';

  @bindable onClick: undefined | (() => Promise<any> | void);
}
