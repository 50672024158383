import { BaseViewModel } from './BaseViewModel';
import { getLogger } from 'aurelia-logging';
import { SubscribeQueryResult, subscribeFn, subscribeQuery, runSingleQuery, revalidateAllActiveQueries, preloadSingleQuery, getCacheKey } from './GraphQLFetcher';
import { observable } from 'aurelia-binding';
import { LocalSettings } from '../../services/localSettingsService';
import stringify from 'json-stable-stringify';
import { DocumentNode } from 'graphql';

export type WithoutPagination<T> = Omit<T, 'offset' | 'first'>;

type ScrollPosition = { offset: number, firstVisibleItem: number }
const previousScrollPositions = new Map<string, ScrollPosition>();


export interface IListEntriesChanged {
    data: unknown[]
    from: number
    to: number
}
export abstract class GraphQLInfiniteListViewModel<UData, UVariables, UListEntry> extends BaseViewModel<void>{
    constructor(
            protected sitename: string,
            private getTotalCount: (data: UData) => number, 
            private getListItems: (data: UData) => UListEntry[],
            protected shouldRestoreScrollPosition = false,
            private persistVariables = false
) {
        super(getLogger(sitename));
        this.unsubscribeOnDetach(() => this.unsubscribeFromDataSubscriptions())

        if(!shouldRestoreScrollPosition) return;
        const previousScrollPosition = this.getPreviousScrollPosition()
        if(!previousScrollPosition) return;

        this.offset = previousScrollPosition.offset
        this.firstVisibleItem = previousScrollPosition.firstVisibleItem
    }


    getPreviousScrollPosition(): ScrollPosition | undefined {
        return previousScrollPositions.get(this.sitename);
    }

    setPreviousScrollPosition(scrollPosition: ScrollPosition) {
        previousScrollPositions.set(this.sitename, scrollPosition);
    }

    bind() {
        if(this.persistVariables && !this.variables) {
            this.variables = LocalSettings.getSettingParsed<WithoutPagination<UVariables>>(`${this.sitename}-variables`, this.defaultvariables)
        }
    }

    listEntries: UListEntry[] = [];
    totalEntriesCount: number | undefined;
    
    @observable({ changeHandler: 'dataOrVariablesChanged' })
    query: string | DocumentNode;
    @observable({ changeHandler: 'dataOrVariablesChanged' })
    variables: WithoutPagination<UVariables>;
    defaultvariables: WithoutPagination<UVariables>;

    variablesHasChanged(variables: WithoutPagination<UVariables>, defaultVariables: WithoutPagination<UVariables>) {
        if(!variables || !defaultVariables) return variables === defaultVariables;
        return stringify(variables) !== stringify(defaultVariables);
    }

    loading = true;
    isRevalidating = true;
    revalidations = new Set<string>()
    previousData = new Map<string, UData>()

    public pageSize = 50;
    private dataFetchSubscriptions = new Map<string, SubscribeQueryResult>();
    private listEntriesForVariables = new Map<string, UListEntry[]>();

    revalidateAllActiveQueries = revalidateAllActiveQueries;

    offset: number = 0;
    firstVisibleItem: number = 0;

    dataOrVariablesChanged(){
        if(!this.variables || !this.query) return;
        
        if(this.persistVariables) {
            LocalSettings.setSetting(`${this.sitename}-variables`, JSON.stringify(this.variables));
        }

        const existingEntries = this.listEntriesForVariables.get(getCacheKey(this.query, this.variables))
        
        if(!existingEntries) {
            this.listEntries = [];
            this.loading = true;
        } else {
            this.listEntries = existingEntries;
            this.loading = false;
        }
     
        this.fetchMoreFunction(this.pageSize, this.offset, this.firstVisibleItem);
    }
    
    unsubscribeFromDataSubscriptions (exceptCacheKeys?: Set<string>) {
        this.dataFetchSubscriptions.forEach((subscription, key) => {
            if(!!exceptCacheKeys && exceptCacheKeys.has(key)) return;
            subscription.unsubscribe();
            this.dataFetchSubscriptions.delete(key);
            this.revalidations.delete(key);
        });
    }

    runQuery<T, TV>(query: string, variables: TV) {
        const { promise, abortController } = runSingleQuery<T, TV>(query, variables);
        return { promise, abortController };
    }

    revalidatePageActiveQueries() {
        this.dataFetchSubscriptions.forEach(subscription => subscription.revalidate());
    }

    preloadQuery<T, TV>(query: string, variables: TV) {
        const { promise } = preloadSingleQuery<T, TV>(query, { ...variables, first: this.pageSize, offset: 0 }, { revalidateOnEvents: false, staleTime: this.dataStaleTime });
        return promise;
    }

    private dataStaleTime = 10 * 1000;

    public listEntriesChanged : IListEntriesChanged | undefined;
    fetchMoreFunction = (first: number, offset: number, firstVisibleItem: number) => {
        if(!this.query) return;
        if(!first && !offset) return;
        const flooredOffset = Math.floor(offset / this.pageSize) * this.pageSize;
        if(this.shouldRestoreScrollPosition)
            this.setPreviousScrollPosition({ firstVisibleItem, offset })
        
        // this.offset = offset;
        // this.firstVisibleItem = firstVisibleItem;
        this.offset = 0;             // disables scroll pos. memory in Sitelist 2.0 to fix the problem with blank rows after filter changes. Related to #5364. Yes we will fix it so that it remembers scroll pos again.
        this.firstVisibleItem = 0;
        const variables = this.variables;

        const cacheKeys = new Set<string>();
        for(let activeOffset = flooredOffset; activeOffset < offset + first; activeOffset = activeOffset + this.pageSize){
            const variablesForOffset = { ...variables, offset: activeOffset, first: this.pageSize }
            const cacheKey = getCacheKey(this.query, variablesForOffset);

            cacheKeys.add(cacheKey);
            const query = this.query;
            if(this.dataFetchSubscriptions.has(cacheKey)) continue;
            
            let previousData: UData | undefined;

            const subscribefn: subscribeFn<UData> = ({ data, isRevalidating }) => {

                if(isRevalidating) {
                    this.revalidations.add(cacheKey)
                }
                else {
                    this.revalidations.delete(cacheKey);
                }

                this.isRevalidating = !!this.revalidations.size;

                if(!isRevalidating) this.loading = false;

                if(data === previousData && data) {
                    return;
                }
                if(!data) return;
                previousData = data;
                const totalCount = this.getTotalCount(data);
                const listItems = this.getListItems(data);
                const cacheKeyWithoutPaging = getCacheKey(query, variables)
                const dataEntries = this.listEntriesForVariables.get(cacheKeyWithoutPaging) ?? new Array(totalCount).fill(null);
                this.listEntriesForVariables.set(cacheKeyWithoutPaging, dataEntries);

                // Here we want to set the allDataEntries to an array that contains [null, null, null, value, value, value, null, null]
                dataEntries.splice(activeOffset, listItems.length, ...listItems);
                this.listEntries = dataEntries;
                this.totalEntriesCount = totalCount;
                this.listEntriesChanged = { data: dataEntries, from: flooredOffset, to: flooredOffset + first };
            }
            const subscription = subscribeQuery(query, variablesForOffset, { staleTime: this.dataStaleTime }, subscribefn)
            
            this.dataFetchSubscriptions.set(cacheKey, subscription)
        }

        this.unsubscribeFromDataSubscriptions(cacheKeys)
    };
}