import { BlobTypes } from '../interfaces';
import { getEnvironment } from '../utility/environmentHelpers';

export const baseConfig = {
  api: '/api',
  auth: '/sts',
  graphql: '/graphql'
}

export const GOOGLE_MAPS_API_KEY = 'AIzaSyD5d7KDmuxUcVlk-SBq9_zfGFT0JKyJnV0';

export const GoolgeMapsUrl = () => {
  const environment = getEnvironment();
  switch (environment) {
    case 'production':
      return 'https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyD5d7KDmuxUcVlk-SBq9_zfGFT0JKyJnV0';
    default:
      return 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD5d7KDmuxUcVlk-SBq9_zfGFT0JKyJnV0';
  }
}

const commonConfig = {
  controllersForSite: (siteId: number) =>
    `${baseConfig.api}/controllers/?siteid=${siteId}`,

  exportJobs: `${baseConfig.api}/exportjobs/`,
  exportJob: (exportJobId: number) =>
    `${baseConfig.api}/exportjobs/${exportJobId}`,
  exportFields: `${baseConfig.api}/exportjobs/fields/`,

  note: (noteId: number) => `${baseConfig.api}/notes/${noteId}`,
  notes: `${baseConfig.api}/notes/`,
  notesForSite: (siteId: number) => `${baseConfig.api}/notes/${siteId}`,
  siteData: `${baseConfig.api}/historian/site`,
  siteDetails: (siteId: number) => `${baseConfig.api}/siteDetails/${siteId}`,
  siteGridData: `${baseConfig.api}/grid`,

  translation: `${baseConfig.api}/translations/i18/{{lng}}`,
  customer: (method: string) => `${baseConfig.api}/customer/${method}`,
  userSiteStats: `${baseConfig.api}/user/sitestats`,
  user: (method: string) => `${baseConfig.api}/user/${method}`,
  userAccessFilter: (method: string) =>
    `${baseConfig.api}/useraccessfilter/${method}`,
  userBase: `${baseConfig.api}/user`,
  controllerParameters: (controllerId: number) =>
    `${baseConfig.api}/controllerparameters/${controllerId}`,
  controllerProfiles: (controllerId: number, profileId: number) =>
    `${baseConfig.api}/controllerprofiles/${controllerId}/${profileId}`,
  controllerLogs: (controllerId: number) =>
    `${baseConfig.api}/controllerlog/${controllerId}`,
  controllerLogsFromDate: (controllerId: number, fromDate: Date) =>
    `${baseConfig.api}/controllerlog/${controllerId}/${fromDate.toJSON()}`,
  uploadBlob: (blobType: BlobTypes, formData: FormData) =>
    new Request(`${baseConfig.api}/blob/${blobType}`, {
      method: 'POST',
      body: formData
    }),
  uploadReleaseNote: (formData: FormData) =>
    new Request(`${baseConfig.api}/graphql/releasenote`, {
      method: 'POST',
      body: formData
    }),
  uploadControllerConfig: (controllerId: Number, file: ArrayBuffer) =>
    new Request(`${baseConfig.api}/controllers/uploadconfig/${controllerId}`, {
      method: 'PUT',
      body: file,
      headers: { 'content-type': 'binary/octet-stream' }
    }),
};

const authenticationConfig = {
  forgotPassword: (email: string) =>
    `/api/${baseConfig.auth}/password/request/${email}`,
  resetPassword: `/api/${baseConfig.auth}/password/reset/`
};

export default { ...commonConfig, ...baseConfig, ...authenticationConfig };
