import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  fetchingUserPosition,
  updateMarkerLocationFromCurrentPosition,
  persistMapMarkerChanges,
  updateSiteAddressForMap,
  updateMapMarker,
  setSiteMapMarkerModeForSite,
  updateMarkerLocationFromControllerPosition
} from '../actions';
import {
  ILatLng,
  ISiteDetailsMapLocation,
  ISiteDetailsMapAddressAndLocation,
  ISiteDetailsMapAddress,
  ISite,
  IControllerMapMarker,
  IControllerMapMarkerWithAutoUpdate,
  ISiteDetailsMapAddressResult
} from '../interfaces';
import { isNone, ensureNumber, isEmpty, emptyObject } from '../utility';
import { BaseService } from './baseService';
import { store } from '../reducers/store';
import { setMarkerMode } from '../reducers/ui/sitedetailsMapReducer';

@autoinject()
export class MapService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(MapService.name), taskQueue);
  }

  public fetchUserPosition = () =>
    !navigator.geolocation
      ? Promise.reject({ message: 'Not supported browser' })
      : new Promise<ILatLng>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) =>
              resolve({
                lat: parseFloat(coords.latitude.toFixed(5)),
                lng: parseFloat(coords.longitude.toFixed(5)),
                accuracy: 0
              }),
            reject
          );
        });

  public async fetchAndPersistUserPosition() {
    const params = true;
    store.dispatch(fetchingUserPosition.started(true));

    try {
      const result = await this.fetchUserPosition();
      store.dispatch(fetchingUserPosition.done({ params, result }));
    } catch (err) {
      store.dispatch(
        fetchingUserPosition.failed({ params, error: err.message })
      );
    }
  }

  public updateSiteAddressFromCurrentPosition = async (
    userPosition?: ILatLng
  ) => {
    store.dispatch(updateSiteAddressForMap.started(emptyObject));
    try {
      const location = isNone(userPosition)
        ? await this.fetchUserPosition()
        : userPosition;
      const address = await this.getAddressFromPosition(location);
      store.dispatch(
        updateSiteAddressForMap.done({ params: emptyObject, result: address })
      );
    } catch (err) {
      store.dispatch(updateSiteAddressForMap.failed(err));
    }
  };

  public updateSiteAddressFromPosition = async (location: ILatLng) => {
    store.dispatch(updateSiteAddressForMap.started(emptyObject));
    try {
      const address = await this.getAddressFromPosition(location);
      store.dispatch(
        updateSiteAddressForMap.done({ params: emptyObject, result: address })
      );
    } catch (err) {
      store.dispatch(updateSiteAddressForMap.failed(err));
    }
  };

  public updatePendingSiteAddressFromPosition = async (
    markerMode: setMarkerMode,
    location: ILatLng
  ) => {
    store.dispatch(setSiteMapMarkerModeForSite.started(markerMode));
    try {
      const address = await this.getAddressFromPosition(location);
      store.dispatch(
        setSiteMapMarkerModeForSite.done({
          params: markerMode,
          result: address
        })
      );
    } catch (err) {
      store.dispatch(setSiteMapMarkerModeForSite.failed(err));
    }
  };

  public queryControllerLocation = (markerToUpdate: IControllerMapMarker) => {
    const controllerId = markerToUpdate.controllerId;
    return this.httpRequestWithDispatch(
      requests.fetchControllerPosition(controllerId),
      updateMarkerLocationFromControllerPosition,
      markerToUpdate,
      'Unexpected error occurred while fetching controller position'
    );
  };

  public updateMarkerLocationFromCurrentPosition = async (
    markerToUpdate: IControllerMapMarker,
    userPosition?: ILatLng
  ) => {
    const params = markerToUpdate;
    if (!isNone(userPosition)) {
      store.dispatch(
        updateMarkerLocationFromCurrentPosition.done({
          params,
          result: userPosition
        })
      );
      return;
    }

    store.dispatch(updateMarkerLocationFromCurrentPosition.started(params));
    try {
      const result = await this.fetchUserPosition();
      store.dispatch(
        updateMarkerLocationFromCurrentPosition.done({ params, result })
      );
      return;
    } catch (err) {
      store.dispatch(updateMarkerLocationFromCurrentPosition.failed(err));
      return;
    }
  };

  public getAddressesFromSiteId(siteId: number) {
    return this.httpRequest<ISiteDetailsMapAddressResult>(
      requests.fetchAdressesFromSiteId(siteId)
    );
  }

  public async updateSiteAddressAndControllerLocations(
    site: ISite,
    markers: IControllerMapMarkerWithAutoUpdate[]
  ) {
    const payload = await this.createUpdateSiteAddressPayload(site, markers);

    return this.httpRequestWithDispatch(
      requests.updateSiteAddressAndMarkers(payload),
      persistMapMarkerChanges,
      payload,
      'Unexpected error occurred while persisting'
    );
  }

  public async updateMarkerLocationFromSiteAddress(
    params: IControllerMapMarker,
    site: ISite
  ) {
    store.dispatch(updateMapMarker.started(params));
    try {
      const result = await this.getLocationFromAddress(
        this.createSiteAddressPayload(site)
      );
      store.dispatch(updateMapMarker.done({ params, result }));
    } catch (err) {
      store.dispatch(updateMapMarker.failed(err));
    }
  }

  private geoPositionFromMarker = ({
    lat,
    lng,
    accuracy,
    controllerId,
    autoUpdateLocation
  }: IControllerMapMarkerWithAutoUpdate): ISiteDetailsMapLocation => ({
    lat: ensureNumber(lat),
    lng: ensureNumber(lng),
    accuracy: ensureNumber(accuracy),
    controllerId,
    autoUpdateLocation
  });

  private getFormattedAddress = (address: ISiteDetailsMapAddress) =>
    `${address.addressLine1} ${address.addressLine2}, ${address.postcode} ${address.city}, ${address.country}`;

  private getLocationFromAddress = async (address: ISiteDetailsMapAddress) =>
    new Promise<ILatLng>((resolve, reject) => {
      const g = new google.maps.Geocoder();
      g.geocode(
        { address: this.getFormattedAddress(address) },
        (result, status) => {
          if (status !== google.maps.GeocoderStatus.OK || isEmpty(result || undefined))
            return reject(result);
          const location = result?.[0].geometry.location;
          if(!location) return
          resolve({
            lat: parseFloat(location.lat().toFixed(5)),
            lng: parseFloat(location.lng().toFixed(5)),
            accuracy: 0
          });
        }
      );
    });

  private getAddressFromPosition = async (location: ILatLng) =>
    new Promise<ISiteDetailsMapAddress>((resolve, reject) => {
      const g = new google.maps.Geocoder();
      const getGeocoderAddressComponentByType = (
        components: google.maps.GeocoderAddressComponent[],
        type: string,
        defaultValue: string = ''
      ) =>
        (
          components.find(c => c.types.some(t => t === type)) || {
            long_name: defaultValue
          }
        ).long_name;

      g.geocode({ location }, (result, status) => {
        if (status !== google.maps.GeocoderStatus.OK || isEmpty(result || undefined))
          return reject(result);
        const components = result?.[0].address_components;
        if(!components) return
        resolve({
          postcode: getGeocoderAddressComponentByType(
            components,
            'postal_code'
          ),
          country: getGeocoderAddressComponentByType(components, 'country'),
          city:
            getGeocoderAddressComponentByType(components, 'postal_town') ||
            getGeocoderAddressComponentByType(components, 'political'),
          addressLine1: (
            getGeocoderAddressComponentByType(components, 'route') +
            ' ' +
            getGeocoderAddressComponentByType(components, 'street_number')
          ).trim(),
          addressLine2: '',
          lookupKey: ''
        });
      });
    });

  private createSiteAddressPayload(site: ISite): ISiteDetailsMapAddress {
    const { addressLine1, addressLine2, postcode, city, country, soldTo } = site;
    return { addressLine1, addressLine2, postcode, city, country, lookupKey: 'Sold-to: ' + soldTo };
  }

  private async createUpdateSiteAddressPayload(
    site: ISite,
    markers: IControllerMapMarkerWithAutoUpdate[]
  ): Promise<ISiteDetailsMapAddressAndLocation> {
    const {
      siteId,
      addressLine1,
      addressLine2,
      postcode,
      country,
      city
    } = site;
    const siteHasAddress =
      !isEmpty(addressLine1) &&
      !isEmpty(postcode) &&
      !isEmpty(country) &&
      !isEmpty(city);
    const shouldSetLocation = (m: IControllerMapMarkerWithAutoUpdate) =>
      !m.lat &&
      !m.lng &&
      (!m.autoUpdateLocation || m.controllerType.toLowerCase() !== 'mt device');
    if (markers.some(shouldSetLocation) && siteHasAddress) {
      try {
        const { lat, lng } = await this.getLocationFromAddress({
          addressLine1,
          addressLine2,
          city,
          country,
          postcode,
          lookupKey: ''
        });
        markers = markers.map(marker =>
          !shouldSetLocation(marker)
            ? marker
            : { ...marker, lat, lng, accuracy: 0 }
        );
      } catch (err) {
        this.logger.error(
          'Could not fetch location from site address when saving',
          err
        );
      }
    }
    return {
      siteId,
      address: this.createSiteAddressPayload(site),
      geoPositions: markers.map(this.geoPositionFromMarker)
    };
  }
}
