import {
  getSiteChannelsForSite,
  getAllSiteChannels,
  getAllSiteChannelsOfTanks,
  updateSiteChannel,
  deleteSiteChannel,
  createSiteChannel,
  getAllChannelsForController,
  deleteChannel,
  updateChannel,
  fetchChannel,
  getSiteChannel,
  updateSiteChannels,
  createChannel,
  createSiteChannelAndChannel,
  deleteSite,
  persistMultipleSites,
  deleteMultipleSites,
  updateTankRefillPoint,
  getChannelByToken,
  getChannelsThatTrackTank,
  multiEditSiteChannels
} from '../../actions';
import {
  ISiteChannel,
  IAsyncReducerState,
  IChannel,
  IChannelsThatTrackTankChannel
} from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  insertAllFetchedEntities,
  insertFetchedEntity,
  createAsyncDictionaryForSiteRelations,
  setDoneEntityInDictionary,
  toObjectAndMap,
  asyncEntityIsFetched,
  getAsyncEntity,
  insertInflightEntity,
  mapFetchedAsyncEntity,
  channelIsTankChannel,
  emptyObject,
  isNone,
  distinct,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  isSomething,
  removeAsyncEntityInDictionary,
  removeAsyncObjects,
  removeAsyncObject,
  flatMap,
  removeAsyncEntitiesInDictionary,
  getAsyncEntities
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncEntity,
  createFetchedEntity,
  IAsyncDictionary,
  createPendingEntity,
  createFailedFetchingEntity
} from '../../types';

export interface IChannelReducerState extends IAsyncReducerState<ISiteChannel> {
  allTankIds: IAsyncEntity<number[]>;
  tanksForSites: IAsyncDictionary<number[]>;
  siteChannelsForSites: IAsyncDictionary<number[]>;
  controllerChannelsById: IAsyncDictionary<IChannel>;
  controllerChannelsForControllers: IAsyncDictionary<number[]>;
  channelsThatTrackChannels: IAsyncDictionary<IChannelsThatTrackTankChannel[]>;
}

const defaultState: IChannelReducerState = {
  allIds: undefined,
  allTankIds: undefined,
  byId: {},
  tanksForSites: {},
  siteChannelsForSites: {},
  controllerChannelsById: {},
  controllerChannelsForControllers: {},
  channelsThatTrackChannels: {}
};

const filterValuesInIAsyncDictionary = <T>(
  dictionary: IAsyncDictionary<T>,
  func: (t: T) => T
) =>
  toObjectAndMap(
    Object.keys(dictionary),
    key => key,
    key => mapFetchedAsyncEntity(getAsyncEntity(dictionary, key), func)
  );

const removeIdsFromHasManyRelations = (
  ids: number[],
  relations: IAsyncDictionary<number[]>
) =>
  filterValuesInIAsyncDictionary(relations, (relationIds: number[]) =>
    relationIds.filter(relationId => !ids.some(id => id === relationId))
  );

const reducer = reducerWithInitialState(defaultState)
  .case(deleteSite.done, (state, payload) => {
    const siteChannelsForSites = getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(state.siteChannelsForSites, payload.params.siteId),
        state.byId
      ),
      undefined
    );

    return isSomething(siteChannelsForSites)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            siteChannelsForSites.map(siteChannel => siteChannel.siteChannelId)
          ),
          {
            tanksForSites: mergeObjects(
              state.tanksForSites,
              removeAsyncEntityInDictionary(
                state.tanksForSites,
                payload.params.siteId
              )
            ),
            siteChannelsForSites: mergeObjects(
              state.siteChannelsForSites,
              removeAsyncEntityInDictionary(
                state.siteChannelsForSites,
                payload.params.siteId
              )
            )
          }
        )
      : state;
  })

  .case(deleteMultipleSites.done, (state, payload) => {
    if (payload.params.deleteControllers) return state;

    const controllerIds = flatMap(
      getEntityOrDefault(
        getAsyncEntities(payload.params.siteIds, state.siteChannelsForSites),
        []
      ),
      x => x
    );
    const controllersForSite = getEntityOrDefault(
      getAsyncEntities(controllerIds, state.byId),
      undefined
    );

    return isSomething(controllersForSite)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            controllersForSite.map(siteChannel => siteChannel.siteChannelId)
          ),
          {
            tanksForSites: mergeObjects(
              state.tanksForSites,
              removeAsyncEntitiesInDictionary(
                state.tanksForSites,
                payload.params.siteIds
              )
            ),
            siteChannelsForSites: mergeObjects(
              state.siteChannelsForSites,
              removeAsyncEntitiesInDictionary(
                state.siteChannelsForSites,
                payload.params.siteIds
              )
            )
          }
        )
      : state;
  })

  .case(persistMultipleSites.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(
        state,
        result.channels,
        channel => channel.siteChannelId
      )
    )
  )

  .case(getSiteChannelsForSite.done, (state, { result, params }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, channel => channel.siteChannelId),
      {
        siteChannelsForSites: mergeObjects(state.siteChannelsForSites, {
          [params]: createFetchedEntity(result.map(r => r.siteChannelId))
        }),
        tanksForSites: mergeObjects(state.tanksForSites, {
          [params]: createFetchedEntity(
            result.filter(channelIsTankChannel).map(r => r.siteChannelId)
          )
        })
      }
    )
  )

  .case(getChannelByToken.started, (state, channelId) => ({
    ...state,
    controllerChannelsById: {
      ...state.controllerChannelsById,
      [channelId]: createPendingEntity()
    }
  }))

  .case(getChannelByToken.done, (state, { result, params }) => ({
    ...state,
    controllerChannelsById: {
      ...state.controllerChannelsById,
      [params]: createFetchedEntity(result)
    }
  }))

  .case(getChannelByToken.failed, (state, { error, params }) => ({
    ...state,
    controllerChannelsById: {
      ...state.controllerChannelsById,
      [params]: createFailedFetchingEntity(error)
    }
  }))

  .case(getChannelsThatTrackTank.started, (state, channelId) => ({
    ...state,
    channelsThatTrackChannels: {
      ...state.channelsThatTrackChannels,
      [channelId]: createPendingEntity()
    }
  }))

  .case(getChannelsThatTrackTank.done, (state, { result, params }) => ({
    ...state,
    channelsThatTrackChannels: {
      ...state.channelsThatTrackChannels,
      [params]: createFetchedEntity(result)
    }
  }))

  .case(getChannelsThatTrackTank.failed, (state, { error, params }) => ({
    ...state,
    channelsThatTrackChannels: {
      ...state.channelsThatTrackChannels,
      [params]: createFailedFetchingEntity(error)
    }
  }))

  .case(getSiteChannelsForSite.started, (state, siteId) =>
    mergeObjects(state, {
      siteChannelsForSites: mergeObjects(state.siteChannelsForSites, {
        [siteId]: createPendingEntity()
      }),
      tanksForSites: asyncEntityIsFetched(
        getAsyncEntity(state.tanksForSites, siteId)
      )
        ? state.tanksForSites
        : mergeObjects(state.tanksForSites, { [siteId]: createPendingEntity() })
    })
  )

  .case(getAllChannelsForController.done, (state, { result, params }) =>
    mergeObjects(state, {
      controllerChannelsForControllers: mergeObjects(
        state.controllerChannelsForControllers,
        createAsyncDictionaryForSiteRelations(
          result,
          _ => params,
          c => c.channelId
        )
      ),
      controllerChannelsById: mergeObjects(
        state.controllerChannelsById,
        toObjectAndMap(result, c => c.channelId, createFetchedEntity)
      )
    })
  )

  .case(fetchChannel.started, (state, params) =>
    mergeObjects(state, {
      controllerChannelsById: mergeObjects(state.controllerChannelsById, {
        [params]: createPendingEntity()
      })
    })
  )
  .case(fetchChannel.done, (state, { params, result }) =>
    mergeObjects(state, {
      controllerChannelsById: mergeObjects(state.controllerChannelsById, {
        [params]: createFetchedEntity(result)
      })
    })
  )

  .case(deleteChannel.done, (state, { params }) =>
    mergeObjects(state, {
      controllerChannelsForControllers: {
        ...state.controllerChannelsForControllers,
        [params.controllerId]: undefined
      },
      channelsThatTrackChannels: {},
      controllerChannelsById: mergeObjects(
        state.controllerChannelsById,
        {
          [params.channelId]: undefined
        },
        isNone(params.dependentChannelId)
          ? emptyObject
          : {
              [params.dependentChannelId]: undefined
            }
      )
    })
  )

  .case(getAllChannelsForController.started, (state, controllerId) =>
    mergeObjects(state, {
      controllerChannelsForControllers: mergeObjects(
        state.controllerChannelsForControllers,
        { [controllerId]: createPendingEntity() }
      )
    })
  )

  // channel actions

  .case(deleteSiteChannel.done, (state, { params }) =>
    mergeObjects(state, removeAsyncObject(state, params.siteChannelId), {
      tanksForSites: removeIdsFromHasManyRelations(
        [params.siteChannelId],
        state.tanksForSites
      ),
      channelsThatTrackChannels: {},
      siteChannelsForSites: {
        ...state.siteChannelsForSites,
        [params.siteId]: undefined
      }
    })
  )
  .case(updateSiteChannels.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, s => s.siteChannelId),
      { channelsThatTrackChannels: {} }
    )
  )
  .case(updateSiteChannel.done, (state, { params, result }) =>
    mergeObjects(
      state,
      (channelIsTankChannel(params) &&
        params.tankDetails.addNewTrackedChannel) ||
        (!isNone(params.doseDetails) && params.doseDetails.addNewTrackedChannel)
        ? {
            siteChannelsForSites: {
              ...state.siteChannelsForSites,
              [params.siteId]: undefined
            }
          }
        : insertFetchedEntity(state, params.siteChannelId, result),
      { channelsThatTrackChannels: {} }
    )
  )
  .cases(
    [updateChannel.done, createChannel.done],
    (state, { params, result }) =>
      mergeObjects(state, {
        controllerChannelsById: mergeObjects(state.controllerChannelsById, {
          [params.channelId]: createFetchedEntity(result)
        }),
        channelsThatTrackChannels: {},
        controllerChannelsForControllers:
          (params.tankDetails != null &&
            params.tankDetails.addNewTrackedChannel) ||
          (!isNone(params.doseDetails) &&
            params.doseDetails.addNewTrackedChannel)
            ? {
                ...state.controllerChannelsForControllers,
                [params.controllerId]: undefined
              }
            : setDoneEntityInDictionary(
                state.controllerChannelsForControllers,
                result.controllerId,
                ids => distinct([...ids, result.channelId])
              )
      })
  )

  .case(getSiteChannel.started, (state, params) =>
    mergeObjects(state, insertInflightEntity(state, params))
  )
  .case(getSiteChannel.done, (state, { params, result }) =>
    mergeObjects(state, insertFetchedEntity(state, params, result))
  )

  .case(getAllSiteChannelsOfTanks.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, channel => channel.siteChannelId),
      {
        allTankIds: createFetchedEntity(result.map(tank => tank.siteChannelId)),
        tanksForSites: mergeObjects(
          state.tanksForSites,
          createAsyncDictionaryForSiteRelations(
            result,
            t => t.siteId,
            t => t.siteChannelId
          )
        )
      }
    )
  )

  .case(getAllSiteChannels.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, channel => channel.siteChannelId),
      {
        siteChannelsForSites: createAsyncDictionaryForSiteRelations(
          result,
          channel => channel.siteId,
          channel => channel.siteChannelId
        )
      }
    )
  )

  .cases(
    [createSiteChannel.done, createSiteChannelAndChannel.done],
    (state, { result }) =>
      mergeObjects(
        state,
        insertFetchedEntity(state, result.siteChannelId, result),
        {
          siteChannelsForSites: {
            ...state.siteChannelsForSites,
            [result.siteId]: undefined
          },
          tanksForSites: channelIsTankChannel(result)
            ? setDoneEntityInDictionary(
                state.tanksForSites,
                result.siteId,
                ids => [...ids, result.siteChannelId]
              )
            : state.tanksForSites,
          channelsThatTrackChannels: {}
        }
      )
  )

  .case(updateTankRefillPoint.done, (state, results) =>
    mergeObjects(
      state,
      insertFetchedEntity(state, results.result.siteChannelId, results.result)
    )
  )

  .case(multiEditSiteChannels, (state, siteId) => ({
    ...state,
    siteChannelsForSites: {
      ...state.siteChannelsForSites,
      [siteId]: undefined
    },
    channelsThatTrackChannels: {},
    tanksForSites: {
      ...state.tanksForSites,
      [siteId]: undefined
    }
  }));

export default reducer;
