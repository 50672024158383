export const timeOperation = (op: () => void) => {
  const before = performance.now();
  op();
  return performance.now() - before;
};

export const debounceRAF = (fun: Function) => {
  let scheduled = false;
  return (...args: any[]) => {
    if (scheduled) return;
    scheduled = true;
    window.requestAnimationFrame(() => {
      scheduled = false;
      fun(args);
    });
  };
};
