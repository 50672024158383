export enum SiteStatus {
  Normal,
  Alarm,
  Offline,
  Parked,
  OfflineParked,
  StaleParked,
  Stale,
  AlarmParked,
  Virtual
}

export enum SiteSignal {
  Online,
  Stale,
  Offline,
  Virtual
}

