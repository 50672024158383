import './toast.css';
import { computedFrom, customElement } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';
import { INotifications } from '../../interfaces';
import { EventAggregator } from 'aurelia-event-aggregator';
import { notificationNotify } from '../../services/notificationService';
import { Router } from 'aurelia-router';
import { routeNames } from '../../config/routes';


@customElement('toast')
export class ToastCustomElement {
  @bindable() visible: boolean = false;
  @bindable() timeout: number = 10;
  @bindable() notification: INotifications | undefined;

  constructor(private eventAggregator: EventAggregator,
    private router: Router) {}

  attached() {
    //Auto-ack info notifications
    if (this.notification && this.notification.level === 'info' && this.autoAcknowledgeType(this.notification.type))
      window.setTimeout(() => this.dismissToast(), this.timeout * 1000);
  }

  buildVersion() {
    return document.head.querySelector('meta[name="build-version"]')?.getAttribute('content') || 'Unknown';
  }
  
  dismissToast() {
    this.visible = false;
    if (this.notification) this.notification.acknowledged = true;

    this.eventAggregator.publish(notificationNotify, this.notification);
  }

  getIconForNotification = (notification: INotifications) => {
    switch (notification.level) {
      case 'warning':
        return 'fa-exclamation-triangle';
      case 'error':
        return 'fa-exclamation-triangle';
      default:
        return 'fa-info-circle';
    }
  };

  navigateToReleaseNotes(): void {
    this.router.navigateToRoute(routeNames.releasenotes);
    this.dismissToast();
  }

  @computedFrom('notification')
  get showProgress(): boolean {
    if (this.notification === undefined || this.notification.type === 'SYSTEM-UPDATED') {
      return false;
    }
    return this.notification.level === 'info';
  }

  private nonAutoAckTypes = ['SYSTEM-UPDATED'];

  autoAcknowledgeType(notificationType: string): boolean {
    return this.nonAutoAckTypes.findIndex(v => v === notificationType) < 0;
  }
}
