import { customElement, bindable } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import {
  BuidsQuery,
  BuidsQuery_buids
} from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';

@customElement('buid-dropdown')
export class BuidDropdown extends GraphQLBaseViewModel<void, BuidsQuery, void> {
  @bindable() selected: BuidsQuery_buids | undefined;
  @bindable() disabled: boolean | undefined;
  @bindable() itemClicked:
    | undefined
    | ((params: { value: BuidsQuery_buids; text: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() required: boolean;
  @bindable valuePath: string;

  query = gql`
    query BuidsQuery {
      buids {
        buidId
        name
      }
    }
  `;
}
