/* eslint-disable @typescript-eslint/no-explicit-any */

export type CustomEventType =
  | 'validationerrors'
  | 'validationsuccess'
  | 'showvalidationerrors'
  | 'inputremoved';

export const createCustomEvent = (
  eventType: CustomEventType,
  element: Element,
  bubbles: boolean = true
): CustomEvent => {
  if ((window as any).CustomEvent) {
    return new (window as any).CustomEvent(eventType, {
      bubbles,
      detail: { element }
    });
  } else {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventType, bubbles, true, {
      element,
    });
    return event;
  }
};
