import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects } from '../../utility/index';
import {
  getSimState,
  fetchingUserPosition,
  controllerPropertyChanged,
  selectChangeBuid,
  getQrControllerBySerial,
  saveQrController,
  createQrController,
  activateSimCard,
  // operationStatusSimCard,
  navigateToRoute
} from '../../actions';
import { IQrController, IBuid, IRequestState } from '../../interfaces';
import { DateTime } from 'luxon';

export interface IQrAppReducerState {
  controller: {
    original: IQrController;
    current: IQrController;

    request: IRequestState;
    controllerValidationErrors: Record<string, string[]> | undefined;
  };

  selectedBuid?: IBuid;
  lastCheckedActivationTime?: string
  activationRequestCreatedAt?: string

  activation: {
    status?: number;
    hasPendingActivation?: boolean;
    simCardType?: string;
  };

  simState: {
    // activationStatus?: number,
    // operationId?: string,

    enabled: boolean;

    controllerId?: number;
    icc?: string;
    productId?: string;
    productState?: string;
    msisdn?: string;
    ipAddress?: string;

    request: IRequestState;
  };
}

const defaultState: IQrAppReducerState = {
  controller: {
    original: {} as IQrController,
    current: {} as IQrController,

    controllerValidationErrors: undefined,

    request: {
      loaded: false,
      processing: false,
      error: false
    }
  },
  activation: {
    status: 5
  },
  simState: {
    // activationStatus: 5, // Initial
    enabled: false,

    request: {
      loaded: false,
      processing: false,
      error: false
    }
  }
};

const controllerStarted = (state: IQrAppReducerState) =>
  mergeObjects(state, {
    controller: mergeObjects(state.controller, {
      request: mergeObjects(state.controller.request, {
        processing: true
      }),

      controllerValidationErrors: undefined
    })
  });

const controllerDone = (state: IQrAppReducerState, payload: any) =>
  mergeObjects(state, {
    controller: mergeObjects(state.controller, {
      current: payload.result ? payload.result : ({} as IQrController),
      original: payload.result ? payload.result : ({} as IQrController),

      request: mergeObjects(state.controller.request, {
        processing: false,
        loaded: true
      })
    })
  });

const controllerFailed = (state: IQrAppReducerState, payload: any) =>
  mergeObjects(state, {
    controller: mergeObjects(state.controller, {
      request: mergeObjects(state.controller.request, {
        processing: false,
        error: true,
        message: payload.error.message
      }),
      controllerValidationErrors:
        typeof payload.error === 'string'
          ? state.controller.controllerValidationErrors
          : payload.error
    })
  });

const mapErrorCodeToMessage = (errorCode: number) => {
  switch (errorCode) {
    case 1:
      return 'UI_QrApp_SimActivation_SimState_CostCenterNotFound';
    case 2:
      return 'UI_QrApp_SimActivation_SimState_InvalidICC';
    default:
      return 'UI_QrApp_SimActivation_SimState_Failed';
  }
};

const reducer = reducerWithInitialState(defaultState)
  .case(getQrControllerBySerial.started, controllerStarted)
  .case(getQrControllerBySerial.done, controllerDone)
  .case(getQrControllerBySerial.failed, controllerFailed)

  .case(saveQrController.started, controllerStarted)
  .case(saveQrController.done, controllerDone)
  .case(saveQrController.failed, controllerFailed)

  .case(createQrController.started, controllerStarted)
  .case(createQrController.done, controllerDone)
  .case(createQrController.failed, controllerFailed)

  .case(activateSimCard.started, (state) =>
    mergeObjects(state, {
      simState: mergeObjects(state.simState, {
        request: mergeObjects(state.simState.request, {
          processing: true,
          loaded: false,
          error: false,
          message: '',
        }),
      }),
    })
  )

  .case(activateSimCard.done, (state, payload) =>
    mergeObjects(state, {
      lastCheckedActivationTime: DateTime.fromJSDate(new Date()).toFormat(
        'HH:mm:ss'
      ),
      activation: mergeObjects(state.activation, {
        status: payload.result.status,
        hasPendingActivation: !(payload.result.status === 0),
        simCardType: payload.result.simCardType,
      }),
      simState: mergeObjects(state.simState, {
        // activationStatus: payload.result.status,
        // operationId: payload.result.operationId,

        request: mergeObjects(state.simState.request, {
          processing: false,
          loaded: true,
          error: false,
          message: '',
        }),
      }),
    })
  )
  .case(activateSimCard.failed, (state, payload) =>
    mergeObjects(state, {
      lastCheckedActivationTime: DateTime.fromJSDate(new Date()).toFormat('HH:mm:ss'),
      simState: mergeObjects(state.simState, {
        request: mergeObjects(state.simState.request, {
          processing: false,
          loaded: false,
          error: true,
          message:
            payload.error.errorText ??
            mapErrorCodeToMessage(payload.error.status),
        }),
      }),
    })
  )

  // .case(operationStatusSimCard.done, (state, payload) =>
  //     mergeObjects(state,
  //         {
  //             activation: mergeObjects(state.activation, {
  //                 status: payload.result.status,
  //                 hasPendingActivation: payload.result.hasPendingActivation,
  //             })
  //         }))

  .case(getSimState.started, (state) =>
    mergeObjects(state, {
      simState: mergeObjects(state.simState, {
        request: mergeObjects(state.simState.request, {
          processing: true,
          loaded: false,
          error: false,
          message: '',
        }),
      }),
    })
  )

  .case(getSimState.done, (state, payload) =>
    mergeObjects(state, {
      lastCheckedActivationTime: DateTime.fromJSDate(new Date()).toFormat(
        'HH:mm:ss'
      ),
      activationRequestCreatedAt: payload.result.activationRequestCreatedAt
        ? DateTime.fromJSDate(
            new Date(Date.parse(payload.result.activationRequestCreatedAt))
          ).toFormat('HH:mm')
        : undefined,
      activation: mergeObjects(state.activation, {
        hasPendingActivation: payload.result.hasPendingActivation,
        simCardType: payload.result.simCardType,
      }),

      simState: mergeObjects(state.simState, {
        enabled: payload.result.enabled,
        controllerId: payload.result.controllerId,
        icc: payload.result.icc,
        productId: payload.result.productId,
        productState: payload.result.productState,
        msisdn: payload.result.msisdn,
        ipAddress: payload.result.ipAddress,

        request: mergeObjects(state.simState.request, {
          processing: false,
          loaded: true,
        }),
      }),
    })
  )
  .case(getSimState.failed, (state, payload) =>
    mergeObjects(state, {
      lastCheckedActivationTime: DateTime.fromJSDate(new Date()).toFormat(
        'HH:mm:ss'
      ),
      simState: mergeObjects(state.simState, {
        request: mergeObjects(state.simState.request, {
          processing: false,
          loaded: false,
          error: true,
          message: payload.error,
        }),
      }),
    })
  )

  .case(fetchingUserPosition.done, (state, payload) =>
    mergeObjects(state, {
      controller: mergeObjects(state.controller, {
        current: mergeObjects(state.controller.current, {
          lat: payload.result.lat,
          lng: payload.result.lng,
        }),
      }),
    })
  )

  .case(selectChangeBuid, (state, payload) =>
    mergeObjects(state, {
      selectedBuid: payload,
      controller: mergeObjects(state.controller, {
        current: mergeObjects(state.controller.current, {
          buidId: payload ? payload.buidId : 0,
        }),
      }),
    })
  )

  .case(controllerPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      controller: mergeObjects(state.controller, {
        current: mergeObjects(state.controller.current, {
          [payload.property]: payload.value,
        }),
      }),
    })
  )

  .case(navigateToRoute, (state) =>
    mergeObjects(state, {
      controller: mergeObjects(state.controller, {
        current: state.controller.original,
      }),
    })
  );

export default reducer;
