import { bindable } from 'aurelia-framework';
import {
  ConditionalOperator,
  IDateFilter,
  ISiteColumn
} from '../../../interfaces';
import './date-filter.css';

export class DateFilterCustomElement {
  // bindables - exposed properties
  @bindable inputText: string;
  @bindable buttonText: string;

  @bindable size = 'medium';

  @bindable items: Set<any>;
  @bindable column: ISiteColumn;

  @bindable onApplyFilter: Function;
  @bindable onCancelFilter: Function;

  @bindable applyOnClose: boolean;

  @bindable isExclude: boolean;

  // internal properties
  private dateFrom?: Date;
  private dateTo?: Date;

  bind() {
    this.dateFrom = undefined;
    this.dateTo = undefined;
  }

  operatorOptions: InternalDateFilter[] = [
    {
      name: 'Equals',
      value: 0,
      operator: ConditionalOperator.Equals,
      symbol: '='
    } as InternalDateFilter,
    {
      name: 'Less than',
      value: 1,
      operator: ConditionalOperator.LesserThan,
      symbol: '<'
    } as InternalDateFilter,
    {
      name: 'Greater than',
      value: 2,
      operator: ConditionalOperator.GreaterThan,
      symbol: '>'
    } as InternalDateFilter,
    {
      name: 'Between',
      value: 3,
      operator: ConditionalOperator.Between,
      symbol: '&&'
    } as InternalDateFilter
  ];

  internalItems: IDateFilter[] = new Array<IDateFilter>();

  dropdownSelectedValue: number = 0;

  detached() {
    if (this.applyOnClose) this.apply();
  }

  add() {
    const filter = {
      value: this.dateFrom,
      operator: this.operatorOptions[this.dropdownSelectedValue].operator,
      symbol: this.operatorOptions[this.dropdownSelectedValue].symbol
    } as IDateFilter;

    if (this.dropdownSelectedValue == 3 && this.dateTo) {
      filter.secondValue = this.dateTo;
    }

    this.internalItems.push(filter);

    this.resetFilter();
  }

  resetFilter() {
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.dropdownSelectedValue = 0;
  }

  remove(index: number) {
    this.internalItems.splice(index, 1);
  }

  apply() {
    if (this.onApplyFilter) {
      this.onApplyFilter({
        column: this.column,
        filters: this.internalItems,
        exclude: this.isExclude
      });
    } else {
    }
  }

  dateFromChanged(newValue: Date) {
    this.dateFrom = newValue;
  }

  dateToChanged(newValue: Date) {
    this.dateTo = newValue;
  }

  dropdownOnChange(value: number) {
    this.dropdownSelectedValue = value;
    if (value < 3) {
      this.dateTo = undefined;
    }
  }

  clearAll() {
    this.internalItems = new Array<IDateFilter>();
    this.apply();
  }

  toggleExclude() {
    this.isExclude = !this.isExclude;
  }

  cancel() {
    if (this.onCancelFilter) this.onCancelFilter();
  }
}

interface InternalDateFilter {
  name: string;
  value: number;
  operator: ConditionalOperator;
  symbol: string;
}
