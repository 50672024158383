import { ITimeZone, IDictionary } from '../../interfaces';
import { toObject } from '../stateHelpers';
import { isNone } from '..';
import { timezones } from './timezones';
import { orderByPredicate } from '../sorting';

const timezoneMap = toObject(timezones, tz => tz.timezoneId);

export const getTimezonesSorted = (): ITimeZone[] => orderByPredicate<ITimeZone>(timezones, timezone => timezone.description, 'asc');

export const getTimezonesAsDictonary = (): IDictionary<ITimeZone> =>
  timezoneMap;

export const getTimezoneById = (timezoneId: number): string | undefined => {
  const timezone = timezoneMap[timezoneId];
  return timezone ? timezone.iana : undefined;
};

export const getTimezoneDescById =  (timezoneId: number): string | undefined => {
  const timezone = timezoneMap[timezoneId];
  return timezone ? timezone.description : undefined;
};

export const getDefaultTimezone = () => timezoneMap[131].iana;

export const getTimezoneByIdOrDefault = (timezoneId?: number) =>
  isNone(timezoneId)
    ? getDefaultTimezone()
    : getTimezoneById(timezoneId) || getDefaultTimezone();
