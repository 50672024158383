/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountReusableContactsQuery
// ====================================================

export interface CountReusableContactsQuery_contactSearch {
  totalCount: number;
}

export interface CountReusableContactsQuery {
  contactSearch: CountReusableContactsQuery_contactSearch;
}

export interface CountReusableContactsQueryVariables {
  first?: number | null;
  offset?: number | null;
  name?: string | null;
  email?: string | null;
  buidId?: string | null;
  customerId?: string | null;
  telephone?: string | null;
  sortProperty?: ContactSortEnum | null;
  sortDirection?: SortDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactSearchForReuseQuery
// ====================================================

export interface ContactSearchForReuseQuery_contactSearch_edges {
  contactId: string;
  name: string;
  email: string | null;
  telephoneNumber: string | null;
}

export interface ContactSearchForReuseQuery_contactSearch {
  edges: ContactSearchForReuseQuery_contactSearch_edges[];
  totalCount: number;
}

export interface ContactSearchForReuseQuery {
  contactSearch: ContactSearchForReuseQuery_contactSearch;
}

export interface ContactSearchForReuseQueryVariables {
  first?: number | null;
  offset?: number | null;
  name?: string | null;
  email?: string | null;
  telephone?: string | null;
  buidId?: string | null;
  customerId?: string | null;
  sortProperty?: ContactSortEnum | null;
  sortDirection?: SortDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BuidsQueryReact
// ====================================================

export interface BuidsQueryReact_buids {
  buidId: string | null;
  name: string | null;
}

export interface BuidsQueryReact {
  buids: BuidsQueryReact_buids[];
}

export interface BuidsQueryReactVariables {
  includeResponsibleBuid?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BuidsQuery
// ====================================================

export interface BuidsQuery_buids {
  buidId: string | null;
  name: string | null;
}

export interface BuidsQuery {
  buids: BuidsQuery_buids[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerTypes
// ====================================================

export interface ControllerTypes {
  controllerTypes: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomersQueryReact
// ====================================================

export interface CustomersQueryReact_customers {
  customerId: string;
  name: string | null;
}

export interface CustomersQueryReact {
  customers: CustomersQueryReact_customers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomersQuery
// ====================================================

export interface CustomersQuery_customers {
  customerId: string;
  name: string | null;
}

export interface CustomersQuery {
  customers: CustomersQuery_customers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HauliersDropdownQuery
// ====================================================

export interface HauliersDropdownQuery_hauliers {
  haulierId: string;
  name: string;
  haulierTag: string;
}

export interface HauliersDropdownQuery {
  hauliers: HauliersDropdownQuery_hauliers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HauliersQuery
// ====================================================

export interface HauliersQuery_hauliers {
  haulierId: string;
  name: string;
  haulierTag: string;
}

export interface HauliersQuery {
  hauliers: HauliersQuery_hauliers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LanguageDropdownQuery
// ====================================================

export interface LanguageDropdownQuery_languages {
  languageId: string;
  code: string | null;
  fullCode: string | null;
  description: string | null;
}

export interface LanguageDropdownQuery {
  languages: LanguageDropdownQuery_languages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductsQueryReact
// ====================================================

export interface ProductsQueryReact_products_translations {
  languageCode: string | null;
  translation: string | null;
}

export interface ProductsQueryReact_products {
  productId: string;
  name: string | null;
  translations: ProductsQueryReact_products_translations[];
}

export interface ProductsQueryReact {
  products: ProductsQueryReact_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductsQuery
// ====================================================

export interface ProductsQuery_products_translations {
  languageCode: string | null;
  translation: string | null;
}

export interface ProductsQuery_products {
  productId: string;
  name: string | null;
  translations: ProductsQuery_products_translations[];
}

export interface ProductsQuery {
  products: ProductsQuery_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StaalduinenPickupPointsQuery
// ====================================================

export interface StaalduinenPickupPointsQuery_staalduinenPickupPoints {
  code: string;
  name: string;
  staalduinenPickupPointId: string;
}

export interface StaalduinenPickupPointsQuery {
  staalduinenPickupPoints: StaalduinenPickupPointsQuery_staalduinenPickupPoints[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnitsQueryReact
// ====================================================

export interface UnitsQueryReact_units {
  unitId: string;
  translationKey: string;
  symbol: string;
  unitIdWithoutInterval: number | null;
}

export interface UnitsQueryReact {
  units: UnitsQueryReact_units[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnitQuery
// ====================================================

export interface UnitQuery_units {
  unitId: string;
  translationKey: string;
  symbol: string;
  unitIdWithoutInterval: number | null;
}

export interface UnitQuery {
  units: UnitQuery_units[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VehicleQuery
// ====================================================

export interface VehicleQuery_vehicles {
  vehicleId: string | null;
  codeAndName: string | null;
}

export interface VehicleQuery {
  vehicles: VehicleQuery_vehicles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Consent
// ====================================================

export interface Consent_consent {
  code: string;
  success: boolean;
  message: string;
}

export interface Consent {
  consent: Consent_consent | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ColumnFilterRefQueryForHistogram
// ====================================================

export interface ColumnFilterRefQueryForHistogram_refFilterValues {
  key: string;
  label: string;
  instances: number;
}

export interface ColumnFilterRefQueryForHistogram {
  refFilterValues: ColumnFilterRefQueryForHistogram_refFilterValues[];
}

export interface ColumnFilterRefQueryForHistogramVariables {
  property: string;
  filters?: string | null;
  page: ElasticSearchPage;
  freeTextQuery?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ColumnFilterRefQuery
// ====================================================

export interface ColumnFilterRefQuery_refFilterValues {
  key: string;
  label: string;
  instances: number;
}

export interface ColumnFilterRefQuery {
  refFilterValues: ColumnFilterRefQuery_refFilterValues[];
}

export interface ColumnFilterRefQueryVariables {
  property: string;
  filters?: string | null;
  page: ElasticSearchPage;
  freeTextQuery?: (string | null)[] | null;
  language?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarkerPopup
// ====================================================

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_gasDetails {
  meanPpm: number | null;
  maxPpm: number | null;
  percentTimeAboveLimit: number | null;
  h2SRuntimeCalibration: number | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_product {
  productId: number;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels {
  gasDetails: MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_gasDetails | null;
  health: HealthEnum | null;
  alias: string;
  isParked: boolean | null;
  productName: string | null;
  product: MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_product | null;
  unit: MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_unit | null;
  markerIconEnum: MarkerIconEnum | null;
  activeAlarms: MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels_activeAlarms[] | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  shipTo: string | null;
  freeCapacity: number | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_product {
  productId: number;
  name: string;
  alias_deDE: string;
  alias_enGB: string;
  alias_esES: string;
  alias_frFR: string;
  alias_huHU: string;
  alias_itIT: string;
  alias_nbNO: string;
  alias_nlNL: string;
  alias_plPL: string;
  alias_svSE: string;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels {
  alias: string;
  capacity: number | null;
  maximum: number | null;
  minimum: number | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  markerIconEnum: MarkerIconEnum | null;
  activeAlarms: MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_activeAlarms[] | null;
  percentage: number | null;
  unit: MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_unit | null;
  tankDetails: MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
  product: MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels_product | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  alias: string;
  customerRef: string | null;
  soldTo: string | null;
  gasChannels: MarkerPopup_elasticSearchPages_sitelist_data_edges_gasChannels[] | null;
  tankChannels: MarkerPopup_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
}

export interface MarkerPopup_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: MarkerPopup_elasticSearchPages_sitelist_data_edges[];
}

export interface MarkerPopup_elasticSearchPages_sitelist {
  data: MarkerPopup_elasticSearchPages_sitelist_data;
}

export interface MarkerPopup_elasticSearchPages {
  sitelist: MarkerPopup_elasticSearchPages_sitelist;
}

export interface MarkerPopup {
  elasticSearchPages: MarkerPopup_elasticSearchPages;
}

export interface MarkerPopupVariables {
  offset?: number | null;
  first?: number | null;
  sortProperty: number;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserInfoQuery
// ====================================================

export interface UserInfoQuery_currentUser_user {
  name: string;
  avatarUrl: string | null;
}

export interface UserInfoQuery_currentUser {
  user: UserInfoQuery_currentUser_user | null;
}

export interface UserInfoQuery {
  currentUser: UserInfoQuery_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactsCreateQuery
// ====================================================

export interface ContactsCreateQuery_site_customer {
  name: string | null;
  customerId: string;
}

export interface ContactsCreateQuery_site_buid {
  name: string | null;
  buidId: string | null;
}

export interface ContactsCreateQuery_site {
  siteId: string;
  customer: ContactsCreateQuery_site_customer | null;
  buid: ContactsCreateQuery_site_buid | null;
}

export interface ContactsCreateQuery {
  site: ContactsCreateQuery_site | null;
}

export interface ContactsCreateQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNewContact
// ====================================================

export interface CreateNewContact_upsertContact {
  customerId: number | null;
}

export interface CreateNewContact {
  upsertContact: CreateNewContact_upsertContact | null;
}

export interface CreateNewContactVariables {
  entity?: ContactInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactContactsSearchQuery
// ====================================================

export interface ReactContactsSearchQuery_contacts_edges_isContactFor {
  contactTypes: string[];
  siteId: number;
}

export interface ReactContactsSearchQuery_contacts_edges {
  avatarUrl: string | null;
  contactId: string;
  email: string | null;
  name: string;
  telephoneNumber: string | null;
  description: string | null;
  initials: string;
  isContactFor: ReactContactsSearchQuery_contacts_edges_isContactFor[];
}

export interface ReactContactsSearchQuery_contacts {
  totalCount: number;
  edges: ReactContactsSearchQuery_contacts_edges[];
}

export interface ReactContactsSearchQuery {
  contacts: ReactContactsSearchQuery_contacts;
}

export interface ReactContactsSearchQueryVariables {
  first?: number | null;
  offset?: number | null;
  freeTextQuery?: string | null;
  sortProperty: ContactSortEnum;
  sortDirection: SortDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactsSearchQuery
// ====================================================

export interface ContactsSearchQuery_contacts_edges {
  avatarUrl: string | null;
  contactId: string;
  email: string | null;
  name: string;
  telephoneNumber: string | null;
  description: string | null;
}

export interface ContactsSearchQuery_contacts {
  totalCount: number;
  edges: ContactsSearchQuery_contacts_edges[];
}

export interface ContactsSearchQuery {
  contacts: ContactsSearchQuery_contacts;
}

export interface ContactsSearchQueryVariables {
  first: number;
  offset: number;
  freeTextQuery?: string | null;
  sortProperty: ContactSortEnum;
  sortDirection: SortDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerProfileEditor
// ====================================================

export interface ControllerProfileEditor_controller_profile_history_user {
  name: string;
  userId: number;
}

export interface ControllerProfileEditor_controller_profile_history {
  controllerProfileHistoryId: number;
  controllerProfileId: number;
  created: string;
  createdByUserIdToken: string;
  user: ControllerProfileEditor_controller_profile_history_user;
}

export interface ControllerProfileEditor_controller_profile_profilevalues {
  profileValues: number[];
  timestamp: string;
  isReadOnly: boolean;
}

export interface ControllerProfileEditor_controller_profile {
  name: string | null;
  controllerId: number;
  controllerProfileId: number;
  lastUpdated: string | null;
  securityLevel: number;
  history: ControllerProfileEditor_controller_profile_history[];
  profilevalues: ControllerProfileEditor_controller_profile_profilevalues | null;
}

export interface ControllerProfileEditor_controller {
  alias: string | null;
  controllerId: string | null;
  timeZoneId: number | null;
  profile: ControllerProfileEditor_controller_profile;
}

export interface ControllerProfileEditor {
  controller: ControllerProfileEditor_controller | null;
}

export interface ControllerProfileEditorVariables {
  controllerId: number;
  profileId: number;
  profileHistoryId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerDetailsQuery
// ====================================================

export interface ControllerDetailsQuery_controller_site {
  alias: string;
}

export interface ControllerDetailsQuery_controller_buid {
  name: string | null;
}

export interface ControllerDetailsQuery_controller_createdByUser {
  name: string;
}

export interface ControllerDetailsQuery_controller_simDetail {
  icc: string | null;
  imei: string | null;
  ipAddress: string | null;
  msisdn: string | null;
  provider: string | null;
  simNotes: string | null;
}

export interface ControllerDetailsQuery_controller {
  controllerId: string | null;
  alias: string | null;
  controllerType: string;
  serial: string;
  isVirual: boolean | null;
  isArchived: boolean;
  isMasterDataSource: boolean | null;
  name: string | null;
  description: string | null;
  countryCode: string | null;
  lastContact: string | null;
  lastSampleTime: string | null;
  softwareDate: string | null;
  siteId: number | null;
  site: ControllerDetailsQuery_controller_site | null;
  timeZoneId: number | null;
  protocolVersion: string | null;
  communicationManagerVersion: string | null;
  simDetailsId: number | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  postCode: string | null;
  country: string | null;
  numberOfSlaves: number | null;
  currentRunningScriptName: string | null;
  currentRunningScriptLastModified: string | null;
  alternateSerial: string | null;
  buidId: number | null;
  buid: ControllerDetailsQuery_controller_buid | null;
  createdByUserId: number | null;
  createdByUserIdToken: string | null;
  createdByUser: ControllerDetailsQuery_controller_createdByUser | null;
  created: string | null;
  simDetail: ControllerDetailsQuery_controller_simDetail | null;
  lat: number | null;
  lng: number | null;
  accuracy: number | null;
}

export interface ControllerDetailsQuery {
  controller: ControllerDetailsQuery_controller | null;
}

export interface ControllerDetailsQueryVariables {
  controllerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateControllerPreflightQuery
// ====================================================

export interface UpdateControllerPreflightQuery_updateControllerPreflight {
  alias: string;
  serial: string;
  controllerId: number;
}

export interface UpdateControllerPreflightQuery {
  updateControllerPreflight: UpdateControllerPreflightQuery_updateControllerPreflight[];
}

export interface UpdateControllerPreflightQueryVariables {
  controllerId: number;
  controllerToCheck: ControllerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateController
// ====================================================

export interface UpdateController_updateController {
  code: string;
  message: string;
  success: boolean;
}

export interface UpdateController {
  updateController: UpdateController_updateController;
}

export interface UpdateControllerVariables {
  controllerId: number;
  serial: string;
  alias: string;
  controllerType: string;
  buidId?: number | null;
  countryCode: string;
  timeZoneId?: number | null;
  isMasterDataSource?: boolean | null;
  numberOfSlaves?: number | null;
  ipAddress?: string | null;
  icc?: string | null;
  msisdn?: string | null;
  protocolVersion?: string | null;
  provider?: string | null;
  simNotes?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUsersWithAccessToController
// ====================================================

export interface getUsersWithAccessToController_controller_usersWithAccess_user {
  userId: number;
  name: string;
  internalUser: boolean | null;
}

export interface getUsersWithAccessToController_controller_usersWithAccess {
  user: getUsersWithAccessToController_controller_usersWithAccess_user;
  token: string;
  hasAccess: boolean;
  added: boolean;
  removed: boolean;
}

export interface getUsersWithAccessToController_controller {
  usersWithAccess: getUsersWithAccessToController_controller_usersWithAccess[];
}

export interface getUsersWithAccessToController {
  controller: getUsersWithAccessToController_controller | null;
}

export interface getUsersWithAccessToControllerVariables {
  controllerId: number;
  pendingBuidId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerLog
// ====================================================

export interface ControllerLog_controller_logs_performedByUser {
  userId: number;
  name: string;
}

export interface ControllerLog_controller_logs {
  controllerLogId: number;
  controllerId: number;
  command: string | null;
  data: string | null;
  performedByUser: ControllerLog_controller_logs_performedByUser | null;
  timeStamp: string;
}

export interface ControllerLog_controller {
  logs: ControllerLog_controller_logs[];
}

export interface ControllerLog {
  controller: ControllerLog_controller | null;
}

export interface ControllerLogVariables {
  controllerId: number;
  amount?: number | null;
  afterId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LatestControllerImports
// ====================================================

export interface LatestControllerImports_controller_importRefs {
  importRefId: number;
  controllerId: number;
  fileName: string | null;
  source: string;
  timestamp: string;
  sasReadUrl: string | null;
}

export interface LatestControllerImports_controller {
  importRefs: LatestControllerImports_controller_importRefs[];
}

export interface LatestControllerImports {
  controller: LatestControllerImports_controller | null;
}

export interface LatestControllerImportsVariables {
  controllerId: number;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: idaxCsUrl
// ====================================================

export interface idaxCsUrl_idaxCsUrl {
  url: string | null;
}

export interface idaxCsUrl {
  idaxCsUrl: idaxCsUrl_idaxCsUrl | null;
}

export interface idaxCsUrlVariables {
  controllerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controllerLastBackup
// ====================================================

export interface controllerLastBackup_controllerLastBackup {
  backupEquipmentId: number | null;
  attachmentId: number | null;
  lastBackupDate: string | null;
  siteId: number | null;
  isAvailableForBackup: boolean | null;
  hasBackupViewer: boolean | null;
}

export interface controllerLastBackup {
  controllerLastBackup: controllerLastBackup_controllerLastBackup;
}

export interface controllerLastBackupVariables {
  controllerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestControllerBackup
// ====================================================

export interface requestControllerBackup {
  backupController: boolean | null;
}

export interface requestControllerBackupVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerDetailsMapQuery
// ====================================================

export interface ControllerDetailsMapQuery_controller {
  controllerId: string | null;
  alias: string | null;
  lat: number | null;
  lng: number | null;
  accuracy: number | null;
}

export interface ControllerDetailsMapQuery {
  controller: ControllerDetailsMapQuery_controller | null;
}

export interface ControllerDetailsMapQueryVariables {
  controllerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getControllerOutputCalibrations
// ====================================================

export interface getControllerOutputCalibrations_controller_outputCalibrations {
  controllerId: number;
  controllerOutputCalibrationId: number;
  alias: string;
  value: number | null;
}

export interface getControllerOutputCalibrations_controller {
  outputCalibrations: getControllerOutputCalibrations_controller_outputCalibrations[];
}

export interface getControllerOutputCalibrations {
  controller: getControllerOutputCalibrations_controller | null;
}

export interface getControllerOutputCalibrationsVariables {
  controllerId: number;
  fromController: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateControllerOutputCalibrationValue
// ====================================================

export interface updateControllerOutputCalibrationValue_updateControllerOutputCalibrationValue {
  success: boolean;
  message: string;
}

export interface updateControllerOutputCalibrationValue {
  updateControllerOutputCalibrationValue: updateControllerOutputCalibrationValue_updateControllerOutputCalibrationValue;
}

export interface updateControllerOutputCalibrationValueVariables {
  controllerId: number;
  controllerOutputCalibrationId: number;
  value: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerOutputCalibrationHistoryQuery
// ====================================================

export interface ControllerOutputCalibrationHistoryQuery_controllerOutputCalibrationHistory_all {
  controllerOutputCalibrationHistoryId: number;
  username: string | null;
  timeOfChange: string;
  event: string | null;
}

export interface ControllerOutputCalibrationHistoryQuery_controllerOutputCalibrationHistory {
  all: ControllerOutputCalibrationHistoryQuery_controllerOutputCalibrationHistory_all[];
}

export interface ControllerOutputCalibrationHistoryQuery {
  controllerOutputCalibrationHistory: ControllerOutputCalibrationHistoryQuery_controllerOutputCalibrationHistory | null;
}

export interface ControllerOutputCalibrationHistoryQueryVariables {
  controllerOutputCalibrationId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getControllerParameters
// ====================================================

export interface getControllerParameters_controller_parameters {
  controllerId: number;
  controllerParameterId: number;
  name: string;
  securityLevel: number;
  unitDescription: string | null;
  unitSymbol: string | null;
  value: number | null;
  minimum: number | null;
  maximum: number | null;
}

export interface getControllerParameters_controller {
  parameters: getControllerParameters_controller_parameters[];
}

export interface getControllerParameters {
  controller: getControllerParameters_controller | null;
}

export interface getControllerParametersVariables {
  controllerId: number;
  fromController: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateControllerParameterValue
// ====================================================

export interface updateControllerParameterValue_updateControllerParameterValue {
  success: boolean;
  message: string;
}

export interface updateControllerParameterValue {
  updateControllerParameterValue: updateControllerParameterValue_updateControllerParameterValue;
}

export interface updateControllerParameterValueVariables {
  controllerId: number;
  controllerParameterId: number;
  value: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateControllerParameterSecurityLevel
// ====================================================

export interface updateControllerParameterSecurityLevel_updateControllerParameterSecurityLevel {
  success: boolean;
  message: string;
}

export interface updateControllerParameterSecurityLevel {
  updateControllerParameterSecurityLevel: updateControllerParameterSecurityLevel_updateControllerParameterSecurityLevel;
}

export interface updateControllerParameterSecurityLevelVariables {
  controllerId: number;
  controllerParameterId: number;
  securityLevel: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerParameterHistoryQuery
// ====================================================

export interface ControllerParameterHistoryQuery_controllerParametersHistory_all {
  controllerParameterHistoryId: number;
  controllerParameterId: number;
  userId: number | null;
  username: string | null;
  fullname: string | null;
  timeOfChange: string;
  event: string | null;
}

export interface ControllerParameterHistoryQuery_controllerParametersHistory {
  all: ControllerParameterHistoryQuery_controllerParametersHistory_all[];
}

export interface ControllerParameterHistoryQuery {
  controllerParametersHistory: ControllerParameterHistoryQuery_controllerParametersHistory | null;
}

export interface ControllerParameterHistoryQueryVariables {
  controllerParameterId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getControllerProfiles
// ====================================================

export interface getControllerProfiles_controller_profiles {
  controllerProfileId: number;
  controllerId: number;
  name: string | null;
  securityLevel: number;
  lastUpdated: string | null;
}

export interface getControllerProfiles_controller {
  profiles: getControllerProfiles_controller_profiles[];
}

export interface getControllerProfiles {
  controller: getControllerProfiles_controller | null;
}

export interface getControllerProfilesVariables {
  controllerId: number;
  fromController: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getControllerProfileValues
// ====================================================

export interface getControllerProfileValues_controller_profileValues {
  isReadOnly: boolean;
  profileName: string;
  profileValues: number[];
  siteId: number;
  timestamp: string;
}

export interface getControllerProfileValues_controller {
  profileValues: getControllerProfileValues_controller_profileValues;
}

export interface getControllerProfileValues {
  controller: getControllerProfileValues_controller | null;
}

export interface getControllerProfileValuesVariables {
  controllerId: number;
  profileId: number;
  profileHistoryId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateControllerProfileValues
// ====================================================

export interface updateControllerProfileValues_updateControllerProfileValues {
  isReadOnly: boolean;
  profileName: string;
  profileValues: number[];
  siteId: number;
  timestamp: string;
}

export interface updateControllerProfileValues {
  updateControllerProfileValues: updateControllerProfileValues_updateControllerProfileValues;
}

export interface updateControllerProfileValuesVariables {
  controllerId: number;
  profileId: number;
  profileValues: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateControllerProfileSecurityLevel
// ====================================================

export interface updateControllerProfileSecurityLevel_updateControllerProfileSecurityLevel {
  success: boolean;
  message: string;
}

export interface updateControllerProfileSecurityLevel {
  updateControllerProfileSecurityLevel: updateControllerProfileSecurityLevel_updateControllerProfileSecurityLevel;
}

export interface updateControllerProfileSecurityLevelVariables {
  controllerId: number;
  controllerProfileId: number;
  securityLevel: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getControllerRemoteControlUrl
// ====================================================

export interface getControllerRemoteControlUrl_controller_remoteControlUrl {
  remoteUrl: string;
}

export interface getControllerRemoteControlUrl_controller {
  remoteControlUrl: getControllerRemoteControlUrl_controller_remoteControlUrl;
}

export interface getControllerRemoteControlUrl {
  controller: getControllerRemoteControlUrl_controller | null;
}

export interface getControllerRemoteControlUrlVariables {
  controllerId: number;
  port?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerReplaceSerialList
// ====================================================

export interface ControllerReplaceSerialList_elasticSearchPages_controllerManager_data_edges_simDetails {
  ipAddress: string | null;
  icc: string | null;
}

export interface ControllerReplaceSerialList_elasticSearchPages_controllerManager_data_edges {
  controllerId: number;
  serial: string | null;
  alias: string | null;
  controllerType: string | null;
  simDetails: ControllerReplaceSerialList_elasticSearchPages_controllerManager_data_edges_simDetails | null;
}

export interface ControllerReplaceSerialList_elasticSearchPages_controllerManager_data {
  edges: ControllerReplaceSerialList_elasticSearchPages_controllerManager_data_edges[];
  totalCount: number;
}

export interface ControllerReplaceSerialList_elasticSearchPages_controllerManager {
  data: ControllerReplaceSerialList_elasticSearchPages_controllerManager_data;
}

export interface ControllerReplaceSerialList_elasticSearchPages {
  controllerManager: ControllerReplaceSerialList_elasticSearchPages_controllerManager;
}

export interface ControllerReplaceSerialList {
  elasticSearchPages: ControllerReplaceSerialList_elasticSearchPages;
}

export interface ControllerReplaceSerialListVariables {
  serialQuery: string;
  first?: number | null;
  offset?: number | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ControllerReplaceMutation
// ====================================================

export interface ControllerReplaceMutation_replaceController {
  code: string;
  success: boolean;
  message: string;
}

export interface ControllerReplaceMutation {
  replaceController: ControllerReplaceMutation_replaceController;
}

export interface ControllerReplaceMutationVariables {
  controllerId: number;
  newSerial: string;
  suspendSim: boolean;
  attachmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ControllersArchive
// ====================================================

export interface ControllersArchive_archiveControllers {
  code: string;
  message: string;
  success: boolean;
}

export interface ControllersArchive {
  archiveControllers: ControllersArchive_archiveControllers;
}

export interface ControllersArchiveVariables {
  controllerIds: number[];
  suspendSim: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ControllersDelete
// ====================================================

export interface ControllersDelete_deleteControllers {
  code: string;
  message: string;
  success: boolean;
}

export interface ControllersDelete {
  deleteControllers: ControllersDelete_deleteControllers;
}

export interface ControllersDeleteVariables {
  controllerIds: number[];
  suspendSim: boolean;
  decommissionReason: number;
  decommissionReasonText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CanSuspendSimCards
// ====================================================

export interface CanSuspendSimCards {
  canSuspendSimCards: boolean;
}

export interface CanSuspendSimCardsVariables {
  controllerIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ControllersDetach
// ====================================================

export interface ControllersDetach_detachControllers {
  code: string;
  message: string;
  success: boolean;
}

export interface ControllersDetach {
  detachControllers: ControllersDetach_detachControllers;
}

export interface ControllersDetachVariables {
  controllerIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactDataFreshnessQuery
// ====================================================

export interface ReactDataFreshnessQuery_elasticSearchPages {
  lastUpdated: number | null;
}

export interface ReactDataFreshnessQuery {
  elasticSearchPages: ReactDataFreshnessQuery_elasticSearchPages;
  epochNow: number;
}

export interface ReactDataFreshnessQueryVariables {
  page: ElasticSearchPage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DataFreshnessQuery
// ====================================================

export interface DataFreshnessQuery_elasticSearchPages {
  lastUpdated: number | null;
}

export interface DataFreshnessQuery {
  elasticSearchPages: DataFreshnessQuery_elasticSearchPages;
  epochNow: number;
}

export interface DataFreshnessQueryVariables {
  page: ElasticSearchPage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteGroupFilterDropdownQueryReact
// ====================================================

export interface SiteGroupFilterDropdownQueryReact_siteFilters {
  filterId: string;
  name: string;
  isPublic: boolean;
  definition: string;
}

export interface SiteGroupFilterDropdownQueryReact {
  siteFilters: SiteGroupFilterDropdownQueryReact_siteFilters[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteGroupFilterDropdownQuery
// ====================================================

export interface SiteGroupFilterDropdownQuery_siteFilters {
  filterId: string;
  name: string;
  isPublic: boolean;
}

export interface SiteGroupFilterDropdownQuery {
  siteFilters: SiteGroupFilterDropdownQuery_siteFilters[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserManagerContactsQuery
// ====================================================

export interface UserManagerContactsQuery_contacts_edges_isContactFor {
  contactTypes: string[];
  siteId: number;
  siteName: string;
}

export interface UserManagerContactsQuery_contacts_edges {
  email: string | null;
  avatarUrl: string | null;
  telephoneNumber: string | null;
  name: string;
  contactId: string;
  customerId: number | null;
  buidId: number | null;
  description: string | null;
  userId: string | null;
  syncedWithUserInformation: boolean | null;
  isContactFor: UserManagerContactsQuery_contacts_edges_isContactFor[];
}

export interface UserManagerContactsQuery_contacts {
  edges: UserManagerContactsQuery_contacts_edges[];
}

export interface UserManagerContactsQuery {
  contacts: UserManagerContactsQuery_contacts;
}

export interface UserManagerContactsQueryVariables {
  userId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserManagerUpdatePendingContact
// ====================================================

export interface UserManagerUpdatePendingContact_upsertContact {
  contactId: string;
}

export interface UserManagerUpdatePendingContact {
  upsertContact: UserManagerUpdatePendingContact_upsertContact | null;
}

export interface UserManagerUpdatePendingContactVariables {
  entity: ContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserManagerRemoveContactMutation
// ====================================================

export interface UserManagerRemoveContactMutation {
  deleteContact: boolean | null;
}

export interface UserManagerRemoveContactMutationVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactManagerList
// ====================================================

export interface ContactManagerList_contacts_edges {
  avatarUrl: string | null;
  contactId: string;
  email: string | null;
  name: string;
  telephoneNumber: string | null;
}

export interface ContactManagerList_contacts {
  totalCount: number;
  edges: ContactManagerList_contacts_edges[];
}

export interface ContactManagerList {
  contacts: ContactManagerList_contacts;
}

export interface ContactManagerListVariables {
  first: number;
  offset: number;
  freeTextQuery?: string | null;
  sortProperty: ContactSortEnum;
  sortDirection: SortDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactManagerDetails
// ====================================================

export interface ContactManagerDetails_contact_user {
  name: string;
  buidId: number | null;
  customerId: number | null;
}

export interface ContactManagerDetails_contact_buid {
  buidId: string | null;
  name: string | null;
}

export interface ContactManagerDetails_contact_customer {
  customerId: string;
  name: string | null;
}

export interface ContactManagerDetails_contact_isContactFor {
  contactTypes: string[];
  currentUserHasAccessToSite: boolean;
  siteId: number;
  siteName: string;
}

export interface ContactManagerDetails_contact {
  avatarUrl: string | null;
  contactId: string;
  email: string | null;
  name: string;
  telephoneNumber: string | null;
  userId: string | null;
  user: ContactManagerDetails_contact_user | null;
  description: string | null;
  buid: ContactManagerDetails_contact_buid | null;
  customer: ContactManagerDetails_contact_customer | null;
  isContactFor: ContactManagerDetails_contact_isContactFor[];
}

export interface ContactManagerDetails {
  contact: ContactManagerDetails_contact | null;
}

export interface ContactManagerDetailsVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertContact
// ====================================================

export interface UpsertContact_upsertContact {
  contactId: string;
}

export interface UpsertContact {
  upsertContact: UpsertContact_upsertContact | null;
}

export interface UpsertContactVariables {
  contactInput: ContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContact
// ====================================================

export interface DeleteContact {
  deleteContact: boolean | null;
}

export interface DeleteContactVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: controllerBackupViewer
// ====================================================

export interface controllerBackupViewer_controllerBackupViewer_ida10Data_calibrationProfiles {
  name: string;
  values: number[];
}

export interface controllerBackupViewer_controllerBackupViewer_ida10Data_calibrationRows {
  id: number;
  title: string;
  name: string;
  min: string;
  max: string;
  units: string;
  offset: string;
  ppu: string;
  ppm: string;
  smooth: string;
  mrMin: string | null;
  mrMax: string | null;
  desc: string;
  source: number;
}

export interface controllerBackupViewer_controllerBackupViewer_ida10Data_configRows {
  name: string;
  value: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida10Data_parameterRows {
  id: number;
  title: string;
  name: string;
  value: string;
  units: string;
  min: string;
  max: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida10Data_trendRows {
  id: number;
  title: string;
  name: string;
  units: string;
  desc: string;
  source: number;
}

export interface controllerBackupViewer_controllerBackupViewer_ida10Data {
  calibrationProfiles: controllerBackupViewer_controllerBackupViewer_ida10Data_calibrationProfiles[];
  calibrationRows: controllerBackupViewer_controllerBackupViewer_ida10Data_calibrationRows[];
  configRows: controllerBackupViewer_controllerBackupViewer_ida10Data_configRows[];
  dcorrProfile: number[];
  parameterRows: controllerBackupViewer_controllerBackupViewer_ida10Data_parameterRows[];
  trendRows: controllerBackupViewer_controllerBackupViewer_ida10Data_trendRows[];
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_alarmRows {
  comment: string;
  id: number;
  name: string;
  source: string;
  setCondition: string;
  setValue: number;
  resetCondition: string;
  resetValue: number;
  message: string;
  smsRecipients: string | null;
  emailRecipients: string | null;
  state: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_loginRows {
  comment: string;
  id: number;
  name: string;
  password: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_parameterRows {
  comment: string;
  id: number;
  initialValue: number;
  maximum: number;
  minimum: number;
  name: string;
  security: string;
  units: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_profileRows {
  comment: string;
  id: number;
  name: string;
  values: number[];
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_settings {
  name: string;
  value: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_trendRows {
  comment: string;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  security: string;
  units: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_units_analogInputRows {
  comment: string;
  id: number;
  maMaximum: number | null;
  maMinimum: number | null;
  maximum: number;
  minimum: number;
  name: string;
  smoothing: number | null;
  units: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_units_analogOutputRows {
  calibration: number;
  comment: string;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  units: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_units_digitalInputRows {
  comment: string;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  smoothing: number | null;
  units: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_units_digitalOutputRows {
  calibration: number;
  comment: string;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  pulsesPM: number;
  units: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data_units {
  analogInputRows: controllerBackupViewer_controllerBackupViewer_ida100Data_units_analogInputRows[];
  analogOutputRows: controllerBackupViewer_controllerBackupViewer_ida100Data_units_analogOutputRows[];
  digitalInputRows: controllerBackupViewer_controllerBackupViewer_ida100Data_units_digitalInputRows[];
  digitalOutputRows: controllerBackupViewer_controllerBackupViewer_ida100Data_units_digitalOutputRows[];
  id: number;
  name: string;
}

export interface controllerBackupViewer_controllerBackupViewer_ida100Data {
  alarmRows: controllerBackupViewer_controllerBackupViewer_ida100Data_alarmRows[];
  loginRows: controllerBackupViewer_controllerBackupViewer_ida100Data_loginRows[];
  parameterRows: controllerBackupViewer_controllerBackupViewer_ida100Data_parameterRows[];
  profileRows: controllerBackupViewer_controllerBackupViewer_ida100Data_profileRows[];
  settings: controllerBackupViewer_controllerBackupViewer_ida100Data_settings[];
  trendRows: controllerBackupViewer_controllerBackupViewer_ida100Data_trendRows[];
  units: controllerBackupViewer_controllerBackupViewer_ida100Data_units[];
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_analogInputRows {
  alias: string | null;
  analogInputType: string;
  comment: string | null;
  density: number;
  disabled: boolean;
  dishRadius: number;
  height: number;
  id: number;
  length: number;
  maximum: number;
  milliAmpereMaximum: number;
  milliAmpereMinimum: number;
  minimum: number;
  minTankLevel: number;
  name: string;
  numberOfTanks: number;
  offset: number;
  pressure: number;
  product: string | null;
  smoothing: string;
  source: string;
  tankLevelSensor: number;
  tankVolumeCustomCurve: number[] | null;
  tankType: string;
  unit: string | null;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_analogOutputRows {
  alias: string | null;
  calibration: number;
  comment: string | null;
  disabled: boolean;
  id: number;
  maximum: number;
  milliAmpereMaximum: number;
  milliAmpereMinimum: number;
  minimum: number;
  name: string;
  unit: string | null;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_communicationManagerSettings {
  name: string;
  value: string;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_digitalInputRows {
  digitalInputType: string;
  disabled: boolean;
  comment: string | null;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  offset: number;
  pulsesPerUnit: number;
  unit: string | null;
  smoothing: string;
  source: string;
  switchType: string;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_digitalOutputRows {
  calibration: number;
  comment: string | null;
  disabled: boolean;
  id: number;
  maximum: number;
  minimum: number;
  mode: string;
  name: string;
  pulsesPerMinute: number;
  pulsesPerMinuteMax: number;
  unit: string | null;
  unitsPerPulse: number;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_parameterRows {
  alias: string | null;
  comment: string | null;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  security: string;
  unit: string | null;
  value: number;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_profileRows {
  comment: string | null;
  id: number;
  name: string;
  values: number[];
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_trendRows {
  alias: string | null;
  comment: string | null;
  disabled: boolean;
  id: number;
  maximum: number;
  minimum: number;
  name: string;
  security: string;
  unit: string | null;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData_unit {
  dataDeliveryPeriod: string;
  feedbackDataDeliveryPeriod: string;
  groupCode: string;
  language: string;
  modbusServer: boolean;
  name: string;
  timeZone: string;
}

export interface controllerBackupViewer_controllerBackupViewer_idaxData {
  analogInputRows: controllerBackupViewer_controllerBackupViewer_idaxData_analogInputRows[];
  analogOutputRows: controllerBackupViewer_controllerBackupViewer_idaxData_analogOutputRows[];
  communicationManagerSettings: controllerBackupViewer_controllerBackupViewer_idaxData_communicationManagerSettings[];
  digitalInputRows: controllerBackupViewer_controllerBackupViewer_idaxData_digitalInputRows[];
  digitalOutputRows: controllerBackupViewer_controllerBackupViewer_idaxData_digitalOutputRows[];
  parameterRows: controllerBackupViewer_controllerBackupViewer_idaxData_parameterRows[];
  profileRows: controllerBackupViewer_controllerBackupViewer_idaxData_profileRows[];
  trendRows: controllerBackupViewer_controllerBackupViewer_idaxData_trendRows[];
  unit: controllerBackupViewer_controllerBackupViewer_idaxData_unit;
}

export interface controllerBackupViewer_controllerBackupViewer {
  type: string;
  backupTimestamp: string;
  controllerSerial: string;
  ida10Data: controllerBackupViewer_controllerBackupViewer_ida10Data | null;
  ida100Data: controllerBackupViewer_controllerBackupViewer_ida100Data | null;
  idaxData: controllerBackupViewer_controllerBackupViewer_idaxData | null;
}

export interface controllerBackupViewer {
  controllerBackupViewer: controllerBackupViewer_controllerBackupViewer;
}

export interface controllerBackupViewerVariables {
  equipmentAttachmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteChannelInfluxData
// ====================================================

export interface DeleteChannelInfluxData {
  deleteChannelInfluxData: boolean | null;
}

export interface DeleteChannelInfluxDataVariables {
  channelIds: number[];
  fromDate: any;
  toDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AttachToSite
// ====================================================

export interface AttachToSite_attachControllers {
  success: boolean;
}

export interface AttachToSite {
  attachControllers: AttachToSite_attachControllers;
}

export interface AttachToSiteVariables {
  controllerIds: number[];
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerManagerList
// ====================================================

export interface ControllerManagerList_elasticSearchPages_controllerManager_data_edges_simDetails {
  ipAddress: string | null;
  icc: string | null;
  msisdn: string | null;
  provider: string | null;
  simnotes: string | null;
}

export interface ControllerManagerList_elasticSearchPages_controllerManager_data_edges_buid {
  id: number | null;
  name: string | null;
}

export interface ControllerManagerList_elasticSearchPages_controllerManager_data_edges_site {
  id: number | null;
  name: string | null;
}

export interface ControllerManagerList_elasticSearchPages_controllerManager_data_edges {
  controllerId: number;
  isArchived: boolean;
  serial: string | null;
  alternateSerial: string | null;
  alias: string | null;
  name: string | null;
  controllerType: string | null;
  timeZone: string | null;
  countryCode: string | null;
  signal: SignalEnum;
  communicationManagerVersion: string | null;
  simDetails: ControllerManagerList_elasticSearchPages_controllerManager_data_edges_simDetails | null;
  buid: ControllerManagerList_elasticSearchPages_controllerManager_data_edges_buid | null;
  protocolVersion: string | null;
  site: ControllerManagerList_elasticSearchPages_controllerManager_data_edges_site | null;
  controllerCreationDate: string | null;
  lastSampleTime: string | null;
  lastBackupDate: any | null;
  backupEquipmentId: number | null;
  createdBy: string | null;
}

export interface ControllerManagerList_elasticSearchPages_controllerManager_data {
  totalCount: number;
  edges: ControllerManagerList_elasticSearchPages_controllerManager_data_edges[];
}

export interface ControllerManagerList_elasticSearchPages_controllerManager {
  data: ControllerManagerList_elasticSearchPages_controllerManager_data;
}

export interface ControllerManagerList_elasticSearchPages {
  controllerManager: ControllerManagerList_elasticSearchPages_controllerManager;
}

export interface ControllerManagerList {
  elasticSearchPages: ControllerManagerList_elasticSearchPages;
}

export interface ControllerManagerListVariables {
  first?: number | null;
  offset?: number | null;
  sortProperty?: ControllersSortEnum | null;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerManagerListIds
// ====================================================

export interface ControllerManagerListIds_elasticSearchPages_controllerManager_data_edges {
  controllerId: number;
}

export interface ControllerManagerListIds_elasticSearchPages_controllerManager_data {
  edges: ControllerManagerListIds_elasticSearchPages_controllerManager_data_edges[];
}

export interface ControllerManagerListIds_elasticSearchPages_controllerManager {
  data: ControllerManagerListIds_elasticSearchPages_controllerManager_data;
}

export interface ControllerManagerListIds_elasticSearchPages {
  controllerManager: ControllerManagerListIds_elasticSearchPages_controllerManager;
}

export interface ControllerManagerListIds {
  elasticSearchPages: ControllerManagerListIds_elasticSearchPages;
}

export interface ControllerManagerListIdsVariables {
  freeTextQuery?: string[] | null;
  filters?: string | null;
  sortDirection: SortDirection;
  sortProperty?: ControllersSortEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateControllerlistDownloadExcel
// ====================================================

export interface GenerateControllerlistDownloadExcel_elasticSearchPages_controllerManager {
  excelSheet: string;
}

export interface GenerateControllerlistDownloadExcel_elasticSearchPages {
  controllerManager: GenerateControllerlistDownloadExcel_elasticSearchPages_controllerManager;
}

export interface GenerateControllerlistDownloadExcel {
  elasticSearchPages: GenerateControllerlistDownloadExcel_elasticSearchPages;
}

export interface GenerateControllerlistDownloadExcelVariables {
  sortProperty?: ControllersSortEnum | null;
  sortDirection?: SortDirection | null;
  freeTextQuery?: string[] | null;
  filters?: string | null;
  columnKeyAndTitle: ColumnKeyAndTitle[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DecommissionedControllers
// ====================================================

export interface DecommissionedControllers {
  decommissionedControllers: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSites
// ====================================================

export interface createSites_createSitesFromControllers {
  success: boolean;
}

export interface createSites {
  createSitesFromControllers: createSites_createSitesFromControllers;
}

export interface createSitesVariables {
  controllerIds: number[];
  buidId: number;
  customerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetControllerBySerial
// ====================================================

export interface GetControllerBySerial_controllerBySerial {
  controllerId: string | null;
}

export interface GetControllerBySerial {
  controllerBySerial: GetControllerBySerial_controllerBySerial | null;
}

export interface GetControllerBySerialVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddNewController
// ====================================================

export interface AddNewController_addController {
  controllerId: string | null;
  serial: string;
}

export interface AddNewController {
  addController: AddNewController_addController;
}

export interface AddNewControllerVariables {
  input: ControllerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getServiceNowCreateSiteTicket
// ====================================================

export interface getServiceNowCreateSiteTicket_getServiceNowCreateSiteTicket_content {
  ticketNumber: string | null;
  serviceNowUrl: string | null;
  onBehalfOf: string | null;
  siteName: string | null;
  controllerSerial: string | null;
  buid: string | null;
  buidId: number | null;
  soldTo: string | null;
  shipTo: string | null;
  product: string | null;
  productId: number | null;
  tankCapacity: string | null;
  tankMinimum: string | null;
  tankMaximum: string | null;
  externalUsersWithAccount: string | null;
  yaraUsers: string | null;
}

export interface getServiceNowCreateSiteTicket_getServiceNowCreateSiteTicket {
  code: string;
  success: boolean;
  message: string | null;
  content: getServiceNowCreateSiteTicket_getServiceNowCreateSiteTicket_content | null;
}

export interface getServiceNowCreateSiteTicket {
  getServiceNowCreateSiteTicket: getServiceNowCreateSiteTicket_getServiceNowCreateSiteTicket | null;
}

export interface getServiceNowCreateSiteTicketVariables {
  ticketNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSiteFromServiceNowTicket
// ====================================================

export interface createSiteFromServiceNowTicket_createSiteFromServiceNowTicket_content {
  name: string;
  siteId: string;
}

export interface createSiteFromServiceNowTicket_createSiteFromServiceNowTicket {
  code: string;
  success: boolean;
  message: string | null;
  content: createSiteFromServiceNowTicket_createSiteFromServiceNowTicket_content | null;
}

export interface createSiteFromServiceNowTicket {
  createSiteFromServiceNowTicket: createSiteFromServiceNowTicket_createSiteFromServiceNowTicket | null;
}

export interface createSiteFromServiceNowTicketVariables {
  createSiteRequest: CreateSiteFromServiceNowTicketInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetUnassignedControllerBySerial
// ====================================================

export interface GetUnassignedControllerBySerial_unassignedControllerBySerial_controller {
  controllerId: string | null;
  controllerType: string;
}

export interface GetUnassignedControllerBySerial_unassignedControllerBySerial {
  controller: GetUnassignedControllerBySerial_unassignedControllerBySerial_controller | null;
}

export interface GetUnassignedControllerBySerial {
  unassignedControllerBySerial: GetUnassignedControllerBySerial_unassignedControllerBySerial | null;
}

export interface GetUnassignedControllerBySerialVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetSiteByShipTo
// ====================================================

export interface GetSiteByShipTo_siteByShipTo {
  name: string | null;
  siteId: number | null;
}

export interface GetSiteByShipTo {
  siteByShipTo: GetSiteByShipTo_siteByShipTo | null;
}

export interface GetSiteByShipToVariables {
  shipTo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetCustomerBySoldTo
// ====================================================

export interface GetCustomerBySoldTo_getCustomerBySoldTo_content {
  name: string | null;
  customerId: string;
}

export interface GetCustomerBySoldTo_getCustomerBySoldTo {
  code: string;
  success: boolean;
  message: string | null;
  content: (GetCustomerBySoldTo_getCustomerBySoldTo_content | null)[] | null;
}

export interface GetCustomerBySoldTo {
  getCustomerBySoldTo: GetCustomerBySoldTo_getCustomerBySoldTo | null;
}

export interface GetCustomerBySoldToVariables {
  soldTo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShipToWithAddressBySoldToQuery
// ====================================================

export interface GetShipToWithAddressBySoldToQuery_getShipToWithAddress {
  shipTo: string;
  street: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
}

export interface GetShipToWithAddressBySoldToQuery {
  getShipToWithAddress: GetShipToWithAddressBySoldToQuery_getShipToWithAddress[];
}

export interface GetShipToWithAddressBySoldToQueryVariables {
  soldTo: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getServiceNowDeviceReplacementTicket
// ====================================================

export interface getServiceNowDeviceReplacementTicket_getServiceNowDeviceReplacementTicket_content {
  ticketNumber: string | null;
  serviceNowUrl: string | null;
  onBehalfOf: string | null;
  currentDeviceType: string | null;
  currentDeviceSerial: string | null;
  newDeviceType: string | null;
  newDeviceSerial: string | null;
  shouldPreserveCurrentConfig: boolean | null;
  shouldTerminateSim: boolean | null;
  isNewDevicePlacedAndPowered: boolean | null;
  buid: string | null;
  notes: string | null;
}

export interface getServiceNowDeviceReplacementTicket_getServiceNowDeviceReplacementTicket {
  code: string;
  success: boolean;
  message: string | null;
  content: getServiceNowDeviceReplacementTicket_getServiceNowDeviceReplacementTicket_content | null;
}

export interface getServiceNowDeviceReplacementTicket {
  getServiceNowDeviceReplacementTicket: getServiceNowDeviceReplacementTicket_getServiceNowDeviceReplacementTicket | null;
}

export interface getServiceNowDeviceReplacementTicketVariables {
  ticketNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkDeviceReplacementAllowedTerminateSim
// ====================================================

export interface checkDeviceReplacementAllowedTerminateSim_checkDeviceReplacementAllowedTerminateSim {
  code: string;
  success: boolean;
  message: string;
  warnings: (string | null)[] | null;
}

export interface checkDeviceReplacementAllowedTerminateSim {
  checkDeviceReplacementAllowedTerminateSim: checkDeviceReplacementAllowedTerminateSim_checkDeviceReplacementAllowedTerminateSim;
}

export interface checkDeviceReplacementAllowedTerminateSimVariables {
  currentControllerSerial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkDeviceReplacmentAllowedPreserveConfig
// ====================================================

export interface checkDeviceReplacmentAllowedPreserveConfig_checkDeviceReplacmentAllowedPreserveConfig_content {
  isPreserveConfigAllowed: boolean | null;
  isTakeNewBackupAllowed: boolean | null;
  isUseExistingBackupAllowed: boolean | null;
  lastBackupAt: any | null;
  lastBackupId: number | null;
}

export interface checkDeviceReplacmentAllowedPreserveConfig_checkDeviceReplacmentAllowedPreserveConfig {
  code: string;
  success: boolean;
  message: string | null;
  warnings: (string | null)[] | null;
  content: checkDeviceReplacmentAllowedPreserveConfig_checkDeviceReplacmentAllowedPreserveConfig_content | null;
}

export interface checkDeviceReplacmentAllowedPreserveConfig {
  checkDeviceReplacmentAllowedPreserveConfig: checkDeviceReplacmentAllowedPreserveConfig_checkDeviceReplacmentAllowedPreserveConfig;
}

export interface checkDeviceReplacmentAllowedPreserveConfigVariables {
  checkPreserveConfigRequest: CheckPreserveConfigRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkIfControllerIsOnline
// ====================================================

export interface checkIfControllerIsOnline_checkIfControllerIsOnline {
  code: string;
  success: boolean;
  message: string;
}

export interface checkIfControllerIsOnline {
  checkIfControllerIsOnline: checkIfControllerIsOnline_checkIfControllerIsOnline;
}

export interface checkIfControllerIsOnlineVariables {
  controllerSerial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetControllerAndTypeBySerial
// ====================================================

export interface GetControllerAndTypeBySerial_controllerBySerial_site {
  siteId: string;
}

export interface GetControllerAndTypeBySerial_controllerBySerial {
  controllerId: string | null;
  name: string | null;
  controllerType: string;
  timeZoneId: number | null;
  site: GetControllerAndTypeBySerial_controllerBySerial_site | null;
}

export interface GetControllerAndTypeBySerial {
  controllerBySerial: GetControllerAndTypeBySerial_controllerBySerial | null;
}

export interface GetControllerAndTypeBySerialVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerBySerial
// ====================================================

export interface ControllerBySerial_controllerBySerial_site {
  name: string;
}

export interface ControllerBySerial_controllerBySerial_simDetail {
  ipAddress: string | null;
  icc: string | null;
  msisdn: string | null;
}

export interface ControllerBySerial_controllerBySerial {
  controllerId: string | null;
  serial: string;
  controllerType: string;
  name: string | null;
  siteId: number | null;
  timeZoneId: number | null;
  site: ControllerBySerial_controllerBySerial_site | null;
  simDetail: ControllerBySerial_controllerBySerial_simDetail | null;
  currentRunningScriptName: string | null;
  lastContact: string | null;
  lat: number | null;
  lng: number | null;
  accuracy: number | null;
}

export interface ControllerBySerial {
  controllerBySerial: ControllerBySerial_controllerBySerial | null;
}

export interface ControllerBySerialVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnqueueDeviceReplacementJob
// ====================================================

export interface EnqueueDeviceReplacementJob_enqueueDeviceReplacement_content {
  deviceReplacementJobId: number;
}

export interface EnqueueDeviceReplacementJob_enqueueDeviceReplacement {
  code: string;
  success: boolean;
  message: string | null;
  content: EnqueueDeviceReplacementJob_enqueueDeviceReplacement_content | null;
}

export interface EnqueueDeviceReplacementJob {
  enqueueDeviceReplacement: EnqueueDeviceReplacementJob_enqueueDeviceReplacement | null;
}

export interface EnqueueDeviceReplacementJobVariables {
  deviceReplacementRequest: DeviceReplacementJobRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getDeviceReplacementJobStatus
// ====================================================

export interface getDeviceReplacementJobStatus_getDeviceReplacementJobStatus_content {
  jobId: number;
  oldControllerSerial: string;
  newControllerSerial: string;
  preserveConfigMode: PreserveConfigModeEnum;
  shouldTerminateSim: boolean;
  preserveConfigStatus: StateEnum | null;
  preserveConfigErrorMessage: string | null;
  deviceReplacementStatus: StateEnum | null;
  deviceReplacementErrorMessage: string | null;
  restoreBackupStatus: StateEnum | null;
  restoreBackupErrorMessage: string | null;
  terminateSimStatus: StateEnum | null;
  terminateSimErrorMessage: string | null;
  jobStatus: StateEnum | null;
}

export interface getDeviceReplacementJobStatus_getDeviceReplacementJobStatus {
  code: string;
  success: boolean;
  message: string | null;
  content: getDeviceReplacementJobStatus_getDeviceReplacementJobStatus_content | null;
}

export interface getDeviceReplacementJobStatus {
  getDeviceReplacementJobStatus: getDeviceReplacementJobStatus_getDeviceReplacementJobStatus | null;
}

export interface getDeviceReplacementJobStatusVariables {
  deviceReplacementJobId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getReplacedDeviceDetails
// ====================================================

export interface getReplacedDeviceDetails_getControllerBySerial_site {
  name: string;
  alias: string;
  siteId: string;
}

export interface getReplacedDeviceDetails_getControllerBySerial {
  controllerId: string | null;
  name: string | null;
  alias: string | null;
  site: getReplacedDeviceDetails_getControllerBySerial_site | null;
}

export interface getReplacedDeviceDetails {
  getControllerBySerial: getReplacedDeviceDetails_getControllerBySerial | null;
}

export interface getReplacedDeviceDetailsVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControllerManagerReceipt
// ====================================================

export interface ControllerManagerReceipt_controllers_site {
  alias: string;
  siteId: string;
}

export interface ControllerManagerReceipt_controllers {
  serial: string;
  alias: string | null;
  site: ControllerManagerReceipt_controllers_site | null;
}

export interface ControllerManagerReceipt {
  controllers: (ControllerManagerReceipt_controllers | null)[];
}

export interface ControllerManagerReceiptVariables {
  controllerIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: simpleControllerGrid
// ====================================================

export interface simpleControllerGrid_controllers_site_customer {
  alias: string | null;
}

export interface simpleControllerGrid_controllers_site {
  customer: simpleControllerGrid_controllers_site_customer | null;
}

export interface simpleControllerGrid_controllers_buid {
  name: string | null;
}

export interface simpleControllerGrid_controllers_createdByUser {
  name: string;
}

export interface simpleControllerGrid_controllers {
  serial: string;
  controllerType: string;
  alias: string | null;
  site: simpleControllerGrid_controllers_site | null;
  buid: simpleControllerGrid_controllers_buid | null;
  timeZoneId: number | null;
  lastSampleTime: string | null;
  created: string | null;
  createdByUser: simpleControllerGrid_controllers_createdByUser | null;
}

export interface simpleControllerGrid {
  controllers: (simpleControllerGrid_controllers | null)[];
}

export interface simpleControllerGridVariables {
  ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardQuery
// ====================================================

export interface DashboardQuery_currentUser_userSettings {
  hideDashboardWelcome: boolean;
  needConsent: boolean;
}

export interface DashboardQuery_currentUser {
  userSettings: DashboardQuery_currentUser_userSettings | null;
}

export interface DashboardQuery {
  currentUser: DashboardQuery_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PinnedSitesQuery
// ====================================================

export interface PinnedSitesQuery_currentUser_userSettings {
  hideDashboardWelcome: boolean;
  needConsent: boolean;
}

export interface PinnedSitesQuery_currentUser {
  userSettings: PinnedSitesQuery_currentUser_userSettings | null;
}

export interface PinnedSitesQuery_pinnedSites_channels {
  isParked: boolean | null;
}

export interface PinnedSitesQuery_pinnedSites_alarms {
  alarmId: string | null;
  active: boolean | null;
}

export interface PinnedSitesQuery_pinnedSites_controllers {
  isVirual: boolean | null;
  controllerType: string;
  controllerId: string | null;
}

export interface PinnedSitesQuery_pinnedSites_tanks_product_translations {
  translation: string | null;
  languageCode: string | null;
}

export interface PinnedSitesQuery_pinnedSites_tanks_product {
  productId: string;
  name: string | null;
  translations: PinnedSitesQuery_pinnedSites_tanks_product_translations[];
}

export interface PinnedSitesQuery_pinnedSites_tanks_unit {
  decimals: number;
  symbol: string;
  unitId: string;
}

export interface PinnedSitesQuery_pinnedSites_tanks_tankDetails {
  shipTo: string | null;
  reachMinimum: string | null;
  percentage: number | null;
}

export interface PinnedSitesQuery_pinnedSites_tanks {
  maximum: number | null;
  minimum: number | null;
  capacity: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  product: PinnedSitesQuery_pinnedSites_tanks_product | null;
  unit: PinnedSitesQuery_pinnedSites_tanks_unit | null;
  tankDetails: PinnedSitesQuery_pinnedSites_tanks_tankDetails | null;
}

export interface PinnedSitesQuery_pinnedSites {
  siteId: string;
  signal: SignalEnum;
  channels: PinnedSitesQuery_pinnedSites_channels[];
  alarms: PinnedSitesQuery_pinnedSites_alarms[];
  controllers: PinnedSitesQuery_pinnedSites_controllers[];
  alias: string;
  tanks: PinnedSitesQuery_pinnedSites_tanks[];
}

export interface PinnedSitesQuery_totalSiteCount_sitelist_data {
  totalCount: number;
}

export interface PinnedSitesQuery_totalSiteCount_sitelist {
  data: PinnedSitesQuery_totalSiteCount_sitelist_data;
}

export interface PinnedSitesQuery_totalSiteCount {
  sitelist: PinnedSitesQuery_totalSiteCount_sitelist;
}

export interface PinnedSitesQuery {
  currentUser: PinnedSitesQuery_currentUser;
  pinnedSites: PinnedSitesQuery_pinnedSites[];
  totalSiteCount: PinnedSitesQuery_totalSiteCount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChannelIdQuickSearch
// ====================================================

export interface ChannelIdQuickSearch_channelByChannelId_controller {
  controllerId: string | null;
  siteId: number | null;
}

export interface ChannelIdQuickSearch_channelByChannelId {
  alias: string;
  channelType: number | null;
  channelId: string;
  controller: ChannelIdQuickSearch_channelByChannelId_controller;
}

export interface ChannelIdQuickSearch {
  channelByChannelId: ChannelIdQuickSearch_channelByChannelId | null;
}

export interface ChannelIdQuickSearchVariables {
  channelId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerRefQuickSearch
// ====================================================

export interface CustomerRefQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimum: string | null;
}

export interface CustomerRefQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels {
  lastSample: number | null;
  percentage: number | null;
  minimum: number | null;
  maximum: number | null;
  capacity: number | null;
  lastSampleTime: string | null;
  tankDetails: CustomerRefQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
}

export interface CustomerRefQuickSearch_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  alias: string;
  tankChannels: CustomerRefQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
}

export interface CustomerRefQuickSearch_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: CustomerRefQuickSearch_elasticSearchPages_sitelist_data_edges[];
}

export interface CustomerRefQuickSearch_elasticSearchPages_sitelist {
  data: CustomerRefQuickSearch_elasticSearchPages_sitelist_data;
}

export interface CustomerRefQuickSearch_elasticSearchPages {
  sitelist: CustomerRefQuickSearch_elasticSearchPages_sitelist;
}

export interface CustomerRefQuickSearch {
  elasticSearchPages: CustomerRefQuickSearch_elasticSearchPages;
}

export interface CustomerRefQuickSearchVariables {
  filter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveryNoteQuickSearch
// ====================================================

export interface DeliveryNoteQuickSearch_deliveryNotes_edges {
  siteName: string | null;
  fileName: string | null;
}

export interface DeliveryNoteQuickSearch_deliveryNotes {
  totalCount: number;
  edges: DeliveryNoteQuickSearch_deliveryNotes_edges[];
}

export interface DeliveryNoteQuickSearch {
  deliveryNotes: DeliveryNoteQuickSearch_deliveryNotes;
}

export interface DeliveryNoteQuickSearchVariables {
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FreetextQuickSearch
// ====================================================

export interface FreetextQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimum: string | null;
}

export interface FreetextQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels {
  lastSample: number | null;
  percentage: number | null;
  minimum: number | null;
  maximum: number | null;
  capacity: number | null;
  lastSampleTime: string | null;
  tankDetails: FreetextQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
}

export interface FreetextQuickSearch_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  alias: string;
  tankChannels: FreetextQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
}

export interface FreetextQuickSearch_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: FreetextQuickSearch_elasticSearchPages_sitelist_data_edges[];
}

export interface FreetextQuickSearch_elasticSearchPages_sitelist {
  data: FreetextQuickSearch_elasticSearchPages_sitelist_data;
}

export interface FreetextQuickSearch_elasticSearchPages {
  sitelist: FreetextQuickSearch_elasticSearchPages_sitelist;
}

export interface FreetextQuickSearch {
  elasticSearchPages: FreetextQuickSearch_elasticSearchPages;
}

export interface FreetextQuickSearchVariables {
  freeTextQuery?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShipToQuickSearch
// ====================================================

export interface ShipToQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ShipToQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels {
  lastSample: number | null;
  percentage: number | null;
  minimum: number | null;
  maximum: number | null;
  capacity: number | null;
  lastSampleTime: string | null;
  tankDetails: ShipToQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
}

export interface ShipToQuickSearch_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  alias: string;
  tankChannels: ShipToQuickSearch_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
}

export interface ShipToQuickSearch_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: ShipToQuickSearch_elasticSearchPages_sitelist_data_edges[];
}

export interface ShipToQuickSearch_elasticSearchPages_sitelist {
  data: ShipToQuickSearch_elasticSearchPages_sitelist_data;
}

export interface ShipToQuickSearch_elasticSearchPages {
  sitelist: ShipToQuickSearch_elasticSearchPages_sitelist;
}

export interface ShipToQuickSearch {
  elasticSearchPages: ShipToQuickSearch_elasticSearchPages;
}

export interface ShipToQuickSearchVariables {
  filter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SoldToQuickSearch
// ====================================================

export interface SoldToQuickSearch_elasticSearchPages_sitelist_data_edges_customer {
  name: string;
  customerId: number;
}

export interface SoldToQuickSearch_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  customer: SoldToQuickSearch_elasticSearchPages_sitelist_data_edges_customer | null;
  alias: string;
}

export interface SoldToQuickSearch_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: SoldToQuickSearch_elasticSearchPages_sitelist_data_edges[];
}

export interface SoldToQuickSearch_elasticSearchPages_sitelist {
  data: SoldToQuickSearch_elasticSearchPages_sitelist_data;
}

export interface SoldToQuickSearch_elasticSearchPages {
  sitelist: SoldToQuickSearch_elasticSearchPages_sitelist;
}

export interface SoldToQuickSearch {
  elasticSearchPages: SoldToQuickSearch_elasticSearchPages;
}

export interface SoldToQuickSearchVariables {
  filter?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShortcutsQuery
// ====================================================

export interface ShortcutsQuery_elasticSearchPages_onlineSitesCount_data {
  totalCount: number;
}

export interface ShortcutsQuery_elasticSearchPages_onlineSitesCount {
  data: ShortcutsQuery_elasticSearchPages_onlineSitesCount_data;
}

export interface ShortcutsQuery_elasticSearchPages_offlineSitesCount_data {
  totalCount: number;
}

export interface ShortcutsQuery_elasticSearchPages_offlineSitesCount {
  data: ShortcutsQuery_elasticSearchPages_offlineSitesCount_data;
}

export interface ShortcutsQuery_elasticSearchPages_parkedSitesCount_data {
  totalCount: number;
}

export interface ShortcutsQuery_elasticSearchPages_parkedSitesCount {
  data: ShortcutsQuery_elasticSearchPages_parkedSitesCount_data;
}

export interface ShortcutsQuery_elasticSearchPages_virtualSitesCount_data {
  totalCount: number;
}

export interface ShortcutsQuery_elasticSearchPages_virtualSitesCount {
  data: ShortcutsQuery_elasticSearchPages_virtualSitesCount_data;
}

export interface ShortcutsQuery_elasticSearchPages_activeAlarmsSitesCount_data {
  totalCount: number;
}

export interface ShortcutsQuery_elasticSearchPages_activeAlarmsSitesCount {
  data: ShortcutsQuery_elasticSearchPages_activeAlarmsSitesCount_data;
}

export interface ShortcutsQuery_elasticSearchPages {
  onlineSitesCount: ShortcutsQuery_elasticSearchPages_onlineSitesCount;
  offlineSitesCount: ShortcutsQuery_elasticSearchPages_offlineSitesCount;
  parkedSitesCount: ShortcutsQuery_elasticSearchPages_parkedSitesCount;
  virtualSitesCount: ShortcutsQuery_elasticSearchPages_virtualSitesCount;
  activeAlarmsSitesCount: ShortcutsQuery_elasticSearchPages_activeAlarmsSitesCount;
}

export interface ShortcutsQuery {
  elasticSearchPages: ShortcutsQuery_elasticSearchPages;
}

export interface ShortcutsQueryVariables {
  onlineSitesFilter?: string | null;
  offlineSitesFilter?: string | null;
  parkedSitesFilter?: string | null;
  virtualSitesFilter?: string | null;
  activeAlarmsSitesFilter?: string | null;
  onlineSitesCountVisible: boolean;
  offlineSitesCountVisible: boolean;
  parkedSitesCountVisible: boolean;
  virtualSitesCountVisible: boolean;
  activeAlarmsSitesCountVisible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeviceSimMarriageWidget
// ====================================================

export interface DeviceSimMarriageWidget_deviceSimMarriageWidget {
  buid: string;
  count: number;
  deviceType: string;
}

export interface DeviceSimMarriageWidget {
  deviceSimMarriageWidget: DeviceSimMarriageWidget_deviceSimMarriageWidget[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DataSourcesWidgetData
// ====================================================

export interface DataSourcesWidgetData_dataSourcesWidgetData_buid {
  name: string | null;
  buidId: string | null;
}

export interface DataSourcesWidgetData_dataSourcesWidgetData {
  buid: DataSourcesWidgetData_dataSourcesWidgetData_buid | null;
  controllerType: string | null;
  count: number;
}

export interface DataSourcesWidgetData {
  dataSourcesWidgetData: DataSourcesWidgetData_dataSourcesWidgetData[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveriesLastWidgetQuery
// ====================================================

export interface DeliveriesLastWidgetQuery_deliveryNoteStatistics {
  amountDelivered: number;
  deliveries: number;
  key: string;
}

export interface DeliveriesLastWidgetQuery {
  deliveryNoteStatistics: DeliveriesLastWidgetQuery_deliveryNoteStatistics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveriesWidgetQuery
// ====================================================

export interface DeliveriesWidgetQuery_deliveryNoteStatistics {
  buid: string | null;
  amountDelivered: number;
  deliveries: number;
  key: string;
}

export interface DeliveriesWidgetQuery {
  deliveryNoteStatistics: DeliveriesWidgetQuery_deliveryNoteStatistics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdaxLifecycleWidgetData
// ====================================================

export interface IdaxLifecycleWidgetData_idaxLifecycleWidget {
  buid: string;
  buidId: string | null;
  shipped: number;
  active: number;
  activated: number;
  decommissioned: number;
  idle: number;
  total: number;
}

export interface IdaxLifecycleWidgetData {
  idaxLifecycleWidget: IdaxLifecycleWidgetData_idaxLifecycleWidget[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NewSitesWidgetQuery
// ====================================================

export interface NewSitesWidgetQuery_newSitesWidget_buids {
  name: string;
  count: number;
}

export interface NewSitesWidgetQuery_newSitesWidget {
  month: string;
  count: number;
  buids: NewSitesWidgetQuery_newSitesWidget_buids[];
}

export interface NewSitesWidgetQuery {
  newSitesWidget: NewSitesWidgetQuery_newSitesWidget[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SitesRecentlyViewedWidgetsQuery
// ====================================================

export interface SitesRecentlyViewedWidgetsQuery_elasticSearchPages_sitelist_data_edges {
  alias: string;
  siteId: string;
}

export interface SitesRecentlyViewedWidgetsQuery_elasticSearchPages_sitelist_data {
  edges: SitesRecentlyViewedWidgetsQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface SitesRecentlyViewedWidgetsQuery_elasticSearchPages_sitelist {
  data: SitesRecentlyViewedWidgetsQuery_elasticSearchPages_sitelist_data;
}

export interface SitesRecentlyViewedWidgetsQuery_elasticSearchPages {
  sitelist: SitesRecentlyViewedWidgetsQuery_elasticSearchPages_sitelist;
}

export interface SitesRecentlyViewedWidgetsQuery {
  elasticSearchPages: SitesRecentlyViewedWidgetsQuery_elasticSearchPages;
}

export interface SitesRecentlyViewedWidgetsQueryVariables {
  filters: string;
  numberToShow: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TankLowLevelWidgetQuery
// ====================================================

export interface TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimum: string | null;
}

export interface TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels {
  siteChannelId: number;
  percentage: number | null;
  lastSample: number | null;
  maximum: number | null;
  minimum: number | null;
  capacity: number | null;
  lastSampleTime: string | null;
  tankDetails: TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
}

export interface TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data_edges {
  alias: string;
  siteId: string;
  tankChannels: TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
}

export interface TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data {
  edges: TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface TankLowLevelWidgetQuery_elasticSearchPages_sitelist {
  data: TankLowLevelWidgetQuery_elasticSearchPages_sitelist_data;
}

export interface TankLowLevelWidgetQuery_elasticSearchPages {
  sitelist: TankLowLevelWidgetQuery_elasticSearchPages_sitelist;
}

export interface TankLowLevelWidgetQuery {
  elasticSearchPages: TankLowLevelWidgetQuery_elasticSearchPages;
}

export interface TankLowLevelWidgetQueryVariables {
  sortDirection: SortDirection;
  sortProperty: number;
  numberToShow: number;
  filters: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TankReachMinimumWidgetQuery
// ====================================================

export interface TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimumSpan: number | null;
}

export interface TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels {
  siteChannelId: number;
  tankDetails: TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
}

export interface TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data_edges {
  alias: string;
  siteId: string;
  tankChannels: TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
}

export interface TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data {
  edges: TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface TankReachMinimumWidgetQuery_elasticSearchPages_sitelist {
  data: TankReachMinimumWidgetQuery_elasticSearchPages_sitelist_data;
}

export interface TankReachMinimumWidgetQuery_elasticSearchPages {
  sitelist: TankReachMinimumWidgetQuery_elasticSearchPages_sitelist;
}

export interface TankReachMinimumWidgetQuery {
  elasticSearchPages: TankReachMinimumWidgetQuery_elasticSearchPages;
}

export interface TankReachMinimumWidgetQueryVariables {
  sortDirection: SortDirection;
  sortProperty: number;
  numberToShow: number;
  filters: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserStatisticsDashboardWidgetQuery
// ====================================================

export interface UserStatisticsDashboardWidgetQuery_reports_userStatistics_widget {
  totalInternal: number | null;
  totalExternal: number | null;
  activeInternal: number | null;
  activeExternal: number | null;
  pendingDeletionInternal: number | null;
  pendingDeletionExternal: number | null;
  deletedInternal: number | null;
  deletedExternal: number | null;
  userManagerAccessInternal: number | null;
  userManagerAccessExternal: number | null;
}

export interface UserStatisticsDashboardWidgetQuery_reports_userStatistics {
  widget: UserStatisticsDashboardWidgetQuery_reports_userStatistics_widget;
}

export interface UserStatisticsDashboardWidgetQuery_reports {
  userStatistics: UserStatisticsDashboardWidgetQuery_reports_userStatistics;
}

export interface UserStatisticsDashboardWidgetQuery {
  reports: UserStatisticsDashboardWidgetQuery_reports;
}

export interface UserStatisticsDashboardWidgetQueryVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveryNotesList
// ====================================================

export interface DeliveryNotesList_deliveryNotes_edges {
  tankChannelRefillAttachmentId: string | null;
  siteName: string | null;
  siteId: number | null;
  siteChannelId: number | null;
  customerName: string | null;
  buid: string | null;
  productName: string | null;
  refillDate: string | null;
  shipTo: string | null;
  amountFilled: number | null;
  confirmed: boolean | null;
  source: string | null;
  fileName: string | null;
  attachmentUrl: string | null;
  refillReference: string | null;
  sapName: string | null;
  sapAddress: string | null;
  sapCity: string | null;
  sapPostCode: string | null;
  haulierTag: string | null;
  countryCode: string | null;
  soldTo: string | null;
  productAlias_deDE: string | null;
  productAlias_enGB: string | null;
  productAlias_esES: string | null;
  productAlias_frFR: string | null;
  productAlias_huHU: string | null;
  productAlias_itIT: string | null;
  productAlias_nbNO: string | null;
  productAlias_nlNL: string | null;
  productAlias_plPL: string | null;
  productAlias_svSE: string | null;
}

export interface DeliveryNotesList_deliveryNotes {
  totalCount: number;
  edges: DeliveryNotesList_deliveryNotes_edges[];
}

export interface DeliveryNotesList {
  deliveryNotes: DeliveryNotesList_deliveryNotes;
}

export interface DeliveryNotesListVariables {
  offset: number;
  first: number;
  sortProperty: number;
  sortDirection?: SortDirection | null;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeliveryNotesAttachments
// ====================================================

export interface DeliveryNotesAttachments {
  deliveryNotesAttachments: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExampleListQuery
// ====================================================

export interface ExampleListQuery_elasticSearchPages_sitelist_data_edges {
  alias: string;
  addressLine1: string | null;
}

export interface ExampleListQuery_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: ExampleListQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface ExampleListQuery_elasticSearchPages_sitelist {
  data: ExampleListQuery_elasticSearchPages_sitelist_data;
}

export interface ExampleListQuery_elasticSearchPages {
  sitelist: ExampleListQuery_elasticSearchPages_sitelist;
}

export interface ExampleListQuery {
  elasticSearchPages: ExampleListQuery_elasticSearchPages;
}

export interface ExampleListQueryVariables {
  offset: number;
  first: number;
  sortProperty: number;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExampleQuery
// ====================================================

export interface ExampleQuery_currentUser_user {
  name: string;
}

export interface ExampleQuery_currentUser {
  user: ExampleQuery_currentUser_user | null;
}

export interface ExampleQuery {
  currentUser: ExampleQuery_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllExportJobs
// ====================================================

export interface AllExportJobs_exportJobs {
  exportJobId: string;
  name: string | null;
  exportTypeEnum: number;
  dateFormat: string | null;
  repeatMinutes: number | null;
  fixedSchedule: string | null;
  enabled: boolean | null;
  notRunRecently: boolean | null;
  lastResult: string | null;
  lastRun: string | null;
  contact: string | null;
  description: string | null;
}

export interface AllExportJobs {
  exportJobs: AllExportJobs_exportJobs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportJobViewInfo
// ====================================================

export interface ExportJobViewInfo_exportJob_customers {
  name: string | null;
  customerId: string;
}

export interface ExportJobViewInfo_exportJob_exportedSiteChannels_unit {
  symbol: string;
}

export interface ExportJobViewInfo_exportJob_exportedSiteChannels_tankDetails {
  shipTo: string | null;
  customerProductRef: string | null;
}

export interface ExportJobViewInfo_exportJob_exportedSiteChannels_product {
  name: string | null;
}

export interface ExportJobViewInfo_exportJob_exportedSiteChannels_controller_site {
  siteId: string;
  name: string;
  alias: string;
  projectCode: string | null;
  customerRef: string | null;
}

export interface ExportJobViewInfo_exportJob_exportedSiteChannels_controller {
  serial: string;
  site: ExportJobViewInfo_exportJob_exportedSiteChannels_controller_site | null;
}

export interface ExportJobViewInfo_exportJob_exportedSiteChannels {
  unit: ExportJobViewInfo_exportJob_exportedSiteChannels_unit | null;
  tankDetails: ExportJobViewInfo_exportJob_exportedSiteChannels_tankDetails | null;
  lastSampleTime: string | null;
  lastSample: number | null;
  siteChannelId: number;
  channelId: number;
  code: string;
  product: ExportJobViewInfo_exportJob_exportedSiteChannels_product | null;
  alias: string;
  controller: ExportJobViewInfo_exportJob_exportedSiteChannels_controller;
}

export interface ExportJobViewInfo_exportJob_fields {
  exportFieldId: number;
  name: string;
  order: number;
  field: number;
}

export interface ExportJobViewInfo_exportJob {
  exportJobId: string;
  destination: string | null;
  destinationFilePattern: string | null;
  includeHeaders: boolean | null;
  name: string | null;
  exportTypeEnum: number;
  dateFormat: string | null;
  delimiterChar: string | null;
  repeatMinutes: number | null;
  fixedSchedule: string | null;
  enabled: boolean | null;
  description: string | null;
  contact: string | null;
  customers: ExportJobViewInfo_exportJob_customers[] | null;
  channelTypes: number[] | null;
  exportedSiteChannels: (ExportJobViewInfo_exportJob_exportedSiteChannels | null)[];
  notRunRecently: boolean | null;
  lastResult: string | null;
  lastRun: string | null;
  fields: (ExportJobViewInfo_exportJob_fields | null)[] | null;
}

export interface ExportJobViewInfo_exportFields {
  field: number;
  name: string;
}

export interface ExportJobViewInfo {
  exportJob: ExportJobViewInfo_exportJob | null;
  exportFields: (ExportJobViewInfo_exportFields | null)[] | null;
}

export interface ExportJobViewInfoVariables {
  exportJobId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LatestExportJobRuns
// ====================================================

export interface LatestExportJobRuns_exportJobRuns {
  exportJobId: string;
  success: boolean;
  result: string | null;
  blobRef: string | null;
  timestamp: string;
  sasReadUrl: string | null;
}

export interface LatestExportJobRuns {
  exportJobRuns: LatestExportJobRuns_exportJobRuns[];
}

export interface LatestExportJobRunsVariables {
  exportJobId: number;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertExportJob
// ====================================================

export interface UpsertExportJob {
  upsertExportJob: string | null;
}

export interface UpsertExportJobVariables {
  exportJobInput: ExportJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateExportFields
// ====================================================

export interface UpdateExportFields_updateExportFields {
  code: string;
  message: string;
}

export interface UpdateExportFields {
  updateExportFields: UpdateExportFields_updateExportFields;
}

export interface UpdateExportFieldsVariables {
  updateData: ExportFieldUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunExport
// ====================================================

export interface RunExport_runExport {
  success: boolean;
  message: string;
}

export interface RunExport {
  runExport: RunExport_runExport;
}

export interface RunExportVariables {
  exportJobId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HaulierManagerQuery
// ====================================================

export interface HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges_buid {
  id: number;
  name: string;
}

export interface HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges {
  haulierId: number;
  dataSource: string;
  reference: string;
  name: string;
  shortName: string;
  haulierTag: string;
  buid: HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges_buid | null;
}

export interface HaulierManagerQuery_elasticSearchPages_haulierManager_data {
  totalCount: number;
  edges: HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges[];
}

export interface HaulierManagerQuery_elasticSearchPages_haulierManager {
  data: HaulierManagerQuery_elasticSearchPages_haulierManager_data;
}

export interface HaulierManagerQuery_elasticSearchPages {
  haulierManager: HaulierManagerQuery_elasticSearchPages_haulierManager;
}

export interface HaulierManagerQuery {
  elasticSearchPages: HaulierManagerQuery_elasticSearchPages;
}

export interface HaulierManagerQueryVariables {
  first: number;
  offset: number;
  freeTextQuery?: string[] | null;
  sortProperty: HaulierSortEnum;
  sortDirection: SortDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateHaulierExcelQuery
// ====================================================

export interface GenerateHaulierExcelQuery_elasticSearchPages_haulierManager {
  excelSheet: string;
}

export interface GenerateHaulierExcelQuery_elasticSearchPages {
  haulierManager: GenerateHaulierExcelQuery_elasticSearchPages_haulierManager;
}

export interface GenerateHaulierExcelQuery {
  elasticSearchPages: GenerateHaulierExcelQuery_elasticSearchPages;
}

export interface GenerateHaulierExcelQueryVariables {
  sortProperty?: HaulierSortEnum | null;
  sortDirection?: SortDirection | null;
  freeTextQuery?: string[] | null;
  columnKeyAndTitle: ColumnKeyAndTitle[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HaulierManagerListIdsQuery
// ====================================================

export interface HaulierManagerListIdsQuery_elasticSearchPages_haulierManager_data_edges {
  haulierId: number;
}

export interface HaulierManagerListIdsQuery_elasticSearchPages_haulierManager_data {
  edges: HaulierManagerListIdsQuery_elasticSearchPages_haulierManager_data_edges[];
}

export interface HaulierManagerListIdsQuery_elasticSearchPages_haulierManager {
  data: HaulierManagerListIdsQuery_elasticSearchPages_haulierManager_data;
}

export interface HaulierManagerListIdsQuery_elasticSearchPages {
  haulierManager: HaulierManagerListIdsQuery_elasticSearchPages_haulierManager;
}

export interface HaulierManagerListIdsQuery {
  elasticSearchPages: HaulierManagerListIdsQuery_elasticSearchPages;
}

export interface HaulierManagerListIdsQueryVariables {
  freeTextQuery?: string[] | null;
  sortProperty: HaulierSortEnum;
  sortDirection: SortDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHauliers
// ====================================================

export interface DeleteHauliers {
  deleteHauliers: boolean | null;
}

export interface DeleteHauliersVariables {
  haulierIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddNewHaulier
// ====================================================

export interface AddNewHaulier_upsertHaulier {
  code: string;
  success: boolean;
  message: string;
}

export interface AddNewHaulier {
  upsertHaulier: AddNewHaulier_upsertHaulier;
}

export interface AddNewHaulierVariables {
  input: UpsertHaulierInput;
  haulierId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HaulierDataSourceDropdownQuery
// ====================================================

export interface HaulierDataSourceDropdownQuery_refFilterValues {
  key: string;
  label: string;
  instances: number;
}

export interface HaulierDataSourceDropdownQuery {
  refFilterValues: HaulierDataSourceDropdownQuery_refFilterValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditMultipleHauliers
// ====================================================

export interface EditMultipleHauliers_upsertMultipleHauliers {
  code: string;
  success: boolean;
  message: string;
}

export interface EditMultipleHauliers {
  upsertMultipleHauliers: EditMultipleHauliers_upsertMultipleHauliers;
}

export interface EditMultipleHauliersVariables {
  input: UpsertMultipleHaulierInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllImportJobs
// ====================================================

export interface AllImportJobs_importJobs {
  importJobId: number | null;
  name: string | null;
  importTypeId: number | null;
  status: string | null;
  repeatMinutes: number | null;
  settings: string | null;
  scheduleType: string | null;
  enabled: boolean | null;
  lastRun: any | null;
  notRunRecently: boolean | null;
  importTypeName: string | null;
  description: string | null;
  contact: string | null;
  lastResult: string | null;
  success: boolean | null;
  lastDuration: string | null;
}

export interface AllImportJobs {
  importJobs: AllImportJobs_importJobs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ImportJobInfo
// ====================================================

export interface ImportJobInfo_importJob {
  importJobId: number | null;
  name: string | null;
  importTypeId: number | null;
  status: string | null;
  repeatMinutes: number | null;
  settings: string | null;
  scheduleType: string | null;
  enabled: boolean | null;
  lastRun: any | null;
  notRunRecently: boolean | null;
  importTypeName: string | null;
  description: string | null;
  contact: string | null;
  lastResult: string | null;
  success: boolean | null;
  lastDuration: string | null;
}

export interface ImportJobInfo {
  importJob: ImportJobInfo_importJob;
}

export interface ImportJobInfoVariables {
  importJobId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ImportJobRuns
// ====================================================

export interface ImportJobRuns_importJobRuns {
  importJobRunId: number | null;
  importJobId: number | null;
  timeStamp: string | null;
  message: string | null;
  success: boolean | null;
  blobRefId: string | null;
  sasReadUrl: string | null;
}

export interface ImportJobRuns {
  importJobRuns: ImportJobRuns_importJobRuns[];
}

export interface ImportJobRunsVariables {
  importJobId: number;
  amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ImportJobTypes
// ====================================================

export interface ImportJobTypes_importJobTypes {
  importTypeId: number | null;
  name: string | null;
  description: string | null;
  schedulerType: number | null;
}

export interface ImportJobTypes {
  importJobTypes: ImportJobTypes_importJobTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateImportJob
// ====================================================

export interface UpdateImportJob_updateImportJob {
  importJobId: number | null;
  name: string | null;
  importTypeId: number | null;
  status: string | null;
  repeatMinutes: number | null;
  settings: string | null;
  scheduleType: string | null;
  enabled: boolean | null;
  lastRun: any | null;
  notRunRecently: boolean | null;
  importTypeName: string | null;
  description: string | null;
  contact: string | null;
  lastResult: string | null;
  success: boolean | null;
  lastDuration: string | null;
}

export interface UpdateImportJob {
  updateImportJob: UpdateImportJob_updateImportJob;
}

export interface UpdateImportJobVariables {
  importJob: ImportJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrderPlanner
// ====================================================

export interface OrderPlanner_siteChannels_edges_tankDetails {
  tankId: string | null;
  freeCapacity: number | null;
  reachEmpty: string | null;
  vehicleId: number | null;
}

export interface OrderPlanner_siteChannels_edges_site {
  siteId: string;
  alias: string;
  projectCode: string | null;
  soldTo: string | null;
}

export interface OrderPlanner_siteChannels_edges_unit {
  decimals: number;
  symbol: string;
}

export interface OrderPlanner_siteChannels_edges_product_staalduinenProductMap {
  goodsId: string;
}

export interface OrderPlanner_siteChannels_edges_product {
  density: number | null;
  name: string | null;
  staalduinenProductMap: OrderPlanner_siteChannels_edges_product_staalduinenProductMap | null;
}

export interface OrderPlanner_siteChannels_edges {
  siteId: number | null;
  isParked: boolean | null;
  tankDetails: OrderPlanner_siteChannels_edges_tankDetails | null;
  alias: string;
  capacity: number | null;
  siteChannelId: number;
  lastSample: number | null;
  site: OrderPlanner_siteChannels_edges_site | null;
  unit: OrderPlanner_siteChannels_edges_unit | null;
  product: OrderPlanner_siteChannels_edges_product | null;
}

export interface OrderPlanner_siteChannels {
  edges: OrderPlanner_siteChannels_edges[];
  totalCount: number;
}

export interface OrderPlanner {
  siteChannels: OrderPlanner_siteChannels;
}

export interface OrderPlannerVariables {
  siteChannelIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportOrderPlan
// ====================================================

export interface ExportOrderPlan_exportOrder {
  code: string;
  message: string;
  success: boolean;
}

export interface ExportOrderPlan {
  exportOrder: ExportOrderPlan_exportOrder;
}

export interface ExportOrderPlanVariables {
  order: Order[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoutePlanner
// ====================================================

export interface RoutePlanner_siteChannels_edges_tankDetails {
  tankId: string | null;
  freeCapacity: number | null;
  reachEmpty: string | null;
  vehicleId: number | null;
  shipTo: string | null;
}

export interface RoutePlanner_siteChannels_edges_site_customer {
  name: string | null;
}

export interface RoutePlanner_siteChannels_edges_site {
  alias: string;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  postcode: string | null;
  country: string | null;
  customerRef: string | null;
  customer: RoutePlanner_siteChannels_edges_site_customer | null;
}

export interface RoutePlanner_siteChannels_edges_unit {
  translationKey: string;
}

export interface RoutePlanner_siteChannels_edges_product {
  name: string | null;
}

export interface RoutePlanner_siteChannels_edges_controller {
  lat: number | null;
  lng: number | null;
}

export interface RoutePlanner_siteChannels_edges {
  tankDetails: RoutePlanner_siteChannels_edges_tankDetails | null;
  siteId: number | null;
  isParked: boolean | null;
  alias: string;
  capacity: number | null;
  siteChannelId: number;
  site: RoutePlanner_siteChannels_edges_site | null;
  unit: RoutePlanner_siteChannels_edges_unit | null;
  product: RoutePlanner_siteChannels_edges_product | null;
  controller: RoutePlanner_siteChannels_edges_controller;
}

export interface RoutePlanner_siteChannels {
  edges: RoutePlanner_siteChannels_edges[];
  totalCount: number;
}

export interface RoutePlanner {
  siteChannels: RoutePlanner_siteChannels;
}

export interface RoutePlannerVariables {
  siteIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductEditorDetailsQuery
// ====================================================

export interface ProductEditorDetailsQuery_product_translations {
  key: string | null;
  languageCode: string | null;
  translation: string | null;
}

export interface ProductEditorDetailsQuery_product_staalduinenProductMap_pickupPoint {
  code: string;
  name: string;
  staalduinenPickupPointId: string;
}

export interface ProductEditorDetailsQuery_product_staalduinenProductMap {
  goodsDescription: string;
  goodsId: string;
  pickupPoint: ProductEditorDetailsQuery_product_staalduinenProductMap_pickupPoint;
}

export interface ProductEditorDetailsQuery_product {
  code: string | null;
  color: string | null;
  density: number | null;
  description: string | null;
  languageKey: string | null;
  name: string | null;
  productId: string;
  translations: ProductEditorDetailsQuery_product_translations[];
  staalduinenProductMap: ProductEditorDetailsQuery_product_staalduinenProductMap | null;
}

export interface ProductEditorDetailsQuery {
  product: ProductEditorDetailsQuery_product | null;
}

export interface ProductEditorDetailsQueryVariables {
  productId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductEditorQuery
// ====================================================

export interface ProductEditorQuery_products_translations {
  key: string | null;
  languageCode: string | null;
  translation: string | null;
}

export interface ProductEditorQuery_products_staalduinenProductMap {
  goodsId: string;
}

export interface ProductEditorQuery_products {
  code: string | null;
  color: string | null;
  density: number | null;
  description: string | null;
  languageKey: string | null;
  name: string | null;
  productId: string;
  translations: ProductEditorQuery_products_translations[];
  staalduinenProductMap: ProductEditorQuery_products_staalduinenProductMap | null;
}

export interface ProductEditorQuery {
  products: ProductEditorQuery_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimStatusByControllerSerial
// ====================================================

export interface SimStatusByControllerSerial_simStatusByControllerSerial {
  simStatus: SimStatusEnum | null;
  controllerSerial: string | null;
  controllerType: string | null;
  controllerName: string | null;
  controllerId: number | null;
  siteName: string | null;
  siteId: number | null;
  buidId: number | null;
  buidName: string | null;
  lastContact: string | null;
  simIcc: string | null;
  simIpAddress: string | null;
  simMsisdn: string | null;
  simActivatedBy: string | null;
  simActivatedAt: string | null;
}

export interface SimStatusByControllerSerial {
  simStatusByControllerSerial: SimStatusByControllerSerial_simStatusByControllerSerial;
}

export interface SimStatusByControllerSerialVariables {
  serial: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnqueueSimOperation
// ====================================================

export interface EnqueueSimOperation_enqueueSimOperation {
  code: string;
  message: string;
  success: boolean;
}

export interface EnqueueSimOperation {
  enqueueSimOperation: EnqueueSimOperation_enqueueSimOperation | null;
}

export interface EnqueueSimOperationVariables {
  simOperation: SimOperation;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckSimOperationStatus
// ====================================================

export interface CheckSimOperationStatus_checkSimOperationStatus {
  icc: string | null;
  createdAt: string | null;
  finishedAt: string | null;
  sierraWirelessAction: number | null;
  state: string | null;
  operationId: string | null;
}

export interface CheckSimOperationStatus {
  checkSimOperationStatus: CheckSimOperationStatus_checkSimOperationStatus;
}

export interface CheckSimOperationStatusVariables {
  icc: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditableReleaseNoteQuery
// ====================================================

export interface EditableReleaseNoteQuery_releaseNotes_one_releaseNoteFolder {
  releaseNoteFolderId: number;
  folderPath: string;
}

export interface EditableReleaseNoteQuery_releaseNotes_one_blob {
  blobId: number;
  fileName: string;
  readUrl: string;
}

export interface EditableReleaseNoteQuery_releaseNotes_one {
  releaseNoteFileId: number;
  releaseNoteFolder: EditableReleaseNoteQuery_releaseNotes_one_releaseNoteFolder;
  blob: EditableReleaseNoteQuery_releaseNotes_one_blob;
  publishedAfter: string;
  lastModified: string;
}

export interface EditableReleaseNoteQuery_releaseNotes {
  one: EditableReleaseNoteQuery_releaseNotes_one;
}

export interface EditableReleaseNoteQuery {
  releaseNotes: EditableReleaseNoteQuery_releaseNotes | null;
}

export interface EditableReleaseNoteQueryVariables {
  releaseNoteFileId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReleaseNoteFile
// ====================================================

export interface UpdateReleaseNoteFile_updateReleaseNote {
  code: string;
  success: boolean;
  message: string;
}

export interface UpdateReleaseNoteFile {
  updateReleaseNote: UpdateReleaseNoteFile_updateReleaseNote;
}

export interface UpdateReleaseNoteFileVariables {
  input: UpdateReleaseNoteFileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishedReleaseNotesQuery
// ====================================================

export interface PublishedReleaseNotesQuery_releaseNotes_all_releaseNoteFolder {
  releaseNoteFolderId: number;
  folderPath: string;
}

export interface PublishedReleaseNotesQuery_releaseNotes_all_blob {
  blobId: number;
  fileName: string;
  size: number;
  readUrl: string;
  mimeType: string;
}

export interface PublishedReleaseNotesQuery_releaseNotes_all {
  releaseNoteFileId: number;
  releaseNoteFolder: PublishedReleaseNotesQuery_releaseNotes_all_releaseNoteFolder;
  blob: PublishedReleaseNotesQuery_releaseNotes_all_blob;
  publishedAfter: string;
  lastModified: string;
}

export interface PublishedReleaseNotesQuery_releaseNotes {
  all: PublishedReleaseNotesQuery_releaseNotes_all[];
}

export interface PublishedReleaseNotesQuery {
  releaseNotes: PublishedReleaseNotesQuery_releaseNotes | null;
}

export interface PublishedReleaseNotesQueryVariables {
  allFiles?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlarmReport
// ====================================================

export interface AlarmReport_reports_alarm_data {
  siteId: string | null;
  alarmType: number;
  siteName: string | null;
  channelName: string | null;
  customerName: string | null;
  triggered: string | null;
  alarmDuration: number | null;
}

export interface AlarmReport_reports_alarm {
  data: AlarmReport_reports_alarm_data[];
}

export interface AlarmReport_reports {
  alarm: AlarmReport_reports_alarm;
}

export interface AlarmReport {
  reports: AlarmReport_reports;
}

export interface AlarmReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlarmReportDownload
// ====================================================

export interface AlarmReportDownload_reports_alarm {
  blobUrl: string;
}

export interface AlarmReportDownload_reports {
  alarm: AlarmReportDownload_reports_alarm;
}

export interface AlarmReportDownload {
  reports: AlarmReportDownload_reports;
}

export interface AlarmReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeviceTypeReportQuery
// ====================================================

export interface DeviceTypeReportQuery_reports_deviceType_data {
  controllerType: string | null;
  buid: string | null;
  count: number | null;
}

export interface DeviceTypeReportQuery_reports_deviceType {
  data: DeviceTypeReportQuery_reports_deviceType_data[];
}

export interface DeviceTypeReportQuery_reports {
  deviceType: DeviceTypeReportQuery_reports_deviceType;
}

export interface DeviceTypeReportQuery {
  reports: DeviceTypeReportQuery_reports;
}

export interface DeviceTypeReportQueryVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeviceTypeReportDownload
// ====================================================

export interface DeviceTypeReportDownload_reports_deviceType {
  blobUrl: string;
}

export interface DeviceTypeReportDownload_reports {
  deviceType: DeviceTypeReportDownload_reports_deviceType;
}

export interface DeviceTypeReportDownload {
  reports: DeviceTypeReportDownload_reports;
}

export interface DeviceTypeReportDownloadVariables {
  input: ScheduledReportFilter;
  sortProperties?: ExcelReportSortingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DosingReport
// ====================================================

export interface DosingReport_reports_dosing_data_data_productData {
  productId: string;
  languageKey: string | null;
  name: string | null;
}

export interface DosingReport_reports_dosing_data_data {
  siteId: number;
  alias: string | null;
  buid: string | null;
  accessTag: string | null;
  productData: DosingReport_reports_dosing_data_data_productData | null;
  doseAvg: number | null;
  previousDoseAvg: number | null;
  doseAvgUnit: string | null;
  doseSum: number | null;
  previousDoseSum: number | null;
  doseSumUnit: string | null;
  deviation: number | null;
  channelId: number | null;
  siteChannelId: number | null;
  channelAlias: string | null;
}

export interface DosingReport_reports_dosing_data {
  fromDate: string | null;
  toDate: string | null;
  data: DosingReport_reports_dosing_data_data[];
}

export interface DosingReport_reports_dosing {
  data: DosingReport_reports_dosing_data;
}

export interface DosingReport_reports {
  dosing: DosingReport_reports_dosing;
}

export interface DosingReport {
  reports: DosingReport_reports;
}

export interface DosingReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DosingReportDownload
// ====================================================

export interface DosingReportDownload_reports_dosing {
  blobUrl: string;
}

export interface DosingReportDownload_reports {
  dosing: DosingReportDownload_reports_dosing;
}

export interface DosingReportDownload {
  reports: DosingReportDownload_reports;
}

export interface DosingReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DryRunReport
// ====================================================

export interface DryRunReport_reports_dryRun_data {
  tankRefillAttachmentId: number | null;
  accessTagId: number | null;
  accessTagName: string | null;
  channelId: number;
  customerRef: string | null;
  dryRunDuration: number | null;
  dryRunStartTime: any;
  productId: number | null;
  productLanguageKey: string | null;
  refillTimeStamp: any;
  shipTo: string | null;
  siteId: number | null;
  siteAlias: string;
  siteChannelId: number;
  soldTo: string | null;
  lostSalesAmount: number | null;
}

export interface DryRunReport_reports_dryRun {
  data: DryRunReport_reports_dryRun_data[];
}

export interface DryRunReport_reports {
  dryRun: DryRunReport_reports_dryRun;
}

export interface DryRunReport {
  reports: DryRunReport_reports;
}

export interface DryRunReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DryRunReportDownload
// ====================================================

export interface DryRunReportDownload_reports_dryRun {
  blobUrl: string;
}

export interface DryRunReportDownload_reports {
  dryRun: DryRunReportDownload_reports_dryRun;
}

export interface DryRunReportDownload {
  reports: DryRunReportDownload_reports;
}

export interface DryRunReportDownloadVariables {
  input: ScheduledReportFilter;
  sortProperties?: DryRunReportSortingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdaxDeliveriesReport
// ====================================================

export interface IdaxDeliveriesReport_reports_idaDeliveries_data {
  buid: string | null;
  buidId: string | null;
  companyName: string | null;
  receivingPlace: string | null;
  amountIda11: number;
  amountIda211: number;
  amountTotal: number;
}

export interface IdaxDeliveriesReport_reports_idaDeliveries {
  data: IdaxDeliveriesReport_reports_idaDeliveries_data[];
}

export interface IdaxDeliveriesReport_reports {
  idaDeliveries: IdaxDeliveriesReport_reports_idaDeliveries;
}

export interface IdaxDeliveriesReport {
  reports: IdaxDeliveriesReport_reports;
}

export interface IdaxDeliveriesReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdaxDeliveriesReportDownload
// ====================================================

export interface IdaxDeliveriesReportDownload_reports_idaDeliveries {
  blobUrl: string;
}

export interface IdaxDeliveriesReportDownload_reports {
  idaDeliveries: IdaxDeliveriesReportDownload_reports_idaDeliveries;
}

export interface IdaxDeliveriesReportDownload {
  reports: IdaxDeliveriesReportDownload_reports;
}

export interface IdaxDeliveriesReportDownloadVariables {
  input: ScheduledReportFilter;
  sortProperties?: IdaDeliveriesReportSortingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdaxLifecycleReport
// ====================================================

export interface IdaxLifecycleReport_reports_idaLifecycle_data {
  buid: string | null;
  controllerType: string | null;
  shipped: number;
  activated: number;
  active: number;
  decommissioned: number;
  idle: number;
}

export interface IdaxLifecycleReport_reports_idaLifecycle {
  data: IdaxLifecycleReport_reports_idaLifecycle_data[];
}

export interface IdaxLifecycleReport_reports {
  idaLifecycle: IdaxLifecycleReport_reports_idaLifecycle;
}

export interface IdaxLifecycleReport {
  reports: IdaxLifecycleReport_reports;
}

export interface IdaxLifecycleReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdaxLifecycleReportDownload
// ====================================================

export interface IdaxLifecycleReportDownload_reports_idaLifecycle {
  blobUrl: string;
}

export interface IdaxLifecycleReportDownload_reports {
  idaLifecycle: IdaxLifecycleReportDownload_reports_idaLifecycle;
}

export interface IdaxLifecycleReportDownload {
  reports: IdaxLifecycleReportDownload_reports;
}

export interface IdaxLifecycleReportDownloadVariables {
  input: ScheduledReportFilter;
  sortProperties?: IdaLifecycleReportSortingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NitratesReport
// ====================================================

export interface NitratesReport_reports_nitratesReport_data {
  alias: string;
  siteId: number;
  tankLevel: number | null;
  waterFlowAverageM3PerHour: number | null;
  waterFlowAverageM3PerDay: number | null;
  waterTemperatureAverage: number | null;
  doseLitersPerHour: number | null;
  doseLitersPerDay: number | null;
  doseGapPercentage: number | null;
  consumptionCalculatedLitersMonth: number | null;
  consumptionRealLitersMonth: number | null;
  consumptionYearToDate: number | null;
  refillCountsMonth: number | null;
  refillCountsYearToDate: number | null;
}

export interface NitratesReport_reports_nitratesReport {
  data: NitratesReport_reports_nitratesReport_data[];
}

export interface NitratesReport_reports {
  nitratesReport: NitratesReport_reports_nitratesReport;
}

export interface NitratesReport {
  reports: NitratesReport_reports;
}

export interface NitratesReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NitratesReportDownload
// ====================================================

export interface NitratesReportDownload_reports_nitratesReport {
  blobUrl: string;
}

export interface NitratesReportDownload_reports {
  nitratesReport: NitratesReportDownload_reports_nitratesReport;
}

export interface NitratesReportDownload {
  reports: NitratesReportDownload_reports;
}

export interface NitratesReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductStockReport
// ====================================================

export interface ProductStockReport_reports_productStock_data_product {
  name: string | null;
  languageKey: string | null;
}

export interface ProductStockReport_reports_productStock_data_unit {
  translationKey: string;
  decimals: number;
  symbol: string;
  sapCode: string;
}

export interface ProductStockReport_reports_productStock_data {
  alias: string | null;
  customerName: string | null;
  address: string | null;
  product: ProductStockReport_reports_productStock_data_product | null;
  shipTo: string | null;
  soldTo: string | null;
  customerRef: string | null;
  sampleTime: string | null;
  value: number | null;
  channelAlias: string | null;
  unit: ProductStockReport_reports_productStock_data_unit | null;
}

export interface ProductStockReport_reports_productStock {
  data: ProductStockReport_reports_productStock_data[];
}

export interface ProductStockReport_reports {
  productStock: ProductStockReport_reports_productStock;
}

export interface ProductStockReport {
  reports: ProductStockReport_reports;
}

export interface ProductStockReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductStockReportDownload
// ====================================================

export interface ProductStockReportDownload_reports_productStock {
  blobUrl: string;
}

export interface ProductStockReportDownload_reports {
  productStock: ProductStockReportDownload_reports_productStock;
}

export interface ProductStockReportDownload {
  reports: ProductStockReportDownload_reports;
}

export interface ProductStockReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteRefillsReport
// ====================================================

export interface SiteRefillsReport_reports_siteRefills_data {
  site: string | null;
  siteId: number | null;
  shipTo: string | null;
  refillStart: string;
  litres: number | null;
  product: string | null;
  timezoneId: number | null;
}

export interface SiteRefillsReport_reports_siteRefills {
  data: (SiteRefillsReport_reports_siteRefills_data | null)[];
}

export interface SiteRefillsReport_reports {
  siteRefills: SiteRefillsReport_reports_siteRefills;
}

export interface SiteRefillsReport {
  reports: SiteRefillsReport_reports;
}

export interface SiteRefillsReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteRefillsReportDownload
// ====================================================

export interface SiteRefillsReportDownload_reports_siteRefills {
  blobUrl: string;
}

export interface SiteRefillsReportDownload_reports {
  siteRefills: SiteRefillsReportDownload_reports_siteRefills;
}

export interface SiteRefillsReportDownload {
  reports: SiteRefillsReportDownload_reports;
}

export interface SiteRefillsReportDownloadVariables {
  input: ScheduledReportFilter;
  sortProperties?: SiteRefillsReportSortingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TemperatureReport
// ====================================================

export interface TemperatureReport_reports_temperature_data_unit {
  decimals: number;
  symbol: string;
  unitId: string;
}

export interface TemperatureReport_reports_temperature_data {
  siteId: number;
  alias: string | null;
  buid: string | null;
  accessTag: string | null;
  channelId: number | null;
  siteChannelId: number | null;
  channelAlias: string | null;
  thisPeriod: number | null;
  previousPeriod: number | null;
  deviation: number | null;
  unit: TemperatureReport_reports_temperature_data_unit | null;
}

export interface TemperatureReport_reports_temperature {
  data: TemperatureReport_reports_temperature_data[];
}

export interface TemperatureReport_reports {
  temperature: TemperatureReport_reports_temperature;
}

export interface TemperatureReport {
  reports: TemperatureReport_reports;
}

export interface TemperatureReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TemperatureReportDownload
// ====================================================

export interface TemperatureReportDownload_reports_temperature {
  blobUrl: string;
}

export interface TemperatureReportDownload_reports {
  temperature: TemperatureReportDownload_reports_temperature;
}

export interface TemperatureReportDownload {
  reports: TemperatureReportDownload_reports;
}

export interface TemperatureReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserStatisticsReport
// ====================================================

export interface UserStatisticsReport_reports_userStatistics_data {
  buid: string | null;
  total: number | null;
  internal: number | null;
  external: number | null;
  active: number | null;
  pendingDeletion: number | null;
  neverLoggedIn: number | null;
  deleted: number | null;
  prolonged: number | null;
  highSecurityLevel: number | null;
  mediumSecurityLevel: number | null;
  lowSecurityLevel: number | null;
  noneSecurityLevel: number | null;
  userManagerAccess: number | null;
}

export interface UserStatisticsReport_reports_userStatistics {
  data: UserStatisticsReport_reports_userStatistics_data[];
}

export interface UserStatisticsReport_reports {
  userStatistics: UserStatisticsReport_reports_userStatistics;
}

export interface UserStatisticsReport {
  reports: UserStatisticsReport_reports;
}

export interface UserStatisticsReportVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserStatisticsReportDownload
// ====================================================

export interface UserStatisticsReportDownload_reports_userStatistics {
  blobUrl: string;
}

export interface UserStatisticsReportDownload_reports {
  userStatistics: UserStatisticsReportDownload_reports_userStatistics;
}

export interface UserStatisticsReportDownload {
  reports: UserStatisticsReportDownload_reports;
}

export interface UserStatisticsReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DailyDoseReportQuery
// ====================================================

export interface DailyDoseReportQuery_reports_dailyDose_data {
  accessTag: string;
  buid: string;
  channelAlias: string;
  doseDate: any;
  doseValue: number;
  doseUnit: string;
  siteAlias: string;
  siteId: number;
}

export interface DailyDoseReportQuery_reports_dailyDose {
  data: DailyDoseReportQuery_reports_dailyDose_data[];
}

export interface DailyDoseReportQuery_reports {
  dailyDose: DailyDoseReportQuery_reports_dailyDose;
}

export interface DailyDoseReportQuery {
  reports: DailyDoseReportQuery_reports;
}

export interface DailyDoseReportQueryVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DailyDoseReportDownload
// ====================================================

export interface DailyDoseReportDownload_reports_dailyDose {
  blobUrl: string;
}

export interface DailyDoseReportDownload_reports {
  dailyDose: DailyDoseReportDownload_reports_dailyDose;
}

export interface DailyDoseReportDownload {
  reports: DailyDoseReportDownload_reports;
}

export interface DailyDoseReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactReportProductDropdownQuery
// ====================================================

export interface ReactReportProductDropdownQuery_reports_filters_products {
  productId: string;
  name: string | null;
  languageKey: string | null;
}

export interface ReactReportProductDropdownQuery_reports_filters {
  products: ReactReportProductDropdownQuery_reports_filters_products[];
}

export interface ReactReportProductDropdownQuery_reports {
  filters: ReactReportProductDropdownQuery_reports_filters;
}

export interface ReactReportProductDropdownQuery {
  reports: ReactReportProductDropdownQuery_reports;
}

export interface ReactReportProductDropdownQueryVariables {
  report: Reports;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportProductDropdownQuery
// ====================================================

export interface ReportProductDropdownQuery_reports_filters_products {
  productId: string;
  name: string | null;
}

export interface ReportProductDropdownQuery_reports_filters {
  products: ReportProductDropdownQuery_reports_filters_products[];
}

export interface ReportProductDropdownQuery_reports {
  filters: ReportProductDropdownQuery_reports_filters;
}

export interface ReportProductDropdownQuery {
  reports: ReportProductDropdownQuery_reports;
}

export interface ReportProductDropdownQueryVariables {
  report: Reports;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactReportFiltersCardQuery
// ====================================================

export interface ReactReportFiltersCardQuery_scheduledReport_reportSchedule {
  scheduleName: string | null;
  reportScheduleId: string | null;
  scheduleType: ScheduleTypeEnum;
  systemReportRecipients: string | null;
}

export interface ReactReportFiltersCardQuery_scheduledReport_buidFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactReportFiltersCardQuery_scheduledReport_accessTagFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactReportFiltersCardQuery_scheduledReport_controllerTypeFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactReportFiltersCardQuery_scheduledReport_soldToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactReportFiltersCardQuery_scheduledReport_shipToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactReportFiltersCardQuery_scheduledReport_productFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactReportFiltersCardQuery_scheduledReport {
  reportPeriod: ReportPeriodEnum;
  reportScheduleId: number;
  reportSchedule: ReactReportFiltersCardQuery_scheduledReport_reportSchedule | null;
  filterId: number | null;
  reportFormat: ReportFormatEnum | null;
  alarmType: string | null;
  buidFilters: ReactReportFiltersCardQuery_scheduledReport_buidFilters[] | null;
  accessTagFilters: ReactReportFiltersCardQuery_scheduledReport_accessTagFilters[] | null;
  controllerTypeFilters: ReactReportFiltersCardQuery_scheduledReport_controllerTypeFilters[] | null;
  soldToFilters: ReactReportFiltersCardQuery_scheduledReport_soldToFilters[] | null;
  shipToFilters: ReactReportFiltersCardQuery_scheduledReport_shipToFilters[] | null;
  productFilters: ReactReportFiltersCardQuery_scheduledReport_productFilters[] | null;
}

export interface ReactReportFiltersCardQuery_products_translation {
  translation: string | null;
}

export interface ReactReportFiltersCardQuery_products {
  code: string | null;
  productId: string;
  name: string | null;
  translation: ReactReportFiltersCardQuery_products_translation;
}

export interface ReactReportFiltersCardQuery_buids {
  buidId: string | null;
  name: string | null;
}

export interface ReactReportFiltersCardQuery_customers {
  customerId: string;
  name: string | null;
}

export interface ReactReportFiltersCardQuery {
  scheduledReport: ReactReportFiltersCardQuery_scheduledReport | null;
  products: ReactReportFiltersCardQuery_products[];
  buids: ReactReportFiltersCardQuery_buids[];
  customers: ReactReportFiltersCardQuery_customers[];
}

export interface ReactReportFiltersCardQueryVariables {
  scheduledReportId: string;
  accessToAllBuids: boolean;
  accessToAllAccessTags: boolean;
  languageCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReactUpsertScheduledReport
// ====================================================

export interface ReactUpsertScheduledReport_upsertScheduledReport_reportSchedule_scheduledReports {
  filterId: number | null;
  report: Reports;
  reportPeriod: ReportPeriodEnum;
  reportScheduleId: number;
  scheduledReportId: string | null;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_reportSchedule {
  scheduleName: string | null;
  reportScheduleId: string | null;
  scheduledReports: ReactUpsertScheduledReport_upsertScheduledReport_reportSchedule_scheduledReports[];
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_buidFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_accessTagFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_controllerTypeFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_productFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_shipToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport_soldToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReactUpsertScheduledReport_upsertScheduledReport {
  reportPeriod: ReportPeriodEnum;
  reportSchedule: ReactUpsertScheduledReport_upsertScheduledReport_reportSchedule | null;
  alarmType: string | null;
  filterId: number | null;
  reportFormat: ReportFormatEnum | null;
  buidFilters: ReactUpsertScheduledReport_upsertScheduledReport_buidFilters[] | null;
  accessTagFilters: ReactUpsertScheduledReport_upsertScheduledReport_accessTagFilters[] | null;
  controllerTypeFilters: ReactUpsertScheduledReport_upsertScheduledReport_controllerTypeFilters[] | null;
  productFilters: ReactUpsertScheduledReport_upsertScheduledReport_productFilters[] | null;
  shipToFilters: ReactUpsertScheduledReport_upsertScheduledReport_shipToFilters[] | null;
  soldToFilters: ReactUpsertScheduledReport_upsertScheduledReport_soldToFilters[] | null;
  reportScheduleId: number;
  scheduledReportId: string | null;
}

export interface ReactUpsertScheduledReport {
  upsertScheduledReport: ReactUpsertScheduledReport_upsertScheduledReport | null;
}

export interface ReactUpsertScheduledReportVariables {
  input: UpsertScheduledReportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactReportSchedulesDropdownQuery
// ====================================================

export interface ReactReportSchedulesDropdownQuery_reportSchedules {
  reportScheduleId: string | null;
  scheduleName: string | null;
  scheduleType: ScheduleTypeEnum;
  recurrenceDetails: number | null;
  recurrence: ReportScheduleRecurrence;
  enabled: boolean | null;
}

export interface ReactReportSchedulesDropdownQuery {
  reportSchedules: ReactReportSchedulesDropdownQuery_reportSchedules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportSchedulesDropdownQuery
// ====================================================

export interface ReportSchedulesDropdownQuery_reportSchedules {
  reportScheduleId: string | null;
  scheduleName: string | null;
  recurrenceDetails: number | null;
  recurrence: ReportScheduleRecurrence;
  enabled: boolean | null;
}

export interface ReportSchedulesDropdownQuery {
  reportSchedules: ReportSchedulesDropdownQuery_reportSchedules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NewSitesReportQuery
// ====================================================

export interface NewSitesReportQuery_reports_newSites_data_newSites {
  siteId: number;
  buid: string | null;
  accessTag: string | null;
  alias: string | null;
  created: string | null;
}

export interface NewSitesReportQuery_reports_newSites_data_assignedControllers {
  siteId: number;
  siteAlias: string | null;
  controllerId: number;
  alias: string | null;
  controllerType: string | null;
  serial: string | null;
  alternateSerial: string | null;
  buid: string | null;
  accessTag: string | null;
  created: string | null;
}

export interface NewSitesReportQuery_reports_newSites_data_unassignedControllers {
  controllerId: number;
  alias: string | null;
  controllerType: string | null;
  serial: string | null;
  alternateSerial: string | null;
  created: string | null;
}

export interface NewSitesReportQuery_reports_newSites_data_parkedSites {
  siteId: number;
  buid: string | null;
  accessTag: string | null;
  alias: string | null;
  created: string | null;
  parkedSince: string | null;
}

export interface NewSitesReportQuery_reports_newSites_data {
  newSites: NewSitesReportQuery_reports_newSites_data_newSites[];
  assignedControllers: NewSitesReportQuery_reports_newSites_data_assignedControllers[];
  unassignedControllers: NewSitesReportQuery_reports_newSites_data_unassignedControllers[];
  parkedSites: NewSitesReportQuery_reports_newSites_data_parkedSites[];
}

export interface NewSitesReportQuery_reports_newSites {
  data: NewSitesReportQuery_reports_newSites_data;
}

export interface NewSitesReportQuery_reports {
  newSites: NewSitesReportQuery_reports_newSites;
}

export interface NewSitesReportQuery {
  reports: NewSitesReportQuery_reports;
}

export interface NewSitesReportQueryVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NewSitesReportDownload
// ====================================================

export interface NewSitesReportDownload_reports_newSites {
  blobUrl: string;
}

export interface NewSitesReportDownload_reports {
  newSites: NewSitesReportDownload_reports_newSites;
}

export interface NewSitesReportDownload {
  reports: NewSitesReportDownload_reports;
}

export interface NewSitesReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StockVsDoseReportDataQuery
// ====================================================

export interface StockVsDoseReportDataQuery_reports_stockVsDose_data_doseCalibration {
  accumulatedDose: number | null;
  alarmDuration: number;
  alias: string;
  deviation: number | null;
  tankDrop: number | null;
}

export interface StockVsDoseReportDataQuery_reports_stockVsDose_data_offline {
  alias: string;
  offlineDuration: number;
}

export interface StockVsDoseReportDataQuery_reports_stockVsDose_data_online {
  accumulatedDose: number | null;
  alias: string;
  deviation: number | null;
  tankDrop: number | null;
}

export interface StockVsDoseReportDataQuery_reports_stockVsDose_data_seriousDoseCalibration {
  accumulatedDose: number | null;
  alarmDuration: number;
  alias: string;
  deviation: number | null;
  tankDrop: number | null;
}

export interface StockVsDoseReportDataQuery_reports_stockVsDose_data {
  siteCount: number;
  doseCalibration: StockVsDoseReportDataQuery_reports_stockVsDose_data_doseCalibration[];
  offline: StockVsDoseReportDataQuery_reports_stockVsDose_data_offline[];
  online: StockVsDoseReportDataQuery_reports_stockVsDose_data_online[];
  seriousDoseCalibration: StockVsDoseReportDataQuery_reports_stockVsDose_data_seriousDoseCalibration[];
}

export interface StockVsDoseReportDataQuery_reports_stockVsDose {
  data: StockVsDoseReportDataQuery_reports_stockVsDose_data;
}

export interface StockVsDoseReportDataQuery_reports {
  stockVsDose: StockVsDoseReportDataQuery_reports_stockVsDose;
}

export interface StockVsDoseReportDataQuery {
  reports: StockVsDoseReportDataQuery_reports;
}

export interface StockVsDoseReportDataQueryVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StockVsDoseReportDownload
// ====================================================

export interface StockVsDoseReportDownload_reports_stockVsDose {
  blobUrl: string;
}

export interface StockVsDoseReportDownload_reports {
  stockVsDose: StockVsDoseReportDownload_reports_stockVsDose;
}

export interface StockVsDoseReportDownload {
  reports: StockVsDoseReportDownload_reports;
}

export interface StockVsDoseReportDownloadVariables {
  input: ScheduledReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScheduledReportsList
// ====================================================

export interface ScheduledReportsList_reportSchedules_scheduledReports {
  report: Reports;
}

export interface ScheduledReportsList_reportSchedules {
  enabled: boolean | null;
  lastDone: string | null;
  lastTrigger: string | null;
  nextTrigger: string | null;
  recurrence: ReportScheduleRecurrence;
  recurrenceDetails: number | null;
  reportScheduleId: string | null;
  scheduleName: string | null;
  triggerTimeLocal: string;
  timeZoneId: number;
  systemReportRecipients: string | null;
  scheduleType: ScheduleTypeEnum;
  scheduledReports: ScheduledReportsList_reportSchedules_scheduledReports[];
}

export interface ScheduledReportsList {
  reportSchedules: ScheduledReportsList_reportSchedules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportScheduleQuery
// ====================================================

export interface ReportScheduleQuery_reportSchedule_scheduledReports_filter {
  filterId: string;
  name: string;
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports_productFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
  translationKeys: string[];
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports_buidFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports_controllerTypeFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports_accessTagFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports_soldToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports_shipToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleQuery_reportSchedule_scheduledReports {
  reportFormat: ReportFormatEnum | null;
  filterId: number | null;
  filter: ReportScheduleQuery_reportSchedule_scheduledReports_filter | null;
  productFilters: ReportScheduleQuery_reportSchedule_scheduledReports_productFilters[] | null;
  buidFilters: ReportScheduleQuery_reportSchedule_scheduledReports_buidFilters[] | null;
  controllerTypeFilters: ReportScheduleQuery_reportSchedule_scheduledReports_controllerTypeFilters[] | null;
  accessTagFilters: ReportScheduleQuery_reportSchedule_scheduledReports_accessTagFilters[] | null;
  soldToFilters: ReportScheduleQuery_reportSchedule_scheduledReports_soldToFilters[] | null;
  shipToFilters: ReportScheduleQuery_reportSchedule_scheduledReports_shipToFilters[] | null;
  scheduledReportId: string | null;
  reportPeriod: ReportPeriodEnum;
  report: Reports;
}

export interface ReportScheduleQuery_reportSchedule {
  reportScheduleId: string | null;
  enabled: boolean | null;
  recurrence: ReportScheduleRecurrence;
  recurrenceDetails: number | null;
  scheduleName: string | null;
  triggerTimeLocal: string;
  timeZoneId: number;
  systemReportRecipients: string | null;
  scheduleType: ScheduleTypeEnum;
  scheduledReports: ReportScheduleQuery_reportSchedule_scheduledReports[];
}

export interface ReportScheduleQuery {
  reportSchedule: ReportScheduleQuery_reportSchedule | null;
}

export interface ReportScheduleQueryVariables {
  scheduleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteScheduleReportMutation
// ====================================================

export interface DeleteScheduleReportMutation {
  deleteScheduledReport: boolean | null;
}

export interface DeleteScheduleReportMutationVariables {
  scheduleReportId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReportScheduleMutation
// ====================================================

export interface DeleteReportScheduleMutation {
  deleteReportSchedule: boolean | null;
}

export interface DeleteReportScheduleMutationVariables {
  reportScheduleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveReportScheduleDetailsMutation
// ====================================================

export interface SaveReportScheduleDetailsMutation_upsertReportSchedule {
  reportScheduleId: string | null;
  scheduleName: string | null;
  recurrence: ReportScheduleRecurrence;
  recurrenceDetails: number | null;
  enabled: boolean | null;
  triggerTimeLocal: string;
  timeZoneId: number;
}

export interface SaveReportScheduleDetailsMutation {
  upsertReportSchedule: SaveReportScheduleDetailsMutation_upsertReportSchedule | null;
}

export interface SaveReportScheduleDetailsMutationVariables {
  input: UpsertReportScheduleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScriptConfigHistory
// ====================================================

export interface ScriptConfigHistory_scriptConfigHistory_items {
  configChanged: string;
  fileName: string;
  scriptConfigHistoryId: number;
  readUrl: string;
}

export interface ScriptConfigHistory_scriptConfigHistory {
  name: string;
  items: ScriptConfigHistory_scriptConfigHistory_items[];
}

export interface ScriptConfigHistory {
  scriptConfigHistory: ScriptConfigHistory_scriptConfigHistory;
}

export interface ScriptConfigHistoryVariables {
  scriptConfigId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteScriptConfigHistory
// ====================================================

export interface deleteScriptConfigHistory_deleteScriptConfigHistory {
  success: boolean;
}

export interface deleteScriptConfigHistory {
  deleteScriptConfigHistory: deleteScriptConfigHistory_deleteScriptConfigHistory;
}

export interface deleteScriptConfigHistoryVariables {
  scriptConfigHistoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scriptConfigs
// ====================================================

export interface scriptConfigs_scriptConfigs {
  lastConfigChanged: string;
  fileName: string;
  readUrl: string;
  scriptConfigId: number;
  scriptConfigName: string;
}

export interface scriptConfigs {
  scriptConfigs: scriptConfigs_scriptConfigs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteScriptConfig
// ====================================================

export interface deleteScriptConfig_deleteScriptConfig {
  success: boolean;
}

export interface deleteScriptConfig {
  deleteScriptConfig: deleteScriptConfig_deleteScriptConfig;
}

export interface deleteScriptConfigVariables {
  scriptConfigId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadScriptConfig
// ====================================================

export interface uploadScriptConfig_uploadScriptConfig {
  success: boolean;
  message: string;
}

export interface uploadScriptConfig {
  uploadScriptConfig: uploadScriptConfig_uploadScriptConfig;
}

export interface uploadScriptConfigVariables {
  fileName: string;
  jsonString: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DecommissionedControllersPage
// ====================================================

export interface DecommissionedControllersPage_elasticSearchPages_decommissionedControllers_data_edges_buid {
  name: string | null;
  id: number | null;
}

export interface DecommissionedControllersPage_elasticSearchPages_decommissionedControllers_data_edges {
  serial: string | null;
  alternateSerial: string | null;
  alias: string | null;
  controllerType: string | null;
  lastSampleTime: any | null;
  decommissioned: any | null;
  decommissionedBy: string | null;
  buid: DecommissionedControllersPage_elasticSearchPages_decommissionedControllers_data_edges_buid | null;
  ipAddress: string | null;
  icc: string | null;
  simCardType: string | null;
  simActive: boolean | null;
  provider: string | null;
  simNotes: string | null;
  decommissionReason: string | null;
  decommissionReasonText: string | null;
}

export interface DecommissionedControllersPage_elasticSearchPages_decommissionedControllers_data {
  totalCount: number;
  edges: DecommissionedControllersPage_elasticSearchPages_decommissionedControllers_data_edges[];
}

export interface DecommissionedControllersPage_elasticSearchPages_decommissionedControllers {
  data: DecommissionedControllersPage_elasticSearchPages_decommissionedControllers_data;
}

export interface DecommissionedControllersPage_elasticSearchPages {
  decommissionedControllers: DecommissionedControllersPage_elasticSearchPages_decommissionedControllers;
}

export interface DecommissionedControllersPage {
  elasticSearchPages: DecommissionedControllersPage_elasticSearchPages;
}

export interface DecommissionedControllersPageVariables {
  first?: number | null;
  offset?: number | null;
  sortProperty: DecommissionedControllersSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DecommissionedControllersExcel
// ====================================================

export interface DecommissionedControllersExcel_elasticSearchPages_decommissionedControllers {
  excelSheet: string;
}

export interface DecommissionedControllersExcel_elasticSearchPages {
  decommissionedControllers: DecommissionedControllersExcel_elasticSearchPages_decommissionedControllers;
}

export interface DecommissionedControllersExcel {
  elasticSearchPages: DecommissionedControllersExcel_elasticSearchPages;
}

export interface DecommissionedControllersExcelVariables {
  sortProperty: DecommissionedControllersSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
  columns: ColumnKeyAndTitle[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShippingLocations
// ====================================================

export interface ShippingLocations_shippingLocationMaps_shippingLocationMap_buid {
  buidId: string | null;
  name: string | null;
}

export interface ShippingLocations_shippingLocationMaps_shippingLocationMap {
  shippingLocationMapId: number | null;
  buid: ShippingLocations_shippingLocationMaps_shippingLocationMap_buid | null;
  companyName: string | null;
  receivingPlace: string | null;
}

export interface ShippingLocations_shippingLocationMaps {
  shippingLocationMap: ShippingLocations_shippingLocationMaps_shippingLocationMap[];
  availableCompanies: string[];
  availableReceivingPlaces: string[];
}

export interface ShippingLocations {
  shippingLocationMaps: ShippingLocations_shippingLocationMaps;
}

export interface ShippingLocationsVariables {
  first?: number | null;
  offset?: number | null;
  freeTextQuery?: string | null;
  sortProperty: ShippingLocationSortEnum;
  sortDirection: SortDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShippingLocationMap
// ====================================================

export interface ShippingLocationMap_shippingLocationMap_shippingLocationMap_buid {
  buidId: string | null;
  name: string | null;
}

export interface ShippingLocationMap_shippingLocationMap_shippingLocationMap {
  shippingLocationMapId: number | null;
  buid: ShippingLocationMap_shippingLocationMap_shippingLocationMap_buid | null;
  companyName: string | null;
  receivingPlace: string | null;
}

export interface ShippingLocationMap_shippingLocationMap {
  shippingLocationMap: ShippingLocationMap_shippingLocationMap_shippingLocationMap[];
  availableCompanies: string[];
  availableReceivingPlaces: string[];
}

export interface ShippingLocationMap {
  shippingLocationMap: ShippingLocationMap_shippingLocationMap;
}

export interface ShippingLocationMapVariables {
  shippingLocationMapId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveShippingLocationMap
// ====================================================

export interface SaveShippingLocationMap_saveShippingLocationMap {
  success: boolean;
}

export interface SaveShippingLocationMap {
  saveShippingLocationMap: SaveShippingLocationMap_saveShippingLocationMap;
}

export interface SaveShippingLocationMapVariables {
  shippingLocationMapId?: number | null;
  buidId: number;
  companyName?: string | null;
  receivingPlace?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteShippingLocationMap
// ====================================================

export interface DeleteShippingLocationMap_deleteShippingLocationMap {
  success: boolean;
}

export interface DeleteShippingLocationMap {
  deleteShippingLocationMap: DeleteShippingLocationMap_deleteShippingLocationMap;
}

export interface DeleteShippingLocationMapVariables {
  shippingLocationMapId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDataUsage
// ====================================================

export interface SimDataUsage_elasticSearchPages_simDataUsage_data_edges_buid {
  buidId: string | null;
  name: string | null;
}

export interface SimDataUsage_elasticSearchPages_simDataUsage_data_edges {
  simDetailsId: number | null;
  controllerId: number | null;
  controllerAlias: string | null;
  serial: string | null;
  alternateSerial: string | null;
  siteAlias: string | null;
  siteId: number | null;
  icc: string | null;
  msisdn: string | null;
  imei: string | null;
  supplier: string | null;
  ipAddress: string | null;
  usage: number | null;
  timestamp: string | null;
  monthId: number | null;
  simCardType: string | null;
  buid: SimDataUsage_elasticSearchPages_simDataUsage_data_edges_buid | null;
}

export interface SimDataUsage_elasticSearchPages_simDataUsage_data {
  edges: SimDataUsage_elasticSearchPages_simDataUsage_data_edges[];
  totalCount: number;
}

export interface SimDataUsage_elasticSearchPages_simDataUsage {
  data: SimDataUsage_elasticSearchPages_simDataUsage_data;
}

export interface SimDataUsage_elasticSearchPages {
  simDataUsage: SimDataUsage_elasticSearchPages_simDataUsage;
}

export interface SimDataUsage {
  elasticSearchPages: SimDataUsage_elasticSearchPages;
}

export interface SimDataUsageVariables {
  first?: number | null;
  offset?: number | null;
  sortProperty: SimDataUsageSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDataUsageExcel
// ====================================================

export interface SimDataUsageExcel_elasticSearchPages_simDataUsage {
  excelSheet: string;
}

export interface SimDataUsageExcel_elasticSearchPages {
  simDataUsage: SimDataUsageExcel_elasticSearchPages_simDataUsage;
}

export interface SimDataUsageExcel {
  elasticSearchPages: SimDataUsageExcel_elasticSearchPages;
}

export interface SimDataUsageExcelVariables {
  sortProperty: SimDataUsageSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
  columns: ColumnKeyAndTitle[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDetails
// ====================================================

export interface SimDetails_simCardDetail_controller {
  name: string | null;
  alias: string | null;
  controllerId: string | null;
  serial: string;
  alternateSerial: string | null;
}

export interface SimDetails_simCardDetail_site {
  siteId: string;
  alias: string;
}

export interface SimDetails_simCardDetail_dataUsage {
  usage: number | null;
  timestamp: string | null;
}

export interface SimDetails_simCardDetail {
  controller: SimDetails_simCardDetail_controller | null;
  site: SimDetails_simCardDetail_site | null;
  dataUsage: (SimDetails_simCardDetail_dataUsage | null)[];
  buidName: string | null;
  icc: string | null;
  ipAddress: string | null;
  msisdn: string | null;
  imsi: string | null;
  imei: string | null;
  provider: string | null;
  simState: string | null;
  longitude: number | null;
  latitude: number | null;
  range: number | null;
  timeZone: string | null;
  terminationStatus: string | null;
  terminationDate: string | null;
  sierraWirelessPendingOperation: string | null;
}

export interface SimDetails {
  simCardDetail: SimDetails_simCardDetail | null;
}

export interface SimDetailsVariables {
  icc?: string | null;
  msisdn?: string | null;
  ipAddress?: string | null;
  simCardType?: string | null;
  controllerId?: number | null;
  getInfoFromProvider: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimLocationSierraWireless
// ====================================================

export interface SimLocationSierraWireless_simCardDetail {
  longitude: number | null;
  latitude: number | null;
  range: number | null;
  timeZone: string | null;
  hasFetchedLocationData: boolean | null;
}

export interface SimLocationSierraWireless {
  simCardDetail: SimLocationSierraWireless_simCardDetail | null;
}

export interface SimLocationSierraWirelessVariables {
  msisdn?: string | null;
  simCardType?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendSimAction
// ====================================================

export interface SendSimAction_sendSimAction {
  command: string | null;
  result: string | null;
  icc: string | null;
  msisdn: string | null;
}

export interface SendSimAction {
  sendSimAction: SendSimAction_sendSimAction;
}

export interface SendSimActionVariables {
  icc?: string | null;
  msisdn?: string | null;
  ipadress?: string | null;
  simcardtype?: string | null;
  buidId?: number | null;
  buidName?: string | null;
  action: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDeviceMap
// ====================================================

export interface SimDeviceMap_elasticSearchPages_simDeviceMap_data_edges_buid {
  name: string | null;
}

export interface SimDeviceMap_elasticSearchPages_simDeviceMap_data_edges {
  simDeviceMapId: number | null;
  icc: string | null;
  imported: string | null;
  serial: string | null;
  alternateSerial: string | null;
  companyName: string | null;
  receivingPlace: string | null;
  deviceType: string | null;
  buid: SimDeviceMap_elasticSearchPages_simDeviceMap_data_edges_buid | null;
  packingSlip: string | null;
  purchaseOrderNumber: string | null;
  rma: string | null;
  rmaCode: string | null;
  controllerAlias: string | null;
  controllerId: number | null;
  suspended: string | null;
  suspendedByUserName: string | null;
  activated: string | null;
  activatedByUserName: string | null;
  shippedDate: string | null;
  decommissionedDate: string | null;
}

export interface SimDeviceMap_elasticSearchPages_simDeviceMap_data {
  totalCount: number;
  edges: SimDeviceMap_elasticSearchPages_simDeviceMap_data_edges[];
}

export interface SimDeviceMap_elasticSearchPages_simDeviceMap {
  data: SimDeviceMap_elasticSearchPages_simDeviceMap_data;
}

export interface SimDeviceMap_elasticSearchPages {
  simDeviceMap: SimDeviceMap_elasticSearchPages_simDeviceMap;
}

export interface SimDeviceMap {
  elasticSearchPages: SimDeviceMap_elasticSearchPages;
}

export interface SimDeviceMapVariables {
  first?: number | null;
  offset?: number | null;
  sortProperty: SimDeviceMapSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDeviceMapIds
// ====================================================

export interface SimDeviceMapIds_elasticSearchPages_simDeviceMap_data_edges {
  simDeviceMapId: number | null;
}

export interface SimDeviceMapIds_elasticSearchPages_simDeviceMap_data {
  totalCount: number;
  edges: SimDeviceMapIds_elasticSearchPages_simDeviceMap_data_edges[];
}

export interface SimDeviceMapIds_elasticSearchPages_simDeviceMap {
  data: SimDeviceMapIds_elasticSearchPages_simDeviceMap_data;
}

export interface SimDeviceMapIds_elasticSearchPages {
  simDeviceMap: SimDeviceMapIds_elasticSearchPages_simDeviceMap;
}

export interface SimDeviceMapIds {
  elasticSearchPages: SimDeviceMapIds_elasticSearchPages;
}

export interface SimDeviceMapIdsVariables {
  sortProperty: SimDeviceMapSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDeviceMapExcel
// ====================================================

export interface SimDeviceMapExcel_elasticSearchPages_simDeviceMap {
  excelSheet: string;
}

export interface SimDeviceMapExcel_elasticSearchPages {
  simDeviceMap: SimDeviceMapExcel_elasticSearchPages_simDeviceMap;
}

export interface SimDeviceMapExcel {
  elasticSearchPages: SimDeviceMapExcel_elasticSearchPages;
}

export interface SimDeviceMapExcelVariables {
  sortProperty: SimDeviceMapSortEnum;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
  columns: ColumnKeyAndTitle[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimDeviceBuidMapping
// ====================================================

export interface SimDeviceBuidMapping_simDeviceBuidMapping_buid {
  buidId: string | null;
  name: string | null;
}

export interface SimDeviceBuidMapping_simDeviceBuidMapping {
  buid: SimDeviceBuidMapping_simDeviceBuidMapping_buid | null;
  companyName: string | null;
  receivingPlace: string | null;
}

export interface SimDeviceBuidMapping {
  simDeviceBuidMapping: SimDeviceBuidMapping_simDeviceBuidMapping[];
}

export interface SimDeviceBuidMappingVariables {
  simDeviceMapIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSimDeviceMapBuid
// ====================================================

export interface UpdateSimDeviceMapBuid_updateSimDeviceMapBuid {
  success: boolean;
  message: string;
}

export interface UpdateSimDeviceMapBuid {
  updateSimDeviceMapBuid: UpdateSimDeviceMapBuid_updateSimDeviceMapBuid;
}

export interface UpdateSimDeviceMapBuidVariables {
  buidId: number;
  simDeviceMapIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEasyfeedRecipeMutation
// ====================================================

export interface UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks_batches_tanks_products {
  name: string;
  bags: string;
  color: string;
}

export interface UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks_batches_tanks {
  name: string;
  products: UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks_batches_tanks_products[];
}

export interface UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks_batches {
  tanks: UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks_batches_tanks[];
  additional: string;
  name: string;
  checked: boolean;
  checkedByUserId: number | null;
  checkedDate: string | null;
}

export interface UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks {
  weekNo: string;
  cropStage: string;
  batches: UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks_batches[];
}

export interface UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe {
  name: string | null;
  currentWeek: number | null;
  weeks: UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe_weeks[] | null;
}

export interface UpdateEasyfeedRecipeMutation {
  updateEasyfeedRecipe: UpdateEasyfeedRecipeMutation_updateEasyfeedRecipe;
}

export interface UpdateEasyfeedRecipeMutationVariables {
  siteId: string;
  recipe: InputRecipe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EasyfeedQuery
// ====================================================

export interface EasyfeedQuery_easyfeed_weeks_batches_tanks_products {
  name: string;
  bags: string;
  color: string;
}

export interface EasyfeedQuery_easyfeed_weeks_batches_tanks {
  name: string;
  products: EasyfeedQuery_easyfeed_weeks_batches_tanks_products[];
}

export interface EasyfeedQuery_easyfeed_weeks_batches {
  tanks: EasyfeedQuery_easyfeed_weeks_batches_tanks[];
  additional: string;
  name: string;
  checked: boolean;
  checkedByUserId: number | null;
  checkedDate: string | null;
}

export interface EasyfeedQuery_easyfeed_weeks {
  weekNo: string;
  cropStage: string;
  batches: EasyfeedQuery_easyfeed_weeks_batches[];
}

export interface EasyfeedQuery_easyfeed {
  name: string | null;
  currentWeek: number | null;
  weeks: EasyfeedQuery_easyfeed_weeks[] | null;
}

export interface EasyfeedQuery {
  easyfeed: EasyfeedQuery_easyfeed | null;
}

export interface EasyfeedQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsAlarmsQuery
// ====================================================

export interface SiteDetailsAlarmsQuery_site {
  alarmsAreMuted: boolean | null;
  disabledSystemAlarms: boolean | null;
}

export interface SiteDetailsAlarmsQuery {
  site: SiteDetailsAlarmsQuery_site | null;
}

export interface SiteDetailsAlarmsQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleMuteAlarmsMutations
// ====================================================

export interface ToggleMuteAlarmsMutations {
  setSiteAlarmExclutionsForSites: boolean | null;
}

export interface ToggleMuteAlarmsMutationsVariables {
  siteIds: number[];
  muted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleDisableSystemAlarms
// ====================================================

export interface ToggleDisableSystemAlarms_updateSites_sites {
  siteId: string;
  disabledSystemAlarms: boolean | null;
}

export interface ToggleDisableSystemAlarms_updateSites {
  sites: (ToggleDisableSystemAlarms_updateSites_sites | null)[] | null;
}

export interface ToggleDisableSystemAlarms {
  updateSites: ToggleDisableSystemAlarms_updateSites | null;
}

export interface ToggleDisableSystemAlarmsVariables {
  siteIds: number[];
  disable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSiteChannels
// ====================================================

export interface DeleteSiteChannels {
  deleteSiteChannels: boolean | null;
}

export interface DeleteSiteChannelsVariables {
  siteChannelIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSiteChannels
// ====================================================

export interface UpdateSiteChannels_updateSiteChannels {
  siteChannelId: number;
}

export interface UpdateSiteChannels {
  updateSiteChannels: UpdateSiteChannels_updateSiteChannels[];
}

export interface UpdateSiteChannelsVariables {
  siteChannelIds: number[];
  productId?: number | null;
  securityLevel?: number | null;
  unitId?: number | null;
  channelType?: number | null;
  parked?: boolean | null;
  capacity?: number | null;
  maximum?: number | null;
  minimum?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SiteDetailsGalleryUpdateTagsMutation
// ====================================================

export interface SiteDetailsGalleryUpdateTagsMutation_updateGalleryItemTags {
  siteGalleryItemId: string | null;
  tags: string[] | null;
}

export interface SiteDetailsGalleryUpdateTagsMutation {
  updateGalleryItemTags: SiteDetailsGalleryUpdateTagsMutation_updateGalleryItemTags | null;
}

export interface SiteDetailsGalleryUpdateTagsMutationVariables {
  galleryItemId: string;
  tags: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsGalleryDetailsQuery
// ====================================================

export interface SiteDetailsGalleryDetailsQuery_site_galleryItems_uploadedByUser {
  userId: number;
  name: string;
}

export interface SiteDetailsGalleryDetailsQuery_site_galleryItems {
  siteGalleryItemId: string | null;
  tags: string[] | null;
  thumbnailUrl: string | null;
  imageUrl: string | null;
  sizeInBytes: number | null;
  uploadedTimestamp: string | null;
  fileName: string | null;
  uploadedByUser: SiteDetailsGalleryDetailsQuery_site_galleryItems_uploadedByUser | null;
}

export interface SiteDetailsGalleryDetailsQuery_site {
  galleryItems: SiteDetailsGalleryDetailsQuery_site_galleryItems[];
}

export interface SiteDetailsGalleryDetailsQuery {
  site: SiteDetailsGalleryDetailsQuery_site | null;
}

export interface SiteDetailsGalleryDetailsQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsGalleryListQuery
// ====================================================

export interface SiteDetailsGalleryListQuery_site_galleryItems_uploadedByUser {
  userId: number;
  name: string;
}

export interface SiteDetailsGalleryListQuery_site_galleryItems {
  siteGalleryItemId: string | null;
  tags: string[] | null;
  thumbnailUrl: string | null;
  imageUrl: string | null;
  sizeInBytes: number | null;
  uploadedTimestamp: string | null;
  fileName: string | null;
  uploadedByUser: SiteDetailsGalleryListQuery_site_galleryItems_uploadedByUser | null;
}

export interface SiteDetailsGalleryListQuery_site {
  siteId: string;
  galleryItems: SiteDetailsGalleryListQuery_site_galleryItems[];
}

export interface SiteDetailsGalleryListQuery {
  site: SiteDetailsGalleryListQuery_site | null;
}

export interface SiteDetailsGalleryListQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SitedetailsAssignContactMutation
// ====================================================

export interface SitedetailsAssignContactMutation {
  setContactOnSite: boolean | null;
}

export interface SitedetailsAssignContactMutationVariables {
  siteId: string;
  contactType: ContactType;
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SitedetailContactsQuery
// ====================================================

export interface SitedetailContactsQuery_site_orderContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

export interface SitedetailContactsQuery_site_customerContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

export interface SitedetailContactsQuery_site_techContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

export interface SitedetailContactsQuery_site_salesContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

export interface SitedetailContactsQuery_site_transportContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

export interface SitedetailContactsQuery_site_dealerContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

export interface SitedetailContactsQuery_site {
  siteId: string;
  orderContact: SitedetailContactsQuery_site_orderContact | null;
  customerContact: SitedetailContactsQuery_site_customerContact | null;
  techContact: SitedetailContactsQuery_site_techContact | null;
  salesContact: SitedetailContactsQuery_site_salesContact | null;
  transportContact: SitedetailContactsQuery_site_transportContact | null;
  dealerContact: SitedetailContactsQuery_site_dealerContact | null;
}

export interface SitedetailContactsQuery {
  site: SitedetailContactsQuery_site | null;
}

export interface SitedetailContactsQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveContactFromSiteMutation
// ====================================================

export interface RemoveContactFromSiteMutation {
  setContactOnSite: boolean | null;
}

export interface RemoveContactFromSiteMutationVariables {
  siteId: string;
  contactType: ContactType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactSiteGalleryQuery
// ====================================================

export interface ReactSiteGalleryQuery_site_galleryItems {
  siteGalleryItemId: string | null;
  thumbnailUrl: string | null;
}

export interface ReactSiteGalleryQuery_site {
  siteId: string;
  galleryItems: ReactSiteGalleryQuery_site_galleryItems[];
}

export interface ReactSiteGalleryQuery {
  site: ReactSiteGalleryQuery_site | null;
}

export interface ReactSiteGalleryQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HasEasyfeedRecipeQuery
// ====================================================

export interface HasEasyfeedRecipeQuery_easyfeed_weeks {
  cropStage: string;
  weekNo: string;
}

export interface HasEasyfeedRecipeQuery_easyfeed {
  name: string | null;
  currentWeek: number | null;
  weeks: HasEasyfeedRecipeQuery_easyfeed_weeks[] | null;
}

export interface HasEasyfeedRecipeQuery {
  easyfeed: HasEasyfeedRecipeQuery_easyfeed | null;
}

export interface HasEasyfeedRecipeQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsUsersWithAccessQuery
// ====================================================

export interface SiteDetailsUsersWithAccessQuery_site_users_customer {
  customerId: string;
  name: string | null;
}

export interface SiteDetailsUsersWithAccessQuery_site_users_buid {
  buidId: string | null;
  name: string | null;
}

export interface SiteDetailsUsersWithAccessQuery_site_users {
  userId: number | null;
  name: string | null;
  currentUserHasAccess: boolean;
  internalUser: boolean;
  customer: SiteDetailsUsersWithAccessQuery_site_users_customer | null;
  buid: SiteDetailsUsersWithAccessQuery_site_users_buid | null;
}

export interface SiteDetailsUsersWithAccessQuery_site {
  siteId: string;
  users: SiteDetailsUsersWithAccessQuery_site_users[];
}

export interface SiteDetailsUsersWithAccessQuery {
  site: SiteDetailsUsersWithAccessQuery_site | null;
}

export interface SiteDetailsUsersWithAccessQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsUserSettingsQuery
// ====================================================

export interface SiteDetailsUserSettingsQuery_currentUser_userSettings {
  siteDetailsDefaultTab: SiteDetailsTabEnum;
}

export interface SiteDetailsUserSettingsQuery_currentUser {
  userSettings: SiteDetailsUserSettingsQuery_currentUser_userSettings | null;
}

export interface SiteDetailsUserSettingsQuery_siteChannels_edges {
  siteChannelId: number;
}

export interface SiteDetailsUserSettingsQuery_siteChannels {
  edges: SiteDetailsUserSettingsQuery_siteChannels_edges[];
}

export interface SiteDetailsUserSettingsQuery {
  currentUser: SiteDetailsUserSettingsQuery_currentUser;
  siteChannels: SiteDetailsUserSettingsQuery_siteChannels;
}

export interface SiteDetailsUserSettingsQueryVariables {
  siteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TankRefillContactsQuery
// ====================================================

export interface TankRefillContactsQuery_site_orderContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
  contactId: string;
}

export interface TankRefillContactsQuery_site_salesContact {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
  contactId: string;
}

export interface TankRefillContactsQuery_site {
  siteId: string;
  orderContact: TankRefillContactsQuery_site_orderContact | null;
  salesContact: TankRefillContactsQuery_site_salesContact | null;
}

export interface TankRefillContactsQuery {
  site: TankRefillContactsQuery_site | null;
}

export interface TankRefillContactsQueryVariables {
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TankRefillListQuery
// ====================================================

export interface TankRefillListQuery_siteChannel_tankDetails_tankRefills_attachments {
  attachmentUrl: string | null;
  attachmentName: string | null;
  attachmentSize: number | null;
}

export interface TankRefillListQuery_siteChannel_tankDetails_tankRefills {
  amountFilled: number | null;
  reference: string | null;
  refillId: number | null;
  refillStart: string | null;
  timeStamp: string;
  vehicle: string | null;
  attachments: TankRefillListQuery_siteChannel_tankDetails_tankRefills_attachments[];
}

export interface TankRefillListQuery_siteChannel_tankDetails_plannedTankRefills {
  plannedTankRefillId: number | null;
  driverName: string | null;
  plannedAt: string | null;
  reference: string | null;
  timeStamp: string;
  vehicle: string | null;
}

export interface TankRefillListQuery_siteChannel_tankDetails_tankOrders_orderedByUser {
  name: string;
  userId: number;
}

export interface TankRefillListQuery_siteChannel_tankDetails_tankOrders {
  orderedAt: string | null;
  orderedByUser: TankRefillListQuery_siteChannel_tankDetails_tankOrders_orderedByUser;
  quantity: number | null;
  tankChannelId: number;
  tankOrderId: number | null;
  timeStamp: string;
}

export interface TankRefillListQuery_siteChannel_tankDetails {
  tankRefills: (TankRefillListQuery_siteChannel_tankDetails_tankRefills | null)[] | null;
  plannedTankRefills: (TankRefillListQuery_siteChannel_tankDetails_plannedTankRefills | null)[] | null;
  tankOrders: (TankRefillListQuery_siteChannel_tankDetails_tankOrders | null)[] | null;
}

export interface TankRefillListQuery_siteChannel {
  tankDetails: TankRefillListQuery_siteChannel_tankDetails | null;
}

export interface TankRefillListQuery {
  siteChannel: TankRefillListQuery_siteChannel | null;
}

export interface TankRefillListQueryVariables {
  siteChannelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsChannelDetailsQuery
// ====================================================

export interface SiteDetailsChannelDetailsQuery_siteChannel_tankDetails_tankRefills {
  refillId: number | null;
  timeStamp: string;
  amountFilled: number | null;
}

export interface SiteDetailsChannelDetailsQuery_siteChannel_tankDetails_plannedTankRefills {
  plannedTankRefillId: number | null;
  timeStamp: string;
}

export interface SiteDetailsChannelDetailsQuery_siteChannel_tankDetails {
  tankRefills: (SiteDetailsChannelDetailsQuery_siteChannel_tankDetails_tankRefills | null)[] | null;
  plannedTankRefills: (SiteDetailsChannelDetailsQuery_siteChannel_tankDetails_plannedTankRefills | null)[] | null;
}

export interface SiteDetailsChannelDetailsQuery_siteChannel_unit {
  decimals: number;
  sapCode: string;
  symbol: string;
  translationKey: string;
  unitId: string;
}

export interface SiteDetailsChannelDetailsQuery_siteChannel {
  tankDetails: SiteDetailsChannelDetailsQuery_siteChannel_tankDetails | null;
  unit: SiteDetailsChannelDetailsQuery_siteChannel_unit | null;
  maximum: number | null;
  minimum: number | null;
}

export interface SiteDetailsChannelDetailsQuery {
  siteChannel: SiteDetailsChannelDetailsQuery_siteChannel | null;
}

export interface SiteDetailsChannelDetailsQueryVariables {
  siteChannelId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteDetailsTanksDetailsQuery
// ====================================================

export interface SiteDetailsTanksDetailsQuery_siteChannel_tankDetails_tankRefills {
  refillId: number | null;
  timeStamp: string;
  amountFilled: number | null;
}

export interface SiteDetailsTanksDetailsQuery_siteChannel_tankDetails_plannedTankRefills {
  plannedTankRefillId: number | null;
  timeStamp: string;
}

export interface SiteDetailsTanksDetailsQuery_siteChannel_tankDetails_vehicle {
  codeAndName: string | null;
}

export interface SiteDetailsTanksDetailsQuery_siteChannel_tankDetails {
  tankRefills: (SiteDetailsTanksDetailsQuery_siteChannel_tankDetails_tankRefills | null)[] | null;
  plannedTankRefills: (SiteDetailsTanksDetailsQuery_siteChannel_tankDetails_plannedTankRefills | null)[] | null;
  vehicle: SiteDetailsTanksDetailsQuery_siteChannel_tankDetails_vehicle | null;
  loadingPointId: number | null;
}

export interface SiteDetailsTanksDetailsQuery_siteChannel_unit {
  decimals: number;
  sapCode: string;
  symbol: string;
  translationKey: string;
  unitId: string;
}

export interface SiteDetailsTanksDetailsQuery_siteChannel_product {
  name: string | null;
  productId: string;
  languageKey: string | null;
}

export interface SiteDetailsTanksDetailsQuery_siteChannel {
  alias: string;
  maximum: number | null;
  minimum: number | null;
  capacity: number | null;
  tankDetails: SiteDetailsTanksDetailsQuery_siteChannel_tankDetails | null;
  unit: SiteDetailsTanksDetailsQuery_siteChannel_unit | null;
  product: SiteDetailsTanksDetailsQuery_siteChannel_product | null;
}

export interface SiteDetailsTanksDetailsQuery_site {
  siteId: string;
  soldTo: string | null;
}

export interface SiteDetailsTanksDetailsQuery {
  siteChannel: SiteDetailsTanksDetailsQuery_siteChannel | null;
  site: SiteDetailsTanksDetailsQuery_site | null;
}

export interface SiteDetailsTanksDetailsQueryVariables {
  siteChannelId: number;
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SitesBySiteIds
// ====================================================

export interface SitesBySiteIds_sitesByIds_buid {
  name: string | null;
  buidId: string | null;
}

export interface SitesBySiteIds_sitesByIds_customer {
  name: string | null;
  customerId: string;
}

export interface SitesBySiteIds_sitesByIds_orderContact {
  contactId: string;
  name: string;
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
}

export interface SitesBySiteIds_sitesByIds_techContact {
  contactId: string;
  name: string;
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
}

export interface SitesBySiteIds_sitesByIds_salesContact {
  contactId: string;
  name: string;
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
}

export interface SitesBySiteIds_sitesByIds_customerContact {
  contactId: string;
  name: string;
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
}

export interface SitesBySiteIds_sitesByIds_channels_tankDetails_haulier {
  haulierId: string;
  haulierTag: string;
}

export interface SitesBySiteIds_sitesByIds_channels_tankDetails {
  haulier: SitesBySiteIds_sitesByIds_channels_tankDetails_haulier | null;
}

export interface SitesBySiteIds_sitesByIds_channels {
  siteChannelId: number;
  tankDetails: SitesBySiteIds_sitesByIds_channels_tankDetails | null;
}

export interface SitesBySiteIds_sitesByIds {
  buid: SitesBySiteIds_sitesByIds_buid | null;
  customer: SitesBySiteIds_sitesByIds_customer | null;
  soldTo: string | null;
  orderContact: SitesBySiteIds_sitesByIds_orderContact | null;
  techContact: SitesBySiteIds_sitesByIds_techContact | null;
  salesContact: SitesBySiteIds_sitesByIds_salesContact | null;
  customerContact: SitesBySiteIds_sitesByIds_customerContact | null;
  channels: SitesBySiteIds_sitesByIds_channels[];
}

export interface SitesBySiteIds {
  sitesByIds: SitesBySiteIds_sitesByIds[];
}

export interface SitesBySiteIdsVariables {
  siteIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSites
// ====================================================

export interface UpdateSites_updateSites_sites {
  siteId: string;
}

export interface UpdateSites_updateSites {
  sites: (UpdateSites_updateSites_sites | null)[] | null;
}

export interface UpdateSites {
  updateSites: UpdateSites_updateSites | null;
}

export interface UpdateSitesVariables {
  customerId?: number | null;
  siteIds: number[];
  editCustomer: boolean;
  buidId?: number | null;
  editBuid: boolean;
  editSystemAlarms: boolean;
  systemAlarmsDisabled?: boolean | null;
  soldTo?: string | null;
  editSoldTo: boolean;
  parked?: boolean | null;
  editParked: boolean;
  orderContactId?: number | null;
  editOrderContact: boolean;
  techContactId?: number | null;
  editTechContact: boolean;
  salesContactId?: number | null;
  editSalesContact: boolean;
  customerContactId?: number | null;
  editCustomerContact: boolean;
  haulierId?: number | null;
  editHaulier: boolean;
  alarmMuted?: boolean | null;
  editAlarmMuted?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SitelistMultiEditLastUpdate
// ====================================================

export interface SitelistMultiEditLastUpdate_elasticSearchPages_sitelist {
  lastUpdated: number | null;
}

export interface SitelistMultiEditLastUpdate_elasticSearchPages {
  sitelist: SitelistMultiEditLastUpdate_elasticSearchPages_sitelist;
}

export interface SitelistMultiEditLastUpdate {
  elasticSearchPages: SitelistMultiEditLastUpdate_elasticSearchPages;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReactDeleteSites
// ====================================================

export interface ReactDeleteSites {
  deleteSites: string | null;
}

export interface ReactDeleteSitesVariables {
  siteIds: number[];
  alsoDeleteControllers?: DeleteControllersInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SitesDeleteDialogInfo
// ====================================================

export interface SitesDeleteDialogInfo_sitesDeleteDialogInfo {
  canSuspendSims: boolean;
}

export interface SitesDeleteDialogInfo {
  sitesDeleteDialogInfo: SitesDeleteDialogInfo_sitesDeleteDialogInfo;
}

export interface SitesDeleteDialogInfoVariables {
  siteIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReactSitelistToggleAlarm
// ====================================================

export interface ReactSitelistToggleAlarm {
  setSiteAlarmExclutionsForSites: boolean | null;
}

export interface ReactSitelistToggleAlarmVariables {
  siteIds: number[];
  muted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSiteFilter
// ====================================================

export interface DeleteSiteFilter {
  deleteSiteFilter: string | null;
}

export interface DeleteSiteFilterVariables {
  filterId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RefFilterValues
// ====================================================

export interface RefFilterValues_refFilterValues {
  key: string;
  label: string;
  translateLabel: boolean | null;
  instances: number;
}

export interface RefFilterValues {
  refFilterValues: RefFilterValues_refFilterValues[];
}

export interface RefFilterValuesVariables {
  property: string;
  filters?: string | null;
  page: ElasticSearchPage;
  freeTextQuery?: (string | null)[] | null;
  language?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactMobileSiteListQuery
// ====================================================

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25 {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5 {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit_unitWithoutInterval | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_product {
  name: string;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit | null;
  tankDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_tankDetails | null;
  gasDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_gasDetails | null;
  doseDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_doseDetails | null;
  temperatureChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_conductivityChannelDetails | null;
  product: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_product | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  alias: string;
  signal: SignalEnum;
  isParked: boolean;
  hasActiveChannelAlarms: boolean;
  lastSampleTime: string | null;
  isVirtual: boolean;
  tankChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
  gasChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_gasChannels[] | null;
  doseChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_doseChannels[] | null;
  flowChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_flowChannels[] | null;
  batteryChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_batteryChannels[] | null;
  stockVsDoseChannels25: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25[] | null;
  stockVsDoseChannels5: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5[] | null;
  temperatureChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels[] | null;
  retentionTimeChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels[] | null;
  conductivityChannels: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels[] | null;
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface ReactMobileSiteListQuery_elasticSearchPages_sitelist {
  data: ReactMobileSiteListQuery_elasticSearchPages_sitelist_data;
}

export interface ReactMobileSiteListQuery_elasticSearchPages {
  sitelist: ReactMobileSiteListQuery_elasticSearchPages_sitelist;
}

export interface ReactMobileSiteListQuery {
  elasticSearchPages: ReactMobileSiteListQuery_elasticSearchPages;
}

export interface ReactMobileSiteListQueryVariables {
  offset: number;
  first: number;
  sortProperty: number;
  sortDirection?: SortDirection | null;
  freeTextQuery?: string[] | null;
  filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSiteFilter
// ====================================================

export interface CreateSiteFilter_createSiteFilter {
  filterId: string;
}

export interface CreateSiteFilter {
  createSiteFilter: CreateSiteFilter_createSiteFilter | null;
}

export interface CreateSiteFilterVariables {
  input: CreateSiteFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSiteFilter
// ====================================================

export interface UpdateSiteFilter_updateSiteFilter {
  filterId: string;
}

export interface UpdateSiteFilter {
  updateSiteFilter: UpdateSiteFilter_updateSiteFilter | null;
}

export interface UpdateSiteFilterVariables {
  input: UpdateSiteFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactSitelistQuery
// ====================================================

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_salesContact {
  contactId: number;
  initials: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_techContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_orderContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_customerContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_transportContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_dealerContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_buid {
  name: string;
  buidId: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_customer {
  name: string;
  customerId: number;
  alias: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
  customerFactor: number | null;
  customerFactorSecurityLevel: number | null;
  customerFactorUnit: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit_unitWithoutInterval | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels {
  channelType: number;
  timeZoneId: number | null;
  doseDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_doseDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_retentionTimeDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit_unitWithoutInterval | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels {
  channelType: number;
  timeZoneId: number | null;
  retentionTimeDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_retentionTimeDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels_activeAlarms[] | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_batteryChannels {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  batteryRuntimeService: number | null;
  maximum: number | null;
  capacity: number | null;
  minimum: number | null;
  percentage: number | null;
  alias: string;
  health: HealthEnum | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_unit | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_batteryChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels_gasDetails {
  meanPpm: number | null;
  maxPpm: number | null;
  percentTimeAboveLimit: number | null;
  h2SRuntimeCalibration: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels {
  channelType: number;
  timeZoneId: number | null;
  gasDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels_gasDetails | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  alias: string;
  isParked: boolean | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels_unit | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit_unitWithoutInterval | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels {
  channelType: number;
  timeZoneId: number | null;
  flowChannelDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_flowChannelDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_temperatureChannelDetails {
  intervalMax: number | null;
  intervalMin: number | null;
  intervalMean: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels {
  channelType: number;
  timeZoneId: number | null;
  temperatureChannelDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_temperatureChannelDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_unit | null;
  alias: string;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5 {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  stockVsDoseAccuracyChannelDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_unit | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5_activeAlarms[] | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25 {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  stockVsDoseAccuracyChannelDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_unit | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25_activeAlarms[] | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails_lastTankRefill_attachments {
  attachmentUrl: string;
  attachmentName: string;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails_lastTankRefill {
  refillStart: string | null;
  amountFilled: number | null;
  attachments: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails_lastTankRefill_attachments[] | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails_vehicle {
  vehicleId: number;
  codeAndName: string;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails {
  reachMinimum: string | null;
  reachMinimumSpan: number | null;
  shipTo: string | null;
  customerProductRef: string | null;
  description: string | null;
  lastFilled: string | null;
  freeCapacity: number | null;
  nextPlannedFilling: string | null;
  haulierTag: string | null;
  lastTankRefill: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails_lastTankRefill | null;
  vehicle: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails_vehicle | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_product {
  productId: number;
  name: string;
  alias_deDE: string;
  alias_enGB: string;
  alias_esES: string;
  alias_frFR: string;
  alias_huHU: string;
  alias_itIT: string;
  alias_nbNO: string;
  alias_nlNL: string;
  alias_plPL: string;
  alias_svSE: string;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels {
  alias: string;
  timeZoneId: number | null;
  channelId: number;
  lastSampleTime: string | null;
  lastSample: number | null;
  siteChannelId: number;
  capacity: number | null;
  maximum: number | null;
  minimum: number | null;
  parkedDate: string | null;
  parkedDescription: string | null;
  isParked: boolean | null;
  channelType: number;
  health: HealthEnum | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_activeAlarms[] | null;
  percentage: number | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_unit | null;
  tankDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_tankDetails | null;
  product: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels_product | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit_unitWithoutInterval | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels {
  channelType: number;
  timeZoneId: number | null;
  conductivityChannelDetails: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_conductivityChannelDetails | null;
  unit: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data_edges {
  siteId: string;
  alias: string;
  soldTo: string | null;
  countryCode: string | null;
  postcode: string | null;
  signal: SignalEnum;
  contractReference: string | null;
  customerRef: string | null;
  creationServiceNowTicket: string | null;
  description: string | null;
  projectCode: string | null;
  lastSampleTime: string | null;
  hasActiveChannelAlarms: boolean;
  numberOfActiveAlarms: number;
  hasEquipment: boolean;
  hasGalleryItems: boolean;
  alarmsAreMuted: boolean;
  hasDeliveryTickets: boolean;
  tankWithLatestDeliveryTicket: number | null;
  isVirtual: boolean;
  isParked: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  salesContact: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_salesContact | null;
  techContact: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_techContact | null;
  orderContact: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_orderContact | null;
  customerContact: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_customerContact | null;
  transportContact: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_transportContact | null;
  dealerContact: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_dealerContact | null;
  buid: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_buid | null;
  customer: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_customer | null;
  doseChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_doseChannels[] | null;
  retentionTimeChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_retentionTimeChannels[] | null;
  batteryChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_batteryChannels[] | null;
  gasChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_gasChannels[] | null;
  flowChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_flowChannels[] | null;
  temperatureChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_temperatureChannels[] | null;
  stockVsDoseChannels5: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels5[] | null;
  stockVsDoseChannels25: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_stockVsDoseChannels25[] | null;
  tankChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_tankChannels[] | null;
  conductivityChannels: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges_conductivityChannels[] | null;
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist_data {
  totalCount: number;
  edges: ReactSitelistQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface ReactSitelistQuery_elasticSearchPages_sitelist {
  data: ReactSitelistQuery_elasticSearchPages_sitelist_data;
}

export interface ReactSitelistQuery_elasticSearchPages {
  sitelist: ReactSitelistQuery_elasticSearchPages_sitelist;
}

export interface ReactSitelistQuery {
  elasticSearchPages: ReactSitelistQuery_elasticSearchPages;
}

export interface ReactSitelistQueryVariables {
  offset: number;
  first: number;
  sortProperty: number;
  sortDirection: SortDirection;
  freeTextQuery?: string[] | null;
  filters?: string | null;
  tankChannelIsShown: boolean;
  customerIsShown: boolean;
  batteryChannelIsShown: boolean;
  lastTankRefillShown: boolean;
  gasChannelIsShown: boolean;
  stockVsDoseChannelIsShown: boolean;
  temperatureChannelIsShown: boolean;
  flowChannelIsShown: boolean;
  doseChannelIsShown: boolean;
  retentiontimeChannelIsShown: boolean;
  language?: string | null;
  conductivityChannelIsShown?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactSiteListIdsQuery
// ====================================================

export interface ReactSiteListIdsQuery_elasticSearchPages_sitelist_data_edges {
  siteId: string;
}

export interface ReactSiteListIdsQuery_elasticSearchPages_sitelist_data {
  edges: ReactSiteListIdsQuery_elasticSearchPages_sitelist_data_edges[];
}

export interface ReactSiteListIdsQuery_elasticSearchPages_sitelist {
  data: ReactSiteListIdsQuery_elasticSearchPages_sitelist_data;
}

export interface ReactSiteListIdsQuery_elasticSearchPages {
  sitelist: ReactSiteListIdsQuery_elasticSearchPages_sitelist;
}

export interface ReactSiteListIdsQuery {
  elasticSearchPages: ReactSiteListIdsQuery_elasticSearchPages;
}

export interface ReactSiteListIdsQueryVariables {
  freeTextQuery?: string[] | null;
  filters?: string | null;
  sortDirection?: SortDirection | null;
  sortProperty?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactSitelistExcelQuery
// ====================================================

export interface ReactSitelistExcelQuery_elasticSearchPages_sitelist {
  excelSheet: string;
}

export interface ReactSitelistExcelQuery_elasticSearchPages {
  sitelist: ReactSitelistExcelQuery_elasticSearchPages_sitelist;
}

export interface ReactSitelistExcelQuery {
  elasticSearchPages: ReactSitelistExcelQuery_elasticSearchPages;
}

export interface ReactSitelistExcelQueryVariables {
  sortProperty: number;
  sortDirection?: SortDirection | null;
  freeTextQuery?: string[] | null;
  filters?: string | null;
  columns: number[];
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReactSitelistmapQuery
// ====================================================

export interface ReactSitelistmapQuery_elasticSearchPages_sitelist_mapGeoPositions {
  lat: number;
  lng: number;
  markerIconEnum: MarkerIconEnum;
  controllerId: number;
  siteId: number;
  title: string | null;
}

export interface ReactSitelistmapQuery_elasticSearchPages_sitelist {
  mapGeoPositions: ReactSitelistmapQuery_elasticSearchPages_sitelist_mapGeoPositions[];
}

export interface ReactSitelistmapQuery_elasticSearchPages {
  sitelist: ReactSitelistmapQuery_elasticSearchPages_sitelist;
}

export interface ReactSitelistmapQuery {
  elasticSearchPages: ReactSitelistmapQuery_elasticSearchPages;
}

export interface ReactSitelistmapQueryVariables {
  freeTextQuery?: string[] | null;
  filters?: string | null;
  siteChannelIdsForTankChannels?: number[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SystemMessageEditable
// ====================================================

export interface SystemMessageEditable_systemMessages_editable_systemMessageTexts {
  languageCode: string | null;
  message: string | null;
  systemMessageTextId: number | null;
  title: string | null;
}

export interface SystemMessageEditable_systemMessages_editable {
  endUtc: string | null;
  startUtc: string;
  systemMessageId: number | null;
  systemMessageTexts: (SystemMessageEditable_systemMessages_editable_systemMessageTexts | null)[];
}

export interface SystemMessageEditable_systemMessages {
  editable: SystemMessageEditable_systemMessages_editable | null;
}

export interface SystemMessageEditable {
  systemMessages: SystemMessageEditable_systemMessages | null;
}

export interface SystemMessageEditableVariables {
  systemMessageId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SystemMessageUpsert
// ====================================================

export interface SystemMessageUpsert_upsertSystemMessage {
  code: string;
  message: string;
  success: boolean;
}

export interface SystemMessageUpsert {
  upsertSystemMessage: SystemMessageUpsert_upsertSystemMessage;
}

export interface SystemMessageUpsertVariables {
  input: SystemMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SystemMessageTranslate
// ====================================================

export interface SystemMessageTranslate_translate_translations {
  text: string;
  to: string;
}

export interface SystemMessageTranslate_translate {
  translations: SystemMessageTranslate_translate_translations[];
}

export interface SystemMessageTranslate {
  translate: SystemMessageTranslate_translate;
}

export interface SystemMessageTranslateVariables {
  textToTranslate: string;
  translateToLanguages: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SystemMessageManagerList
// ====================================================

export interface SystemMessageManagerList_systemMessages_listing {
  message: string | null;
  showFrom: string | null;
  showTo: string | null;
  systemMessageId: number;
  title: string | null;
  active: boolean | null;
}

export interface SystemMessageManagerList_systemMessages {
  listing: (SystemMessageManagerList_systemMessages_listing | null)[];
}

export interface SystemMessageManagerList {
  systemMessages: SystemMessageManagerList_systemMessages | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: reactAlarmSubscriptionQuery
// ====================================================

export interface reactAlarmSubscriptionQuery_currentUser_alarmSubscriptions {
  lowLevel: boolean;
  offline: boolean;
  percentCapacity30: boolean;
  preLowLevel: boolean;
  tankCalibration: boolean;
  doseCalibration: boolean;
  seriousDoseCalibration: boolean;
  brokenSensor: boolean;
  documentNotifications: boolean;
  deliveryNotes: boolean;
}

export interface reactAlarmSubscriptionQuery_currentUser {
  alarmSubscriptions: reactAlarmSubscriptionQuery_currentUser_alarmSubscriptions | null;
}

export interface reactAlarmSubscriptionQuery {
  currentUser: reactAlarmSubscriptionQuery_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: reactChangeUserSubscriptions
// ====================================================

export interface reactChangeUserSubscriptions {
  changeAlarmSubscription: string | null;
}

export interface reactChangeUserSubscriptionsVariables {
  alarmType: number;
  activate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePassword
// ====================================================

export interface changePassword {
  changePassword: string | null;
}

export interface changePasswordVariables {
  oldPassword: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserContacts
// ====================================================

export interface UserContacts_contacts_edges_isContactFor {
  contactTypes: string[];
  siteId: number;
  siteName: string;
}

export interface UserContacts_contacts_edges {
  email: string | null;
  avatarUrl: string | null;
  telephoneNumber: string | null;
  name: string;
  contactId: string;
  customerId: number | null;
  buidId: number | null;
  description: string | null;
  userId: string | null;
  syncedWithUserInformation: boolean | null;
  isContactFor: UserContacts_contacts_edges_isContactFor[];
}

export interface UserContacts_contacts {
  edges: UserContacts_contacts_edges[];
}

export interface UserContacts {
  contacts: UserContacts_contacts;
}

export interface UserContactsVariables {
  userId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserContact
// ====================================================

export interface DeleteUserContact {
  deleteContact: boolean | null;
}

export interface DeleteUserContactVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUserContact
// ====================================================

export interface updateUserContact_upsertContact {
  contactId: string;
}

export interface updateUserContact {
  upsertContact: updateUserContact_upsertContact | null;
}

export interface updateUserContactVariables {
  entity: ContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: reactUserInfoQuery
// ====================================================

export interface reactUserInfoQuery_currentUser_user {
  avatarUrl: string | null;
  email: string;
  name: string;
  telephoneNumber: string | null;
  userId: number;
  username: string;
  buidId: number | null;
  customerId: number | null;
}

export interface reactUserInfoQuery_currentUser {
  user: reactUserInfoQuery_currentUser_user | null;
}

export interface reactUserInfoQuery {
  currentUser: reactUserInfoQuery_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: reactUpdateCurrentUser
// ====================================================

export interface reactUpdateCurrentUser_updateCurrentUser {
  userId: number;
}

export interface reactUpdateCurrentUser {
  updateCurrentUser: reactUpdateCurrentUser_updateCurrentUser | null;
}

export interface reactUpdateCurrentUserVariables {
  user: InputUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: languages
// ====================================================

export interface languages_languages {
  languageId: string;
  code: string | null;
  fullCode: string | null;
  description: string | null;
}

export interface languages {
  languages: languages_languages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCurrentUserSettings
// ====================================================

export interface updateCurrentUserSettings_updateCurrentUserSettings {
  preferredLanguage: string;
}

export interface updateCurrentUserSettings {
  updateCurrentUserSettings: updateCurrentUserSettings_updateCurrentUserSettings;
}

export interface updateCurrentUserSettingsVariables {
  preferredLanguage: string;
  hideDashboardWelcome: boolean;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userSettings
// ====================================================

export interface userSettings_currentUser_userSettings {
  preferredLanguage: string;
  hideDashboardWelcome: boolean;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
}

export interface userSettings_currentUser {
  userSettings: userSettings_currentUser_userSettings | null;
}

export interface userSettings {
  currentUser: userSettings_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserChangelog
// ====================================================

export interface UserChangelog_userChangelog {
  userId: number;
  modified: any;
  modifiedBy: string;
  description: string[];
}

export interface UserChangelog {
  userChangelog: UserChangelog_userChangelog[] | null;
}

export interface UserChangelogVariables {
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserByCloningPermissions
// ====================================================

export interface CreateUserByCloningPermissions_createUserByCloningPermissions {
  code: string;
  success: boolean;
  message: string;
}

export interface CreateUserByCloningPermissions {
  createUserByCloningPermissions: CreateUserByCloningPermissions_createUserByCloningPermissions;
}

export interface CreateUserByCloningPermissionsVariables {
  entity: CreateUserByCloningPermissionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getScheduledReportsForUser
// ====================================================

export interface getScheduledReportsForUser_getUserScheduledReports {
  reportScheduleId: string | null;
  scheduleName: string | null;
  recurrence: ReportScheduleRecurrence;
  recurrenceDetails: number | null;
  triggerTimeLocal: string;
  scheduleType: ScheduleTypeEnum;
  scheduledReportTypes: Reports[];
}

export interface getScheduledReportsForUser {
  getUserScheduledReports: getScheduledReportsForUser_getUserScheduledReports[];
}

export interface getScheduledReportsForUserVariables {
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserFilters
// ====================================================

export interface CurrentUserFilters_currentUserFilters {
  buidFilter: string[];
  customerFilter: string[];
  haulierFilter: string[];
}

export interface CurrentUserFilters {
  currentUserFilters: CurrentUserFilters_currentUserFilters;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAccess
// ====================================================

export interface UserAccess_userAccess_userFeatures_features {
  translationKey: string;
  featureId: number;
  readEnabled: boolean;
  canRead: boolean;
  writeEnabled: boolean;
  canWrite: boolean;
  deleteEnabled: boolean;
  canDelete: boolean;
}

export interface UserAccess_userAccess_userFeatures {
  groupKey: string;
  features: UserAccess_userAccess_userFeatures_features[];
}

export interface UserAccess_userAccess_userColumns {
  sortIndex: number;
  columnId: number;
  selected: boolean;
  columnWidth: number | null;
  languageKey: string;
  columnGroupKey: string;
  connectedToUserFeature: number | null;
  disabled: boolean;
}

export interface UserAccess_userAccess_details {
  buidTags: string[];
  customerTags: string[];
  haulierTags: string[];
  hasAccessToAllBuids: boolean;
  hasAccessToAllCustomers: boolean;
  isSuperUser: boolean | null;
  internalUser: boolean | null;
  securityLevel: number;
}

export interface UserAccess_userAccess {
  userId: number;
  userFeatures: UserAccess_userAccess_userFeatures[];
  userColumns: UserAccess_userAccess_userColumns[];
  details: UserAccess_userAccess_details;
}

export interface UserAccess {
  userAccess: UserAccess_userAccess;
}

export interface UserAccessVariables {
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoleTemplates
// ====================================================

export interface RoleTemplates_roleTemplates_features {
  featureId: number;
  name: string;
  group: string;
  read: boolean;
  write: boolean;
  delete: boolean;
}

export interface RoleTemplates_roleTemplates_columns {
  columnId: number;
  name: string;
  group: string;
  isDefault: boolean;
}

export interface RoleTemplates_roleTemplates {
  name: string;
  roleTemplateId: number;
  features: RoleTemplates_roleTemplates_features[];
  columns: RoleTemplates_roleTemplates_columns[];
  internalOnly: boolean;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
  inactivityExpirationDays: number;
}

export interface RoleTemplates {
  roleTemplates: RoleTemplates_roleTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveUserAccess
// ====================================================

export interface SaveUserAccess_saveUserAccess {
  userId: number;
}

export interface SaveUserAccess {
  saveUserAccess: SaveUserAccess_saveUserAccess;
}

export interface SaveUserAccessVariables {
  userAccess: InputUserAccess;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUserInfo
// ====================================================

export interface AddUserInfo_addUserInfo {
  userId: number;
}

export interface AddUserInfo {
  addUserInfo: AddUserInfo_addUserInfo;
}

export interface AddUserInfoVariables {
  userInfo: AddUserInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckUsermanagerEmail
// ====================================================

export interface CheckUsermanagerEmail_elasticSearchPages_userManager_data_edges {
  name: string | null;
  userId: string;
}

export interface CheckUsermanagerEmail_elasticSearchPages_userManager_data {
  totalCount: number;
  edges: CheckUsermanagerEmail_elasticSearchPages_userManager_data_edges[];
}

export interface CheckUsermanagerEmail_elasticSearchPages_userManager {
  data: CheckUsermanagerEmail_elasticSearchPages_userManager_data;
}

export interface CheckUsermanagerEmail_elasticSearchPages {
  userManager: CheckUsermanagerEmail_elasticSearchPages_userManager;
}

export interface CheckUsermanagerEmail {
  elasticSearchPages: CheckUsermanagerEmail_elasticSearchPages;
}

export interface CheckUsermanagerEmailVariables {
  filters?: string | null;
  freeTextQuery?: string[] | null;
  sortDirection: SortDirection;
  sortProperty: UserManagerSortEnum;
  first: number;
  offset: number;
  deletedOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckUsernameAvailible
// ====================================================

export interface CheckUsernameAvailible {
  checkUsernameAvailible: boolean;
}

export interface CheckUsernameAvailibleVariables {
  userName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetCreateOrModifyUserTicket
// ====================================================

export interface GetCreateOrModifyUserTicket_getCreateOrModifyUserTicket {
  requestItem: string;
  accessTag: string | null;
  fullName: string | null;
  yaraNumber: string | null;
  onBehalfOf: string | null;
  description: string | null;
  action: string | null;
  businessSegment: string | null;
  email: string | null;
  phoneNumber: string | null;
  serviceNowUrl: string | null;
}

export interface GetCreateOrModifyUserTicket {
  getCreateOrModifyUserTicket: GetCreateOrModifyUserTicket_getCreateOrModifyUserTicket | null;
}

export interface GetCreateOrModifyUserTicketVariables {
  ticketNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllFeaturesAndColumns
// ====================================================

export interface AllFeaturesAndColumns_featuresAndColumns_features {
  featureId: number;
  name: string;
  group: string;
  read: boolean;
  write: boolean;
  delete: boolean;
}

export interface AllFeaturesAndColumns_featuresAndColumns_columns {
  columnId: number;
  name: string;
  group: string;
  isDefault: boolean;
}

export interface AllFeaturesAndColumns_featuresAndColumns {
  features: AllFeaturesAndColumns_featuresAndColumns_features[];
  columns: AllFeaturesAndColumns_featuresAndColumns_columns[];
}

export interface AllFeaturesAndColumns {
  featuresAndColumns: AllFeaturesAndColumns_featuresAndColumns;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllRoleTemplates
// ====================================================

export interface AllRoleTemplates_roleTemplates_features {
  featureId: number;
  name: string;
  group: string;
  read: boolean;
  write: boolean;
  delete: boolean;
}

export interface AllRoleTemplates_roleTemplates_columns {
  columnId: number;
  name: string;
  group: string;
  isDefault: boolean;
}

export interface AllRoleTemplates_roleTemplates {
  name: string;
  roleTemplateId: number;
  features: AllRoleTemplates_roleTemplates_features[];
  columns: AllRoleTemplates_roleTemplates_columns[];
  internalOnly: boolean;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
  securityLevel: number | null;
  inactivityExpirationDays: number;
}

export interface AllRoleTemplates {
  roleTemplates: AllRoleTemplates_roleTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveRoleTemplate
// ====================================================

export interface SaveRoleTemplate_saveRoleTemplate {
  code: string;
  message: string;
  success: boolean;
}

export interface SaveRoleTemplate {
  saveRoleTemplate: SaveRoleTemplate_saveRoleTemplate;
}

export interface SaveRoleTemplateVariables {
  roleTemplate: RoleTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DuplicateRoleTemplate
// ====================================================

export interface DuplicateRoleTemplate_duplicateRoleTemplate {
  code: string;
  message: string;
  success: boolean;
}

export interface DuplicateRoleTemplate {
  duplicateRoleTemplate: DuplicateRoleTemplate_duplicateRoleTemplate | null;
}

export interface DuplicateRoleTemplateVariables {
  roleTemplateId: number;
  newName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewRoleTemplate
// ====================================================

export interface NewRoleTemplate_newRoleTemplate {
  code: string;
  message: string;
  success: boolean;
}

export interface NewRoleTemplate {
  newRoleTemplate: NewRoleTemplate_newRoleTemplate | null;
}

export interface NewRoleTemplateVariables {
  newName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRoleTemplate
// ====================================================

export interface DeleteRoleTemplate_deleteRoleTemplate {
  code: string;
  message: string;
  success: boolean;
}

export interface DeleteRoleTemplate {
  deleteRoleTemplate: DeleteRoleTemplate_deleteRoleTemplate | null;
}

export interface DeleteRoleTemplateVariables {
  roleTemplateId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableBuids
// ====================================================

export interface AvailableBuids_buids {
  name: string | null;
  buidId: string | null;
}

export interface AvailableBuids {
  buids: AvailableBuids_buids[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableAccssTags
// ====================================================

export interface AvailableAccssTags_customers {
  name: string | null;
  customerId: string;
  buidId: number | null;
}

export interface AvailableAccssTags {
  customers: AvailableAccssTags_customers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserManagerListQuery
// ====================================================

export interface UserManagerListQuery_elasticSearchPages_userManager_data_edges_securityLevel {
  securityLevelId: string;
  securityLevelName: string;
}

export interface UserManagerListQuery_elasticSearchPages_userManager_data_edges_customer {
  customerId: string;
  customerName: string;
}

export interface UserManagerListQuery_elasticSearchPages_userManager_data_edges_buid {
  buidId: string;
  buidName: string;
}

export interface UserManagerListQuery_elasticSearchPages_userManager_data_edges {
  userId: string;
  loginName: string | null;
  accessToUserManager: boolean;
  name: string | null;
  email: string | null;
  serviceNowTicket: string | null;
  description: string | null;
  lastLogin: string | null;
  reminderEmailSent: string | null;
  phoneNumber: string | null;
  isSuperUser: boolean;
  internalUser: boolean;
  invitationSentDate: string | null;
  language: string | null;
  roleTemplateDescription: string | null;
  inactivityExpirationDays: number | null;
  deleted: boolean;
  modified: string | null;
  securityLevel: UserManagerListQuery_elasticSearchPages_userManager_data_edges_securityLevel;
  customer: UserManagerListQuery_elasticSearchPages_userManager_data_edges_customer | null;
  buid: UserManagerListQuery_elasticSearchPages_userManager_data_edges_buid | null;
}

export interface UserManagerListQuery_elasticSearchPages_userManager_data {
  totalCount: number;
  edges: UserManagerListQuery_elasticSearchPages_userManager_data_edges[];
}

export interface UserManagerListQuery_elasticSearchPages_userManager {
  data: UserManagerListQuery_elasticSearchPages_userManager_data;
}

export interface UserManagerListQuery_elasticSearchPages {
  userManager: UserManagerListQuery_elasticSearchPages_userManager;
}

export interface UserManagerListQuery {
  elasticSearchPages: UserManagerListQuery_elasticSearchPages;
}

export interface UserManagerListQueryVariables {
  filters?: string | null;
  freeTextQuery?: string[] | null;
  sortDirection: SortDirection;
  sortProperty: UserManagerSortEnum;
  first: number;
  offset: number;
  deletedOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserManagerListIdsQuery
// ====================================================

export interface UserManagerListIdsQuery_elasticSearchPages_userManager_data_edges {
  userId: string;
}

export interface UserManagerListIdsQuery_elasticSearchPages_userManager_data {
  edges: UserManagerListIdsQuery_elasticSearchPages_userManager_data_edges[];
}

export interface UserManagerListIdsQuery_elasticSearchPages_userManager {
  data: UserManagerListIdsQuery_elasticSearchPages_userManager_data;
}

export interface UserManagerListIdsQuery_elasticSearchPages {
  userManager: UserManagerListIdsQuery_elasticSearchPages_userManager;
}

export interface UserManagerListIdsQuery {
  elasticSearchPages: UserManagerListIdsQuery_elasticSearchPages;
}

export interface UserManagerListIdsQueryVariables {
  freeTextQuery?: string[] | null;
  sortDirection?: SortDirection | null;
  sortProperty?: UserManagerSortEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserManagerListExcelQuery
// ====================================================

export interface UserManagerListExcelQuery_elasticSearchPages_userManager {
  excelSheet: string;
}

export interface UserManagerListExcelQuery_elasticSearchPages {
  userManager: UserManagerListExcelQuery_elasticSearchPages_userManager;
}

export interface UserManagerListExcelQuery {
  elasticSearchPages: UserManagerListExcelQuery_elasticSearchPages;
}

export interface UserManagerListExcelQueryVariables {
  filters?: string | null;
  columnKeyAndTitle: ColumnKeyAndTitle[];
  freeTextQuery?: string[] | null;
  sortDirection: SortDirection;
  sortProperty: UserManagerSortEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteControllersMutation
// ====================================================

export interface DeleteControllersMutation_deleteControllers {
  code: string;
  message: string;
  success: boolean;
}

export interface DeleteControllersMutation {
  deleteControllers: DeleteControllersMutation_deleteControllers;
}

export interface DeleteControllersMutationVariables {
  controllerIds: number[];
  alsoSuspendSimCards: boolean;
  decommissionReason: number;
  decommissionReasonText?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ElasticPageLastUpdated
// ====================================================

export interface ElasticPageLastUpdated_elasticSearchPages {
  lastUpdated: number | null;
}

export interface ElasticPageLastUpdated {
  elasticSearchPages: ElasticPageLastUpdated_elasticSearchPages;
}

export interface ElasticPageLastUpdatedVariables {
  page: ElasticSearchPage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCommandToController
// ====================================================

export interface SendCommandToController_sendCommandToController {
  statusCode: number | null;
  result: string | null;
}

export interface SendCommandToController {
  sendCommandToController: SendCommandToController_sendCommandToController;
}

export interface SendCommandToControllerVariables {
  controllerId: number;
  command: string;
  slaveNumber?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SiteStatusBarFragment
// ====================================================

export interface SiteStatusBarFragment_channels {
  isParked: boolean | null;
}

export interface SiteStatusBarFragment_alarms {
  alarmId: string | null;
  active: boolean | null;
}

export interface SiteStatusBarFragment_controllers {
  isVirual: boolean | null;
  controllerType: string;
  controllerId: string | null;
}

export interface SiteStatusBarFragment {
  siteId: string;
  signal: SignalEnum;
  channels: SiteStatusBarFragment_channels[];
  alarms: SiteStatusBarFragment_alarms[];
  controllers: SiteStatusBarFragment_controllers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TankTrendChartChannelDetailsFragment
// ====================================================

export interface TankTrendChartChannelDetailsFragment_tankDetails_tankRefills {
  refillId: number | null;
  timeStamp: string;
  amountFilled: number | null;
}

export interface TankTrendChartChannelDetailsFragment_tankDetails_plannedTankRefills {
  plannedTankRefillId: number | null;
  timeStamp: string;
}

export interface TankTrendChartChannelDetailsFragment_tankDetails {
  tankRefills: (TankTrendChartChannelDetailsFragment_tankDetails_tankRefills | null)[] | null;
  plannedTankRefills: (TankTrendChartChannelDetailsFragment_tankDetails_plannedTankRefills | null)[] | null;
}

export interface TankTrendChartChannelDetailsFragment_unit {
  decimals: number;
  sapCode: string;
  symbol: string;
  translationKey: string;
  unitId: string;
}

export interface TankTrendChartChannelDetailsFragment {
  tankDetails: TankTrendChartChannelDetailsFragment_tankDetails | null;
  unit: TankTrendChartChannelDetailsFragment_unit | null;
  maximum: number | null;
  minimum: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SitecardFragment
// ====================================================

export interface SitecardFragment_channels {
  isParked: boolean | null;
}

export interface SitecardFragment_alarms {
  alarmId: string | null;
  active: boolean | null;
}

export interface SitecardFragment_controllers {
  isVirual: boolean | null;
  controllerType: string;
  controllerId: string | null;
}

export interface SitecardFragment_tanks_product_translations {
  translation: string | null;
  languageCode: string | null;
}

export interface SitecardFragment_tanks_product {
  productId: string;
  name: string | null;
  translations: SitecardFragment_tanks_product_translations[];
}

export interface SitecardFragment_tanks_unit {
  decimals: number;
  symbol: string;
  unitId: string;
}

export interface SitecardFragment_tanks_tankDetails {
  reachMinimum: string | null;
  percentage: number | null;
}

export interface SitecardFragment_tanks {
  maximum: number | null;
  minimum: number | null;
  capacity: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  product: SitecardFragment_tanks_product | null;
  unit: SitecardFragment_tanks_unit | null;
  tankDetails: SitecardFragment_tanks_tankDetails | null;
}

export interface SitecardFragment {
  siteId: string;
  signal: SignalEnum;
  channels: SitecardFragment_channels[];
  alarms: SitecardFragment_alarms[];
  controllers: SitecardFragment_controllers[];
  alias: string;
  tanks: SitecardFragment_tanks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SiteCardFragment
// ====================================================

export interface SiteCardFragment_channels {
  isParked: boolean | null;
}

export interface SiteCardFragment_alarms {
  alarmId: string | null;
  active: boolean | null;
}

export interface SiteCardFragment_controllers {
  isVirual: boolean | null;
  controllerType: string;
  controllerId: string | null;
}

export interface SiteCardFragment_tanks_product_translations {
  translation: string | null;
  languageCode: string | null;
}

export interface SiteCardFragment_tanks_product {
  productId: string;
  name: string | null;
  translations: SiteCardFragment_tanks_product_translations[];
}

export interface SiteCardFragment_tanks_unit {
  decimals: number;
  symbol: string;
  unitId: string;
}

export interface SiteCardFragment_tanks_tankDetails {
  shipTo: string | null;
  reachMinimum: string | null;
  percentage: number | null;
}

export interface SiteCardFragment_tanks {
  maximum: number | null;
  minimum: number | null;
  capacity: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  product: SiteCardFragment_tanks_product | null;
  unit: SiteCardFragment_tanks_unit | null;
  tankDetails: SiteCardFragment_tanks_tankDetails | null;
}

export interface SiteCardFragment {
  siteId: string;
  signal: SignalEnum;
  channels: SiteCardFragment_channels[];
  alarms: SiteCardFragment_alarms[];
  controllers: SiteCardFragment_controllers[];
  alias: string;
  tanks: SiteCardFragment_tanks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportScheduleDetailsFragment
// ====================================================

export interface ReportScheduleDetailsFragment {
  enabled: boolean | null;
  recurrence: ReportScheduleRecurrence;
  recurrenceDetails: number | null;
  reportScheduleId: string | null;
  scheduleName: string | null;
  triggerTimeLocal: string;
  timeZoneId: number;
  systemReportRecipients: string | null;
  scheduleType: ScheduleTypeEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportScheduleReportsFragment
// ====================================================

export interface ReportScheduleReportsFragment_scheduledReports_filter {
  filterId: string;
  name: string;
}

export interface ReportScheduleReportsFragment_scheduledReports_productFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
  translationKeys: string[];
}

export interface ReportScheduleReportsFragment_scheduledReports_buidFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleReportsFragment_scheduledReports_controllerTypeFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleReportsFragment_scheduledReports_accessTagFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleReportsFragment_scheduledReports_soldToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleReportsFragment_scheduledReports_shipToFilters {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface ReportScheduleReportsFragment_scheduledReports {
  reportFormat: ReportFormatEnum | null;
  filterId: number | null;
  filter: ReportScheduleReportsFragment_scheduledReports_filter | null;
  productFilters: ReportScheduleReportsFragment_scheduledReports_productFilters[] | null;
  buidFilters: ReportScheduleReportsFragment_scheduledReports_buidFilters[] | null;
  controllerTypeFilters: ReportScheduleReportsFragment_scheduledReports_controllerTypeFilters[] | null;
  accessTagFilters: ReportScheduleReportsFragment_scheduledReports_accessTagFilters[] | null;
  soldToFilters: ReportScheduleReportsFragment_scheduledReports_soldToFilters[] | null;
  shipToFilters: ReportScheduleReportsFragment_scheduledReports_shipToFilters[] | null;
  scheduledReportId: string | null;
  reportPeriod: ReportPeriodEnum;
  report: Reports;
}

export interface ReportScheduleReportsFragment {
  scheduledReports: ReportScheduleReportsFragment_scheduledReports[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SiteDetailsGalleryDetailsQueryFragment
// ====================================================

export interface SiteDetailsGalleryDetailsQueryFragment_uploadedByUser {
  userId: number;
  name: string;
}

export interface SiteDetailsGalleryDetailsQueryFragment {
  siteGalleryItemId: string | null;
  tags: string[] | null;
  thumbnailUrl: string | null;
  imageUrl: string | null;
  sizeInBytes: number | null;
  uploadedTimestamp: string | null;
  fileName: string | null;
  uploadedByUser: SiteDetailsGalleryDetailsQueryFragment_uploadedByUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SitedetailsContactFragment
// ====================================================

export interface SitedetailsContactFragment {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TankRefillContactsFragment
// ====================================================

export interface TankRefillContactsFragment {
  avatarUrl: string | null;
  telephoneNumber: string | null;
  email: string | null;
  name: string;
  userId: string | null;
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReactSiteStatusCardChannelFragment
// ====================================================

export interface ReactSiteStatusCardChannelFragment_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardChannelFragment_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardChannelFragment_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardChannelFragment_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardChannelFragment_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardChannelFragment_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardChannelFragment_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardChannelFragment_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardChannelFragment_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardChannelFragment_product {
  name: string;
}

export interface ReactSiteStatusCardChannelFragment {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardChannelFragment_unit | null;
  tankDetails: ReactSiteStatusCardChannelFragment_tankDetails | null;
  gasDetails: ReactSiteStatusCardChannelFragment_gasDetails | null;
  doseDetails: ReactSiteStatusCardChannelFragment_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardChannelFragment_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardChannelFragment_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardChannelFragment_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardChannelFragment_conductivityChannelDetails | null;
  product: ReactSiteStatusCardChannelFragment_product | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReactSiteStatusCardFragment
// ====================================================

export interface ReactSiteStatusCardFragment_tankChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_tankChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_tankChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_tankChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_tankChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_tankChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_tankChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_tankChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_tankChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_tankChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_tankChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_tankChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_tankChannels_product | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_gasChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_gasChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_gasChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_gasChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_gasChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_gasChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_gasChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_gasChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_gasChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_gasChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_gasChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_gasChannels_product | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_doseChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_doseChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_doseChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_doseChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_doseChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_doseChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_doseChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_doseChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_doseChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_doseChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_doseChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_doseChannels_product | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_flowChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_flowChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_flowChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_flowChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_flowChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_flowChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_flowChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_flowChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_flowChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_flowChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_flowChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_flowChannels_product | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_batteryChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_batteryChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_batteryChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_batteryChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_batteryChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_batteryChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_batteryChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_batteryChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_batteryChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_batteryChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_batteryChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_batteryChannels_product | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_stockVsDoseChannels25_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels25 {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_stockVsDoseChannels25_unit | null;
  tankDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels25_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_stockVsDoseChannels25_product | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_stockVsDoseChannels5_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_stockVsDoseChannels5 {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_stockVsDoseChannels5_unit | null;
  tankDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_stockVsDoseChannels5_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_stockVsDoseChannels5_product | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_temperatureChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_temperatureChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_temperatureChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_temperatureChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_temperatureChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_temperatureChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_temperatureChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_temperatureChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_temperatureChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_temperatureChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_temperatureChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_temperatureChannels_product | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_retentionTimeChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_retentionTimeChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_retentionTimeChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_retentionTimeChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_retentionTimeChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_retentionTimeChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_retentionTimeChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_retentionTimeChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_retentionTimeChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_retentionTimeChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_retentionTimeChannels_product | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: ReactSiteStatusCardFragment_conductivityChannels_unit_unitWithoutInterval | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_tankDetails {
  reachMinimum: string | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_gasDetails {
  maxPpm: number | null;
  meanPpm: number | null;
  percentTimeAboveLimit: number | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_temperatureChannelDetails {
  intervalMin: number | null;
  intervalMax: number | null;
  intervalMean: number | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface ReactSiteStatusCardFragment_conductivityChannels_product {
  name: string;
}

export interface ReactSiteStatusCardFragment_conductivityChannels {
  alias: string;
  siteChannelId: number;
  minimum: number | null;
  maximum: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  capacity: number | null;
  channelType: number;
  percentage: number | null;
  unit: ReactSiteStatusCardFragment_conductivityChannels_unit | null;
  tankDetails: ReactSiteStatusCardFragment_conductivityChannels_tankDetails | null;
  gasDetails: ReactSiteStatusCardFragment_conductivityChannels_gasDetails | null;
  doseDetails: ReactSiteStatusCardFragment_conductivityChannels_doseDetails | null;
  temperatureChannelDetails: ReactSiteStatusCardFragment_conductivityChannels_temperatureChannelDetails | null;
  flowChannelDetails: ReactSiteStatusCardFragment_conductivityChannels_flowChannelDetails | null;
  stockVsDoseAccuracyChannelDetails: ReactSiteStatusCardFragment_conductivityChannels_stockVsDoseAccuracyChannelDetails | null;
  conductivityChannelDetails: ReactSiteStatusCardFragment_conductivityChannels_conductivityChannelDetails | null;
  product: ReactSiteStatusCardFragment_conductivityChannels_product | null;
}

export interface ReactSiteStatusCardFragment {
  siteId: string;
  alias: string;
  signal: SignalEnum;
  isParked: boolean;
  hasActiveChannelAlarms: boolean;
  lastSampleTime: string | null;
  isVirtual: boolean;
  tankChannels: ReactSiteStatusCardFragment_tankChannels[] | null;
  gasChannels: ReactSiteStatusCardFragment_gasChannels[] | null;
  doseChannels: ReactSiteStatusCardFragment_doseChannels[] | null;
  flowChannels: ReactSiteStatusCardFragment_flowChannels[] | null;
  batteryChannels: ReactSiteStatusCardFragment_batteryChannels[] | null;
  stockVsDoseChannels25: ReactSiteStatusCardFragment_stockVsDoseChannels25[] | null;
  stockVsDoseChannels5: ReactSiteStatusCardFragment_stockVsDoseChannels5[] | null;
  temperatureChannels: ReactSiteStatusCardFragment_temperatureChannels[] | null;
  retentionTimeChannels: ReactSiteStatusCardFragment_retentionTimeChannels[] | null;
  conductivityChannels: ReactSiteStatusCardFragment_conductivityChannels[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: stockVsDoseFragment
// ====================================================

export interface stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface stockVsDoseFragment_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: stockVsDoseFragment_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface stockVsDoseFragment_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface stockVsDoseFragment_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface stockVsDoseFragment {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  stockVsDoseAccuracyChannelDetails: stockVsDoseFragment_stockVsDoseAccuracyChannelDetails | null;
  unit: stockVsDoseFragment_unit | null;
  activeAlarms: stockVsDoseFragment_activeAlarms[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: sitelistItem
// ====================================================

export interface sitelistItem_salesContact {
  contactId: number;
  initials: string | null;
}

export interface sitelistItem_techContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface sitelistItem_orderContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface sitelistItem_customerContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface sitelistItem_transportContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface sitelistItem_dealerContact {
  contactId: number;
  initials: string | null;
  name: string | null;
}

export interface sitelistItem_buid {
  name: string;
  buidId: number;
}

export interface sitelistItem_customer {
  name: string;
  customerId: number;
  alias: string | null;
}

export interface sitelistItem_doseChannels_doseDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
  customerFactor: number | null;
  customerFactorSecurityLevel: number | null;
  customerFactorUnit: string | null;
}

export interface sitelistItem_doseChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_doseChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: sitelistItem_doseChannels_unit_unitWithoutInterval | null;
}

export interface sitelistItem_doseChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_doseChannels {
  channelType: number;
  timeZoneId: number | null;
  doseDetails: sitelistItem_doseChannels_doseDetails | null;
  unit: sitelistItem_doseChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: sitelistItem_doseChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem_retentionTimeChannels_retentionTimeDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface sitelistItem_retentionTimeChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_retentionTimeChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: sitelistItem_retentionTimeChannels_unit_unitWithoutInterval | null;
}

export interface sitelistItem_retentionTimeChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_retentionTimeChannels {
  channelType: number;
  timeZoneId: number | null;
  retentionTimeDetails: sitelistItem_retentionTimeChannels_retentionTimeDetails | null;
  unit: sitelistItem_retentionTimeChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: sitelistItem_retentionTimeChannels_activeAlarms[] | null;
}

export interface sitelistItem_batteryChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_batteryChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_batteryChannels {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  batteryRuntimeService: number | null;
  maximum: number | null;
  capacity: number | null;
  minimum: number | null;
  percentage: number | null;
  alias: string;
  health: HealthEnum | null;
  unit: sitelistItem_batteryChannels_unit | null;
  activeAlarms: sitelistItem_batteryChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem_gasChannels_gasDetails {
  meanPpm: number | null;
  maxPpm: number | null;
  percentTimeAboveLimit: number | null;
  h2SRuntimeCalibration: number | null;
}

export interface sitelistItem_gasChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_gasChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_gasChannels {
  channelType: number;
  timeZoneId: number | null;
  gasDetails: sitelistItem_gasChannels_gasDetails | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  alias: string;
  isParked: boolean | null;
  unit: sitelistItem_gasChannels_unit | null;
  activeAlarms: sitelistItem_gasChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem_flowChannels_flowChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
  intervalSum: number | null;
}

export interface sitelistItem_flowChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_flowChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: sitelistItem_flowChannels_unit_unitWithoutInterval | null;
}

export interface sitelistItem_flowChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_flowChannels {
  channelType: number;
  timeZoneId: number | null;
  flowChannelDetails: sitelistItem_flowChannels_flowChannelDetails | null;
  unit: sitelistItem_flowChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: sitelistItem_flowChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem_temperatureChannels_temperatureChannelDetails {
  intervalMax: number | null;
  intervalMin: number | null;
  intervalMean: number | null;
}

export interface sitelistItem_temperatureChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_temperatureChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_temperatureChannels {
  channelType: number;
  timeZoneId: number | null;
  temperatureChannelDetails: sitelistItem_temperatureChannels_temperatureChannelDetails | null;
  unit: sitelistItem_temperatureChannels_unit | null;
  alias: string;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  activeAlarms: sitelistItem_temperatureChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface sitelistItem_stockVsDoseChannels5_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_stockVsDoseChannels5_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_stockVsDoseChannels5 {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  stockVsDoseAccuracyChannelDetails: sitelistItem_stockVsDoseChannels5_stockVsDoseAccuracyChannelDetails | null;
  unit: sitelistItem_stockVsDoseChannels5_unit | null;
  activeAlarms: sitelistItem_stockVsDoseChannels5_activeAlarms[] | null;
}

export interface sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel {
  lastSample: number | null;
  unit: sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel_unit | null;
}

export interface sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel {
  lastSample: number | null;
  unit: sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel_unit | null;
}

export interface sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannel: sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedDoseChannel | null;
  aggregatedTankUsageChannel: sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails_aggregatedTankUsageChannel | null;
  tankUsage: TankUsage | null;
}

export interface sitelistItem_stockVsDoseChannels25_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_stockVsDoseChannels25_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_stockVsDoseChannels25 {
  channelType: number;
  timeZoneId: number | null;
  lastSample: number | null;
  lastSampleTime: string | null;
  health: HealthEnum | null;
  stockVsDoseAccuracyChannelDetails: sitelistItem_stockVsDoseChannels25_stockVsDoseAccuracyChannelDetails | null;
  unit: sitelistItem_stockVsDoseChannels25_unit | null;
  activeAlarms: sitelistItem_stockVsDoseChannels25_activeAlarms[] | null;
}

export interface sitelistItem_tankChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_tankChannels_unit {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_tankChannels_tankDetails_lastTankRefill_attachments {
  attachmentUrl: string;
  attachmentName: string;
}

export interface sitelistItem_tankChannels_tankDetails_lastTankRefill {
  refillStart: string | null;
  amountFilled: number | null;
  attachments: sitelistItem_tankChannels_tankDetails_lastTankRefill_attachments[] | null;
}

export interface sitelistItem_tankChannels_tankDetails_vehicle {
  vehicleId: number;
  codeAndName: string;
}

export interface sitelistItem_tankChannels_tankDetails {
  reachMinimum: string | null;
  reachMinimumSpan: number | null;
  shipTo: string | null;
  customerProductRef: string | null;
  description: string | null;
  lastFilled: string | null;
  freeCapacity: number | null;
  nextPlannedFilling: string | null;
  haulierTag: string | null;
  lastTankRefill: sitelistItem_tankChannels_tankDetails_lastTankRefill | null;
  vehicle: sitelistItem_tankChannels_tankDetails_vehicle | null;
}

export interface sitelistItem_tankChannels_product {
  productId: number;
  name: string;
  alias_deDE: string;
  alias_enGB: string;
  alias_esES: string;
  alias_frFR: string;
  alias_huHU: string;
  alias_itIT: string;
  alias_nbNO: string;
  alias_nlNL: string;
  alias_plPL: string;
  alias_svSE: string;
}

export interface sitelistItem_tankChannels {
  alias: string;
  timeZoneId: number | null;
  channelId: number;
  lastSampleTime: string | null;
  lastSample: number | null;
  siteChannelId: number;
  capacity: number | null;
  maximum: number | null;
  minimum: number | null;
  parkedDate: string | null;
  parkedDescription: string | null;
  isParked: boolean | null;
  channelType: number;
  health: HealthEnum | null;
  activeAlarms: sitelistItem_tankChannels_activeAlarms[] | null;
  percentage: number | null;
  unit: sitelistItem_tankChannels_unit | null;
  tankDetails: sitelistItem_tankChannels_tankDetails | null;
  product: sitelistItem_tankChannels_product | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem_conductivityChannels_conductivityChannelDetails {
  intervalMax: number | null;
  intervalMean: number | null;
  intervalMin: number | null;
}

export interface sitelistItem_conductivityChannels_unit_unitWithoutInterval {
  decimals: number | null;
  symbol: string | null;
}

export interface sitelistItem_conductivityChannels_unit {
  decimals: number | null;
  symbol: string | null;
  unitWithoutInterval: sitelistItem_conductivityChannels_unit_unitWithoutInterval | null;
}

export interface sitelistItem_conductivityChannels_activeAlarms {
  name: string | null;
  alarmType: number;
}

export interface sitelistItem_conductivityChannels {
  channelType: number;
  timeZoneId: number | null;
  conductivityChannelDetails: sitelistItem_conductivityChannels_conductivityChannelDetails | null;
  unit: sitelistItem_conductivityChannels_unit | null;
  alias: string;
  lastSampleTime: string | null;
  isParked: boolean | null;
  health: HealthEnum | null;
  activeAlarms: sitelistItem_conductivityChannels_activeAlarms[] | null;
  controllerType: string | null;
  controllerSerial: string | null;
  controllerAlternateSerial: string | null;
  controllerIcc: string | null;
  controllerIpAddress: string | null;
  controllerId: number | null;
  controllerLatitude: number | null;
  controllerLongitude: number | null;
}

export interface sitelistItem {
  siteId: string;
  alias: string;
  soldTo: string | null;
  countryCode: string | null;
  postcode: string | null;
  signal: SignalEnum;
  contractReference: string | null;
  customerRef: string | null;
  creationServiceNowTicket: string | null;
  description: string | null;
  projectCode: string | null;
  lastSampleTime: string | null;
  hasActiveChannelAlarms: boolean;
  numberOfActiveAlarms: number;
  hasEquipment: boolean;
  hasGalleryItems: boolean;
  alarmsAreMuted: boolean;
  hasDeliveryTickets: boolean;
  tankWithLatestDeliveryTicket: number | null;
  isVirtual: boolean;
  isParked: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  salesContact: sitelistItem_salesContact | null;
  techContact: sitelistItem_techContact | null;
  orderContact: sitelistItem_orderContact | null;
  customerContact: sitelistItem_customerContact | null;
  transportContact: sitelistItem_transportContact | null;
  dealerContact: sitelistItem_dealerContact | null;
  buid: sitelistItem_buid | null;
  customer: sitelistItem_customer | null;
  doseChannels: sitelistItem_doseChannels[] | null;
  retentionTimeChannels: sitelistItem_retentionTimeChannels[] | null;
  batteryChannels: sitelistItem_batteryChannels[] | null;
  gasChannels: sitelistItem_gasChannels[] | null;
  flowChannels: sitelistItem_flowChannels[] | null;
  temperatureChannels: sitelistItem_temperatureChannels[] | null;
  stockVsDoseChannels5: sitelistItem_stockVsDoseChannels5[] | null;
  stockVsDoseChannels25: sitelistItem_stockVsDoseChannels25[] | null;
  tankChannels: sitelistItem_tankChannels[] | null;
  conductivityChannels: sitelistItem_conductivityChannels[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlarmTypeEnum {
  BrokenSensor = "BrokenSensor",
  Configuration = "Configuration",
  Custom = "Custom",
  DoseCalibration = "DoseCalibration",
  LowLevel = "LowLevel",
  Offline = "Offline",
  PercentCapacity30 = "PercentCapacity30",
  PreLowLevel = "PreLowLevel",
  SeriousDoseCalibration = "SeriousDoseCalibration",
}

export enum ContactSortEnum {
  Buid = "Buid",
  ContactId = "ContactId",
  Customer = "Customer",
  Email = "Email",
  Name = "Name",
}

export enum ContactType {
  Customer = "Customer",
  Dealer = "Dealer",
  Order = "Order",
  Sales = "Sales",
  Tech = "Tech",
  Transport = "Transport",
}

export enum ControllersSortEnum {
  Alias = "Alias",
  AlternateSerial = "AlternateSerial",
  Buid = "Buid",
  CommunicationManagerVersion = "CommunicationManagerVersion",
  ControllerType = "ControllerType",
  CountryCode = "CountryCode",
  Created = "Created",
  CreatedBy = "CreatedBy",
  ICC = "ICC",
  IpAddress = "IpAddress",
  IsArchived = "IsArchived",
  LastBackupDate = "LastBackupDate",
  LastSampleTime = "LastSampleTime",
  MSISDN = "MSISDN",
  Name = "Name",
  Provider = "Provider",
  Serial = "Serial",
  Signal = "Signal",
  SimNotes = "SimNotes",
  SiteName = "SiteName",
  TimeZone = "TimeZone",
  Version = "Version",
}

export enum DecommissionedControllersSortEnum {
  Alias = "Alias",
  AlternateSerial = "AlternateSerial",
  Buid = "Buid",
  ControllerType = "ControllerType",
  DecommissionReason = "DecommissionReason",
  DecommissionReasonText = "DecommissionReasonText",
  Decommissioned = "Decommissioned",
  DecommissionedBy = "DecommissionedBy",
  ICC = "ICC",
  IpAddress = "IpAddress",
  LastSampleTime = "LastSampleTime",
  Provider = "Provider",
  SIMActive = "SIMActive",
  SIMNotes = "SIMNotes",
  Serial = "Serial",
  SimCardType = "SimCardType",
}

export enum ElasticSearchPage {
  ControllerManager = "ControllerManager",
  DecommissionedControllers = "DecommissionedControllers",
  DeliveryNotes = "DeliveryNotes",
  HaulierManager = "HaulierManager",
  SimDataUsage = "SimDataUsage",
  SimDeviceMap = "SimDeviceMap",
  Sitelist = "Sitelist",
  UserManager = "UserManager",
}

export enum HaulierSortEnum {
  Buid = "Buid",
  HaulierTag = "HaulierTag",
  Name = "Name",
  Reference = "Reference",
  ShortName = "ShortName",
  Source = "Source",
}

export enum HealthEnum {
  HasAlarm = "HasAlarm",
  Offline = "Offline",
  Online = "Online",
  Parked = "Parked",
  Stale = "Stale",
}

export enum MarkerIconEnum {
  Alarm = "Alarm",
  ControllerOffline = "ControllerOffline",
  ControllerOnline = "ControllerOnline",
  EasyFeed = "EasyFeed",
  H2SHigh = "H2SHigh",
  H2SOffline = "H2SOffline",
  H2SOk = "H2SOk",
  ParkedOffline = "ParkedOffline",
  ParkedOnline = "ParkedOnline",
  TankLevelAbove50percent = "TankLevelAbove50percent",
  TankLevelAboveMinimim = "TankLevelAboveMinimim",
  TankOffline = "TankOffline",
  Virtual = "Virtual",
}

export enum PreserveConfigModeEnum {
  None = "None",
  TakeNewBackup = "TakeNewBackup",
  UseExistingBackup = "UseExistingBackup",
}

export enum ReportFormatEnum {
  Csv = "Csv",
  Excel = "Excel",
  Pdf = "Pdf",
}

export enum ReportPeriodEnum {
  Custom = "Custom",
  Previous6Months = "Previous6Months",
  PreviousDay = "PreviousDay",
  PreviousMonth = "PreviousMonth",
  PreviousQuarter = "PreviousQuarter",
  PreviousWeek = "PreviousWeek",
  PreviousYear = "PreviousYear",
}

export enum ReportScheduleRecurrence {
  Daily = "Daily",
  FirstDayOfHalfYear = "FirstDayOfHalfYear",
  FirstDayOfMonth = "FirstDayOfMonth",
  FirstDayOfQuarter = "FirstDayOfQuarter",
  FirstDayOfYear = "FirstDayOfYear",
  MonthlyByDate = "MonthlyByDate",
  MonthlyByFirstWeekDay = "MonthlyByFirstWeekDay",
  Never = "Never",
  WeeklyByWeekDay = "WeeklyByWeekDay",
}

export enum Reports {
  Alarm = "Alarm",
  DailyDose = "DailyDose",
  DeviceType = "DeviceType",
  Dosing = "Dosing",
  DryRun = "DryRun",
  GlobalStatistics = "GlobalStatistics",
  H2SLevel = "H2SLevel",
  IdaDeliveries = "IdaDeliveries",
  IdaLifecycle = "IdaLifecycle",
  MonthlyOffline = "MonthlyOffline",
  NewSites = "NewSites",
  NitratesReport = "NitratesReport",
  ProductStock = "ProductStock",
  SiteRefills = "SiteRefills",
  StockVsDose = "StockVsDose",
  Temperature = "Temperature",
  UserStatistics = "UserStatistics",
}

export enum ScheduleTypeEnum {
  System = "System",
  User = "User",
}

export enum ShippingLocationSortEnum {
  Buid = "Buid",
  CompanyName = "CompanyName",
  ReceivingPlace = "ReceivingPlace",
}

export enum SignalEnum {
  Offline = "Offline",
  Online = "Online",
  Stale = "Stale",
}

export enum SimDataUsageSortEnum {
  AlternateSerial = "AlternateSerial",
  Buid = "Buid",
  ControllerAlias = "ControllerAlias",
  ICC = "ICC",
  IMEI = "IMEI",
  IpAddress = "IpAddress",
  MSISDN = "MSISDN",
  Month = "Month",
  Serial = "Serial",
  SimCardType = "SimCardType",
  SiteAlias = "SiteAlias",
  Supplier = "Supplier",
  Usage = "Usage",
}

export enum SimDeviceMapSortEnum {
  Activated = "Activated",
  ActivatedByUserName = "ActivatedByUserName",
  AlternateSerial = "AlternateSerial",
  Buid = "Buid",
  CompanyName = "CompanyName",
  ControllerAlias = "ControllerAlias",
  ControllerId = "ControllerId",
  DecommissionedDate = "DecommissionedDate",
  DeviceType = "DeviceType",
  ICC = "ICC",
  Imported = "Imported",
  PackingSlip = "PackingSlip",
  PurchaseOrderNumber = "PurchaseOrderNumber",
  RMA = "RMA",
  RMACode = "RMACode",
  ReceivingPlace = "ReceivingPlace",
  Serial = "Serial",
  ShippedDate = "ShippedDate",
  Suspended = "Suspended",
  SuspendedByUserName = "SuspendedByUserName",
}

export enum SimOperationEnum {
  Activate = "Activate",
  Resume = "Resume",
  Suspend = "Suspend",
  Terminate = "Terminate",
}

export enum SimStatusEnum {
  Active = "Active",
  Inactive = "Inactive",
  Suspended = "Suspended",
  Terminated = "Terminated",
  Unknown = "Unknown",
}

export enum SiteDetailsTabEnum {
  Details = "Details",
  Graph = "Graph",
  Tanks = "Tanks",
}

export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc",
}

export enum StateEnum {
  Completed = "Completed",
  Failed = "Failed",
  Ignored = "Ignored",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
}

export enum TankUsage {
  FivePercent = "FivePercent",
  TwentyFivePercent = "TwentyFivePercent",
}

export enum UserManagerSortEnum {
  AccessToUserManager = "AccessToUserManager",
  BUID = "BUID",
  Customer = "Customer",
  Description = "Description",
  Email = "Email",
  InactivityExpirationDays = "InactivityExpirationDays",
  InternalUser = "InternalUser",
  InvitationSentDate = "InvitationSentDate",
  IsSuperUser = "IsSuperUser",
  Language = "Language",
  LastLogin = "LastLogin",
  Modified = "Modified",
  Name = "Name",
  PhoneNumber = "PhoneNumber",
  ReminderEmailSent = "ReminderEmailSent",
  RoleTemplateDescription = "RoleTemplateDescription",
  SecurityLevel = "SecurityLevel",
  ServiceNowTicket = "ServiceNowTicket",
  UserId = "UserId",
  Username = "Username",
}

export interface AddUserInfoInput {
  templateId?: number | null;
  customerId?: number | null;
  buidId?: number | null;
  loginName?: string | null;
  description?: string | null;
  neverExpires?: boolean | null;
  name?: string | null;
  email?: string | null;
  telephoneNumber?: string | null;
  internalUser?: boolean | null;
  serviceNowTicket?: string | null;
  userId?: number | null;
  securityLevel?: number | null;
  avatarUrl?: string | null;
  siteDetailsDefaultTab?: string | null;
  inactivityExpirationDays?: number | null;
}

export interface CheckPreserveConfigRequest {
  oldControllerSerial: string;
  newControllerSerial: string;
}

export interface ColumnKeyAndTitle {
  title: string;
  key: string;
}

export interface ContactInput {
  email?: string | null;
  name: string;
  telephoneNumber?: string | null;
  contactId?: string | null;
  buidId?: number | null;
  customerId?: number | null;
  userId?: string | null;
  description?: string | null;
  initAssignmentSiteId?: number | null;
  initAssignmentContactType?: ContactType | null;
  syncedWithUserInformation?: boolean | null;
}

export interface ControllerInput {
  controllerId?: number | null;
  serial: string;
  alias: string;
  controllerType?: string | null;
  buidId?: number | null;
  countryCode: string;
  timeZoneId?: number | null;
  isMasterDataSource?: boolean | null;
  numberOfSlaves?: number | null;
  ipAddress?: string | null;
  icc?: string | null;
  msisdn?: string | null;
  protocolVersion?: string | null;
  provider?: string | null;
  simNotes?: string | null;
}

export interface CreateSiteFilterInput {
  name: string;
  buidId?: number | null;
  customerId?: number | null;
  definition: string;
  isPublic: boolean;
  filterId: number;
  enabled: boolean;
}

export interface CreateSiteFromServiceNowTicketInput {
  ticketNumber: string;
  controllerId: number;
  buidId: number;
  accessTag: number;
  siteName: string;
  shipTo?: string | null;
  soldTo?: string | null;
  tankCapacity?: number | null;
  tankMinimumPercentage?: number | null;
  tankMinimum?: number | null;
  tankMaximumPercentage?: number | null;
  tankMaximum?: number | null;
  unitId?: number | null;
  productId?: number | null;
}

export interface CreateUserByCloningPermissionsInput {
  userIdToClone: number;
  loginName: string;
  name?: string | null;
  email: string;
  telephoneNumber?: string | null;
  description?: string | null;
}

export interface DeleteControllersInput {
  alsoSuspendSIMCards: boolean;
  decommissionReason: number;
  decommissionReasonText: string;
}

export interface DeviceReplacementJobRequest {
  oldControllerSerial: string;
  newControllerSerial: string;
  preserveConfigMode: PreserveConfigModeEnum;
  shouldTerminateSim: boolean;
  existingBackupAttachmentId?: number | null;
  serviceNowTicket?: string | null;
}

export interface DryRunReportSortingInput {
  SortColumn: string;
  SortDirection: string;
}

export interface ExcelReportSortingInput {
  SortColumn: string;
  SortDirection: string;
}

export interface ExportFieldForAddInput {
  exportJobId: number;
  field: number;
  order: number;
}

export interface ExportFieldForUpdateInput {
  exportFieldId: number;
  order: number;
}

export interface ExportFieldUpdateInput {
  adds: ExportFieldForAddInput[];
  updates: ExportFieldForUpdateInput[];
  deletes: number[];
}

export interface ExportJobCustomerInput {
  customerId: string;
  name?: string | null;
}

export interface ExportJobInput {
  exportJobId?: string | null;
  destination?: string | null;
  destinationFilePattern?: string | null;
  includeHeaders?: boolean | null;
  name?: string | null;
  exportTypeEnum: number;
  dateFormat?: string | null;
  delimiterChar?: string | null;
  repeatMinutes?: number | null;
  fixedSchedule?: string | null;
  enabled?: boolean | null;
  description?: string | null;
  contact?: string | null;
  channelTypes?: (number | null)[] | null;
  customers?: ExportJobCustomerInput[] | null;
}

export interface IdaDeliveriesReportSortingInput {
  SortColumn: string;
  SortDirection: string;
}

export interface IdaLifecycleReportSortingInput {
  SortColumn: string;
  SortDirection: string;
}

export interface ImportJobInput {
  importJobId?: number | null;
  name?: string | null;
  importTypeId?: number | null;
  status?: string | null;
  repeatMinutes?: number | null;
  settings?: string | null;
  scheduleType?: string | null;
  enabled?: boolean | null;
  lastRun?: any | null;
  notRunRecently?: boolean | null;
  importTypeName?: string | null;
  description?: string | null;
  contact?: string | null;
  lastResult?: string | null;
  success?: boolean | null;
  lastDuration?: string | null;
}

export interface InputAppliedRoleTemplateInfo {
  name: string;
  time: any;
}

export interface InputBatch {
  tanks: InputTank[];
  additional: string;
  name: string;
  checked: boolean;
  checkedByUserId?: number | null;
  checkedDate?: string | null;
}

export interface InputRecipe {
  weeks?: InputWeek[] | null;
  currentWeek?: number | null;
  name?: string | null;
}

export interface InputReportFilter {
  values: string[];
  partialMatch: boolean;
  exclude: boolean;
}

export interface InputServerSiteColumn {
  sortIndex: number;
  columnId: number;
  selected: boolean;
  columnWidth?: number | null;
  languageKey: string;
  columnGroupKey: string;
  connectedToUserFeature?: number | null;
  disabled: boolean;
}

export interface InputTank {
  name: string;
  products: InputTankProduct[];
}

export interface InputTankProduct {
  name: string;
  bags: string;
  color: string;
}

export interface InputUser {
  avatarUrl?: string | null;
  email: string;
  name: string;
  telephoneNumber?: string | null;
  userId: number;
  password: string;
}

export interface InputUserAccess {
  userId: number;
  userFeatures: InputUserAccessFeatureGroup[];
  userColumns: InputServerSiteColumn[];
  details: InputUserAccessDetails;
  lastAppliedRoleTemplate?: InputAppliedRoleTemplateInfo | null;
}

export interface InputUserAccessDetails {
  buidTags: string[];
  customerTags: string[];
  haulierTags: string[];
  hasAccessToAllBuids: boolean;
  hasAccessToAllCustomers: boolean;
  isSuperUser?: boolean | null;
  internalUser?: boolean | null;
  securityLevel: number;
}

export interface InputUserAccessFeature {
  translationKey: string;
  featureId: number;
  readEnabled: boolean;
  canRead: boolean;
  writeEnabled: boolean;
  canWrite: boolean;
  deleteEnabled: boolean;
  canDelete: boolean;
}

export interface InputUserAccessFeatureGroup {
  groupKey: string;
  features: InputUserAccessFeature[];
}

export interface InputWeek {
  weekNo: string;
  cropStage: string;
  batches: InputBatch[];
}

export interface Order {
  siteId: number;
  tankId: number;
  quantity: number;
}

export interface RoleTemplateColumnInput {
  columnId: number;
  group: string;
  name: string;
  isDefault: boolean;
}

export interface RoleTemplateFeatureInput {
  featureId: number;
  read: boolean;
  write: boolean;
  delete: boolean;
  group: string;
  name: string;
}

export interface RoleTemplateInput {
  name: string;
  roleTemplateId: number;
  features: RoleTemplateFeatureInput[];
  columns: RoleTemplateColumnInput[];
  internalOnly: boolean;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
  securityLevel?: number | null;
  inactivityExpirationDays?: number | null;
}

export interface ScheduledReportFilter {
  reportPeriod?: ReportPeriodEnum | null;
  alarmType?: (AlarmTypeEnum | null)[] | null;
  filterId?: string | null;
  accessTagFilters?: InputReportFilter[] | null;
  buidFilters?: InputReportFilter[] | null;
  controllerTypeFilters?: InputReportFilter[] | null;
  siteId?: string | null;
  productFilters?: InputReportFilter[] | null;
  shipToFilters?: InputReportFilter[] | null;
  soldToFilters?: InputReportFilter[] | null;
  fromDate?: any | null;
  fromDateOffset?: number | null;
  toDate?: any | null;
  toDateOffset?: number | null;
  reportFormat?: ReportFormatEnum | null;
}

export interface SimOperation {
  Operation: SimOperationEnum;
  ICC: string;
  UserId: number;
  BuidId: number;
  ControllerId?: number | null;
}

export interface SiteRefillsReportSortingInput {
  SortColumn: string;
  SortDirection: string;
}

export interface SystemMessageInput {
  systemMessageId?: number | null;
  startUtc: string;
  endUtc?: string | null;
  systemMessageTexts: (SystemMessageTextInput | null)[];
}

export interface SystemMessageTextInput {
  systemMessageTextId?: number | null;
  languageCode?: string | null;
  title?: string | null;
  message?: string | null;
}

export interface UpdateReleaseNoteFileInput {
  releaseNoteFileId: number;
  folderPath?: string | null;
  fileName?: string | null;
  publishedAfterDate?: string | null;
}

export interface UpdateSiteFilterInput {
  enabled: boolean;
  definition: string;
  filterId: number;
  isPublic: boolean;
  name: string;
}

export interface UpsertHaulierInput {
  reference: string;
  dataSource: string;
  name: string;
  shortName: string;
  buid: string;
}

export interface UpsertMultipleHaulierInput {
  buid?: string | null;
  dataSource?: string | null;
  haulierIds: number[];
}

export interface UpsertReportScheduleInput {
  reportScheduleId?: string | null;
  scheduleName?: string | null;
  scheduleType: ScheduleTypeEnum;
  systemReportRecipients?: string | null;
  recurrence: ReportScheduleRecurrence;
  recurrenceDetails?: number | null;
  enabled?: boolean | null;
  triggerTimeLocal: string;
  timeZoneId: number;
}

export interface UpsertScheduledReportInput {
  scheduledReportId?: string | null;
  reportPeriod?: ReportPeriodEnum | null;
  alarmType?: (AlarmTypeEnum | null)[] | null;
  filterId?: number | null;
  report: Reports;
  accessTagFilters?: InputReportFilter[] | null;
  buidFilters?: InputReportFilter[] | null;
  shipToFilters?: InputReportFilter[] | null;
  soldToFilters?: InputReportFilter[] | null;
  productFilters?: InputReportFilter[] | null;
  controllerTypeFilters?: InputReportFilter[] | null;
  reportSchedule: UpsertReportScheduleInput;
  reportFormat?: ReportFormatEnum | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
