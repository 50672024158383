import { getLogger } from 'aurelia-logging';

const logger = getLogger('uriHelpers');

export const convertOldQrURI = (url: string) => {
  logger.debug('Converting url: ', url);

  const serialHashKeyword = '/qr.aspx/#/?serial=';
  // TODO: use URLSearchParams instead
  if (url.indexOf(serialHashKeyword) > -1) {
    const serial = url.replace(serialHashKeyword, '');
    return `/qr/${serial}`;
  }

  return '';
};

export const isLegacyStyleUrl = (url: string) =>
  url.indexOf('qr.aspx') > -1 ? false : url.indexOf('.aspx') > -1;
