import { requests } from '../config';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import {
  deletedSiteEquipment,
  persistingNewSiteEquipment,
  updateSiteEquipment,
  getSiteEquipment
} from '../actions';
import { IEquipment } from '../interfaces';
import { getLogger } from 'aurelia-logging';
import {
  saveBlob,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity
} from '../utility';
import { BaseService } from './baseService';

@autoinject()
export class EquipmentService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(EquipmentService.name), taskQueue);
  }

  deleteSiteEquipment = async (siteId: number, equipmentId: number) =>
    this.httpRequestWithDispatch(
      requests.deleteSiteEquipment(siteId, equipmentId),
      deletedSiteEquipment,
      { siteId, equipmentId },
      'Unexpected error occurred'
    );

  saveNewSiteEquipment = async (siteId: number, equipment: IEquipment) =>
    this.httpRequestWithDispatch(
      requests.addSiteEquipment(siteId, equipment),
      persistingNewSiteEquipment,
      siteId,
      'Could not add equipment to site.'
    );

  updateSiteEquipment = async (siteId: number, equipment: IEquipment) =>
    this.httpRequestWithDispatch(
      requests.updateSiteEquipment(siteId, equipment),
      updateSiteEquipment,
      { siteId, equipmentId: equipment.equipmentId },
      'Unexpected error occurred'
    );

  getSiteEquipment = (siteId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteEquipment(siteId),
      getSiteEquipment,
      { siteId },
      'Could not fetch site equipment for site.',
      state =>
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(state.equipment.bySiteId, siteId),
          state.equipment.byId
        )
    );

  downloadSiteEquipmentAttachment = async (
    equipmentId: number,
    attachmentId: number,
    filename: string
  ) => {
    const file = await this.httpClient.fetch(
      requests.downloadSiteEquipmentAttachmentBlob(equipmentId, attachmentId)
    );
    saveBlob(await file.blob(), filename);
  };
}
