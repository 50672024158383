import { customElement, bindable } from 'aurelia-framework';
import { orderByPredicate } from '../../../utility/sorting';
import { getCodes, getName } from '../../../utility/application/countryHelpers';

@customElement('countrycode-dropdown')
export class CountrycodeDropdown {
  constructor() {
    this.data = this.getData();
  }

  @bindable() alignTop = false;
  @bindable() valuePath: string;
  @bindable() selected: string;
  @bindable() itemClicked: undefined | ((params: { value: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() required: boolean;

  _itemClicked(value: string, text: string) {
    this.itemClicked && this.itemClicked({ value: value || text });
  }

  getData() {
    return orderByPredicate(getCodes(), this.getDisplayValue, 'asc');
  }

  getDisplayValue(code: string) {
    const country = getName(code);
    return country ? `${country}: ${code}` : code;
  }

  data: string[];
}
