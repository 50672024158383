import { combineReducers } from 'redux';
// Entity reducers
import {
  default as equipment,
  IEquipmentReducerState
} from './entity/equipmentReducer';
import {
  default as controllerProfiles,
  IControllerProfilesReducer
} from './entity/controllerProfilesReducer';
import {
  default as controllerParameters,
  IControllerParameterReducer
} from './entity/controllerParametersReducer';
import {
  default as controllerLog,
  IControllerLogsReducerState
} from './entity/controllerLogsReducer';
import {
  default as channels,
  IChannelReducerState
} from './entity/channelReducer';
import {
  default as columns,
  IColumnsReducerState
} from './entity/columnsReducer';
import { default as notes, INoteReducerState } from './entity/notesReducer';
import {
  default as commands,
  IControllerCommandsReducerState
} from './entity/controllerCommandsReducer';
import { default as site, ISiteReducerState } from './entity/siteReducer';
import {
  default as controllers,
  IControllerReducer
} from './entity/controllerReducer';
import {
  default as usermanager,
  IUserManagerReducerState
} from '../pages/usermanager/usermanagerReducer';
import {
  default as customermanager,
  ICustomerManagerReducerState
} from '../pages/customermanager/customermanagerReducer';
import { default as buid, IBuidReducerState } from './entity/buidReducer';

import {
  default as multiEdit,
  IMultiEditReducerState
} from '../pages/sitelist/common/multi-edit/multiEditReducer';

import {
  default as tankUsages,
  ITankUsageReducerState
} from './entity/tankUsageReducer';

import {
  default as products,
  IProductsReducerState
} from './entity/productsReducer';
import {
  default as translations,
  ITranslationReducerState
} from './entity/translationReducer';
import {
  default as loadingPoints,
  ILoadingPointsReducerState
} from './entity/loadingPointsReducer';
import {
  default as vehicles,
  IVehicleReducerState
} from './entity/vehicleReducer';
import {
  default as customer,
  ICustomerReducerState
} from './entity/customerReducer';
import {
  default as language,
  ILanguageReducerState
} from './entity/languageReducer';
import { default as units, IUnitReducerState } from './entity/unitReducer';
import {
  default as pinnedSites,
  IPinnedSitesReducerState
} from './entity/pinnedSitesReducer';

import {
  default as userTrendGroups,
  IUserTrendGroupsReducerState
} from './entity/userTrendGroupReducer';
import {
  default as userTrendChannels,
  IUserTrendChannelsReducerState
} from './entity/userTrendChannelsReducer';

import {
  default as filters,
  IFilterReducerState
} from './entity/filterReducer';
import { default as users, IUserReducerState } from './entity/userReducer';

import {
  default as siteExports,
  ISiteExportReducer
} from './entity/siteExportReducer';

import { default as alarms, IAlarmReducer } from './entity/alarmReducer';
import {
  default as alarmLogs,
  IAlarmLogsReducer
} from './entity/alarmLogsReducer';
import {
  default as alarmRecipients,
  IAlarmRecipientsReducer
} from './entity/alarmRecipientsReducer';

import {
  default as channelHistory,
  IChannelHistoryReducerState
} from './entity/channelHistoryReducer';

// UI reducers
import {
  default as deliveryplanner,
  IPlanningReducerState
} from './ui/planningReducer';
import { default as router, IRouterReducerState } from './ui/routerReducer';
import {
  default as sitelist,
  ISiteListReducerState
} from './ui/sitelistReducer';
import {
  default as sitedetails,
  ISiteDetailsReducerState
} from './ui/sitedetailsReducer';
import {
  default as application,
  IApplicationReducerState
} from './ui/applicationReducer';
import {
  default as sitedetailsAsset,
  ISiteDetailsAssetReducerState
} from './ui/sitedetailsAssetReducer';
import {
  default as sitedetailsMap,
  ISiteDetailsMapReducerState
} from './ui/sitedetailsMapReducer';
import { default as account, IAccountReducerState } from './ui/accountReducer';
import { default as maps, IMapReducerState } from './ui/mapReducer';
import {
  default as sitedetailsTanks,
  ISiteDetailsTanksReducer
} from './ui/sitedetailsTanksReducer';
import { default as qrApp, IQrAppReducerState } from './ui/qrAppReducer';
import {
  default as dataanalysis,
  IDataAnalysisReducerState
} from './ui/dataanalysisReducer';
import { default as importJobs, IImportJobState } from './ui/importjobsreducer';
import {
  default as exportJobs,
  IExportJobReducer
} from './ui/exportjobsreducer';
import {
  default as translationsUi,
  ITranslationUiReducerState
} from './ui/translationUiReducer';
import { default as reports, IReportReducerState } from './ui/reportReducer';
import { default as device, IDeviceReducerState } from './ui/deviceReducer';
import {
  default as sitedetailsChannels,
  ISiteDetailsChannelsReducerState
} from './ui/sitedetailsChannelsReducer';
import {
  default as controllerManager,
  IControllerManagerReducerState
} from './ui/controllerManagerReducer';
import {
  default as sitedetailsAlarms,
  ISiteDetailsAlarmsReducerState
} from './ui/sitedetailsAlarmsReducer';
import {
  default as userProfile,
  IUserProfileReducerState
} from './ui/userProfileReducer';
import {
  default as controllerEditorReducer,
  IControllerEditorReducerState
} from './ui/controllerEditorReducer';

import {
  IContactReducerState,
  default as contacts
} from './entity/contactReducer';

import {
  default as importJobRuns,
  IImportJobRunsState
} from './ui/importjobrunsreducer';
import {
  default as exportJobRuns,
  IExportJobRunsState
} from './ui/exportjobrunsreducer';

import {
  default as haulier,
  IHaulierReducerState
} from './entity/haulierReducer';

export default combineReducers<rootState>({
  application,
  channelHistory,
  controllers,
  customermanager,
  dataanalysis,
  usermanager,
  sitedetails,
  router,
  notes,
  commands,
  site,
  sitelist,
  columns,
  channels,
  deliveryplanner,
  controllerLog,
  controllerProfiles,
  controllerParameters,
  controllerManager,
  importJobs,
  exportJobs,
  equipment,
  sitedetailsAsset,
  maps,
  sitedetailsMap,
  account,
  sitedetailsTanks,
  qrApp,
  buid,
  tankUsages,
  customer,
  language,
  products,
  reports,
  translations,
  translationsUi,
  loadingPoints,
  vehicles,
  units,
  userTrendGroups,
  userTrendChannels,
  pinnedSites,
  device,
  filters,
  users,
  sitedetailsChannels,
  siteExports,
  alarms,
  alarmLogs,
  alarmRecipients,
  contacts,
  sitedetailsAlarms,
  userProfile,
  controllerEditorReducer,
  importJobRuns,
  exportJobRuns,
  multiEdit,
  haulier
});

/**
 * Quick note on this interface. The keys of it's content needs to match the keys in the combineReducers function above.
 */
export interface rootState {
  application: IApplicationReducerState;
  controllers: IControllerReducer;
  channelHistory: IChannelHistoryReducerState;
  dataanalysis: IDataAnalysisReducerState;
  sitedetails: ISiteDetailsReducerState;
  router: IRouterReducerState;
  notes: INoteReducerState;
  commands: IControllerCommandsReducerState;
  site: ISiteReducerState;
  sitelist: ISiteListReducerState;
  columns: IColumnsReducerState;
  channels: IChannelReducerState;
  deliveryplanner: IPlanningReducerState;
  controllerParameters: IControllerParameterReducer;
  controllerLog: IControllerLogsReducerState;
  controllerProfiles: IControllerProfilesReducer;
  equipment: IEquipmentReducerState;
  sitedetailsAsset: ISiteDetailsAssetReducerState;
  maps: IMapReducerState;
  sitedetailsMap: ISiteDetailsMapReducerState;
  qrApp: IQrAppReducerState;
  importJobs: IImportJobState;
  exportJobs: IExportJobReducer;
  account: IAccountReducerState;
  sitedetailsTanks: ISiteDetailsTanksReducer;
  contacts: IContactReducerState;
  usermanager: IUserManagerReducerState;
  customermanager: ICustomerManagerReducerState;
  buid: IBuidReducerState;
  haulier: IHaulierReducerState;
  tankUsages: ITankUsageReducerState;
  customer: ICustomerReducerState;
  language: ILanguageReducerState;
  products: IProductsReducerState;
  reports: IReportReducerState;

  loadingPoints: ILoadingPointsReducerState;
  vehicles: IVehicleReducerState;

  translations: ITranslationReducerState;
  translationsUi: ITranslationUiReducerState;

  units: IUnitReducerState;

  userTrendGroups: IUserTrendGroupsReducerState;
  userTrendChannels: IUserTrendChannelsReducerState;
  pinnedSites: IPinnedSitesReducerState;
  device: IDeviceReducerState;

  filters: IFilterReducerState;

  users: IUserReducerState;

  sitedetailsChannels: ISiteDetailsChannelsReducerState;

  siteExports: ISiteExportReducer;
  controllerManager: IControllerManagerReducerState;

  alarms: IAlarmReducer;
  alarmLogs: IAlarmLogsReducer;
  alarmRecipients: IAlarmRecipientsReducer;
  sitedetailsAlarms: ISiteDetailsAlarmsReducerState;

  userProfile: IUserProfileReducerState;
  controllerEditorReducer: IControllerEditorReducerState;
  importJobRuns: IImportJobRunsState;
  exportJobRuns: IExportJobRunsState;
  multiEdit: IMultiEditReducerState;
}

export * from './entity/controllerReducer';
export * from './entity/notesReducer';
export * from './entity/controllerCommandsReducer';
export * from './entity/siteReducer';
export * from './entity/channelReducer';
export * from './entity/loadingPointsReducer';
export * from './entity/vehicleReducer';
export * from './entity/productsReducer';
export * from './entity/unitReducer';
export * from './entity/userTrendGroupReducer';
export * from './entity/userTrendChannelsReducer';
export * from './entity/filterReducer';
export * from './entity/userReducer';
