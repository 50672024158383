import type { RequestDocument } from "graphql-request/dist/types";
import gql from "graphql-tag";
import { OperationDefinitionNode } from "graphql/language/ast";
import { print } from "graphql/language/printer";
import { ElasticPageLastUpdated, ElasticPageLastUpdatedVariables, ElasticSearchPage } from '../../custom_typings/graphql';
import { runSingleQuery, wait } from "../pages/common/GraphQLFetcher";

export const compareReduxAndGraphQLIds = (id1: unknown, id2: unknown) => {
  return id1 == id2;
};


export function resolveRequestDocument(document: RequestDocument): { query: string; operationName?: string } {
  if (typeof document === 'string') return { query: document }

  let operationName = undefined

  let operationDefinitions = document.definitions.filter(
    (definition) => definition.kind === 'OperationDefinition'
  ) as OperationDefinitionNode[]

  if (operationDefinitions.length === 1) {
    operationName = operationDefinitions[0].name?.value
  }

  return { query: print(document), operationName }
}

const getLastUpdatedByElasticSearchPage = async (page: ElasticSearchPage) => {
  const { promise } = runSingleQuery<ElasticPageLastUpdated, ElasticPageLastUpdatedVariables>(gql`
      query ElasticPageLastUpdated($page: ElasticSearchPage!) {
        elasticSearchPages {
          lastUpdated(page: $page)
        }
      }
    `, {
      page
    })

    const lastUpdated = (await promise).elasticSearchPages.lastUpdated;
    return lastUpdated;
}

export const waitForElasticPageRefresh = async (page: ElasticSearchPage, newerThan: number, maxWaitTimeInSecond: number = 5) => {
  const getNowInSeconds = () => Math.floor(new Date().getTime() / 1000);
  const operationStart = getNowInSeconds()

  while(true && getNowInSeconds() - operationStart <= maxWaitTimeInSecond) {
    const lastUpdated = await getLastUpdatedByElasticSearchPage(page);
    if(!!lastUpdated && lastUpdated >= newerThan) {
      break;
    }
    await wait(300);
  }
}

export const createPageUpdateChecker = async (page: ElasticSearchPage, maxWaitTimeInSecond: number = 5) => {
  const operationStart = await getLastUpdatedByElasticSearchPage(page);
  const operationStartLocal = Date.now();

  if(!operationStart)
    throw new Error("Could not fetch last updatetime for elasticsearch page")

  return async () => {
    while(true && operationStartLocal <= Date.now() - maxWaitTimeInSecond * 1000) {
      const lastUpdated = await getLastUpdatedByElasticSearchPage(page);
      if(!!lastUpdated && lastUpdated >= operationStart) {
        break;
      }
      
      await wait(300);
    }
  }
}