import { endpoints, requests } from '../config';
import { saveBlob } from '../utility';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { BlobTypes, IBlob } from '../interfaces';
import { AsyncActionCreators } from 'typescript-fsa';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';

@autoinject()
export class BlobService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(BlobService.name), taskQueue);
  }

  async uploadFile(file: File, blobType: BlobTypes) {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpRequest<IBlob>(endpoints.uploadBlob(blobType, formData));
  }

  async uploadFileAndDispatch<P>(
    file: File,
    blobType: BlobTypes,
    actionCreator: AsyncActionCreators<P, IBlob, any>,
    actionParam: P
  ) {
    const formData = new FormData();
    formData.append('file', file);
    const request = endpoints.uploadBlob(blobType, formData);
    await this.httpRequestWithDispatch(
      request,
      actionCreator,
      actionParam,
      'Could not upload blob'
    );
  }

  async downloadFile(blobId: number, filename: string) {
    const file = await this.httpClient.fetch(requests.downloadBlob(blobId));
    const blob = await file.blob();
    saveBlob(blob, filename);
  }

  async downloadFileFromUrl(url: string, filename: string) {
    const file = await fetch(url);
    const blob = await file.blob();
    saveBlob(blob, filename);
  }

  async downloadFileFromApiUrl(url: string, filename: string) {
    const file = await this.httpClient.fetch(url);
    const blob = await file.blob();
    saveBlob(blob, filename);
  }
}
