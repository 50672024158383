import { IFilterDefinition } from "$components/grid/grid";
import { ConditionalOperator, IDateFilter, IFilterGroup } from "$interfaces/iFilter";
import { ensureDate, formatDate } from "$lib/dateHelpers";
import { isNone, lastTwo } from "$lib/helpers";
import { bindable, customElement } from "aurelia-framework";
import {I18N} from 'aurelia-i18n';

type operations = 'between' | 'gt' | 'lt' | 'equals'
@customElement('column-filter-date')
export class ColumnFilterDate {
    @bindable() filter: IFilterGroup | undefined;
    @bindable() definition: IFilterDefinition;
    @bindable() changedFilter: Function
    
    value1: string | Date | undefined;
    value2: string | Date | undefined;

    operation: operations = 'equals';

    constructor(private i18n: I18N) {}

    removeFilter(filterToRemove: IDateFilter, event: Event) {
        if(!this.filter) return;
        event.preventDefault();
        const newfilter: IFilterGroup = {...this.filter, filters: (this.filter.filters as IDateFilter[]).filter(f => f !== filterToRemove) };
        this.changedFilter && this.changedFilter({ newfilter })
    }

    validateValues(value1: Date | string | undefined, value2: Date | string | undefined, operation: operations): boolean {
        const value1IsNotDefined = isNone(value1) || value1.toString() === '';
        const value2IsNotDefined = isNone(value2) || value2.toString() === '';
        return operation === 'between' ? !value1IsNotDefined && !value2IsNotDefined : !value1IsNotDefined;
    }

    getDateAsStringWithoutTimezone(date: string | Date) {
        if(date === undefined) return date;

        const converted = ensureDate(date);
        const isoString = `${converted.getFullYear()}-${lastTwo(converted.getMonth() + 1)}-${lastTwo(converted.getDate())}T${lastTwo(converted.getHours())}:${lastTwo(converted.getMinutes())}:${lastTwo(converted.getSeconds())}` 
        return isoString
    }

    getNewFilter(): IDateFilter {
        if(!this.validateValues(this.value1, this.value2, this.operation) || isNone(this.value1))
            throw Error('Value1 or value2 is not defined.');

        switch(this.operation) {
            case 'equals':
                return { operator: ConditionalOperator.Equals, value: this.getDateAsStringWithoutTimezone(this.value1), symbol: '=' };
            case 'gt':
                return { operator: ConditionalOperator.GreaterThan, value: this.getDateAsStringWithoutTimezone(this.value1), symbol: '>' };
            case 'lt':
                return { operator: ConditionalOperator.LesserThan, value: this.getDateAsStringWithoutTimezone(this.value1), symbol: '<' };
            case 'between':
                return { operator: ConditionalOperator.Between, value: this.getDateAsStringWithoutTimezone(this.value1), secondValue: this.value2 === undefined ? undefined : this.getDateAsStringWithoutTimezone(this.value2), symbol: '&&' }
        }
    }

    getTextForFilter(filter: IDateFilter){
        /*const formatDate = (d: Date | undefined | string) => {
            if(isNone(d)) return;
            return ensureDate(d).toDateString();
        }*/
        const format = (date: Date | undefined | string) => formatDate(date, false, ".");
        switch(filter.operator) {
            case ConditionalOperator.Between:
                return this.i18n.tr('UI_SiteList_Filter_Date_Between') + ` ${format(filter.value)} and ${format(filter.secondValue)}`;
            case ConditionalOperator.Equals:
                return `${format(filter.value)}`;
            case ConditionalOperator.GreaterThan:
                return this.i18n.tr('UI_SiteList_Filter_Date_After') + ` ${format(filter.value)}`;
            case ConditionalOperator.LesserThan:
                return this.i18n.tr('UI_SiteList_Filter_Date_Before') + ` ${format(filter.value)}`;
        }
    }

    addFilter(){
        const filter = this.getNewFilter();

        const newfilter: IFilterGroup = {
            ...(this.filter || { exclude: false, field: this.definition.property, type: 'date', filters: [], partialMatch: true }), 
            filters: this.filter ? [...(this.filter.filters as IDateFilter[]), filter] : [filter]
        };

        this.changedFilter && this.changedFilter({ newfilter })
        this.value1 = undefined;
        this.value2 = undefined;
    }
}