import { IAlarm, IAsyncReducerState, ISiteChannel } from '../../interfaces';
import {
  IAsyncDictionary,
  createFetchedEntity,
  createPendingEntity,
  IAsyncEntity
} from '../../types';
import {
  insertFetchedEntities,
  mergeObjects,
  createAsyncDictionaryForSiteRelations,
  insertAllFetchedEntities,
  isSomething,
  insertFetchedEntity,
  setEntity,
  appendObjectToArray,
  removeAsyncObject,
  removeObjectFromArray,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  removeAsyncObjects,
  removeAsyncEntityInDictionary,
  flatMap,
  removeAsyncEntitiesInDictionary,
  getAsyncEntities,
  isNone
} from '../../utility';
import {
  fetchingAllAlarms,
  fetchingAlarmsForSite,
  fetchingAllCustomAndActive
} from '../../actions/alarms';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  updateAlarm,
  createAlarm,
  deleteAlarm,
  acknowledgeAlarm,
  deleteSiteChannel,
  deleteSite,
  deleteMultipleSites,
  updateSiteChannels,
  updateSiteChannel
} from '../../actions';

export interface IAlarmReducer extends IAsyncReducerState<IAlarm> {
  alarmsForSites: IAsyncDictionary<number[]>;
  activeAndCustomAlarms: IAsyncEntity<number[]>;
}

const defaultState: IAlarmReducer = {
  allIds: undefined,
  byId: {},
  alarmsForSites: {},
  activeAndCustomAlarms: undefined
};

const removeAlarmsForParkedChannels = (
  state: IAlarmReducer,
  siteId: number | undefined,
  siteChannels: ISiteChannel[]
) => {
  if (isNone(siteId)) return state;

  const alarmsForSite = getEntityOrDefault(
    getAsyncEntitiesByAsyncArray(
      getAsyncEntity(state.alarmsForSites, siteId),
      state.byId
    ),
    undefined
  );

  if (isSomething(alarmsForSite)) {
    const parkedSiteChannelIds = siteChannels
      .filter(sc => sc.isParked)
      .map(sc => sc.siteChannelId);
    const alarmsToRemove = alarmsForSite
      .filter(
        alarm =>
          alarm.siteChannelId &&
          parkedSiteChannelIds.includes(alarm.siteChannelId)
      )
      .map(alarm => alarm.alarmId);

    return mergeObjects(state, removeAsyncObjects(state, alarmsToRemove));
  }

  return state;
};

const reducer = reducerWithInitialState(defaultState)
  .case(updateSiteChannel.done, (state, payload) =>
    removeAlarmsForParkedChannels(state, payload.params.siteId, [
      payload.params
    ])
  )
  .case(updateSiteChannels.done, (state, payload) =>
    removeAlarmsForParkedChannels(state, payload.params.siteId, payload.result)
  )

  .case(deleteSite.done, (state, payload) => {
    const alarmsForSite = getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(state.alarmsForSites, payload.params.siteId),
        state.byId
      ),
      undefined
    );

    return isSomething(alarmsForSite)
      ? mergeObjects(
          state,
          removeAsyncObjects(state, alarmsForSite.map(alarm => alarm.alarmId)),
          {
            alarmsForSites: mergeObjects(
              state.alarmsForSites,
              removeAsyncEntityInDictionary(
                state.alarmsForSites,
                payload.params.siteId
              )
            )
          }
        )
      : state;
  })

  .case(deleteMultipleSites.done, (state, payload) => {
    const alarmIds = flatMap(
      getEntityOrDefault(
        getAsyncEntities(payload.params.siteIds, state.alarmsForSites),
        []
      ),
      x => x
    );
    const alarmsForSites = getEntityOrDefault(
      getAsyncEntities(alarmIds, state.byId),
      undefined
    );

    return isSomething(alarmsForSites)
      ? mergeObjects(
          state,
          removeAsyncObjects(state, alarmsForSites.map(alarm => alarm.alarmId)),
          {
            alarmsForSites: mergeObjects(
              state.alarmsForSites,
              removeAsyncEntitiesInDictionary(
                state.alarmsForSites,
                payload.params.siteIds
              )
            )
          }
        )
      : state;
  })

  .case(fetchingAlarmsForSite.started, (state, siteId) =>
    mergeObjects(state, {
      alarmsForSites: mergeObjects(state.alarmsForSites, {
        [siteId]: createPendingEntity()
      })
    })
  )

  .case(fetchingAlarmsForSite.done, (state, { result, params }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, alarm => alarm.alarmId),
      {
        alarmsForSites: mergeObjects(state.alarmsForSites, {
          [params]: createFetchedEntity(result.map(r => r.alarmId))
        })
      }
    )
  )
  .case(fetchingAllCustomAndActive.started, state => ({
    ...state,
    activeAndCustomAlarms: createPendingEntity()
  }))

  .case(fetchingAllCustomAndActive.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, a => a.alarmId),
    activeAndCustomAlarms: createFetchedEntity(result.map(a => a.alarmId))
  }))

  .case(fetchingAllAlarms.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, alarm => alarm.alarmId),
      {
        alarmsForSites: createAsyncDictionaryForSiteRelations(
          result.filter(c => isSomething(c.siteId)),
          a => a.siteId!,
          a => a.alarmId
        )
      }
    )
  )

  .case(updateAlarm.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntity(state, payload.params.alarmId, payload.result)
    )
  )

  .case(createAlarm.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntity(state, payload.result.alarmId, payload.result),
      {
        alarmsForSites: mergeObjects(state.alarmsForSites, {
          [payload.params.siteId]: setEntity(
            state.alarmsForSites[payload.params.siteId],
            entity => appendObjectToArray(entity, payload.result.alarmId)
          )
        })
      }
    )
  )

  .case(deleteSiteChannel.done, state =>
    mergeObjects(state, {
      alarmsForSites: {}
    })
  )

  .case(acknowledgeAlarm.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntity(state, payload.params.alarm.alarmId, payload.result)
    )
  )

  .case(deleteAlarm.done, (state, payload) =>
    mergeObjects(
      state,
      removeAsyncObject(state, payload.params.alarm.alarmId),
      {
        alarmsForSites: mergeObjects(state.alarmsForSites, {
          [payload.params.siteId]: setEntity(
            state.alarmsForSites[payload.params.siteId],
            entity =>
              removeObjectFromArray(entity, payload.params.alarm.alarmId)
          )
        }),
        activeAndCustomAlarms: undefined
      }
    )
  );

export default reducer;
