import { autoinject, bindable, customElement, inlineView } from 'aurelia-framework';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import SystemMessageDisplay from './system-message-display';
import {ISystemMessageToDisplay} from "./system-message-display-datatypes";

/**
 * Aurelia wrapper for SystemMessageDisplay
 */
@autoinject()
@inlineView(`<template><div ref="react"></div></template>`)
@customElement("system-message-display-wrapper")
export class SystemMessageDisplayWrapper {
  react: HTMLElement | null;
  parent: HTMLElement | null;
  reactRoot: Root | null;

  @bindable systemMessage: ISystemMessageToDisplay;
  
  attached() {
    if (!this.react) return;
    this.parent = this.react.parentElement;
    if (!this.parent) return;
    
    const element = React.createElement(SystemMessageDisplay, { 
      systemMessage: this.systemMessage }
    );
    this.reactRoot = createRoot(this.parent)
    this.reactRoot.render(element);
  }

  detached() {
    if (this.parent) {
      this.reactRoot?.unmount()
    }
  }
}
