import { customElement, bindable } from 'aurelia-framework';
import './fab-button.css';

@customElement('fab-button')
export class FabButton {
  @bindable icon: string;
  @bindable img: string;
  @bindable type: 'primary' | 'secondary' = 'primary';
  @bindable showItems: boolean = false;
}
