import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient, json } from 'aurelia-fetch-client';
import { endpoints, requests } from '../config';
import {
  updateNoteAction,
  postNewNoteAction,
  deleteNoteAction,
  getNotesForSite,
  getAllDisplayNotes
} from '../actions';
import { INote } from '../interfaces';
import {
  getNewId,
  assureStatusCode,
  getAsyncEntity,
  getEntityOrUndefined,
  getAsyncEntities,
  getAsyncEntitiesByAsyncArray
} from '../utility';
import { store } from '../reducers/store';
import { BaseService } from './baseService';
import { getLogger } from 'aurelia-logging';

@autoinject()
export class NoteService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(NoteService.name), taskQueue);
  }

  postNewNoteForSite(siteId: number, text: string, noteType: number): void {
    const params = { siteId, text, noteType, internalId: getNewId() };

    store.dispatch(postNewNoteAction.started(params));
    this.httpClient
      .fetch(endpoints.notes, {
        body: json({ siteId, text, noteType }),
        method: 'POST'
      })
      .then(assureStatusCode)
      .then((response): Promise<INote> => response.json())
      .then(result =>
        store.dispatch(postNewNoteAction.done({ params, result }))
      )
      .catch(error =>
        store.dispatch(postNewNoteAction.failed({ params, error }))
      );
  }

  updateExistingNoteOnSite(
    siteId: number,
    noteId: number,
    text: string,
    noteType: number
  ): void {
    const params = { siteId, noteId, noteType, text, internalId: getNewId() };

    store.dispatch(updateNoteAction.started(params));
    this.httpClient
      .fetch(endpoints.note(noteId), {
        body: json({ noteId, text, noteType }),
        method: 'PUT'
      })
      .then(assureStatusCode)
      .then((response): Promise<INote> => response.json())
      .then(result => store.dispatch(updateNoteAction.done({ params, result })))
      .catch(error =>
        store.dispatch(updateNoteAction.failed({ params, error }))
      );
  }

  deleteNote(siteId: number, noteId: number) {
    const params = { siteId, noteId, internalId: getNewId() };

    store.dispatch(deleteNoteAction.started(params));
    this.httpClient
      .fetch(endpoints.note(noteId), {
        method: 'DELETE'
      })
      .then(assureStatusCode)
      .then(() =>
        store.dispatch(deleteNoteAction.done({ params, result: true }))
      )
      .catch(error =>
        store.dispatch(deleteNoteAction.failed({ params, error }))
      );
  }

  getAllDisplayNotes = () =>
    this.httpRequestWithDispatch(
      requests.allDisplayNotes(),
      getAllDisplayNotes,
      {},
      'Could not fetch displaynotes',
      state => {
        if (!state.notes.allDisplayNotesAreFetched) return undefined;
        return getEntityOrUndefined(
          getAsyncEntities(state.notes.allDisplayNoteIds, state.notes.byId)
        );
      }
    );

  getAllNotesForSite = (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.notesForSite(siteId),
      getNotesForSite,
      { siteId },
      'error',
      state =>
        getEntityOrUndefined(
          getAsyncEntitiesByAsyncArray(
            getAsyncEntity(state.notes.notesForSites, siteId),
            state.notes.byId
          )
        )
    );

  getAllNotesForSiteNoCache = (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.notesForSite(siteId),
      getNotesForSite,
      { siteId },
      'error',
      undefined
    );
}
