import { IFilterDefinition } from "$components/grid/grid";
import { ConditionalOperator, IFilterGroup, INumberFilter } from "$interfaces/iFilter";
import { removeNoneFromArray } from "$lib/arrayHelpers";
import { isTouchDeviceWatcher } from "$lib/deviceHelpers";
import { isEmpty, isSomething } from "$lib/helpers";
import { bindable, computedFrom, customElement } from "aurelia-framework";

@customElement('column-filter-number-ids')
export class ColumnFilterNumberIds {
    @bindable() filter: IFilterGroup | undefined;
    @bindable() definition: IFilterDefinition;
    @bindable() changedFilter: Function;
    
    autofocus: boolean = false;
    private unsubscribeWatcher: Function; 

    constructor() {
        this.unsubscribeWatcher = isTouchDeviceWatcher((isTouchDevice: boolean) => {
            this.autofocus = !isTouchDevice;
        });        
    }

    detached() {
        this.unsubscribeWatcher();
    }

    textQuery = '';

    @computedFrom('textQuery')
    get rowsInTextArea() {
        const numberOfNewlines = (this.textQuery && this.textQuery.match(/\n/g)?.length) || 0;
        return Math.min(4, numberOfNewlines + 1);
    }

    removeFilter(filterToRemove: INumberFilter, event: Event) {
        if(!this.filter) return;
        event.preventDefault();
        const newfilter: IFilterGroup = {...this.filter, filters: (this.filter.filters as INumberFilter[]).filter(f => f !== filterToRemove) };
        this.changedFilter && this.changedFilter({ newfilter })
    }


    addFilter(){
        if(!this.textQuery) return;
        const tryParse = (s:string) => {
            try{
                const parsed = parseInt(s, 10);
                if(isNaN(parsed)) {
                    return;
                }
                return parsed;
            }catch(err) {
                return;
            }
        }
        const filters = removeNoneFromArray(this.textQuery.split('\n')
            .filter(query => !isEmpty(query))
            .map<INumberFilter | undefined>(value => {
                const parsed = tryParse(value);
                return isSomething(parsed) ? { value: parsed, symbol: "=", operator: ConditionalOperator.Equals, selected: true } : undefined;
            }));

        const newfilter: IFilterGroup = {
            ...(this.filter || { exclude: false, field: this.definition.property, type: 'number', filters: [], partialMatch: true }), 
            filters: this.filter ? [...(this.filter.filters as INumberFilter[]), ...filters] : filters
        };

        this.changedFilter && this.changedFilter({ newfilter })
        this.textQuery = '';
    }
}