import { mergeObjects } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {} from '../../actions';
import { deviceSize } from '../../types/index';
import { deviceSizeChanged } from '../../actions/device';

export interface IDeviceReducerState {
  screenSize: deviceSize;
}

const defaultState: IDeviceReducerState = {
  screenSize: 'desktop'
};

const reducer = reducerWithInitialState(defaultState).case(
  deviceSizeChanged,
  (state, screenSize) => mergeObjects(state, { screenSize })
);

export default reducer;
