export enum ChannelTypes {
  Unknown = 0,
  Dose = 1,
  Flow = 2,
  H2SGas = 3,
  RetentionTime = 5,
  Tank = 6,
  Aggregation = 7,
  Temperature = 8,
  Battery = 9,
  UnknownWithSum = 10,
  AggregatedTankUsage = 11,
  AggregatedDose = 12,
  StockVsDoseAccuracy = 13,
  Counter = 14,
  DissolvedSulphides = 15,
  Conductivity = 16
}
