import { customElement, bindable, PLATFORM, computedFrom } from 'aurelia-framework';
import { ensureNumber, isNone } from '../../utility';
import './slider.css';

@customElement('slider')
export class Slider {
  @bindable min: number;
  @bindable max: number = 100;
  @bindable step: number | undefined;
  @bindable onChanged = PLATFORM.noop;

  @bindable value: number | string = 0;
  @bindable from: number | string;
  @bindable to: number | string;
  
  @bindable range = false;

  changed(value1: number, value2: number){
    const value1parsed = ensureNumber(value1);
    if(this.range){
      const value2parsed = ensureNumber(value2);
      const from = Math.min(value1parsed, value2parsed);
      const to = Math.max(value1parsed, value2parsed);
      this.onChanged({ from, to, value: [from, to] });
    }
    else {
      this.onChanged({ value: value1parsed })
    }
  }

  @computedFrom('value', 'from', 'to', 'range')
  get filledStyle(){
    if(!this.range && !isNone(this.value) && !isNone(this.max)) {
      const percentage = ensureNumber(this.value) / ensureNumber(this.max) * 100;
      return { width: `${percentage}%` };
    }
    if(this.range && !isNone(this.from) && !isNone(this.to) && !isNone(this.max)) {
      const percentage = (ensureNumber(this.to) - ensureNumber(this.from)) / ensureNumber(this.max) * 100;
      const shifted = ensureNumber(this.from) / ensureNumber(this.max) * 100;
      return { width: `${percentage}%`, left: `${shifted}%`}

    }
    return { width: 0};
  }
}
