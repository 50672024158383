import {
  createExportAction,
  navigateToRoute,
  quantityChanged,
  toggleTank,
  clearSelection,
  routePlanExported,
  selectionChanged
} from '../../actions';
import {
  mergeObjects,
  roundDownToNearestHundred,
  defaultRequest,
  requestLoading,
  requestLoaded,
  requestFailed,
  isNone
} from '../../utility';
import {
  IDictionary,
  IPlanningTank,
  ISiteListTank,
  ISiteListSite,
  IRequestState
} from '../../interfaces';
import { IDeliveryTankErrorItem } from '../../interfaces/index';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface IPlanningReducerState {
  tanks: IDictionary<IPlanningTank>;
  orderRequest: IRequestState;

  routePlanExported: boolean;
}

const defaultState: IPlanningReducerState = {
  tanks: {},
  orderRequest: defaultRequest,
  routePlanExported: false
};

const mapPlanningTank = (tank: ISiteListTank) => {
  return {
    quantity: isNone(tank.tankDetails.freeCapacity)
      ? 0
      : roundDownToNearestHundred(tank.tankDetails.freeCapacity),
    selected: !tank.isParked,
    error: false,
    errorMessage: '',
    productDensity: tank.product ? tank.product.density : 0,
    unitSymbol: tank.unit ? tank.unit.symbol : '',
    missingUnit: !tank.unit,
    freeCapacity: tank.tankDetails.freeCapacity
  };
};

const applySelection = (
  state: IPlanningReducerState,
  payload: { site: ISiteListSite; value: boolean }
) => {
  const deliveryTanks = { ...state.tanks } as IDictionary<IPlanningTank>;

  if (payload.value) {
    payload.site.tanks.forEach((t: ISiteListTank) => {
      deliveryTanks[t.tankDetails.tankId] = mapPlanningTank(t);
    });
  } else {
    payload.site.tanks.forEach(t => delete deliveryTanks[t.tankDetails.tankId]);
  }

  return mergeObjects(
    state,
    {
      tanks: deliveryTanks
    },
    {
      orderRequest: defaultRequest,
      routePlanExported: false
    }
  );
};

const mapTankError = (tank: IPlanningTank, quantity: number) =>
  quantity <= 0 || (tank.freeCapacity ? quantity > tank.freeCapacity : true);

const reducer = reducerWithInitialState(defaultState)
  .case(clearSelection, state =>
    mergeObjects(state, {
      tanks: {},
      orderRequest: defaultRequest
    })
  )

  .case(quantityChanged, (state, payload) =>
    mergeObjects(state, {
      tanks: mergeObjects(state.tanks, {
        [payload.tankId]: mergeObjects(state.tanks[payload.tankId], {
          quantity: payload.quantity,
          error: mapTankError(state.tanks[payload.tankId], payload.quantity),
          errorMessage: mapTankError(
            state.tanks[payload.tankId],
            payload.quantity
          )
            ? 'UI_SiteList_Delivery_Quantity_OutOfRange'
            : ''
        })
      })
    })
  )

  .case(toggleTank, (state, payload) =>
    mergeObjects(state, {
      tanks: mergeObjects(state.tanks, {
        [payload.tankId]: mergeObjects(state.tanks[payload.tankId], {
          selected: payload.selected
        })
      })
    })
  )

  .case(selectionChanged, (state, payload) => applySelection(state, payload))

  .case(createExportAction.started, state =>
    mergeObjects(state, {
      orderRequest: mergeObjects(state.orderRequest, requestLoading())
    })
  )

  .case(createExportAction.done, state =>
    mergeObjects(state, {
      orderRequest: mergeObjects(state.orderRequest, requestLoaded)
    })
  )

  .case(createExportAction.failed, (state, payload) => {
    const deliveryTanks = { ...state.tanks } as IDictionary<IPlanningTank>;
    const errors = payload.error as IDeliveryTankErrorItem[];

    errors.forEach(item => {
      deliveryTanks[item.tankId] = mergeObjects(deliveryTanks[item.tankId], {
        error: true,
        errorMessage: item.message
      });
    });

    return mergeObjects(state, {
      tanks: deliveryTanks,
      orderRequest: mergeObjects(state.orderRequest, requestFailed(''))
    });
  })

  .case(navigateToRoute, state => {
    const deliveryTanks = mergeObjects({}, state.tanks) as IDictionary<
      IPlanningTank
    >;

    Object.keys(deliveryTanks).forEach((key: any) => {
      if (deliveryTanks[key]) {
        deliveryTanks[key].error = false;
        deliveryTanks[key].errorMessage = '';
      }
    });

    return mergeObjects(state, {
      tanks: deliveryTanks,
      orderRequest: mergeObjects(state.orderRequest, defaultRequest),
      routePlanExported: false
    });
  })

  .case(routePlanExported, state =>
    mergeObjects(state, { routePlanExported: true })
  );

export default reducer;
