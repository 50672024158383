import { ICustomerManager, ICustomersPayload } from './../interfaces';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { BaseService } from './baseService';
import {
  getAllCustomers,
  removeCustomers,
  getCustomerByToken,
  getCustomersByTokens, getCustomerById
} from '../actions';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  getEntityOrUndefined,
  getAsyncEntity,
  getAsyncEntities,
  getAsyncEntitiesByAsyncArray,
  emptyObject
} from '../utility/index';
import { selectCustomers } from '../pages/common';
import {
  getAllAllowedCustomermanagers,
  updateCustomerManager,
  updateCustomerManagers,
  addCustomerManager,
  getCustomermanagersById,
  getCustomermanagersByIds,
  fetchDefaultCustomerUsers,
  fetchAccessCustomerUsers,
  sapLookupCustomerManager
} from '../pages/customermanager/customermanagerReducer';

@autoinject
export class CustomerService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(CustomerService.name), taskQueue);
  }

  getById = (id: number) => 
    this.httpRequestWithDispatchFromState(
      requests.getCustomerById(id),
      getCustomerById,
      id,
      'Get customer by id failed',
      state => getAsyncEntity(state.customer.byId, id)
    );
    
  getByToken = (id: number, token: string) =>
    this.httpRequestWithDispatchFromState(
      requests.getCustomerByToken(token),
      getCustomerByToken,
      id,
      'Get customer by token failed',
      state => getAsyncEntity(state.customer.byId, id)
    );

  getByTokenAsync = (id: number, token: string) =>
    this.httpRequestWithDispatch(
      requests.getCustomerByToken(token),
      getCustomerByToken,
      id,
      'Get customer by token failed',
      state => getEntityOrUndefined(getAsyncEntity(state.customer.byId, id))
    );

  getByTokensAsync = (ids: number[], tokens: string[]) =>
    this.httpRequestWithDispatch(
      requests.getCustomersByTokens(tokens),
      getCustomersByTokens,
      ids,
      'Get customers by tokens failed',
      state => getEntityOrUndefined(getAsyncEntities(ids, state.customer.byId))
    );

  getByTokens = (ids: number[], tokens: string[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getCustomersByTokens(tokens),
      getCustomersByTokens,
      ids,
      'Get customers by tokens failed',
      state => getAsyncEntities(ids, state.customer.byId)
    );

  getAllAsync = () =>
    this.httpRequestWithDispatch(
      requests.getAllCustomers(),
      getAllCustomers,
      {},
      'Get all customer failed',
      state => getEntityOrUndefined(selectCustomers(state))
    );

  getAll = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllCustomers(),
      getAllCustomers,
      {},
      'Get all customer failed',
      selectCustomers
    );

  getAllCustomerManagers = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllCustomermanagers(),
      getAllAllowedCustomermanagers,
      {},
      'Get all customer managers failed',
      state =>
        getAsyncEntitiesByAsyncArray(
          state.customermanager.allIds,
          state.customermanager.byId
        )
    );

  getCustomerManagersById = (id: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getCustomermanagersById(id),
      getCustomermanagersById,
      id,
      'Could not fetch customer manager',
      state => getAsyncEntity(state.customermanager.byId, id)
    );

  getCustomerManagersByIds = (ids: number[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getCustomermanagersByIds(ids),
      getCustomermanagersByIds,
      ids,
      'Could not fetch customer managers',
      state => getAsyncEntities(ids, state.customermanager.byId)
    );

  private getCustomersPayload = (
    customerManager: Partial<ICustomerManager>,
    customerIds: number[]
  ): ICustomersPayload => ({
    customerManager,
    customerIds
  });

  updateCustomerAsync = (
    customerId: number,
    customerManager: Partial<ICustomerManager>
  ) =>
    this.httpRequestWithDispatch(
      requests.updateCustomer(customerId, customerManager),
      updateCustomerManager,
      customerId,
      'Unexpected error occurred'
    );

  updateCustomersAsync = (
    customerManager: Partial<ICustomerManager>,
    customerIds: number[]
  ) =>
    this.httpRequestWithDispatch(
      requests.updateCustomers(
        this.getCustomersPayload(customerManager, customerIds)
      ),
      updateCustomerManagers,
      { customerManager, customerIds },
      'Unexpected error occurred'
    );

  addCustomerManagerAsync = (customerManager: Partial<ICustomerManager>) =>
    this.httpRequestWithDispatch(
      requests.addCustomerManager(customerManager),
      addCustomerManager,
      emptyObject,
      'Could not add customer.'
    );

  removeCustomersAsync = (customerIds: number[], success?: Function) =>
    this.httpRequestWithDispatch(
      requests.deleteCustomers(customerIds),
      removeCustomers,
      customerIds,
      'Unexpected error occurred',
      undefined,
      success
    );

  private getUsersWithAccessCacheKey = (
    customerId: number | undefined,
    pendingCustomerName: string | undefined,
    pendingBuidId: number | undefined
  ) =>
    `cust_${customerId || ''}_customer_${pendingCustomerName ||
      ''}_buid_${pendingBuidId || ''}`;

  getUsersWithAccessToCustomerId = (
    customerId?: number,
    pendingCustomerName?: string,
    pendingBuidId?: number
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.getUsersWithAccessToCustomerId(
        customerId,
        pendingCustomerName,
        pendingBuidId
      ),
      fetchAccessCustomerUsers,
      this.getUsersWithAccessCacheKey(
        customerId,
        pendingCustomerName,
        pendingBuidId
      ),
      'Could not fetch users for customer',
      state =>
        getAsyncEntity(
          state.customermanager.accessCustomerUsers,
          this.getUsersWithAccessCacheKey(
            customerId,
            pendingCustomerName,
            pendingBuidId
          )
        )
    );

  sapLookup = (soldTo: string) =>
    this.httpRequestWithDispatch(
      requests.sapLookup(soldTo),
      sapLookupCustomerManager,
      soldTo,
      'Could not lookup customer from sold-to',
      undefined
    );  


  getUsersWithDefaultCustomerId = (customerId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getUsersWithDefaultCustomerId(customerId),
      fetchDefaultCustomerUsers,
      customerId,
      'Could not fetch users for customer',
      state =>
        getAsyncEntity(state.customermanager.defaultCustomerUsers, customerId)
    );
}
