import { ImportJob, ImportType } from '../models';
import actionCreatorFactory from 'typescript-fsa';
import { sortDirection } from '../types';

const actionCreator = actionCreatorFactory('importjobs');

export const fetchingImportJobs = actionCreator.async<{}, ImportJob[]>(
  'Fetching_ImportJobs'
);
export const getImportTypes = actionCreator.async<{}, ImportType[]>(
  'Get_ImportTypes_2'
);
export const savedImportJob = actionCreator.async<number, ImportJob>(
  'Save_ImportJob'
);
export const importJobSortingChanged = actionCreator<{
  sortProperty: string;
  sortDirection: sortDirection;
}>('importJobSortingChanged');

export const importJobFilterChanged = actionCreator<{
  filter: string;
}>('importJobFilterChanged');
