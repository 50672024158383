import './username-input.css';
import {
  bindable,
  PLATFORM,
  computedFrom,
  customElement, 
  ComponentDetached,
  autoinject
} from 'aurelia-framework';
import { isEmpty, isNone, emptyArray, assureArray, isSomething } from '../../../utility';
import { createCustomEvent } from '../../../utility/customEventHelper';

type errorType = undefined | string | string[] | boolean;
const isValidationError = (error: errorType): boolean => {
  return !(typeof error === 'boolean' || isNone(error));
};

@autoinject()
@customElement('username-input')
export class UsernameInput implements ComponentDetached {
  @bindable placeholder: string | undefined;
  @bindable value: string | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable error: errorType;
  @bindable isValid: boolean = true;
  @bindable required: boolean;
  inputelement: Element;

  attached() {
    this.runValidation(this.value);
  }

  detached() {
    dispatchEvent(
      createCustomEvent('inputremoved', this.inputelement)
    );
  }

  valueChanged(newValue: string | undefined) {
    this.onChanged && this.onChanged({ value: newValue });

    if (isSomething(this.inputelement)) {
      this.runValidation(newValue);
    }    
  }

  validateUsername(newValue: string | undefined): errorType {
    let input = newValue?.trim();
    
    if (!input) {
      this.isValid = false; 
      return ['UI_UserManager_InvalidUsername'];
    }

    const regex = /^[a-z0-9äöëæøåü\.\@_-]{3,50}$/igm; 
    if(!regex.test(input)) {
      this.isValid = false; 
      return ['UI_UserManager_InvalidUsername'];
    }
    
    this.isValid = true; 
    return undefined;   
  }

  runValidation(newValue: string | undefined) {
    const oldError = this.error;
    this.error = this.requiredValidation(newValue);
    
    if(!this.error) {
      this.error = this.validateUsername(newValue)
    }
    
    if (isValidationError(this.error) !== isValidationError(oldError)) {
      this.notifyOfValidationErrors();
    }
  }

  requiredValidation(newValue: string | undefined): errorType {
    if (this.required && isEmpty(newValue)) {
      return ['UI_Common_ValidationError_RequiredField'];
    }
    return undefined;
  }

  notifyOfValidationErrors() {
    if (isNone(this.inputelement)) {      
      return;
    }
    if (isValidationError(this.error)) {
      this.inputelement.dispatchEvent(
        createCustomEvent('validationerrors', this.inputelement)
      );
    } else if (!isValidationError(this.error)) {
      this.inputelement.dispatchEvent(
        createCustomEvent('validationsuccess', this.inputelement)
      );
    }
  }

  @computedFrom('error')
  get hasValidationErrors() {    
    return typeof this.error === 'boolean' ? this.error : !isEmpty(this.error);
  }

  @computedFrom('error')
  get errorTexts() {
    return typeof this.error === 'boolean' || isNone(this.error)
      ? emptyArray
      : assureArray(this.error);
  }
}
