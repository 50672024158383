import { autoinject, bindable, computedFrom, customElement } from 'aurelia-framework';
import { ISiteCardSite, ISiteListSite } from '../../interfaces/index';
import { distinct, removeNoneFromArray } from '../../utility/arrayHelpers';
import { isEmpty } from '../../utility';
import './sitecard.css';
import { SiteSignal } from '../../models';
import gql from 'graphql-tag';
import { I18N } from 'aurelia-i18n';
import { SiteStatusBarFragmentDefinition } from '../bars/site-status-bar/site-status-bar';
import { SitecardFragment, SitecardFragment_tanks_product } from '../../../custom_typings/graphql';

export const SitecardFragmentDefinition = gql`
  fragment SitecardFragment on Site {
    ...SiteStatusBarFragment
    siteId
    alias
    alarms(active: true, alarmType: Custom) {
      alarmId
      active
    }
    tanks: channels(channelTypes: [Tank]) {
      maximum
      minimum
      capacity
      lastSample
      lastSampleTime
      product {
        productId
        name
        translations {
          translation
          languageCode
        }
      }
      unit {
        decimals
        symbol
        unitId
      }
      tankDetails {
        reachMinimum
        percentage
      }
    }
  }
  ${SiteStatusBarFragmentDefinition}
`;

@autoinject()
@customElement('sitecard')
export class SitecardCustomElement {
  @bindable site: ISiteCardSite | ISiteListSite | SitecardFragment;
  @bindable fromgraphql = false;
  siteSignal = SiteSignal;

  constructor(private i18n: I18N) {}
  
  @computedFrom('site.tanks')
  get showToggleTanks() {
    return this.site.tanks.length > 1;
  }

  hasActiveAlarms(site: SitecardFragment) {
    return !isEmpty(site.alarms) && site.alarms.every(a => a.active);
  }

  isVirtual(site: SitecardFragment) {
    return (
      !isEmpty(site.controllers) && site.controllers.every(a => a.isVirual)
    );
  }

  showTranslationIfExist = (item: SitecardFragment_tanks_product) => {
    const selectedLanguage = this.i18n.getLocale();
    if(!item.translations) return item.name;

    const translation = item.translations.find(
      (t) => t.languageCode === selectedLanguage
    );
    return translation?.translation || item.name;
  };

  @computedFrom('site.tanks')
  get shipTo() {
    const tanks = this.site.tanks as any;
    return removeNoneFromArray(
      distinct(
        tanks
          .map((tank: any) => tank.tankDetails.shipTo)
          .filter((shipTo: string) => !isEmpty(shipTo))
      )
    );
  }
}
