export interface IControllerCommand {
  readonly slaveNumber: number | undefined;
  readonly commandId: number;
  readonly controllerId: number;
  readonly started: Date;
  readonly command: string;
  readonly status: IControllerCommandStatus;
  readonly result?: string;
  readonly error?: Error;
  readonly ended?: Date;
}

export interface IControllerCommandResult {
  readonly result?: string;
  readonly statusCode?: number;
}

export enum IControllerCommandStatus {
  Running,
  Success,
  Failed
}
