import bowser from 'bowser';

const supportedBrowsers = {
  // declare browsers per OS
  // per platform (mobile, desktop or tablet)
  mobile: {
    safari: '>=9',
    'android browser': '>5'
  },
  chrome: '>=68',
  edge: '>=17',
  firefox: '>=66',
  safari: '>=12.1'
};

export const browserIsMobileOrTablet = (): boolean => {
  const parser = bowser.getParser(window.navigator.userAgent);
  const platform = parser.parsePlatform();
  return ['tablet', 'mobile'].includes(platform?.type || '');
};

export const browserIsDesktop = (): boolean => {
  const parser = bowser.getParser(window.navigator.userAgent);
  const platform = parser.parsePlatform();
  return platform.type === 'desktop';
}

export const browserIsAndroid = (): boolean => {
  const parser = bowser.getParser(window.navigator.userAgent);
  const os = parser.parseOS();
  return os.name === 'Android';
}

export const browserIsDesktopOrAndroid = (): boolean => browserIsDesktop() || browserIsAndroid();

export const browserIsSupported = () => {
  const parser = bowser.getParser(window.navigator.userAgent);
  return parser.satisfies(supportedBrowsers);
};
