import { IContact } from '../interfaces/entity/icontact';
import { isNone } from '../utility/helpers';

export class ContactIsEmptyValueConverter {
  toView(value: IContact | undefined | null): boolean {
    if (value === null || value === undefined) return true;
    const nameEmpty = isNone(value.name) || value.name.trim().length == 0;
    const emailEmpty = isNone(value.email) || value.email.trim().length == 0;
    const phoneEmpty = isNone(value.telephoneNumber) || value.telephoneNumber.trim().length == 0;
    return nameEmpty && emailEmpty && phoneEmpty;
  }
}
