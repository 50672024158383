import { getLanguages } from './../actions';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';
import { getAllEntities } from '../utility';

@autoinject
export class LanguageService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(LanguageService.name), taskQueue);
  }

  getLanguages = () =>
    this.httpRequestWithDispatchFromState(
      requests.getLanguages(),
      getLanguages,
      {},
      'Get all languages failed',
      state => getAllEntities(state.language)
    );
}
