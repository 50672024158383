import {
  customElement,
  bindable,
  computedFrom,
  autoinject
} from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import { UnitQuery, UnitQuery_units } from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';
import { orderByPredicate, isNone } from '../../../utility';
import { I18N } from 'aurelia-i18n';
import { getLogger } from 'aurelia-logging';

export type UnitDropdownFilter = 'ONLY_INTERVAL' | 'NO_INTERVAL' | undefined;

@autoinject()
@customElement('unit-dropdown')
export class UnitDropdown extends GraphQLBaseViewModel<void, UnitQuery, void> {
  @bindable() selected: UnitQuery_units | string | undefined;
  @bindable() valuePath: string;
  @bindable() itemClicked:
    | undefined
    | ((params: { value: UnitQuery_units; text: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() filterUnits: UnitDropdownFilter;
  @bindable() disabled: boolean;
  @bindable() placeholder: string;

  constructor(private i18n: I18N) {
    super(getLogger(UnitDropdown.name));
  }

  query = gql`
    query UnitQuery {
      units {
        unitId
        translationKey
        symbol
        unitIdWithoutInterval
      }
    }
  `;

  @computedFrom('data', 'filterUnits')
  get sortedUnits() {
    if (!this.data) return this.data;
    const sorted = orderByPredicate(
      this.data.units,
      u => this.i18n.tr(u.translationKey),
      'asc',
      true
    );
    if (!this.filterUnits) return sorted;

    switch (this.filterUnits) {
      case 'NO_INTERVAL':
        return sorted.filter(s => isNone(s.unitIdWithoutInterval));
      case 'ONLY_INTERVAL':
        return sorted.filter(s => !isNone(s.unitIdWithoutInterval));
      default:
        return sorted;
    }
  }

  displayUnitItem(item: UnitQuery_units) {
    return `${this.i18n.tr(item.translationKey)} (${item.symbol})`;
  }
}
