export const tooltipEvent = 'tooltip';

export type TooltipEvent = 
    { type: 'show', id: unknown, properties: TooltipToDisplay } | 
    { type: 'hide', id: unknown }

export interface TooltipToDisplay {
    text: string
    attachedElement: DOMRect
    placement: 'top' | 'left'
}