import { bindable, computedFrom } from 'aurelia-framework';
import { SiteStatus, SiteSignal } from '../../../models';
import { ISiteCardSite, ISiteListSite } from '../../../interfaces';
import './site-status-bar.css';
import gql from 'graphql-tag';
import { SignalEnum, SiteStatusBarFragment } from '../../../../custom_typings/graphql';
import { isEmpty } from '../../../utility/helpers';

export const SiteStatusBarFragmentDefinition = gql`
  fragment SiteStatusBarFragment on Site {
    siteId
    signal
    channels {
      isParked
    }
    alarms(active: true, alarmType: Custom) {
      alarmId
      active
    }
    controllers {
      isVirual
      controllerType
      controllerId
    }
  }
`;

const getSiteHealthFromGraphQL = (site: SiteStatusBarFragment) => {
  const isParked =
    !isEmpty(site.channels) && site.channels.every(c => c.isParked);
  const hasAlarm = !isEmpty(site.alarms) && site.alarms.some(a => a.active);
  const isVirtual =
    !isEmpty(site.controllers) && site.controllers.every(c => c.isVirual);

  if (site.signal == SignalEnum.Stale && isParked && !hasAlarm)
    return SiteStatus.StaleParked;
  else if (site.signal == SignalEnum.Offline && isParked && !hasAlarm)
    return SiteStatus.OfflineParked;
  else if (hasAlarm && isParked) return SiteStatus.AlarmParked;
  else if (isVirtual) return SiteStatus.Virtual;
  else if (hasAlarm) return SiteStatus.Alarm;
  else if (site.signal == SignalEnum.Stale) return SiteStatus.Stale;
  else if (site.signal == SignalEnum.Offline) return SiteStatus.Offline;
  else if (isParked) return SiteStatus.Parked;
  else return SiteStatus.Normal;
};

export const getSiteHealth = (site: ISiteCardSite | ISiteListSite) => {
  if (site.signal == SiteSignal.Stale && site.isParked && !site.hasAlarm)
    return SiteStatus.StaleParked;
  else if (site.signal == SiteSignal.Offline && site.isParked && !site.hasAlarm)
    return SiteStatus.OfflineParked;
  else if (site.hasAlarm && site.isParked) return SiteStatus.AlarmParked;
  else if (site.isVirtual) return SiteStatus.Virtual;
  else if (site.hasAlarm) return SiteStatus.Alarm;
  else if (site.signal == SiteSignal.Stale) return SiteStatus.Stale;
  else if (site.signal == SiteSignal.Offline) return SiteStatus.Offline;
  else if (site.isParked) return SiteStatus.Parked;
  else return SiteStatus.Normal;
};

export class SiteStatusBarCustomElement {
  @bindable onClick: () => void;
  @bindable site: ISiteCardSite | ISiteListSite | undefined;
  @bindable sitegraphql: SiteStatusBarFragment | undefined;
  @bindable noBackground: boolean = false;

  @computedFrom('site')
  get classAndIconName(): IClassAndIconName {
    switch (this.siteHealth) {
      case SiteStatus.Alarm:
        return { className: 'alarm', iconName: 'alarm' };

      case SiteStatus.Offline:
        return { className: 'offline', iconName: 'cross' };

      case SiteStatus.Parked:
        return { className: 'parked', iconName: 'parking' };

      case SiteStatus.OfflineParked:
        return { className: 'offline', iconName: 'parking' };

      case SiteStatus.StaleParked:
        return { className: 'stale', iconName: 'parking' };

      case SiteStatus.Virtual:
      case SiteStatus.Stale:
        return { className: 'stale', iconName: 'uncertain' };

      case SiteStatus.AlarmParked:
        return { className: 'alarm', iconName: 'parking' };

      case SiteStatus.Normal:
      default:
        return { className: '', iconName: 'tick' };
    }
  }

  @computedFrom('site', 'sitegraphql')
  get siteHealth(): SiteStatus | undefined {
    if (this.sitegraphql) return getSiteHealthFromGraphQL(this.sitegraphql);
    else if (this.site) return getSiteHealth(this.site);
    return;
  }

  @computedFrom('site')
  get iconSrc() {
    return `icons/${this.classAndIconName.iconName}.svg`;
  }
}

interface IClassAndIconName {
  className: string;
  iconName: string;
}
