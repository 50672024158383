import endpoints from './endpoints';
import { Aurelia } from 'aurelia-framework';
import { BaseConfig } from 'aurelia-authentication';
import { PLATFORM } from 'aurelia-pal';

export const authConfig = {
  baseUrl: endpoints.auth,
  loginUrl: 'connect/token',
  clientId: 'client',
  clientSecret: 'secret',
  httpInterceptor: true,
  authHeader: 'Authorization',
  authTokenType: 'Bearer',
  accessTokenProp: 'access_token',
  useRefreshToken: false,
  loginRedirect: '/',
  logoutRedirect: '/',
  storageChangedReload: true,
  expiredRedirect: '/' // bug #3556
};

export const configureAuth = (aurelia: Aurelia) => {
  aurelia.use.plugin(
    PLATFORM.moduleName('aurelia-authentication'),
    (baseConfig: BaseConfig) => {
      baseConfig.configure(authConfig);
    }
  );
};
