import { insertFetchedEntities } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IAsyncReducerState } from '../../interfaces';
import {
  IAsyncDictionary,
  createPendingEntity,
  createFetchedEntity
} from '../../types';
import { getExportJobRuns } from '../../actions/exportJobRuns';
import { IExportJobRun } from '../../interfaces/entity/iExportJobRun';

export interface IExportJobRunsState extends IAsyncReducerState<IExportJobRun> {
  byExportJob: IAsyncDictionary<number[]>;
}

const defaultState: IExportJobRunsState = {
  allIds: undefined,
  byId: {},
  byExportJob: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(getExportJobRuns.started, (state, exportJobId) => ({
    ...state,
    byExportJob: {
      ...state.byExportJob,
      [exportJobId]: createPendingEntity()
    }
  }))
  .case(getExportJobRuns.done, (state, { params, result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, r => r.exportJobId),
    byExportJob: {
      ...state.byExportJob,
      [params]: createFetchedEntity(result.map(r => r.exportJobId))
    }
  }))
  .case(getExportJobRuns.failed, state => ({ ...state }));

export default reducer;
