import { autoinject, bindable, computedFrom, customElement } from 'aurelia-framework';
import gql from "graphql-tag";
import './columnfilterref.css';
import { getLogger } from "aurelia-logging";
import { I18N } from 'aurelia-i18n';
import { GraphQLBaseViewModel } from '$pages/common';
import { ColumnFilterRefQuery, ColumnFilterRefQueryVariables, ElasticSearchPage } from '$typings/graphql';
import { IFilterGroup, ITextFilter } from '$interfaces/iFilter';
import { IFilterDefinition } from '$components/grid/grid';
import { isMobileWatcher, isTouchDeviceWatcher } from '$lib/deviceHelpers';
import { orderByPredicate } from '$lib/sorting';
import { distinct } from '$lib/arrayHelpers';
import { isEmpty } from '$lib/helpers';

@autoinject()
@customElement('column-filter-ref')
export class ColumnFilterRef extends GraphQLBaseViewModel<void, ColumnFilterRefQuery, ColumnFilterRefQueryVariables> {
    @bindable({ changeHandler: 'updateVariables' }) filterGroup: IFilterGroup | undefined;
    @bindable({ changeHandler: 'updateVariables' }) property: string;
    @bindable({ changeHandler: 'updateVariables' }) activeFilters: IFilterGroup[] | undefined;
    @bindable({ changeHandler: 'updateVariables' }) page: ElasticSearchPage | undefined;
    @bindable({ changeHandler: 'updateVariables' }) definition: IFilterDefinition;
    @bindable({ changeHandler: 'updateVariables' }) filterValue: string = '';
    @bindable({ changeHandler: 'updateVariables' }) freeTextQuery: string[] | undefined;
    @bindable() changedFilter: Function;

    rowHeight: number = 30;

    showOnlyItemsWithSites = true;

    autofocus: boolean = false;
    private unsubscribeWatcher: Function; 
    
    isMobile: boolean;

    constructor(private i18n: I18N) {
        super(getLogger(ColumnFilterRef.name));
        this.unsubscribeWatcher = isTouchDeviceWatcher((isTouchDevice: boolean) => {
            this.autofocus = !isTouchDevice;
        });
        this.unsubscribeOnDetach(
          isMobileWatcher(isMobile => {
            this.isMobile = isMobile;
          })
        );
    }

    attached() {
        this.filterValue = '';   
    }

    detached() {
        this.unsubscribeWatcher();
    }

    @computedFrom('data.siteFilterRefValues', 'filterValue')
    get textFilteredItems() {
        if(isEmpty(this.filterValue)) return this.data?.refFilterValues;
        const sanitizedFilterValue = this.filterValue.toLocaleLowerCase().trim();
        return this.data?.refFilterValues.filter(i => i.label?.toLocaleLowerCase()?.trim()?.includes(sanitizedFilterValue));
    }

    @computedFrom('textFilteredItems', 'showOnlyItemsWithSites')
    get filteredItems() {
        const arr = this.showOnlyItemsWithSites ? 
            this.textFilteredItems?.filter(v => v.instances) : 
            this.textFilteredItems;
        
        return arr ? orderByPredicate(arr, i => i.label?.toLocaleLowerCase()?.trim(), 'asc') : arr;
    }

    @computedFrom('filteredItems', 'filterGroup.filters')
    get allItemsAreSelected() {
        const filters = this.filterGroup?.filters || [];
        const items = this.filteredItems;
        if(!items || !items.length || !filters.length || items.length > filters.length) return false;
        return items.every(item => this.isSelected(item.key, filters as ITextFilter[]))
    }

    toggleAll(selectAll: boolean){
        const existingFilters = (this.filterGroup?.filters || []) as ITextFilter[];
        const items = this.filteredItems || [];
        
        const newFilters: ITextFilter[] = selectAll ? 
        distinct([
            ...existingFilters, 
            ...(items.map(i => ({ value: i.key })))
        ]) : 
        existingFilters.filter(filter => !items.some(i => i.key === filter.value));
        
        const newfilter = { ...(this.filterGroup || { exclude: false, field: this.property, type: 'ref' }), filters: newFilters };
        this.changedFilter && this.changedFilter({ newfilter });

    }

    @computedFrom('textFilteredItems')
    get numberOfItemsWithNoSites() {
        return this.textFilteredItems?.filter(i => !i.instances).length;
    }
    
    excludeClicked() {
    }

    isSelected(key: string, filters: ITextFilter[]){
        return filters && !!filters.find(filter => filter.value.toString() === key);
    }

    query = gql`
        query ColumnFilterRefQuery($property: String!, $filters: String, $page: ElasticSearchPage!, $freeTextQuery: [String], $language: String) {
            refFilterValues(property: $property, filters: $filters, page: $page, freeTextQuery: $freeTextQuery, language: $language){
                key
                label
                instances
            }
        }
    `

    activeFiltersChanged(){
        this.updateVariables();
    }

    bind(){
        this.updateVariables();
    }

    updateVariables() {
        if(!this.page)
            throw Error("Page was not set on grid/column-filter/column-filter-ref");
 
        const filters = this.activeFilters?.filter(f => f.field !== this.property);
        if (
          this.variables &&
          this.variables.filters === JSON.stringify(filters) &&
          this.variables.property === this.property &&
          this.variables.freeTextQuery === this.freeTextQuery) {
            return;
          }

        this.variables = {
            property: this.property,
            freeTextQuery: this.freeTextQuery,
            filters: filters ? JSON.stringify(filters) : undefined,
            page: this.page,
            language: this.i18n.getLocale()
        }
    }

    refChecked(checked: boolean, reference: string, existingFilters: ITextFilter[]){
        const filters: ITextFilter[] = checked ? 
            [...existingFilters, { value: reference }] : 
            existingFilters.filter(filter => filter.value !== reference);
        
        const newfilter = { ...(this.filterGroup || { exclude: false, field: this.property, type: 'ref' }), filters };
        this.changedFilter && this.changedFilter({ newfilter });
    }
}
