import { sendControllerCommandAction, cleanupCommands } from '../../actions';
import { IControllerCommand, IControllerCommandStatus } from '../../interfaces';
import { mergeObjects, prependObjectToArray } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

interface IControllerCommandsById {
  [id: number]: IControllerCommand;
}

export interface IControllerCommandsReducerState {
  byId: IControllerCommandsById;
  allIds: number[];
}

const defaultState: IControllerCommandsReducerState = { allIds: [], byId: {} };

const replaceControllerCommand = (
  byIdState: IControllerCommandsById,
  commandId: number,
  command: Partial<IControllerCommand>
) => ({
  ...byIdState,
  [commandId]: {
    ...byIdState[commandId],
    ...command
  }
});

const reducer = reducerWithInitialState(defaultState)
  .case(sendControllerCommandAction.started, (state, param) =>
    mergeObjects(state, {
      allIds: prependObjectToArray(state.allIds, param.internalId),
      byId: replaceControllerCommand(state.byId, param.internalId, {
        command: param.command,
        commandId: param.internalId,
        controllerId: param.controllerId,
        status: IControllerCommandStatus.Running,
        started: new Date(),
        slaveNumber: param.slaveNumber
      })
    })
  )
  .case(sendControllerCommandAction.failed, (state, { params, error }) =>
    mergeObjects(state, {
      byId: replaceControllerCommand(state.byId, params.internalId, {
        command: params.command,
        commandId: params.internalId,
        controllerId: params.controllerId,
        status: IControllerCommandStatus.Failed,
        ended: new Date(),
        error: error,
        slaveNumber: params.slaveNumber
      })
    })
  )
  .case(sendControllerCommandAction.done, (state, { params, result }) =>
    mergeObjects(state, {
      byId: replaceControllerCommand(state.byId, params.internalId, {
        command: params.command,
        commandId: params.internalId,
        controllerId: params.controllerId,
        status:
          result.statusCode === 503
            ? IControllerCommandStatus.Failed
            : IControllerCommandStatus.Success,
        result: result.result,
        ended: new Date(),
        slaveNumber: params.slaveNumber
      })
    })
  )

  .case(cleanupCommands, (state, action) => {
    let remainingIds = state.allIds.filter(id => {
      let command = state.byId[id];
      return (
        command.controllerId !== action.controllerId ||
        command.status === IControllerCommandStatus.Running
      );
    });
    return mergeObjects(state, { allIds: remainingIds });
  });

export default reducer;
