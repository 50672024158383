import { ITankUsage, IAsyncReducerState } from '../../interfaces/index';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { fetchingTankUsage } from '../../actions/index';
import { mergeObjects, insertFetchedEntity } from '../../utility/index';
import { createPendingEntity } from '../../types';

export interface ITankUsageReducerState
  extends IAsyncReducerState<ITankUsage[]> {}

const defaultState: ITankUsageReducerState = {
  allIds: undefined,
  byId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingTankUsage.started, (state, payload) =>
    mergeObjects(state, { byId: { [payload]: createPendingEntity() } })
  )
  .case(fetchingTankUsage.done, (state, { params, result }) =>
    insertFetchedEntity(state, params, result)
  );

export default reducer;
