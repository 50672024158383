import { isEmpty } from './helpers';

export const hexTo255Number = (hex: string): number => parseInt(hex, 16);

export const hexToRGBA = (hex: string, opactity: number = 1) =>
  `rgba(${hexTo255Number(hex.substring(1, 3))}, ${hexTo255Number(
    hex.substring(3, 5)
  )}, ${hexTo255Number(hex.substring(5, 7))}, ${opactity})`;

export const getContrastColor = (rgbaOrHex: string) => {
  if (isEmpty(rgbaOrHex)) return 'black';
  let rgba = rgbaOrHex.startsWith('#') ? hexToRGBA(rgbaOrHex) : rgbaOrHex;
  rgba = rgba.split(/\(([^)]+)\)/)[1].replace(/ /g, '');

  // map RGB values to variables
  let r = parseInt(rgba.split(',')[0], 10),
    g = parseInt(rgba.split(',')[1], 10),
    b = parseInt(rgba.split(',')[2], 10);

  // calculate contrast of color (standard grayscale algorithmic formula)
  let contrast =
    (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;

  return contrast >= 128 ? 'black' : 'white';
};
