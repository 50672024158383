import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getAllHauliers } from '../actions';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';
import { selectHauliers } from '../pages/common';

@autoinject
export class HaulierService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(HaulierService.name), taskQueue);
  }

  getAll = () => 
    this.httpRequestWithDispatchFromState(
      requests.getAllHauliers(),
      getAllHauliers,
      {},
      'Fetching all hauliers failed',
      selectHauliers
    );   
}
