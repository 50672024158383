import { getAllVehicles } from '../../actions';
import { mergeObjects, insertAllFetchedEntities } from '../../utility';
import { IVehicle, IAsyncReducerState } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { createPendingEntity } from '../../types';

export interface IVehicleReducerState extends IAsyncReducerState<IVehicle> {}

const defaultState: IVehicleReducerState = { allIds: undefined, byId: {} };

const reducer = reducerWithInitialState(defaultState)
  .case(getAllVehicles.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )
  .case(getAllVehicles.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, vehicle => vehicle.vehicleId)
    )
  );

export default reducer;
