
export class BroadcastManagerChannelService {
  private static instance: BroadcastManagerChannelService | null = null;
  private channel: BroadcastChannel | undefined;
  private channels: string[];

  private constructor() {
    if (typeof BroadcastChannel !== 'undefined') {
      this.channel = new BroadcastChannel('yt3-broadcast-channel');
    } else {
      this.channel = undefined; 
    }
    
    this.channels = [];
  }

  public static getInstance(): BroadcastManagerChannelService {
     if (!BroadcastManagerChannelService.instance) {
      BroadcastManagerChannelService.instance = new BroadcastManagerChannelService();
     }
     return BroadcastManagerChannelService.instance;
  }
   
  public setupListener(action: string, callback: () => void) {
    //only add an event once
    try {
      if(this.channel && !this.channels.includes(action)){
        this.channel.onmessage = (event) => {
          if (event.data.action === action) {
          callback()
          }
        };
        this.channels.push(action);
      }  
    } catch (error) {
      console.error(error);
    }
  }
   
  public sendMessage(action: string) {
    try {
      this.channel && this.channel.postMessage({ action });
    } catch (error) {
      console.error(error);
    }     
  }
   
  public close() {
    try {
      this.channel && this.channel.close();
    } catch (error) {
      console.error(error);
    }       
  }
}