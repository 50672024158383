import { bindable, customElement, DOM } from 'aurelia-framework';
import { isNone } from '../../../utility';
import './action-button.css';

@customElement('action-button')
export class ActionButton {
  @bindable onClick: () => void;
  @bindable text: string;
  @bindable processing: boolean;

  @bindable disabled: boolean = false;
  @bindable icon: string = 'fa-caret-down';
  @bindable alignTop: boolean;
  @bindable alignRight: boolean;

  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;

  @bindable showContent: boolean | undefined;
  @bindable buttonType: 'primary' | 'secondary' = 'primary';
  @bindable size: string = 'medium';

  @bindable showIcon: boolean = true;

  @bindable buttonClicked: Function;
  @bindable stopPropagation: boolean = false;

  showContentChanged(newValue: boolean | undefined) {
    this._isExpanded = !!newValue;
  }

  _isExpanded: boolean = false;

  buttonIsClicked(event: Event) {
    if (this.stopPropagation) event.stopPropagation();
    if (!isNone(this.buttonClicked)) {
      this.buttonClicked();
    }

    this.toggleList();
  }

  toggleList = () => {
    if (!isNone(this.showContent) || this.disabled) return;
    if (this._isExpanded) {
      this.closeList();
    } else {
      this.openList();
    }
  };

  closeList = () => {
    if (!isNone(this.showContent) || this.disabled) return;
    this._isExpanded = false;
    DOM.removeEventListener('click', this.closeList, false);
  };

  openList = () => {
    this._isExpanded = true;
    setTimeout(() => DOM.addEventListener('click', this.closeList, false));
  };
}
