import {
  ISiteChannel,
  IChannel,
  ITankDetails,
  ChannelTypes,
  IProduct,
  IChannelsThatTrackTankChannel
} from '../interfaces';

import { sortDirection, requestError } from '../types';
import actionCreatorFactory from 'typescript-fsa';
import { ITrendDataAndStats } from '../models/trend';

const actionCreator = actionCreatorFactory('sitechannels');

export const getSiteChannelsForSite = actionCreator.async<
  number,
  ISiteChannel[]
>('get_sitechannels_for_site');
export const getSiteChannel = actionCreator.async<number, ISiteChannel, string>(
  'getSiteChannel'
);

export const getChannelByToken = actionCreator.async<number, IChannel, string>(
  'getChannelByToken'
);

export const getChannelsThatTrackTank = actionCreator.async<
  number,
  IChannelsThatTrackTankChannel[],
  string
>('getChannelsThatTrackTank');

export const getAllSiteChannels = actionCreator.async<number, ISiteChannel[]>(
  'get_all_sitechannels'
);
export const getAllSiteChannelsOfTanks = actionCreator.async<
  number,
  ISiteChannel[]
>('get_all_sitechannels_of_tanktype');

export const getAllChannelsForController = actionCreator.async<
  number,
  IChannel[],
  string
>('get_channels_for_controller');

export const deleteSiteChannel = actionCreator.async<
  { siteChannelId: number; siteId: number },
  void
>('deleteSiteChannel');
export const updateSiteChannel = actionCreator.async<
  ISiteChannel,
  ISiteChannel,
  requestError
>('updateSiteChannel');
export const createSiteChannel = actionCreator.async<
  { siteId: number; channelId: number },
  ISiteChannel,
  requestError
>('createSiteChannel');
export const createSiteChannelAndChannel = actionCreator.async<
  ISiteChannel,
  ISiteChannel,
  requestError
>('createSiteChannelAndChannel');

export const updateSiteChannels = actionCreator.async<
  { siteChannelIds: number[]; siteId?: number },
  ISiteChannel[],
  string
>('updateSiteChannels');

export const editSiteChannelProductProperty = actionCreator<{
  id: number;
  product: IProduct;
}>('editSiteChannelProductProperty');
export const editSiteChannel = actionCreator<{
  id: number;
  key: keyof IChannel;
  value: string | number | undefined;
}>('edit_site_channel');
export const editSiteChannelType = actionCreator<{
  id: number;
  channelType: ChannelTypes;
  channel: IChannel;
}>('edit_sitechannel_type');
export const editTankSiteChannel = actionCreator<{
  id: number;
  key: keyof ITankDetails;
  value: string | number | undefined;
}>('edit_sitechannel_tankdetails');

export const siteChannelSortingChanged = actionCreator<{
  sortProperty: string;
  sortDirection: sortDirection;
}>('siteChannelSortingChanged');

export const setChannelOrderMode = actionCreator<undefined | number[]>(
  'setChannelOrderMode'
);

export const toggleHideStaleChannels = actionCreator(
  'toggleHideStaleChannels'
);

export const addChannelPropertyChanged = actionCreator<{
  property: string;
  value: string | number;
}>('addChannelPropertyChanged');
export const deleteChannel = actionCreator.async<
  { controllerId: number; channelId: number; dependentChannelId?: number },
  void,
  string
>('deleteChannel');
export const updateChannel = actionCreator.async<
  IChannel,
  IChannel,
  requestError
>('updateChannel');
export const createChannel = actionCreator.async<
  IChannel,
  IChannel,
  requestError
>('createChannel');
export const fetchChannel = actionCreator.async<number, IChannel, string>(
  'fetchChannel'
);

export const fetchChannelHistoryForSiteChannel = actionCreator.async<
  string,
  ITrendDataAndStats,
  requestError
>('fetchChannelHistoryForSiteChannel');
export const fetchChannelHistoryForSiteChannels = actionCreator.async<
  string[],
  ITrendDataAndStats[],
  requestError
>('fetchChannelHistoryForSiteChannels');

export const multiEditSiteChannels = actionCreator<number>('multiEditSiteChannels')
