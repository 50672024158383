import actionCreatorFactory from 'typescript-fsa';
import { IAlarm, IAlarmRecipient, IAlarmLog, IUser } from '../interfaces';

const actionCreator = actionCreatorFactory('alarms');

// fetching alarm(s)
export const fetchingAlarmsForSite = actionCreator.async<
  number,
  IAlarm[],
  string
>('fetchingAlarmsForSite');
export const fetchingAllAlarms = actionCreator.async<
  undefined,
  IAlarm[],
  string
>('fetchingAllAlarms');
export const fetchingAllCustomAndActive = actionCreator.async<
  undefined,
  IAlarm[],
  string
>('fetchingAllCustomAndActive');
export const fetchingAlarm = actionCreator.async<
  { alarmId: number; siteId: number },
  IAlarm,
  string
>('fetchingAlarm');

// alarm edit
export const editAlarm = actionCreator<IAlarm>('editAlarm');

export const alarmPropertyChanged = actionCreator<{
  name: string;
  value: string | number;
}>('alarmPropertyChanged');
export const updateAlarm = actionCreator.async<IAlarm, IAlarm, string>(
  'updateAlarm'
);

export const cancelEdit = actionCreator('cancelEdit');

export const createAlarm = actionCreator.async<
  { siteId: number; alarm: IAlarm; recipients: IAlarmRecipient[] },
  IAlarm,
  string
>('createAlarm');

export const deleteAlarm = actionCreator.async<
  { siteId: number; alarm: IAlarm },
  void,
  string
>('deleteAlarm');

export const acknowledgeAlarm = actionCreator.async<
  { siteId: number, alarm: IAlarm },
  IAlarm,
  string
>('acknowledgeAlarm');


// alarm logs
export const fetchingAlarmLogsAction = actionCreator.async<
  { alarmId: number; fetchAll: boolean },
  IAlarmLog[],
  string
>('fetchingAlarmLogsAction');

// alarm recipients
export const fetchingAlarmRecipients = actionCreator.async<
  { alarmId: number },
  IAlarmRecipient[],
  string
>('fetchingAlarmRecipients');

export const addAlarmRecipient = actionCreator.async<
  { alarmId: number; externalEmail?: string; userId?: number },
  IAlarmRecipient,
  string
>('addAlarmRecipient');

export const removeAlarmRecipient = actionCreator.async<
  { alarmId: number; recipientId: number },
  void,
  string
>('removeAlarmRecipient');

export const updateAlarmRecipient = actionCreator.async<
  IAlarmRecipient,
  IAlarmRecipient,
  string
>('updateAlarmRecipient');

export const recipientPropertyChanged = actionCreator<{
  name: string;
  value: string;
}>('recipientPropertyChanged');

export const addRecipient = actionCreator<{
  recipient: IAlarmRecipient;
  user?: IUser;
  existingRecipients: IAlarmRecipient[];
}>('addRecipient');
export const removeRecipient = actionCreator<{
  recipient: IAlarmRecipient;
  existingRecipients: IAlarmRecipient[];
}>('removeRecipient');
export const updateRecipient = actionCreator<{
  recipient: IAlarmRecipient;
  existingRecipients: IAlarmRecipient[];
}>('updateRecipient');

// alarm UI actions
export const toggleAddRecipientDialog = actionCreator(
  'toggleAddRecipientDialog'
);
export const changeRecipientType = actionCreator<'email' | 'user' | undefined>(
  'changeRecipientType'
);
export const externalRecipientAddressChanged = actionCreator<string>(
  'externalRecipientAddressChanged'
);

export const findAlarmRecipient = actionCreator.async<
  { query: string },
  IUser[],
  string
>('findUser');

export const editExternalRecipient = actionCreator<IAlarmRecipient>(
  'editExternalRecipient'
);
