import { customElement, bindable } from 'aurelia-framework';
// import { PLATFORM } from "aurelia-pal";
import './pill.css';

@customElement('pill-item')
export class PillItemComponent {
  @bindable iconClicked: Function | undefined;

  @bindable disabled: boolean = false;
}
