import { customElement, bindable, computedFrom } from 'aurelia-framework';
import { ProcessingElement } from '../../bases/processing-element';

@customElement('icon-async')
export class IconAsync extends ProcessingElement {
  @bindable icon: string;

  @bindable processingIcon: string = 'fa-spinner fa-pulse';
  @bindable processing: boolean | undefined;
  @bindable errorIcon: string = 'fa-exclamation-triangle';
  @bindable error: boolean | undefined;
  @bindable disabled: boolean | undefined;
  @bindable onClick: undefined | (() => Promise<any> | void);

  @computedFrom('_processing', '_disabled', '_error', 'icon')
  get iconName() {
    if (this._processing) return this.processingIcon;
    if (this._error) return this.errorIcon;
    return this.icon;
  }
}
