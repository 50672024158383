import { ISiteColumn, IUser, IUserSettings } from './../interfaces';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';
import {
  getAsyncEntity,
  getAsyncEntities,
} from '../utility/index';
import {
  getUser,
  getUsersByIds,
  alarmSubscriptionChanged,
  updateUser,
  updateUserSettings,
  updateSettingDismissDashboardWelcome
} from '../actions/user';
import {
  getAllBuidAndCustomerFilters,
  getRoleTemplates,
  getUserColumns,
  removeUsers,
  getUserManagerUser,
  userManagerUserInfoPersisting,
  getUserManagerAccessForUser,
  IUserAccess,
  persistUserManagerAccessForUser,
  getUserManagerAccessDescriptionForUser,
  userManagerUserInfoCreate,
  IUserAccessDescriptionRequest,
  getUserManagerSiteAccessForUser,
  getUserManagerCustomerAccessForUser,
  getUserManagerAlarmConnectionForUser
} from '../pages/usermanager/usermanagerReducer';
import { getCurrentUser } from '../actions';
import memoizee from 'memoizee';
import { IUserManagerListUser } from '../pages/usermanager/modules/usermanagerlist/usermanagerlist';

@autoinject
export class UserService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(UserService.name), taskQueue);
  }

  getUser = (userId: number, token: string) =>
    this.httpRequestWithDispatchFromState(
      requests.getUser(token),
      getUser,
      userId,
      'Could not fetch user',
      state => getAsyncEntity(state.users.byId, userId)
    );

  selectSpecificUsers = memoizee(getAsyncEntities);
  getUsers = (userIds: number[], tokens: string[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getUsersByIds(tokens),
      getUsersByIds,
      userIds,
      'Could not fetch users',
      state => this.selectSpecificUsers(userIds, state.users.byId)
    );

  getUserManagerUser = (userId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getUserForUserManager(userId),
      getUserManagerUser,
      userId,
      'Get user for usermanager failed',
      state => getAsyncEntity(state.usermanager.byId, userId)
    );

  getUserAccessForUser = (userId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getUserAccessForUser(userId),
      getUserManagerAccessForUser,
      userId,
      'Get user access failed',
      state => getAsyncEntity(state.usermanager.userAccess, userId)
    );

  getUserAccessDescription = (request: IUserAccessDescriptionRequest) =>
    this.httpRequestWithDispatchFromState(
      requests.getUserAccessDescriptionForUser(request.userId, request),
      getUserManagerAccessDescriptionForUser,
      JSON.stringify(request),
      'Getting user access description failed',
      state =>
        getAsyncEntity(
          state.usermanager.userAccessDescription,
          JSON.stringify(request)
        )
    );

  getUserCustomerAccess = (request: IUserAccessDescriptionRequest) =>
    this.httpRequestWithDispatchFromState(
      requests.getUserCustomerAccessForUser(request.userId, request),
      getUserManagerCustomerAccessForUser,
      JSON.stringify(request),
      'Getting user site access failed',
      state =>
        getAsyncEntity(
          state.usermanager.userCustomerAccess,
          JSON.stringify(request)
        )
    );

  getUserSiteAccess = (request: IUserAccessDescriptionRequest) =>
    this.httpRequestWithDispatchFromState(
      requests.getUserSiteAccessForUser(request.userId, request),
      getUserManagerSiteAccessForUser,
      JSON.stringify(request),
      'Getting user site access failed',
      state =>
        getAsyncEntity(
          state.usermanager.userSiteAccess,
          JSON.stringify(request)
        )
    );

  getUserAlarmConnection = (request: IUserAccessDescriptionRequest) =>
    this.httpRequestWithDispatchFromState(
      requests.getUserAlarmConnectionsForUser(request.userId, request),
      getUserManagerAlarmConnectionForUser,
      JSON.stringify(request),
      'Getting user alarm connections failed',
      state =>
        getAsyncEntity(
          state.usermanager.userAlarmConnection,
          JSON.stringify(request)
        )
    );

  persistUserAccessForUser = (access: IUserAccess) =>
    this.httpRequestWithDispatch(
      requests.saveUserAccessForUser(access.userId, access),
      persistUserManagerAccessForUser,
      access.userId,
      'Could not save user access'
    );

  getAllUserBuidAndCustomerFilter = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllBuidAndCustomerFilters(),
      getAllBuidAndCustomerFilters,
      {},
      'Get all user buid, customer and haulier filters failed',
      state => state.usermanager.currentUserBuidAndCustomerFilter
    );

  getRolesTemplates = () =>
    this.httpRequestWithDispatchFromState(
      requests.getRoleTemplates(),
      getRoleTemplates,
      {},
      'Get all user features failed',
      state => state.usermanager.roleTemplates
    );

  getCurrentUser = () =>
    this.httpRequestWithDispatchFromState(
      requests.getCurrentUser(),
      getCurrentUser,
      {},
      'Get current user features failed',
      state => state.userProfile.userProfile
    );

  getUserColumns = async (userId: number) =>
    this.httpRequestWithDispatch(
      requests.getColumnsByUserId(userId),
      getUserColumns,
      { userId },
      'Get columns by userId failed.'
    );

  updateUserColumns = async (userId: number, columns: ISiteColumn[]) =>
    this.httpRequest(requests.updateColumnsByUserId(columns, userId));

  updateMultipleUserColumns = async (
    userIds: number[],
    columns: ISiteColumn[]
  ) =>
    this.httpRequest(requests.updateColumnsByMultipleUserIds(columns, userIds));

  updateUserInformation = (userInfo: IUserManagerListUser) =>
    this.httpRequestWithDispatch(
      requests.updateUserInfo(userInfo),
      userManagerUserInfoPersisting,
      userInfo,
      'Unexpected error occurred when trying to save user information'
    );

  addUserAsync = (userInfo: IUserManagerListUser) =>
    this.httpRequestWithDispatch(
      requests.addUserInfo(userInfo),
      userManagerUserInfoCreate,
      userInfo,
      'Could not create new user.'
    );

  removeUsersAsync = async (userManagerIds: number[], success?: Function) =>
    this.httpRequestWithDispatch(
      requests.deleteUsers(userManagerIds),
      removeUsers,
      userManagerIds,
      'Unexpected error occurred',
      undefined,
      success
    );

  changeAlarmSubscription = (alarmTypeId: number, checked: boolean) =>
    this.httpRequestWithDispatch(
      requests.changeAlarmSubscription(alarmTypeId, checked),
      alarmSubscriptionChanged,
      { alarmTypeId, value: checked },
      'Could not add user.'
    );

  updateCurrentUser = (user: IUser) =>
    this.httpRequestWithDispatch(
      requests.updateCurrentUser(user),
      updateUser,
      user,
      'Could not update user'
    );

  updateUserSettingsAsync = (userSettings: IUserSettings) =>
    this.httpRequestWithDispatch(
      requests.updateUserSettings(userSettings),
      updateUserSettings,
      userSettings,
      'Could not update user settings'
    );

  changePassword = (password: string) =>
    this.httpRequest(requests.changePassword(password));

  dismissDashBoardWelcome = () =>
    this.httpRequestWithDispatch(
      requests.updateSettingDismissDashboardWelcome(),
      updateSettingDismissDashboardWelcome,
      undefined,
      'Could not update user settings'
    );
}
