import { bindable } from 'aurelia-framework';
import './information-dialog.css';

export class InformationDialogCustomElement {
  @bindable onConfirm: Function;

  @bindable headerText = 'Information';
  @bindable submitText = 'Ok';
  @bindable bodyText: string | undefined;
  @bindable error: string | undefined;

  confirm() {
    if (!this.onConfirm) return;

    return this.onConfirm();
  }
}
