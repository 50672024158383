import { customElement, bindable, autoinject } from 'aurelia-framework';
// import { orderByPredicate } from '../../../utility';
import { I18N } from 'aurelia-i18n';

const months: Array<{ key: string; value: number }> = [
  {
    key: 'All',
    value: -1
  },
  {
    key: 'UI_DateTime_Months_January',
    value: 1
  },
  {
    key: 'UI_DateTime_Months_February',
    value: 2
  },
  {
    key: 'UI_DateTime_Months_March',
    value: 3
  },
  {
    key: 'UI_DateTime_Months_April',
    value: 4
  },
  {
    key: 'UI_DateTime_Months_May',
    value: 5
  },
  {
    key: 'UI_DateTime_Months_June',
    value: 6
  },
  {
    key: 'UI_DateTime_Months_July',
    value: 7
  },
  {
    key: 'UI_DateTime_Months_August',
    value: 8
  },
  {
    key: 'UI_DateTime_Months_September',
    value: 9
  },
  {
    key: 'UI_DateTime_Months_October',
    value: 10
  },
  {
    key: 'UI_DateTime_Months_November',
    value: 11
  },
  {
    key: 'UI_DateTime_Months_December',
    value: 12
  }
];

@autoinject()
@customElement('month-dropdown')
export class MonthDropdown {
  @bindable() alignTop = false;
  @bindable() valuePath: string;
  @bindable() selected: string;
  @bindable() itemClicked: undefined | ((params: { value: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;

  data: Array<{ key: string; value: number }>;

  constructor(private i18n: I18N) {
    this.data = this.getData();
  }

  _itemClicked(value: string, text: string) {
    this.itemClicked && this.itemClicked({ value: value || text });
  }

  getDisplayValue = (item: { key: string; value: number }) =>
    this.i18n.tr(item.key);

  getData() {
    // return orderByPredicate(months, d => this.i18n.tr(d.value), 'asc');
    return months;
  }
}
