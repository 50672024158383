import { formatDate } from '../utility/dateHelpers';

export class DateFormatValueConverter {
  toView(
    value: Date | string,
    withTime: boolean,
    timezone?: string | number,
    separator?: string
  ) {
    if (value == null || value == undefined) return null;

    if (separator === undefined) separator = '.';

    return formatDate(value, withTime, separator, timezone);
  }
}
