import { bindable } from 'aurelia-framework';
import {
  INumberFilter,
  ConditionalOperator,
  ISiteColumn
} from '../../../interfaces';
import './number-filter.css';

export class NumberFilterCustomElement {
  // bindables - exposed properties
  @bindable inputText: string;
  @bindable buttonText: string;

  @bindable size = 'medium';

  @bindable items: Set<any>;

  @bindable useOptions: boolean = false;
  @bindable applyOnClose: boolean;

  @bindable column: ISiteColumn;

  @bindable onApplyFilter: Function;
  @bindable onCancelFilter: Function;

  @bindable isExclude: boolean;

  // internal properties
  currentValue: string;
  currentSecondValue: string;

  bind() {
    this.currentSecondValue = '';
    this.currentValue = '';
  }

  operatorOptions: InternalNumberFilter[] = [
    {
      name: 'Equals',
      value: 0,
      operator: ConditionalOperator.Equals,
      symbol: '='
    },
    {
      name: 'Less than',
      value: 1,
      operator: ConditionalOperator.LesserThan,
      symbol: '<'
    },
    {
      name: 'Greater than',
      value: 2,
      operator: ConditionalOperator.GreaterThan,
      symbol: '>'
    },
    {
      name: 'Between',
      value: 3,
      operator: ConditionalOperator.Between,
      symbol: '&&'
    }
  ];

  internalItems: INumberFilter[] = new Array<INumberFilter>();

  dropdownSelectedValue: number = 0;

  detached() {
    if (this.applyOnClose) this.apply();
  }

  add() {
    const value = parseFloat(this.currentValue);
    if (isNaN(value)) return;

    const filter: INumberFilter = {
      value,
      operator: this.operatorOptions[this.dropdownSelectedValue].operator,
      symbol: this.operatorOptions[this.dropdownSelectedValue].symbol
    };

    const secondValue = parseFloat(this.currentSecondValue);

    if (this.dropdownSelectedValue === 3) {
      if (isNaN(secondValue)) return;
      filter.secondValue = secondValue;
    }

    this.internalItems.push(filter);

    this.resetFilter();
  }

  resetFilter() {
    this.currentValue = '';
    this.currentSecondValue = '';
    this.dropdownSelectedValue = 0;
  }

  remove(index: number) {
    this.internalItems.splice(index, 1);
  }

  apply() {
    if (this.onApplyFilter) {
      this.onApplyFilter({
        column: this.column,
        filters: this.internalItems,
        exclude: this.isExclude
      });
    } else {
    }
  }

  dropdownOnChange(value: number) {
    this.dropdownSelectedValue = value;
  }

  clearAll() {
    this.internalItems = new Array<INumberFilter>();
    this.apply();
  }

  toggleExclude() {
    this.isExclude = !this.isExclude;
  }

  cancel() {
    if (this.onCancelFilter) this.onCancelFilter();
  }
}

interface InternalNumberFilter {
  name: string;
  value: number;
  operator: ConditionalOperator;
  symbol: string;
}
