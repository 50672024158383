import { bindingBehavior } from 'aurelia-framework';

@bindingBehavior('stopPropagation')
export class StopPropagationBindingBehavior {
  bind(binding: any, _source: any, enabled: boolean = true) {
    binding.standardCallSource = binding.callSource;
    if (!enabled) return;
    binding.callSource = function(this: any, event: Event) {
      this.standardCallSource(event);
      event.stopPropagation();
    };
  }

  unbind(binding: any, _source: any) {
    binding.callSource = binding.standardCallSource;
    binding.standardCallSource = null;
  }
}
