import { IAsyncReducerState } from '../../interfaces';
import {
  fetchChannelHistoryForSiteChannel,
  fetchChannelHistoryForSiteChannels
} from '../../actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ITrendDataAndStats } from '../../models';
import { createPendingEntity, createFetchedEntity } from '../../types';
import { toObjectAndMap } from '../../utility/stateHelpers';
import { insertFetchedEntities } from '../../utility/asyncHelpers';

export interface IChannelHistoryReducerState
  extends IAsyncReducerState<ITrendDataAndStats> {}

const defaultState: IChannelHistoryReducerState = {
  byId: {},
  allIds: undefined
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchChannelHistoryForSiteChannels.started, (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      ...toObjectAndMap(payload, id => id, () => createPendingEntity())
    }
  }))
  .case(fetchChannelHistoryForSiteChannels.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, p => p.siteChannelId)
  }))
  .case(fetchChannelHistoryForSiteChannel.started, (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload]: createPendingEntity()
    }
  }))
  .case(
    fetchChannelHistoryForSiteChannel.done,
    (state, { params, result }) => ({
      ...state,
      byId: {
        ...state.byId,
        [params]: createFetchedEntity(result)
      }
    })
  );

export default reducer;
