import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects, appendOrReplaceObjectInArray } from '../../utility';
import {
  selectDestinationLanguage,
  getLanguagesFromSource,
  toggleOnlyMissing,
  textChanged,
  discardChanges,
  createOrUpdateLanguageTexts
} from '../../actions';
import { selectSourceLanguage } from '../../actions/translation';
import { ILanguageText } from '../../interfaces';

export interface ITranslationUiReducerState {
  destinationLanguageId: number;
  sourceLanguageId: number;
  toggleOnlyMissing: boolean;
  isSwitchingSourceLanguage: boolean;
  editedLanguageTexts: ILanguageText[];
}

const defaultState: ITranslationUiReducerState = {
  destinationLanguageId: 0,
  sourceLanguageId: 7, // english
  toggleOnlyMissing: false,
  isSwitchingSourceLanguage: false,
  editedLanguageTexts: []
};

const reducer = reducerWithInitialState(defaultState)
  .case(textChanged, (state, payload) =>
    mergeObjects(state, {
      editedLanguageTexts: appendOrReplaceObjectInArray(
        state.editedLanguageTexts,
        languageText =>
          languageText.key == payload.key &&
          languageText.languageId == payload.languageId,
        payload
      )
    })
  )

  .case(createOrUpdateLanguageTexts.done, state =>
    mergeObjects(state, {
      editedLanguageTexts: []
    })
  )

  .case(discardChanges, state =>
    mergeObjects(state, {
      editedLanguageTexts: []
    })
  )

  .case(selectDestinationLanguage, (state, payload) =>
    mergeObjects(state, {
      destinationLanguageId: payload
    })
  )

  .case(selectSourceLanguage, (state, payload) =>
    mergeObjects(state, {
      sourceLanguageId: payload
    })
  )

  .case(getLanguagesFromSource.started, state =>
    mergeObjects(state, {
      isSwitchingSourceLanguage: true
    })
  )

  .case(getLanguagesFromSource.done, (state, payload) =>
    mergeObjects(state, {
      sourceLanguageId: payload.params.languageId,
      isSwitchingSourceLanguage: false
    })
  )

  .case(getLanguagesFromSource.failed, state =>
    mergeObjects(state, {
      isSwitchingSourceLanguage: true
    })
  )

  .case(toggleOnlyMissing, (state, payload) =>
    mergeObjects(state, {
      toggleOnlyMissing: payload
    })
  );

export default reducer;
