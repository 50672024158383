import { customElement, bindable } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import {
  CustomersQuery,
  CustomersQuery_customers
} from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';

@customElement('customer-dropdown')
export class CustomerDropdown extends GraphQLBaseViewModel<
  void,
  CustomersQuery,
  void
> {
  @bindable() selected: CustomersQuery_customers;
  @bindable() valuePath: string | undefined;
  @bindable() itemClicked:
    | undefined
    | ((params: { value: CustomersQuery_customers; text: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() placeholder: undefined | string;
  @bindable() required: boolean;

  query = gql`
    query CustomersQuery {
      customers {
        customerId
        name
      }
    }
  `;
}
