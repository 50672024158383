import { bindable } from 'aurelia-framework';
import './require-confirmation.css';

export class RequireConfirmationCustomElement {
  @bindable onCancel: Function;
  @bindable onConfirm: Function;

  @bindable confirmType: 'delete' | 'primary' = 'delete';
  @bindable headerText = 'UI_RequireConfirmation_Header';
  @bindable submitText = 'UI_RequireConfirmation_ConfirmOkButton';
  @bindable cancelText = 'UI_RequireConfirmation_CancelButton';
  @bindable deleteText = 'UI_RequireConfirmation_ConfirmDeleteButton';
  @bindable entityName: string = '';
  @bindable bodyText: string = '';
  @bindable error: string | undefined;

  cancel() {
    if (!this.onCancel) return;

    this.onCancel();
  }

  confirm() {
    if (!this.onConfirm) return;

    return this.onConfirm();
  }
}
