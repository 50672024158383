export interface ITrendOperator {
  id: number;
  key: string;
  value: string;
}

export const trendOperators = [
  {
    id: 0,
    key: 'UI_Trend_Edit_Operator_Operator_Last',
    value: 'last'
  },
  {
    id: 1,
    key: 'UI_Trend_Edit_Operator_Operator_Minimum',
    value: 'min'
  },
  {
    id: 2,
    key: 'UI_Trend_Edit_Operator_Operator_Maximum',
    value: 'max'
  },
  {
    id: 3,
    key: 'UI_Trend_Edit_Operator_Operator_Mean',
    value: 'mean'
  },
  {
    id: 4,
    key: 'UI_Trend_Edit_Operator_Operator_Delta',
    value: 'delta'
  },
  {
    id: 5,
    key: 'UI_Trend_Edit_Operator_Operator_Stdev',
    value: 'stdev'
  },
  {
    id: 6,
    key: 'UI_Trend_Edit_Operator_Operator_Count',
    value: 'count'
  },
  {
    id: 7,
    key: 'UI_Trend_Edit_Operator_Operator_Sum',
    value: 'sum'
  }
] as ITrendOperator[];

export const trendAxisTypes = [
  {
    id: 0,
    key: 'UI_Trend_Edit_Axis_Own',
    value: 'own'
  },
  {
    id: 1,
    key: 'UI_Trend_Edit_Axis_Shared',
    value: 'shared'
  }
] as ITrendOperator[];

export const trendAxisLocations = [
  {
    id: 0,
    key: 'UI_Trend_Edit_Axis_Scale_Left',
    value: 'left'
  },
  {
    id: 1,
    key: 'UI_Trend_Edit_Axis_Scale_Right',
    value: 'right'
  }
] as ITrendOperator[];
