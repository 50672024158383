import { IFilterDefinition } from "$components/grid/grid";
import { ConditionalOperator, IFilterGroup, INumberFilter } from "$interfaces/iFilter";
import { isNone, isSomething } from "$lib/helpers";
import { ensureNumber } from "$lib/numberHelpers";
import { GraphQLBaseViewModel } from "$pages/common";
import { ColumnFilterRefQueryForHistogram, ColumnFilterRefQueryForHistogramVariables, ElasticSearchPage } from "$typings/graphql";
import { customElement, bindable, computedFrom } from "aurelia-framework";
import { getLogger } from 'aurelia-logging';
import gql from 'graphql-tag';
import debounce from 'lodash.debounce';

@customElement('column-filter-range')
export class ColumnFilterRange extends GraphQLBaseViewModel<void, ColumnFilterRefQueryForHistogram, ColumnFilterRefQueryForHistogramVariables> {
    @bindable({ changeHandler: 'setVariables' }) filter: IFilterGroup | undefined;
    @bindable({ changeHandler: 'setVariables' }) changedFilter: Function;
    @bindable({ changeHandler: 'setVariables' }) page: ElasticSearchPage | undefined;
    @bindable({ changeHandler: 'setVariables' }) definition: IFilterDefinition;
    @bindable({ changeHandler: 'setVariables' }) activeFilters: IFilterGroup[] | undefined;
    @bindable({ changeHandler: 'setVariables' }) freeTextQuery: string[] | undefined;

    constructor(){
        super(getLogger(ColumnFilterRange.name))
        this.notifyChangedFilter = debounce(this.notifyChangedFilter, 500);
    }

    @computedFrom('filter')
    get filters(): INumberFilter[] {
        return !this.filter ? [] : this.filter.filters as INumberFilter[];
    }

    _pendingFrom: number | undefined;
    _pendingTo: number | undefined;
    @computedFrom('filters', '_pendingFrom', 'definition')
    get fromValue(){
        if(isSomething(this._pendingFrom)) return this._pendingFrom;

        const fromFilter = this.filters.find(f => f.operator === ConditionalOperator.GreaterThan || f.operator === ConditionalOperator.Between);
        if(fromFilter)
            return fromFilter.value;

        return this.definition.settings?.initialFrom || this.definition.settings?.min || 0;
    }

    @computedFrom('filters', '_pendingTo', 'definition')
    get toValue(){
        if(isSomething(this._pendingTo)) return this._pendingTo;

        const toFilter = this.filters.find(f => f.operator === ConditionalOperator.LesserThan || f.operator === ConditionalOperator.Between);
        if(toFilter)
            return toFilter.secondValue || toFilter.value;

        return this.definition.settings?.initialTo || this.definition.settings?.max || 100;
    }

    rangeChanged([from, to]: [number, number]){
        this._pendingTo = to;
        this._pendingFrom = from;
        this.notifyChangedFilter();
    }

    notifyChangedFilter() {
        const from = this._pendingFrom;
        const to = this._pendingTo;

        if(isNone(from) || isNone(to)) return;
        this._pendingFrom = undefined;
        this._pendingTo = undefined;
        
        const filters: INumberFilter[] = [];
        
        const settings = this.definition.settings;
        if(!settings)
            throw Error('Missing settings on filterdefinition');
        
        const min = settings.minMaxValuesRemoveFilter && settings.min === from ? undefined : from;
        const max = settings.minMaxValuesRemoveFilter && settings.max === to ? undefined : to;

        const bothMinAndMaxIsSet = isSomething(min) && isSomething(max);

        if(isSomething(min) && isSomething(max))
            filters.push({
                operator: ConditionalOperator.Between,
                symbol: '&&',
                value: min,
                secondValue: max
            });
        
        if(!bothMinAndMaxIsSet && isSomething(min)) {
            filters.push({
                operator: ConditionalOperator.GreaterThan,
                symbol: '>',
                value: min
            })
        }

        if(!bothMinAndMaxIsSet && isSomething(max)) {
            filters.push({
                operator: ConditionalOperator.LesserThan,
                symbol: '>',
                value: max
            })
        }

        const newfilter: IFilterGroup = {...(this.filter || { exclude: false, field: this.definition.property, type: 'number', filters: [] }), filters };

        this.changedFilter && this.changedFilter({ newfilter })
    }


    query = gql`
        query ColumnFilterRefQueryForHistogram($property: String!, $filters: String, $page: ElasticSearchPage!, $freeTextQuery: [String]) {
            refFilterValues(property: $property, filters: $filters, page: $page, freeTextQuery: $freeTextQuery){
                key
                label
                instances
            }
        }
    `

    bind(){
        this.setVariables();
    }

    setVariables() {
        const filters = this.activeFilters?.filter(f => f.field !== this.definition.property);
        if(
          this.variables &&
          this.variables.filters === JSON.stringify(filters) &&
          this.variables.property === this.definition.property &&
          this.variables.freeTextQuery === this.freeTextQuery
        ) {
          return;
        }
        
        if(!this.page)
            throw Error("Missing page variable in columnfilterrange!");

        this.variables = {
            property: this.definition.property,
            filters: filters ? JSON.stringify(filters) : undefined,
            freeTextQuery: this.freeTextQuery,
            page: this.page
        }
    }

    definitionChanged(){
        this.setVariables()
    }

    @computedFrom('definition')
    get min() {
        return (this.definition?.settings?.min as number) ?? 0;
    }

    @computedFrom('definition')
    get max() {
        return (this.definition?.settings?.max as number) ?? 100;
    }

    numBars = 20;

    stepIsInRange(index: number, from: number | string, to: number | string) {
        const perStep = this.max / this.numBars;
        const fromStep = ensureNumber(from) / perStep;
        const toStep = ensureNumber(to) / perStep;
        return index >= fromStep && index <= toStep;
    }

    @computedFrom('data')
    get histogram() {
        const bars = new Array(this.numBars).fill(0);
        if(!this.data) return bars;

        const stepSize = this.max / this.numBars;
        const instances = this.data.refFilterValues
            .map(a => ({ percent: ensureNumber(a.key), instances: a.instances || 0, step: Math.floor(ensureNumber(a.key) / (stepSize)) }))
            .filter(a => a.percent >= this.min && a.percent <= this.max);
        
        const sum = (arr: number[]) => arr.reduce((cur, next) => cur + next, 0);
        // Return a histogram inside the steps. The last index is inclusive on the upper bounds.
        const isLastIndex = (index: number, arr: any[]) => arr.length - 1 === index;
        const instancesInSteps = bars.map((_, i, ar) => sum(instances.filter(instance => instance.step === i || (isLastIndex(i, ar) && instance.step == i + 1) ).map(a => a.instances)));
        const max = Math.max(...instancesInSteps);

        return instancesInSteps.map(i => i / max * 100);
    }

}
