import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  insertFetchedEntities,
  insertInflightEntities,
  removeNoneFromArray
} from '../../utility';
import { IAsyncReducerState } from '../../interfaces';
import { IContact } from '../../interfaces/entity/icontact';
import { getContactsByIds } from '../../actions/user';
import { persistMultipleSites } from '../../actions';

export interface IContactReducerState extends IAsyncReducerState<IContact> {}

const defaultState: IContactReducerState = {
  allIds: undefined,
  byId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(getContactsByIds.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, r => r.contactId)
  }))

  .case(getContactsByIds.started, (state, params) => ({
    ...state,
    ...insertInflightEntities(state, params)
  }))

  .case(persistMultipleSites.done, (state, payload) => {
    const { contacts } = payload.params.pendingChanges;
    const mergedContacts = removeNoneFromArray([
      contacts.customer,
      contacts.order,
      contacts.sales,
      contacts.tech
    ]);

    return insertFetchedEntities(state, mergedContacts, c => c.contactId, true);
  });

export default reducer;
