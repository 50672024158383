import { IAsyncReducerState, IAlarmRecipient } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  insertFetchedEntity,
  setEntity,
  appendObjectToArray,
  removeObjectFromArray,
  removeAsyncObject,
  replaceObjectInArray
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createFetchedEntity,
  createPendingEntity
} from '../../types';
import {
  fetchingAlarmRecipients,
  addAlarmRecipient,
  removeAlarmRecipient,
  updateAlarmRecipient,
  updateAlarm
} from '../../actions';

export interface IAlarmRecipientsReducer
  extends IAsyncReducerState<IAlarmRecipient> {
  byAlarmId: IAsyncDictionary<number[]>;
}

const defaultState: IAlarmRecipientsReducer = {
  byId: {},
  allIds: undefined,
  byAlarmId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingAlarmRecipients.started, (state, params) =>
    mergeObjects(state, {
      byAlarmId: mergeObjects(state.byAlarmId, {
        [params.alarmId]: createPendingEntity()
      })
    })
  )

  .case(updateAlarm.done, (state, { result, params }) =>
    mergeObjects(
      state,

      insertFetchedEntities(
        state,
        result.recipientUsers,
        a => a.alarmRecipientId
      ),

      {
        byAlarmId: mergeObjects(state.byAlarmId, {
          [params.alarmId]: createFetchedEntity(
            result.recipientUsers.map(r => r.alarmRecipientId)
          )
        })
      }
    )
  )

  .case(fetchingAlarmRecipients.done, (state, { result, params }) =>
    mergeObjects(
      state,

      insertFetchedEntities(state, result, a => a.alarmRecipientId),

      {
        byAlarmId: mergeObjects(state.byAlarmId, {
          [params.alarmId]: createFetchedEntity(
            result.map(r => r.alarmRecipientId)
          )
        })
      }
    )
  )

  .case(addAlarmRecipient.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntity(
        state,
        payload.result.alarmRecipientId,
        payload.result
      ),
      {
        byAlarmId: mergeObjects(state.byAlarmId, {
          [payload.params.alarmId]: setEntity(
            state.byAlarmId[payload.params.alarmId],
            entity =>
              appendObjectToArray(entity, payload.result.alarmRecipientId)
          )
        })
      }
    )
  )

  .case(removeAlarmRecipient.done, (state, payload) =>
    mergeObjects(state, removeAsyncObject(state, payload.params.recipientId), {
      byAlarmId: mergeObjects(state.byAlarmId, {
        [payload.params.alarmId]: setEntity(
          state.byAlarmId[payload.params.alarmId],
          entity => removeObjectFromArray(entity, payload.params.recipientId)
        )
      })
    })
  )

  .case(updateAlarmRecipient.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntity(
        state,
        payload.result.alarmRecipientId,
        payload.result
      ),
      {
        byAlarmId: mergeObjects(state.byAlarmId, {
          [payload.params.alarmId]: setEntity(
            state.byAlarmId[payload.params.alarmId],
            entity =>
              replaceObjectInArray(
                entity,
                id => id === payload.result.alarmRecipientId,
                payload.result.alarmRecipientId
              )
          )
        })
      }
    )
  );

export default reducer;
