import { timeBetweenDateTranslation } from '../utility';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';

@autoinject
export class TimeUntillValueConverter {
  constructor(private i18n: I18N) {}

  toView(value: string | Date | undefined, prependCount: boolean = true) {
    const timeUntil = timeBetweenDateTranslation(false, value);
    if (!timeUntil) return;
    return (
      (prependCount ? timeUntil.count : '') +
      ' ' +
      this.i18n.tr(timeUntil.key, { count: timeUntil.count })
    );
  }
}
