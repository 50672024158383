import { json } from 'aurelia-fetch-client';
import endpoints from './endpoints';
import {
  IEquipment,
  ISiteDetailsMapAddressAndLocation,
  ISiteColumn,
  IResetPasswordPayload,
  IQrController,
  ILanguageText,
  ICustomerManager,
  ICustomersPayload,
  ITrendChannel,
  ITrendGroup,
  IFilterDTO,
  ICustomer,
  IBuid,
  ISite,
  IAlarm,
  IChannel,
  IAlarmRecipient,
  IUser,
  IUserSettings,
  IMultiSiteChannelEdit
} from '../interfaces';
import { isNone } from '../utility';
import { IDataProcessRequest } from '../models/trend';
import { IGraphExport } from '../interfaces/entity/iGraphExport';
import { IUserManagerListUser } from '../pages/usermanager/modules/usermanagerlist/usermanagerlist';
import {
  IUserAccess,
  IUserAccessDescriptionRequest
} from '../pages/usermanager/usermanagerReducer';
import { ImportJob } from '../models';
import { IMultiEditDTO } from '../interfaces/entity/iMultiEdit';
import { ProductEditorDetailsQuery_product } from '../../custom_typings/graphql';

const createApiPostRequest = (url: string, body?: any) =>
  new Request(endpoints.api + url, {
    method: 'POST',
    body: json(body),
    headers: [ ['content-type', 'application/json'], ['X-CSRF', '1']]
  });
const createApiPutRequest = (url: string, body?: any) =>
  new Request(endpoints.api + url, {
    method: 'PUT',
    body: json(body),
    headers: [ ['content-type', 'application/json'], ['X-CSRF', '1']]
  });
const createApiDeleteRequest = (url: string, body?: any) =>
  new Request(endpoints.api + url, {
    method: 'DELETE',
    body: json(body),
    headers: [ ['content-type', 'application/json'], ['X-CSRF', '1']]
  });
const createApiDeleteRequestWithBody = (url: string, body?: any) =>
  new Request(endpoints.api + url, {
    method: 'DELETE',
    body: json(body),
    headers: [ ['content-type', 'application/json'], ['X-CSRF', '1']]
  });
const createApiGetRequest = (url: string) =>
  new Request(endpoints.api + url, { method: 'GET', headers: [ ['content-type', 'application/json'], ['X-CSRF', '1']] });
//const createApiHeadRequest = (url: string) =>
//  new Request(endpoints.api + url, { method: "HEAD" });

const common = {
  consentStatus: () => createApiGetRequest(`/consent`),
  consentAccept: () => createApiPostRequest(`/consent`),

  getAllHauliers: () => createApiGetRequest('/haulier/getall'),
  getAllAllowedHauliers: () => createApiGetRequest('/haulier/getallallowed'),

  updateSiteEquipment: (siteId: number, equipment: IEquipment) =>
    createApiPutRequest(
      `/siteEquipment/${siteId}/${equipment.equipmentId}`,
      equipment
    ),
  deleteSiteEquipment: (siteId: number, equipmentId: number) =>
    createApiDeleteRequest(`/siteEquipment/${siteId}/${equipmentId}`),
  addSiteEquipment: (siteId: number, equipment: IEquipment) =>
    createApiPostRequest(`/siteEquipment/${siteId}`, equipment),
  getSiteEquipment: (siteId: number) =>
    createApiGetRequest(`/siteEquipment/${siteId}`),
  getSiteExportedChannels: (siteId: number) =>
    createApiGetRequest(`/siteExport/${siteId}`),
  getSiteChannelExportedChannels: (siteChannelId: number) =>
    createApiGetRequest(`/siteExport/siteChannel/${siteChannelId}`),

  downloadSiteEquipmentAttachmentBlob: (
    equipmentId: number,
    attachmentId: number
  ) =>
    createApiGetRequest(
      `/SiteEquipmentAttachment/${equipmentId}/${attachmentId}`
    ),
  downloadControllerBackup: (serial: string) => createApiPostRequest(`/controllers/dobackup`, serial),
  downloadBlob: (blobId: number) => createApiGetRequest(`/blob/${blobId}`),
  updateSiteAddressAndMarkers: (details: ISiteDetailsMapAddressAndLocation) =>
    createApiPutRequest(`/siteDetailsMap/${details.siteId}`, details),
  fetchControllerPosition: (controllerId: number) =>
    createApiGetRequest(`/siteDetailsMap/controllerPositition/${controllerId}`),
  fetchAdressesFromSiteId: (siteId: number) =>
    createApiGetRequest(`/siteDetailsMap/siteAddresses/${siteId}`),
  updateSiteGalleryItemInformation: (galleryitemId: number, tags: string[]) =>
    createApiPutRequest(`/siteGalleryItem/${galleryitemId}`, tags),
  uploadGalleryItem: (siteId: number, blobId: number) =>
    createApiPostRequest(`/siteGalleryItem/${siteId}/${blobId}`),
  deleteGalleryItem: (itemId: number) =>
    createApiDeleteRequest(`/siteGalleryItem/${itemId}`),
  getAllAlarms: () => createApiGetRequest(`/sites/alarms`),

  saveAlarm: (siteId: number, alarm: IAlarm, recipients: IAlarmRecipient[]) =>
    createApiPutRequest(`/sites/${siteId}/alarms`, { alarm, recipients }),

  updateAlarm: (siteId: number, alarm: IAlarm, recipients: IAlarmRecipient[]) =>
    createApiPostRequest(`/sites/${siteId}/alarms/${alarm.alarmId}`, {
      alarm,
      recipients
    }),

  deleteAlarm: (siteId: number, alarm: IAlarm) =>
    createApiDeleteRequest(`/sites/${siteId}/alarms/${alarm.alarmId}`),
  acknowledgeAlarm: (siteId: number, alarmId: number) =>
    createApiPostRequest(`/sites/${siteId}/alarms/${alarmId}/acknowledge`),  
  getAlarmsBySiteId: (siteId: number) =>
    createApiGetRequest(`/sites/${siteId}/alarms`),
  getAllActiveCustomAlarms: () =>
    createApiGetRequest(`/sites/alarms/customAndActive`),
  getAlarmRecipients: (siteId: number, alarmId: number) =>
    createApiGetRequest(`/sites/${siteId}/alarms/${alarmId}/recipients`),
  searchForAlarmRecipients: (siteId: number, alarmId: number, query: string) =>
    createApiPostRequest(
      `/sites/${siteId}/alarms/${alarmId}/recipients/searchuser`,
      query
    ),
  removeAlarmRecipient: (
    siteId: number,
    alarmId: number,
    recipientId: number
  ) =>
    createApiDeleteRequest(
      `/sites/${siteId}/alarms/${alarmId}/recipients/${recipientId}`
    ),
  updateAlarmRecipient: (
    siteId: number,
    alarmId: number,
    recipient: IAlarmRecipient
  ) =>
    createApiPostRequest(
      `/sites/${siteId}/alarms/${alarmId}/recipients/${recipient.alarmRecipientId}`,
      recipient
    ),
  testAlarmRecipient: (
    siteId: number,
    alarmId: number,
    recipient: IAlarmRecipient
  ) =>
    createApiPostRequest(
      `/sites/${siteId}/alarms/${alarmId}/recipients/${recipient.alarmRecipientId}/test`
    ),
  addAlarmRecipient: (
    siteId: number,
    alarmId: number,
    externalEmail?: string,
    userId?: number
  ) =>
    !isNone(externalEmail) && isNone(userId)
      ? createApiPostRequest(`/sites/${siteId}/alarms/${alarmId}/recipients`, {
          externalEmail
        })
      : createApiPostRequest(`/sites/${siteId}/alarms/${alarmId}/recipients`, {
          userId
        }),

  alarmLogs: (
    siteId: number,
    alarmId: number,
    fetchAll: boolean,
    afterId: number | undefined
  ) =>
    fetchAll
      ? createApiGetRequest(`/sites/${siteId}/alarms/${alarmId}/logs`)
      : createApiGetRequest(
          `/sites/${siteId}/alarms/${alarmId}/logs/10/${afterId || ''}`
        ),

  getAllControllers: () => createApiGetRequest(`/controllers/`),
  getAllRemoteControllers: () => createApiGetRequest(`/controllers/remote`),
  getController: (id: number) => createApiGetRequest(`/controllers/${id}`),
  getControllers: (ids: number[]) =>
    createApiPostRequest(`/controllers/multiple/`, ids),
getUnassignedControllers: createApiGetRequest(`/controllers/unassigned`),
  unassignedControllerCreateSite: (
    controllerIds: number[],
    buid: IBuid,
    customer: ICustomer
  ) =>
    createApiPostRequest(
      `/controllers/createSite/${buid.buidId}/${customer.customerId}`,
      controllerIds
    ),

  unassignedControllerAttachSite: (controllerIds: number[], siteId: number) =>
    createApiPostRequest(`/controllers/attach/${siteId}`, controllerIds),
  detachControllers: (controllerIds: number[]) =>
    createApiPostRequest(`/controllers/detach`, controllerIds),
  /*getRemoteControllUrlForController: (controllerId: number, port: number) =>
    createApiGetRequest(`/controllerproxy/${controllerId}/${port}`),*/
  getUsersWithAccessToController: (
    controllerId: number,
    pendingBuidId: number | undefined
  ) =>
    createApiPostRequest(`/controllers/${controllerId}/access`, {
      buidId: pendingBuidId
    }),
  getControllersBySiteId: (siteId: number) =>
    createApiGetRequest(`/sites/${siteId}/controllers`),

  getAllUnits: () => createApiGetRequest(`/units`),

  getAllLoadingPoints: () => createApiGetRequest(`/loadingpoints`),

  getColumns: () => createApiGetRequest('/columns'),
  updateColumns: (columns: ISiteColumn[]) =>
    createApiPutRequest(`/columns/`, columns),

  getQrControllerBySerial: (serial: string) =>
    createApiGetRequest(`/qrapp/controller/${serial}`),
  controllerCommand: (
    controllerId: number,
    slaveNumber: number,
    body: string
  ) =>
    createApiPostRequest(`/controllercommands/${controllerId}/${slaveNumber}`, {
      command: body
    }),

  updateQrController: (controller: IQrController) =>
    createApiPostRequest(`/qrapp/controller/${controller.serial}`, controller),
  createQrController: (controller: IQrController) =>
    createApiPostRequest(`/qrapp/controller`, controller),

  getControllerParameters: (controllerId: number, fromController: boolean) =>
    createApiGetRequest(
      `/controllerparameters/${controllerId}/${fromController}`
    ),

  getControllerProfiles: (controllerId: number, fromController: boolean) =>
    createApiGetRequest(
      `/controllerprofiles/${controllerId}&refresh=${fromController}`
    ),

  getControllerProfileHistory: (controllerId: number, profileId: number) =>
    createApiGetRequest(
      `/controllerprofiles/${controllerId}/${profileId}/history`
    ),

  /*persistControllerProfiles: (
    controllerId: number,
    profiles: IControllerProfile[]
  ) => createApiPutRequest(`/controllerprofiles/${controllerId}`, profiles),*/

  getControllerProfileValues: (
    controllerId: number,
    profileId: number,
    profileHistoryId?: number
  ) =>
    createApiGetRequest(
      `/controllerprofiles/${controllerId}/${profileId}/${profileHistoryId ||
        ''}`
    ),

  postControllerProfileValues: (
    controllerId: number,
    profileId: number,
    body: number[]
  ) =>
    createApiPutRequest(
      `/controllerprofiles/${controllerId}/${profileId}`,
      body
    ),

  controllerLogs: (
    controllerId: number,
    fetchAll: boolean,
    afterId: number | undefined
  ) =>
    fetchAll
      ? createApiGetRequest(`/controllerlog/${controllerId}`)
      : createApiGetRequest(
          `/controllerlog/${controllerId}/10/${afterId || ''}`
        ),

  controllerTypes: createApiGetRequest(`/controllertypes`),

  simCardStatus: (controllerId: number) =>
    createApiGetRequest(`/simcard/status/${controllerId}`),
  simCardActivation: (
    controllerId: number,
    buidId: number,
    icc: string,
    simOnly: boolean
  ) =>
    createApiPostRequest(`/simcard/activation/`, {
      buidId,
      icc,
      controllerId,
      simOnly
    }),

  bindSimCardToController: (msisdn: string) =>
    createApiPostRequest('/simmanagement/bind-msisdn', msisdn),

  sendSimCardAction: (
    icc: string,
    msisdn: string,
    ipAddress: string,
    simCardType: string,
    buidName: string,
    action: string
  ) =>
    createApiPostRequest('/simmanagement/sim-details/action', {
      icc,
      msisdn,
      ipaddress: ipAddress,
      simCardType,
      buidName,
      action
    }),

  getAllCustomers: () => createApiGetRequest('/customer/getall'),  
  getCustomerByToken: (token: string) =>
    createApiPostRequest(`/customer/token/`, token),
  getCustomerById: (id: number) =>
    createApiGetRequest(`/customer/${id}`),
  getCustomersByTokens: (tokens: string[]) =>
    createApiPostRequest(`/customer/tokens/`, tokens),
  getAllAllowedCustomers: () => createApiGetRequest('/customer/getallallowed'),
  getAllCustomermanagers: () => createApiGetRequest('/customermanager/getall'),
  sapLookup: (soldTo : string) => createApiGetRequest(`/customermanager/saplookup/${soldTo}`),
  getCustomermanagersByIds: (ids: number[]) =>
    createApiPostRequest('/customermanager/getByIds', ids),
  getCustomermanagersById: (id: number) =>
    createApiGetRequest(`/customermanager/${id}`),
  updateCustomer: (customerId: number, customer: Partial<ICustomerManager>) =>
    createApiPutRequest(`/customermanager/${customerId}`, customer),
  updateCustomers: (customers: ICustomersPayload) =>
    createApiPutRequest(`/customermanager/updateCustomers/`, customers),
  addCustomerManager: (customer: Partial<ICustomerManager>) =>
    createApiPostRequest(`/customermanager`, customer),
  deleteCustomers: (customers: number[]) =>
    createApiDeleteRequestWithBody(`/customer/deletecustomers`, customers),
  getUsersWithDefaultCustomerId: (customerId: number) =>
    createApiGetRequest(`/customermanager/defaultusers/${customerId}`),
  getUsersWithAccessToCustomerId: (
    customerId?: number,
    pendingCustomerName?: string,
    pendingBuidId?: number
  ) =>
    createApiGetRequest(
      `/customermanager/accessusers?customerId=${customerId || ''}${
        !isNone(pendingCustomerName)
          ? '&customerName=' + pendingCustomerName
          : ''
      }${!isNone(pendingBuidId) ? '&buidId=' + pendingBuidId : ''}`
    ),

  getUserAccessDescriptionForUser: (
    userId: number,
    pendingInfo: IUserAccessDescriptionRequest
  ) =>
    createApiPutRequest(
      `/usermanager/user-access/${userId}/description`,
      pendingInfo
    ),
  getUserSiteAccessForUser: (
    userId: number,
    pendingInfo: IUserAccessDescriptionRequest
  ) =>
    createApiPutRequest(
      `/usermanager/user-access/${userId}/sites`,
      pendingInfo
    ),
  getUserCustomerAccessForUser: (
    userId: number,
    pendingInfo: IUserAccessDescriptionRequest
  ) =>
    createApiPutRequest(
      `/usermanager/user-access/${userId}/customers`,
      pendingInfo
    ),
  getUserAlarmConnectionsForUser: (
    userId: number,
    pendingInfo: IUserAccessDescriptionRequest
  ) =>
    createApiPutRequest(
      `/usermanager/user-access/${userId}/alarms`,
      pendingInfo
    ),
  getUserAccessForUser: (userId: number) =>
    createApiGetRequest(`/usermanager/user-access/${userId}`),
  saveUserAccessForUser: (userId: number, access: IUserAccess) =>
    createApiPutRequest(`/usermanager/user-access/${userId}`, access),
  getAllUserManagers: () => createApiGetRequest('/usermanager/getall'),
  getUserForUserManager: (userId: number) =>
    createApiGetRequest(`/usermanager/getUser/${userId}`),
  getRoleTemplates: () => createApiGetRequest('/usermanager/roletemplates'),

  updateUserInfo: (userInfo: IUserManagerListUser) =>
    createApiPutRequest(`/usermanager/updateUserInfo/${userInfo.userId}`, userInfo),
  addUserInfo: (userInfo: IUserManagerListUser) =>
    createApiPostRequest(`/usermanager/addUserInfo/`, userInfo),
  updateColumnsByUserId: (columns: ISiteColumn[], userId: number) =>
    createApiPutRequest(`/usermanager/${userId}/columns`, columns),
  getColumnsByUserId: (userId: number) =>
    createApiGetRequest(`/usermanager/${userId}/columns`),
  updateColumnsByMultipleUserIds: (columns: ISiteColumn[], userIds: number[]) =>
    createApiPutRequest(`/usermanager/users/columns/`, { columns, userIds }),

  deleteUsers: (userIds: number[]) =>
    createApiDeleteRequestWithBody(`/user/deleteusers`, userIds),
  getUser: (token: string) =>
    createApiGetRequest(`/user/token/${encodeURIComponent(token)}`),
  getUsersByIds: (tokens: string[]) =>
    createApiPostRequest(`/user/tokens`, tokens),
  getContactsByIds: (tokens: string[]) =>
    createApiPostRequest(`/contacts/tokens`, tokens),
  getAllBuids: createApiGetRequest('/buid/getall'),
  getAllAllowedBuids: () => createApiGetRequest('/buid/getallallowed'),
  getAllBuidAndCustomerFilters: () =>
    createApiGetRequest('/usermanager/GetAllBuidAndCustomerFilters'),
  getCurrentUser: () => createApiGetRequest(`/user/current`),
  updateCurrentUser: (user: IUser) =>
    createApiPostRequest(`/user/current`, user),

  changeAlarmSubscription: (alarmType: number, activate: boolean) =>
    createApiPostRequest(`/user/current/subscriptions`, {
      alarmType,
      activate
    }),
  changePassword: (password: string) =>
    createApiPostRequest(`/user/current/password`, password),
  updateUserSettings: (userSettings: IUserSettings) =>
    createApiPostRequest(`/user/current/settings`, userSettings),

  updateSettingDismissDashboardWelcome: () =>
    createApiPostRequest(`/user/current/settings/dashboard-welcome`),

  getAllVehicles: createApiGetRequest(`/vehicles/`),

  getAllSiteChannels: () => createApiGetRequest(`/siteChannels`),
  getAllChannelsOfTypeTank: () => createApiGetRequest(`/siteChannels/tanks`),
  getChannelByChannelToken: (token: string) =>
    createApiGetRequest(`/channels/getChannelByChannelToken/` + token),
  getChannelsThatTrackChannel: (
    channelId: number,
    siteChannelId: number | undefined
  ) =>
    createApiGetRequest(
      `/channels/getChannelsThatTrackChannel/${channelId}/${siteChannelId}`
    ),
  getSiteChannelsForSite: (siteId: number) =>
    createApiGetRequest(`/sites/${siteId}/sitechannels/`),
  getSiteAccessForSiteChannelsOnSite: (siteId: number) =>
    createApiGetRequest(`/sites/${siteId}/siteChannels/controller/site/access`),
  getSiteChannelBySiteChannelId: (siteChannelId: number) =>
    createApiGetRequest(`/siteChannels/${siteChannelId}`),

  deleteSiteChannel: (siteChannelId: number) =>
    createApiDeleteRequest(`/siteChannels/${siteChannelId}`),
  updateSiteChannel: (siteChannelId: number, siteChannel: IChannel) =>
    createApiPutRequest(`/siteChannels/${siteChannelId}`, siteChannel),
  createSiteChannel: (siteId: number, channelId: number) =>
    createApiPostRequest(`/siteChannels/${channelId}/${siteId}`),
  createSiteChannelAndChannel: (channel: IChannel) =>
    createApiPostRequest(`/siteChannels/`, channel),

  updateSiteChannels: (siteChannels: IMultiSiteChannelEdit) =>
    createApiPostRequest(`/siteChannels/multiple`, siteChannels),
  updateSiteChannelsOrder: (siteChannelIds: number[]) =>
    createApiPostRequest('/siteChannels/order', siteChannelIds),

  createChannel: (channel: IChannel) =>
    createApiPostRequest(`/channels`, channel),
  getChannelHistoryBySiteChannelId: (siteChannelId: number) =>
    createApiGetRequest(`/historian/siteChannel/${siteChannelId}`),
  getChannelHistoriesBySiteChannelIds: (siteChannelIds: number[]) =>
    createApiPostRequest(`/historian/siteChannel/`, siteChannelIds),
  getChannelHistoryForSiteChannel: (
    siteChannelId: number,
    from: Date,
    to: Date
  ) =>
    createApiGetRequest(
      `/historian/siteChannel/${siteChannelId}/${from.toJSON()}/${to.toJSON()}`
    ),
  getChannelsByChannelId: (channelId: number) =>
    createApiGetRequest(`/channels/${channelId}`),
  getChannelsByControllerId: (controllerId: number) =>
    createApiGetRequest(`/controllers/${controllerId}/channels`),
  deleteChannelById: (channelId: number) =>
    createApiDeleteRequest(`/channels/${channelId}`),
  updateChannel: (channel: IChannel) =>
    createApiPutRequest(`/channels/${channel.channelId}`, channel),

  getLanguages: () => createApiGetRequest('/language/getlanguages'),

  sendInvite: (userId: number, languageId: number) =>
    createApiGetRequest(`/account/sendinvite/${userId}/${languageId}`),
  forgotPassword: (email: string) =>
    createApiGetRequest(`/account/request/${email}`),
  activateAccount: (param: IResetPasswordPayload) =>
    createApiPostRequest(`/account/activateAccount`, param),
  activateAccountState: (username: string, nonce: string) =>
    createApiGetRequest(`/account/activateAccountState/${username}/${nonce}`),
  requestNewAccountActivation: (username: string, nonce: string) =>
    createApiPostRequest(`/account/requestNewAccountActivation/${username}/${nonce}`),
  resetAccount: (param: IResetPasswordPayload) =>
    createApiPostRequest(`/account/reset`, param),

  getAllLanguages: (lngCode?: string) =>
    createApiGetRequest(`/translations/all/${lngCode || ''}`),
  getLanguageKeys: () => createApiGetRequest(`/translations/keys`),
  createOrUpdateLanguageText: (texts: ILanguageText[]) =>
    createApiPostRequest(`/translations/`, texts),
  getMissingTranslations: (missingKeys: string[], language: string) =>
    createApiPostRequest('/translations/i18/missing', {
      missingKeys,
      language
    }),

  getTankUsage: (tankId: number) =>
    createApiGetRequest(`/tanks/usage/${tankId}`),
  getTankHistory: (siteId: number, tankId: number, from: Date, to: Date) =>
    createApiGetRequest(
      `/tanks/history/${siteId}/${tankId}/${from.toJSON()}/${to.toJSON()}`
    ),
  getSiteTankHistory: (siteId: number) =>
    createApiGetRequest(`/tanks/history/${siteId}`),

  //TODO: Remove this
  orderSiteTankRefill: (
    siteId: number,
    tankId: number,
    contactIds: number[],
    language: string
  ) =>
    createApiPostRequest(`/tanks/orderrefill/`, {
      siteId,
      tankId,
      language,
      contactIds
    }),

  updateTankRefillPoint: (
    siteId: number,
    tankChannelId: number,
    timestamp: string,
    value: number
  ) =>
    createApiPostRequest(`/tanks/updaterefill`, {
      siteId,
      tankChannelId,
      timestamp,
      value
    }),

  getShipTosMatchingSoldTo: (soldTo: string) =>
    createApiGetRequest(`/tanks/shiptos/${soldTo}`),

  getDeviceTypeReport: () =>
    createApiGetRequest(`/report/getdevicetypesperbuid`),
  getRefillsForPeriod: (fromDate: Date, toDate: Date, siteId: number) =>
    createApiGetRequest(
      `/report/getrefillsforperiod/${fromDate.toJSON()}/${toDate.toJSON()}/${siteId}`
    ),

  getAllControllerTypes: () =>
    createApiGetRequest(`/report/getcontrollertypes`),
  getAllSiteFilters: () => createApiGetRequest(`/report/getallsitefilters`),

  getSites: createApiGetRequest(`/sites/`),
  getSiteById: (siteId: number) => createApiGetRequest(`/sites/${siteId}`),
  getSitesByIds: (siteIds: number[]) =>
    createApiPostRequest(`/sites/`, { ids: siteIds }),

  updateSite: (site: ISite) =>
    createApiPutRequest(`/sites/${site.siteId}`, site),

  updateSites: (siteIds: number[], payload: IMultiEditDTO) =>
    createApiPutRequest(`/sites/multiple/update`, { siteIds, ...payload }),
  deleteSites: (siteIds: number[], deleteController: boolean, alsoSuspendSimCards: boolean) =>
    createApiPostRequest(`/sites/multiple/delete`, {
      siteIds,
      deleteController,
      alsoSuspendSimCards: alsoSuspendSimCards
    }),

  getControllersDeleteDialogInfo: (controllerIds: number[]) =>
    createApiPostRequest(`/controllers/deletedialoginfo`, {
      controllerIds: controllerIds
    }),

  getSitesDeleteDialogInfo: (siteIds: number[]) =>
    createApiPostRequest(`/sites/deletedialoginfo`, {
      siteIds
    }),

  findContactForSites: (siteIds: number[], query: string) =>
    createApiPostRequest(`/sitecontact/FindContactForSites`, {
      siteIds,
      query
    }),

  deleteSite: (siteId: number, deleteControllers: boolean, alsoSuspendSimCards: boolean) =>
    createApiDeleteRequest(`/sites/${siteId}/${deleteControllers}/${alsoSuspendSimCards}`),

  getProducts: () => createApiGetRequest(`/products`),
  updateProduct: (product: ProductEditorDetailsQuery_product) =>
    createApiPostRequest(`/products/`, product),

  pinAllSites: () => createApiPostRequest(`/pinnedsites/pinall`),
  unpinAllSites: () => createApiDeleteRequest(`/pinnedsites/unpinall`),
  getPinnedSiteIds: () => createApiGetRequest(`/pinnedsites/ids`),
  getPinnedSites: () => createApiGetRequest(`/pinnedsites`),
  addPinnedSite: (siteId: number) =>
    createApiPostRequest(`/pinnedsites`, siteId),
  removePinnedSite: (siteId: number) =>
    createApiDeleteRequest(`/pinnedsites/${siteId}`),

  graphExport: (siteId: number, definition: IGraphExport) =>
    createApiPostRequest(`/sites/${siteId}/export/trend/`, definition),
  addUserTrendChannel: (
    trendGroupId: number,
    userTrendChannel: Partial<ITrendChannel>
  ) =>
    createApiPostRequest(
      `/usertrendchannels/${trendGroupId}`,
      userTrendChannel
    ),
  updateUserTrendChannels: (userTrendChannels: ITrendChannel[]) =>
    createApiPutRequest(`/usertrendchannels/multiple/`, userTrendChannels),

  removeUserTrendChannel: (trendChannels: ITrendChannel[]) =>
    createApiDeleteRequest(
      `/usertrendchannels/`,
      trendChannels.map(t => t.trendKey)
    ),

  deleteUserTrendChannel: (trendChannels: ITrendChannel[]) =>
    createApiPostRequest(
      `/usertrendchannels/delete`,
      trendChannels.map(t => t.trendKey)
    ),

  getUserTrendGroups: (siteId: number) =>
    createApiGetRequest(`/usertrendgroups/${siteId}`),

  setActiveTrendGroup: (siteId: number, trendGroupId: number) =>
    createApiPutRequest(`/usertrendgroups/setActive/${siteId}/${trendGroupId}`),

  addUserTrendGroup: (siteId: number, userTrendGroup: ITrendGroup) =>
    createApiPostRequest(`/usertrendgroups/${siteId}`, userTrendGroup),

  removeUserTrendGroup: (siteId: number, userTrendGroupId: number) =>
    createApiDeleteRequest(`/usertrendgroups/${siteId}/${userTrendGroupId}`),

  getFilters: () => createApiGetRequest(`/filters`),
  addFilter: (payload: IFilterDTO) => createApiPostRequest(`/filters`, payload),

  updateFilter: (payload: IFilterDTO) =>
    createApiPostRequest(`/filters/${payload.filterId}`, payload),
  removeFilter: (payload: IFilterDTO) =>
    createApiDeleteRequest(`/filters/${payload.filterId}`),

  dataProcessed: (utcFrom: Date, utcTo: Date, payload: IDataProcessRequest) =>
    createApiPostRequest(
      `/historian/dataprocessed/${utcFrom.toJSON()}/${utcTo.toJSON()}`,
      payload
    ),

  userAlarmLogs: (siteId: number, payload: any) =>
    createApiPostRequest(`/sites/${siteId}/alarms/logs/between`, payload),

  notesForSite: (siteId: number) =>
    createApiGetRequest(`/sites/${siteId}/notes`),
  allDisplayNotes: () => createApiGetRequest(`/notes/displayNote=true`),

  staalduinenExport: (order: any) =>
    createApiPostRequest(`/order/staalduinen`, order),

  exportJobs: () => createApiGetRequest(`/exportjobs/`),
  exportJobNames: () => createApiGetRequest(`/exportjobs/names`),

  exportJobLatestRuns: (exportJobId: number, amount: number) =>
    createApiGetRequest(
      `/exportjobruns/latest/${exportJobId}?amount=${amount}`
    ),

  importJobs: () => createApiGetRequest('/importjobs'),
  importJobTypes: () => createApiGetRequest('/importjobs/types'),

  addImportJob: (job: ImportJob) => createApiPostRequest(`/importjobs`, job),
  updateImportJob: (job: ImportJob) => createApiPutRequest(`/importjobs`, job),

  importJobLatestRuns: (importJobId: number, amount: number) =>
    createApiGetRequest(
      `/importjobruns/latest/${importJobId}?amount=${amount}`
    ),

  runReportScheduleNow: (scheduleId: number) =>
    createApiPostRequest(
      `/graphql/scheduledReports/runReportScheduleNow/${scheduleId}`
    ),

  sendPdiMasterData: (siteId: number) =>
    createApiPostRequest(`/masterdataexport/pdi/${siteId}`

    )
};

export default common;
