import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { getAllSiteFilters, getRefillsForPeriodReport } from '../actions';

@autoinject()
export class ReportService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ReportService.name), taskQueue);
  }

  getAllSiteFilters = async () =>
    this.httpRequestWithDispatch(
      requests.getAllSiteFilters(),
      getAllSiteFilters,
      {},
      'Could not get all sites'
    );

  getRefillsForPeriod = async (fromDate: Date, toDate: Date, siteId: number) =>
    this.httpRequestWithDispatch(
      requests.getRefillsForPeriod(fromDate, toDate, siteId),
      getRefillsForPeriodReport,
      {},
      'An error occurred while trying to fetch refills for period report'
    );

  runReportScheduleNow = (
    scheduleId: number
  ) => 
    this.httpRequest<string>(
      requests.runReportScheduleNow(scheduleId));

}
