export class rangeValueConverter {
  toView(array: Array<{}>, from: number, to: number | undefined) {
    if (!array) {
      return array;
    }

    let indexFrom = from - 1;

    let sliced = array.slice(indexFrom, to);

    return sliced;
  }
}
