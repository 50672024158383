import {
  tankUsageFilterChanged,
  navigateToRoute,
  cancelTankInformationForEdit,
  editSiteChannelTankDetailsProperty,
  fetchingTankHistory,
  toggleCustomHistoryFilter,
  tankHistoryDateRangeChanged,
  markTankInformationForEdit,
  editSiteChannelProperty,
  updateSiteChannel,
  toggleRefillPointEditing,
  updateTankRefillPoint
} from '../../actions';
import {
  TankUsageDuration,
  IRequestStateWrapper,
  TankHistoryFilter,
  IDateRange,
  ISiteChannel,
  ILocalizedDateRange
} from '../../interfaces';
import { mergeObjects } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IPoint } from '../../models';

export interface ISiteDetailsTanksReducer {
  tankInformationToModify?: ISiteChannel;
  usageFilter: TankUsageDuration;
  tankHistoryFilter: TankHistoryFilter;
  tankHistory?: IRequestStateWrapper<
    IPoint[],
    { tankId: number; dates: ILocalizedDateRange }
  >;
  showCustomHistoryDropdown: boolean;
  tankHistoryCustomDateRange?: IDateRange;
  editRefillPointEnabled: boolean;
  tankChannelValidationErrors: Record<string, string[]> | string | undefined;
}

const defaultState: ISiteDetailsTanksReducer = {
  showCustomHistoryDropdown: false,
  usageFilter: TankUsageDuration.LastRefill,
  tankHistoryFilter: TankHistoryFilter.min,
  editRefillPointEnabled: false,
  tankChannelValidationErrors: undefined
};

const reducer = reducerWithInitialState(defaultState)
  .case(tankUsageFilterChanged, (state, usageFilter) =>
    mergeObjects(state, { usageFilter })
  )

  .case(markTankInformationForEdit, (state, tankInformationToModify) =>
    mergeObjects(state, { tankInformationToModify })
  )

  .case(cancelTankInformationForEdit, state =>
    mergeObjects(state, {
      tankInformationToModify: undefined,
      tankChannelValidationErrors: undefined
    })
  )
  .case(toggleCustomHistoryFilter, state =>
    mergeObjects(state, {
      showCustomHistoryDropdown: !state.showCustomHistoryDropdown
    })
  )

  .case(navigateToRoute, state =>
    mergeObjects(state, {
      tankInformationToModify: undefined,
      editRefillPointEnabled: false
    })
  )

  .case(updateSiteChannel.done, state =>
    mergeObjects(state, {
      tankInformationToModify: undefined,
      tankChannelValidationErrors: undefined
    })
  )

  .case(updateSiteChannel.failed, (state, { error }) => ({
    ...state,
    tankChannelValidationErrors: error
  }))

  .case(editSiteChannelTankDetailsProperty, (state, payload) =>
    mergeObjects(state, {
      tankInformationToModify: mergeObjects(state.tankInformationToModify, {
        tankDetails: mergeObjects(state.tankInformationToModify!.tankDetails, {
          [payload.property]: payload.value
        })
      })
    })
  )

  .case(editSiteChannelProperty, (state, payload) =>
    mergeObjects(
      state,
      {
        tankInformationToModify: mergeObjects(state.tankInformationToModify, {
          [payload.property]: payload.value
        })
      },
      {
        tankChannelValidationErrors: undefined
      }
    )
  )
  .case(fetchingTankHistory.started, (state, params) =>
    !params.fromTankGraph
      ? state
      : mergeObjects(state, {
          tankHistory: {
            type: 'processing',
            processing: true,
            query: {
              tankId: params.tankId,
              dates: params.localizedDateRange
              // from: params.localizedDateRange.from,
              // to: params.localizedDateRange.to
            }
          },
          tankHistoryFilter: params.filter,
          showCustomHistoryDropdown: false
        })
  )

  .case(fetchingTankHistory.done, (state, payload) =>
    !payload.params.fromTankGraph
      ? state
      : mergeObjects(state, {
          tankHistory: {
            type: 'done',
            entity: payload.result,
            query: {
              tankId: payload.params.tankId,
              dates: payload.params.localizedDateRange
            },
            loaded: true
          }
        })
  )

  .case(tankHistoryDateRangeChanged, (state, payload) =>
    mergeObjects(state, { tankHistoryCustomDateRange: payload })
  )
  .case(toggleRefillPointEditing, (state, payload) =>
    mergeObjects(state, { editRefillPointEnabled: payload })
  )
  .case(updateTankRefillPoint.done, state =>
    mergeObjects(state, { editRefillPointEnabled: false })
  )
  .case(updateTankRefillPoint.failed, state =>
    mergeObjects(state, { editRefillPointEnabled: false })
  );

export default reducer;
