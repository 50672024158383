import { ensureNumber } from '../utility';

export class EntriesValueConverter {
  toView(object: Record<string, any>, parseKeyAsInt = false) {
    const entries = Object.entries(object);
    return !parseKeyAsInt
      ? entries
      : entries.map(([key, value]) => [ensureNumber(key), value]);
  }
}
