import { isEmpty } from 'lodash';
import { isSomething } from './helpers';
import { ensureNumber } from './numberHelpers';

export const literToTonnes = (level: number, density: number) => {
  return level * density;
};

export const cubicMetreToTonnes = (level: number, density: number) => {
  return level * 1000 * density;
};

export const convertByUnit = (
  value?: number,
  density?: number | null,
  unit?: string | null
) => {
  if (!value || !density || !unit) return 0;

  switch (unit.toLowerCase()) {
    case 'ltr':
    case 'l':
      return literToTonnes(value, density);
    case 'm³':
      return cubicMetreToTonnes(value, density);
    case 'tonne':
      return value;
    default:
      return 0;
  }
};

export interface IMeasurementUnit {
  symbol: string | undefined | null
  decimals: number | undefined | null
}

const addUnitSymbol = (withUnit: boolean, unit: IMeasurementUnit | undefined | null, unitSymbolOnNoValue: boolean) => (
  level: string | undefined | null
): string => {
  if(!unitSymbolOnNoValue && !isSomething(level))
    return '';
  if(!unit || !withUnit)
    return isSomething(level) ? level : '';
  return `${isSomething(level) ? level + ' ' : ''}${unit.symbol}`;
}

export const displayMeasurement = (
  level: number | undefined | null,
  unit: IMeasurementUnit | undefined | null,
  withUnitSymbol = false,
  unitSymbolOnNoValue = false,
  valueIfNothing: string | undefined = undefined
) => {
  if (!isSomething(level) && isSomething(valueIfNothing))
    return valueIfNothing;

  const withUnit = addUnitSymbol(withUnitSymbol, unit, unitSymbolOnNoValue);
  if (!isSomething(level) || (typeof(level) == 'string' && isEmpty(level))) 
    return withUnit(level);

  if (!unit || unit.decimals === null || unit.decimals === undefined) return withUnit(ensureNumber(level).toFixed(2));
  return withUnit(ensureNumber(level).toFixed(unit.decimals));
};

const bytesMapTable = {
  bytes: 0,
  kilobytes: 1024,
  megabytes: Math.pow(1024, 2),
  gigabytes: Math.pow(1024, 3)
};
export const convertBytesToHumanReadable = (bytes: number): string => {
  if (bytes < bytesMapTable.kilobytes) return `${bytes} b`;
  if (bytes < bytesMapTable.megabytes)
    return `${Math.round(bytes / bytesMapTable.kilobytes)} kb`;
  if (bytes < bytesMapTable.gigabytes)
    return `${Math.round(bytes / bytesMapTable.megabytes)} mb`;
  return `${Math.round(bytes / bytesMapTable.gigabytes)} gb`;
};
