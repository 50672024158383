import { isNone, isEmpty } from './helpers';

export const isInvalidNumber = (
  toValidate: number | string | undefined,
  emptyIsInvalid?: boolean
) =>
  typeof toValidate === 'number'
    ? false
    : isNone(toValidate) || isEmpty(toValidate)
    ? isNone(emptyIsInvalid)
      ? true
      : emptyIsInvalid
    : isNaN(Number(toValidate));

export const isNumber = (toValidate: any): toValidate is number =>
  typeof toValidate === 'number';

export const isNumeric = (value: string | number) =>
  isNumber(value) || (!isNaN(Number(value)) && isFinite(Number(value)));

export const ensureNumber = (value: number | string): number =>
  typeof value === 'string' ? parseFloat(value) : value;

export const leftPadNumber = (value: number, padding: number = 2) =>
  isNone(value) || value.toString().length >= padding
    ? value
    : (new Array(padding).fill(0).join() + value).slice(-padding);

export const roundDownToNearestHundred = (value: number) =>
  Math.floor(value / 100) * 100;

/**
 * Floors/rounds down a number to a set number of decimal digits
 * @param num The number to round
 * @param nDecimals The amount of decimal digits to output
 */
export const roundDownToNDecimals = (num: number, nDecimals: number): number => {
  if (nDecimals < 0) { return num; }
  const multiplier = Math.pow(10, nDecimals);
  return Math.floor(num * multiplier) / multiplier;
}

export const isFiniteNumber = (value: unknown): boolean => {
  const number =
    typeof value === 'number'
      ? value
      : typeof value === 'string'
      ? parseFloat(value)
      : NaN;

  return !isNaN(number);
};
