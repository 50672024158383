import { bindable, customElement } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import './contact-row.css';
import { isTouchDeviceWatcher } from '../../../utility/deviceHelpers';
import { IContact } from '../../../interfaces/entity/icontact';

@customElement('contact-row')
export class ContactRow {
  @bindable contact: IContact;
  @bindable contactType: string;
  @bindable menuIsOpen: boolean;
  @bindable canEdit: boolean;
  @bindable canRemove: boolean;
  @bindable changeContact = PLATFORM.noop;
  @bindable removeContact = PLATFORM.noop;
  @bindable selected: boolean;
  @bindable showDescription: boolean;
  @bindable canViewInContactManager = false;

  isTouchDevice: boolean = false;
  private unsubscribeWatcher: Function;

  constructor() {
    this.unsubscribeWatcher = isTouchDeviceWatcher((isTouchDevice: boolean) => {
      this.isTouchDevice = isTouchDevice;
    });
  }

  detached() {
    this.unsubscribeWatcher && this.unsubscribeWatcher();
  }
}
