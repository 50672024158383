import {
  IRequestState,
  IRequestStateEntityError,
  IRequestStateEntityDone,
  IRequestStateEntityProcessing
} from '../interfaces/index';

export const defaultRequest: IRequestState = {
  loaded: false,
  error: false,
  processing: false
};

export const requestLoaded: IRequestState = {
  loaded: true,
  error: false,
  processing: false
};

export const requestLoading = (message?: string): IRequestState => ({
  loaded: false,
  error: false,
  processing: true,
  message
});

export const requestFailed = (message: string): IRequestState => ({
  loaded: false,
  error: true,
  processing: false,
  message
});

export const requestFailedWrapper = <Q>(
  query: Q,
  message: string
): IRequestStateEntityError<Q> => ({
  error: true,
  message,
  type: 'error',
  query
});

export const requestLoadedWrapper = <T, Q>(
  query: Q,
  entity: T
): IRequestStateEntityDone<T, Q> => ({
  type: 'done',
  query,
  entity,
  loaded: true
});

export const requestLoadingWrapper = <Q>(
  query: Q
): IRequestStateEntityProcessing<Q> => ({
  type: 'processing',
  query,
  processing: true
});
