import { customElement, bindable, bindingMode } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import './filepicker-overlay.css';

@customElement('filepicker-overlay')
export class FilepickerOverlay {
  @bindable({ defaultBindingMode: bindingMode.oneWay }) accept = '';
  @bindable({ defaultBindingMode: bindingMode.oneWay }) multiple = false;
  @bindable({ defaultBindingMode: bindingMode.oneWay }) filepicked =
    PLATFORM.noop;

  input: HTMLInputElement;

  filesChanged() {
    this.filepicked({ files: this.input.files });
    this.clearPicker();
  }

  private clearPicker() {
    this.input.value = '';
  }
}
