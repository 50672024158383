import {
  getProfilesForController,
  fetchingProfileForDownload,
  persistingControllerProfile,
  toggleEditProfileSecurityLevel,
  editProfileSecurityLevel,
  fetchingProfileHistory,
  fetchingProfileForEdit,
  persistControllerProfiles,
  deleteSite
} from '../../actions';
import {
  IControllerProfile,
  IAsyncReducerState,
  IControllerProfileHistory
} from '../../interfaces';
import {
  mergeObjects,
  isNone,
  insertFetchedEntities,
  mergeDoneEntityInState,
  createAsyncDictionaryForSiteRelations,
  isEmpty,
  emptyArray,
  mergeDoneEntitiesInState,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  isSomething,
  removeAsyncObjects,
  removeNoneFromArray,
  flatMap,
  removeAsyncEntitiesInDictionary
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createFetchedEntity,
  createPendingEntity,
  createFailedFetchingEntity
} from '../../types';
import { IFetchProfileResult } from '../../interfaces/entity/iFetchProfileResult';

export interface IControllerProfilesReducer
  extends IAsyncReducerState<IControllerProfile> {
  byControllerIds: IAsyncDictionary<number[]>;
  profileHistory: IAsyncDictionary<IControllerProfileHistory[]>;
  profileValues: IAsyncDictionary<IFetchProfileResult>;
}

const defaultState: IControllerProfilesReducer = {
  byId: {},
  allIds: undefined,
  byControllerIds: {},
  profileHistory: {},
  profileValues: {}
};

const editProfile = (
  state: IControllerProfilesReducer,
  id: number,
  profile: Partial<IControllerProfile>
) => mergeObjects(state, mergeDoneEntityInState(state, id, _ => profile));

export const createControllerProfileHistoryKey = (
  profileId: number,
  historyId: number | undefined
) => (isNone(historyId) ? profileId : `${profileId}_${historyId}`);

const getProfilesByControllerIds = (
  state: IControllerProfilesReducer,
  controllerIds: number[]
) =>
  flatMap(
    removeNoneFromArray(
      controllerIds.map(id =>
        getEntityOrDefault(
          getAsyncEntitiesByAsyncArray(
            getAsyncEntity(state.byControllerIds, id),
            state.byId
          ),
          undefined
        )
      )
    ),
    item => item
  );

const reducer = reducerWithInitialState(defaultState)
  .case(deleteSite.done, (state, payload) => {
    const profilesByControllerIds = getProfilesByControllerIds(
      state,
      payload.params.nativeControllerIds
    );

    return isSomething(profilesByControllerIds)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            profilesByControllerIds.map(profile => profile.controllerProfileId)
          ),
          {
            byControllerIds: mergeObjects(
              state.byControllerIds,
              removeAsyncEntitiesInDictionary(
                state.byControllerIds,
                payload.params.nativeControllerIds
              )
            )
          }
        )
      : state;
  })

  .case(fetchingProfileForEdit.done, (state, { params, result }) => ({
    ...state,
    profileValues: {
      ...state.profileValues,
      [createControllerProfileHistoryKey(
        params.controllerProfileId,
        params.historyId
      )]: createFetchedEntity(result)
    }
  }))
  .case(fetchingProfileForEdit.started, (state, params) => ({
    ...state,
    profileValues: {
      ...state.profileValues,
      [createControllerProfileHistoryKey(
        params.controllerProfileId,
        params.historyId
      )]: createPendingEntity()
    }
  }))
  .case(fetchingProfileForEdit.failed, (state, { error, params }) => ({
    ...state,
    profileValues: {
      ...state.profileValues,
      [createControllerProfileHistoryKey(
        params.controllerProfileId,
        params.historyId
      )]: createFailedFetchingEntity(error)
    }
  }))

  .case(fetchingProfileHistory.done, (state, { params, result }) => ({
    ...state,
    profileHistory: {
      ...state.profileHistory,
      [params]: createFetchedEntity(result)
    }
  }))

  .case(fetchingProfileHistory.started, (state, params) => ({
    ...state,
    profileHistory: { ...state.profileHistory, [params]: createPendingEntity() }
  }))

  .case(getProfilesForController.done, (state, { result, params }) => ({
    ...state,
    ...insertFetchedEntities(state, result, cp => cp.controllerProfileId),
    byControllerIds: mergeObjects(
      state.byControllerIds,
      isEmpty(result)
        ? { [params.controllerId]: createFetchedEntity(emptyArray) }
        : createAsyncDictionaryForSiteRelations(
            result,
            r => r.controllerId,
            r => r.controllerProfileId
          )
    )
  }))

  .case(getProfilesForController.started, (state, params) =>
    mergeObjects(state, {
      byControllerIds: mergeObjects(state.byControllerIds, {
        [params.controllerId]: createPendingEntity()
      })
    })
  )

  .case(getProfilesForController.failed, (state, { error, params }) =>
    mergeObjects(state, {
      byControllerIds: mergeObjects(state.byControllerIds, {
        [params.controllerId]: createFailedFetchingEntity(error)
      })
    })
  )

  .case(toggleEditProfileSecurityLevel, (state, payload) =>
    payload.previous
      ? mergeObjects(
          state,
          mergeDoneEntitiesInState(
            state,
            payload.profiles.map(p => p.controllerProfileId),
            _ => ({ pendingSecurityLevel: undefined })
          )
        )
      : state
  )

  .case(persistControllerProfiles.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, payload.result, (cp) => cp.controllerProfileId)
    )
  )

  .case(editProfileSecurityLevel, (state, payload) =>
    mergeObjects(
      state,
      mergeDoneEntityInState(state, payload.controllerProfileId, _ => ({
        pendingSecurityLevel: payload.securityLevel
      }))
    )
  )

  .case(fetchingProfileForDownload.started, (state, payload) =>
    editProfile(state, payload.controllerProfileId, {
      loadingDataForExcel: true
    })
  )
  .case(fetchingProfileForDownload.done, (state, payload) =>
    editProfile(state, payload.params.controllerProfileId, {
      loadingDataForExcel: false,
      loadingDataForExcelError: undefined
    })
  )
  .case(fetchingProfileForDownload.failed, (state, payload) =>
    editProfile(state, payload.params.controllerProfileId, {
      loadingDataForExcel: false,
      loadingDataForExcelError: payload.error
    })
  )

  .case(persistingControllerProfile.started, (state, payload) =>
    editProfile(state, payload.controllerProfileId, {
      saving: true,
      savingFromExcel: payload.persistingThroughExcel
    })
  )
  .case(persistingControllerProfile.failed, (state, payload) =>
    editProfile(state, payload.params.controllerProfileId, {
      saving: false,
      saveError: payload.error
    })
  )
  .case(persistingControllerProfile.done, (state, { params, result }) => ({
    ...state,
    profileValues: {
      ...state.profileValues,
      [params.controllerProfileId]: createFetchedEntity<IFetchProfileResult>(result)
    },
    profileHistory: {
      ...state.profileHistory,
      [params.controllerProfileId]: undefined
    }
  }));
//editProfile(state, payload.params.controllerProfileId, { saving: false }));

export default reducer;
