import { isNone } from '../../utility';

export class ProcessingElement {
  _processing: boolean | string = false;
  _disabled: boolean = false;
  _error: boolean | string = false;
  onClick: undefined | (() => Promise<any> | void);
  disabled: boolean | undefined;
  disabledChanged(newVal: boolean | undefined) {
    this._disabled = !!newVal;
  }

  processing: boolean | string | undefined;
  processingChanged(newVal: boolean | string | undefined) {
    this._processing = typeof newVal === 'string' ? newVal : !!newVal;
  }

  error: boolean | string | undefined;
  errorChanged(newVal: boolean | string | undefined) {
    this._error = typeof newVal === 'string' ? newVal : !!newVal;
  }

  detached() {
    if (isNone(this.error)) this._error = false;
  }

  _doOnClick = async () => {
    if (!this.onClick) return;

    const maybePromise = this.onClick();
    if (!maybePromise || isNone(maybePromise.then)) return;
    if (isNone(this.error)) this._error = false;
    if (isNone(this.processing)) this._processing = true;
    if (isNone(this.disabled)) this._disabled = true;
    try {
      await maybePromise;
    } catch (err) {
      if (isNone(this.error)) this._error = true;
    }
    if (isNone(this.processing)) this._processing = false;
    if (isNone(this.disabled)) this._disabled = false;
  };
}
