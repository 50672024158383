import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  addFilter,
  getFilters,
  deleteFilter,
  updateFilter
} from '../actions/index';
import { BaseService } from './baseService';
import { IFilterDTO } from '../interfaces/index';
import { revalidateAllActiveQueries } from '../pages/common/GraphQLFetcher';

@autoinject
export class FilterService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(FilterService.name), taskQueue);
  }

  getAllAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getFilters(),
      getFilters,
      {},
      'Get all filters failed'
    );

  createAsync = async (filter: IFilterDTO) => {
    const response = await this.httpRequestWithDispatch(
      requests.addFilter(filter),
      addFilter,
      filter,
      'Add filter failed'
    );
    await revalidateAllActiveQueries();
    return response;
  };

  updateAsync = async (filter: IFilterDTO) => {
    const response = await this.httpRequestWithDispatch(
      requests.updateFilter(filter),
      updateFilter,
      filter,
      'Update filter failed'
    );

    await revalidateAllActiveQueries();
    return response;
  };

  deleteAsync = async (filter: IFilterDTO) => {
    const response = await this.httpRequestWithDispatch(
      requests.removeFilter(filter),
      deleteFilter,
      filter,
      'Remove filter failed.'
    );
    await revalidateAllActiveQueries();
    return response;
  };
}
