import actionCreatorFactory from 'typescript-fsa';
import {
  ISiteDetailsMapAddressAndLocation,
  ILatLng,
  ISiteDetailsMapAddress,
  IController,
  IControllerMapMarker
} from '../interfaces';
import { setMarkerMode } from '../reducers/ui/sitedetailsMapReducer';

const actionCreator = actionCreatorFactory('sitedetailsmap');

export const editSiteDetailsMap = actionCreator('sitedetailsmap_editmode');

export const editSiteAddressPropertyForMap = actionCreator<{
  property: string;
  value: string;
}>('edit_site_address_property_for_map');

export const editMapMarkerProperty = actionCreator<{
  markerToEdit: IControllerMapMarker;
  property: string;
  value: string;
}>('edit_map_marker_property');

export const persistMapMarkerChanges = actionCreator.async<
  ISiteDetailsMapAddressAndLocation,
  IController[]
>('persist_map_marker_changes');

export const updateMarkerLocationFromCurrentPosition = actionCreator.async<
  IControllerMapMarker,
  ILatLng
>('update_marker_location_from_current_location');

export const updateSiteAddressForMap = actionCreator.async<
  {},
  ISiteDetailsMapAddress
>('update_site_address_for_map');
export const updateMapMarker = actionCreator.async<
  IControllerMapMarker,
  ILatLng
>('update_map_marker_for_map');

export const cancelSiteAddressForMapEdit = actionCreator(
  'cancel_site_address_for_map_edit'
);
export const setSiteMapMarkerMode = actionCreator<setMarkerMode>(
  'setSiteMapMarkerMode'
);
export const confirmMapMarkerMode = actionCreator<setMarkerMode>(
  'confirmMapMarkerMode'
);
export const setSiteMapMarkerModeForSite = actionCreator.async<
  setMarkerMode,
  ISiteDetailsMapAddress,
  string
>('setSiteMapMarkerModeForSite');
export const updateMarkerLocationFromControllerPosition = actionCreator.async<
  IControllerMapMarker,
  ILatLng
>('updateMarkerLocationFromControllerPosition');
