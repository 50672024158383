import { customElement, computedFrom } from 'aurelia-framework';
import './sortable-cell.css';
import { bindable } from 'aurelia-templating';
import { sortDirection } from '../../../types';

@customElement('sortable-cell')
export class SortableCell {
  @bindable() sortProperty: string;
  @bindable() sortDirection: sortDirection;

  @bindable() property: string;

  @computedFrom('sortProperty', 'property')
  get isActive() {
    return this.property === this.sortProperty;
  }
}
