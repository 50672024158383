import { fetchingUnits } from '../../actions';
import {
  mergeObjects,
  insertAllFetchedEntities,
  ensureNumber
} from '../../utility';
import { IUnit, IAsyncReducerState } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { createPendingEntity } from '../../types';

export interface IUnitReducerState extends IAsyncReducerState<IUnit> {}

const defaultState: IUnitReducerState = { allIds: undefined, byId: {} };

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingUnits.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )
  .case(fetchingUnits.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, unit => ensureNumber(unit.unitId))
    )
  );

export default reducer;
