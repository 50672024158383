import { pureFormatDate } from '../utility/dateHelpers';

export class PureDateFormatValueConverter {
  toView(
    value: Date | string,
    withTime: boolean,
    separator?: string
  ) {
    if (value == null || value == undefined) return null;

    if (separator === undefined) separator = '.';

    return pureFormatDate(value, withTime, separator);
  }
}
