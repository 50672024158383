export enum features {
  siteDetails = 1,
  ownSiteNotes = 2,
  allSiteNotes = 3,

  controllerCommands = 5,
  routePlanning = 6,
  staalduinenExport = 7,
  controllerLog = 8,
  siteEquipment = 11,
  manageCustomers = 13,
  manageUsers = 14,
  restrictedAttachments = 15,
  siteGallery = 16,
  siteTanks = 17,

  translations = 18,

  productEditor = 19,
  detailedTankUsage = 21,
  importManagement = 22,
  publicFilters = 24,
  reportsDeviceType = 25,
  reportsProductStock = 26,
  reportsSiteRefills = 28,

  trendTool = 29,

  exportManagement = 30,
  alarms = 31,
  controllerManagement = 32,
  replaceController = 33,
  siteDetailsController = 34,
  siteDetailsChannels = 35,
  qrApp = 36,
  exportJobChannels = 37,
  controllerParameters = 38,
  controllerProfiles = 39,
  contactManager = 40,
  idacs = 41,
  stockVsDose = 42,
  reportsSitesStatus = 43,
  simManagement = 44,
  sapLookup = 45,
  createChannel = 46,
  scheduledReports = 47,
  sendPdiMasterData = 48,

  rawGraphValues = 50,
  reportsAlarm = 51,
  deliveryNoteManager = 52,
  easyFeedRecipe = 53,
  requestRefill = 54,
  dashboard = 55,
  createController = 56,
  unassignedDeliveryNotes = 57,
  decommissionedControllers = 58,
  haulierManager = 59,
  releaseNoteEditor = 60,
  userStatisticsReport = 61,
  customerManagerEditName = 62,
  liveMap = 63,
  reportsNewSites = 64,
  reportDosing = 65,
  graphSecurityLevels = 66,
  dashboardWidgetNewSites = 68,  
  dashboardWidgetLowestLevels = 69,
  dashboardWidgetNextReachMinimum = 70,
  dashboardWidgetRecentlyViewedSites = 71,
  dashboardWidgetDeliveriesLast = 72,
  dashboardWidgetDeliveriesYTD = 73,
  dashboardWidgetDataSources = 74,
  dashboardWidgetUserStatistics = 75,
  dashboardWidgetControllers = 76,
  reportTemperature = 77,
  reportIdaDeliveries = 78,
  reportDryRuns = 79,
  controllerOutputCalibrations = 80,
  controllerUploadConfig = 81,
  controllerFreeTextCommand = 82,
  reportDryRunsLostSales = 83,
  scriptConfigs = 84,
  reportDailyDose = 85,
  reportNitratesMonthly = 86,
  reportIdaLifecycle = 87,
  dashboardWidgetLifecycle = 88
}
