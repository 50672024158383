import { INote } from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('notes');

export const postNewNoteAction = actionCreator.async<
  { siteId: number; text: string; noteType: number; internalId: number },
  INote,
  { code: number }
>('postNew');

export const deleteNoteAction = actionCreator.async<
  { siteId: number; noteId: number; internalId: number },
  boolean
>('deleteNote');

export const updateNoteAction = actionCreator.async<
  {
    siteId: number;
    noteId: number;
    text: string;
    noteType: number;
    internalId: number;
  },
  INote
>('update_note');

export const getAllDisplayNotes = actionCreator.async<{}, INote[]>(
  'getAllDisplayNotes'
);
export const getNotesForSite = actionCreator.async<{ siteId: number }, INote[]>(
  'getNotesForSite'
);
