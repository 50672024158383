import { bindable, customElement, DOM } from 'aurelia-framework';
import './action-text.css';
import { isSomething } from '../../../utility';

@customElement('action-text')
export class ActionText {
  constructor() {
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
  }

  @bindable onClick: () => void;
  @bindable text: string;

  @bindable disabled: boolean = false;
  @bindable icon: string = 'fa-caret-down';
  @bindable alignTop: boolean;
  @bindable alignRight: boolean;

  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;

  @bindable showContent: boolean | undefined;
  _showContent = false;

  showContentChanged() {
    this._showContent = !!this.showContent;
  }

  @bindable showIcon: boolean = true;

  @bindable textClicked: Function;

  textIsClicked(event: Event) {
    if (event && !!this.textClicked) event.stopPropagation();
    if (!!this.textClicked) return this.textClicked();
    if (isSomething(this.showContent)) return;
    if (this._showContent) this.hideDropdown();
    else this.showDropdown();
  }

  showDropdown() {
    this._showContent = true;
    setTimeout(() => DOM.addEventListener('click', this.hideDropdown, false));
  }

  hideDropdown() {
    this._showContent = false;
    DOM.removeEventListener('click', this.hideDropdown, false);
  }
}
