import { CardFormGroup } from '../../cards/card-form-group/card-form-group';
import { bindable } from 'aurelia-framework';
import './modal-card-form-group.css';
import { computedFrom } from 'aurelia-binding';

export class ModalCardFormGroup extends CardFormGroup {
  @bindable size: 'flex' | 'fill' = 'flex';
  @bindable overflow: boolean = true;
  @bindable placementOnMobile: undefined | 'bottom';
  @bindable contentDirection: 'row' | undefined;
  modalElement: Element;

  @bindable() submit: undefined | (() => Promise<any> | void);
  @bindable() cancel: undefined | (() => void);
  @bindable() formIsValid: boolean | undefined = undefined;
  @bindable() class: string = '';
  @bindable() showFooter: boolean = true;
  submitting = false;
  @bindable() cancelText = 'UI_Common_Cancel';
  @bindable() submitText = 'UI_Common_SaveChanges';

  attached() {
    this.addFormValidationlisteners(this.modalElement);
  }

  @computedFrom('_formIsValid', 'showValidationErrors')
  get primaryButtonDisabled(): boolean {
    return super.calculatePrimaryButtonDisabled();
  }
}
