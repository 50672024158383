import actionCreatorFactory from 'typescript-fsa';
import { ILanguage } from '../interfaces/index';
import { ILanguageText } from '../interfaces/entity/iLanguageText';

const actionCreator = actionCreatorFactory('translation');

export const getAllLanguages = actionCreator.async<void, ILanguage[]>(
  'getAllLanguages'
);

export const getLanguagesFromSource = actionCreator.async<
  ILanguage,
  ILanguage[]
>('getLanguagesFromSource');

export const selectDestinationLanguage = actionCreator<number>(
  'selectDestinationLanguage'
);
export const selectSourceLanguage = actionCreator<number>(
  'selectSourceLanguage'
);

export const getAllTranslationKeys = actionCreator.async<{}, string[]>(
  'getAllTranslationKeys'
);

export const toggleOnlyMissing = actionCreator<boolean>('toggleOnlyMissing');

export const createOrUpdateLanguageTexts = actionCreator.async<
  ILanguageText[],
  ILanguageText[]
>('createOrUpdateLanguageTexts');

// export const textChanged = actionCreator.async<{languageKey: string, languageId: number, value: string}, {}>("textChanged");
export const textChanged = actionCreator<ILanguageText>('textChanged');

export const discardChanges = actionCreator<ILanguageText[]>('discardChanges');
