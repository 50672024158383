import { customElement, bindable } from 'aurelia-framework';
import './checkbox.css';

@customElement('checkbox')
export class Checkbox {
  @bindable onChange: Function;
  @bindable checked: boolean;
  @bindable text: string;
  @bindable loading: boolean;
  @bindable value: string;
  @bindable disabled: boolean;
  @bindable size?: 'normal' | 'bigger'

  change(event: MouseEvent | undefined) {
    if (this.onChange && !this.disabled) {
      this.onChange({ checked: this.checked, value: this.value, event })
    }
    event && event.preventDefault();
  }
}

