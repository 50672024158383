import {
  getExportedChannelsForSite,
  getExportedChannelsForSiteChannel,
  getExportJobNamesAsync,
  navigateToRoute,
  addSiteChannelToExportJob,
  removeSiteChannelFromExportJob,
  updateSiteChannel,
  setPendingExportJobs
} from '../../actions';
import {
  mergeObjects,
  insertFetchedEntities,
  removeObjectFromArray,
  removeAsyncEntityInDictionary
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ISiteExport } from '../../interfaces/entity/iSiteExport';
import { IAsyncReducerState } from '../../interfaces';
import {
  IAsyncDictionary,
  createPendingEntity,
  IAsyncEntity,
  createFetchedEntity
} from '../../types';
import { IExportJobNameAndId } from '../../interfaces/entity/iexportjobNameAndId';

export interface ISiteExportReducer extends IAsyncReducerState<ISiteExport> {
  bySiteId: IAsyncDictionary<number[]>;
  bySiteChannelId: IAsyncDictionary<number[]>;
  exportJobNames: IAsyncEntity<Array<IExportJobNameAndId>>;
  pendingExportJobsForCurrentChannel: undefined | IExportJobNameAndId[];
}

const defaultState: ISiteExportReducer = {
  allIds: undefined,
  byId: {},
  bySiteChannelId: {},
  bySiteId: {},
  exportJobNames: undefined,
  pendingExportJobsForCurrentChannel: undefined
};

const reducer = reducerWithInitialState(defaultState)
  .case(getExportedChannelsForSite.done, (state, { result, params }) => ({
    ...state,
    ...insertFetchedEntities(state, result, j => j.exportJobId),
    bySiteId: {
      ...state.bySiteId,
      [params]: createFetchedEntity(result.map(r => r.exportJobId))
    }
  }))

  .case(getExportedChannelsForSite.started, (state, params) =>
    mergeObjects(state, {
      bySiteId: mergeObjects(state.bySiteId, {
        [params]: createPendingEntity()
      })
    })
  )

  .case(getExportJobNamesAsync.started, state =>
    mergeObjects(state, { exportJobNames: createPendingEntity() })
  )
  .case(getExportJobNamesAsync.done, (state, { result }) =>
    mergeObjects(state, { exportJobNames: createFetchedEntity(result) })
  )

  .case(updateSiteChannel.done, (state, { params }) => {
    return params.pendingExportJobs
      ? mergeObjects(state, {
          pendingExportJobsForCurrentChannel: undefined,
          bySiteId: {}, // TODO: If you find a better solution. Do not clear this!
          bySiteChannelId: removeAsyncEntityInDictionary(
            state.bySiteChannelId,
            params.siteChannelId
          ) 
        })
      : state
    }
  )

  .case(getExportedChannelsForSiteChannel.started, (state, params) =>
    mergeObjects(state, {
      bySiteChannelId: mergeObjects(state.bySiteChannelId, {
        [params]: createPendingEntity()
      })
    })
  )

  .case(navigateToRoute, state =>
    mergeObjects(state, { pendingExportJobsForCurrentChannel: undefined })
  )
  
  .case(setPendingExportJobs, (state, {exportJobs}) => {
      return mergeObjects(state, { pendingExportJobsForCurrentChannel: exportJobs })
    }
  )

  .case(addSiteChannelToExportJob, (state, { job, existing }) =>
    mergeObjects(state, {
      pendingExportJobsForCurrentChannel: [
        ...existing,
        job
      ]
    })
  )
  .case(removeSiteChannelFromExportJob, (state, { jobId, existing }) => {        
    return mergeObjects(state, {
        pendingExportJobsForCurrentChannel: removeObjectFromArray(
          state.pendingExportJobsForCurrentChannel
            ? state.pendingExportJobsForCurrentChannel
            : existing,
          (job) => job.exportJobId == jobId
        )
      });
    }
  )

  .case(getExportedChannelsForSiteChannel.done, (state, { result, params }) =>
   mergeObjects(
        state,
        insertFetchedEntities(state, result, k => k.exportJobId),
        {
          bySiteChannelId: mergeObjects(state.bySiteChannelId, {
            [params]: createFetchedEntity(result.map(r => r.exportJobId))
          })
        }
      )  
  );

export default reducer;
