import { autoinject } from 'aurelia-framework';
import { ColumnService } from '../../services/index';
import { I18N } from 'aurelia-i18n';
import { ISiteColumn } from '../../interfaces/index';
import { BaseViewModel } from '../../pages/common';
import { getLogger } from 'aurelia-logging';
import { mapFromColumnIdsToISiteColumnsMemoized } from '../../reducers/entity/columnsReducer';
import { IAsyncEntityManager } from '../../types';
import './column-selector.css';

@autoinject()
export class ColumnSelectorCustomElement extends BaseViewModel<
  IAsyncEntityManager<ISiteColumn[]>
> {
  // internals
  private selectedColumns: ISiteColumn[] = new Array<ISiteColumn>();
  private internalColumns: ISiteColumn[] = new Array<ISiteColumn>();

  constructor(private gridService: ColumnService, private i18n: I18N) {
    super(getLogger(ColumnSelectorCustomElement.name));
    this.attachMapState(this.mapstate);
  }

  mapstate = () =>
    this.gridService.getColumns().map(mapFromColumnIdsToISiteColumnsMemoized);

  stateChanged = (state: IAsyncEntityManager<ISiteColumn[]>) => {
    if (!state.isFetched) return;
    this.selectedColumns = state
      .getEntityOrDefault([])
      .filter(column => column.selected);
    this.internalColumns = state
      .getEntityOrDefault([])
      .filter(column => !column.selected);
    this.sort();
  };

  async change() {
    this.sort();

    this.updateColumns();
  }

  private hasPendingChangeRequest: boolean = false;
  private currentlyRunningRequest: boolean = false;
  async updateColumns() {
    if (this.currentlyRunningRequest && this.hasPendingChangeRequest) return;

    if (this.currentlyRunningRequest) {
      this.hasPendingChangeRequest = true;
      return;
    }

    this.currentlyRunningRequest = true;
    await this.gridService.updateColumns([
      ...this.selectedColumns,
      ...this.internalColumns
    ]);

    this.currentlyRunningRequest = false;

    if (this.hasPendingChangeRequest) {
      this.hasPendingChangeRequest = false;
      this.updateColumns();
    }
  }

  sort() {
    const sorted = this.internalColumns.sort(
      (a: ISiteColumn, b: ISiteColumn) => {
        const first = this.i18n.tr(a.languageKey);
        const second = this.i18n.tr(b.languageKey);

        if (first < second) return -1;
        else if (first > second) return 1;
        else return 0;
      }
    );

    return sorted;
  }

  addColumn(index: number): void {
    const column = this.internalColumns[index];
    column.selected = true;

    this.selectedColumns.push(column);
    this.internalColumns.splice(index, 1);
    this.change();
  }

  removeColumn(index: number): void {
    const column = this.selectedColumns[index];
    column.selected = false;

    this.internalColumns.push(column);
    this.selectedColumns.splice(index, 1);
    this.change();
  }

  moveLeft(index: number): void {
    this.swap(index, index - 1);
    this.change();
  }

  moveRight(index: number): void {
    this.swap(index, index + 1);
    this.change();
  }

  swap(a: number, b: number) {
    const temp = this.selectedColumns[b];
    this.selectedColumns.splice(b, 1, this.selectedColumns[a]);
    this.selectedColumns.splice(a, 1, temp);
  }
}
