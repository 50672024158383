import { bindable, customElement } from 'aurelia-framework';
import Flatpickr from 'flatpickr';
import { ensureDate, isNone } from '../../utility';
import './date-picker.css';
import 'flatpickr/dist/flatpickr.min.css';
import { Instance } from 'flatpickr/dist/types/instance';

@customElement('date-picker')
export class DatePickerCustomElement {
  datePicker: HTMLElement;

  // External properties
  @bindable showTimeSelector: boolean = false;
  @bindable showRange: boolean = false;
  @bindable selectedDate: Date | string | undefined;
  @bindable selectedDateFrom: Date | string | undefined;
  @bindable selectedDateTo: Date | string | undefined;
  @bindable minimumDate: Date | undefined;
  @bindable maximumDate: Date | undefined;
  @bindable onChanged: Function;
  @bindable inline: boolean = false;

  @bindable placeholder: string;
  @bindable size: string = 'medium';
  @bindable disabled: boolean;

  // Internal properties
  private flatpickrCtrl: Instance;

  clear() {
    this.flatpickrCtrl.clear();
  }

  bind() {
    // This causes bind to be called instead of changehandlers.
  }

  detached() {
    this.flatpickrCtrl.destroy()
  }

  attached() {
    const getDefaultDate = (): Date | Date[] | undefined => {
      if (
        this.showRange &&
        !isNone(this.selectedDateFrom) &&
        !isNone(this.selectedDateTo)
      )
        return [
          ensureDate(this.selectedDateFrom),
          ensureDate(this.selectedDateTo)
        ];
      if (!this.showRange && !isNone(this.selectedDate))
        return ensureDate(this.selectedDate);
      return;
    };

    this.flatpickrCtrl = Flatpickr(this.datePicker, {
      time_24hr: true,
      enableTime: this.showTimeSelector,
      mode: this.showRange ? 'range' : 'single',
      dateFormat: this.showTimeSelector ? 'd.m.Y H:i' : 'd.m.Y',
      defaultMinute: 0,
      inline: this.inline,
      minDate: this.minimumDate,
      maxDate: this.maximumDate,
      minuteIncrement: 5,
      defaultDate: getDefaultDate(),
      onChange: selectedDates => {
        if (!selectedDates.length) return;
        if (selectedDates && selectedDates[0] && this.onChanged)
          this.onChanged({ selectedDate: selectedDates[0], selectedDates });
        if (!this.showRange) this.selectedDate = selectedDates[0];
      }
    });
  }

  minimumDateChanged(newValue: Date) {
    if (this.flatpickrCtrl !== undefined)
      this.flatpickrCtrl.set('minDate', newValue);
  }

  maximumDateChanged(newValue: Date) {
    if (this.flatpickrCtrl !== undefined)
      this.flatpickrCtrl.set('maxDate', newValue);
  }

  selectedDateChanged(
    newVal: Date | string | undefined,
    oldVal: Date | string | undefined
  ) {
    if (oldVal === newVal || isNone(this.flatpickrCtrl)) return;
    if (isNone(newVal)) this.flatpickrCtrl.clear();
    else this.flatpickrCtrl.setDate(ensureDate(newVal));
  }

  selectedDateFromChanged(
    newVal: Date | string | undefined,
    oldVal: Date | string | undefined
  ) {
    if (oldVal === newVal || isNone(this.flatpickrCtrl)) return;
    if (isNone(newVal)) this.flatpickrCtrl.clear();
    else this.flatpickrCtrl.setDate([ensureDate(newVal)]);
  }

  selectedDateToChanged(
    newVal: Date | string | undefined,
    oldVal: Date | string | undefined
  ) {
    if (oldVal === newVal || isNone(this.flatpickrCtrl)) return;
    if (isNone(newVal) || isNone(this.selectedDateFrom))
      this.flatpickrCtrl.clear();
    else
      this.flatpickrCtrl.setDate([
        ensureDate(this.selectedDateFrom),
        ensureDate(newVal)
      ]);
  }
}
