import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { requests } from '../config';
import { getLogger } from 'aurelia-logging';
import { getSimState, activateSimCard } from '../actions/index';
import { BaseService } from './baseService';

@autoinject()
export class SimService extends BaseService {
  constructor(protected httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(SimService.name), taskQueue);
  }

  getSimState = async (controllerId: number) =>
    this.httpRequestWithDispatch(
      requests.simCardStatus(controllerId),
      getSimState,
      controllerId,
      'UI_QrApp_SimActivation_GetSimState_Failed'
    );

  activateSim = async (
    controllerId: number,
    buidId: number,
    icc: string,
    simOnly: boolean
  ) =>
    this.httpRequestWithDispatch(
      requests.simCardActivation(controllerId, buidId, icc, simOnly),
      activateSimCard,
      { controllerId, buidId, icc, simOnly },
      'UI_QrApp_SimActivation_ActivateSim_Failed'
    );

  tryBindSimCardToController = async (msisdn: string) => {
    return this.httpRequest<boolean>(requests.bindSimCardToController(msisdn));
  };

  sendSimAction = async (
    icc: string,
    msisdn: string,
    ipAddress: string,
    simCardType: string,
    buidName: string,
    action: string
  ) =>
    this.httpRequest<boolean>(
      requests.sendSimCardAction(
        icc,
        msisdn,
        ipAddress,
        simCardType,
        buidName,
        action
      )
    );
}
