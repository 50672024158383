import { customElement, bindable } from 'aurelia-framework'
import { isNone } from '../../utility';

const animationTimeInMs = 750;

@customElement('animated-number')
export class AnimatedNumber {
    @bindable() number: number | undefined;
    
    
    private _currentValue: number;
    private previousValue: number | undefined;
    private time: number | undefined;
    private animationLoop: number | undefined;

    private animate() {
        if(isNone(this.previousValue) || isNone(this.time)) return;
        const now = new Date().getTime();
        const distanceToTravel = (this.number || 0) - this.previousValue;

        const percentOfTravel = Math.min((now - this.time) / animationTimeInMs, 1)
        
        const newInterpolated = Math.round(distanceToTravel * percentOfTravel + this.previousValue);
        this._currentValue = newInterpolated

        if(this.number != this._currentValue)
            this.animationLoop = requestAnimationFrame(() => this.animate());
        else {
            this.animationLoop = undefined;
        }
    }

    numberChanged(newNumber: number | undefined) {
        if(isNone(newNumber)) return;
        const now = new Date().getTime();
        this.time = now;
        this.previousValue = this._currentValue || newNumber;
        if(!this.animationLoop) this.animate();
    }

}