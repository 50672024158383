import { IBuid, AccessLevel, ICustomer, ISession } from '../interfaces';
import { ColumnAccess, features } from '../config';

export const userHasFeature = (
  session: ISession,
  feature: features,
  accesslevel: AccessLevel
) => {
  if (session.currentUser.isSuperUser) return true;

  switch (accesslevel) {
    case AccessLevel.Write:
      return (
        session.features.write_access.includes(feature) ||
        session.features.delete_access.includes(feature)
      );
    case AccessLevel.Read:
      return (
        session.features.read_access.includes(feature) ||
        session.features.write_access.includes(feature) ||
        session.features.delete_access.includes(feature)
      );
    case AccessLevel.Delete:
      return session.features.delete_access.includes(feature);
  }

  return false;
};

export const hasAccessToBuidId = (session: ISession, buidId: number) =>
  session.currentUser.isSuperUser ||
  session.currentUser.allBuidAllowed ||
  session.allowedBuids.includes(buidId);

export const hasAccessToCustomerId = (session: ISession, customerId: number) =>
  session.currentUser.isSuperUser ||
  session.currentUser.allCustomerAllowed ||
  session.allowedCustomers.includes(customerId);

export const hasAccessToBuid = (session: ISession, buid: IBuid) =>
  session.currentUser.isSuperUser ||
  session.currentUser.allBuidAllowed ||
  session.allowedBuids.includes(buid.buidId);

export const hasAccessToBuidCurried = (session: ISession) => (buid: IBuid) =>
  session.currentUser.isSuperUser ||
  session.currentUser.allBuidAllowed ||
  session.allowedBuids.includes(buid.buidId);

export const hasAccessToCustomer = (session: ISession, customer: ICustomer) =>
  session.currentUser.isSuperUser ||
  session.currentUser.allCustomerAllowed ||
  session.allowedCustomers.includes(customer.customerId) ||
  (customer.buidId && session.allowedBuids.includes(customer.buidId));

export const hasAccessToCustomerCurried = (session: ISession) => (
  customer: ICustomer
) =>
  session.currentUser.isSuperUser ||
  session.currentUser.allCustomerAllowed ||
  session.allowedCustomers.includes(customer.customerId) ||
  (customer.buidId && session.allowedBuids.includes(customer.buidId));

export const hasAccessToColumn = (session: ISession, column: ColumnAccess) =>
  session.currentUser.isSuperUser || session.columns.includes(column);

export const hasAccessToSecurityLevel = (
  session: ISession,
  securityLevel: number
) => session.currentUser.isSuperUser || session.securityLevel >= securityLevel;
