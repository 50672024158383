import './decimal-input.css';
import {
  bindable,
  PLATFORM,
  computedFrom,
  ComponentDetached,
  autoinject
} from 'aurelia-framework';
import {
  isEmpty,
  isNone,
  emptyArray,
  assureArray,
  isFiniteNumber,
  isSomething
} from '../../../utility';
import { createCustomEvent } from '../../../utility/customEventHelper';

type errorType = undefined | string | string[] | boolean;

const isValidationError = (error: errorType): boolean => {
  return !(typeof error === 'boolean' || isNone(error));
};

@autoinject()
export class DecimalInput implements ComponentDetached {
  @bindable value: number | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable required: boolean = false;
  @bindable error: undefined | string | string[] | boolean;
  @bindable placeholder: string = "";

  htmlinput: Element;

  attached() {
    this.runValidation(this.value);
  }

  detached() {
    dispatchEvent(createCustomEvent('inputremoved', this.htmlinput));
  }

  requiredChanged(newValue: boolean) {
    if (newValue !== undefined) {
      this.runValidation(this.value);
    }
  }

  valueChanged(newValue: number | undefined) {
    this.onChanged && this.onChanged({ value: newValue });

    if (isSomething(this.htmlinput)) {
      this.runValidation(newValue);
    }
  }

  //Returns locale settings decimal separator (, or .)
  getLocaleDecimalSeparater() {
    // const n = 1.1;
    // const localen = n.toLocaleString();
    // const match = /^1(.+)1$/.exec(localen);
    
    // if (match) 
    //   return match[1];
    
    //Note, now only supports . as decimal separator (#4956). Number, parseInt and parseFloat does not support locale.
    return ".";
  }

  keyPress({ charCode }: KeyboardEvent) {
    const decimalSeparator = this.getLocaleDecimalSeparater();
    const dots = ['-', decimalSeparator];
    const dotCodes = dots.map(x => x.charCodeAt(0));    
    const lowerKeyBounds = '0'.charCodeAt(0);
    const higherKeyBounds = '9'.charCodeAt(0);

    //Allow all numbers
    if (charCode >= lowerKeyBounds && charCode <= higherKeyBounds) return true;

    //If character pressed is not in dots array, disallow
    if (!dotCodes.includes(charCode)) return false;
    if (isNone(this.value)) return true;
    
    //Only allowed to have one - sign
    if (this.value.toString().includes('-') && charCode == '-'.charCodeAt(0)) return false;

    //Only allowed to have one decimal separator sign
    if (this.value.toString().includes(decimalSeparator) && charCode == decimalSeparator.charCodeAt(0)) return false;

    return true;
  }

  @computedFrom('error')
  get hasValidationErrors() {
    return typeof this.error === 'boolean' ? this.error : !isEmpty(this.error);
  }

  @computedFrom('error')
  get errorTexts() {
    return typeof this.error === 'boolean' || isNone(this.error)
      ? emptyArray
      : assureArray(this.error);
  }

  validateDecimal(newValue: string | number | undefined): errorType {    
    const isNan = isNaN(Number(newValue));    
    if (!isNan)
      return undefined;
    
    return ['Invalid number'];
  }


  runValidation(newValue: string | number | undefined) {
    this.error = this.requiredValidation(newValue);

    if(!this.error && newValue !== undefined){
      this.error = this.validateDecimal(newValue)
    }

    this.notifyOfValidationErrors();
  }

  requiredValidation(newValue: string | number | undefined): errorType {
    if (this.required && !isFiniteNumber(newValue)) {
      return ['UI_Common_ValidationError_RequiredField'];
    }
    return undefined;
  }

  notifyOfValidationErrors() {
    if (isNone(this.htmlinput)) {
      return;
    }
    if (isValidationError(this.error)) {
      this.htmlinput.dispatchEvent(
        createCustomEvent('validationerrors', this.htmlinput)
      );
    } else if (!isValidationError(this.error)) {
      this.htmlinput.dispatchEvent(
        createCustomEvent('validationsuccess', this.htmlinput)
      );
    }
  }

  callOnBlur() {
    this.runValidation(this.value);
    if (!this.onBlur) return;
    this.onBlur({ value: this.value });
  }
}
