import { createSelector } from 'reselect';
import {
  IRoute,
  routes,
  ISiteDetailsRoute,
  IProduct,
  IVehicle,
  ISiteChannel,
  IAsyncReducerState,
  ICustomer,
  IUser,
  IBuid,
  ISite,
  IControllerLog,
  IUnit,
  ILoadingPoint,
  IController,
  ISession,
  IHaulier
} from '../../interfaces';

import {
  rootState,
  ISiteReducerState,
  IControllerReducer
} from '../../reducers';
import {
  isNone,
  getAllEntities,
  getEntityOrUndefined,
  getAsyncEntity,
  getEntityOrDefault,
  emptyArray,
  getAsyncEntitiesByAsyncArray,
  mapFetchedAsyncEntity,
  hasAccessToColumn,
  removeNoneFromArray,
  getDefaultTimezone,
  getTimezoneByIdOrDefault
} from '../../utility';
import { routeNames, ColumnAccess } from '../../config';
import { Maybe } from 'tsmonad';
import { IAsyncEntity, IAsyncDictionary } from '../../types/index';

const routeIsSiteDetailsRoute = (route: routes): route is ISiteDetailsRoute =>
  route.route === routeNames.sitedetails;

const selectInnerMostChildRoute = (route: IRoute): IRoute =>
  isNone(route.child) ? route : selectInnerMostChildRoute(route.child);

export const selectCurrentRoute = createSelector<rootState, IRoute, IRoute>(
  state => state.router.currentRoute,
  route => selectInnerMostChildRoute(route)
);

export const selectCurrentRouteName = createSelector<
  rootState,
  IRoute,
  string | undefined
>(
  selectCurrentRoute,
  route => route.route
);

const getSiteDetailsRouteFromRoute = (
  route: routes
): Maybe<ISiteDetailsRoute> =>
  routeIsSiteDetailsRoute(route)
    ? Maybe.just(route)
    : route.child
    ? getSiteDetailsRouteFromRoute(route.child)
    : Maybe.nothing<ISiteDetailsRoute>();

const getSiteDetailsRouteFromState = createSelector<
  rootState,
  routes,
  Maybe<ISiteDetailsRoute>
>(
  state => state.router.currentRoute,
  getSiteDetailsRouteFromRoute
);

export const getSiteIdParameter = createSelector<
  rootState,
  Maybe<ISiteDetailsRoute>,
  Maybe<number>
>(
  getSiteDetailsRouteFromState,
  route => route.map(r => parseInt(r.params.id, 10))
);

export const selectCurrentSite = createSelector<
  rootState,
  Maybe<number>,
  ISiteReducerState,
  IAsyncEntity<ISite>
>(
  getSiteIdParameter,
  state => state.site,
  (siteId, sites) => getAsyncEntity(sites.byId, siteId.valueOr(0))
);

export const selectProducts = createSelector<
  rootState,
  IAsyncReducerState<IProduct>,
  IAsyncEntity<IProduct[]>
>(
  state => state.products,
  getAllEntities
);

export const selectProductsOrEmptyArray = createSelector<
  rootState,
  IAsyncEntity<IProduct[]>,
  IProduct[]
>(
  selectProducts,
  asyncProducts => getEntityOrDefault(asyncProducts, emptyArray)
);

export const selectControllerLogs = createSelector<
  rootState,
  IAsyncReducerState<IControllerLog>,
  IAsyncEntity<IControllerLog[]>
>(
  state => state.controllerLog,
  getAllEntities
);

export const selectCustomers = createSelector<
  rootState,
  IAsyncReducerState<ICustomer>,
  IAsyncEntity<ICustomer[]>
>(
  state => state.customer,
  getAllEntities
);

export const selectUsers = createSelector<
  rootState,
  IAsyncReducerState<IUser>,
  IAsyncEntity<IUser[]>
>(
  state => state.users,
  getAllEntities
);

export const selectUnits = createSelector<
  rootState,
  IAsyncReducerState<IUnit>,
  IAsyncEntity<IUnit[]>
>(
  state => state.units,
  getAllEntities
);

export const selectUnitsOrEmptyArray = createSelector<
  rootState,
  IAsyncEntity<IUnit[]>,
  IUnit[]
>(
  selectUnits,
  asyncUnits => getEntityOrDefault(asyncUnits, emptyArray)
);

export const selectChannels = createSelector<
  rootState,
  IAsyncReducerState<ISiteChannel>,
  IAsyncEntity<ISiteChannel[]>
>(
  state => state.channels,
  getAllEntities
);

export const selectChannelsOrUndefined = createSelector<
  rootState,
  IAsyncEntity<ISiteChannel[]>,
  ISiteChannel[] | undefined
>(
  selectChannels,
  getEntityOrUndefined
);

export const selectVehicles = createSelector<
  rootState,
  IAsyncReducerState<IVehicle>,
  IAsyncEntity<IVehicle[]>
>(
  state => state.vehicles,
  getAllEntities
);

export const selectLoadingPoints = createSelector<
  rootState,
  IAsyncReducerState<ILoadingPoint>,
  IAsyncEntity<ILoadingPoint[]>
>(
  state => state.loadingPoints,
  getAllEntities
);

export const selectBuids = createSelector<
  rootState,
  IAsyncReducerState<IBuid>,
  IAsyncEntity<IBuid[]>
>(
  state => state.buid,
  getAllEntities
);

export const selectHauliers = createSelector<
  rootState,
  IAsyncReducerState<IHaulier>,
  IAsyncEntity<IHaulier[]>
>(
  state => state.haulier,
  getAllEntities
);

export const selectSites = createSelector<
  rootState,
  IAsyncReducerState<ISite>,
  IAsyncEntity<ISite[]>
>(
  state => state.site,
  getAllEntities
);

export const nativeControllersForSiteSelector = createSelector<
  rootState,
  Maybe<number>,
  IControllerReducer,
  IController[]
>(
  getSiteIdParameter,
  state => state.controllers,
  (siteId, controllers) => {
    return getEntityOrDefault(
      mapFetchedAsyncEntity(
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(controllers.controllersForSites, siteId.valueOr(0)),
          controllers.byId
        ),
        cs => cs.filter(c => c.siteId === siteId.valueOr(0))
      ),
      []
    );
  }
);

export const getTimezoneForSiteSelector = createSelector<
  rootState,
  IController[],
  string
>(
  nativeControllersForSiteSelector,
  controllers => {
    const timezoneIds = removeNoneFromArray(controllers.map(c => c.timeZoneId));
    const timezoneId =
      timezoneIds !== undefined && timezoneIds.length > 0
        ? timezoneIds[0]
        : 131;

    return getTimezoneByIdOrDefault(timezoneId);
  }
);

export const selectSortedSites = createSelector<
  rootState,
  IAsyncEntity<ISite[]>,
  IAsyncEntity<ISite[]>
>(
  selectSites,
  sites =>
    mapFetchedAsyncEntity(sites, s =>
      s.sort((s1, s2) => (s1.alias > s2.alias ? 1 : -1))
    )
);

export const selectControllers = createSelector<
  rootState,
  IAsyncEntity<number[]>,
  IAsyncDictionary<IController>,
  IAsyncEntity<IController[]>
>(
  state => state.controllers.allIds,
  state => state.controllers.byId,
  getAsyncEntitiesByAsyncArray
);

export const selectUnassignedControllers = createSelector<
  rootState,
  IAsyncEntity<number[]>,
  IAsyncDictionary<IController>,
  IAsyncEntity<IController[]>
>(
  state => state.controllers.unassignedControllers,
  state => state.controllers.byId,
  getAsyncEntitiesByAsyncArray
);

export const getTimezoneByControllers = (controllers: IController[]) => {
  const timezoneIds = removeNoneFromArray(controllers.map(c => c.timeZoneId));
  return timezoneIds.length > 0
    ? getTimezoneByIdOrDefault(timezoneIds[0])
    : getDefaultTimezone();
};

export interface IAllowedColumns {
  SiteId: boolean;
  SiteName: boolean;
  CustomerName: boolean;
  ChannelId: boolean;
  Description: boolean;
  AvailableSpace: boolean;
  ParkedDescription: boolean;
  ProjectCode: boolean;
  TankPercentFull: boolean;
  SalesPerson: boolean;
  SoldTo: boolean;
  CountryCode: boolean;
  CustomerRef: boolean;
  Buid: boolean;
  ParkedSince: boolean;
  ContractNumber: boolean;
  DeviceType: boolean;
  Imei: boolean;
  IpAddress: boolean;
  LastSampleDate: boolean;
  OrderProcess: boolean;
  Postcode: boolean;
  ProductName: boolean;
  ReachMinimum: boolean;
  ReachMinimumInDays: boolean;
  SapNumber: boolean;
  SerialNumber: boolean;
  Icc: boolean;
  TankDescription: boolean;
  TankLastFilled: boolean;
  TankLevel: boolean;
  TankLevelInTonnes: boolean;
  TankUnit: boolean;
  Telephone: boolean;
  TankVehicle: boolean;
  ControllerLatitude: boolean;
  ControllerLongitude: boolean;
  Signal: boolean;
  SiteHealth: boolean;
  ShipTo: boolean;
  ControllerAlternateSerial: boolean;
  TankCapacity: boolean;
  ControllerSimProvider: boolean;
  ServiceProvider: boolean;
  AccessTagAlias: boolean
}

export const getAllowedColumns = (session: ISession): IAllowedColumns => ({
  AvailableSpace: hasAccessToColumn(session, ColumnAccess.AvailableSpace),
  Buid: hasAccessToColumn(session, ColumnAccess.Buid),
  ContractNumber: hasAccessToColumn(session, ColumnAccess.ContractNumber),
  ControllerAlternateSerial: hasAccessToColumn(
    session,
    ColumnAccess.ControllerAlternateSerial
  ),
  ControllerLatitude: hasAccessToColumn(
    session,
    ColumnAccess.ControllerLatitude
  ),
  ControllerLongitude: hasAccessToColumn(
    session,
    ColumnAccess.ControllerLongitude
  ),
  ControllerSimProvider: hasAccessToColumn(
    session,
    ColumnAccess.ControllerSimProvider
  ),
  CountryCode: hasAccessToColumn(session, ColumnAccess.CountryCode),
  ChannelId: hasAccessToColumn(session, ColumnAccess.ChannelId),
  CustomerName: hasAccessToColumn(session, ColumnAccess.CustomerName),
  CustomerRef: hasAccessToColumn(session, ColumnAccess.CustomerRef),
  Description: hasAccessToColumn(session, ColumnAccess.Description),
  DeviceType: hasAccessToColumn(session, ColumnAccess.DeviceType),
  Icc: hasAccessToColumn(session, ColumnAccess.Icc),
  Imei: hasAccessToColumn(session, ColumnAccess.Imei),
  IpAddress: hasAccessToColumn(session, ColumnAccess.IpAddress),
  LastSampleDate: hasAccessToColumn(session, ColumnAccess.LastSampleDate),
  OrderProcess: hasAccessToColumn(session, ColumnAccess.OrderProcess),
  ParkedDescription: hasAccessToColumn(session, ColumnAccess.ParkedDescription),
  ParkedSince: hasAccessToColumn(session, ColumnAccess.ParkedSince),
  Postcode: hasAccessToColumn(session, ColumnAccess.Postcode),
  ProductName: hasAccessToColumn(session, ColumnAccess.ProductName),
  ProjectCode: hasAccessToColumn(session, ColumnAccess.ProjectCode),
  ReachMinimum: hasAccessToColumn(session, ColumnAccess.ReachMinimum),
  ReachMinimumInDays: hasAccessToColumn(
    session,
    ColumnAccess.ReachMinimumInDays
  ),
  SalesPerson: hasAccessToColumn(session, ColumnAccess.SalesPerson),
  SapNumber: hasAccessToColumn(session, ColumnAccess.SapNumber),
  SerialNumber: hasAccessToColumn(session, ColumnAccess.SerialNumber),
  ServiceProvider: hasAccessToColumn(session, ColumnAccess.ServiceProvider),
  Signal: hasAccessToColumn(session, ColumnAccess.Signal),
  SiteHealth: hasAccessToColumn(session, ColumnAccess.SiteHealth),
  SiteId: hasAccessToColumn(session, ColumnAccess.SiteId),
  SiteName: hasAccessToColumn(session, ColumnAccess.SiteName),
  ShipTo: hasAccessToColumn(session, ColumnAccess.SapNumber),
  SoldTo: hasAccessToColumn(session, ColumnAccess.SoldTo),
  TankCapacity: hasAccessToColumn(session, ColumnAccess.TankCapacity),
  TankDescription: hasAccessToColumn(session, ColumnAccess.TankDescription),
  TankLastFilled: hasAccessToColumn(session, ColumnAccess.TankLastFilled),
  TankLevel: hasAccessToColumn(session, ColumnAccess.TankLevel),
  TankLevelInTonnes: hasAccessToColumn(session, ColumnAccess.TankLevelInTonnes),
  TankPercentFull: hasAccessToColumn(session, ColumnAccess.TankPercentFull),
  TankUnit: hasAccessToColumn(session, ColumnAccess.TankUnit),
  TankVehicle: hasAccessToColumn(session, ColumnAccess.TankVehicle),
  Telephone: hasAccessToColumn(session, ColumnAccess.Telephone),
  AccessTagAlias: hasAccessToColumn(session, ColumnAccess.AccessTagAlias)
});
