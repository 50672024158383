import { isNone } from './helpers';
import {
  setLocalStorageItemByUser,
  isLocalStorageAvailable
} from './localStorageHelpers';
import {
  getSessionUserId,
  getSessionClientEncryptionKey
} from '../config/sessionService';

export const mergeObjects = <T>(
  oldObject: T,
  ...newObjects: Array<Partial<T>>
) => Object.assign.apply(null, [{}, oldObject].concat(newObjects)) as T;

export const mergeObjectsWithCache = <T>(
  cacheKey: string,
  oldObject: T,
  ...newObjects: Array<Partial<T>>
) => {
  const newState = mergeObjects(oldObject, ...newObjects);

  if (isLocalStorageAvailable())
    setLocalStorageItemByUser(
      cacheKey,
      getSessionUserId(),
      newState,
      getSessionClientEncryptionKey()
    );

  return newState;
};

export const updateProperty = <T, K extends keyof T>(
  oldObject: T,
  key: K,
  property: T[K]
): T =>
  Object.assign.apply(null, [
    {},
    oldObject,
    { [key as string]: property }
  ]) as T;

export const appendObjectToArray = <T>(array: T[], objToAdd: T) => [
  ...array,
  objToAdd
];

export const prependObjectToArray = <T>(array: T[], objToAdd: T) => [
  objToAdd,
  ...array
];

export const addObjToArrayDistinct = <T>(
  array: T[],
  objToAdd: T,
  findQuery: (obj: T) => boolean
) => (array.some(findQuery) ? array : [...array, objToAdd]);

export const removeObjectFromArray = <T>(
  array: T[],
  findQueryOrType: findQueryOrType<T>
) =>
  findQueryOrType instanceof Function
    ? array.filter(item => !findQueryOrType(item))
    : array.filter(item => item !== findQueryOrType);

export const prependObjectsToArray = <T>(array: T[], objsToAdd: T[]) => [
  ...objsToAdd,
  ...array
];

type findQueryOrType<T> = T | ((obj: T) => boolean);
type functionOrType<T> = T | ((obj: T) => T);

const runFunctionOrReturnType = <T>(
  functionOrType: functionOrType<T>,
  objToFunction: T
) =>
  functionOrType instanceof Function
    ? functionOrType(objToFunction)
    : functionOrType;

export const replaceObjectInArray = <T>(
  array: T[],
  findQuery: (obj: T, index: number) => boolean,
  objToReplaceWith: functionOrType<T>
): T[] =>
  array.map((item, index) =>
    findQuery(item, index)
      ? runFunctionOrReturnType(objToReplaceWith, item)
      : item
  );

export const appendOrReplaceObjectInArray = <T>(
  array: T[],
  findQuery: (obj: T, index: number) => boolean,
  objToReplaceWith: T
): T[] => {
  let foundInArray = false;
  const returnArray = array.map((item, index) => {
    const found = findQuery(item, index);
    foundInArray = foundInArray || found;

    return found ? objToReplaceWith : item;
  });

  return foundInArray
    ? returnArray
    : appendObjectToArray(returnArray, objToReplaceWith);
};

interface IToObjectReturnType<T> {
  [id: string]: T;
}

export const toObject = <T>(t: T[], keySelector: (k: T) => string | number) =>
  t.reduce<IToObjectReturnType<T>>(
    (acc, cur) => {
      const key = keySelector(cur);
      if (isNone(key))
        throw new Error('Keyselector in toObject did not return a valid key');
      acc[key] = cur;
      return acc;
    },
    {} as IToObjectReturnType<T>
  );

export const toObjectAndMap = <T, U>(
  t: T[],
  keySelector: (k: T) => string | number,
  valueSelector: (k: T) => U
) =>
  t.reduce<IToObjectReturnType<U>>(
    (acc, cur) => {
      const key = keySelector(cur);
      if (isNone(key))
        throw new Error('Keyselector in toObject did not return a valid key');
      acc[key] = valueSelector(cur);
      return acc;
    },
    {} as IToObjectReturnType<U>
  );

export const except = <T>(source: T[], excludeItems: T[]) =>
  source.filter(item => !excludeItems.some(excludee => excludee === item));
