import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('map');

export const toggleClustering = actionCreator('toggleClustering');

export const fetchingUserPosition = actionCreator.async<
  boolean,
  { lat: number; lng: number; accuracy: number }
>('fetching_user_position');
