import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { INotifications } from '../interfaces';

export const notificationNotify = 'notifications:notify';

@autoinject
export class NotificationService {
  constructor(private eventAggregator: EventAggregator) {}

  notify(notification: INotifications) {
    this.eventAggregator.publish(notificationNotify, notification);
  }
}
