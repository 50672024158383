import { getColumns, saveColumns } from '../../actions';
import {
  isNone,
  removeNoneFromArray,
  insertAllFetchedEntities,
  getAllEntities,
  mapFetchedAsyncEntity,
  getEntityOrDefault
} from '../../utility';
import {
  IReportColumn,
  ISiteColumn,
  IServerSiteColumn,
  IAsyncReducerState
} from '../../interfaces/index';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { ColumnConfiguration } from '../../config';
import {
  createPendingEntity,
  createFailedFetchingEntity,
  IAsyncEntity
} from '../../types';
import { rootState } from '..';
import { defaultMemoize, createSelector } from 'reselect';

export interface IColumnsReducerState extends IAsyncReducerState<ISiteColumn> {
  reportDeviceTypeColumns: IReportColumn[];
  reportProductStockColumns: IReportColumn[];
  reportSiteExportColumns: IReportColumn[];
  reportSiteRefills: IReportColumn[];
}

const defaultReportDeviceTypeColumns = [
  { property: 'ControllerType', languageKey: 'ControllerType', selected: true },
  { property: 'BUID', languageKey: 'BUID', selected: true },
  { property: 'Count', languageKey: 'Count', selected: true }
];

const defaultReportProductStockColumns = [
  { property: 'Alias', languageKey: 'Alias', selected: true },
  { property: 'Customer', languageKey: 'Customer', selected: true },
  { property: 'ProductType', languageKey: 'ProductType', selected: true },
  { property: 'ShipTo', languageKey: 'ShipTo', selected: true },
  { property: 'SampleTime', languageKey: 'SampleTime', selected: true },
  { property: 'Value', languageKey: 'Value', selected: true },
  { property: 'UnitId', languageKey: 'UnitId', selected: true }
];

const defaultReportSiteExportColumns = [
  { property: 'SiteID', languageKey: 'SiteID', selected: true },
  { property: 'NameID', languageKey: 'NameID', selected: true },
  { property: 'SiteName', languageKey: 'SiteName', selected: true },
  { property: 'Alias', languageKey: 'Alias', selected: true },
  { property: 'CountryCode', languageKey: 'CountryCode', selected: true },
  { property: 'ControllerType', languageKey: 'ControllerType', selected: true },
  { property: 'IPAddress', languageKey: 'IPAddress', selected: true },
  { property: 'Telephone', languageKey: 'Telephone', selected: true },
  { property: 'Parked', languageKey: 'Parked', selected: true },
  { property: 'Lat', languageKey: 'Lat', selected: true },
  { property: 'Lng', languageKey: 'Lng', selected: true },
  { property: 'ShipTo', languageKey: 'ShipTo', selected: true },
  { property: 'Address1', languageKey: 'Address1', selected: true },
  { property: 'Address2', languageKey: 'Address2', selected: true },
  { property: 'Postcode', languageKey: 'Postcode', selected: true },
  { property: 'Address3', languageKey: 'Address3', selected: true },
  { property: 'Country', languageKey: 'Country', selected: true },
  { property: 'SIMNo', languageKey: 'SIMNo', selected: true },
  { property: 'BUID', languageKey: 'BUID', selected: true },
  { property: 'Customer', languageKey: 'Customer', selected: true },
  { property: 'SoldTo', languageKey: 'SoldTo', selected: true },
  {
    property: 'CustomerContactName',
    languageKey: 'CustomerContactName',
    selected: true
  },
  {
    property: 'CustomerContactTelephone',
    languageKey: 'CustomerContactTelephone',
    selected: true
  },
  { property: 'ChannelID', languageKey: 'ChannelID', selected: true },
  { property: 'TankID', languageKey: 'TankID', selected: true },
  { property: 'Product', languageKey: 'Product', selected: true },
  { property: 'Capacity', languageKey: 'Capacity', selected: true },
  { property: 'Maximum', languageKey: 'Maximum', selected: true },
  { property: 'Minimum', languageKey: 'Minimum', selected: true },
  {
    property: 'TankDescription',
    languageKey: 'TankDescription',
    selected: true
  }
];

const defaultReportSiteRefills = [
  { property: 'Site', languageKey: 'Site', selected: true },
  { property: 'ShipTo', languageKey: 'ShipTo', selected: true },
  { property: 'RefillStart', languageKey: 'RefillStart', selected: true },
  { property: 'Litres', languageKey: 'Litres', selected: true },
  { property: 'Product', languageKey: 'Product', selected: true }
];

const defaultState: IColumnsReducerState = {
  reportDeviceTypeColumns: defaultReportDeviceTypeColumns,
  reportProductStockColumns: defaultReportProductStockColumns,
  reportSiteExportColumns: defaultReportSiteExportColumns,
  reportSiteRefills: defaultReportSiteRefills,
  allIds: undefined,
  byId: {}
};

export const mapFromColumnIdToISiteColumn = (c: IServerSiteColumn): ISiteColumn | undefined => {
  const config = ColumnConfiguration.find(con => con.key === c.columnId);
  if (isNone(config)) return undefined;
  return {
    ...c,
    default: config.default,
    filterActive: false,
    filter: config.filter,
    languageKey: config.languageKey,
    locked: config.locked,
    property: config.property,
    propertyType: config.propertyType || 'string',
    useFilterOptions: config.useFilterOptions,
    widget: config.widget,
    width: config.width
  };
};

const mapFromColumnIdsToISiteColumns = (
  list: IServerSiteColumn[]
): ISiteColumn[] => removeNoneFromArray(list.map(mapFromColumnIdToISiteColumn));

export const mapFromColumnIdsToISiteColumnsMemoized = defaultMemoize(
  mapFromColumnIdsToISiteColumns
);

export const getAvaliableColumns = createSelector<
  rootState,
  IColumnsReducerState,
  IAsyncEntity<ISiteColumn[]>
>(
  [state => state.columns],
  columns => getAllEntities(columns)
);

export const getAvaliableColumnsOrEmptyArray = (state: rootState) =>
  getEntityOrDefault(getAvaliableColumns(state), []);

export const getSelectedColumns = createSelector<
  rootState,
  IAsyncEntity<ISiteColumn[]>,
  IAsyncEntity<ISiteColumn[]>
>(
  [getAvaliableColumns],
  columns => mapFetchedAsyncEntity(columns, cs => cs.filter(c => c.selected))
);

export const getSelectedColumnsOrEmptyArray = (state: rootState) =>
  getEntityOrDefault(getSelectedColumns(state), []);

const reducer = reducerWithInitialState(defaultState)
  .case(getColumns.started, state => ({
    ...state,
    allIds: createPendingEntity()
  }))

  .case(getColumns.failed, (state, { error }) => ({
    ...state,
    allIds: createFailedFetchingEntity(error)
  }))

  .case(getColumns.done, (state, { result }) => ({
    ...state,
    ...insertAllFetchedEntities(
      state,
      removeNoneFromArray(result.map(mapFromColumnIdToISiteColumn)),
      r => r.columnId
    )
  }))
  .case(saveColumns.started, (state, params) => ({
    ...state,
    ...insertAllFetchedEntities(
      state,
      removeNoneFromArray(params.map(mapFromColumnIdToISiteColumn)),
      r => r.columnId
    )
  }));

export default reducer;
