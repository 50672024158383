import { ICustomer } from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('customer');

export const getAllCustomers = actionCreator.async<{}, ICustomer[]>(
  'CUSTOMER_GET_ALL_CUSTOMERS'
);

export const getCustomerByToken = actionCreator.async<number, ICustomer>(
  'CUSTOMER_GET_BY_TOKEN'
);
export const getCustomerById = actionCreator.async<number, ICustomer>(
  'CUSTOMER_GET_BY_ID'
);

export const getCustomersByTokens = actionCreator.async<number[], ICustomer[]>(
  'CUSTOMERS_GET_BY_TOKENS'
);

// export const getAllAllowedCustomers = actionCreator.async<{}, ICustomer[]>("CUSTOMER_GET_ALL_ALLOWED_CUSTOMERS");

export const removeCustomers = actionCreator.async<number[], void>(
  'CUSTOMER_REMOVE_CUSTOMERS'
);
