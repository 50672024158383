import { ensureNumber } from "$lib/numberHelpers";

export interface IClaimsPair {
  type: string;
  value: string;
}

export interface IClaims {
  sub: string;
  allBuidAllowed: string;
  allCustomerAllowed: string;
  role: string;
  securityLevel: string;
  name: string;
  buidId: string;
  allowedBuids: string;
  fw: string;
  fd: string;
  fr: string;
  columns: string;
  auth_time: number;
  allowedCustomers: string;
  locale: string | undefined;
  clientEncryptionKey: string | undefined;
  onlyAccessToOneSiteId: string
  logoutUrl: string;
}

export function mapClaims(response: IClaimsPair[]): IClaims {
  const mapped: Partial<IClaims> = {};

  response.forEach(item => {
    switch (item.type) {
      case 'sub':
        mapped.sub = item.value;
        break;
      case 'allBuidAllowed':
        mapped.allBuidAllowed = item.value;
        break;
      case 'allCustomerAllowed':
        mapped.allCustomerAllowed = item.value;
        break;
      case 'role':
        mapped.role = item.value;
        break;
      case 'securityLevel':
        mapped.securityLevel = item.value;
        break;
      case 'name':
        mapped.name = item.value;
        break;
      case 'buidId':
        mapped.buidId = item.value;
        break;
      case 'allowedBuids':
        mapped.allowedBuids = item.value;
        break;
      case 'fw':
        mapped.fw = item.value;
        break;
      case 'fd':
        mapped.fd = item.value;
        break;
      case 'fr':
        mapped.fr = item.value;
        break;
      case 'columns':
        mapped.columns = item.value;
        break;
      case 'auth_time':
        mapped.auth_time = ensureNumber(item.value);
        break;
      case 'allowedCustomers':
        mapped.allowedCustomers = item.value;
        break;
      case 'locale':
        mapped.locale = item.value || undefined;
        break;
      case 'clientEncryptionKey':
        mapped.clientEncryptionKey = item.value || undefined;
        break;
      case 'onlyAccessToOneSiteId':
        mapped.onlyAccessToOneSiteId = item.value;
        break;
      case 'bff:logout_url':
        mapped.logoutUrl = item.value;
        break;
    }
  });

  return mapped as IClaims;
}


