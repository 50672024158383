import { bindable, customElement, inject, inlineView } from 'aurelia-framework';
import { DOM } from 'aurelia-pal';
import { GoolgeMapsUrl } from '../../config/endpoints';
import { IMapMarker } from '../../interfaces';
import { ensureNumber, loadScript, isEmpty, isNone } from '../../utility';
import { MarkerIconEnum } from '$typings/graphql-codegen';

@inlineView('<template></template>')
@customElement('map')
@inject(DOM.Element)
export class MapCustomElement {
  @bindable forceResize: boolean = false;

  @bindable markers: IMapMarker[];
  @bindable clustered: boolean;
  @bindable showAccuracy: boolean = true;
  @bindable zoom: number | string = 1; // Initial zoom
  @bindable autoBounds: boolean = false;
  @bindable noGoogleui: boolean;
  @bindable gestureHandling: 'cooperative' | 'greedy' | 'none' = 'cooperative';

  @bindable onClick: Function;
  @bindable initialBounds: string;
  @bindable boundsChanged: Function;
  @bindable mapClick: Function;
  @bindable deepEqualMarkers: boolean = false;
  private map: google.maps.Map;
  private mapMarkers: google.maps.Marker[];
  private mapCircles: google.maps.Circle[] = [];

  private mapIsLoaded: boolean = false;

  private markerCluster: MarkerClusterer;
  private oneMarkerZoom = 13;
  private noMarkerZoom = 2;

  constructor(private mapElement: HTMLDivElement) {}

  getInitialZoom = () =>
    isEmpty(this.markers) && !this.initialBounds
      ? this.noMarkerZoom
      : this.markers.length === 1 && !this.initialBounds
      ? this.oneMarkerZoom
      : undefined;

  getInitialCenter = () =>
    isEmpty(this.markers) && !this.initialBounds
      ? { lat: 0, lng: 0 }
      : this.markers.length === 1 && !this.initialBounds
      ? { lat: this.markers[0].lat || 0, lng: this.markers[0].lng || 0 }
      : undefined;

  async attached() {
    await this.loadMap();
    this.boundsChangedBecauseOfInternalChange = true;
    this.map = new google.maps.Map(this.mapElement, {
      mapTypeControl: true,
      disableDefaultUI: this.noGoogleui,
      center: this.getInitialCenter(),
      zoom: this.getInitialZoom(),
      gestureHandling: this.gestureHandling,
      zoomControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        }],
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT
      }
    });
    this.mapIsLoaded = true;
    this.markersChanged(this.markers, undefined, !!this.initialBounds);

    if (this.forceResize) google.maps.event.trigger(this.map, 'resize');

    if (this.initialBounds) {
      const initialCastedBounds = JSON.parse(
        this.initialBounds
      ) as google.maps.LatLngBoundsLiteral;
      this.map.setZoom(ensureNumber(this.zoom));
      this.map.fitBounds(initialCastedBounds);
    }

    this.addEventListeners();
  }

  showAccuracyChanged() {
    this.markersChanged(this.markers, undefined);
  }

  private boundsChangedBecauseOfInternalChange = false;

  // zoom_changed && center_changed && bounds_changed
  addEventListeners() {
    const zoomOrManuallyMovedChanged = () => {
      if (this.boundsChangedBecauseOfInternalChange) {
        this.boundsChangedBecauseOfInternalChange = false;
        if (this.boundsChanged)
          this.boundsChanged({ bounds: undefined, zoom: this.map.getZoom() });
        return;
      }
      const bounds = this.map.getBounds();
      if (!bounds || !this.boundsChanged) return;
      this.boundsChanged({
        bounds: JSON.stringify(bounds.toJSON()),
        zoom: this.map.getZoom()
      });
    };

    this.map.addListener('dragend', zoomOrManuallyMovedChanged);
    this.map.addListener('zoom_changed', zoomOrManuallyMovedChanged);

    this.map.addListener(
      'click',
      (e: any) =>
        !isNone(this.mapClick) && this.mapClick({ position: e.latLng.toJSON() })
    );
  }

  loadMap() {    
    return Promise.all([
      loadScript('/js/markerclusterer.min.js'),
      typeof google === "undefined" && loadScript(GoolgeMapsUrl())
    ]);
  }

  calc(markers: google.maps.Marker[], numStyles: number): ClusterIconInfo {
    const hasAlarm = markers.some(marker => marker.get('hasAlarm'));
    return hasAlarm
      ? ({
          text: markers.length.toString(),
          index: numStyles
        } as ClusterIconInfo)
      : ({ text: markers.length.toString(), index: 1 } as ClusterIconInfo);
  }

  regularMarker = "/svg/map_marker_regular.svg";
  alarmMarker = "/svg/map_marker_alarm.svg";

  getMarkerIcon(
    marker: IMapMarker
  ): string | google.maps.Icon | google.maps.Symbol | undefined {
    switch (marker.markerType) {
      case 'controller':
        return marker.markerIconEnum === MarkerIconEnum.Alarm ? this.alarmMarker : this.regularMarker;
    }

    return this.regularMarker;
  }

  mapEventListeners: google.maps.MapsEventListener[] = [];

  createMarkers(markers: IMapMarker[]) {
    return markers.map(marker => {
      const m = new google.maps.Marker({
        position: { lat: marker.lat || 0, lng: marker.lng || 0 },
        icon: this.getMarkerIcon(marker),
        optimized: false,
        title: marker.markerType === 'controller' ? marker.title : undefined
      });

      if (marker.markerType === 'controller')
        m.set('hasAlarm', marker.markerIconEnum === MarkerIconEnum.Alarm);

      if (this.onClick)
        this.mapEventListeners.push(
          m.addListener('click', () => this.onClick({ marker }))
        );

      return m;
    });
  }

  userPositionHasBeenAdded = (
    markers: IMapMarker[] | undefined,
    oldMarkers?: IMapMarker[] | undefined
  ): boolean =>
    (!oldMarkers || !oldMarkers.find(m => m.markerType === 'userposition')) &&
    (!!markers && !!markers.find(m => m.markerType === 'userposition'));

  createSaneMapBounds(markers: google.maps.Marker[]) {
    let bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => {
      const position = marker.getPosition();
      if (position) bounds.extend(position);
    });

    const minimalLat = 0.008;
    const minimalLng = 0.008;
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    if (sw.lat() - ne.lat() < minimalLat || ne.lng() - sw.lng() < minimalLng) {
      const centerPos = bounds.getCenter(); // These bounds adjustments solve bug #4393
      bounds.extend(
        new google.maps.LatLng(
          centerPos.lat() + minimalLat,
          centerPos.lng() - minimalLng,
          false
        )
      );
      bounds.extend(
        new google.maps.LatLng(
          centerPos.lat() - minimalLat,
          centerPos.lng() + minimalLng,
          false
        )
      );
    }
    return bounds;
  }

  markersChanged(
    markers: IMapMarker[],
    _oldMarkers?: IMapMarker[],
    hasInitialBounds: boolean = false
  ) {
    if (!this.mapIsLoaded) return;

    if (!isEmpty(this.mapMarkers)) this.clearMarkers();

    if (isEmpty(markers)) return;

    if (this.forceResize) google.maps.event.trigger(this.map, 'resize');

    const mapMarkers = this.createMarkers(markers);

    if (!this.clustered && this.showAccuracy) {
      this.mapCircles = this.createAccuracyCircles(markers, this.map);

      if (markers.length === 1) hasInitialBounds = true;
    }

    this.markerCluster = new MarkerClusterer(
      this.map,
      mapMarkers,
      this.getMarkerClustererOptions()
    );
    this.markerCluster.setCalculator(this.calc);
    this.boundsChangedBecauseOfInternalChange = true;
    this.setClustering();

    if (!hasInitialBounds && this.autoBounds) {
      if (mapMarkers.length > 1) {
        const bounds = this.createSaneMapBounds(mapMarkers);
        this.map.fitBounds(bounds);
      } else if (mapMarkers.length === 1) {
        const firstPosition = mapMarkers[0].getPosition();
        if (firstPosition) {
          this.map.setCenter(firstPosition);
          this.map.setZoom(this.oneMarkerZoom);
        }
      }
    }

    this.mapMarkers = mapMarkers;
  }

  createAccuracyCircles(
    markers: IMapMarker[],
    map: google.maps.Map
  ): google.maps.Circle[] {
    let circles: google.maps.Circle[] = [];

    for (const marker of markers) {
      if (marker.accuracy !== 0) {
        const circle = new google.maps.Circle({
          strokeColor: '#000044',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#000044',
          fillOpacity: 0.2,
          map: map,
          center: { lat: marker.lat, lng: marker.lng },
          radius: marker.accuracy
        });
        const circleBounds = circle.getBounds()
        //circle.bindTo('center', marker, 'position');
        if (circleBounds)
          map.fitBounds(circleBounds);
        circles.push(circle);
      }
    }

    return circles;
  }

  clusteredChanged() {
    this.setClustering();
  }

  setClustering() {
    if (!this.markerCluster) return;
    this.clustered
      ? this.markerCluster.setMaxZoom(8)
      : this.markerCluster.setMaxZoom(-1);

    this.markerCluster.repaint();
  }

  getMarkerClustererOptions(): MarkerClustererOptions {
    const clusterStyles: ClusterIconStyle[] = [
      {
        url: 'images/c2.png',
        height: 48,
        width: 48,
        anchorIcon: [0, 0],
        textColor: '#000000',
        textSize: 10,
        backgroundPosition: '0 0'
      } as ClusterIconStyle,
      {
        url: 'images/c1.png',
        height: 48,
        width: 48,
        anchorIcon: [0, 0],
        textColor: '#ffffff',
        textSize: 10,
        backgroundPosition: '0 0'
      } as ClusterIconStyle
    ];

    const mcOptions: MarkerClustererOptions = {
      maxZoom: 8,
      styles: [...clusterStyles]
    };

    return mcOptions;
  }

  setMapOnMarkers(map: google.maps.Map | null, markers: google.maps.Marker[]) {
    if (isEmpty(markers)) return;

    markers.forEach(marker => marker.setMap(map));
  }

  clearMarkers() {
    this.markerCluster.clearMarkers();
    this.setMapOnMarkers(null, this.mapMarkers);
    this.mapCircles.forEach(c => c.setMap(null));

    this.mapEventListeners.forEach(eventListener => eventListener.remove());
    this.mapEventListeners = [];
  }
}
