import { bindable, customElement } from 'aurelia-framework';
import './secondary-button.css';

@customElement('secondary-button')
export class SecondaryButton {
  @bindable disabled: boolean;
  @bindable text: string;
  @bindable processing: boolean | string | undefined;
  @bindable error: boolean | string | undefined;

  @bindable icon: string;

  @bindable iconPlacement: 'right' | 'left' | undefined;
  @bindable colortype: 'color-danger' | 'color-regular' = 'color-regular';

  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;

  @bindable size: string = 'medium';
  @bindable onClick: undefined | (() => Promise<any> | void);
}
