import {
  addPinnedSite,
  removePinnedSite,
  getPinnedSiteIds,
  unpinAllSites,
  deleteSite,
  deleteMultipleSites
} from '../../actions';
import {
  mergeObjects,
  appendObjectToArray,
  removeObjectFromArray,
  setEntity
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncEntity,
  createFetchedEntity,
  createPendingEntity
} from '../../types/index';

export interface IPinnedSitesReducerState {
  all: IAsyncEntity<number[]>;
}

const defaultState: IPinnedSitesReducerState = { all: undefined };

const reducer = reducerWithInitialState(defaultState)
  .case(deleteSite.done, (state, payload) =>
    mergeObjects(state, {
      all: setEntity(state.all, entity =>
        removeObjectFromArray(entity, payload.params.siteId)
      )
    })
  )

  .case(deleteMultipleSites.done, (state, payload) =>
    mergeObjects(state, {
      all: setEntity(state.all, entity =>
        payload.params.siteIds
          .map(id => removeObjectFromArray(entity, id))
          .reduce((prev, current) => prev.concat(current), [])
      )
    })
  )

  .case(addPinnedSite.done, (state, payload) =>
    mergeObjects(state, {
      all: setEntity(state.all, all => appendObjectToArray(all, payload.params))
    })
  )
  .case(removePinnedSite.done, (state, payload) =>
    mergeObjects(state, {
      all: setEntity(state.all, all =>
        removeObjectFromArray(all, payload.params)
      )
    })
  )
  .case(unpinAllSites.done, state =>
    mergeObjects(state, {
      all: undefined
    })
  )

  .case(getPinnedSiteIds.started, state =>
    mergeObjects(state, {
      all: createPendingEntity()
    })
  )
  .case(getPinnedSiteIds.done, (state, { result }) =>
    mergeObjects(state, {
      all: createFetchedEntity(result)
    })
  );

export default reducer;
