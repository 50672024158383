import {
  editTrendChannel,
  toggleTrendEditDialog,
  selectedChannelChanged,
  selectedOperatorChanged,
  selectedAxisTypeChanged,
  selectedAxisLocationChanged,
  axisPropertyChanged,
  trendGroupChanged,
  trendChannelAdded,
  trendChannelsUpdated,
  trendGroupAdded,
  getTrendGroups,
  toggleTrendNewGroupDialog,
  trendGroupPropertyChanged,
  trendGroupRemoved,
  navigateToRoute,
  trendChannelDataLoaded,
  setTrendChannelColor,
  exportPropertyChanged,
  exportToggleSiteChannel,
  toggleExport,
  getTrendExport,
  toggleSelectedTrendChannels,
  trendChannelsRemoved,
  exportDatePropertyChanged,
  exportToggleAllSiteChannels,
  showGapsChanged,
  showRawChanged,
  showAlarmsChanged,
  selectedVisibilityChanged,
  selectedAxisAutoScaleChanged,
  securityLevelChanged
} from '../../actions/index';
import {
  mergeObjects,
  appendObjectToArray,
  removeObjectFromArray,
  defaultRequest,
  requestLoading,
  requestFailed,
  isNone,
  distinct,
  setBeginningOfDay,
  setEndOfDay,
  routeIsActive
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  trendOperators,
  ITrendOperator,
  trendAxisLocations,
  trendAxisTypes
} from '../../utility/trendHelpers';
import {
  ITrendChannel,
  ITrendGroup,
  IRequestState
} from '../../interfaces/index';
import { IGraphExport } from '../../interfaces/entity/iGraphExport';
import { DeepPartial } from '../../types/deepPartial';
import { daysBetweenDates } from '../../utility/dateHelpers';
import { SecurityLevel } from "../../interfaces/enums/securityLevel";

export interface IDataAnalysisReducerState {
  utcFrom: Date;
  utcTo: Date;

  queryFrom: Date;
  queryTo: Date;
  operatorInterval: number;
  interval: number;
  currentRequestId: number | undefined;
  showGaps: boolean;
  showRaw: boolean;
  currentTrendGroupId: number | undefined;
  currentlyEditingTrendGroup?: ITrendGroup;
  securityLevel: SecurityLevel | undefined;

  currentlyEditingTrendIds: number[] | undefined;
  currentlyEditingTrend: DeepPartial<ITrendChannel> | undefined;
  showAllTrendChannels: boolean;

  toggleTrendNewGroupDialog: boolean;
  trendOperators: ITrendOperator[];
  trendAxisTypes: ITrendOperator[];
  trendAxisLocations: ITrendOperator[];

  graphExport?: IGraphExport;
  graphExportRequest: IRequestState;
  showExportDialog: boolean;
  selectedTrendChannels: number[];

  showAlarms: boolean;
}

/**
 * Returns beginning of today
 */
const defaultFromDate = (days?: number) => {
  const daysToSubtract = days !== undefined ? days : 7;

  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - daysToSubtract);
  setBeginningOfDay(fromDate);
  return fromDate;
};
/**
 * Returns end of today
 */
const defaultToDate = () => {
  const toDate = new Date();
  setEndOfDay(toDate);
  return toDate;
};

const getPreselectedSiteChannels = (
  state: IDataAnalysisReducerState,
  trendChannels: ITrendChannel[]
) =>
  state.selectedTrendChannels.length === 0
    ? trendChannels.map(c => c.siteChannelId)
    : distinct(
        trendChannels
          .filter(
            p =>
              !isNone(state.selectedTrendChannels.find(id => p.trendKey === id))
          )
          .map(p => p.siteChannelId)
      );

const getMinimumAllowedResolution = (utcFrom: Date, utcTo: Date) => {
  const daysBetween = daysBetweenDates(utcFrom, utcTo);
   if (!daysBetween) return 0;
   if (daysBetween > 365) return 2; // 1 day minimum resolution for ranges above 1 year
  
  return 0; //5-minute values
};

const defaultState: IDataAnalysisReducerState = {
  utcFrom: defaultFromDate(),
  utcTo: defaultToDate(),
  queryFrom: defaultFromDate(),
  queryTo: defaultToDate(),
  interval: 5,
  operatorInterval: 0,
  showGaps: false,
  showRaw: false,
  currentRequestId: undefined,
  toggleTrendNewGroupDialog: false,
  trendOperators,
  trendAxisLocations,
  trendAxisTypes,
  currentTrendGroupId: undefined,
  showAllTrendChannels: true,
  currentlyEditingTrend: undefined,
  showExportDialog: false,
  graphExportRequest: defaultRequest,
  selectedTrendChannels: [],
  currentlyEditingTrendIds: undefined,
  showAlarms: false,
  securityLevel: undefined
};

const dataAnalysisReducer = reducerWithInitialState(defaultState)
  .case(getTrendExport.started, state =>
    mergeObjects(state, {
      graphExportRequest: requestLoading()
    })
  )

  .case(getTrendExport.done, state =>
    mergeObjects(state, {
      graphExportRequest: defaultRequest,
      showExportDialog: !state.showExportDialog
    })
  )

  .case(getTrendExport.failed, (state, payload) =>
    mergeObjects(state, {
      graphExportRequest: requestFailed(payload.error)
    })
  )

  .case(toggleSelectedTrendChannels, (state, { add, ids }) =>
    mergeObjects(state, {
      selectedTrendChannels: add
        ? distinct([...state.selectedTrendChannels, ...ids])
        : state.selectedTrendChannels.filter(tc =>
            isNone(ids.find(id => id === tc))
          )
    })
  )

  .case(toggleExport, (state, payload) =>
    mergeObjects(state, {
      showExportDialog: !state.showExportDialog,
      graphExportRequest: defaultRequest,
      graphExport: {
        from: state.utcFrom,
        fromOffset: state.utcFrom.getTimezoneOffset(),
        to: state.utcTo,
        toOffset: state.utcTo.getTimezoneOffset(),
        format: 1,
        resolution: getMinimumAllowedResolution(state.utcFrom, state.utcTo),
        minimumAllowedResolution: getMinimumAllowedResolution(
          state.utcFrom,
          state.utcTo
        ),
        queryOperator: 0,
        siteChannelIds: getPreselectedSiteChannels(state, payload)
      }
    })
  )

  .case(exportPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      graphExport: mergeObjects(state.graphExport, {
        [payload.property]: payload.value
      })
    })
  )

  .case(exportDatePropertyChanged, (state, payload) => {
    let minimumAllowedResolution = 0,
      resolution = 0;

    if (payload && state && state.graphExport) {
      minimumAllowedResolution =
        payload.property === 'from'
          ? getMinimumAllowedResolution(payload.value, state.graphExport.to)
          : getMinimumAllowedResolution(state.graphExport.from, payload.value);

      resolution =
        state.graphExport.resolution >= minimumAllowedResolution
          ? state.graphExport.resolution
          : minimumAllowedResolution;
    }

    return mergeObjects(state, {
      graphExport: mergeObjects(state.graphExport, {
        [payload.property]: payload.value,
        [payload.property + 'Offset']: payload.value.getTimezoneOffset(),
        minimumAllowedResolution: minimumAllowedResolution,
        resolution: resolution
      })
    });
  })

  .case(exportToggleSiteChannel, (state, payload) =>
    mergeObjects(state, {
      graphExport: mergeObjects(state.graphExport, {
        siteChannelIds: payload.checked
          ? appendObjectToArray(
              state.graphExport ? state.graphExport.siteChannelIds || [] : [],
              payload.siteChannelId
            )
          : removeObjectFromArray(
              state.graphExport ? state.graphExport.siteChannelIds : [],
              id => id === payload.siteChannelId
            )
      })
    })
  )

  .case(exportToggleAllSiteChannels, (state, payload) =>
    mergeObjects(state, {
      graphExport: mergeObjects(state.graphExport, {
        siteChannelIds: payload.ids
      })
    })
  )

  .case(navigateToRoute, (state, payload) =>
    routeIsActive(payload, 'siteDetailsGraph')
      ? state
      : mergeObjects(state, {
          utcFrom: defaultFromDate(),
          utcTo: defaultToDate(),
          selectedTrendChannels: [],
          currentTrendGroupId: undefined,
          graphExportRequest: defaultRequest,
          showExportDialog: false,
          graphExport: undefined
        })
  )

  .case(trendGroupRemoved.started, (state, payload) =>
    mergeObjects(state, {
      currentTrendGroupId:
        payload.trendGroupId === state.currentTrendGroupId
          ? undefined
          : state.currentTrendGroupId
    })
  )

  .case(axisPropertyChanged, (state, payload) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            axis: {
              ...state.currentlyEditingTrend.axis,
              ...{ [payload.property]: payload.value }
            }
          }
        }
  )

  .case(trendGroupPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingTrendGroup: mergeObjects(
        state.currentlyEditingTrendGroup,
        {
          [payload.property]: payload.value
        }
      )
    })
  )

  .case(trendGroupChanged, (state, payload) =>
    mergeObjects(state, {
      currentTrendGroupId: payload.userTrendGroupId,
      selectedTrendChannels: []
    })
  )

  .case(
    trendChannelDataLoaded.started,
    (state, { request, localizedDateRange, internalId }) =>
      mergeObjects(state, {
        utcFrom: localizedDateRange.from,
        utcTo: localizedDateRange.to,
        queryFrom: localizedDateRange.queryFrom,
        queryTo: localizedDateRange.queryTo,
        interval: request.interval,
        operatorInterval: request.operatorInterval,
        currentRequestId: internalId
      })
  )

  .case(trendChannelDataLoaded.done, (state, { params }) =>
    state.currentRequestId !== params.internalId
      ? state
      : mergeObjects(state, {
          currentRequestId: undefined
        })
  )

  .case(trendChannelDataLoaded.failed, (state, { params }) =>
    state.currentRequestId !== params.internalId
      ? state
      : mergeObjects(state, {
          currentRequestId: undefined
        })
  )

  .case(trendChannelsRemoved.done, (state, { params }) =>
    mergeObjects(state, {
      selectedTrendChannels: state.selectedTrendChannels.filter(selectedId =>
        isNone(params.map(p => p.trendKey).find(id => id === selectedId))
      ),
      currentlyEditingTrendIds: state.currentlyEditingTrendIds
        ? state.currentlyEditingTrendIds.filter(selectedId =>
            isNone(params.map(p => p.trendKey).find(id => id === selectedId))
          )
        : undefined
    })
  )

  .case(getTrendGroups.done, (state, { result }) =>
    mergeObjects(state, {
      currentTrendGroupId: state.currentTrendGroupId
        ? state.currentTrendGroupId
        : (result.find(r => r.active) || result[0]).userTrendGroupId
    })
  )

  .case(trendGroupAdded.done, (state, { result }) =>
    mergeObjects(state, {
      currentTrendGroupId: result.userTrendGroupId,
      toggleTrendNewGroupDialog: false,
      currentlyEditingTrendGroup: undefined
    })
  )

  .case(showGapsChanged, (state, showGaps) =>
    mergeObjects(state, {
      showGaps
    })
  )
  .case(securityLevelChanged, (state, securityLevel) =>
    mergeObjects(state, {
      securityLevel
    })
  )

  .case(showRawChanged, (state, showRaw) =>
    mergeObjects(state, {
      showRaw
    })
  )

  .case(selectedChannelChanged, (state, { siteChannelId, channelId, color }) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            siteChannelId,
            channelId,
            color
          }
        }
  )

  .case(selectedOperatorChanged, (state, operatorId) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            operatorId
          }
        }
  )

  .case(selectedVisibilityChanged, (state, visible) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            visible
          }
        }
  )

  .case(selectedAxisTypeChanged, (state, payload) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            axis: {
              ...state.currentlyEditingTrend.axis,
              axisTypeId: payload
            }
          }
        }
  )

  .case(selectedAxisLocationChanged, (state, payload) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            axis: {
              ...state.currentlyEditingTrend.axis,
              locationId: payload
            }
          }
        }
  )

  .case(selectedAxisAutoScaleChanged, (state, autoScale) => 
    
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            axis: {
              ...state.currentlyEditingTrend.axis,
              autoScale,
              // Fixes #4929 by setting field to zero when disabling auto scale:
              minScale: (!autoScale && isNone(state.currentlyEditingTrend.axis?.minScale) ? 0 : state.currentlyEditingTrend.axis?.minScale),
            }
          }
    }
  )

  .case(setTrendChannelColor, (state, newColor) =>
    !state.currentlyEditingTrend
      ? state
      : {
          ...state,
          currentlyEditingTrend: {
            ...state.currentlyEditingTrend,
            color: newColor
          }
        }
  )

  .cases([trendChannelAdded.done, trendChannelsUpdated.done], state =>
    mergeObjects(state, {
      currentlyEditingTrend: undefined,
      currentlyEditingTrendIds: undefined,
      selectedTrendChannels: []
    })
  )

  .case(toggleTrendEditDialog, state =>
    mergeObjects(state, {
      currentlyEditingTrend: undefined,
      currentlyEditingTrendIds: undefined
    })
  )

  .case(toggleTrendNewGroupDialog, state =>
    mergeObjects(state, {
      toggleTrendNewGroupDialog: !state.toggleTrendNewGroupDialog
    })
  )

  .case(editTrendChannel, (state, { trendChannel, trendChannelIds }) =>
    mergeObjects(state, {
      currentlyEditingTrend: trendChannel,
      currentlyEditingTrendIds: trendChannelIds
    })
  )

  .case(showAlarmsChanged, (state, { value }) =>
    mergeObjects(state, {
      showAlarms: value
    })
  );

export default dataAnalysisReducer;
