import { bindable, customElement } from 'aurelia-framework';
import { containerless } from 'aurelia-templating';
import './card-with-statusicon.css';
import { computedFrom } from 'aurelia-binding';

export enum CardWithStatusIconStatus {
  Ok,
  Error,
  Parked
}

@containerless()
@customElement('card-with-statusicon')
export class CardWithStatusIcon {
  @bindable class: string = '';
  @bindable status: CardWithStatusIconStatus = CardWithStatusIconStatus.Ok;

  @computedFrom('status')
  get error() {
    return this.status === CardWithStatusIconStatus.Error;
  }

  @computedFrom('status')
  get ok() {
    return this.status === CardWithStatusIconStatus.Ok;
  }

  @computedFrom('status')
  get parked() {
    return this.status === CardWithStatusIconStatus.Parked;
  }
}
