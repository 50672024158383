import { bindable, observable } from 'aurelia-framework';
import { IPoint } from '../../models';
import { Line, FixedScaleAxis, IChartistLineChart } from 'chartist';
import 'chartist/dist/chartist.css';
import './thumbtrend.css';

export class Thumbtrend {
  @bindable @observable data: IPoint[];
  trend: HTMLDivElement;
  line: IChartistLineChart | undefined;

  attached() {
    this.updateTrend();
  }

  detached() {
    if (this.line) this.line.detach();
  }

  public updateTrend() {
    if (this.data === undefined || this.data.length == 0) {
      return;
    }

    if (this.trend === undefined) {
      return;
    }

    this.line = new Line(
      this.trend,
      {
        series: [this.data.map(p => p.v)]
      },
      {
        fullWidth: true,
        showPoint: false,
        showArea: true,
        lineSmooth: true,
        axisX: {
          showGrid: true,
          offset: 0,
          showLabel: false,
          divisor: this.data.length
        },
        axisY: {
          showGrid: true,
          offset: 0,
          showLabel: false,
          high: 100,
          divisor: 10,
          low: 0,
          type: FixedScaleAxis
        }
      }
    );

  }

  dataChanged() {
    this.updateTrend();
  }
}
