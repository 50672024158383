import {
  customElement,
  bindable,
  PLATFORM,
  containerless
} from 'aurelia-framework';
import { ensureNumber, isNumeric } from '../../../utility';

@containerless()
@customElement('ipaddress-input')
export default class IpaddressInput {
  @bindable value: string | number | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable error: undefined | string | string[] | boolean;
  @bindable required: boolean | undefined;

  inputmask = '#*.#*.#*.#*'; //https://github.com/beholdr/maska

  inputIsValid = () => {
    if (!this.value) return !this.required;
    const splitted = this.value.toString().split('.');
    if (splitted.length !== 4) return 'UI_Common_ValidationErrors_IPAddress';
    for (const entry of splitted) {
      if (entry.length > 3 || entry.length <= 0 || !isNumeric(entry))
        return 'UI_Common_ValidationErrors_IPAddress';
      const asNumber = ensureNumber(entry);
      if (asNumber > 255 || asNumber < 0)
        return 'UI_Common_ValidationErrors_IPAddress';
    }
    return true;
  };
}
