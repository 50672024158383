import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('sim');

export const getSimState = actionCreator.async<
  number,
  {
    enabled: boolean;
    controllerId: number;
    icc: string;
    productId: string;
    productState: string;
    msisdn: string;
    ipAddress: string;
    hasPendingActivation: boolean;
    simCardType: string;
    activationRequestCreatedAt?: string;
  }
>('getSimState');

export const activateSimCard = actionCreator.async<
  {
    controllerId: number;
    buidId: number;
    icc: string;
    simOnly: boolean;
  },
  {
    status?: number;
    simCardType: string;
    errorText?: string;
  }
>('activateSimCard');
