import { get, set } from 'idb-keyval/dist/idb-keyval-cjs-compat.min.js';
import CryptoJS, { AES } from 'crypto-js';
import { actionCreatorFactory, isType } from 'typescript-fsa';

const actionCreator = actionCreatorFactory("localsettingsworker");
export const saveSettingsAction = actionCreator.async<{ userId: number, encryptionKey: string, settings: Record<string, string>}, boolean, void>("saveSettings");
export const getSettingsAction = actionCreator.async<{ userId: number, encryptionKey: string }, Record<string, string> | undefined, void>("getSettings");

const getSettingsKey = (userId: number): string => `settings-${userId}`;

const encryptSettings = (settings: Record<string, string>, encryptionKey: string): string | undefined => {
    try {
        const serializedSettings = JSON.stringify(settings);
        return AES.encrypt(serializedSettings, encryptionKey).toString();
    } catch(err) {
        return;
    }
}

const decryptSettings = (encryptedValue: string, encryptionKey: string): string | undefined => {
    try {
        return AES.decrypt(encryptedValue, encryptionKey).toString(CryptoJS.enc.Utf8)
    } catch(err){
        return;
    }
}

const storeSettings = async (settingsToStore: Record<string, string>, userId: number, encryptionKey: string) => {
    try {
        const key = getSettingsKey(userId);
        const encryptedValue = encryptSettings(settingsToStore, encryptionKey);
        if(!encryptedValue) return;
        await set(key, encryptedValue);
    } catch(err) {
        console.error(err);
        return;
    }
}

const getCurrentUserSettings = async (userId: number, encryptionKey: string): Promise<Record<string, string> | undefined> => {
    try {
        const key = getSettingsKey(userId);
        const encryptedValue = await get<string>(key);
        if(!encryptedValue) return;
        const decryptedValue = decryptSettings(encryptedValue, encryptionKey);
        if(!decryptedValue) return undefined;
        return JSON.parse(decryptedValue);
    } catch(err){
        console.error(err);
        return;
    }
}

self.onmessage = (msg: MessageEvent) => {
    const { data } = msg;
    if(isType(data, getSettingsAction.started)) {
        const { payload } = data;
        getCurrentUserSettings(data.payload.userId, data.payload.encryptionKey).then(result => {
            (self as any).postMessage(getSettingsAction.done({ params: payload, result }));
        });
    }
    if(isType(data, saveSettingsAction.started)) {
        const { payload } = data;
        storeSettings(payload.settings, payload.userId, payload.encryptionKey).then(_result => {
            //(self as any).postMessage(getSettingsAction.done({ params: payload, result }));
        })
    }
}
