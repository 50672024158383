import {
  sendControllerCommandAction,
  fetchingControllersForSite,
  fetchingAllControllers,
  unassigedControllersSiteAttach,
  modifyPropertyOnSimCard,
  modifyPropertyOnController,
  updateController,
  getControllerTypes,
  navigateToRoute,
  fetchingUnassignedControllers,
  unassigedControllersSiteCreate,
  deleteControllers,
  fetchingController,
  fetchRemoteControllUrlForController,
  detachControllers,
  fetchingControllers,
  getAllSiteChannels,
  persistMapMarkerChanges,
  fetchingAllRemoteControllers,
  deleteSite,
  getUserAccessToController,
  cancelEditController,
  deleteMultipleSites,
  replacedControllerSerial
} from '../../actions';
import { IController, IAsyncReducerState, IRemoteUrl } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  insertAllFetchedEntities,
  insertFetchedEntity,
  getEntityOrUndefined,
  getAsyncEntity,
  updateProperty,
  markEntityAsSavingInState,
  setEntity,
  removeAsyncObjects,
  toObjectAndMap,
  mapFetchedAsyncEntity,
  mergeDoneEntitiesInDictionary,
  removeAsyncObject,
  createAsyncDictionaryForSiteRelations,
  setDoneEntityInDictionary,
  emptyObject,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  isSomething,
  removeAsyncEntityInDictionary,
  flatMap,
  removeAsyncEntitiesInDictionary,
  getAsyncEntities,
  toISOString
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createFetchedEntity,
  IAsyncEntity,
  createPendingEntity
} from '../../types';
import { ISimDetail } from '../../interfaces/entity/iSimDetail';
import { IUserTokenAndAccessRemovedAndAdded } from '../../pages/customermanager/customermanagerReducer';

export interface IControllerReducer extends IAsyncReducerState<IController> {
  modifiedController: Partial<IController>;
  modifiedSimDetails: Partial<ISimDetail>;
  controllersForSites: IAsyncDictionary<number[]>;
  nativeControllersForSites: IAsyncDictionary<number[]>;
  controllerTypes: IAsyncEntity<string[]>;
  unassignedControllers: IAsyncEntity<number[]>;
  remoteUrls: IAsyncDictionary<IRemoteUrl>;
  accessControllerUsers: IAsyncDictionary<IUserTokenAndAccessRemovedAndAdded[]>;
}

const defaultState: IControllerReducer = {
  allIds: undefined,
  byId: {},
  modifiedController: {},
  modifiedSimDetails: {},
  controllersForSites: {},
  controllerTypes: undefined,
  unassignedControllers: undefined,
  remoteUrls: {},
  nativeControllersForSites: {},
  accessControllerUsers: {}
};

const getController = (state: IControllerReducer, controllerId: number) => {
  const asyncController = state.byId[controllerId];
  return getEntityOrUndefined(asyncController);
};

const filterValuesInIAsyncDictionary = <T>(
  dictionary: IAsyncDictionary<T>,
  func: (t: T) => T
) =>
  toObjectAndMap(
    Object.keys(dictionary),
    key => key,
    key => mapFetchedAsyncEntity(getAsyncEntity(dictionary, key), func)
  );

const removeIdsFromHasManyRelations = (
  ids: number[],
  relations: IAsyncDictionary<number[]>
) =>
  filterValuesInIAsyncDictionary(relations, (relationIds: number[]) =>
    relationIds.filter(relationId => !ids.some(id => id === relationId))
  );

const addIdsToHasManyRelations = (
  relationId: number,
  entityIds: number[],
  relations: IAsyncDictionary<number[]>
) =>
  setDoneEntityInDictionary(relations, relationId, ids => [
    ...ids,
    ...entityIds
  ]);

const reducer = reducerWithInitialState(defaultState)
  .case(replacedControllerSerial, (state, payload) => ({
    ...state,
    ...removeAsyncObject(state, payload.controllerId)
  }))

  .case(deleteSite.done, (state, payload) => {
    const controllersForSite = getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(state.controllersForSites, payload.params.siteId),
        state.byId
      ),
      undefined
    );

    return isSomething(controllersForSite)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            controllersForSite.map(controller => controller.controllerId)
          ),
          {
            controllersForSites: mergeObjects(
              state.controllersForSites,
              removeAsyncEntityInDictionary(
                state.controllersForSites,
                payload.params.siteId
              )
            ),
            nativeControllersForSites: mergeObjects(
              state.nativeControllersForSites,
              removeAsyncEntityInDictionary(
                state.nativeControllersForSites,
                payload.params.siteId
              )
            )
          }
        )
      : state;
  })

  .case(deleteMultipleSites.done, (state, payload) => {
    if (payload.params.deleteControllers) return state;

    const controllerIds = flatMap(
      getEntityOrDefault(
        getAsyncEntities(payload.params.siteIds, state.controllersForSites),
        []
      ),
      x => x
    );
    const controllersForSite = getEntityOrDefault(
      getAsyncEntities(controllerIds, state.byId),
      undefined
    );

    return isSomething(controllersForSite)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            controllersForSite.map(controller => controller.controllerId)
          ),
          {
            controllersForSites: mergeObjects(
              state.controllersForSites,
              removeAsyncEntitiesInDictionary(
                state.controllersForSites,
                payload.params.siteIds
              )
            ),
            nativeControllersForSites: mergeObjects(
              state.nativeControllersForSites,
              removeAsyncEntitiesInDictionary(
                state.nativeControllersForSites,
                payload.params.siteIds
              )
            )
          }
        )
      : state;
  })

  .case(navigateToRoute, state =>
    mergeObjects(state, {
      modifiedController: emptyObject,
      modifiedSimDetails: {}
    })
  )

  .case(modifyPropertyOnSimCard, (state, { property, value }) => ({
    ...state,
    modifiedSimDetails: {
      ...state.modifiedSimDetails,
      [property]: value
    }
  }))

  .case(modifyPropertyOnController, (state, { property, value }) => ({
    ...state,
    modifiedController: {
      ...state.modifiedController,
      [property]: value
    }
  }))

  .case(cancelEditController, state => ({
    ...state,
    modifiedController: {},
    modifiedSimDetails: {}
  }))

  .case(persistMapMarkerChanges.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, c => c.controllerId)
    )
  )

  .case(getControllerTypes.done, (state, { result }) =>
    mergeObjects(state, {
      controllerTypes: createFetchedEntity(
        result.sort((a, b) =>
          a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1
        )
      )
    })
  )

  .case(fetchingControllers.started, (state, params) => ({
    ...state,
    byId: {
      ...state.byId,
      ...toObjectAndMap(params, key => key, createPendingEntity)
    }
  }))

  .case(fetchingControllers.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, c => c.controllerId)
    )
  )

  .case(getControllerTypes.started, state =>
    mergeObjects(state, { controllerTypes: createPendingEntity() })
  )

  .case(updateController.started, (state, payload) =>
    mergeObjects(state, markEntityAsSavingInState(state, payload.controllerId))
  )
  .case(updateController.done, (state, { result }) => {
    const stateWithNewEntity = mergeObjects(
      state,
      insertFetchedEntity(
        state,
        result.controller.controllerId,
        result.controller
      )
    );

    const deletedRemoved = !result.deletedController
      ? stateWithNewEntity
      : mergeObjects(
          stateWithNewEntity,
          removeAsyncObject(stateWithNewEntity, result.deletedController),
          {
            unassignedControllers: setEntity(
              stateWithNewEntity.unassignedControllers,
              ucl => ucl.filter(uc => result.deletedController !== uc)
            )
          }
        );

    return mergeObjects(deletedRemoved, {
      modifiedController: {},
      accessControllerUsers: {}
    });
  })

  .case(fetchingControllersForSite.started, (state, params) =>
    mergeObjects(state, {
      controllersForSites: mergeObjects(state.controllersForSites, {
        [params]: createPendingEntity()
      }),
      nativeControllersForSites: mergeObjects(state.nativeControllersForSites, {
        [params]: createPendingEntity()
      })
    })
  )

  .case(fetchingControllersForSite.done, (state, { result, params }) =>
    mergeObjects(
      state,
      insertFetchedEntities(
        state,
        result,
        controller => controller.controllerId,
        false
      ),
      {
        controllersForSites: mergeObjects(state.controllersForSites, {
          [params]: createFetchedEntity(result.map(r => r.controllerId))
        }),
        nativeControllersForSites: mergeObjects(
          state.nativeControllersForSites,
          {
            [params]: createFetchedEntity(
              result.filter(c => c.siteId == params).map(r => r.controllerId)
            )
          }
        )
      }
    )
  )

  .case(fetchingAllRemoteControllers.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntities(
        state,
        payload.result,
        controller => controller.controllerId
      )
    )
  )

  .case(fetchingAllControllers.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(
        state,
        result,
        controller => controller.controllerId
      ),
      {
        nativeControllersForSites: createAsyncDictionaryForSiteRelations(
          result,
          controller => controller.siteId,
          controller => controller.controllerId
        )
      }
    )
  )

  .case(getAllSiteChannels.done, (state, { result }) =>
    mergeObjects(state, {
      controllersForSites: createAsyncDictionaryForSiteRelations(
        result,
        channel => channel.siteId,
        channel => channel.controllerId
      )
    })
  )

  .case(fetchingAllControllers.started, state =>
    mergeObjects(state, {
      allIds: createPendingEntity()
    })
  )

  .case(fetchingController.done, (state, { result, params }) =>
    mergeObjects(state, insertFetchedEntity(state, params, result))
  )

  .case(fetchingController.started, (state, id) =>
    mergeObjects(state, {
      byId: mergeObjects(state.byId, { [id]: createPendingEntity() })
    })
  )

  .case(sendControllerCommandAction.done, (state, payload) => {
    if (payload.result.statusCode === 503) return state; // dont update last contact if there's a connection issue from remote to device.

    const controller = getController(state, payload.params.controllerId);
    if (!controller) return state;

    return mergeObjects(
      state,
      insertFetchedEntity(
        state,
        payload.params.controllerId,
        mergeObjects(controller, {
          lastContact: toISOString(new Date(), true)
        })
      )
    );
  })
  .case(fetchingUnassignedControllers.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(
        state,
        result,
        controller => controller.controllerId
      ),
      {
        unassignedControllers: createFetchedEntity(
          result.map(c => c.controllerId)
        )
      }
    )
  )
  .case(fetchingUnassignedControllers.started, state =>
    mergeObjects(state, {
      unassignedControllers: createPendingEntity()
    })
  )
  .case(fetchRemoteControllUrlForController.started, (state, params) =>
    mergeObjects(state, {
      remoteUrls: mergeObjects(state.remoteUrls, {
        [params]: createPendingEntity()
      })
    })
  )

  .case(fetchRemoteControllUrlForController.done, (state, { params, result }) =>
    mergeObjects(state, {
      remoteUrls: mergeObjects(state.remoteUrls, {
        [params]: createFetchedEntity(result)
      })
    })
  )
  .case(deleteControllers.done, (state, { params }) =>
    mergeObjects(state, removeAsyncObjects(state, params), {
      unassignedControllers: setEntity(state.unassignedControllers, ucl =>
        ucl.filter(uc => !params.some(id => id === uc))
      ),
      controllersForSites: removeIdsFromHasManyRelations(
        params,
        state.controllersForSites
      )
    })
  )
  .case(detachControllers.done, (state, { params }) =>
    mergeObjects(state, {
      unassignedControllers: setEntity(state.unassignedControllers, ucl => [
        ...ucl,
        ...params
      ]),
      allIds: setEntity(state.allIds, ucl =>
        ucl.filter(uc => !params.some(id => id === uc))
      ),
      controllersForSites: removeIdsFromHasManyRelations(
        params,
        state.controllersForSites
      ),
      byId: mergeDoneEntitiesInDictionary(state.byId, params, entity =>
        updateProperty(entity, 'siteId', undefined)
      )
    })
  )
  .case(unassigedControllersSiteAttach.done, (state, { result, params }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, c => c.controllerId),
      {
        unassignedControllers: setEntity(state.unassignedControllers, ucl =>
          ucl.filter(uc => !params.controllerIds.some(id => id === uc))
        ),
        controllersForSites: addIdsToHasManyRelations(
          params.siteId,
          params.controllerIds,
          state.controllersForSites
        )
      }
    )
  )
  .case(getUserAccessToController.started, (state, params) => ({
    ...state,
    accessControllerUsers: {
      ...state.accessControllerUsers,
      [params]: createPendingEntity()
    }
  }))
  .case(getUserAccessToController.done, (state, { params, result }) => ({
    ...state,
    accessControllerUsers: {
      ...state.accessControllerUsers,
      [params]: createFetchedEntity(result)
    }
  }))
  .case(unassigedControllersSiteCreate.done, (state, { result, params }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result.controllers, c => c.controllerId),
      {
        unassignedControllers: setEntity(state.unassignedControllers, ucl =>
          ucl.filter(uc => !params.controllerIds.some(id => id === uc))
        )
      }
    )
  );

export default reducer;
