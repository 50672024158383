import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('authentication');

export const forgotPassword = actionCreator.async<{}, number, string>(
  'forgotPassword'
);
export const sendInvite = actionCreator.async<number, number, string>(
  'sendInvite'
);
export const activateAccount = actionCreator.async<undefined, number, string>(
  'activateAccount'
);
export const activateAccountState= actionCreator.async<string, number, string>(
  'activateAccountState'
);
export const requestNewAccountActivation = actionCreator.async<string, boolean, string>(
  'requestNewAccountActivation'
);
export const resetPassword = actionCreator.async<undefined, number, string>(
  'resetPassword'
);
