import actionCreatorFactory from 'typescript-fsa';
import { IFilterDTO } from '../interfaces/index';

const actionCreator = actionCreatorFactory('filter');

export const getFilters = actionCreator.async<{}, IFilterDTO[]>('getFilters');

export const addFilter = actionCreator.async<IFilterDTO, IFilterDTO>(
  'addFilter'
);

export const selectFilter = actionCreator<IFilterDTO>('selectFilter');
export const selectFilterDisabled = actionCreator<IFilterDTO>(
  'selectFilterDisabled'
);
export const deleteFilter = actionCreator.async<IFilterDTO, void>(
  'deleteFilter'
);
export const updateFilter = actionCreator.async<IFilterDTO, IFilterDTO>(
  'updateFilter'
);

export const resetFilters = actionCreator('resetFilters');

export const selectFilterForEdit = actionCreator<IFilterDTO | undefined>(
  'selectFilterForEdit'
);

export const filterPropertyChanged = actionCreator<{
  property: string;
  value: string | boolean;
}>('filterPropertyChanged');

// export const trendGroupRemoved = actionCreator.async<{ siteId: number, trendGroupId: number }, void>('trendGroupRemoved');
// export const trendGroupUpdated = actionCreator.async<ITrendGroup, ITrendGroup>('trendGroupUpdated');
