import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { endpoints, requests } from '../config';
import {
  sendControllerCommandAction,
  fetchingControllerLogsAction,
  fetchingProfileForEdit,
  persistingControllerProfile,
  fetchingControllersForSite,
  fetchingAllControllers,
  unassigedControllersSiteCreate,
  unassigedControllersSiteAttach,
  getControllerParameters,
  getProfilesForController,
  getControllerTypes,
  fetchingController,
  detachControllers,
  fetchingControllers,
  fetchingAllRemoteControllers,
  fetchingProfileHistory,
  getUserAccessToController
} from '../actions';
import {
  IBuid,
  ICustomer
} from '../interfaces';
import {
  getNewId,
  formatDate,
  downloadDataAsExcel,
  emptyObject,
  getEntityOrUndefined,
  getAsyncEntity,
  getAsyncEntities,
  getAsyncEntitiesByAsyncArray,
  mapFetchedAsyncEntity,
  isNone,
} from '../utility';
import { runSingleQuery } from '../pages/common/GraphQLFetcher'
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import {
  selectControllers,
} from '../pages/common';
import { createControllerProfileHistoryKey } from '../reducers/entity/controllerProfilesReducer';
import gql from 'graphql-tag';
import { DeleteControllersMutation, DeleteControllersMutationVariables } from '../../custom_typings/graphql';
import { IFetchProfileResult } from '../interfaces/entity'

export class ControllersDeleteDialogInfo {
  canSuspendSims: boolean;
}
export interface PingStatus {
  promise: Promise<unknown> | undefined
  status: 'inflight' | 'error' | 'init' | 'success'
}

@autoinject()
export class ControllerService extends BaseService {
  constructor(protected httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ControllerService.name), taskQueue);
  }

  getAllControllerTypes = () =>
    this.httpRequestWithDispatchFromState(
      requests.controllerTypes,
      getControllerTypes,
      emptyObject,
      'Could not fetch all controller-types',
      state => state.controllers.controllerTypes
    );
    
  getControllers = (controllerIds: number[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getControllers(controllerIds),
      fetchingControllers,
      controllerIds,
      'Could not fetch controllers',
      state => getAsyncEntities(controllerIds, state.controllers.byId)
    );

  uploadControllerConfig = async (controllerId: Number, configFile: ArrayBuffer) =>       
    this.httpRequest<string>(endpoints.uploadControllerConfig(controllerId, configFile));
    
  getAllControllersAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getAllControllers(),
      fetchingAllControllers,
      emptyObject,
      'Could not fetch all controllers',
      state => getEntityOrUndefined(selectControllers(state))
    );

  getAllRemoteControllersAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getAllRemoteControllers(),
      fetchingAllRemoteControllers,
      emptyObject,
      'Could not fetch all controllers',
      state => getEntityOrUndefined(selectControllers(state))
    );

  getControllerAsync = (controllerId: number) =>
    this.httpRequestWithDispatch(
      requests.getController(controllerId),
      fetchingController,
      controllerId,
      'Could not fetch controller',
      state =>
        getEntityOrUndefined(
          getAsyncEntity(state.controllers.byId, controllerId)
        )
    );

  getController = (controllerId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getController(controllerId),
      fetchingController,
      controllerId,
      'Could not fetch controller',
      state => getAsyncEntity(state.controllers.byId, controllerId)
    );

  async getDeleteDialogInfo(controllerIds: number[]) {
    const request = requests.getControllersDeleteDialogInfo(controllerIds);
    const response = this.httpRequest<ControllersDeleteDialogInfo>(request);
    return response;
  }

  deleteControllers = async (controllerIds: number[], alsoSuspendSimCards: boolean, decommissionReason: number, decommissionReasonText: string) => {
    const result = runSingleQuery<DeleteControllersMutation, DeleteControllersMutationVariables>(
      gql`
        mutation DeleteControllersMutation($controllerIds: [Int!]!, $alsoSuspendSimCards: Boolean!, $decommissionReason: Int!, $decommissionReasonText: String) {
          deleteControllers(controllerIds: $controllerIds, alsoSuspendSimCards: $alsoSuspendSimCards, decommissionReason: $decommissionReason, decommissionReasonText: $decommissionReasonText) {
            code
            message
            success
          }
        }
      `,
      {
        controllerIds,
        alsoSuspendSimCards,
        decommissionReason,
        decommissionReasonText
      }
    );
    
    const data = await result.promise;
    if (!data.deleteControllers.success)
      throw Error(
        data.deleteControllers.message || 'Could not delete controllers'
      );
  };

  getControllersBySiteIdAsync = (siteId: number, useCache = true) =>
    this.httpRequestWithDispatch(
      requests.getControllersBySiteId(siteId),
      fetchingControllersForSite,
      siteId,
      'Could not fetch controllers for site',
      state =>
        !useCache
          ? undefined
          : getEntityOrUndefined(
              getAsyncEntitiesByAsyncArray(
                getAsyncEntity(state.controllers.controllersForSites, siteId),
                state.controllers.byId
              )
            )
    );

  getControllersBySiteId = (siteId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getControllersBySiteId(siteId),
      fetchingControllersForSite,
      siteId,
      'Could not fetch controllers for site',
      state =>
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(state.controllers.controllersForSites, siteId),
          state.controllers.byId
        )
    );


  pendingPings = new Map<number, PingStatus>();

  sendCommandToController = async (
    controllerId: number,
    slaveNumber: number | undefined,
    command: string
  ) => {

    const promise = this.httpRequestWithDispatch(
      requests.controllerCommand(controllerId, slaveNumber || 0, command),
      sendControllerCommandAction,
      { controllerId, command, internalId: getNewId(), slaveNumber },
      'controllerCommand failed'
    );

    if(command.toLocaleLowerCase().trim() == 'ping') {
      let status: PingStatus | undefined = this.pendingPings.get(controllerId)
      
      if(!status) {
        status = ({ promise, status: 'inflight' })
        this.pendingPings.set(controllerId, status)
      } else {
        status.status = 'inflight';
        status.promise = promise;
      }

      promise.then(response => {
        if(!status) return;
        status.status = response.statusCode && response.statusCode >= 200 && response.statusCode < 300 ? 'success' : 'error';
      }).catch(_ => {
        if(!status) return;
        status.status = 'error'
      });
    }

    return promise;
  }
    

  getControllerLogEntriesForControllerAsync = async (
    controllerId: number,
    fetchAll: boolean,
    useCache: boolean,
    since?: number
  ) =>
    this.httpRequestWithDispatch(
      requests.controllerLogs(controllerId, fetchAll, since),
      fetchingControllerLogsAction,
      { controllerId, fetchAll },
      'An unexpected error occurred trying to fetch the history. Try again in a while.',
      state => {
        if (!useCache) return;
        const controllerLogIds = getAsyncEntity(
          state.controllerLog.byControllerId,
          controllerId
        );
        const allFetchedControllerLogs = getAsyncEntitiesByAsyncArray(
          controllerLogIds,
          state.controllerLog.byId
        );

        if (!fetchAll)
          return getEntityOrUndefined(
            mapFetchedAsyncEntity(allFetchedControllerLogs, logs =>
              logs
                .filter(log => isNone(since) || log.controllerLogId > since)
                .slice(0, 10)
            )
          );

        return state.controllerLog.fetchedAllLogsForControllers.some(
          cid => cid === controllerId
        )
          ? getEntityOrUndefined(allFetchedControllerLogs)
          : undefined;
      }
    );

  getControllerParameters = (
    controllerId: number,
    fromController: boolean = false
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.getControllerParameters(controllerId, fromController),
      getControllerParameters,
      controllerId,
      'An unexpected error occurred trying to fetch the controller parameters. Try again in a while.',
      state =>
        fromController
          ? undefined
          : getAsyncEntitiesByAsyncArray(
              getAsyncEntity(
                state.controllerParameters.byControllerIds,
                controllerId
              ),
              state.controllerParameters.byId
            )
    );

  getControllerProfiles = (
    controllerId: number,
    fromController: boolean = false
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.getControllerProfiles(controllerId, fromController),
      getProfilesForController,
      { controllerId },
      `An unexpected error occurred trying to fetch profiles for controller: ${controllerId}. Try again in a while.`,
      state =>
        fromController
          ? undefined
          : getAsyncEntitiesByAsyncArray(
              getAsyncEntity(
                state.controllerProfiles.byControllerIds,
                controllerId
              ),
              state.controllerProfiles.byId
            )
    );

  persistControllerProfile = async (
    controllerId: number,
    controllerProfileId: number,
    profileValues: number[],
    persistingThroughExcel: boolean,
    fetchControllerHistory: boolean,
    lastControllerLogId?: number
  ) => {
    await this.httpRequestWithDispatch(
      requests.postControllerProfileValues(
        controllerId,
        controllerProfileId,
        profileValues
      ),
      persistingControllerProfile,
      { controllerProfileId, persistingThroughExcel, values: profileValues },
      'An unexpected error occurred trying to persist the controller profile. Try again in a while.'
    );

    if (!fetchControllerHistory) return;
    await this.getControllerLogEntriesForControllerAsync(
      controllerId,
      false,
      false,
      lastControllerLogId
    );
  };

  getProfileHistory = (controllerId: number, profileId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getControllerProfileHistory(controllerId, profileId),
      fetchingProfileHistory,
      profileId,
      'An error occurred trying to fetch profile history',
      state =>
        getAsyncEntity(state.controllerProfiles.profileHistory, profileId)
    );

  getControllerProfileValues = (
    controllerProfileId: number,
    controllerId: number,
    profileHistoryId?: number
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.getControllerProfileValues(
        controllerId,
        controllerProfileId,
        profileHistoryId
      ),
      fetchingProfileForEdit,
      { controllerProfileId, historyId: profileHistoryId },
      'An unexpected error occurred trying to fetch the controller profile. Try again in a while.',
      state =>
        getAsyncEntity(
          state.controllerProfiles.profileValues,
          createControllerProfileHistoryKey(
            controllerProfileId,
            profileHistoryId
          )
        )
    );

  async downloadControllerBackup(serial: string) {
    return await this.httpRequestBlob(requests.downloadControllerBackup(serial));
  }

  async downloadControllerProfileAsExcel(
    controllerId: number,
    controllerProfileId: number,
    controllerProfileHistoryId?: number
  ) {
      const result = await this.httpRequest<IFetchProfileResult>(
        requests.getControllerProfileValues(controllerId, controllerProfileId, controllerProfileHistoryId)
      );

      const exportedDateString = formatDate(result.timestamp, true, '.');
      const fileName = `ProfileExport_${result.profileName}_${exportedDateString}.xlsx`;

      await downloadDataAsExcel(
        [
          { title: 'SiteId', value: result.siteId },
          { title: 'ControllerId', value: controllerId },
          { title: 'Profile', value: result.profileName },
          { title: 'Exported', value: exportedDateString }
        ],
        ['Point', 'Value'],
        result.profileValues.map((point, index) => [index + 1, point]),
        fileName,
        { controllerId, controllerProfileId, type: 'profile', version: 1 }
      );
  }

  createNewSite = async (
    controllerIds: number[],
    buid: IBuid,
    customer: ICustomer
  ) =>
    this.httpRequestWithDispatch(
      requests.unassignedControllerCreateSite(controllerIds, buid, customer),
      unassigedControllersSiteCreate,
      { controllerIds },
      'Could not create site'
    );

  attachToSite = async (controllerIds: number[], siteId: number) =>
    this.httpRequestWithDispatch(
      requests.unassignedControllerAttachSite(controllerIds, siteId),
      unassigedControllersSiteAttach,
      { controllerIds, siteId },
      'Could not attach controller(s) to site'
    );

  detachControllers = async (controllerIds: number[]) =>
    this.httpRequestWithDispatch(
      requests.detachControllers(controllerIds),
      detachControllers,
      controllerIds,
      'Could not detach controllers'
    );

  private getUserWithAccessToControllerCacheKey = (
    controllerId: number,
    pendingControllerBuidId: number | undefined
  ) =>
    isNone(pendingControllerBuidId)
      ? controllerId.toString()
      : `${controllerId}_${pendingControllerBuidId}`;

  getUsersWithAccessToController = (
    controllerId: number,
    pendingControllerBuidId: number | undefined
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.getUsersWithAccessToController(
        controllerId,
        pendingControllerBuidId
      ),
      getUserAccessToController,
      this.getUserWithAccessToControllerCacheKey(
        controllerId,
        pendingControllerBuidId
      ),
      'Could not fetch users with access to controller',
      state =>
        getAsyncEntity(
          state.controllers.accessControllerUsers,
          this.getUserWithAccessToControllerCacheKey(
            controllerId,
            pendingControllerBuidId
          )
        )
    );
}
