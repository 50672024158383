import { customElement, bindable, autoinject } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '$pages/common';
import { ProductsQuery, ProductsQuery_products } from '$typings/graphql';
import { I18N } from 'aurelia-i18n';
import gql from 'graphql-tag';
import { getLogger } from 'aurelia-logging';

@autoinject()
@customElement('product-dropdown')
export class ProductDropdown extends GraphQLBaseViewModel<
  void,
  ProductsQuery,
  void
> {
  @bindable() selected: Array<ProductsQuery_products>;
  @bindable() itemClicked:
    | undefined
    | ((params: { value: ProductsQuery_products; text: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() valuePath: string | undefined;
  @bindable() placeholder: string;

  constructor(private i18n: I18N) {
    super(getLogger(ProductDropdown.name));
  }

  query = gql`
    query ProductsQuery {
      products {
        productId
        name
        translations {
          languageCode
          translation
        }
      }
    }
  `;

  showTranslationIfExist = (item: ProductsQuery_products) => {
    const selectedLanguage = this.i18n.getLocale();
    if(!item.translations) return item.name;

    const translation = item.translations.find(
      (t) => t.languageCode === selectedLanguage
    );
    return translation?.translation || item.name;
  };
}
