import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects, mergeEntity } from '../../utility';
import {
  IUser,
  IAlarmSubscriptions,
  IUserSettings,
  IUserProfile
} from '../../interfaces';
import {
  editUser,
  alarmSubscriptionChanged,
  editUserSettings,
  updateUserSettings,
  userSettingPropertyChanged,
  updateUser,
  userPropertyChanged,
  toggleChangePassword,
  updateSettingDismissDashboardWelcome
} from '../../actions/user';
import { getCurrentUser } from '../../actions';
import {
  IAsyncEntity,
  createPendingEntity,
  createFetchedEntity
} from '../../types';

export interface IUserProfileReducerState {
  currentlyEditingUser?: IUser;
  currentlyEditingUserSettings?: IUserSettings;
  userProfile: IAsyncEntity<IUserProfile>;

  showChangePassword: boolean;
}

const defaultState: IUserProfileReducerState = {
  userProfile: undefined,
  showChangePassword: false
};

const mapAlarmState = (
  subscriptions: IAlarmSubscriptions,
  { alarmTypeId, value }: { alarmTypeId: number; value: boolean }
): IAlarmSubscriptions => {
  switch (alarmTypeId) {
    case 2:
      return { ...subscriptions, lowLevel: value };
    case 3:
      return { ...subscriptions, preLowLevel: value };
    case 4:
      return { ...subscriptions, tankCalibration: value };
    case 5:
      return { ...subscriptions, offline: value };
    case 6:
      return { ...subscriptions, percentCapacity30: value };
    case 7:
      return { ...subscriptions, maintenance: value };
    case 8:
      return { ...subscriptions, doseCalibration: value };
    case 9:
      return { ...subscriptions, seriousDoseCalibration: value };
    case 10:
      return { ...subscriptions, brokenSensor: value };
    case 11:
      return { ...subscriptions, documentNotifications: value };
    case 12:
      return { ...subscriptions, deliveryNotes: value };  
  }
  return subscriptions;
};

const reducer = reducerWithInitialState(defaultState)
  .case(editUser, (state, payload) =>
    mergeObjects(state, { currentlyEditingUser: payload })
  )
  .case(getCurrentUser.started, state =>
    mergeObjects(state, {
      userProfile: createPendingEntity()
    })
  )
  .case(getCurrentUser.done, (state, { result }) =>
    mergeObjects(state, {
      userProfile: createFetchedEntity(result)
    })
  )
  .case(alarmSubscriptionChanged.done, (state, payload) =>
    mergeObjects(state, {
      userProfile: mergeEntity(state.userProfile, profile => ({
        ...profile,
        alarmSubscriptions: mapAlarmState(
          profile.alarmSubscriptions,
          payload.params
        )
      }))
    })
  )
  .case(editUserSettings, (state, payload) =>
    mergeObjects(state, { currentlyEditingUserSettings: payload })
  )
  .case(userSettingPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingUserSettings: mergeObjects(
        state.currentlyEditingUserSettings,
        {
          [payload.property]: payload.value
        }
      )
    })
  )
  .case(updateUserSettings.done, (state, { result }) =>
    mergeObjects(state, {
      currentlyEditingUserSettings: undefined,
      userProfile: mergeEntity(state.userProfile, profile => ({
        ...profile,
        userSettings: result
      }))
    })
  )
  .case(userPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingUser: mergeObjects(state.currentlyEditingUser, {
        [payload.property]: payload.value
      })
    })
  )
  .case(updateUser.done, (state, { result }) =>
    mergeObjects(state, {
      currentlyEditingUser: undefined,
      userProfile: mergeEntity(state.userProfile, profile => ({
        ...profile,
        user: result
      }))
    })
  )
  .case(toggleChangePassword, state =>
    mergeObjects(state, {
      showChangePassword: !state.showChangePassword
    })
  )
  .case(updateSettingDismissDashboardWelcome.done, state =>
    mergeObjects(state, {
      userProfile: mergeEntity(state.userProfile, profile => ({
        ...profile,
        userSettings: {
          ...profile.userSettings,
          hideDashboardWelcome: true
        }
      }))
    })
  );

export default reducer;
