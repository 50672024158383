import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { updateController } from '../../actions';
import { clearValidationErrors } from '../../actions/controllerEditor';

export interface IControllerEditorReducerState {
  controllerValidationErrors: Record<string, string[]> | undefined;
}

const defaultState: IControllerEditorReducerState = {
  controllerValidationErrors: undefined
};

/**
 * Note: Should multiple controller editors be open in the same context then validation messages will not work correctly (not currently a problem)
 */
const controllerEditorReducer = reducerWithInitialState(defaultState)
  .case(clearValidationErrors, state => {
    return {
      ...state,
      controllerValidationErrors: undefined
    };
  })

  .case(updateController.done, state => {
    return {
      ...state,
      controllerValidationErrors: undefined
    };
  })

  .case(updateController.failed, (state, { error }) => {
    if (typeof error === 'string') return state;
    else
      return {
        ...state,
        controllerValidationErrors: error
      };
  });

export default controllerEditorReducer;
