import { customElement, bindable } from 'aurelia-framework';
import './modal-card.css';

@customElement('modal-card')
export class ModalCard {
  @bindable size: 'flex' | 'fill' = 'flex';
  @bindable overflow: boolean = true;
  @bindable placementOnMobile: undefined | 'bottom';
  @bindable contentDirection: 'row' | undefined;
  @bindable cardClass: string | undefined;
}
