import { customAttribute, autoinject } from 'aurelia-framework';
import { ResizeObserver } from '@juggle/resize-observer';

@customAttribute('when-resized')
@autoinject()
export class WhenResized {
  value: Function;

  private observer: ResizeObserver | undefined;

  constructor(private element: Element) {
    const observerFunc: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
      const [first] = entries;
      const { contentRect } = first;
      this.value && this.value({ height: contentRect.height, width: contentRect.width })
    }
    this.observer = new ResizeObserver(observerFunc);
  }

  attached() {
    if(this.observer)
      this.observer.observe(this.element);
  }
  
  detached() {
    if(this.observer)
      this.observer.unobserve(this.element);
  }
}
