import { customAttribute, autoinject } from 'aurelia-framework';

@customAttribute('enter-press')
@autoinject
export class EnterPress {
  value: Function;
  enterPressed: (e: KeyboardEvent) => void;

  constructor(private element: Element) {
    this.enterPressed = e => {
      const code = e.which || e.keyCode;
      if (code === 13 && this.value) {
        e.preventDefault();
        this.value({ shiftKey: e.shiftKey, event: e});
      }
    };
  }

  attached() {
    this.element.addEventListener('keypress', this.enterPressed);
  }

  detached() {
    this.element.removeEventListener('keypress', this.enterPressed);
  }
}
