import { bindable, customElement } from 'aurelia-framework';
import './dropdown-list.css';

@customElement('dropdown-list')
export class DropdownList {
  @bindable onClick: () => void;

  @bindable disabled: boolean;

  @bindable alignTop: boolean;
  @bindable icon: string;

  @bindable showContent: boolean = false;
  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;

  @bindable defaultTitle: string;

  @bindable selectedTitle: string;
  @bindable error: boolean;

  @bindable inline: boolean;
  @bindable size: string;
  @bindable inverseColor: boolean = false;

  // TODO: This event.stopPropagation should be removed!
  toggleShowContent(event: Event) {
    event.stopPropagation();
    if (!!this.disabled) return;
    if (!!this.onClick) return this.onClick();

    this.showContent = !this.showContent;
  }
}
