import { NavModel } from 'aurelia-router';
import { userHasFeature, isNone } from '../utility';
import { getSession } from '../config/sessionService';

export class navFilterValueConverter {
  toView(navs: NavModel[] | undefined) {
    if (isNone(navs)) return navs;

    // const state = store.getState();
    const session = getSession();

    return navs.filter(
      a =>
        isNone(a.settings) ||
        isNone(a.settings.feature) ||
        isNone(a.settings.accesslevel) ||
        userHasFeature(session, a.settings.feature, a.settings.accesslevel)
    );
  }
}
