import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  trendGroupRemoved,
  trendGroupAdded,
  getTrendGroups
} from '../actions/index';
import { BaseService } from './baseService';
import { ITrendGroup } from '../interfaces/index';

@autoinject
export class UserTrendGroupService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(UserTrendGroupService.name), taskQueue);
  }

  getAll = async (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.getUserTrendGroups(siteId),
      getTrendGroups,
      siteId,
      'Get all user trend groups failed'
    );

  add = async (siteId: number, trendGroup: ITrendGroup) =>
    this.httpRequestWithDispatch(
      requests.addUserTrendGroup(siteId, trendGroup),
      trendGroupAdded,
      trendGroup,
      'Add user trend group failed'
    );

  remove = async (siteId: number, trendGroupId: number) =>
    this.httpRequestWithDispatch(
      requests.removeUserTrendGroup(siteId, trendGroupId),
      trendGroupRemoved,
      { siteId, trendGroupId },
      'Remove user trend group failed.'
    );

  setActive = async (siteId: number, trendGroupId: number) =>
    this.httpRequest(requests.setActiveTrendGroup(siteId, trendGroupId));
}
