import { bindable, customElement } from 'aurelia-framework';
import './action-icon.css';

@customElement('action-icon')
export class ActionIcon {
  @bindable icon: string;
  @bindable alignTop: boolean;
  @bindable alignRight: boolean;
  @bindable extraPadding: boolean = true;

  @bindable showContent: boolean = false;
  @bindable iconClicked: () => void;

  iconIsClicked(event: Event) {
    event.stopPropagation();
    if (!!this.iconClicked) return this.iconClicked();
    this.showContent = !this.showContent;
  }
}
