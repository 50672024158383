import { RouterConfiguration, NavigationInstruction } from 'aurelia-router';
import { navigateToRoute } from '../actions';
import { routes } from '../interfaces';
import { store } from '../reducers/store';
import { isNumeric } from '../utility';
import applicationInsights from './events';
import { getSession } from './sessionService';

const getRouteAndParams = ({
  params = {},
  config,
  viewPortInstructions
}: NavigationInstruction): routes => ({
  route: config.name || '',
  params,
  title: config.title,
  child:
    viewPortInstructions &&
    viewPortInstructions.default &&
    viewPortInstructions.default.childNavigationInstruction &&
    getRouteAndParams(viewPortInstructions.default.childNavigationInstruction)
});

function getPageUri() {
  const relativeUri = document.location.pathname;
  const urlParts = relativeUri.split('/');
  let pageUri = '/';
  urlParts.forEach(element => {
    if (element !== '' && element !== '/' && !isNumeric(element)) {
      pageUri += element + '/';
    }
  });
  
  return pageUri;
}

export const setupRouterWithRedux = (config: RouterConfiguration) => {
  config.addAuthorizeStep(
    () => (instruction: NavigationInstruction, next: () => {}) => {
      const session = getSession();
      if(applicationInsights && session.userIsLoggedIn) {                
        applicationInsights.trackPageView({ uri: getPageUri() });
      }
      store.dispatch(navigateToRoute(getRouteAndParams(instruction)));
      return next();
    }
  );
};
