import { customElement, bindable } from 'aurelia-framework';
import './radio-button-item.css';

@customElement('radio-button-item')
export class RadioButtonItem {
  @bindable id: string | number;
  @bindable label: string;
  @bindable selected: boolean;
  @bindable disabled: boolean;
  @bindable group: string;
}
