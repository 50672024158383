import { IEquipment, IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  insertFetchedEntity,
  removeAsyncObject,
  setDoneEntityInDictionary,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  isSomething,
  removeAsyncObjects,
  removeAsyncEntityInDictionary,
  getAsyncEntities,
  flatMap,
  removeAsyncEntitiesInDictionary
} from '../../utility';
import {
  getSiteEquipment,
  persistingNewSiteEquipment,
  updateSiteEquipment,
  deletedSiteEquipment,
  deleteSite,
  deleteMultipleSites
} from '../../actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createPendingEntity,
  createFetchedEntity
} from '../../types';

export interface IEquipmentReducerState extends IAsyncReducerState<IEquipment> {
  bySiteId: IAsyncDictionary<number[]>;
}

const defaultState: IEquipmentReducerState = {
  byId: {},
  allIds: undefined,
  bySiteId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(deleteSite.done, (state, payload) => {
    const equipmentsForSite = getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(state.bySiteId, payload.params.siteId),
        state.byId
      ),
      undefined
    );

    return isSomething(equipmentsForSite)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            equipmentsForSite.map(equipment => equipment.equipmentId)
          ),
          {
            bySiteId: mergeObjects(
              state.bySiteId,
              removeAsyncEntityInDictionary(
                state.bySiteId,
                payload.params.siteId
              )
            )
          }
        )
      : state;
  })

  .case(deleteMultipleSites.done, (state, payload) => {
    const equipmentIds = flatMap(
      getEntityOrDefault(
        getAsyncEntities(payload.params.siteIds, state.bySiteId),
        []
      ),
      x => x
    );
    const equipmentsForSite = getEntityOrDefault(
      getAsyncEntities(equipmentIds, state.byId),
      undefined
    );

    return isSomething(equipmentsForSite)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            equipmentsForSite.map(equipment => equipment.equipmentId)
          ),
          {
            bySiteId: mergeObjects(
              state.bySiteId,
              removeAsyncEntitiesInDictionary(
                state.bySiteId,
                payload.params.siteIds
              )
            )
          }
        )
      : state;
  })

  .case(getSiteEquipment.started, (state, { siteId }) => ({
    ...state,
    bySiteId: {
      ...state.bySiteId,
      [siteId]: createPendingEntity()
    }
  }))

  .case(deletedSiteEquipment.done, (state, { params }) => ({
    ...state,
    ...removeAsyncObject(state, params.equipmentId),
    bySiteId: setDoneEntityInDictionary(state.bySiteId, params.siteId, ids =>
      ids.filter(id => id !== params.equipmentId)
    )
  }))

  .case(getSiteEquipment.done, (state, { result, params }) => ({
    ...state,
    ...insertFetchedEntities(state, result, e => e.equipmentId),
    bySiteId: {
      ...state.bySiteId,
      [params.siteId]: createFetchedEntity(result.map(r => r.equipmentId))
    }
  }))
  .case(persistingNewSiteEquipment.done, (state, { result, params }) => ({
    ...state,
    bySiteId: setDoneEntityInDictionary(state.bySiteId, params, ids => [
      ...ids,
      result.equipmentId
    ])
  }))

  .cases(
    [persistingNewSiteEquipment.done, updateSiteEquipment.done],
    (state, payload) =>
      mergeObjects(
        state,
        insertFetchedEntity(state, payload.result.equipmentId, payload.result)
      )
  );

export default reducer;
