import { ISiteListSite, IApplyFilterPayload } from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('sitelist');

export const toggleSiteListVisibility = actionCreator<boolean>(
  'toggleSiteListVisibility'
);
export const selectionChanged = actionCreator<{
  site: ISiteListSite;
  value: boolean;
}>('selectionChanged');

// export const sitelistSelectionChanged = actionCreator<{ siteId: number, checked: boolean }>("sitelistSelectionChanged");

export const singleSelectionChanged = actionCreator<{ site: ISiteListSite }>(
  'singleSelectionChanged'
);
export const clearSelection = actionCreator('clearSelection');
export const toggleColumnSelector = actionCreator('toggleColumnSelector');
export const selectAllChanged = actionCreator<number[]>('selectAllChanged');
export const sortingChanged = actionCreator<{
  property: string;
  direction: string;
}>('sortingChanged');
export const scrollChanged = actionCreator<number>('scrollChanged');
export const addMobileTo = actionCreator<number>('addMobileTo');
export const applyFilter = actionCreator<IApplyFilterPayload>('applyFilter');
export const toggleSitelistMap = actionCreator('toggle_sitelist_map');
export const sitelistMapBoundsChanged = actionCreator<{
  bounds: string;
  zoom: number;
}>('sitelist_bounds_changed');

export const scrollPositionChanged = actionCreator<number>(
  'scrollPositionChanged'
);
export const searchByTerm = actionCreator<string>('searchByTerm');

export const filterIsOpen = actionCreator<boolean>('filterIsOpen');
