import CryptoJS, { AES } from 'crypto-js';
import { isSomething, isNone } from './helpers';

interface ILocalStorageItem {
  /** Use id for versioning the ciphertext contents */
  id: string;
  ciphertext: string;
}

function instanceOfIStoredItem(obj: any): obj is ILocalStorageItem {
  return isSomething(obj) && isSomething(obj.id) && isSomething(obj.ciphertext);
}

/*
 *   Get item for global context
 */
export const getLocalStorageItem = <T>(key: string, defaultValue: T): T => {
  let fetched = localStorage.getItem(key);

  if (!fetched) return defaultValue;

  return JSON.parse(fetched) as T;
};

/*
    Set item for global context
*/
export const setLocalStorageItem = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/*
 *  Get item within current user context
 */
export const getLocalStorageItemByUser = <T>(
  key: string,
  userId: number,
  defaultValue: T,
  clientEncryptionKey: string | undefined
): T => {
  const compositeKey = `${userId}_${key}`;

  const json = localStorage.getItem(compositeKey);
  if (isNone(json)) return defaultValue;

  const item = JSON.parse(json as string);
  if (instanceOfIStoredItem(item)) {
    if (isSomething(clientEncryptionKey))
      // use item.id for versioning
      try {
        return JSON.parse(
          AES.decrypt(item.ciphertext, clientEncryptionKey as string).toString(
            CryptoJS.enc.Utf8
          )
        ) as T;
      } catch (err) {}
    return defaultValue; // default value on missing or wrong key.
  }

  return item as T;
};

/*
    Set item within current user context
*/
export const setLocalStorageItemByUser = <T>(
  key: string,
  userId: number,
  value: T,
  clientEncryptionKey: string | undefined
) => {
  const compositeKey = `${userId}_${key}`;

  if (isSomething(clientEncryptionKey)) {
    const item: ILocalStorageItem = {
      id: 'base',
      ciphertext: AES.encrypt(
        JSON.stringify(value),
        clientEncryptionKey as string
      ).toString()
    };
    localStorage.setItem(compositeKey, JSON.stringify(item));
  } else localStorage.setItem(compositeKey, JSON.stringify(value)); // stores unencrypted if no key
};

// ref: https://stackoverflow.com/a/41462752/1307748

let localStorageIsAvailable = false;

export const isLocalStorageAvailable = () => localStorageIsAvailable;

export const configureLocalStorageAvailability = () => {

  try {
    const storage = window['localStorage'],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);

    localStorageIsAvailable = true;

    return localStorageIsAvailable;
  } catch (e) {
    return false;
  }
};

export type aureliaAutenticationToken = {
  access_token?: string | null;
  expires_in?: number | null;
  token_type?: string | null;
  scope?: string | null;
}

export const getAccessTokenFromLocalStorage = (): aureliaAutenticationToken | undefined => { 
  const wrapper = localStorage.getItem('aurelia_authentication') ?? undefined;
  if (isNone(wrapper) || wrapper.length === 0) {
    return undefined;
  }
  return JSON.parse(wrapper) as aureliaAutenticationToken;
}
