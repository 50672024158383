import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getAllVehicles } from '../actions';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';
import { getEntityOrUndefined } from '../utility/index';
import { selectVehicles } from '../pages/common';

@autoinject
export class VehicleService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(VehicleService.name), taskQueue);
  }

  getAllVehiclesAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getAllVehicles,
      getAllVehicles,
      {},
      'Get all vehicles failed',
      state => getEntityOrUndefined(selectVehicles(state))
    );

  getAllVehicles = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllVehicles,
      getAllVehicles,
      {},
      'Get all vehicles failed',
      state => selectVehicles(state)
    );
}
