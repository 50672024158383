import { ensureNumber } from './numberHelpers';

export type triggerOperators = '=' | '<' | '<=' | '>' | '>=';

export type resetOperators = '!=' | '>=' | '>' | '<=' | '<';

export const getResetOperator = (
  triggerOperator: triggerOperators
): resetOperators => {
  switch (triggerOperator) {
    case '=':
      return '!=';
    case '<':
      return '>=';
    case '<=':
      return '>';
    case '>':
      return '<=';
    case '>=':
      return '<';
  }
};

export const resetLimitValid = (
  limit: number,
  operator: triggerOperators,
  resetLimit: number
) => {
  const resetOperator = getResetOperator(operator);

  limit = ensureNumber(limit);
  resetLimit = ensureNumber(resetLimit);

  switch (resetOperator) {
    case '!=':
      return resetLimit != limit;
    case '>=':
      return resetLimit >= limit;
    case '>':
      return resetLimit > limit;
    case '<=':
      return resetLimit <= limit;
    case '<':
      return resetLimit < limit;
  }
};
