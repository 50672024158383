import { isNone, lastTwo } from './';
import { DateTime, IANAZone, Duration } from 'luxon';
import { IDateRange, ILocalizedDateRange } from '../interfaces';
import { isNumber, isNumeric, ensureNumber } from './numberHelpers';
import {
  getTimezoneById,
  getTimezoneByIdOrDefault
} from './timezone/timezoneHelpers';
import moment from 'moment';

export const parseHumanDate = (value: string): Date => {
  const [date, time] = value.split(' ');
  const [day, month, year] = date.split('.');
  const [hours, minutes] = time.split(':');
  return new Date(
    ensureNumber(year),
    ensureNumber(month),
    ensureNumber(day),
    ensureNumber(hours),
    ensureNumber(minutes)
  );
};

export const ensureDate = (value: Date | string | number): Date =>
  value instanceof Date ? value : new Date(value);

const zones = new Map<string, IANAZone>();
const getZoneByTimeZone = (timezone: string | number): IANAZone => {
  const ianaTimezone = isNumber(timezone)
    ? getTimezoneByIdOrDefault(timezone)
    : timezone;
  const cached = zones.get(ianaTimezone);
  if (!isNone(cached)) return cached;
  const newInstance = new IANAZone(ianaTimezone);
  zones.set(ianaTimezone, newInstance);
  return newInstance;
};

export const getTimezoneOffset = (date: Date, timezone: string) =>
  DateTime.fromJSDate(date, { zone: getZoneByTimeZone(timezone) }).offset;

export const shiftDateTimeByOffset = (
  date: Date,
  timezone: string | undefined = 'Europe/Oslo'
) => {
  const offset = getTimezoneOffset(date, timezone); // * -1;
  date.setMinutes(date.getMinutes() - offset);
};

export const convertToTimezone = (
  timezone: string | undefined,
  date: Date | string
): DateTime =>
  !isNone(timezone)
    ? DateTime.fromJSDate(ensureDate(date), {
        zone: getZoneByTimeZone(timezone)
      })
    : DateTime.fromJSDate(ensureDate(date));

export const correctForClientTimezone = (date: Date) =>
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

export const toUtcByLocalTime = (date: Date, timezone?: string) => {
  const utcDate = new Date(date.getTime());

  shiftDateTimeByOffset(utcDate, timezone);
  correctForClientTimezone(utcDate);

  return utcDate;
};

export const toISOString = (date: Date, keepOffset?: boolean) : string => {
  return moment(date).toISOString(keepOffset);
}

//JS uses the browsers timezone when creating a date, this sometimes is altering the timestamp and adding offset
//this function purley formats a date without altering it
export const pureFormatDate = (
  value: Date | string | undefined,
  withTime: boolean,
  separator: string,
): string => {
  if (!value) return '';
  
  const ensuredDate = moment(value).utcOffset(value.toString());
  const dateString =
      lastTwo(ensuredDate.date()) +
      separator +
      lastTwo(ensuredDate.month() + 1) +
      separator +
      ensuredDate.year();

    return withTime
      ? dateString +
          ' ' +
          lastTwo(ensuredDate.hours()) +
          ':' +
          lastTwo(ensuredDate.minutes())
      : dateString;
};

export const pureFormatTime = (value: Date | string) =>
  {
    const momentDate = moment(value).utcOffset(value.toString())
    return momentDate
      .format('HH:mm');
  };

 export const pureFormatDayAsNumber = (value: Date | string): number =>
   {
     const momentDate = moment(value).utcOffset(value.toString())
     return momentDate.date()
   };


export const formatDate = (
  value: Date | string | undefined,
  withTime: boolean,
  separator: string,
  timezone?: string | number | null
): string => {
  if (!value) return '';

  const ensuredDate = ensureDate(value);

  if (timezone === undefined || timezone === null) {
    const dateString =
      lastTwo(ensuredDate.getDate()) +
      separator +
      lastTwo(ensuredDate.getMonth() + 1) +
      separator +
      ensuredDate.getFullYear();

    return withTime
      ? dateString +
          ' ' +
          lastTwo(ensuredDate.getHours()) +
          ':' +
          lastTwo(ensuredDate.getMinutes())
      : dateString;
  } else {
    const dateFormat = 'dd' + separator + 'MM' + separator + 'y';
    const ianaTimezone = isNumeric(timezone)
      ? getTimezoneById(ensureNumber(timezone))
      : timezone.toString();
    const convertedDate = convertToTimezone(ianaTimezone, ensuredDate);
    return withTime
      ? convertedDate.toFormat(dateFormat + ' HH:mm')
      : convertedDate.toFormat(dateFormat);
  }
};

export const getLocalizedDateRange = (
  range: IDateRange,
  timezone?: string
): ILocalizedDateRange => {
  const queryFrom = new Date(range.from.getTime());
  const queryTo = new Date(range.to.getTime());

  shiftDateTimeByOffset(queryFrom, timezone);
  shiftDateTimeByOffset(queryTo, timezone);

  correctForClientTimezone(queryFrom);
  correctForClientTimezone(queryTo);

  return {
    from: range.from,
    to: range.to,
    queryFrom,
    queryTo
  };
};

export const toLocalTimeFromUtc = (date: Date, timezone?: string) => {
  const converted = convertToTimezone(timezone, date);
  const jsDate = converted.toJSDate();
  const totalOffset = (jsDate.getTimezoneOffset() + converted.offset) * -1;

  jsDate.setMinutes(jsDate.getMinutes() - totalOffset);

  return jsDate;
};

export const isSameDay = (
  firstDate: Date | string | undefined,
  lastDate: Date | string | undefined
) => {
  if (isNone(firstDate) || isNone(lastDate)) return false;
  const firstEnsuredDate = ensureDate(firstDate);
  const lastEnsuredDate = ensureDate(lastDate);

  return (
    firstEnsuredDate.getDate() === lastEnsuredDate.getDate() &&
    firstEnsuredDate.getMonth() === lastEnsuredDate.getMonth() &&
    firstEnsuredDate.getFullYear() === lastEnsuredDate.getFullYear()
  );
};

export const setEndOfDay = (date: Date) => {
  date.setHours(23, 59, 59, 999);
  return date;
};

export const setBeginningOfDay = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const formatShortDate = (
  value: Date | string | undefined,
  withYear: boolean,
  separator: string
) => {
  if (!value) return '';

  const ensuredDate = ensureDate(value);

  return withYear
    ? lastTwo(ensuredDate.getDate()) +
        separator +
        lastTwo(ensuredDate.getMonth() + 1) +
        separator +
        lastTwo(ensuredDate.getFullYear())
    : lastTwo(ensuredDate.getDate()) +
        separator +
        lastTwo(ensuredDate.getMonth() + 1);
};

export const formatDayAsNumber = (
  value: Date | string,
  timezone: string | undefined = undefined
) : string =>
  timezone !== undefined
    ? convertToTimezone(timezone, ensureDate(value)).toFormat('dd')
    : DateTime.fromJSDate(ensureDate(value)).toFormat('dd');
    
export const formatDateTime = (
  value: Date | string,
  format: string,
  timezone: string | undefined
) =>
  timezone !== undefined
    ? convertToTimezone(timezone, ensureDate(value)).toFormat(format)
    : DateTime.fromJSDate(ensureDate(value)).toFormat(format);

export const formatTime = (
  value: Date | string,
  timezone: string | undefined = undefined
) =>
  timezone !== undefined
    ? convertToTimezone(timezone, ensureDate(value)).toFormat('HH:mm')
    : DateTime.fromJSDate(ensureDate(value)).toFormat('HH:mm');

    
export  const getCurrentTimeAsString = () => {
  let d = new Date();
  let mins = d.getMinutes();
  let hours = d.getHours();
  return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}`;
};

export const getMonthAsLanguageText = (
  value: Date | string | number | undefined,
  timezone: string | undefined = undefined
): string | void => {
  if (!value) return;
  const ensuredDate = ensureDate(value);

  const date =
    timezone !== undefined
      ? convertToTimezone(timezone, ensuredDate)
      : DateTime.fromJSDate(ensuredDate);

  const month = date.month;
  return months[month.toString()];
};

export const months: Record<string, string> = {
  '1': 'UI_DateTime_Months_January',
  '2': 'UI_DateTime_Months_February',
  '3': 'UI_DateTime_Months_March',
  '4': 'UI_DateTime_Months_April',
  '5': 'UI_DateTime_Months_May',
  '6': 'UI_DateTime_Months_June',
  '7': 'UI_DateTime_Months_July',
  '8': 'UI_DateTime_Months_August',
  '9': 'UI_DateTime_Months_September',
  '10': 'UI_DateTime_Months_October',
  '11': 'UI_DateTime_Months_November',
  '12': 'UI_DateTime_Months_December',
}

export const shortMonths : Record<string, string> = {
  '1': 'UI_DateTime_ShortMonths_January',
  '2': 'UI_DateTime_ShortMonths_February',
  '3': 'UI_DateTime_ShortMonths_March',
  '4': 'UI_DateTime_ShortMonths_April',
  '5': 'UI_DateTime_ShortMonths_May',
  '6': 'UI_DateTime_ShortMonths_June',
  '7': 'UI_DateTime_ShortMonths_July',
  '8': 'UI_DateTime_ShortMonths_August',
  '9': 'UI_DateTime_ShortMonths_September',
  '10': 'UI_DateTime_ShortMonths_October',
  '11': 'UI_DateTime_ShortMonths_November',
  '12': 'UI_DateTime_ShortMonths_December',
}

export const getShortMonthAsLanguageText = (
  value: Date | string | number | undefined,
  timezone: string | undefined = undefined
): string | void => {
  if (!value) return;
  const ensuredDate = ensureDate(value);

  const date =
    timezone !== undefined
      ? convertToTimezone(timezone, ensuredDate)
      : DateTime.fromJSDate(ensuredDate);

  const month = date.month;

  return shortMonths[month.toString()];
};

export const dateHasPassed = (from: Date | string | undefined) =>
  isNone(from) ? undefined : ensureDate(from).getTime() < new Date().getTime();

export const daysBetweenDates = (
  from: Date | string | undefined,
  to: Date | string | undefined
) => {
  if (isNone(from) || isNone(to)) return;

  const ensuredFromDate = ensureDate(from);
  const ensuredToDate = ensureDate(to);

  const secondsBetweenDate =
    ensuredFromDate > to
      ? Math.round((ensuredFromDate.getTime() - ensuredToDate.getTime()) / 1000)
      : Math.round(
          (ensuredToDate.getTime() - ensuredFromDate.getTime()) / 1000
        );

  return Math.floor(secondsBetweenDate / 86400);
};

export const daysFromToday = (checkee: Date | string | undefined) => {
  if (isNone(checkee)) return;

  const ensuredFromDate = ensureDate(checkee);
  const ensuredToDate = new Date();

  const secondsBetweenDate = Math.round(
    (ensuredFromDate.getTime() - ensuredToDate.getTime()) / 1000
  );

  return Math.floor(secondsBetweenDate / 86400);
};

export const millisecondsBetweenDates = (firstDate: Date, secondsDate: Date) =>
  firstDate > secondsDate
    ? Math.round(firstDate.getTime() - secondsDate.getTime())
    : Math.round(secondsDate.getTime() - firstDate.getTime());

export const secondsBetweenDates = (firstDate: Date, secondsDate: Date) =>
  millisecondsBetweenDates(firstDate, secondsDate) / 1000;

export const humanReadableDurationMs = (durationInMilliSeconds: number) => {
  return humanReadableDuration(durationInMilliSeconds / 1000);
}

export const humanReadableDuration = (durationInSeconds: number) => {
  const daysBetweenDate = Math.floor(durationInSeconds / 86400);
  if (daysBetweenDate > 0)
    return { key: 'UI_DateTime_Duration_Day', count: daysBetweenDate };

  const hoursBetweenDate = Math.floor(durationInSeconds / 3600);
  if (hoursBetweenDate > 0)
    return {
      key: 'UI_DateTime_Duration_Hour',
      count: hoursBetweenDate
    };

  const minutesBetweenDate = Math.floor(durationInSeconds / 60);
  if (minutesBetweenDate > 0)
    return {
      key: 'UI_DateTime_Duration_Minute',
      count: minutesBetweenDate
    };

  return {
    key: 'UI_DateTime_Duration_Second',
    count: durationInSeconds
  };
};

export const timeBetweenDateTranslation = (
  absolute: boolean,
  from: Date | string | number | undefined,
  to: Date | string | number | undefined = new Date()
): { key: string; count: number } | undefined => {
  if (!from) return;
  const ensuredFromDate = ensureDate(from);
  const ensuredToDate = ensureDate(to);

  const prefix = absolute || ensuredFromDate > ensuredToDate ? 1 : -1;

  const secondsBetweenDate =
    ensuredFromDate > ensuredToDate
      ? Math.round((ensuredFromDate.getTime() - ensuredToDate.getTime()) / 1000)
      : Math.round(
          (ensuredToDate.getTime() - ensuredFromDate.getTime()) / 1000
        );

  const duration = humanReadableDuration(secondsBetweenDate);
  duration.count = duration.count * prefix;
  return duration;
};

export const getFirstDateInWeek = (curr: Date) =>
  new Date(curr.setDate(curr.getDate() - curr.getDay()));
export const getLastDateInWeek = (curr: Date) =>
  new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

export const subtractHoursFromNow = (hours: number) => {
  let today = new Date();
  today.setHours(today.getHours() - hours);

  return today;
};

export const modifyDateRange = (
  from: Date,
  to: Date,
  dayInMs: number
): { to: Date; from: Date } => {
  if (to < from) {
    from = new Date(to.getTime() - dayInMs * 30);
    return { from, to };
  }

  from = new Date(from.getTime() - dayInMs * 2);
  // from = new Date(Math.max(from.getTime(), new Date().getTime() - TIME.DAYINMS * 182)) // This is supposed to be appx 6 months.

  return { from, to };
};

export const datesEqual = (
  first: Date | string,
  second: Date | string,
  timezone: string | undefined = undefined
) =>
  convertToTimezone(timezone, ensureDate(first)).equals(
    convertToTimezone(timezone, ensureDate(second))
  );

export const dateGreaterThan = (
  first: Date | string,
  second: Date | string,
  timezone: string | undefined = undefined
) =>
  convertToTimezone(timezone, ensureDate(first)).valueOf() >
  convertToTimezone(timezone, ensureDate(second)).valueOf();

export const dateLesserThan = (
  first: Date | string,
  second: Date | string,
  timezone: string | undefined = undefined
) =>
  convertToTimezone(timezone, ensureDate(first)).valueOf() <
  convertToTimezone(timezone, ensureDate(second)).valueOf();

export const dateBetween = (
  isBetween: Date | string,
  first: Date | string,
  second: Date | string,
  timezone: string | undefined = undefined
) =>
  convertToTimezone(timezone, ensureDate(isBetween)).valueOf() >
    convertToTimezone(timezone, ensureDate(first)).valueOf() &&
  convertToTimezone(timezone, ensureDate(isBetween)).valueOf() <
    convertToTimezone(timezone, ensureDate(second)).valueOf();

export const durationFromMilliseconds = (milliseconds: number) =>
  Duration.fromMillis(milliseconds);

export const formattedDurationFromMilliseconds = (
  milliseconds: number,
  format?: string
) => {
  if (!format) format = 'dd:hh:mm:ss';

  const duration = durationFromMilliseconds(milliseconds);

  return duration.toFormat(format);
};

export const roundUpToSecond = (date: Date): Date =>
  (date.getMilliseconds() !== 0)
    ? new Date(date.getTime() - date.getMilliseconds() + 1000)
    : date;

export const getDate30DaysAgo = () => {
  const today = new Date();
  return setBeginningOfDay(new Date(new Date().setDate(today.getDate() - 30)));
}

export function dateStringIsValid(date: string): boolean {
  return moment(date).isValid()
}
