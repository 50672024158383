import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { fetchingUserPosition, toggleClustering } from '../../actions';
import { ILatLng } from '../../interfaces';
import { mergeObjects } from '../../utility';

export interface IMapReducerState {
  clustered: boolean;
  userPosition?: ILatLng;
}

const defaultState: IMapReducerState = {
  clustered: true
};

const reducer = reducerWithInitialState(defaultState)
  .case(toggleClustering, state =>
    mergeObjects(state, { clustered: !state.clustered })
  )
  .case(fetchingUserPosition.done, (state, payload) =>
    mergeObjects(state, { userPosition: payload.result })
  );

export default reducer;
