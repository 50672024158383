import { customElement, bindable } from 'aurelia-framework';

import './card-sticker.css';

@customElement('card-sticker')
export class CardSticker {
    @bindable() type: 'success' | 'error' | 'stale' | 'parked';
    @bindable() muted = false;
    @bindable position: 'right' | 'left' = 'left';
    @bindable size: 'small' | 'medium' | 'large' = 'medium';
    @bindable roundedCorners: boolean = false;
}