import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('pinnedSites');

export const getPinnedSiteIds = actionCreator.async<{}, number[], string>(
  'getPinnedSiteIds'
);
export const addPinnedSite = actionCreator.async<number, void, string>(
  'addPinnedSite'
);
export const removePinnedSite = actionCreator.async<number, void, string>(
  'removePinnedSite'
);
export const unpinAllSites = actionCreator.async<void, void, string>(
  'unpinAllSites'
);
