import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { fetchingUnits } from '../actions';
import { emptyObject, getEntityOrUndefined } from '../utility';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { selectUnits } from '../pages/common';

@autoinject()
export class UnitService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(UnitService.name), taskQueue);
  }

  fetchUnitsAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getAllUnits(),
      fetchingUnits,
      emptyObject,
      'Could not fetch units',
      state => getEntityOrUndefined(selectUnits(state))
    );

  fetchUnits = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllUnits(),
      fetchingUnits,
      emptyObject,
      'Could not fetch units',
      selectUnits
    );
}
