import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { navigateToRoute } from '../../actions';
import { mergeObjects } from '../../utility';
import { routes } from '../../interfaces';

export interface IRouterReducerState {
  currentRoute: routes;
  previousRoute?: routes;
}

const defaultState: IRouterReducerState = {
  currentRoute: {
    route: 'init',
    params: {}
  }
};

const reducer = reducerWithInitialState(defaultState).case(
  navigateToRoute,
  (state, action) =>
    mergeObjects(state, {
      previousRoute: state.currentRoute,
      currentRoute: action
    })
);

export default reducer;
