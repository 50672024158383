import offline from 'offline-plugin/runtime';
import { getLogger } from 'aurelia-logging';
import { Aurelia } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator'
import { getLocalStorageItem, isLocalStorageAvailable, setLocalStorageItem } from '../utility';
import { NotificationService } from '../services/notificationService';
import { NavigationInstruction, RouterEvent } from 'aurelia-router';
import { flushLanguageCache } from './i18n';

export const configureBrowserCache = (aurelia: Aurelia) => {
  

  if (ENV !== 'production') return;

  const notificationService = aurelia.container.get(NotificationService);
  const eventAggregator = aurelia.container.get(EventAggregator);
  const logger = getLogger('ServiceWorker');

  try {
    if (isLocalStorageAvailable()) {
      const isUpdated = getLocalStorageItem<boolean>("yt3_updated", false);
      if (isUpdated) {                
        notificationService.notify({
          type: 'SYSTEM-UPDATED',
          level: 'info',
          timestamp: new Date().toString(),
          acknowledged: false
        });
        setLocalStorageItem("yt3_updated", false);        
      }
    }
  } catch(e) {
    logger.error(e);
  }

  offline.install({
    onInstalled() {
      logger.debug('event onInstalled triggered');
    },
    async onUpdateFailed() {
      logger.error('Error updating service worker');
      try {
        let unregSuccess: boolean = false;
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (let registration of registrations) {
          const res = await registration.unregister();
          if (res == true) unregSuccess = true;
        }
        if (unregSuccess == true) {
          logger.error(
            'Manual unregistered serviceworker. Relaunching application.'
          );
          window.location.reload();
        }
      } catch (e) {
        logger.error('Error unregistring service worker');
        logger.error(e);
      }
    },
    onUpdateReady() {
      logger.debug('onUpdateReady');
      offline.applyUpdate();
    },
    onUpdated() {
      logger.debug('onUpdated');
      try {
        if (isLocalStorageAvailable()) {
          flushLanguageCache();
          setLocalStorageItem("yt3_updated", true);
        } 
        // Enables new service worker to take over control.
        // Because page reload happens right before navigation, the user hopefully doesn't notice it too much:
        eventAggregator.subscribe(RouterEvent.Processing, (routeConfig: {instruction: NavigationInstruction}) => {
          window.location.href = routeConfig.instruction.fragment
        })

        
      } catch (e) {
        logger.error(e);
        window.location.reload()
      }
    },
    onUpdating() {
      logger.debug('onUpdating');
    }
  });
};

