export const getPdfJs = () => 
  import('pdfjs-dist/es5/build/pdf');

export const showPdfViewer = async (containerId: string, url: string) => {
  const pdfjsLib = await getPdfJs();
            
  pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
  
  try {
    const scale = 1.5;

    // Asynchronous download of PDF
    const pdf = await pdfjsLib.getDocument(url).promise;    
    const container = document.getElementById(containerId) as HTMLElement;
    container.textContent = ''; //Clear any previous children
    container.innerHTML = '';
    

    if (!container) {
      return;
    }
      
    // Render pages
    for (let pageNumber=1; pageNumber <= pdf.numPages; pageNumber++)
    {
      let page = await pdf.getPage(pageNumber);
      
      let viewport = page.getViewport({scale: scale});

      // Prepare canvas using PDF page dimensions
      let canvas = document.createElement('canvas') as HTMLCanvasElement;
      let context = canvas.getContext('2d');

      if (!context) {
        return;
      }

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      let renderContext = {
        canvasContext: context,
        viewport: viewport
      };

      container.appendChild(canvas);      
      await page.render(renderContext).promise; //Render page     
    }

    return;
    
  } 
  catch {
    // PDF loading error
    return Promise.reject('Unable to load PDF')
  }
};
