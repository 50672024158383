import { ChannelTypes } from '../config';
import { ChannelTypes as ChannelTypesEnum } from '../interfaces';
import { isNone } from '../utility';

export class DisplayChannelTypeValueConverter {
  toView(value: ChannelTypesEnum | undefined) {
    if (isNone(value)) return '';
    return ChannelTypes[value];
  }
}
