import actionCreatorFactory from 'typescript-fsa';
import { IUser, IUserSettings } from '../interfaces/index';
import { IContact } from '../interfaces/entity/icontact';

const actionCreator = actionCreatorFactory('user');

export const getUser = actionCreator.async<number, IUser, string>('getUser');
export const getUsersByIds = actionCreator.async<number[], IUser[], string>(
  'getUsersByIds'
);
export const getContactsByIds = actionCreator.async<
  number[],
  IContact[],
  string
>('getContactsByIds');

/*   User profile   */
export const updateUser = actionCreator.async<IUser, IUser, string>(
  'updateUser'
);
export const editUser = actionCreator<IUser>('editUser');
export const userPropertyChanged = actionCreator<{
  property: string;
  value: string | number;
}>('userPropertyChanged');

export const updateUserSettings = actionCreator.async<
  IUserSettings,
  IUserSettings,
  string
>('updateUserSettings');
export const editUserSettings = actionCreator<IUserSettings | undefined>(
  'editUserSettings'
);
export const userSettingPropertyChanged = actionCreator<{
  property: string;
  value: string | number;
}>('userSettingPropertyChanged');

export const passwordPropertyChanged = actionCreator<{
  property: string;
  value: string | number;
}>('passwordPropertyChanged');

export const toggleChangePassword = actionCreator('toggleChangePassword');

/*  User settings */
export const updateSettingDismissDashboardWelcome = actionCreator.async<
  void,
  void,
  string
>('updateSettingDismissDashboardWelcome');

/* Alarm subscription */
export const alarmSubscriptionChanged = actionCreator.async<
  { alarmTypeId: number; value: boolean },
  void,
  string
>('alarmSubscriptionChanged');
