import actionCreatorFactory from 'typescript-fsa';
import {
  ITankUsage,
  TankUsageDuration,
  TankHistoryFilter,
  ISiteChannel,
  ILocalizedDateRange
} from '../interfaces';
import { IPoint } from '../models';

const actionCreator = actionCreatorFactory('sitedetailstanks');

export const fetchingTankUsage = actionCreator.async<
  number,
  ITankUsage[],
  string
>('fetching_tank_usage');
export const tankUsageFilterChanged = actionCreator<TankUsageDuration>(
  'changed_tank_usage_filter'
);
export const toggleCustomHistoryFilter = actionCreator(
  'toggle_custom_history_filter'
);
export const markTankInformationForEdit = actionCreator<ISiteChannel>(
  'mark_tank_information_for_edit'
);
export const cancelTankInformationForEdit = actionCreator(
  'cancel_tank_information_for_edit'
);

export const editSiteChannelTankDetailsProperty = actionCreator<{
  property: string;
  value: string | number;
}>('editSiteChannelTankDetailsProperty');
export const editSiteChannelProperty = actionCreator<{
  property: string;
  value: string | number;
}>('editSiteChannelProperty');

export const fetchingTankHistory = actionCreator.async<
  {
    tankId: number;
    localizedDateRange: ILocalizedDateRange;
    filter: TankHistoryFilter;
    fromTankGraph: boolean;
  },
  IPoint[]
>('fetching_tank_history');
export const tankHistoryDateRangeChanged = actionCreator<{
  from: Date;
  to: Date;
}>('tank_history_date_range_changed');

// results in a ISiteChannel which is actually a IChannel with Tank info:
export const updateTankRefillPoint = actionCreator.async<
  { siteId: number; tankChannelId: number; siteChannelId: number },
  ISiteChannel
>('tank_refill_update_point');
export const toggleRefillPointEditing = actionCreator<boolean>(
  'tank_refill_edit_toggle'
);
