import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  addChannelPropertyChanged,
  createSiteChannel,
  siteChannelSortingChanged,
  navigateToRoute,
  setChannelOrderMode,
  updateSiteChannels,
  toggleHideStaleChannels,
} from '../../actions';
import { mergeObjects } from '../../utility';
import { ISite } from '../../interfaces';
import { sortDirection } from '../../types';

export interface ISiteDetailsChannelsReducerState {
  currentlyAddingChannel?: {
    selectedSite: ISite;
    selectedChannelId: number;
    selectedSiteId: number;
  };
  pendingChannelOrders: undefined | number[];
  pendingChannelOrdersCurrentRequest: 'failed' | 'current' | undefined;
  sortDirection: sortDirection;
  sortProperty: string;
  hideStaleChannels: boolean;
}

const defaultState: ISiteDetailsChannelsReducerState = {
  sortDirection: 'asc',
  sortProperty: 'sortIndex',
  pendingChannelOrders: undefined,
  pendingChannelOrdersCurrentRequest: undefined,
  hideStaleChannels: true
};

const reducer = reducerWithInitialState(defaultState)
  .case(siteChannelSortingChanged, (state, { sortDirection, sortProperty }) =>
    mergeObjects(state, {
      sortDirection,
      sortProperty
    })
  )

  .case(navigateToRoute, state =>
    mergeObjects(state, {
      pendingChannelOrders: undefined
    })
  )

  .case(updateSiteChannels.done, state =>
    mergeObjects(state, {
      pendingChannelOrders: undefined,
      pendingChannelOrdersCurrentRequest: undefined
    })
  )

  .case(updateSiteChannels.failed, state =>
    mergeObjects(state, {
      pendingChannelOrdersCurrentRequest: state.pendingChannelOrders
        ? 'failed'
        : state.pendingChannelOrdersCurrentRequest
    })
  )

  .case(updateSiteChannels.started, state =>
    mergeObjects(state, {
      pendingChannelOrdersCurrentRequest: state.pendingChannelOrders
        ? 'current'
        : state.pendingChannelOrdersCurrentRequest
    })
  )

  .case(toggleHideStaleChannels, state => 
    mergeObjects(state, {
      hideStaleChannels: !state.hideStaleChannels        
    })
  )

  .case(setChannelOrderMode, (state, payload) =>
    mergeObjects(state, {
      pendingChannelOrders: payload
    })
  )

  .case(addChannelPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      currentlyAddingChannel: mergeObjects(state.currentlyAddingChannel, {
        [payload.property]: payload.value
      })
    })
  )

  .case(createSiteChannel.done, state =>
    mergeObjects(state, {
      currentlyAddingChannel: undefined
    })
  );

export default reducer;
