import { IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  appendObjectToArray,
  getEntityOrDefault,
  getAsyncEntity
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createFetchedEntity,
  createPendingEntity
} from '../../types';
import { IAlarmLog, IAlarmLogExtended } from '../../interfaces/entity/';
import { fetchingAlarmLogsAction } from '../../actions/alarms';
import { userAlarmDataLoaded } from '../../actions';

export interface IAlarmLogsReducer extends IAsyncReducerState<IAlarmLog> {
  byAlarmId: IAsyncDictionary<number[]>;
  fetchedAllLogsForAlarms: number[];
  bySiteAndDate: IAsyncDictionary<IAlarmLogExtended[]>;
}

const defaultState: IAlarmLogsReducer = {
  byId: {},
  allIds: undefined,
  byAlarmId: {},
  fetchedAllLogsForAlarms: [],
  bySiteAndDate: {} // todo: rename field or create new reducer for al.extended.
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingAlarmLogsAction.started, (state, params) =>
    mergeObjects(state, {
      byAlarmId: mergeObjects(state.byAlarmId, {
        [params.alarmId]: createPendingEntity()
      })
    })
  )

  .case(fetchingAlarmLogsAction.done, (state, { result, params }) =>
    mergeObjects(
      state,

      insertFetchedEntities(state, result, a => a.alarmLogId),

      {
        byAlarmId: mergeObjects(state.byAlarmId, {
          [params.alarmId]: params.fetchAll
            ? createFetchedEntity(result.map(r => r.alarmLogId))
            : createFetchedEntity([
                ...result.map(r => r.alarmLogId),
                ...getEntityOrDefault(
                  getAsyncEntity(state.byAlarmId, params.alarmId),
                  []
                )
              ])
        }),
        fetchedAllLogsForAlarms:
          params.fetchAll || result.length < 10
            ? appendObjectToArray(state.fetchedAllLogsForAlarms, params.alarmId)
            : state.fetchedAllLogsForAlarms
      }
    )
  )

  .case(userAlarmDataLoaded.started, state =>
    mergeObjects(state, {
      bySiteAndDate: mergeObjects(state.bySiteAndDate, {
        ['value']: createPendingEntity()
      })
    })
  )

  .case(userAlarmDataLoaded.done, (state, { result }) =>
    mergeObjects(state, {
      bySiteAndDate: mergeObjects(state.bySiteAndDate, {
        ['value']: createFetchedEntity(
          result.map(alarmLogExtended => ({
            ...alarmLogExtended,
            localTime: new Date(alarmLogExtended.timeStamp)
          }))
        )
      })
    })
  );

export default reducer;
