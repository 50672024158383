import { customAttribute, autoinject, bindable } from 'aurelia-framework';
import throttle from 'lodash.throttle';



@customAttribute('keep-scroll')
@autoinject
export class KeepScroll {
  @bindable({ primaryProperty: true }) shouldKeepScroll: boolean = true;
  private requested: number | undefined;
  private lastEvent: Event | undefined;
  private lastUrl: string | undefined;

  constructor(private element: Element) {
    this.onScroll = throttle(this.onScroll.bind(this), 1100);
  }

  detached() {
    this.element.removeEventListener('scroll', this.onScroll);
    this.requested && window.cancelIdleCallback(this.requested);
  }

  attached() {
    if (!this.shouldKeepScroll) return;
    this.element.addEventListener('scroll', this.onScroll);
    requestAnimationFrame(() => {
      this.resetScrollPosition();
    });
  }

  onScroll(event: Event) {
    this.lastEvent = event;
    this.lastUrl = window.location.href;

    if(this.requested) return;
    
    this.requested = window.requestIdleCallback(() => {
      this.requested = undefined;
      if(!this.lastEvent || this.lastUrl !== window.location.href) return;
      if (!this.lastEvent.target) return;
      const target = this.lastEvent.target as Element;
      const { scrollTop } = target;
      history.replaceState({ ...history.state, scrollTop }, '');
    });

  }

  resetScrollPosition() {
    if (!history.state || !history.state.scrollTop) return;
    this.element.scrollTop = history.state.scrollTop;
  }
}
