import { IServerSiteColumn } from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('columns');

export const getColumns = actionCreator.async<{}, IServerSiteColumn[], string>(
  'getColumns'
);

export const saveColumns = actionCreator.async<IServerSiteColumn[], void>(
  'saveColumns'
);
