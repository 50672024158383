import { bindable, computedFrom, customElement } from "aurelia-framework";
import './columnfilterbool.css';
import { IFilterGroup } from "$interfaces/iFilter";
import { IFilterDefinition } from "$components/grid/grid";
import { isNone } from "$lib/helpers";

@customElement('column-filter-bool')
export class ColumnFilterBool {
    @bindable() filter: IFilterGroup | undefined;
    @bindable() definition: IFilterDefinition;
    @bindable() changedFilter: Function;



    valueSelected(newValue?: boolean) {
        const newfilter: IFilterGroup = {
            ...(this.filter || { exclude: !newValue, field: this.definition.property, type: 'boolean', filters: [] }), 
            filters: isNone(newValue) ? [] : [{ value: true }],
            exclude: !newValue
        };
        this.changedFilter && this.changedFilter({ newfilter })
    }

    @computedFrom('filter')
    get selectedValue(): boolean | undefined {
        if(!this.filter || !this.filter.filters || !this.filter.filters.length) return undefined;
        return !this.filter.exclude;
    }
}