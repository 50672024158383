import { IBuid } from './../interfaces/entity';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('buid');
export const getAllBuids = actionCreator.async<{}, IBuid[]>(
  'BUID_GET_ALL_BUIDS'
);
export const getAllAllowedBuids = actionCreator.async<{}, IBuid[]>(
  'BUID_GET_ALL_ALLOWED_BUIDS'
);
export const addBuid = actionCreator<IBuid>('BUID_ADD_BUID');
