import { deviceSize } from '../types/index';
import { isNone } from './helpers';

export const getDeviceSize = (): deviceSize => {
  const screenSize = window.innerWidth;
  if (screenSize >= 992) return 'desktop';
  if (screenSize >= 768) return 'tablet';
  return 'mobile';
};

// tslint:disable-next-line:ban-types
const callbacks: Function[] = [];
let running = false;

const runCallbacks = () => {
  callbacks.forEach(callback => callback());
  running = false;
};

const resize = () => {
  if (!running) {
    running = true;
    if (window.requestAnimationFrame)
      window.requestAnimationFrame(runCallbacks);
    else setTimeout(runCallbacks, 66);
  }
};

// tslint:disable-next-line:ban-types
export const addGlobalResize = (callback: Function) => {
  if (!callbacks.length) window.addEventListener('resize', resize);
  callbacks.push(callback);
};

// tslint:disable-next-line:ban-types
export const removeGlobalResize = (callback: Function) => {
  callbacks.filter(cb => cb !== callback);
  if (!callbacks.length) window.removeEventListener('resize', resize);
};

const mediaQueryCache = new Map<string, MediaQueryList>();
export const installMediaQueryWatcher = (
  mediaQuery: string,
  layoutChangedCallback: (mediaQueryMatches: boolean) => void
) => {
  let mediaQueryList = mediaQueryCache.get(mediaQuery);
  if (isNone(mediaQueryList)) {
    mediaQueryList = window.matchMedia(mediaQuery);
    mediaQueryCache.set(mediaQuery, mediaQueryList);
  }
  const listener = (e: MediaQueryListEvent) => layoutChangedCallback(e.matches);
  mediaQueryList.addEventListener("change", listener);
  layoutChangedCallback(mediaQueryList.matches);
  return () => mediaQueryList && mediaQueryList.removeListener(listener);
};

export const isTabletWatcher = (callback: (isTablet: boolean) => void) => {
  return installMediaQueryWatcher(
    `(min-width: 768px) and (orientation: portrait) and (max-width: 991px), (min-width: 900px) and (orientation: landscape) and (max-width: 991px)`,
    callback
  );
};

export const isDesktopWatcher = (callback: (isDesktop: boolean) => void) => {
  return installMediaQueryWatcher(`(min-width: 992px)`, callback);
};

export const isMobileWatcher = (callback: (isMobile: boolean) => void) => {
  return installMediaQueryWatcher(
    `(max-width: 767px) and (orientation: portrait), (max-width: 899px) and (orientation: landscape)`,
    callback
  );
};

export const isTouchDeviceWatcher = (
  callback: (isTouchDevice: boolean) => void
) => {
  return installMediaQueryWatcher(`(hover: none)`, callback);
};
