import { customElement, bindable } from 'aurelia-framework';
import './tool-bar-item.css';

@customElement('tool-bar-item')
export class ToolBarItem {
  @bindable active: boolean;
  @bindable iconName: string;
  @bindable text: string;
  @bindable disabled: boolean;
  @bindable align: 'left' | 'right' | 'center' = 'left';
}
