import {
  applyFilter,
  getFilters,
  addFilter,
  selectFilter,
  selectFilterForEdit,
  deleteFilter,
  updateFilter,
  filterPropertyChanged,
  selectFilterDisabled,
  resetFilters,
  navigateToRoute,
  loadSavedUserState,
  saveColumns
} from '../../actions';
import {
  mergeObjects,
  appendObjectToArray,
  defaultRequest,
  requestLoading,
  requestFailed,
  requestLoaded,
  removeObjectFromArray,
  replaceObjectInArray,
  getLocalStorageItemByUser,
  mergeObjectsWithCache,
  removeNoneFromArray
} from '../../utility';
import {
  IFilterGroup,
  IApplyFilterPayload,
  IFilterDTO,
  IRequestState,
  IRoute
} from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  getSessionUserId,
  getSessionClientEncryptionKey
} from '../../config/sessionService';
import { mapFromColumnIdToISiteColumn } from './columnsReducer';
import { quickFilterNames } from '../../utility/siteListHelpers';

export interface IFilterReducerState {
  filters: Array<IFilterGroup>;
  all: IFilterDTO[];
  request: IRequestState;

  current?: IFilterDTO;
  disabledFilter?: IFilterDTO;
  currentlyEditing?: IFilterDTO;

  dirty: boolean;

  createRequest: IRequestState;
}

const defaultFilters = [
  {
    type: 'boolean',
    field: 'isParked',
    exclude: true,
    filters: [
      {
        value: true
      }
    ]
  },
  {
    type: 'boolean',
    field: 'isVirtual',
    exclude: true,
    filters: [
      {
        value: true
      }
    ]
  }
] as IFilterGroup[];

const storageKey = 'filters';

const defaultState: IFilterReducerState = {
  filters: defaultFilters,
  all: [],

  dirty: false,
  request: defaultRequest,
  createRequest: defaultRequest
};

let getApplyFilterState = (
  state: IFilterReducerState,
  payload: IApplyFilterPayload
) => {
  let filters = Object.assign([], state.filters) as Array<IFilterGroup>;

  let index = filters.findIndex(group => group.field === payload.field);

  if (index > -1) {
    let group = filters[index];

    if (payload.filters.length > 0) {
      group.filters = payload.filters;
      group.exclude = payload.exclude;
    } else {
      filters.splice(index, 1);
    }
  } else {
    if (payload.filters.length > 0) {
      let group: IFilterGroup = {
        field: payload.field,
        filters: payload.filters,
        type: payload.filterType,
        exclude: payload.exclude
      };

      filters.push(group);
    }
  }

  return mergeObjectsWithCache(storageKey, state, {
    filters,
    dirty: true,
    current: undefined
  });
};

const predefinedFilters = {
  isOffline: [
    {
      type: 'boolean',
      field: 'isOffline',
      exclude: false,
      filters: [
        {
          value: true
        }
      ]
    },
    {
      type: 'boolean',
      field: 'isVirtual',
      exclude: true,
      filters: [
        {
          value: true
        }
      ]
    }
  ],
  isBelowMinimum: [
    {
      type: 'boolean',
      field: 'isBelowMinimum',
      exclude: false,
      filters: [
        {
          value: true
        }
      ]
    }
  ],
  hasAlarm: [
    {
      type: 'boolean',
      field: 'hasAlarm',
      exclude: false,
      filters: [
        {
          value: true
        }
      ]
    }
  ],
  hasCalibrationAlarm: [
    {
      type: 'boolean',
      field: 'hasCalibrationAlarm',
      exclude: false,
      filters: [
        {
          value: true
        }
      ]
    }
  ],
  online: [
    {
      type: 'boolean',
      field: 'hasCalibrationAlarm',
      exclude: true,
      filters: [
        {
          value: true
        }
      ]
    },
    {
      type: 'boolean',
      field: 'hasAlarm',
      exclude: true,
      filters: [
        {
          value: true
        }
      ]
    },
    {
      type: 'boolean',
      field: 'isOffline',
      exclude: true,
      filters: [
        {
          value: true
        }
      ]
    },
    {
      type: 'boolean',
      field: 'isVirtual',
      exclude: true,
      filters: [
        {
          value: true
        }
      ]
    }
  ]
};

const assignPredefinedFilter = (state: IFilterReducerState, route: IRoute) => {
  switch (route.params.childRoute) {
    case 'offline':
      return mergeObjects(state, {
        filters: predefinedFilters.isOffline,
        dirty: true
      });
    case 'below-minimum':
      return mergeObjects(state, {
        filters: predefinedFilters.isBelowMinimum,
        dirty: true
      });
    case 'alarm':
      return mergeObjects(state, {
        filters: predefinedFilters.hasAlarm,
        dirty: true
      });
    case 'calibration-alarm':
      return mergeObjects(state, {
        filters: predefinedFilters.hasCalibrationAlarm,
        dirty: true
      });
    case 'online':
      return mergeObjects(state, {
        filters: predefinedFilters.online,
        dirty: true
      });
  }

  return state;
};

const reducer = reducerWithInitialState(defaultState)
  .case(resetFilters, state =>
    mergeObjectsWithCache(storageKey, state, {
      filters: defaultFilters,
      dirty: false,
      current: undefined
    })
  )

  .case(navigateToRoute, (state, payload) =>
    assignPredefinedFilter(state, payload)
  )

  .case(applyFilter, (state, payload) => getApplyFilterState(state, payload))

  .case(saveColumns.started, (state, columns) =>
    mergeObjectsWithCache(storageKey, state, {
      filters: state.filters.filter(
        filter =>
          quickFilterNames.some(name => name === filter.field) ||
          removeNoneFromArray(columns.map(mapFromColumnIdToISiteColumn)).some(
            column => column.property === filter.field
          )
      )
    })
  )
  .case(getFilters.started, state =>
    mergeObjects(state, {
      request: requestLoading()
    })
  )

  .case(getFilters.done, (state, payload) =>
    mergeObjects(state, {
      all: payload.result,
      request: requestLoaded
    })
  )

  .case(getFilters.failed, (state, payload) =>
    mergeObjects(state, {
      request: requestFailed(payload.error.message)
    })
  )

  .case(addFilter.started, state =>
    mergeObjects(state, {
      createRequest: requestLoading()
    })
  )

  .case(addFilter.done, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, {
      all: appendObjectToArray(state.all, payload.result),
      current: payload.result,
      disabledFilter: undefined,
      currentlyEditing: undefined,
      createRequest: requestLoaded
    })
  )

  .case(deleteFilter.done, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, {
      all: removeObjectFromArray(
        state.all,
        item => item.filterId === payload.params.filterId
      ),
      current:
        state.current && state.current.filterId === payload.params.filterId
          ? undefined
          : state.current
    })
  )

  .case(updateFilter.done, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, {
      all: replaceObjectInArray(
        state.all,
        item => item.filterId == payload.params.filterId,
        payload.result
      ),
      current: payload.result,
      currentlyEditing: undefined,
      dirty: false
    })
  )

  .case(selectFilter, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, {
      current: payload,
      filters: payload
        ? (JSON.parse(payload.definition) as IFilterGroup[])
        : [],
      disabledFilter: undefined,
      dirty: false
    })
  )

  .case(selectFilterDisabled, (state, payload) =>
    mergeObjects(state, {
      disabledFilter: payload,
      current: undefined,
      filters: [],
      dirty: false
    })
  )

  .case(selectFilterForEdit, (state, payload) =>
    mergeObjects(state, {
      currentlyEditing: payload
    })
  )

  .case(filterPropertyChanged, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, {
      currentlyEditing: mergeObjects(state.currentlyEditing, {
        [payload.property]: payload.value
      })
    })
  )

  .case(loadSavedUserState, state =>
    mergeObjects(
      state,
      getLocalStorageItemByUser(
        storageKey,
        getSessionUserId(),
        defaultState,
        getSessionClientEncryptionKey()
      ),
      {
        // add defaults here if needed.
      }
    )
  );

export default reducer;
