import { isNumeric } from './numberHelpers';

export const validateEmail = (email: string) =>
  /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/.test(
    email
  );

// export const validatePassword = (password: string) => /^(?=.*[0-9])(?=.*\w)(?=.*[A-ZØÆÅÅÄÖßÜ])([\S]{8,})/.test(password);

export const validatePassword = (password: string) => {
  if (!password || password.length < 8) return false;

  let haveUpper = false;
  let haveLower = false;
  let haveNumber = false;

  for (let i = 0; i < password.length; i++) {
    let character = password[i];

    if (
      character.toLowerCase() != character.toUpperCase() &&
      character === character.toUpperCase() &&
      !isNumeric(character)
    ) {
      haveUpper = true;
    } else if (
      character.toLowerCase() != character.toUpperCase() &&
      character === character.toLowerCase() &&
      !isNumeric(character)
    )
      haveLower = true;
    else if (isNumeric(character)) haveNumber = true;
  }

  return haveUpper && haveLower && haveNumber;
};

/**
 * Quill will by default represent each user typed in line as a paragraph: "<p></p>"
 * A line with content will look like "<p>This</p>"
 * A blank line will is represented as "<p><br></p>"
 * This function considers all of these as empty lines as well:
 * <p><br/><p>
 * <p>  </p>  (whitespace or tabs etc)
 * @param content HTML output by the Quill editor
 */
export const validateQuillEditorHTMLOutputIsNotEmpty = (content: string): boolean => {
  return !(/^(<p>\s*(<br\/?>)?<\/p>)+$/.test(content));
}
  

