import {
  getControllerParameters,
  parameterValueChanged,
  cancelEditParameters,
  parameterSecurityLevelChanged,
  deleteSite,
  persistControllerParameters
} from '../../actions';
import { IControllerParameter, IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  mergeDoneEntityInState,
  mergeDoneEntitiesInState,
  isEmpty,
  createAsyncDictionaryForSiteRelations,
  emptyArray,
  flatMap,
  removeNoneFromArray,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  isSomething,
  removeAsyncObjects,
  removeAsyncEntitiesInDictionary
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createPendingEntity,
  createFetchedEntity,
  createFailedFetchingEntity
} from '../../types';

export interface IControllerParameterReducer
  extends IAsyncReducerState<IControllerParameter> {
  byControllerIds: IAsyncDictionary<number[]>;
}

const defaultState: IControllerParameterReducer = {
  byId: {},
  allIds: undefined,
  byControllerIds: {}
};

const mergeByControllerIds = (
  state: IControllerParameterReducer,
  controllerId: number,
  parameters: IControllerParameter[]
): Partial<IControllerParameterReducer> => ({
  byControllerIds: mergeObjects(
    state.byControllerIds,
    isEmpty(parameters)
      ? { [controllerId]: createFetchedEntity(emptyArray) }
      : createAsyncDictionaryForSiteRelations(
          parameters,
          () => controllerId,
          p => p.controllerParameterId
        )
  )
});

const getParametersByControllerIds = (
  state: IControllerParameterReducer,
  controllerIds: number[]
) =>
  flatMap(
    removeNoneFromArray(
      controllerIds.map(id =>
        getEntityOrDefault(
          getAsyncEntitiesByAsyncArray(
            getAsyncEntity(state.byControllerIds, id),
            state.byId
          ),
          undefined
        )
      )
    ),
    item => item
  );

const reducer = reducerWithInitialState(defaultState)
  .case(deleteSite.done, (state, payload) => {
    const parametersByControllerIds = getParametersByControllerIds(
      state,
      payload.params.nativeControllerIds
    );

    return isSomething(parametersByControllerIds)
      ? mergeObjects(
          state,
          removeAsyncObjects(
            state,
            parametersByControllerIds.map(
              parameter => parameter.controllerParameterId
            )
          ),
          {
            byControllerIds: mergeObjects(
              state.byControllerIds,
              removeAsyncEntitiesInDictionary(
                state.byControllerIds,
                payload.params.nativeControllerIds
              )
            )
          }
        )
      : state;
  })

  .case(persistControllerParameters.done, (state, payload) => ({
    ...state,
    ...insertFetchedEntities(
      state,
      payload.params,
      e => e.controllerParameterId
    )
  }))
  .case(getControllerParameters.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntities(
        state,
        payload.result,
        cp => cp.controllerParameterId
      ),
      mergeByControllerIds(state, payload.params, payload.result)
    )
  )
  .case(getControllerParameters.started, (state, payload) =>
    mergeObjects(state, {
      byControllerIds: mergeObjects(state.byControllerIds, {
        [payload]: createPendingEntity()
      })
    })
  )
  .case(getControllerParameters.failed, (state, payload) =>
    mergeObjects(state, {
      byControllerIds: mergeObjects(state.byControllerIds, {
        [payload.params]: createFailedFetchingEntity(payload.error)
      })
    })
  )
  .case(parameterValueChanged, (state, { parameterId, parameterValue }) =>
    mergeObjects(
      state,
      mergeDoneEntityInState(state, parameterId, _ => ({
        pendingValue: parameterValue,
        isInvalid: !isEmpty(parameterValue) && isNaN(Number(parameterValue))
      }))
    )
  )
  .case(
    parameterSecurityLevelChanged,
    (state, { parameterId, securityLevel }) =>
      mergeObjects(
        state,
        mergeDoneEntityInState(state, parameterId, _ => ({
          pendingSecurityLevel: securityLevel
        }))
      )
  )
  .case(cancelEditParameters, (state, parameterIds) =>
    mergeObjects(
      state,
      mergeDoneEntitiesInState(state, parameterIds, _ => ({ isInvalid: false, pendingValue: undefined }))
    )
  );

export default reducer;
