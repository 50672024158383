import { humanReadableDuration, ensureNumber } from '../utility';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';

@autoinject
export class DurationValueConverter {
  constructor(private i18n: I18N) {}
  toView(value: string | undefined, prependCount = true) {
    if (!value) return;
    const vettedValue = ensureNumber(value);
    
    const prefix = vettedValue > 0 ? 1 : -1;

    const timeUntil = humanReadableDuration(prefix * vettedValue / 1000);
    if (!timeUntil) return;
    return (
      (prependCount ? prefix * timeUntil.count : '') +
      ' ' +
      this.i18n.tr(timeUntil.key, { count: timeUntil.count })
    );
  }
}
