import {
  activateAccountState,
  sendInvite,
  forgotPassword,
  resetPassword,
  activateAccount,
  requestNewAccountActivation
} from './../actions';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { getLogger } from 'aurelia-logging';
import { IResetPasswordPayload } from '../interfaces/index';
import { BaseService } from './baseService';
import { getNewId } from '../utility';

@autoinject
export class AccountService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(AccountService.name), taskQueue);
  }

  sendInviteAsync = async (userId: number, languageId: number) =>
    this.httpRequestWithDispatch(
      requests.sendInvite(userId, languageId),
      sendInvite,
      getNewId(),
      'Send invite to user failed'
    );

    activateAccountStateAsync = async(username: string, nonce: string) =>
  this.httpRequestWithDispatch(
    requests.activateAccountState(username, nonce),
    activateAccountState,
    undefined,
    'Failed to get activate account state'
  );

  requestNewAccountActivationAsync = async(username: string, nonce: string) =>
  this.httpRequestWithDispatch(
    requests.requestNewAccountActivation(username, nonce),
    requestNewAccountActivation,
    undefined,
    'Request new accout activation failed'
  );

  activateAccountAsync = async (params: IResetPasswordPayload) =>
    this.httpRequestWithDispatch(
      requests.activateAccount(params),
      activateAccount,
      undefined,
      'Activate account failed'
    );

  forgotPasswordAsync = async (email: string) =>
    this.httpRequestWithDispatch(
      requests.forgotPassword(email),
      forgotPassword,
      {},
      'Failed to request new password'
    );

  resetAccountAsync = async (params: IResetPasswordPayload) =>
    this.httpRequestWithDispatch(
      requests.resetAccount(params),
      resetPassword,
      undefined,
      'Failed to reset account'
    );
}
