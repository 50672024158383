import { customElement, bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import './expandable-panel.css';

@customElement('expandable-panel')
export class ExpandablePanel {
  @bindable icon: string;
  @bindable text: string;
  @bindable expanded: string;

  @bindable contentOnTop: boolean = false;

  @bindable onClick: Function = PLATFORM.noop;
}
