export const routeNames = {
  init: 'init',
  dashboard: 'dashboard',
  sitelist: 'sitelist',
  sitelistpage: 'sitelistpage',
  sitedetails: 'sitedetails',
  sitedetailsIndex: 'sitedetailsindex',
  sitedetailsAlarms: 'sitedetailsalarms',
  sitedetailsMap: 'sitedetailsmap',
  sitedetailsAlarmsCreateOrEdit: 'siteDetailsAlarmsEdit',
  sitedetailsTanks: 'sitedetailstanks',
  sitedetailsGraph: 'sitedetailsGraph',
  sitedetailsTanksDetails: 'sitedetailsTanksDetails',
  sitedetailsChannelDetails: 'sitedetailsChannelDetails',
  sitedetailsChannelsList: 'sitedetailsChannelsList',
  sitedetailsChannels: 'siteDetailsChannels',
  sitedetailsAssets: 'siteDetailsAssets',
  sitedetailsControllers: 'sitedetailscontrollers',
  sitedetailsControllerDetails: 'sitedetailscontrollerdetails',
  sitedetailsProfileDetails: 'sitedetailsProfileDetails',
  sitedetailsEasyFeed: 'easyfeed',
  orderPlanner: 'orderplanner',
  routePlanner: 'routeplanner',
  controllerManagerChannels: 'controllermanagercontrollerchannels',
  controllermanagerCreateSite: 'controllermanagerCreateSite',
  controllermanagerAttachSite: 'controllermanagerAttachSite',
  controllermanagerReceipt: 'controllermanagerReceipt',
  controllerManagerControllerList: 'controllerManagerControllerList',
  controllerManagerControllerDetails: 'controllerManagerControllerDetails',
  customermanagerlist: 'customermanagerlist',
  customermanagerdetails: 'customermanagerdetails',
  customermanagermultiedit: 'customermanagermultiedit',
  customermanagercreate: 'customermanagercreate',
  usermanagerlist: 'usermanagerlist',
  usermanagerdetails: 'usermanagerdetails',
  usermanagercreation: 'usermanagercreation',
  usermanagerroles: 'usermanagerroles',
  importjobs: 'import',
  importjobreadonly: 'import-job-readonly',
  importjobeditable: 'import-job-editable',
  contactmanager: 'contactmanager',
  simManagement: 'sim-management',
  simManagemenetDataUsageMaingateDetails: 'data-usage-maingate-details',
  exportjobs: 'export',
  exportjobreadonly: 'export-job-readonly',
  exportjobeditable: 'export-job-editable',
  editRoleTemplate: 'edit-role-template',
  go: 'go',
  qr: 'qr',
  releasenotes: 'releasenotespage',
  systemmessagesmanager: 'systemmessagesmanager',  
  // customermanager: 'customermanager',
  sitedetailsTanksHref: (siteId: number, siteChannelId?: number) =>
    !!siteChannelId && siteChannelId > 0
      ? `/sitedetails/${siteId}/tanks/tankDetails/${siteChannelId}`
      : `/sitedetails/${siteId}/tanks`,
  sitedetailsControllerHref: (siteId: number, controllerId: number | null) => 
    !!controllerId && controllerId > 0
      ? `/sitedetails/${siteId}/controllers/${controllerId}`
      : undefined,
  customerManagerDetailsHref: (customerId: number) =>              
      `/customermanager/details/${customerId}`,
};
