import { customElement, bindable } from 'aurelia-framework';
import {
  ContactSearchForReuseQuery,
  ContactSearchForReuseQueryVariables,
  ContactSearchForReuseQuery_contactSearch_edges,
  ContactSortEnum,
  CountReusableContactsQuery,
  CountReusableContactsQueryVariables,
  SortDirection
} from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';
import { GraphQLInfiniteListViewModel } from '../../../pages/common/GraphQLInfiniteListViewModel';
import { isNone, isSomething } from '../../../utility/helpers';
import './contactreusemodal.css';
import { DocumentNode } from 'graphql';

/**
 * Generic shape for passing in a contact to use as search parameters.
 * Buid and Customer are objects to fit with how contact-manager-details' way of handling that data.
 * Everything is x | undefined | null, to be duck-typable as any other Contact datatype (there are several in frontend).
 */
export interface IContactSearchInfo {
  name?: string;
  email?: string | null;
  telephoneNumber?: string | null;
  buid?: { buidId?: string | null } | null;
  customer?: { customerId?: string | null } | null;
}

/**
 * Determine if there is any point in showing the contact reuse modal.
 * @param contact The contact search parameters
 * @param runQuery The GraphQlBaseViewModels's runQuery method as a callback, that can be used to run a query from inside this function.
 * @returns true if there is one or more matching contacts, false otherwise.
 */
export async function checkIfAnyContactsCanBeReUsed(
      contact: IContactSearchInfo,
      runQuery: (query: DocumentNode, variables: CountReusableContactsQueryVariables) => Promise<CountReusableContactsQuery>
    ): Promise<boolean> {

  const variables: CountReusableContactsQueryVariables = {
    name: contact.name,
    email: contact.email,
    telephone: contact.telephoneNumber,
    customerId: isSomething(contact.customer) ? contact.customer.customerId : undefined,
    buidId: isSomething(contact.buid) ? contact.buid.buidId : undefined,
    sortProperty: ContactSortEnum.Name,
    sortDirection: SortDirection.Asc,
    first: 1,
    offset: 0
  };

  const result = await runQuery(gql`
    query CountReusableContactsQuery(
      $first:Int, 
      $offset:Int, 
      $name:String, 
      $email:String,
      $buidId:String,
      $customerId:String,
      $telephone:String,
      $sortProperty:ContactSortEnum,
      $sortDirection: SortDirection
    ) {
    contactSearch(
      first:$first,
      offset:$offset,
      name:$name,
      email:$email,
      telephone:$telephone,
      buidId:$buidId,
      customerId:$customerId,
      sortProperty:$sortProperty,
      sortDirection:$sortDirection
      ) {
        totalCount
      }
    }`,
    variables
  );

  if (isNone(result)) {
    return false;
  }
  return result.contactSearch.totalCount !== 0;
}



@customElement("contact-reuse-modal")
export class ContactReuseModal extends GraphQLInfiniteListViewModel<ContactSearchForReuseQuery, ContactSearchForReuseQueryVariables, ContactSearchForReuseQuery_contactSearch_edges> {

  @bindable()
  contact: IContactSearchInfo;

  @bindable()
  cancel: () => void;

  @bindable()
  reused: (params: any) => Promise<unknown> | void;

  @bindable()
  createnew: (params: any) => Promise<unknown> | void;

  @bindable()
  enableReuseButtons: boolean = true;

  @bindable()
  reuseButtonsText: string = 'UI_SiteDetails_Details_Contacts_AssignContact_Actions_SetAsContact';


  constructor() {
    super(ContactReuseModal.name, data => data.contactSearch.totalCount, data => data.contactSearch.edges);
  }


  query = gql`
    query ContactSearchForReuseQuery(
      $first:Int, 
      $offset:Int, 
      $name:String, 
      $email:String, 
      $telephone:String, 
      $buidId: String, 
      $customerId: String,
      $sortProperty:ContactSortEnum, 
      $sortDirection: SortDirection) 
      {
      contactSearch(
        first:$first,
        offset:$offset,
        name:$name,
        email:$email,
        telephone:$telephone,
        buidId:$buidId,
        customerId:$customerId,
        sortProperty:$sortProperty,
        sortDirection:$sortDirection)
      {
      edges {
          contactId
          name
          email
          telephoneNumber
        }
        totalCount
      }
    }`;

  bind(): void {
    super.bind();
    this.setVariables();
  }

  setVariables(): void {
    if (isSomething(this.contact)
      && (isSomething(this.contact.name)
        || isSomething(this.contact.email)
        || isSomething(this.contact.telephoneNumber)
        || (isSomething(this.contact.buid) && isSomething(this.contact.buid.buidId))
        || (isSomething(this.contact.customer) && isSomething(this.contact.customer.customerId)))) {

      this.variables = {
        name: this.contact.name,
        email: this.contact.email,
        telephone: this.contact.telephoneNumber,
        buidId: isSomething(this.contact.buid) ? this.contact.buid.buidId : undefined,
        customerId: isSomething(this.contact.customer) ? this.contact.customer.customerId : undefined,
        sortProperty: ContactSortEnum.Name,
        sortDirection: SortDirection.Asc
      };
    }
  }

  cancelAction(): void {
    if (isSomething(this.cancel)) this.cancel();
  }

  reusedAction(contactEdge: ContactSearchForReuseQuery_contactSearch_edges): Promise<unknown> | void {
    if (isSomething(this.reused)) {
      return this.reused({ contactId: contactEdge.contactId });
    }
  }

  createNewAction(contact: IContactSearchInfo): Promise<unknown> | void {
    if (isSomething(this.createnew)) {
      return this.createnew({ contact });
    }
  }
}
