import { customElement, autoinject, bindable } from "aurelia-framework";
import './columnfilter.css';
import { IFilterGroup } from "$interfaces/iFilter";
import { ElasticSearchPage } from "$typings/graphql";
import { IFilterDefinition, IGridColumn } from "$components/grid/grid";

@autoinject()
@customElement('column-filter')
export class ColumnFilter<T>{
    @bindable filterGroup: IFilterGroup | undefined;
    @bindable activeFilters: IFilterGroup[];
    @bindable freeTextQuery: string[] | undefined;
    @bindable page: ElasticSearchPage | undefined;
    @bindable() changedFilter: (args: { newfilter: IFilterGroup }) => void;
    @bindable selectedColumn: IGridColumn<T>;
    @bindable selectedColumnDefinition: IFilterDefinition;
    @bindable() selectedFilterGroupId: number | undefined;

    filterChanged(newfilter: IFilterGroup){  
        this.changedFilter && this.changedFilter({ newfilter })
    }
}
