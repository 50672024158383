import {
  trendChannelAdded,
  trendChannelsUpdated,
  trendChannelsRemoved,
  getTrendGroups,
  trendGroupAdded,
  trendChannelDataLoaded
} from '../../actions';
import { ITrendChannel } from '../../interfaces';
import {
  mergeObjects,
  appendOrReplaceObjectInArray,
  emptyArray,
  ensureDate,
  toObjectAndMap,
  isNone
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IPoint, ITrendStats } from '../../models/trend';

export interface IUserTrendChannelsReducerState {
  [trendGroupId: number]: ITrendChannel[];
  lastTrendRequestId: number | undefined;
}

const defaultState: IUserTrendChannelsReducerState = {
  lastTrendRequestId: undefined
};

const calculateStatsForData = (points: IPoint[]): ITrendStats => {
  if (points.length === 0)
    return {
      count: 0
    };

  let sum: number = 0.0;
  let max: number = Number.MIN_VALUE;
  let min: number = Number.MAX_VALUE;
  let count: number = 0;
  let first: number = Number.MIN_VALUE;
  let last: number = Number.MIN_VALUE;
  let lastHour = ensureDate(points[0].ts).getHours();
  const hourlyAverages = new Array<number>();
  let hourSum: number = 0.0;
  let hourSampleCount: number = 0.0;

  // Map Influx data series to HSChart data series
  points.forEach(p => {
    const val = p.v;

    if (val !== null) {
      if (first === Number.MIN_VALUE) first = val;

      if (lastHour !== ensureDate(p.ts).getHours()) {
        hourlyAverages.push(hourSum / hourSampleCount);
        lastHour = ensureDate(p.ts).getHours();
        hourSum = 0;
        hourSampleCount = 0;
      }

      hourSum += val;
      hourSampleCount++;

      count++;
      if (val > max) max = val;

      if (val < min) min = val;

      sum += val;

      last = val;
    }
  });

  let hourlySum: number = 0.0;

  if (hourSampleCount !== 0) {
    hourlyAverages.push(hourSum / hourSampleCount);
    hourlyAverages.forEach(a => (hourlySum = hourlySum + a));
  }

  return {
    min,
    max,
    count,
    sum: hourlySum,
    mathSum: sum,
    mean: sum / count,
    delta: last - first
  };
};

const reducer = reducerWithInitialState(defaultState)
  .case(getTrendGroups.done, (state, { result }) =>
    mergeObjects(
      state,
      toObjectAndMap(
        result,
        r => r.userTrendGroupId,
        r => {
          const existing = state[r.userTrendGroupId];
          if (isNone(existing)) return r.trendChannels;
          return r.trendChannels.map(rc =>
            mergeObjects(
              existing.find(e => e.trendKey === rc.trendKey) || rc,
              rc
            )
          );
        }
      )
    )
  )

  .case(trendGroupAdded.done, (state, payload) =>
    mergeObjects(state, {
      [payload.result.userTrendGroupId]: payload.result.trendChannels
    })
  )

  .case(trendChannelAdded.done, (state, payload) =>
    mergeObjects(state, {
      [payload.params.trendGroup
        .userTrendGroupId]: appendOrReplaceObjectInArray(
        state[payload.params.trendGroup.userTrendGroupId] || emptyArray,
        trend => trend.trendKey === payload.result.trendKey,
        payload.result
      )
    })
  )

  .case(trendChannelsRemoved.done, (state, { params }) =>
    !params.length
      ? state
      : mergeObjects(state, {
          [params[0].trendGroupId]: state[params[0].trendGroupId].filter(
            t => !params.some(p => p.trendKey === t.trendKey)
          )
        })
  )

  .case(trendChannelsUpdated.done, (state, { result }) =>
    !result.length
      ? state
      : mergeObjects(state, {
          [result[0].trendGroupId]: state[result[0].trendGroupId].map(
            channel => {
              const newChannel = result.find(
                newC => newC.trendKey === channel.trendKey
              );
              return newChannel ? mergeObjects(channel, newChannel) : channel;
            }
          )
        })
  )

  .case(trendChannelDataLoaded.started, (state, { internalId }) =>
    mergeObjects(state, {
      lastTrendRequestId: internalId
    })
  )

  .case(trendChannelDataLoaded.done, (state, { params, result }) =>
    state.lastTrendRequestId !== params.internalId
      ? state
      : mergeObjects(state, {
          [params.trendGroupId]: state[params.trendGroupId].map(
            trendChannelInState => {
              const trendDataForChannel = result.find(
                r =>
                  r.channelId === trendChannelInState.channelId &&
                  r.operatorId === trendChannelInState.operatorId
              );

              const dataForChannel = trendDataForChannel
                ? trendDataForChannel.points
                : emptyArray;

              return mergeObjects(trendChannelInState, {
                data: dataForChannel,
                stats: calculateStatsForData(dataForChannel)
              });
            }
          )
        })
  );

export default reducer;
