import { getColumns, saveColumns } from '../actions';
import { requests } from '../config';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { ISiteColumn } from '../interfaces/index';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { getAllEntities, getEntityOrUndefined } from '../utility';

@autoinject()
export class ColumnService extends BaseService {
  constructor(protected httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ColumnService.name), taskQueue);
  }

  getColumnsAsync = () =>
    this.httpRequestWithDispatch(
      requests.getColumns(),
      getColumns,
      {},
      'UI_Columns_Get_Failed',
      state => getEntityOrUndefined(getAllEntities(state.columns))
    );

  getColumns = () =>
    this.httpRequestWithDispatchFromState(
      requests.getColumns(),
      getColumns,
      {},
      'UI_Columns_Get_Failed',
      state => getAllEntities(state.columns)
    );

  updateColumns = async (columns: ISiteColumn[]) =>
    this.httpRequestWithDispatch(
      requests.updateColumns(columns),
      saveColumns,
      columns,
      'UI_Columns_Save_Failed'
    );
}
