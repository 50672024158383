import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects, ensureNumber, isNumeric } from '../../utility/index';
import { forgotPassword, resetPassword, activateAccount } from '../../actions/';
import { navigateToRoute } from '../../actions';

export interface IAccountReducerState {
  error: boolean;
  message: string;
  done: boolean;
}

const defaultState: IAccountReducerState = {
  error: false,
  message: '',
  done: false
};

const mapResetResultCodeToMessage = (errorCode: string | number) => {
  const parsedCode = ensureNumber(errorCode);
  switch (parsedCode) {
    case 1:
      return 'UI_Login_ResetPassword_NoMatch';
    case 2:
      return 'UI_Login_ResetPassword_RuleMismatch';
    case 3:
      return 'UI_Login_ResetPassword_InvalidRequest';
    default:
      return 'UI_Login_ResetPassword_Changed';
  }
};

const mapForgotResultCodeToMessage = (errorCode: string | number) => {
  const parsedCode = ensureNumber(errorCode);
  switch (parsedCode) {
    case 1:
      return 'UI_Login_ForgotPassword_EmailSendingError';
    case 2:
      return 'UI_Login_ForgotPassword_EmailInvalid';
    default:
      return 'UI_Login_ForgotPassword_EmailSent';
  }
};

const mapActivateCodeToMessage = (errorCode: string | number) => {
  const parsedCode = ensureNumber(errorCode);
  switch (parsedCode) {
    case 0:
      return 'UI_Login_ActivateUser_UserActivated';
    default:
      return 'UI_Login_ActivateUser_ActivateError';
  }
};

const reducer = reducerWithInitialState(defaultState)
  .case(forgotPassword.done, (state, payload) =>
    mergeObjects(state, {
      done: true,
      message: mapForgotResultCodeToMessage(payload.result)
    })
  )

  .case(forgotPassword.failed, (state, payload) =>
    mergeObjects(state, {
      error: true,
      message: isNumeric(payload.error)
        ? mapForgotResultCodeToMessage(payload.error)
        : payload.error
    })
  )

  .case(resetPassword.done, (state, payload) =>
    mergeObjects(state, {
      done: true,
      message: mapResetResultCodeToMessage(payload.result)
    })
  )

  .case(resetPassword.failed, (state, payload) =>
    mergeObjects(state, {
      error: true,
      message: isNumeric(payload.error)
        ? mapResetResultCodeToMessage(payload.error)
        : payload.error
    })
  )

  .case(activateAccount.done, (state, payload) =>
    mergeObjects(state, {
      done: true,
      message: mapActivateCodeToMessage(payload.result)
    })
  )

  .case(activateAccount.failed, (state, payload) =>
    mergeObjects(state, {
      error: true,
      message: isNumeric(payload.error)
        ? mapActivateCodeToMessage(payload.error)
        : payload.error
    })
  )

  .case(navigateToRoute, state =>
    mergeObjects(state, {
      error: false,
      message: '',
      done: false
    })
  );

export default reducer;
