import { ITrendChannelDetails } from '../interfaces/entity/iTrendChannel';
import { IUnit } from '../interfaces';
import { isNone, displayMeasurement, ensureNumber } from '../utility';

export interface ITrendDataAndStats {
  siteChannelId: number;
  trendData: ITrendData;
  statistics: IChannelTrendStats[];
}

export interface ITrendData {
  channelId: number;
  operator: string;
  points: IPoint[];
}

export interface IChannelTrendStatsDetails extends IChannelTrendStats {
  unit: IUnit | undefined;
}

export interface IChannelTrendStats {
  value: number | string | undefined;
  label: string;
  unitId?: number | string;
  doNotParse?: boolean;
}

export interface ITrendStats {
  count: number;
  min?: number;
  max?: number;
  mean?: number;
  delta?: number;
  sum?: number;
  mathSum?: number;
}

export interface IPoint {
  /** Value */
  v: number;
  /** Timestamp */
  ts: string;
}

export interface IAxisLine {
  lineStyle: ILineStyle;
  onZero?: boolean;
}

export interface ILineStyle {
  color: string;
}

export interface IAxisLabel {
    inside: boolean;
    margin: number;
    fontSize: number;
}

export interface IYAxis {
  index: number;
  nameLocation: 'center' | 'start' | 'middle' | 'end' | undefined;
  nameRotation: number;
  name: string;
  position: 'right' | 'left' | undefined;
  type: 'value' | 'time' | 'category' | 'log' | undefined;
  nameGap: number;
  splitLine: undefined;
  axisLine: IAxisLine;
  offset: number | undefined;
  width: number;
  min: number | undefined;
  max: number | undefined;
  boundaryGap?: Array<string>;
}

const getLongestStringByPointValues = (
  channel: ITrendChannelDetails,
  points: IPoint[]
) =>
  points !== undefined
    ? points
        .map(d =>
          isNone(d.v) || !isFinite(d.v) || isNaN(d.v)
            ? ''
            : displayMeasurement(d.v, channel.unit) || ''
        )
        .reduce((a, b) => (a.length > b.length ? a : b), '')
    : '';

const getWidthOfNumber = (longestString: string) => {
  if (isNone(longestString)) return 80;
  return 40 + 10 * longestString.length;
};

export const createSharedYAxisFromTrendChannel = (
  channel: ITrendChannelDetails,
  name: string,
  index: number,
  offset: number,
  allSharedChannels: ITrendChannelDetails[]
): IYAxis => {
  let longestString = '';

  allSharedChannels.forEach(ch => {
    let channelLongestString = getLongestStringByPointValues(channel, ch.data);
    if (channelLongestString.length > longestString.length)
      longestString = channelLongestString;
  });

  return {
    type: 'value',
    index: index,
    offset: offset,
    name: name,
    nameLocation: 'center',
    nameRotation: 90,
    nameGap: -20,
    splitLine: undefined,
    position: channel.axis.locationId === 0 ? 'left' : 'right',
    axisLine: {
      lineStyle: {
        color:
          channel.axis.axisTypeId === 0 && channel.color
            ? channel.color
            : '#000000'
      }
    },
    min: channel.axis.autoScale ? undefined : ensureNumber(channel.axis.minScale),
    max: channel.axis.autoScale ? undefined : ensureNumber(channel.axis.maxScale),
    width: getWidthOfNumber(longestString)
  };
};

export const createYAxisFromTrendChannel = (
  channel: ITrendChannelDetails,
  name: string,
  index: number,
  offset: number
): IYAxis => {
  return {
    type: 'value',
    index: index,
    offset: offset,    
    name: name,    
    nameLocation: 'center',
    nameRotation: 90,
    nameGap: -20,
    splitLine: undefined,    
    position: channel.axis.locationId === 0 ? 'left' : 'right',
    axisLine: {
      onZero: false,
      lineStyle: {
        color:
          channel.axis.axisTypeId === 0 && channel.color
            ? channel.color
            : '#000000'
      }
    },
    min: channel.axis.autoScale ? undefined : ensureNumber(channel.axis.minScale),
    max: channel.axis.autoScale ? undefined : ensureNumber(channel.axis.maxScale),
    width: getWidthOfNumber(
      getLongestStringByPointValues(channel, channel.data)
    )
  };
};

export interface IDataProcessChannel {
  siteChannelId: number;
  channelId: number;
  trendKey: number;
  operatorId: number;
}
export interface IDataProcessChannelResponse extends IDataProcessChannel {
  points: IPoint[];
}
export interface IDataProcessRequest {
  interval: number;
  operatorInterval: number;
  trendChannels: IDataProcessChannel[];
  showGaps?: boolean;
  showRaw?: boolean;
}
