import {
  INote,
  IControllerProfile,
  IControllerProfileHistory
} from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';
import { SecurityLevel } from '../interfaces/enums/securityLevel';
import { IFetchProfileResult } from '../interfaces/entity'

const actionCreator = actionCreatorFactory('sitedetails');

/* Sync actions */
export const cancelNoteEditAction = actionCreator('CancelNoteEdit');
export const collapseMenuItemOnNote = actionCreator(
  'Collapse_Menu_Item_On_Note'
);
export const noteValuesEdited = actionCreator<{
  text: string;
  noteType: number;
}>('Note_Value_Edited');
export const markNoteForEditAction = actionCreator<INote>('Mark_Note_For_Edit');
export const toggleCommandPallet = actionCreator('Toggle_Command_Pallet');
export const editParameters = actionCreator('Edit_Parameters');
export const openParameterHistory = actionCreator<{ parameterId: number }>('Open_Parameter_History');
export const closeParameterHistory = actionCreator('Close_Parameter_History');

export const parameterValueChanged = actionCreator<{
  parameterId: number;
  parameterValue: string;
}>('Parameter_Value_Changed');
export const parameterSecurityLevelChanged = actionCreator<{
  parameterId: number;
  securityLevel: number;
}>('parameterSecurityLevelChanged');
export const cancelEditParameters = actionCreator<number[]>(
  'Cancel_Edit_Parameters'
);

/* Async actions */

export const profileEditorSetSelectedDay = actionCreator<number>(
  'profile_editor_set_selected_day'
);
export const profileEditorValueChanged = actionCreator<{
  index: number;
  value: string;
  existingValues: (string | number)[];
}>('profile_editor_value_changed');
export const profileEditorFormulaValueChanged = actionCreator<string>(
  'profile_editor_formulavalue_changed'
);
export const profileEditorFormulaOperationClicked = actionCreator<{
  operation: string;
  values: (string | number)[];
}>('profile_editor_formulaoperation_clicked');
export const profileEditorCopyWorkingSetToDays = actionCreator<{
  days: number[];
  values: (string | number)[];
}>('profile_editor_copy_dataset_to_days');

export const fetchingProfileForEdit = actionCreator.async<
  { controllerProfileId: number; historyId: number | undefined },
  IFetchProfileResult
>('fetching_profile_for_edit');

export const fetchingProfileHistory = actionCreator.async<
  number,
  IControllerProfileHistory[]
>('fetchingProfileHistory');
export const fetchingProfileForDownload = actionCreator.async<
  { controllerProfileId: number },
  IFetchProfileResult
>('fetching_profile_for_download');
export const persistingControllerProfile = actionCreator.async<
  {
    controllerProfileId: number;
    persistingThroughExcel: boolean;
    values: number[];
  },
  IFetchProfileResult
>('persisting_controller_profile');
export const profileEditorChangeYScale = actionCreator<{
  ymin: number | string | undefined;
  ymax: number | string | undefined;
}>('profile_editor_change_scale');

export const getProfilesForController = actionCreator.async<
  { controllerId: number },
  IControllerProfile[]
>('getProfilesForController');
export const toggleEditProfileSecurityLevel = actionCreator<{
  profiles: IControllerProfile[];
  previous: boolean;
}>('toggleEditProfileSecurityLevel');
export const editProfileSecurityLevel = actionCreator<{
  controllerProfileId: number;
  securityLevel: SecurityLevel;
}>('editProfileSecurityLevel');

export const toggleShowTanks = actionCreator('toggle_show_tanks');
export const toggleEditSiteChannelStatus = actionCreator(
  'toggleEditSiteChannelStatus'
);
export const setEditSiteChannelStatus = actionCreator<boolean>(
  'setEditSiteChannelStatus'
);
export const setEditSiteChannelParkedDescription = actionCreator<string>(
  'setEditSiteChannelParkedDescription'
);
export const toggleSubMenuSiteDetails = actionCreator<boolean>(
  'toggle_sub_menu_on_sitedetails'
);
export const modifyPropertyOnSite = actionCreator<{
  siteId: number;
  property: string;
  value: string | number | undefined;
}>('modify_property_on_site');
export const toggleEditSiteDetails = actionCreator('toggle_edit_site_details');
