import { OrderProcess } from '../../config';
import { ChannelTypes } from '../enums';

export interface ISiteChannel extends IChannel {
  siteChannelId: number;
  siteId: number;
  pendingExportJobs?: number[];
}

export interface IMultiSiteChannelEdit
{
  siteChannelIds : number[];
  parked: boolean;
  parkedDescription: string;
}

export interface IChannel {
  alias: string;
  channelId: number;
  lastSample: number;
  lastSampleTime: string;
  channelType: ChannelTypes;
  productId?: number;
  controllerId: number;
  code: string;
  sortIndex?: number;
  unitId?: number | string;
  capacity?: number;
  maximum?: number;
  minimum?: number;
  color?: string;
  isParked: boolean;
  parkedDate?: string;
  parkedDescription?: string;
  tankDetails?: ITankDetails;
  retentionTimeDetails?: IRetentionTimeDetails;
  aggregatedTankUsageDetails?: IAggregatedTankUsageDetails;
  doseDetails?: IDoseChannelDetails;
  aggregatedDoseChannelDetails?: IAggregatedDoseChannelDetails;
  stockVsDoseAccuracyChannelDetails?: IStockVsDoseAccuracyChannelDetails;
  securityLevel: number;
}

export interface ISiteTankChannel extends ISiteChannel {
  tankDetails: ITankDetails;
}

export interface IAggregatedDoseChannelDetails {
  doseChannelId?: number;
  doseChannelToken?: string;
  calibrationAlarmThreshold?: number;
  seriousCalibrationAlarmThreshold?: number;
}

export enum StockVsDoseTankUsage {
  FivePercent = 0,
  TwentyFivePercent = 1
}

export interface IStockVsDoseAccuracyChannelDetails {
  aggregatedDoseChannelId?: number;
  aggregatedDoseChannelToken?: string;
  aggregatedTankUsageChannelId?: number;
  aggregatedTankUsageChannelToken?: string;
  tankUsage?: StockVsDoseTankUsage;
}

export interface IRetentionTimeDetails {
  flowChannelId?: number;
  flowChannelToken?: string;
  volume?: number;
  calculatedChannel?: boolean;
}

export interface IAggregatedTankUsageDetails {
  tankChannelId?: number;
  tankChannelToken?: string;
}

export interface IDoseChannelDetails {
  tankChannelId?: number;
  tankChannelToken?: string;
  addNewTrackedChannel?: boolean;
  newTrackedChannelSeriousCalibrationAlarmThreshold?: number;
  newTrackedChannelCalibrationAlarmThreshold?: number;
}

export interface ITankDetails {
  tankId: number;
  reachMinimum?: string;
  reachEmpty?: string;
  lastFilled?: string;
  shipPoint?: number; // missing in dto.
  description?: string;
  planPoint?: number;
  valueLastFilled?: number;
  loadingPointId?: number;
  vehicleId?: number;
  shipTo?: string;
  haulierId?: number;
  tankType?: number; // missing in dto
  orderProcess?: keyof typeof OrderProcess; // missing in dto

  outsideThreshold: boolean;
  /**@deprecated use percentage on channel instead*/
  percentage: number | undefined; 
  freeCapacity: number | undefined;
  noConsumption: boolean;
  error: boolean;
  addNewTrackedChannel?: boolean;
  predictionType: TankPredictionEnum;
  predictionLineFromValue?: number;
  predictionLineFromDate?: string;
}

export enum TankPredictionEnum {
  TankCapacity = 0,
  TankUsageVector = 1,
  TankCapacityFromFilteredRawValues = 2
}

export interface IChannelsThatTrackTankChannel {
  id: number;
  name: string;
}
