import { bindable, computedFrom, customElement } from 'aurelia-framework';
import './output-box.css';

@customElement('output-box')
export class OutputBox {
  @bindable onChange: Function;
  @bindable items: any[];

  @bindable textProperty: string = 'text';
  @bindable valueProperty: string = 'value';

  @bindable selectedValue: any;

  @computedFrom('items', 'textProperty', 'valueProperty')
  get internalItems(): Array<{ text: any; value: any }> {
    return this.textProperty && this.valueProperty
      ? this.items.map(item => ({
          text: item[this.textProperty],
          value: item[this.valueProperty]
        }))
      : this.items;
  }

  get selectedItem(): string {
    let item = this.internalItems.filter(x => x.value == this.selectedValue);
    return item[0] == null ? 'Not set' : item[0].text;
  }
}
