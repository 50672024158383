import { customElement, bindable, computedFrom } from 'aurelia-framework';
import './flex-grid-cell.css';
import { isNone } from '../../../utility';

@customElement('flex-grid-cell')
export class FlexGridCell {
  @bindable class: string;
  @bindable link: string;
  @bindable width: string | number | undefined;
  
  @computedFrom('width')
  get innerStyle() {
      if(isNone(this.width)) return;
      return { 'min-width': `${this.width}px`, 'max-width': `${this.width}px` };
  }
}
