import { autoinject } from 'aurelia-framework';

@autoinject()
export class AttachedCustomAttribute {
  value: Function | undefined;

  constructor(private element: Element){

  }

  attached() {
    if (!!this.value) this.value({ element: this.element });
  }
}
