import { isNone } from '../utility';
import { SecurityLevel } from '../interfaces/enums/securityLevel';
import { ChannelTypes as ChannelTypesEnum } from '../interfaces/enums/channelTypes';
import { StockVsDoseTankUsage, TankPredictionEnum } from '../interfaces';

export const TIME = {
  MINUTEINMS: 1000 * 60,
  HOURINMS: 1000 * 60 * 60,
  DAYINMS: 1000 * 60 * 60 * 24,
  DAY6INMS: 1000 * 60 * 60 * 24 * 6,
  WEEKINMS: 1000 * 60 * 60 * 24 * 7,
  DAY29INMS: 1000 * 60 * 60 * 24 * 29,
  MONTHINMS: 1000 * 60 * 60 * 24 * 30,
};

export const ConsentKeys = {
  Cookies: 'cookie-consent',
  HideInstallPrompt: 'hide-install-prompt'
};

/**
 * This "enum" is duplicated in backend. Do not change this without also changing it in backend.
 */
export const OrderProcess: Record<OrderProcessEnum, string> = {
  0: 'Auto', // Auto
  1: 'Manual', // Manual
  2: 'Spot' // Spot
};

export enum OrderProcessEnum {
  Auto = 0, // Auto
  Manual = 1, // Manual
  Spot = 2 // Spot
}

export const ChannelTypes: Record<ChannelTypesEnum, string> = {
  [ChannelTypesEnum.Unknown]: 'UI_ChannelTypes_Unknown',
  [ChannelTypesEnum.Dose]: 'UI_ChannelTypes_Dose',
  [ChannelTypesEnum.Flow]: 'UI_ChannelTypes_Flow',
  [ChannelTypesEnum.H2SGas]: 'UI_ChannelTypes_H2SGas',
  [ChannelTypesEnum.RetentionTime]: 'UI_ChannelTypes_RetentionTime',
  [ChannelTypesEnum.Tank]: 'UI_ChannelTypes_Tank',
  [ChannelTypesEnum.Aggregation]: 'UI_ChannelTypes_Aggregation',
  [ChannelTypesEnum.Temperature]: 'UI_ChannelTypes_Temperature',
  [ChannelTypesEnum.Battery]: 'UI_ChannelTypes_Battery',
  [ChannelTypesEnum.UnknownWithSum]: 'UI_ChannelTypes_UnknownWithSum',
  [ChannelTypesEnum.AggregatedTankUsage]: 'UI_ChannelTypes_AggregatedTankUsage',
  [ChannelTypesEnum.AggregatedDose]: 'UI_ChannelTypes_AggregatedDose',
  [ChannelTypesEnum.StockVsDoseAccuracy]: 'UI_ChannelTypes_StockVsDoseAccuracy',
  [ChannelTypesEnum.Counter]: 'UI_ChannelTypes_Counter',
  [ChannelTypesEnum.DissolvedSulphides]: 'UI_ChannelTypes_DissolvedSulphides',
  [ChannelTypesEnum.Conductivity]: 'UI_ChannelTypes_Conductivity',
};

export enum ExportTypeEnum {
  Snapshot = 0,
  Full = 1,
  ShellStocklevels = 2,
  ShellDeliveries = 3,
  DeliveryTicketsEmail = 4,
  IDSQ8Deliveries  = 5,
  TankLevelAtMidnight = 6,
  HESQReport = 7,
}

export const ExportTypes: Record<ExportTypeEnum, string> = {
  [ExportTypeEnum.Snapshot]: 'UI_Admin_ExportJob_Type_Snapshot',
  [ExportTypeEnum.Full]: 'UI_Admin_ExportJob_Type_Full',
  [ExportTypeEnum.ShellStocklevels] : 'Shell XML Stocklevels',
  [ExportTypeEnum.ShellDeliveries]: 'Shell XML Deliveries',
  [ExportTypeEnum.DeliveryTicketsEmail] : 'Delivery tickets email',
  [ExportTypeEnum.IDSQ8Deliveries] : 'IDS/Q8 CSV deliveries',
  [ExportTypeEnum.TankLevelAtMidnight] : 'Tank level at midnight',
  [ExportTypeEnum.HESQReport] : 'Welsh Water HSEQ reports',
}

export const StockVsDoseTankUsageValues: Record<
  StockVsDoseTankUsage,
  string
> = {
  [StockVsDoseTankUsage.FivePercent]: 'UI_StockVsDoseTankUsage_FivePercent',
  [StockVsDoseTankUsage.TwentyFivePercent]:
    'UI_StockVsDoseTankUsage_TwentyFivePercent'
};

export const unitSymbols = {
  liters: 'ltr'
};

export const SecurityLevels: Record<SecurityLevel, string> = {
  [SecurityLevel.None]: 'None',
  [SecurityLevel.Low]: 'Low',
  [SecurityLevel.Medium]: 'Medium',
  [SecurityLevel.High]: 'High'
};

export const TankTypes: Record<TankTypesEnum, string> = {
  0: 'Unknown',
  1: 'Distribution',
  2: 'Inbound',
  3: 'Longnose'
};

/**
 * #6713: TankPredictionTypes is a mapping of a subset of TankPredictionEnum to language strings
 */
export const TankPredictionTypes: Pick<Record<TankPredictionEnum, string>, TankPredictionEnum.TankCapacity | TankPredictionEnum.TankUsageVector> = {
  [TankPredictionEnum.TankCapacity]:
    'UI_Common_TankPredictionType_TankCapacity',
  [TankPredictionEnum.TankUsageVector]:
    'UI_Common_TankPredictionType_TankUsageVector',
};


export enum TankTypesEnum {
  Unknown = 0,
  Distribution = 1,
  Inbound = 2,
  Longnose = 3
}

//These are sitelist columns
export enum ColumnAccess {
  SiteId = 1,
  SiteName = 2,
  CustomerName = 3,
  Description = 4,
  AvailableSpace = 5,
  ParkedDescription = 6,
  ProjectCode = 7,
  TankPercentFull = 8,
  SalesPerson = 9,
  SoldTo = 10,
  CountryCode = 12,
  CustomerRef = 14,
  Buid = 15,
  ParkedSince = 16,
  ContractNumber = 17,
  DeviceType = 18,
  Imei = 19,
  IpAddress = 20,
  LastSampleDate = 21,
  OrderProcess = 22,
  Postcode = 23,
  ProductName = 24,
  ReachMinimum = 25,
  ReachMinimumInDays = 26,
  SapNumber = 27,
  SerialNumber = 28,
  Icc = 29,
  TankDescription = 30,
  TankLastFilled = 31,
  TankLevel = 32,
  TankLevelInTonnes = 33,
  TankUnit = 34,
  Telephone = 35,
  TankVehicle = 36,
  ControllerLatitude = 37, //Deprecated
  ControllerLongitude = 38, //Deprecated
  Signal = 39,
  SiteHealth = 40, //This is really TankHealth
  ControllerAlternateSerial = 41,
  TankCapacity = 42,
  ControllerSimProvider = 44,
  ServiceProvider = 45,
  TankNextFilling = 46,
  ChannelId = 47,
  TankLastDeliveryTicket = 48,
  ControllerPosition = 49, //Deprecated
  H2SMax = 50,
  H2SMean = 51,
  H2SPercentTWA = 52,
  BatteryLevel = 53,
  SiteHasEquipment = 54,
  SiteHasGalleryImages = 55,
  SiteIsReceivingDeliveryTickets = 56,
  SiteAlarmsAreMuted = 57,
  StockVsDoseHoulyTankUsage = 58,
  StockVsDoseHoulyAvgDose = 59,
  StockVsDoseCalibration = 60,
  StockVsDoseCalibration25 = 60,
  TemperatureMean = 61,
  TemperatureMax = 62,
  FlowMin = 63,
  FlowMean = 64,
  FlowMax = 65,
  FlowSum = 66,
  BatteryAlias = 67,
  BatteryLastSampleTime = 68,
  BatteryHealth = 69,
  H2SLastSampleTime = 70,
  H2SParked = 71,
  H2SHealth = 72,
  H2SAlias = 73,
  TemperatureLastSampleTime = 74,
  TemperatureHealth = 75,
  TemperatureAlias = 76,
  StockVsDoseLastSampleTime = 77,
  StockVsDoseHealth = 78,
  FlowAlias = 79,
  FlowUnit = 80,
  FlowLastSampleTime = 81,
  FlowParked = 82,
  FlowHealth = 83,
  SiteAddress1 = 84,
  SiteAddress2 = 85,
  SiteCity = 86,
  TankIsParked = 87,
  DoseMin = 88,
  DoseMean = 89,
  DoseMax = 90,
  DoseSum = 91, 
  DoseAlias = 92,
  DoseUnit = 93,
  DoseLastSampleTime = 94,
  DoseParked = 95,
  DoseHealth = 96,
  RetentiontimeMin = 97,
  RetentiontimeMean = 98,
  RetentiontimeMax = 99,
  RetentiontimeAlias = 100,
  RetentiontimeUnit = 101,
  RetentiontimeLastSampleTime = 102,
  RetentiontimeParked = 103,
  RetentiontimeHealth = 104,
  TankLastDeliveryAmount = 105,
  OrderPerson = 106,
  TechPerson = 107,
  CustomerPerson = 108,
  TransportPerson = 109,
  DealerPerson = 110,
  
  TankControllerType = 111,
  DoseControllerType = 112,
  FlowControllerType = 113,
  TemperatureControllerType = 114,
  BatteryControllerType = 115,
  H2SControllerType = 116,

  TankControllerIpAddress= 117,
  DoseControllerIpAddress = 118,
  FlowControllerIpAddress = 119,
  TemperatureControllerIpAddress = 120,
  BatteryControllerIpAddress = 121,
  H2SControllerIpAddress = 122,

  TankControllerIcc = 123,
  DoseControllerIcc = 124,
  FlowControllerIcc = 125,
  TemperatureControllerIcc = 126,
  BatteryControllerIcc = 127,
  H2SControllerIcc = 128,

  TankControllerSerial = 129,
  DoseControllerSerial = 130,
  FlowControllerSerial = 131,
  TemperatureControllerSerial = 132,
  BatteryControllerSerial = 133,
  H2SControllerSerial = 134,

  TankControllerAlternateSerial = 135,
  DoseControllerAlternateSerial = 136,
  FlowControllerAlternateSerial = 137,
  TemperatureControllerAlternateSerial = 138,
  BatteryControllerAlternateSerial = 139,
  H2SControllerAlternateSerial = 140,
  HaulierTag = 141,
  ConductivityAlias = 142,
  ConductivityControllerAlternateSerial = 143,
  ConductivityControllerIcc = 144,
  ConductivityControllerIpAddress = 145,
  ConductivityControllerSerial = 146,
  ConductivityHealth = 147,
  ConductivityLastSampleTime = 148,
  ConductivityMax = 149,
  ConductivityMin = 150,
  ConductivityMean = 151,
  ConductivityParked = 152,
  ConductivityLastSample = 153,
  ConductivityUnit = 154,
  ConductivityControllerType = 155,
  CustomerProductRef = 156,
  TankMaximum = 157,
  TankMinimum = 158,
  DoseCustomerFactor = 159,
  BatteryRuntimeService = 160,
  H2SRuntimeCalibration = 161,
  AccessTagAlias = 162,
  TankControllerPosition = 163,
  H2SControllerPosition = 164,
  BatteryControllerPosition = 165,
  DoseControllerPosition = 166,
  FlowControllerPosition = 167,
  ConductivityControllerPosition = 168,
  TemperatureControllerPosition = 169,
  TankAlias = 170,
  CreationServiceNowTicket = 171,
  TemperatureMin = 172,
  StockVsDoseCalibration5 = 173,
}

//These are columns in delivery note manager (DNM)
export enum ColumnSortProperty {
  SiteName = 1,
  BUID = 2,
  CustomerName = 3,
  ProductName = 4,
  RefillDate = 5,
  AmountFilled = 6,
  Source = 7,
  ShipTo = 8,
  Reference = 9,
  SapAddress = 10,
  SapCity = 11,
  SapPostCode = 12,
  SapName = 13,
  FileName = 14,
  HaulierTag = 15,
  CountryCode = 16,
  SoldTo = 17,    
  Confirmed = 18,
}

interface IColumnAccessValue {
  key: ColumnAccess;
  /**
   * Translation-key on the column
   */
  languageKey: string;

  /**
   * If the column is default shown to the user. The user should always have access to these.
   */
  default: boolean;

  /**
   * This column is always shown and cannot be hidden in sitelist
   */
  locked: boolean;

  /**
   * What kind of filter-box should be shown. TODO: This is just a relic from the db-days and should be removed when we have time.
   * Cannot use union type here because of the filter-methods created in sitelist
   */
  filter: string | undefined; //'text' | 'number' | 'date' | undefined;

  /**
   * What kind of property the Column contains. TODO: This is just a relic from the db-days and should be removed when we have time.
   * Cannot use union type here because of the filter-methods created in sitelist
   */
  propertyType: string | undefined; //'string' | 'number' | 'complex' | 'date'

  /**
   * Property-path. Should be removed in the future
   */
  property: string;

  /**
   * Migrated from db. Dunno what this does? Remove?
   */
  sortIndex: number;

  /**
   * If the filter-box should show the possible values to the user. TODO: This is just a relic from the db-days and should be removed when we have time.
   */
  useFilterOptions: boolean;

  /**
   * What kind of widget should be used to show the data
   */
  widget: string | undefined;

  /**
   * The width of the column. If set to 0, it grows to whatever it can.
   */
  width: number;
}

const getValueOrDefault = <T>(k: T | undefined, d: T) => (isNone(k) ? d : k);

const createColumnAccess = (
  obj: Partial<IColumnAccessValue>
): IColumnAccessValue => ({
  key: getValueOrDefault(obj.key, 0),
  languageKey: getValueOrDefault(obj.languageKey, ''),
  default: getValueOrDefault(obj.default, false),
  locked: getValueOrDefault(obj.locked, false),
  filter: getValueOrDefault(obj.filter, 'text'),
  width: getValueOrDefault(obj.width, 150),
  propertyType: getValueOrDefault(obj.propertyType, 'string'),
  property: getValueOrDefault(obj.property, ''),
  sortIndex: obj.sortIndex || 0,
  useFilterOptions: getValueOrDefault(obj.useFilterOptions, false),
  widget: obj.widget
});

export const ColumnConfiguration: IColumnAccessValue[] = [
  createColumnAccess({
    key: ColumnAccess.SiteId,
    sortIndex: 110,
    languageKey: 'UI_Sitelist_Column_SiteId',
    property: 'siteId',
    filter: 'number',
    propertyType: 'number',
    width: 95
  }),
  createColumnAccess({
    key: ColumnAccess.SiteName,
    sortIndex: 10,
    languageKey: 'UI_Sitelist_Column_SiteName',
    property: 'alias',
    width: 0,
    default: true,
    locked: true
  }),
  createColumnAccess({
    key: ColumnAccess.CustomerName,
    sortIndex: 120,
    languageKey: 'UI_Sitelist_Column_CustomerName',
    property: 'customerName'
  }),
  createColumnAccess({
    key: ColumnAccess.Description,
    sortIndex: 130,
    languageKey: 'UI_Sitelist_Column_Description',
    property: 'description',
    width: 0
  }),
  createColumnAccess({
    key: ColumnAccess.AvailableSpace,
    sortIndex: 140,
    languageKey: 'UI_Sitelist_Column_AvailableSpace',
    property: 'availableSpace',
    propertyType: 'number',
    filter: 'number',
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.ParkedDescription,
    sortIndex: 150,
    languageKey: 'UI_Sitelist_Column_ParkedDescription',
    property: 'parkedDescription'
  }),
  createColumnAccess({
    key: ColumnAccess.ProjectCode,
    sortIndex: 160,
    languageKey: 'UI_Sitelist_Column_ProjectCode',
    property: 'projectCode'
  }),
  createColumnAccess({
    key: ColumnAccess.TankPercentFull,
    sortIndex: 20,
    languageKey: 'UI_Sitelist_Column_TankPercentFull',
    property: 'tanks',
    propertyType: 'complex',
    filter: 'number',
    width: 100,
    default: true,
    widget: 'site-channel-bar',
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.SalesPerson,
    sortIndex: 170,
    languageKey: 'UI_Sitelist_Column_SalesPerson',
    property: 'salesPerson'
  }),
  createColumnAccess({
    key: ColumnAccess.SoldTo,
    sortIndex: 180,
    languageKey: 'UI_Sitelist_Column_SoldTo',
    property: 'soldTo'
  }),
  createColumnAccess({
    key: ColumnAccess.CountryCode,
    sortIndex: 200,
    languageKey: 'UI_Sitelist_Column_CountryCode',
    property: 'countryCode'
  }),
  createColumnAccess({
    key: ColumnAccess.CustomerRef,
    sortIndex: 210,
    languageKey: 'UI_Sitelist_Column_CustomerRef',
    property: 'customerRef'
  }),
  createColumnAccess({
    key: ColumnAccess.Buid,
    sortIndex: 220,
    languageKey: 'UI_Sitelist_Column_Buid',
    property: 'buid',
    filter: 'text'
  }),
  createColumnAccess({
    key: ColumnAccess.ParkedSince,
    sortIndex: 230,
    languageKey: 'UI_Sitelist_Column_ParkedSince',
    property: 'parkedSince',
    propertyType: 'date',
    filter: 'date'
  }),
  createColumnAccess({
    key: ColumnAccess.ContractNumber,
    sortIndex: 240,
    languageKey: 'UI_Sitelist_Column_contractReference',
    property: 'contractReference',
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.DeviceType,
    sortIndex: 250,
    languageKey: 'UI_Sitelist_Column_ControllerType',
    property: 'controllerType',
    filter: 'text'
  }),
  createColumnAccess({
    key: ColumnAccess.Imei,
    sortIndex: 260,
    languageKey: 'UI_Sitelist_Column_controllerIMEI',
    property: 'controllerImei'
  }),
  createColumnAccess({
    key: ColumnAccess.IpAddress,
    sortIndex: 270,
    languageKey: 'UI_Sitelist_Column_controllerIpaddress',
    property: 'controllerIpaddress'
  }),
  createColumnAccess({
    key: ColumnAccess.LastSampleDate,
    sortIndex: 60,
    languageKey: 'UI_Sitelist_Column_channelLastSampleTime',
    property: 'tankChannelLastsampletime',
    default: true,
    propertyType: 'date',
    filter: 'date'
  }),
  createColumnAccess({
    key: ColumnAccess.OrderProcess,
    sortIndex: 280,
    languageKey: 'UI_Sitelist_Column_orderProcess',
    property: 'orderProcess'
  }),
  createColumnAccess({
    key: ColumnAccess.Postcode,
    sortIndex: 290,
    languageKey: 'UI_Sitelist_Column_postcode',
    property: 'postcode'
  }),
  createColumnAccess({
    key: ColumnAccess.ProductName,
    sortIndex: 30,
    languageKey: 'UI_Sitelist_Column_channelProductName',
    property: 'tankChannelProductProductName',
    default: true
  }),
  createColumnAccess({
    key: ColumnAccess.ReachMinimum,
    sortIndex: 70,
    languageKey: 'UI_Sitelist_Column_tankReachMinimum',
    property: 'tankReachMinimum',
    default: true,
    propertyType: 'date',
    filter: 'date'
  }),
  createColumnAccess({
    key: ColumnAccess.ReachMinimumInDays,
    sortIndex: 80,
    languageKey: 'UI_Sitelist_Column_tankReachMinimumSpan',
    property: 'tankReachMinimumSpan',
    filter: 'number',
    useFilterOptions: true,
    default: true
  }),
  createColumnAccess({
    key: ColumnAccess.SapNumber,
    sortIndex: 300,
    languageKey: 'UI_Sitelist_Column_tankshipTo',
    property: 'tankShipTo'
  }),
  createColumnAccess({
    key: ColumnAccess.CustomerProductRef,
    sortIndex: 305,
    languageKey: 'UI_Sitelist_Column_tankcustomerproductref',
    property: 'tankcustomerproductref'
  }),
  createColumnAccess({
    key: ColumnAccess.SerialNumber,
    sortIndex: 310,
    languageKey: 'UI_Sitelist_Column_controllerSerial',
    property: 'controllerSerial'
  }),
  createColumnAccess({
    key: ColumnAccess.Icc,
    sortIndex: 320,
    languageKey: 'UI_Sitelist_Column_controllerIcc',
    property: 'controllerICC'
  }),
  createColumnAccess({
    key: ColumnAccess.TankDescription,
    sortIndex: 330,
    languageKey: 'UI_Sitelist_Column_tankDescription',
    property: 'tankDescription'
  }),
  createColumnAccess({
    key: ColumnAccess.TankLastFilled,
    sortIndex: 340,
    languageKey: 'UI_Sitelist_Column_tankLastFilledSpan',
    property: 'tankLastFilledSpan',
    propertyType: 'date',
    filter: 'date'
  }),
  createColumnAccess({
    key: ColumnAccess.TankLevel,
    sortIndex: 40,
    languageKey: 'UI_Sitelist_Column_channelLastSample',
    property: 'tankChannelLastSample',
    filter: 'number',
    propertyType: 'number',
    default: true,
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.TankLevelInTonnes,
    sortIndex: 350,
    languageKey: 'UI_Sitelist_Column_currentLevelTonnes',
    property: 'tankCurrentLevelTonnes',
    useFilterOptions: true,
    propertyType: 'number',
    filter: 'number'
  }),
  createColumnAccess({
    key: ColumnAccess.TankUnit,
    sortIndex: 50,
    languageKey: 'UI_Sitelist_Column_channelUnitName',
    property: 'tankChannelUnitName',
    default: true
  }),
  createColumnAccess({
    key: ColumnAccess.Telephone,
    sortIndex: 360,
    languageKey: 'UI_Sitelist_Column_controllerMsisdn',
    property: 'controllerMSISDN'
  }),
  createColumnAccess({
    key: ColumnAccess.TankVehicle,
    sortIndex: 370,
    languageKey: 'UI_Sitelist_Column_tankVehicleName',
    property: 'tankVehicleName'
  }),
  createColumnAccess({
    key: ColumnAccess.ControllerLatitude,
    sortIndex: 380,
    languageKey: 'UI_Sitelist_Column_controllerLatitude',
    property: 'controllerLatitude',
    filter: 'number',
    propertyType: 'number'
  }),
  createColumnAccess({
    key: ColumnAccess.ControllerLongitude,
    sortIndex: 390,
    languageKey: 'UI_Sitelist_Column_controllerLongitude',
    property: 'controllerLongitude',
    filter: 'number',
    propertyType: 'number'
  }),
  createColumnAccess({
    key: ColumnAccess.Signal,
    sortIndex: 100,
    languageKey: 'UI_Sitelist_Column_SiteSignal',
    property: 'signal',
    width: 60,
    filter: '',
    default: true,
    widget: 'site-signal'
  }),
  createColumnAccess({
    key: ColumnAccess.SiteHealth,
    sortIndex: 90,
    languageKey: 'UI_Sitelist_Column_SiteHealth',
    property: 'siteHealth',
    filter: '',
    propertyType: 'complex',
    width: 80,
    default: true,
    widget: 'site-status-bar'
  }),
  createColumnAccess({
    key: ColumnAccess.ControllerAlternateSerial,
    sortIndex: 400,
    languageKey: 'UI_Sitelist_Column_ControllerAlternateSerial',
    property: 'controllerAlternateSerial',
    filter: 'number',
    propertyType: 'number'
  }),
  createColumnAccess({
    key: ColumnAccess.TankCapacity,
    sortIndex: 410,
    languageKey: 'UI_Sitelist_Column_TankCapacity',
    property: 'tankCapacity',
    width: 80,
    filter: 'number',
    propertyType: 'number',
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.TankMaximum,
    sortIndex: 410,
    languageKey: 'UI_Sitelist_Column_TankMaximum',
    property: 'tankMaximum',
    width: 80,
    filter: 'number',
    propertyType: 'number',
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.TankMinimum,
    sortIndex: 410,
    languageKey: 'UI_Sitelist_Column_TankMinimum',
    property: 'tankMinimum',
    width: 80,
    filter: 'number',
    propertyType: 'number',
    useFilterOptions: true
  }),
  createColumnAccess({
    key: ColumnAccess.ControllerSimProvider,
    sortIndex: 430,
    languageKey: 'UI_Sitelist_Column_ControllerSimProvider',
    property: 'controllerSimProvider',
    width: 80
  }),
  createColumnAccess({
    key: ColumnAccess.ServiceProvider,
    sortIndex: 440,
    languageKey: 'UI_Sitelist_Column_ServiceProvider',
    property: 'serviceProvider',
    width: 80
  })
];
