import { IAsyncReducerState, IRequestState } from '../interfaces/index';
import {
  mergeObjects,
  toObjectAndMap,
  isNone,
  requestFailed,
  requestLoading,
  requestLoaded,
  defaultRequest,
  isEmpty,
  emptyArray,
  distinct
} from './index';
import {
  createFetchedEntity,
  IAsyncEntity,
  IAsyncDictionary,
  IAsyncFetchingEntityState,
  IAsyncFetchedEntityState,
  IObjectDictionary,
  createFailedFetchingEntity,
  changeEntityOfFetchedEntity,
  setPendingFetchedEntity,
  createPendingEntity
} from '../types/index';
import memoizee from 'memoizee';

export const getAsyncEntity = <T>(
  dictionary: IAsyncDictionary<T>,
  key: string | number | undefined
) => (key ? dictionary[key] : undefined);

export const getEntity = <T>(entity: IAsyncFetchedEntityState<T>) =>
  entity.entity;

export const asyncEntityIsFetched = <T>(
  check: IAsyncEntity<T>
): check is IAsyncFetchedEntityState<T> => !isNone(check) && check.fetched;

export const asyncEntititesAreFetched = <T>(
  check: Array<IAsyncEntity<T>>
): check is Array<IAsyncFetchedEntityState<T>> =>
  check.every(asyncEntityIsFetched);

export const getAsyncEntitiesByAsyncArray = <T>(
  asyncEntity: IAsyncEntity<number[]>,
  dictionary: IAsyncDictionary<T>
): IAsyncEntity<T[]> => {
  if (!asyncEntityIsFetched(asyncEntity)) return asyncEntity;
  const relationshipIds = getEntity(asyncEntity);
  if (isEmpty(relationshipIds)) return createFetchedEntity(emptyArray);
  const relationshipAsyncEntities = relationshipIds.map(relationshipId =>
    getAsyncEntity(dictionary, relationshipId)
  );

  if (asyncEntititesAreFetched(relationshipAsyncEntities))
    return createFetchedEntity(relationshipAsyncEntities.map(getEntity));
  const error = relationshipAsyncEntities.find(r => !isNone(r) && r.error);
  if (error) return error as IAsyncFetchingEntityState;
  const pending = relationshipAsyncEntities.find(r => !isNone(r) && r.loading);
  return pending ? (pending as IAsyncFetchingEntityState) : undefined;
};

export const getAsyncEntitiesByAsyncArrayMemoized = memoizee(
  getAsyncEntitiesByAsyncArray
);

export const asyncEntityHasBeenTriedToFetchOrIsFetching = <T>(
  check: IAsyncEntity<T>
) => !isNone(check);

export const bindFetchedAsyncEntity = <T, E>(
  asyncEntity: IAsyncEntity<T>,
  bind: (entity: T) => IAsyncEntity<E>
): IAsyncEntity<E> =>
  asyncEntityIsFetched(asyncEntity)
    ? bind(getEntity(asyncEntity))
    : asyncEntity;

export const doWithFetchedAsyncEntity = <T>(
  asyncEntity: IAsyncEntity<T>,
  doFunc: (entity: T) => void
) =>
  asyncEntityIsFetched(asyncEntity)
    ? doFunc(getEntity(asyncEntity))
    : undefined;

export const mapFetchedAsyncEntity = <T, E>(
  asyncEntity: IAsyncEntity<T>,
  map: (entity: T) => E
): IAsyncEntity<E> =>
  asyncEntityIsFetched(asyncEntity)
    ? changeEntityOfFetchedEntity(asyncEntity, map(getEntity(asyncEntity)))
    : asyncEntity;

// Reducer helpers - Moving models between states

// Fetched - Clean
export const insertFetchedEntity = <T>(
  state: IAsyncReducerState<T>,
  key: number,
  entity: T,
  insertIntoAllIds: boolean = true
): IAsyncReducerState<T> =>
  mergeObjects(state, {
    byId: mergeObjects(state.byId, { [key]: createFetchedEntity(entity) }),
    allIds: insertIntoAllIds
      ? mapFetchedAsyncEntity(state.allIds, ids => distinct([...ids, key]))
      : state.allIds
  });

export const insertFetchedEntities = <T>(
  state: IAsyncReducerState<T>,
  entities: T[],
  keySelector: (e: T) => number,
  insertIntoAllIds: boolean = true
): IAsyncReducerState<T> =>
  mergeObjects(state, {
    byId: mergeObjects(
      state.byId,
      toObjectAndMap(entities, keySelector, createFetchedEntity)
    ),
    allIds: insertIntoAllIds
      ? mapFetchedAsyncEntity(state.allIds, ids =>
          distinct([...ids, ...entities.map(keySelector)])
        )
      : state.allIds
  });

export const insertAllFetchedEntities = <T>(
  state: IAsyncReducerState<T>,
  entities: T[],
  keySelector: (e: T) => number
): IAsyncReducerState<T> =>
  mergeObjects(state, {
    byId: mergeObjects(
      state.byId,
      toObjectAndMap(entities, keySelector, createFetchedEntity)
    ),
    allIds: createFetchedEntity(entities.map(keySelector))
  });

export const insertInflightEntity = <T>(
  state: IAsyncReducerState<T>,
  key: number
): IAsyncReducerState<T> =>
  mergeObjects(state, {
    byId: mergeObjects(state.byId, { [key]: createPendingEntity() })
  });

export const insertInflightEntities = <T>(
  state: IAsyncReducerState<T>,
  keys: number[]
): IAsyncReducerState<T> =>
  mergeObjects(state, {
    byId: mergeObjects(
      state.byId,
      toObjectAndMap(keys, k => k, () => createPendingEntity())
    )
  });

// Remove
export const removeAsyncObject = <T>(
  state: IAsyncReducerState<T>,
  id: number
): IAsyncReducerState<T> => {
  const asyncEntityToRemove = state.byId[id];
  if (!asyncEntityIsFetched(asyncEntityToRemove)) return state;

  return mergeObjects(state, {
    allIds: mapFetchedAsyncEntity(state.allIds, allIdsEntity =>
      allIdsEntity.filter(idToCheck => idToCheck !== id)
    ),
    byId: mergeObjects(state.byId, {
      [id]: undefined
    })
  });
};

export const getAsyncEntities = <T>(
  ids: number[],
  dictionary: IAsyncDictionary<T>
): IAsyncEntity<T[]> => {
  const relationshipAsyncEntities = ids.map(relationshipId =>
    getAsyncEntity(dictionary, relationshipId)
  );

  if (asyncEntititesAreFetched(relationshipAsyncEntities))
    return createFetchedEntity(relationshipAsyncEntities.map(getEntity));
  const error = relationshipAsyncEntities.find(r => !isNone(r) && r.error);
  if (error) return error as IAsyncFetchingEntityState;
  const pending = relationshipAsyncEntities.find(r => !isNone(r) && r.loading);
  return pending ? (pending as IAsyncFetchingEntityState) : undefined;
};

export const removeAsyncObjects = <T>(
  state: IAsyncReducerState<T>,
  ids: number[]
): IAsyncReducerState<T> => {
  const asyncEntitiesToRemove = getAsyncEntities(ids, state.byId);
  const { allIds } = state;
  if (!asyncEntityIsFetched(asyncEntitiesToRemove)) return state;

  return mergeObjects(state, {
    allIds: mapFetchedAsyncEntity(allIds, allIdsEntity =>
      allIdsEntity.filter(idToCheck => ids.indexOf(idToCheck) === -1)
    ),
    byId: mergeObjects(state.byId, toObjectAndMap(ids, k => k, () => undefined))
  });
};

// Failed fetching
export const failedFetchingEntity = <T>(
  state: IAsyncReducerState<T>,
  key: number,
  errorMessage: string
): IAsyncReducerState<T> =>
  mergeObjects(state, {
    byId: mergeObjects(state.byId, {
      [key]: createFailedFetchingEntity(errorMessage)
    })
  });

export const setPendingFetchedEntityInState = <T>(
  state: IAsyncReducerState<T>,
  key: number,
  pendingEntity: Partial<T>
): IAsyncReducerState<T> => {
  const asyncEntity = getAsyncEntity(state.byId, key);
  if (!asyncEntityIsFetched(asyncEntity)) return state;
  return mergeObjects(state, {
    byId: mergeObjects(state.byId, {
      [key]: setPendingFetchedEntity(asyncEntity, pendingEntity)
    })
  });
};

// Reducer helpers end - Moving models between states

export const changeEntityState = <T>(
  state: IAsyncReducerState<T>,
  key: number,
  entityChanger: (e: IAsyncEntity<T>) => IAsyncEntity<T>
) =>
  mergeObjects(state, {
    byId: mergeObjects(state.byId, {
      [key]: entityChanger(getAsyncEntity(state.byId, key))
    })
  });

export const markEntityAsSaving = <T>(entity: IAsyncEntity<T>) =>
  mergeObjects(entity, { saving: true });

export const markEntityAsSavingInState = <T>(
  state: IAsyncReducerState<T>,
  key: number
) => changeEntityState(state, key, markEntityAsSaving);

export const markEntitiesAsSavingInState = <T>(
  state: IAsyncReducerState<T>,
  entities: T[],
  keySelector: (e: T) => number
) =>
  mergeObjects(state, {
    byId: mergeObjects(
      state.byId,
      toObjectAndMap(entities, keySelector, createFetchedEntity)
    )
  });

export const entityHasFinishedSaving = <T>(entity: IAsyncEntity<T>) =>
  mergeObjects(entity, { saving: false });

export const getAllEntities = <T>(
  state: IAsyncReducerState<T>
): IAsyncEntity<T[]> => getAsyncEntitiesByAsyncArray(state.allIds, state.byId);

export const allEntitiesAreFetched = <T>(
  state: IAsyncReducerState<T>
): boolean => asyncEntityIsFetched(getAllEntities(state));

export const getPendingEntity = <T>(
  dictionary: IAsyncDictionary<T>,
  key: number
): Partial<T> | undefined => {
  const asyncEntity = getAsyncEntity(dictionary, key);
  if (!asyncEntityIsFetched(asyncEntity)) return undefined;
  return asyncEntity.pendingEntity;
};

export const getPendingEntityFromState = <T>(
  state: IAsyncReducerState<T>,
  key: number
): Partial<T> | undefined => getPendingEntity(state.byId, key);

export const getAsyncEntitiesMemoized = memoizee(getAsyncEntities);

interface IAsyncEntityFetchedPatters<T, U> {
  fetched: (entity: T) => U;
  notFetched: () => U;
}

export const asyncEntityHasPendingChanges = <T>(
  asyncEntity: IAsyncEntity<T>
): boolean =>
  asyncEntityIsFetched(asyncEntity) && !isNone(asyncEntity.pendingEntity);

export const caseOfAsyncEntityFetched = <T, U>(
  asyncEntity: IAsyncEntity<T>,
  pattern: IAsyncEntityFetchedPatters<T, U>
) =>
  asyncEntityIsFetched(asyncEntity)
    ? pattern.fetched(asyncEntity.entity)
    : pattern.notFetched();

export const getEntitiesStatus = (
  asyncEntity: Array<IAsyncEntity<any>>,
  nullIsPending: boolean = false
): IRequestState =>
  asyncEntity.every(isNone)
    ? nullIsPending
      ? requestLoading()
      : defaultRequest
    : asyncEntity.every(asyncEntityIsFetched)
    ? requestLoaded
    : asyncEntity.some(a => !isNone(a) && a.error)
    ? requestFailed('')
    : requestLoading();

export const getEntityOrUndefined = <T>(async: IAsyncEntity<T>) =>
  asyncEntityIsFetched(async) ? getEntity(async) : undefined;

export const getEntityOrDefault = <T>(
  async: IAsyncEntity<T>,
  defaultvalue: T
) => (asyncEntityIsFetched(async) ? getEntity(async) : defaultvalue);

const mergeFetchedEntity = <T>(
  asyncEntity: IAsyncFetchedEntityState<T>,
  func: (entity: T) => Partial<T>
) =>
  mergeObjects(asyncEntity, {
    entity: mergeObjects(asyncEntity.entity, func(asyncEntity.entity))
  });

export const mergeDoneEntityInDictionary = <T>(
  asyncDictionary: IAsyncDictionary<T>,
  key: string | number,
  func: (entity: T) => Partial<T>
) => {
  const asyncEntity = getAsyncEntity(asyncDictionary, key);
  if (!asyncEntityIsFetched(asyncEntity)) return asyncDictionary;
  return mergeObjects(asyncDictionary, {
    [key]: mergeFetchedEntity(asyncEntity, func)
  });
};

export const mergeDoneEntityInState = <T>(
  state: IAsyncReducerState<T>,
  key: string | number,
  func: (entity: T) => Partial<T>
) =>
  mergeObjects(state, {
    byId: mergeDoneEntityInDictionary(state.byId, key, func)
  });

export const mergeDoneEntitiesInDictionary = <T>(
  asyncDictionary: IAsyncDictionary<T>,
  keys: Array<string | number>,
  func: (entity: T) => Partial<T>
) => {
  let dictionary = asyncDictionary;
  for (const key of keys)
    dictionary = mergeDoneEntityInDictionary(dictionary, key, func);
  return dictionary;
};

export const mergeDoneEntitiesInState = <T>(
  state: IAsyncReducerState<T>,
  keys: Array<string | number>,
  func: (entity: T) => Partial<T>
) =>
  mergeObjects(state, {
    byId: mergeDoneEntitiesInDictionary(state.byId, keys, func)
  });

export const setEntity = <T>(
  asyncEntity: IAsyncEntity<T>,
  func: (entity: T) => T
) =>
  asyncEntityIsFetched(asyncEntity)
    ? createFetchedEntity(func(getEntity(asyncEntity)))
    : asyncEntity;

export const setDoneEntityInDictionary = <T>(
  asyncDictionary: IAsyncDictionary<T>,
  key: string | number,
  func: (entity: T) => T
) => {
  const asyncEntity = getAsyncEntity(asyncDictionary, key);
  if (!asyncEntityIsFetched(asyncEntity)) return asyncDictionary;
  return mergeObjects(asyncDictionary, { [key]: setEntity(asyncEntity, func) });
};

export const removeAsyncEntityInDictionary = <T>(
  asyncDictionary: IAsyncDictionary<T>,
  key: string | number
) => ({
  ...asyncDictionary,
  [key]: undefined
});

export const removeAsyncEntitiesInDictionary = <T>(
  asyncDictionary: IAsyncDictionary<T>,
  keys: (string | number)[]
) => {
  let items = asyncDictionary;
  for (const key of keys) items[key] = undefined;
  return items;
};

export const removeEntityInDictionary = <T>(
  asyncDictionary: IAsyncDictionary<T>,
  key: string | number
) => setDoneEntityInDictionary(asyncDictionary, key, () => undefined);

export const mergeEntity = <T>(
  asyncEntity: IAsyncEntity<T>,
  func: (entity: T) => Partial<T>
) =>
  asyncEntityIsFetched(asyncEntity)
    ? mergeFetchedEntity(asyncEntity, func)
    : asyncEntity;

export const createAsyncDictionaryForSiteRelations = <T>(
  entities: T[],
  siteIdFunc: (entity: T) => number | undefined,
  entityIdFunc: (entity: T) => number
) => {
  const entitiesPerSite = entities.reduce<IObjectDictionary<number[]>>(
    (dictionary, entity) => {
      const entityKey = entityIdFunc(entity);
      const siteIdKey = siteIdFunc(entity);
      if (isNone(siteIdKey)) return dictionary;
      (dictionary[siteIdKey] = dictionary[siteIdKey] || []).push(entityKey);
      return dictionary;
    },
    {}
  );

  const newDictionary: IAsyncDictionary<number[]> = {};
  Object.keys(entitiesPerSite).forEach(key => {
    const entitiesForSite = entitiesPerSite[key];
    if (isNone(entitiesForSite)) return;
    newDictionary[key] = createFetchedEntity(entitiesForSite);
  });
  return newDictionary;
};
