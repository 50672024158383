import { requests } from '../config';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { BlobService } from './blobService';
import { BaseService } from './baseService';
import { BlobTypes, ISiteGalleryItem } from '../interfaces';
import { getLogger } from 'aurelia-logging';

@autoinject()
export class GalleryService extends BaseService {
  constructor(
    httpClient: HttpClient,
    private blobService: BlobService,
    taskQueue: TaskQueue
  ) {
    super(httpClient, getLogger(GalleryService.name), taskQueue);
  }

  uploadGalleryImageForSite = async (siteId: number, file: File) => {
    const blob = await this.blobService.uploadFile(
      file,
      BlobTypes.GalleryItems
    );
    const request = requests.uploadGalleryItem(siteId, blob.blobId);

    return await this.httpRequest<ISiteGalleryItem>(request);
  };

  deleteGalleryItem = (galleryItemId: number) =>
    this.httpRequest(requests.deleteGalleryItem(galleryItemId));
}
