import { ITimeZone } from '../../interfaces';

export const timezones: ITimeZone[] = [{
  timezoneId: "1",
  windows: "Dateline Standard Time",
  description: "(UTC-12:00) International Date Line West",
  iana: "Etc/GMT+12"
}, {
  timezoneId: "2",
  windows: "Tomsk Standard Time",
  description: "(UTC+07:00) Tomsk",
  iana: "Asia/Tomsk"
}, {
  timezoneId: "3",
  windows: "N. Central Asia Standard Time",
  description: "(UTC+07:00) Novosibirsk",
  iana: "Asia/Novosibirsk"
}, {
  timezoneId: "4",
  windows: "North Asia Standard Time",
  description: "(UTC+07:00) Krasnoyarsk",
  iana: "Asia/Krasnoyarsk"
}, {
  timezoneId: "5",
  windows: "W. Mongolia Standard Time",
  description: "(UTC+07:00) Hovd",
  iana: "Asia/Hovd"
}, {
  timezoneId: "6",
  windows: "Altai Standard Time",
  description: "(UTC+07:00) Barnaul, Gorno-Altaysk",
  iana: "Asia/Barnaul"
}, {
  timezoneId: "7",
  windows: "SE Asia Standard Time",
  description: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
  iana: "Asia/Bangkok"
}, {
  timezoneId: "8",
  windows: "Myanmar Standard Time",
  description: "(UTC+06:30) Yangon (Rangoon)",
  iana: "Asia/Yangon"
}, {
  timezoneId: "9",
  windows: "Omsk Standard Time",
  description: "(UTC+06:00) Omsk",
  iana: "Asia/Omsk"
}, {
  timezoneId: "10",
  windows: "Bangladesh Standard Time",
  description: "(UTC+06:00) Dhaka",
  iana: "Asia/Dhaka"
}, {
  timezoneId: "11",
  windows: "Central Asia Standard Time",
  description: "(UTC+06:00) Astana",
  iana: "Asia/Almaty"
}, {
  timezoneId: "12",
  windows: "Nepal Standard Time",
  description: "(UTC+05:45) Kathmandu",
  iana: "Asia/Kathmandu"
}, {
  timezoneId: "13",
  windows: "Sri Lanka Standard Time",
  description: "(UTC+05:30) Sri Jayawardenepura",
  iana: "Asia/Colombo"
}, {
  timezoneId: "14",
  windows: "India Standard Time",
  description: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  iana: "Asia/Kolkata"
}, {
  timezoneId: "15",
  windows: "Pakistan Standard Time",
  description: "(UTC+05:00) Islamabad, Karachi",
  iana: "Asia/Karachi"
}, {
  timezoneId: "16",
  windows: "Ekaterinburg Standard Time",
  description: "(UTC+05:00) Ekaterinburg",
  iana: "Asia/Yekaterinburg"
}, {
  timezoneId: "17",
  windows: "West Asia Standard Time",
  description: "(UTC+05:00) Ashgabat, Tashkent",
  iana: "Asia/Tashkent"
}, {
  timezoneId: "18",
  windows: "Afghanistan Standard Time",
  description: "(UTC+04:30) Kabul",
  iana: "Asia/Kabul"
}, {
  timezoneId: "19",
  windows: "Caucasus Standard Time",
  description: "(UTC+04:00) Yerevan",
  iana: "Asia/Yerevan"
}, {
  timezoneId: "20",
  windows: "Georgian Standard Time",
  description: "(UTC+04:00) Tbilisi",
  iana: "Asia/Tbilisi"
}, {
  timezoneId: "21",
  windows: "Mauritius Standard Time",
  description: "(UTC+04:00) Port Louis",
  iana: "Indian/Mauritius"
}, {
  timezoneId: "22",
  windows: "Russia Time Zone 3",
  description: "(UTC+04:00) Izhevsk, Samara",
  iana: "Europe/Samara"
}, {
  timezoneId: "23",
  windows: "Azerbaijan Standard Time",
  description: "(UTC+04:00) Baku",
  iana: "Asia/Baku"
}, {
  timezoneId: "24",
  windows: "Astrakhan Standard Time",
  description: "(UTC+04:00) Astrakhan, Ulyanovsk",
  iana: "Europe/Astrakhan"
}, {
  timezoneId: "25",
  windows: "Arabian Standard Time",
  description: "(UTC+04:00) Abu Dhabi, Muscat",
  iana: "Asia/Dubai"
}, {
  timezoneId: "26",
  windows: "Iran Standard Time",
  description: "(UTC+03:30) Tehran",
  iana: "Asia/Tehran"
}, {
  timezoneId: "27",
  windows: "E. Africa Standard Time",
  description: "(UTC+03:00) Nairobi",
  iana: "Africa/Nairobi"
}, {
  timezoneId: "28",
  windows: "Russian Standard Time",
  description: "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
  iana: "Europe/Moscow"
}, {
  timezoneId: "29",
  windows: "Belarus Standard Time",
  description: "(UTC+03:00) Minsk",
  iana: "Europe/Minsk"
}, {
  timezoneId: "30",
  windows: "Arab Standard Time",
  description: "(UTC+03:00) Kuwait, Riyadh",
  iana: "Asia/Riyadh"
}, {
  timezoneId: "31",
  windows: "China Standard Time",
  description: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  iana: "Asia/Shanghai"
}, {
  timezoneId: "32",
  windows: "Turkey Standard Time",
  description: "(UTC+03:00) Istanbul",
  iana: "Europe/Istanbul"
}, {
  timezoneId: "33",
  windows: "North Asia East Standard Time",
  description: "(UTC+08:00) Irkutsk",
  iana: "Asia/Irkutsk"
}, {
  timezoneId: "34",
  windows: "W. Australia Standard Time",
  description: "(UTC+08:00) Perth",
  iana: "Australia/Perth"
}, {
  timezoneId: "35",
  windows: "Tonga Standard Time",
  description: "(UTC+13:00) Nuku'alofa",
  iana: "Pacific/Tongatapu"
}, {
  timezoneId: "36",
  windows: "Chatham Islands Standard Time",
  description: "(UTC+12:45) Chatham Islands",
  iana: "Pacific/Chatham"
}, {
  timezoneId: "37",
  windows: "Kamchatka Standard Time",
  description: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
  iana: "Asia/Kamchatka"
}, {
  timezoneId: "38",
  windows: "Fiji Standard Time",
  description: "(UTC+12:00) Fiji",
  iana: "Pacific/Fiji"
}, {
  timezoneId: "39",
  windows: "UTC+12",
  description: "(UTC+12:00) Coordinated Universal Time+12",
  iana: "Etc/GMT-12"
}, {
  timezoneId: "40",
  windows: "New Zealand Standard Time",
  description: "(UTC+12:00) Auckland, Wellington",
  iana: "Pacific/Auckland"
}, {
  timezoneId: "41",
  windows: "Russia Time Zone 11",
  description: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
  iana: "Asia/Kamchatka"
}, {
  timezoneId: "42",
  windows: "Central Pacific Standard Time",
  description: "(UTC+11:00) Solomon Is., New Caledonia",
  iana: "Pacific/Guadalcanal"
}, {
  timezoneId: "43",
  windows: "Sakhalin Standard Time",
  description: "(UTC+11:00) Sakhalin",
  iana: "Asia/Sakhalin"
}, {
  timezoneId: "44",
  windows: "Norfolk Standard Time",
  description: "(UTC+11:00) Norfolk Island",
  iana: "Pacific/Norfolk"
}, {
  timezoneId: "45",
  windows: "Magadan Standard Time",
  description: "(UTC+11:00) Magadan",
  iana: "Asia/Magadan"
}, {
  timezoneId: "46",
  windows: "Russia Time Zone 10",
  description: "(UTC+11:00) Chokurdakh",
  iana: "Asia/Srednekolymsk"
}, {
  timezoneId: "47",
  windows: "Bougainville Standard Time",
  description: "(UTC+11:00) Bougainville Island",
  iana: "Pacific/Bougainville"
}, {
  timezoneId: "48",
  windows: "Lord Howe Standard Time",
  description: "(UTC+10:30) Lord Howe Island",
  iana: "Australia/Lord_Howe"
}, {
  timezoneId: "49",
  windows: "Vladivostok Standard Time",
  description: "(UTC+10:00) Vladivostok",
  iana: "Asia/Vladivostok"
}, {
  timezoneId: "50",
  windows: "Tasmania Standard Time",
  description: "(UTC+10:00) Hobart",
  iana: "Australia/Hobart"
}, {
  timezoneId: "51",
  windows: "West Pacific Standard Time",
  description: "(UTC+10:00) Guam, Port Moresby",
  iana: "Pacific/Port_Moresby"
}, {
  timezoneId: "52",
  windows: "AUS Eastern Standard Time",
  description: "(UTC+10:00) Canberra, Melbourne, Sydney",
  iana: "Australia/Sydney"
}, {
  timezoneId: "53",
  windows: "E. Australia Standard Time",
  description: "(UTC+10:00) Brisbane",
  iana: "Australia/Brisbane"
}, {
  timezoneId: "54",
  windows: "AUS Central Standard Time",
  description: "(UTC+09:30) Darwin",
  iana: "Australia/Darwin"
}, {
  timezoneId: "55",
  windows: "Cen. Australia Standard Time",
  description: "(UTC+09:30) Adelaide",
  iana: "Australia/Adelaide"
}, {
  timezoneId: "56",
  windows: "Yakutsk Standard Time",
  description: "(UTC+09:00) Yakutsk",
  iana: "Asia/Yakutsk"
}, {
  timezoneId: "57",
  windows: "Korea Standard Time",
  description: "(UTC+09:00) Seoul",
  iana: "Asia/Seoul"
}, {
  timezoneId: "58",
  windows: "Tokyo Standard Time",
  description: "(UTC+09:00) Osaka, Sapporo, Tokyo",
  iana: "Asia/Tokyo"
}, {
  timezoneId: "59",
  windows: "Transbaikal Standard Time",
  description: "(UTC+09:00) Chita",
  iana: "Asia/Chita"
}, {
  timezoneId: "60",
  windows: "Aus Central W. Standard Time",
  description: "(UTC+08:45) Eucla",
  iana: "Australia/Eucla"
}, {
  timezoneId: "61",
  windows: "North Korea Standard Time",
  description: "(UTC+08:30) Pyongyang",
  iana: "Asia/Pyongyang"
}, {
  timezoneId: "62",
  windows: "Ulaanbaatar Standard Time",
  description: "(UTC+08:00) Ulaanbaatar",
  iana: "Asia/Ulaanbaatar"
}, {
  timezoneId: "63",
  windows: "Taipei Standard Time",
  description: "(UTC+08:00) Taipei",
  iana: "Asia/Taipei"
}, {
  timezoneId: "64",
  windows: "Singapore Standard Time",
  description: "(UTC+08:00) Kuala Lumpur, Singapore",
  iana: "Asia/Singapore"
}, {
  timezoneId: "65",
  windows: "Arabic Standard Time",
  description: "(UTC+03:00) Baghdad",
  iana: "Asia/Baghdad"
}, {
  timezoneId: "66",
  windows: "Libya Standard Time",
  description: "(UTC+02:00) Tripoli",
  iana: "Africa/Tripoli"
}, {
  timezoneId: "67",
  windows: "Kaliningrad Standard Time",
  description: "(UTC+02:00) Kaliningrad",
  iana: "Europe/Kaliningrad"
}, {
  timezoneId: "68",
  windows: "Pacific SA Standard Time",
  description: "(UTC-04:00) Santiago",
  iana: "America/Santiago"
}, {
  timezoneId: "69",
  windows: "SA Western Standard Time",
  description: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
  iana: "America/La_Paz"
}, {
  timezoneId: "70",
  windows: "Central Brazilian Standard Time",
  description: "(UTC-04:00) Cuiaba",
  iana: "America/Cuiaba"
}, {
  timezoneId: "71",
  windows: "Venezuela Standard Time",
  description: "(UTC-04:00) Caracas",
  iana: "America/Caracas"
}, {
  timezoneId: "72",
  windows: "Atlantic Standard Time",
  description: "(UTC-04:00) Atlantic Time (Canada)",
  iana: "America/Halifax"
}, {
  timezoneId: "73",
  windows: "Paraguay Standard Time",
  description: "(UTC-04:00) Asuncion",
  iana: "America/Asuncion"
}, {
  timezoneId: "74",
  windows: "US Eastern Standard Time",
  description: "(UTC-05:00) Indiana (East)",
  iana: "America/Indiana/Indianapolis"
}, {
  timezoneId: "75",
  windows: "Cuba Standard Time",
  description: "(UTC-05:00) Havana",
  iana: "America/Havana"
}, {
  timezoneId: "76",
  windows: "Haiti Standard Time",
  description: "(UTC-05:00) Haiti",
  iana: "America/Port-au-Prince"
}, {
  timezoneId: "77",
  windows: "Eastern Standard Time",
  description: "(UTC-05:00) Eastern Time (US & Canada)",
  iana: "America/New_York"
}, {
  timezoneId: "78",
  windows: "Eastern Standard Time (Mexico)",
  description: "(UTC-05:00) Chetumal",
  iana: "America/Cancun"
}, {
  timezoneId: "79",
  windows: "SA Pacific Standard Time",
  description: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
  iana: "America/Bogota"
}, {
  timezoneId: "80",
  windows: "Canada Central Standard Time",
  description: "(UTC-06:00) Saskatchewan",
  iana: "America/Regina"
}, {
  timezoneId: "81",
  windows: "Central Standard Time (Mexico)",
  description: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
  iana: "America/Mexico_City"
}, {
  timezoneId: "82",
  windows: "Easter Island Standard Time",
  description: "(UTC-06:00) Easter Island",
  iana: "Pacific/Easter"
}, {
  timezoneId: "83",
  windows: "Central Standard Time",
  description: "(UTC-06:00) Central Time (US & Canada)",
  iana: "America/Chicago"
}, {
  timezoneId: "84",
  windows: "Central America Standard Time",
  description: "(UTC-06:00) Central America",
  iana: "America/Guatemala"
}, {
  timezoneId: "85",
  windows: "Mountain Standard Time",
  description: "(UTC-07:00) Mountain Time (US & Canada)",
  iana: "America/Denver"
}, {
  timezoneId: "86",
  windows: "Mountain Standard Time (Mexico)",
  description: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
  iana: "America/Chihuahua"
}, {
  timezoneId: "87",
  windows: "US Mountain Standard Time",
  description: "(UTC-07:00) Arizona",
  iana: "America/Phoenix"
}, {
  timezoneId: "88",
  windows: "Pacific Standard Time",
  description: "(UTC-08:00) Pacific Time (US & Canada)",
  iana: "America/Los_Angeles"
}, {
  timezoneId: "89",
  windows: "UTC-08",
  description: "(UTC-08:00) Coordinated Universal Time-08",
  iana: "Etc/GMT+8"
}, {
  timezoneId: "90",
  windows: "Pacific Standard Time (Mexico)",
  description: "(UTC-08:00) Baja California",
  iana: "America/Tijuana"
}, {
  timezoneId: "91",
  windows: "UTC-09",
  description: "(UTC-09:00) Coordinated Universal Time-09",
  iana: "Etc/GMT+9"
}, {
  timezoneId: "92",
  windows: "Alaskan Standard Time",
  description: "(UTC-09:00) Alaska",
  iana: "America/Anchorage"
}, {
  timezoneId: "93",
  windows: "Marquesas Standard Time",
  description: "(UTC-09:30) Marquesas Islands",
  iana: "Pacific/Marquesas"
}, {
  timezoneId: "94",
  windows: "Hawaiian Standard Time",
  description: "(UTC-10:00) Hawaii",
  iana: "Pacific/Honolulu"
}, {
  timezoneId: "95",
  windows: "Aleutian Standard Time",
  description: "(UTC-10:00) Aleutian Islands",
  iana: "America/Adak"
}, {
  timezoneId: "96",
  windows: "UTC-11",
  description: "(UTC-11:00) Coordinated Universal Time-11",
  iana: "Etc/GMT+11"
}, {
  timezoneId: "97",
  windows: "Turks And Caicos Standard Time",
  description: "(UTC-04:00) Turks and Caicos",
  iana: "America/Grand_Turk"
}, {
  timezoneId: "98",
  windows: "Newfoundland Standard Time",
  description: "(UTC-03:30) Newfoundland",
  iana: "America/St_Johns"
}, {
  timezoneId: "99",
  windows: "Tocantins Standard Time",
  description: "(UTC-03:00) Araguaina",
  iana: "America/Araguaina"
}, {
  timezoneId: "100",
  windows: "E. South America Standard Time",
  description: "(UTC-03:00) Brasilia",
  iana: "America/Sao_Paulo"
}, {
  timezoneId: "101",
  windows: "Israel Standard Time",
  description: "(UTC+02:00) Jerusalem",
  iana: "Asia/Jerusalem"
}, {
  timezoneId: "102",
  windows: "FLE Standard Time",
  description: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  iana: "Europe/Kiev"
}, {
  timezoneId: "103",
  windows: "South Africa Standard Time",
  description: "(UTC+02:00) Harare, Pretoria",
  iana: "Africa/Johannesburg"
}, {
  timezoneId: "104",
  windows: "West Bank Standard Time",
  description: "(UTC+02:00) Gaza, Hebron",
  iana: "Asia/Hebron"
}, {
  timezoneId: "105",
  windows: "Syria Standard Time",
  description: "(UTC+02:00) Damascus",
  iana: "Asia/Damascus"
}, {
  timezoneId: "106",
  windows: "E. Europe Standard Time",
  description: "(UTC+02:00) Chisinau",
  iana: "Europe/Chisinau"
}, {
  timezoneId: "107",
  windows: "Egypt Standard Time",
  description: "(UTC+02:00) Cairo",
  iana: "Africa/Cairo"
}, {
  timezoneId: "108",
  windows: "Middle East Standard Time",
  description: "(UTC+02:00) Beirut",
  iana: "Asia/Beirut"
}, {
  timezoneId: "109",
  windows: "GTB Standard Time",
  description: "(UTC+02:00) Athens, Bucharest",
  iana: "Europe/Bucharest"
}, {
  timezoneId: "110",
  windows: "Jordan Standard Time",
  description: "(UTC+02:00) Amman",
  iana: "Asia/Amman"
}, {
  timezoneId: "111",
  windows: "Namibia Standard Time",
  description: "(UTC+01:00) Windhoek",
  iana: "Africa/Windhoek"
}, {
  timezoneId: "112",
  windows: "W. Central Africa Standard Time",
  description: "(UTC+01:00) West Central Africa",
  iana: "Africa/Lagos"
}, {
  timezoneId: "113",
  windows: "Central European Standard Time",
  description: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  iana: "Europe/Warsaw"
}, {
  timezoneId: "114",
  windows: "Romance Standard Time",
  description: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
  iana: "Europe/Paris"
}, {
  timezoneId: "115",
  windows: "Samoa Standard Time",
  description: "(UTC+13:00) Samoa",
  iana: "Pacific/Apia"
}, {
  timezoneId: "116",
  windows: "Central Europe Standard Time",
  description: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  iana: "Europe/Budapest"
}, {
  timezoneId: "117",
  windows: "Greenwich Standard Time",
  description: "(UTC+00:00) Monrovia, Reykjavik",
  iana: "Atlantic/Reykjavik"
}, {
  timezoneId: "118",
  windows: "GMT Standard Time",
  description: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
  iana: "Europe/London"
}, {
  timezoneId: "119",
  windows: "Morocco Standard Time",
  description: "(UTC+00:00) Casablanca",
  iana: "Africa/Casablanca"
}, {
  timezoneId: "120",
  windows: "UTC",
  description: "(UTC) Coordinated Universal Time",
  iana: "Etc/UTC"
}, {
  timezoneId: "121",
  windows: "Cape Verde Standard Time",
  description: "(UTC-01:00) Cabo Verde Is.",
  iana: "Atlantic/Cape_Verde"
}, {
  timezoneId: "122",
  windows: "Azores Standard Time",
  description: "(UTC-01:00) Azores",
  iana: "Atlantic/Azores"
}, {
  timezoneId: "123",
  windows: "Mid-Atlantic Standard Time",
  description: "(UTC-02:00) Mid-Atlantic - Old",
  iana: "Etc/GMT+2"
}, {
  timezoneId: "124",
  windows: "UTC-02",
  description: "(UTC-02:00) Coordinated Universal Time-02",
  iana: "Etc/GMT+2"
}, {
  timezoneId: "125",
  windows: "Bahia Standard Time",
  description: "(UTC-03:00) Salvador",
  iana: "America/Bahia"
}, {
  timezoneId: "126",
  windows: "Saint Pierre Standard Time",
  description: "(UTC-03:00) Saint Pierre and Miquelon",
  iana: "America/Miquelon"
}, {
  timezoneId: "127",
  windows: "Montevideo Standard Time",
  description: "(UTC-03:00) Montevideo",
  iana: "America/Montevideo"
}, {
  timezoneId: "128",
  windows: "Greenland Standard Time",
  description: "(UTC-03:00) Greenland",
  iana: "America/Godthab"
}, {
  timezoneId: "129",
  windows: "Argentina Standard Time",
  description: "(UTC-03:00) City of Buenos Aires",
  iana: "America/Argentina/Buenos_Aires"
}, {
  timezoneId: "130",
  windows: "SA Eastern Standard Time",
  description: "(UTC-03:00) Cayenne, Fortaleza",
  iana: "America/Cayenne"
}, {
  timezoneId: "131",
  windows: "W. Europe Standard Time",
  description: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  iana: "Europe/Berlin"
}, {
  timezoneId: "132",
  windows: "Line Islands Standard Time",
  description: "(UTC+14:00) Kiritimati Island",
  iana: "Pacific/Kiritimati"
}]
