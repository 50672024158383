export const alarmStatistics = [
  {
    id: 0,
    value: 'UI_SiteDetails_Alarms_Statistics_Last'
  },
  {
    id: 1,
    value: 'UI_SiteDetails_Alarms_Statistics_MinLast12h'
  },
  {
    id: 2,
    value: 'UI_SiteDetails_Alarms_Statistics_MaxLast12h'
  },
  {
    id: 3,
    value: 'UI_SiteDetails_Alarms_Statistics_MinLast24h'
  },
  {
    id: 4,
    value: 'UI_SiteDetails_Alarms_Statistics_MaxLast24h'
  },
  {
    id: 5,
    value: 'UI_SiteDetails_Alarms_Statistics_TotalLast12h'
  },
  {
    id: 6,
    value: 'UI_SiteDetails_Alarms_Statistics_TotalLast24h'
  },
  {
    id: 7,
    value: 'UI_SiteDetails_Alarms_Statistics_AverageLast12h'
  },
  {
    id: 8,
    value: 'UI_SiteDetails_Alarms_Statistics_AverageLast24h'
  },
  {
    id: 9,
    value: 'UI_SiteDetails_Alarms_Statistics_DeltaLast12h'
  },
  {
    id: 10,
    value: 'UI_SiteDetails_Alarms_Statistics_DeltaLast24h'
  },
  {
    id: 11,
    value: 'UI_SiteDetails_Alarms_Statistics_MinLastData'
  },
  {
    id: 12,
    value: 'UI_SiteDetails_Alarms_Statistics_MaxLastData'
  },
  {
    id: 13,
    value: 'UI_SiteDetails_Alarms_Statistics_AverageLastData'
  },
  {
    id: 14,
    value: 'UI_SiteDetails_Alarms_Statistics_TotalLastData'
  }
];

export const alarmOperators = [
  {
    operator: '=',
    resetOperator: '!='
  },
  {
    operator: '<',
    resetOperator: '>='
  },
  {
    operator: '<=',
    resetOperator: '>'
  },
  {
    operator: '>',
    resetOperator: '<='
  },
  {
    operator: '>=',
    resetOperator: '<'
  }
];

export const alarmTypes: Record<number, string> = {
  1: 'UI_SiteDetails_Alarms_Custom',
  2: 'UI_SiteDetails_Alarms_LowLevel',
  3: 'UI_SiteDetails_Alarms_PreLowLevel',
  4: 'UI_SiteDetails_Alarms_Configuration',
  5: 'UI_SiteDetails_Alarms_Offline',
  6: 'UI_SiteDetails_Alarms_PercentCapacity30',
  7: 'UI_SiteDetails_Alarms_Maintenance',
  8: 'UI_SiteDetails_Alarms_DoseCalibration',
  9: 'UI_SiteDetails_Alarms_SeriousDoseCalibration',
  10: 'UI_SiteDetails_Alarms_BrokenSensor'
};

export const alarmTypesLowerCase: Record<number, string> = {
  1: 'ui_sitedetails_alarms_custom',
  2: 'ui_sitedetails_alarms_lowlevel',
  3: 'ui_sitedetails_alarms_prelowlevel',
  4: 'ui_sitedetails_alarms_configuration',
  5: 'ui_sitedetails_alarms_offline',
  6: 'ui_sitedetails_alarms_percentcapacity30',
  7: 'ui_sitedetails_alarms_maintenance',
  8: 'ui_sitedetails_alarms_dosecalibration',
  9: 'ui_sitedetails_alarms_seriousdosecalibration',
  10: 'ui_sitedetails_alarms_brokensensor'
};
