import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getAllAllowedBuids, getAllBuids } from '../actions';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';
import { getAllEntities, getEntityOrUndefined } from '../utility/index';
import { selectBuids } from '../pages/common';

@autoinject
export class BuidService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(BuidService.name), taskQueue);
  }

  getAllBuidsAsync = () =>
    this.httpRequestWithDispatch(
      requests.getAllBuids,
      getAllBuids,
      {},
      'Get all buids failed',
      state => getEntityOrUndefined(getAllEntities(state.buid))
    );

  getAllBuids = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllBuids,
      getAllBuids,
      {},
      'Fetching all buids failed',
      selectBuids
    );

  getAllAllowedBuidsAsync = () =>
    this.httpRequestWithDispatch(
      requests.getAllAllowedBuids(),
      getAllAllowedBuids,
      {},
      'Get all allowed buids failed'
    );
}
