import { customAttribute, autoinject } from 'aurelia-framework';

@customAttribute('when-visible')
@autoinject
export class WhenVisible {
  value: Function;
  visible: (visible: boolean) => void;

  private observer: IntersectionObserver | undefined;

  constructor(private element: Element) {

    const observerFunc = (entries: IntersectionObserverEntry[]) => {
        const [first] = entries;
        this.value && this.value({ isVisible: first.intersectionRatio > 0 })
    }

    if('IntersectionObserver' in window)
      this.observer = new IntersectionObserver(observerFunc);
    else
      this.value && this.value({ isVisible: true });
  }

  attached() {
    if(this.observer)
      this.observer.observe(this.element);
  }
  
  detached() {
    if(this.observer)
      this.observer.unobserve(this.element);
  }
}
