import { autoinject } from 'aurelia-framework';
import { rootState } from '../../reducers';
import { Unsubscribe, Dispatch } from 'redux';
import { isMobileWatcher, isNone, isSomething } from '../../utility';
import { store } from '../../reducers/store';
import { Logger } from 'aurelia-logging';
import { menuIsOpenForEntity } from '../../types';
import { toggleExpandMenuOnEntity } from '../../actions';
import { getUserFeatures } from '../../config/sessionService';

@autoinject
export abstract class BaseViewModel<T> {
  protected state: T;
  protected dispatch: Dispatch<any>;
  protected unsubscribe: Unsubscribe | undefined;

  protected features = getUserFeatures();
  protected stateChanged: undefined | ((newState: T) => void);

  isMobile: boolean;
  constructor(
    protected logger: Logger,
    mapState?: (state: rootState) => T,
    shouldMapState?: (state: rootState) => boolean
  ) {
    this.dispatch = store.dispatch;

    this.attachMapState(mapState, shouldMapState);

    this.unsubscribeOnDetach(
      isMobileWatcher(isMobile => (this.isMobile = isMobile))
    );
  }

  attachMapState(
    mapState?: (state: rootState) => T,
    shouldMapState?: (state: rootState) => boolean
  ) {
    if (isNone(mapState)) return;
    this.unsubscribeFromStore();
    let previousState: rootState | undefined;
    let previousMappedState: T | undefined;
    const mapAndInjectState = () => {
      const before = performance.now();
      const newState = store.getState();
      if (previousState === newState) return;
      previousState = newState;
      if (isSomething(shouldMapState) && !shouldMapState(newState)) return;
      const mappedState = mapState(newState);
      this.logger.debug(`mapstate calculate: ${performance.now() - before}ms`);
      if (previousMappedState === mappedState) return;
      this.inject(mappedState);
      previousMappedState = mappedState;
    };

    mapAndInjectState();
    this.unsubscribe = store.subscribe(mapAndInjectState);
  }

  detached() {
    this.unsubscribeFromStore();
    for (const unsub of this.unsubscribeOnDetachList) {
      try {
        unsub();
      } catch (err) {
        this.logger.error(err);
      }
    }
    this.unsubscribeOnDetachList = [];
  }

  toggleMenu(entity: menuIsOpenForEntity) {
    this.dispatch(toggleExpandMenuOnEntity(entity));
  }

  unsubscribeFromStore() {
    if (!this.unsubscribe) return;
    this.unsubscribe();
    this.unsubscribe = undefined;
  }

  private unsubscribeOnDetachList: Function[] = [];
  
  public unsubscribeOnDetach(unsubFunc: Function) {
    this.unsubscribeOnDetachList.push(unsubFunc);
  }
  
  private inject(state: T) {
    const beforeInject = performance.now();
    this.state = state;
    if (this.stateChanged) this.stateChanged(state);
    // console.count(`${this.logger.id} injected state`);
    this.logger.debug(`mapstate inject: ${performance.now() - beforeInject}ms`);
  }
}
