import { isNone, saveBlob } from './';

interface ExcelMetadata {
  type: 'profile';
  version: number;
  controllerProfileId: number;
  controllerId: number;
}

interface ParsedExcelMetadata {
  metadata?: ExcelMetadata;
  datarows: string;
  data?: any[][];
}

export const getXlsx = () =>
  import('xlsx-populate/browser/xlsx-populate').then(a => a.default);

export const parseDataInExcel = async (file: File) => {
  const XlsxPopulate = await getXlsx();
  const workbook = await XlsxPopulate.fromDataAsync(file);
  const metainfosheet = workbook.sheet('metainfo');

  const metainfostring = metainfosheet.cell('A1').value();
  const metainfo = JSON.parse(metainfostring) as ParsedExcelMetadata;

  const datasheet = workbook.sheet('Sheet1');
  const datarange = datasheet.range(metainfo.datarows);
  metainfo.data = datarange.value() as any[][];
  return metainfo;
};

export const downloadDataAsExcelMultipleSheets = async (
  headers: Array<{ title: string; value: string | number }>,
  sheets: Array<{
    tableInfo?: string;
    tableHeaders: string[];
    tableData: any[][];
  }>,
  filename: string,
  metadata?: ExcelMetadata
) => {
  const XlsxPopulate = await getXlsx();
  const workbook = await XlsxPopulate.fromBlankAsync();

  const metainfosheet = workbook.addSheet('metainfo');

  for (let i = 0; i < sheets.length; i++) {
    const index = i + 1;
    let sheet = workbook.sheet(`Sheet${index}`);

    if (!sheet) sheet = workbook.addSheet(`Sheet${index}`, i);

    // Insert headers
    const headerRange = sheet.range(1, 1, headers.length + 1, 3);
    headerRange.value(headers.map(header => [header.title, header.value]));
    headerRange.style({ bold: true });

    // insert tableinfo
    const lastHeaderRow = headerRange.endCell().rowNumber();
    const tableInfoRange = sheet.range(
      lastHeaderRow + 1,
      1,
      lastHeaderRow + 1,
      1
    );
    tableInfoRange.value(sheets[i].tableInfo);
    tableInfoRange.style({ bold: true });

    // Insert tableheaders
    const lastInfoRow = tableInfoRange.endCell().rowNumber();
    const tableHeaderRange = sheet.range(
      lastInfoRow + 1,
      1,
      lastInfoRow + 1,
      sheets[i].tableHeaders.length + 1
    );
    tableHeaderRange.value([sheets[i].tableHeaders]);
    tableHeaderRange.style({ bold: true });

    // Insert datacolumns
    const lastTableHeaderRow = tableHeaderRange.endCell().rowNumber();
    const rowLength =
      sheets[i].tableData.length > 0 ? sheets[i].tableData[0].length : 1;
    const dataRange = sheet.range(
      lastTableHeaderRow + 1,
      1,
      lastTableHeaderRow + sheets[i].tableData.length,
      rowLength
    );
    dataRange.value(sheets[i].tableData);

    // Expand columns to longest value
    const allValuesRange = sheet.usedRange();
    const allValues = allValuesRange.value();
    for (let i = 0; i < allValues[0].length; i++) {
      const lengths = allValues.map((d: any) => {
        const cell = d[i];
        return isNone(cell) ? 0 : cell.toString().length;
      });
      const longest = Math.max(...lengths);
      sheet.column(i + 1).width(longest);
    }

    const metainfoWrapper = {
      metadata,
      datarows: dataRange.address()
    } as ParsedExcelMetadata;
    metainfosheet.cell(`A${index}`).value(JSON.stringify(metainfoWrapper));
  }

  metainfosheet.hidden(true);

  const document = await workbook.outputAsync();
  saveBlob(new Blob([document]), filename);
};

export const downloadDataAsExcel = async (
  headers: Array<{ title: string; value: string | number }>,
  tableHeaders: string[],
  tableData: any[][],
  filename: string,
  metadata?: ExcelMetadata
) =>
  downloadDataAsExcelMultipleSheets(
    headers,
    [{ tableHeaders, tableData }],
    filename,
    metadata
  );
