import { isNone } from './helpers';
import { routes, IRoute } from '../interfaces';

export const getInnerRoute = (route: IRoute): IRoute =>
  !route.child ? route : getInnerRoute(route.child);

export const routeIsActive = (
  activeRoute: routes,
  ...routeNameToCheck: string[]
): boolean => {
  const childRouteMatches = isNone(activeRoute.child)
    ? false
    : routeIsActive(activeRoute.child, ...routeNameToCheck);
  if (childRouteMatches === true) return true;
  return (
    !isNone(activeRoute.route) && routeNameToCheck.includes(activeRoute.route)
  );
};
