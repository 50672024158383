import { customElement, bindable, computedFrom } from 'aurelia-framework';
import {
  getShortMonthAsLanguageText,
  formatDayAsNumber,
  ensureDate,
  isNone,
  emptyArray,
  toLocalTimeFromUtc,
  formatTime,
  removeNoneFromArray,
  displayMeasurement,
  ensureNumber,
  getContrastColor,
  isNumeric,
  formatDate,
  roundUpToSecond, isMobileWatcher
} from '../../../utility';
import { Line, IChartistLineChart, FixedScaleAxis, Svg } from 'chartist';
import { I18N } from 'aurelia-i18n';
import { TIME } from '../../../config';
import debounce from 'lodash.debounce';
import { IPoint } from '../../../models';
import 'chartist/dist/chartist.css';
import './tanktrendchart.css';
import { Logger } from 'aurelia-logging';
import gql from 'graphql-tag';
import {
  TankTrendChartChannelDetailsFragment,
  TankTrendChartChannelDetailsFragment_unit
} from '../../../../custom_typings/graphql';

export interface ISelectedGraphPoint {
  xIndex: number;
  value: number;
  timestamp: string;
  localtime: Date;
  minYValue: number;
  maxYValue: number;
  valueUnit: TankTrendChartChannelDetailsFragment_unit | undefined | null;
  maxXIndex: number;
}

export const TankTrendChartChannelDetailsFragmentDeclaration = gql`
  fragment TankTrendChartChannelDetailsFragment on SiteChannel {
    tankDetails {
      tankRefills {
        refillId
        timeStamp
        amountFilled
      }
      plannedTankRefills {
        plannedTankRefillId
        timeStamp
      }
    }
    unit {
      decimals
      sapCode
      symbol
      translationKey
      unitId
    }
    maximum
    minimum
  }
`;

const isInRect = (
  point: { x: number; y: number },
  rect: { x1: number; x2: number; y1: number; y2: number }
) =>
  point.x >= rect.x1 &&
  point.x <= rect.x2 &&
  point.y >= rect.y2 &&
  point.y <= rect.y1;

const transform = (
  x: number,
  y: number,
  svgElement: any
): { x: number; y: number } => {
  const svg =
    svgElement.tagName === 'svg' ? svgElement : svgElement.ownerSVGElement;
  const matrix = svg.getScreenCTM();
  let point = svg.createSVGPoint();
  point.x = x;
  point.y = y;
  point = point.matrixTransform(matrix.inverse());
  return point || { x: 0, y: 0 };
};

const convertXValueForGraph = (timeStamp: string, timezone?: string): Date =>
  toLocalTimeFromUtc(new Date(timeStamp), timezone);

@customElement('tank-trend-chart')
export class TankTrendChart {
  @bindable({ changeHandler: 'updateGraphInstance' })
  history: IPoint[];
  @bindable({ changeHandler: 'updateGraphInstance' })
  yMax: number | undefined;
  @bindable({ changeHandler: 'updateGraphInstance' })
  trend: IPoint[];
  @bindable({ changeHandler: 'updateGraphInstance' })
  minDate: Date | string | undefined;
  @bindable({ changeHandler: 'updateGraphInstance' })
  maxDate: Date | string | undefined;
  @bindable({ changeHandler: 'updateGraphInstance' })
  channelDetails: TankTrendChartChannelDetailsFragment | undefined;
  @bindable({ changeHandler: 'updateGraphInstance' })
  timezone: string | undefined;
  @bindable({ changeHandler: 'updateGraphInstance' })
  onlyShowPeaks: boolean = false;
  @bindable({ changeHandler: 'updateGraphInstance' })
  @bindable
  color: string | undefined;

  @bindable({ changeHandler: 'updateEnableRefillPointSelection' })
  enableRefillPointSelection: boolean | undefined;
  @bindable refillPointSelectionCancelled: undefined | (() => any);
  @bindable refillPointSelectionConfirmed:
    | undefined
    | ((arg: { point: ISelectedGraphPoint }) => Promise<any>);
  selectedGraphPoint: ISelectedGraphPoint | undefined;

  sortedHistory: IPoint[] = [];

  private graphInstance: IChartistLineChart;
  private graphElement: HTMLElement;
  private chartData: any;
  private isMobile: boolean;

  constructor(private i18n: I18N, private logger: Logger) {
    this.updateGraphInstance = debounce(this.updateGraphInstance, 200);
    this.colorPlugin = this.colorPlugin.bind(this);
    this.refillPlugin = this.refillPlugin.bind(this);
    this.mouseDownHandler = this.mouseDownHandler.bind(this);
    this.labelOnHighestAndLowest = this.labelOnHighestAndLowest.bind(this);

    isMobileWatcher(isMobile => (this.isMobile = isMobile))
  }

  bind() {
    this.updateGraphInstance();
  }

  mouseDownHandler(event: MouseEvent) {
    if (
      !this.enableRefillPointSelection ||
      !this.chartData ||
      this.submittingNewRefillPoint
    )
      return;

    const svg = this.chartData.svg._node;
    const { chartRect, axisX, axisY } = this.chartData;
    const points = transform(event.clientX, event.clientY, svg);
    if (!isInRect(points, chartRect)) return;

    const maxXIndex = this.sortedHistory.length - 1;
    const oneXwidth = axisX.axisLength / (this.sortedHistory.length - 1);
    const xIndex = Math.round(
      (points.x - chartRect.x1 + oneXwidth / 2) / oneXwidth
    );

    if (xIndex < 0 || this.sortedHistory.length < xIndex) return;

    const timestamp = this.sortedHistory[xIndex].ts;

    this.selectedGraphPoint = this.sortedHistory[xIndex].v
      ? {
          xIndex,
          value: this.sortedHistory[xIndex].v,
          timestamp,
          localtime: convertXValueForGraph(timestamp, this.timezone),
          minYValue: axisY.range.min,
          maxYValue: axisY.range.max,
          valueUnit: this.channelDetails && this.channelDetails.unit,
          maxXIndex
        }
      : undefined;

    this.updateGraphInstance();
  }

  attached() {
    this.selectedGraphPoint = undefined;
    this.updateGraphInstance();

    this.graphElement.addEventListener('mousedown', this.mouseDownHandler);
  }

  detached() {
    if (this.graphInstance) {
      this.graphInstance.detach();

      if (this.graphElement && this.graphElement.childNodes)
        // For IE 11
        Array.prototype.forEach.call(
          this.graphElement.childNodes,
          (node: any) => {
            this.graphElement.removeChild(node);
          }
        );
    }
    this.graphElement.removeEventListener('mousedown', this.mouseDownHandler);
  }

  _findNearestWholeDivisor(n: number) {
    const maxDivisors = 21;
    let currentDivisor = maxDivisors;
    while (currentDivisor > 0) {
      if (n % currentDivisor === 0) return currentDivisor;
      currentDivisor--;
    }
    return n;
  }

  private textForYAxisValue(value: number | string): string | undefined {
    return isNumeric(value)
      ? displayMeasurement(
          ensureNumber(value),
          this.channelDetails && this.channelDetails.unit,
          true
        )
      : '';
  }

  private getLongestCharacterCountForYAxis(data: IPoint[]): number {
    if (!data || !data.length) return 3;
    let lengths = this.sortedHistory
      .map(
        t => this.textForYAxisValue((t && t.v && t.v.toFixed(0)) || '') || ''
      )
      .map(t => t.length);
    return Math.max(...lengths);
  }

  intializeGraph() {
    if (isNone(this.minDate) || isNone(this.maxDate))
      throw new Error(`Tanktrendchart is missing either min-date or max-date`);

    if (isNone(this.graphElement)) return;
    if (isNone(this.channelDetails)) return;

    this.sortedHistory = (this.history || emptyArray).sort((a, b) =>
      new Date(a.ts).getTime() > new Date(b.ts).getTime() ? 1 : -1
    );

    //Make start and end dates for min/max lines at least as long as the trend data
    const trendStart = new Date(this.sortedHistory[0].ts);
    const trendEnd = new Date(this.sortedHistory[this.sortedHistory.length-1].ts);    
    
    if (trendStart < this.minDate) {
      this.minDate = trendStart;
    }

    if (trendEnd > this.maxDate) {
      this.maxDate = trendEnd;
    }

    const yMax = isNone(this.yMax)
      ? undefined
      : Math.max(this.yMax, ...this.sortedHistory.map(d => d.v));
    
    const minDate = toLocalTimeFromUtc(ensureDate(this.minDate), this.timezone);
    
    const parsedMaxDate = toLocalTimeFromUtc(ensureDate(this.maxDate), this.timezone);
    const maxDate =
      parsedMaxDate.getMilliseconds() === 999
        ? new Date(parsedMaxDate.getTime() + 1)
        : parsedMaxDate;

    const oneDayOnly =
      Math.abs(maxDate.getTime() - minDate.getTime()) / 36e5 <= 24;

    const timeBetweenDates = maxDate.getTime() - minDate.getTime();
    const daysBetweenDates = Math.ceil(timeBetweenDates / TIME.DAYINMS);
    const onlyIntegerYValues = true;
    const longestYAxisLabel = this.getLongestCharacterCountForYAxis(
      this.sortedHistory
    );
    const divisor = oneDayOnly
      ? 6
      : Math.max(this._findNearestWholeDivisor(daysBetweenDates), 6);      
    
    this.graphInstance = new Line(
      this.graphElement,
      {
        series: [
          {
            data: [
              { x: minDate, y: this.channelDetails.minimum },
              { x: maxDate, y: this.channelDetails.minimum }
            ] as any,
            name: 'minimum',
            className: 'series-minimum'
          },
          {
            data: [
              { x: minDate, y: this.channelDetails.maximum },
              { x: maxDate, y: this.channelDetails.maximum }
            ] as any,
            name: 'maximum',
            className: 'series-maximum'
          },
          {
            data: (this.trend || emptyArray).map(t => ({
              x: new Date(t.ts),
              y: t.v
            })) as any,
            name: 'trend',
            className: 'series-trend'
          },
          {
            data: this.sortedHistory.map((p, i) => ({
              x: convertXValueForGraph(p.ts, this.timezone),
              y: p.v,
              meta: { index: i }
            })),
            name: 'history',
            className: 'series-history'
          }
        ]
      },
      {
        fullWidth: true,
        showLine: true,
        showPoint: true,
        showArea: true,
        areaBase: 0,
        lineSmooth: true,
        ...(this.onlyShowPeaks
          ? { chartPadding: { left: 0, right: 0, top: 10 } }
          : {}),
        axisX: {
          showLabel: true,
          labelInterpolationFnc: (value: string | number) => {
            const xAxisDate = roundUpToSecond(ensureDate(value));
            if (oneDayOnly) {
              return formatTime(xAxisDate);
            } else {
              return (
                this.i18n.tr(getShortMonthAsLanguageText(xAxisDate)) +
                ' ' +
                formatDayAsNumber(xAxisDate)
              );
            }
          },
          type: FixedScaleAxis,
          divisor
        },
        axisY: {
          ...(this.onlyShowPeaks
            ? { offset: 0 }
            : { offset: longestYAxisLabel * 8 }),
          onlyInteger: onlyIntegerYValues,
          showLabel: !this.onlyShowPeaks,
          ...(isNone(yMax) ? {} : { high: yMax }),
          low: 0,
          labelInterpolationFnc: this.textForYAxisValue.bind(this)
        },
        plugins: removeNoneFromArray([
          this.color ? this.colorPlugin : undefined,
          this.refillPlugin,
          this.onlyShowPeaks ? this.labelOnHighestAndLowest : undefined,
          this.getDataPlugin,
          this.markDataPointPlugin,
          this.hoverPlugin
        ])
      },
      [
        [
          'screen and ((min-width: 768px) and (orientation: portrait), (min-width: 900px) and (orientation: landscape))',
          {
            axisY: {
              offset: 45,
              divisor: oneDayOnly
                ? 24
                : Math.min(
                    10,
                    Math.ceil(
                      (maxDate.getTime() - minDate.getTime()) / TIME.DAYINMS
                    )
                  )
            },
            chartPadding: {
              left: 30,
              right: 10
            }
          }
        ]
      ]
    );
  }

  labelOnHighestAndLowest(chart: any) {
    chart.on('draw', (data: any) => {
      if (
        !data.series ||
        data.series.name !== 'history' ||
        data.type !== 'line'
      )
        return;
      
      if (data.values.length === 0) return;
      const unit = this.channelDetails && this.channelDetails.unit;
      let min: { x: number; y: number } = data.values[0];
      let max: { x: number; y: number } = data.values[0];

      for (let value of data.values) {
        if (value.y < min.y) min = value;
        if (value.y > max.y) max = value;
      }

      const { chartRect, axisX, axisY } = data;

      const minDisplay = displayMeasurement(min.y, unit, true);
      const maxDisplay = displayMeasurement(max.y, unit, true);
      const perLetterWidth = 8;
      const height = 20;
      const minWidth = (minDisplay || '').length * perLetterWidth;
      const maxWidth = (maxDisplay || '').length * perLetterWidth;

      const pxPerMsX = axisX.axisLength / (axisX.range.max - axisX.range.min);
      const minxpos =
        pxPerMsX * (min.x - axisX.range.min) + chartRect.x1 - minWidth / 2;
      const maxxpos =
        pxPerMsX * (max.x - axisX.range.min) + chartRect.x1 - maxWidth / 2;

      const bufferFromPoint = 5;
      const pxPerY = axisY.axisLength / (axisY.range.max - axisY.range.min);
      const minypos =
        pxPerY * (axisY.range.max - min.y) +
        chartRect.y2 -
        height -
        bufferFromPoint;
      const maxypos =
        pxPerY * (axisY.range.max - max.y) +
        chartRect.y2 -
        height -
        bufferFromPoint;

      const safeMinXPos = Math.min(
        Math.max(minxpos, 0),
        chartRect.x2 - minWidth
      );
      const safeMaxXPos = Math.min(
        Math.max(maxxpos, 0),
        chartRect.x2 - maxWidth
      );

      const safeMinYPos = Math.min(Math.max(minypos, 0), chartRect.y1 - height);
      const safeMaxYPos = Math.min(Math.max(maxypos, 0), chartRect.y1 - height);

      const possibleColorStyle = this.color
        ? `style="background-color:${this.color}; color:${getContrastColor(
            this.color
          )}"`
        : '';

      data.group.foreignObject(
        `<div class="minmaxlabel" ${possibleColorStyle}>${minDisplay}</div>`,
        {
          x: safeMinXPos,
          y: safeMinYPos,
          style: 'text-anchor: middle',
          height,
          width: minWidth
        },
        'test'
      );
      data.group.foreignObject(
        `<div class="minmaxlabel" ${possibleColorStyle}>${maxDisplay}</div>`,
        {
          x: safeMaxXPos,
          y: safeMaxYPos,
          height,
          width: maxWidth,
          style: 'text-anchor: middle'
        },
        'test'
      );
    });
  }

  refillPlugin(chart: any) {
    chart.on('created', (context: any) => {
      const refills =
        (this.channelDetails &&
          this.channelDetails.tankDetails &&
          this.channelDetails.tankDetails.tankRefills) ||
        [];
      const plannedRefills =
        (this.channelDetails &&
          this.channelDetails.tankDetails &&
          this.channelDetails.tankDetails.plannedTankRefills) ||
        [];

      if (isNone(this.maxDate) || isNone(this.minDate)) return;
      const maxDate = ensureDate(this.maxDate).getTime();
      const minDate = ensureDate(this.minDate).getTime();

      const { chartRect, axisX } = context;
      const { range } = axisX;
      for (const refill of refills) {
        if (!refill) continue;
        const refillDate = convertXValueForGraph(
          refill.timeStamp,
          this.timezone
        ).getTime();

        if (refillDate > maxDate || refillDate < minDate) continue;
        const unit = this.channelDetails && this.channelDetails.unit;
        const pxPerMs = axisX.axisLength / (range.max - range.min);
        const xpos = pxPerMs * (refillDate - range.min) + chartRect.x1;

        context.svg.elem(
          'line',
          {
            y1: chartRect.y1,
            y2: chartRect.y2,
            x1: xpos,
            x2: xpos
          },
          'refill'
        );

        context.svg.elem(
          'image',
          {
            height: 32,
            width: 32,
            x: xpos + 10,
            y: chartRect.y2,
            'xlink:href': '/images/truck.png'
          },
          'refill-image'
        );
        context.svg
          .elem(
            'text',
            {
              x: xpos + 10,
              y: chartRect.y2 + 40
            },
            'refill-text'
          )
          .text(
            displayMeasurement(refill.amountFilled || undefined, unit, true)
          );
      }

      for (const refill of plannedRefills) {
        if (!refill) continue;
        const refillDate = convertXValueForGraph(
          refill.timeStamp,
          this.timezone
        ).getTime();

        if (refillDate > maxDate || refillDate < minDate) continue;
        const pxPerMs = axisX.axisLength / (range.max - range.min);
        const xpos = pxPerMs * (refillDate - range.min) + chartRect.x1;
        const ypos = chartRect.y2 + 40;
        context.svg.elem(
          'line',
          {
            y1: chartRect.y1,
            y2: chartRect.y2,
            x1: xpos,
            x2: xpos
          },
          'refill-planned'
        );

        context.svg.elem(
          'image',
          {
            height: 32,
            width: 32,
            x: xpos + 10,
            y: ypos,
            'xlink:href': '/images/truck.png'
          },
          'planned-refill-image'
        );

        context.svg
          .elem(
            'text',
            {
              x: xpos + 10,
              y: ypos + 40
            },
            'refill-text'
          )
          .text('Planned');

        context.svg
          .elem(
            'text',
            {
              x: xpos + 10,
              y: ypos + 60
            },
            'refill-text'
          )
          .text(formatDate(refill.timeStamp, true, '.', this.timezone));
      }
    });
  }

  colorPlugin(chart: any) {
    chart.on('draw', (data: any) => {
      if (!data.series || data.series.name !== 'history') return;
      
      if (data.type === 'area' && this.color)
        data.element.attr({
          style: `fill: ${this.color}; fill-opacity: 0.2;`
        });

      if (data.type === 'line' && this.color)
        data.element.attr({
          style: `stroke: ${this.color};`
        });
    });
  }

  getDataPlugin = (chart: any) => {
    chart.on('created', (data: any) => {
      this.chartData = data;
    });
  };

  pointMouseEnter(data : any) {
    return (e: any) => {
      // I'm getting the tooltip by its class name.
      const tooltip = document.getElementsByClassName("chartist-tooltip") as HTMLCollectionOf<HTMLElement>;

      if (!tooltip || !tooltip[0]) {
        console.error("Can't find tooltip");
        return;
      }

      const point = e.target as HTMLElement;
      if (point) {
        point.classList.toggle("highlight", true);
      }

      // This is how we're setting the position of the tooltip.
      // This will set the top of the tool tip.
      let top = data.y - 50;
      tooltip[0].style.top = top + "px"; 

      // This will set the left of the tooltip. What this does is if you're on the
      // right side of the card the tooltip display left of the cursor, if you're on
      // the left side of the card the tooltip displays right of the cursor.
      let left = data.x > 75 ? data.x - 75 : 75;
      const width = (tooltip[0].parentNode as HTMLElement).offsetWidth;
      if (left + 180 > width) {
        left = width - 180;
      }

      tooltip[0].style.left = left + "px";

      // Here we're removing the hidden class so that the tooltip will display.
      tooltip[0].classList.remove("hidden");

      // This gets the tooltip meta div.
      const meta = document.getElementsByClassName(
        "chartist-tooltip-meta"
      );

      // This sets the data for the meta information on the tooltip
      meta[0].innerHTML = formatDate(new Date(data.value.x), true, ".");

      // This gets the tooltip value div.
      const value = document.getElementsByClassName(
        "chartist-tooltip-value"
      );

      // This sets the data for the value.
      value[0].innerHTML = Math.round(data.value.y).toString() + ' ' + (this.channelDetails?.unit ? this.channelDetails?.unit?.symbol : '');
    }
  }
  
  pointMouseLeave(e: any) {
    const tooltip = document.getElementsByClassName("chartist-tooltip") as HTMLCollectionOf<HTMLElement>;

    if (!tooltip || !tooltip[0]) {
      console.error("Can't find tooltip");
      return;
    }
    const point = e.target as HTMLElement;
    if (point) {
      point.classList.toggle("highlight", false);
    }

    //Hide tooltip
    tooltip[0].classList.add("hidden");
  }
  
  hoverPlugin = (chart: any) => {
    let pointCount = 0;
    const MAX_POINTS_TO_SHOW = 100;
    const MAX_RANGE_TO_SHOW_DAYS = 2;
    
    chart.on('draw', (data: any) => {
      //Only show hover points on 'history' trend
      if (!data.series || data.series.name !== 'history' || data.series.data.length == 0) return;

      const first = data.series.data[0].x;
      const last = data.series.data[data.series.data.length-1].x;
      const spanInTime = new Date(last).getTime() - new Date(first).getTime();
      const spanInDays = Math.round(spanInTime / (1000 * 3600 * 24));

      if (data.type === "point") {
        pointCount++;
                 
        data.element._node.addEventListener("mouseenter", this.pointMouseEnter(data));
        data.element._node.addEventListener("mouseleave", this.pointMouseLeave);
        
        //Highligh points on desktop if not too many
        if (pointCount <= MAX_POINTS_TO_SHOW && !this.isMobile && spanInDays <= MAX_RANGE_TO_SHOW_DAYS) 
           data.element._node.classList.toggle("visible", true);
      } //if type=='point'
      
      //Make points invisible if too many to show 
      if (pointCount > MAX_POINTS_TO_SHOW || spanInDays > MAX_RANGE_TO_SHOW_DAYS) {
        const allPoints = document.getElementsByClassName("ct-point");
        for (let i = 0; i < allPoints.length; i++) {           
          allPoints[i].classList.toggle("visible", false); //Remove visible
        }
      }
    }); //onDraw
  }
  
  markDataPointPlugin = (chart: any) => {
    chart.on('draw', (data: any) => {
      if (!data.series || data.series.name !== 'history') return;
      if (data.type !== 'point') return;
      if (
        !data.meta ||
        !this.selectedGraphPoint ||
        data.meta.index !== this.selectedGraphPoint.xIndex
      )
        return;

      const xcross = new Svg(
        'path',
        {
          d: [
            'M',
            data.x - 10,
            data.y - 10,
            'L',
            data.x + 10,
            data.y + 10,
            'M',
            data.x + 10,
            data.y - 10,
            'L',
            data.x - 10,
            data.y + 10
          ].join(' '),
          style: 'fill-opacity: 1; stroke-width:2; stroke:rgb(255,0,0);'
        },
        'ct-area'
      );

      data.element.replace(xcross);
    });
  };

  updateGraphInstance() {
    this.intializeGraph();
  }

  @computedFrom('selectedGraphPoint')
  get infoBoxStyle() {
    if (!this.selectedGraphPoint) return '';
    const xLeftOfCentre =
      this.selectedGraphPoint.xIndex < this.selectedGraphPoint.maxXIndex / 2;
    return (
      'position:absolute;bottom:5px;' +
      (xLeftOfCentre ? 'left:60%' : 'left:20%')
    );
  }

  submittingNewRefillPoint: boolean = false;
  submittingNewRefillPointError: string | undefined;
  async usePointForNewRefill(selection: ISelectedGraphPoint) {
    if (!this.refillPointSelectionConfirmed) {
      this.logger.info(
        'No callback named refillPointSelectionConfirmed given to tanktrendchart'
      );
      return;
    }
    this.submittingNewRefillPointError = undefined;
    this.submittingNewRefillPoint = true;
    try {
      await this.refillPointSelectionConfirmed({ point: selection });
      this.selectedGraphPoint = undefined;
    } catch (err) {
      this.logger.error(err);
      this.submittingNewRefillPointError = err;
    }

    this.submittingNewRefillPoint = false;
    this.updateGraphInstance();
  }

  cancelGraphPointSelection() {
    if (this.refillPointSelectionCancelled)
      this.refillPointSelectionCancelled();
    this.selectedGraphPoint = undefined;
    this.updateGraphInstance();
  }

  updateEnableRefillPointSelection(newValue: boolean) {
    if (!newValue) this.selectedGraphPoint = undefined;
  }
}
