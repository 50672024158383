import actionCreatorFactory from 'typescript-fsa';
import { IBuid, IQrController } from '../interfaces';

const actionCreator = actionCreatorFactory('qrApp');

export const controllerPropertyChanged = actionCreator<{
  property: string;
  value: string;
}>('controllerPropertyChanged');

export const selectChangeBuid = actionCreator<IBuid | undefined>(
  'selectChangeBuid'
);

export const getQrControllerBySerial = actionCreator.async<
  { serial: string; internalId: number },
  IQrController
>('getQrControllerBySerial');
export const saveQrController = actionCreator.async<
  { controller: IQrController; internalId: number },
  IQrController
>('saveQrController');
export const createQrController = actionCreator.async<
  { controller: IQrController; internalId: number },
  IQrController
>('createQrController');
