import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import {
  saveQrController,
  getQrControllerBySerial,
  createQrController
} from '../actions';

import { getLogger } from 'aurelia-logging';
import { getNewId } from '../utility';
import { IQrController } from '../interfaces';
import { BaseService } from './baseService';

@autoinject()
export class QrControllerService extends BaseService {
  constructor(protected httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(QrControllerService.name), taskQueue);
  }

  get = async (serial: string) =>
    this.httpRequestWithDispatch(
      requests.getQrControllerBySerial(serial),
      getQrControllerBySerial,
      { serial, internalId: getNewId() },
      'UI_QrController_GetBySerial_Failed'
    );

  update = async (controller: IQrController) =>
    this.httpRequestWithDispatch(
      requests.updateQrController(controller),
      saveQrController,
      { controller, internalId: getNewId() },
      'UI_QrController_Update_Failed'
    );

  create = async (controller: IQrController) =>
    this.httpRequestWithDispatch(
      requests.createQrController(controller),
      createQrController,
      { controller, internalId: getNewId() },
      'UI_QrController_Create_Failed'
    );
}
