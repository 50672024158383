import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import {
  getAllLanguages,
  getLanguagesFromSource,
  createOrUpdateLanguageTexts,
  getAllTranslationKeys
} from '../actions';
import { ILanguage } from '../interfaces';
import { ILanguageText } from '../interfaces/entity/iLanguageText';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';

@autoinject()
export class TranslationService extends BaseService {
  constructor(protected httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(TranslationService.name), taskQueue);
  }

  getLanguageKeys = async () =>
    this.httpRequestWithDispatch(
      requests.getLanguageKeys(),
      getAllTranslationKeys,
      {},
      'Failed to fetch translation keys'
    );

  getAllLanguages = async () =>
    this.httpRequestWithDispatch(
      requests.getAllLanguages(),
      getAllLanguages,
      undefined,
      'Get all translations failed'
    );

  getLanguagesFromSource = async (sourceLanguage: ILanguage) =>
    this.httpRequestWithDispatch(
      requests.getAllLanguages(sourceLanguage.code),
      getLanguagesFromSource,
      sourceLanguage,
      'Get all translations from source failed'
    );

  saveLanguageTexts = async (texts: ILanguageText[]) =>
    this.httpRequestWithDispatch(
      requests.createOrUpdateLanguageText(texts),
      createOrUpdateLanguageTexts,
      texts,
      'Failed to save changes to languageText'
    );
}
