import {
  customElement,
  bindable,
  DOM,
  observable,
  TaskQueue,
  computedFrom,
  ComponentDetached,
  autoinject
} from 'aurelia-framework';
import {
  isNone,
  isEmpty,
  isMobileWatcher,
  isSomething
} from '../../../utility';
import './dropdown.css';
import { I18N } from 'aurelia-i18n';
import { compareReduxAndGraphQLIds } from '../../../utility/graphqlHelpers';
import { createCustomEvent } from '../../../utility/customEventHelper';

@autoinject()
@customElement('dropdown')
export class Dropdown<T> implements ComponentDetached {
  @bindable() placeholder: string;
  @bindable() selected: string | T | undefined;
  @bindable() items: T[];
  @bindable() loading: boolean = false;
  @bindable() query: string = '';
  @bindable() filter:
    | undefined
    | ((t: T, query: string | undefined) => boolean);
  @bindable() itemClicked:
    | undefined
    | ((params?: { value: T | T[keyof T] | undefined; text: string; iconName: T | T[keyof T] | undefined; }) => void);
  @bindable() searchable = true;
  @bindable() error: boolean | string[] | string | undefined;

  @bindable() valuePath: undefined | keyof T;
  @bindable() iconPath: undefined | keyof T;
  @bindable() displayPath: undefined | keyof T | Function;

  @bindable() alignTop = false;
  @bindable() straightRight = false;
  @bindable() straightLeft = false;
  @bindable() disabled = false;

  /**
   * Adds a new entry to the top to clear selected value
   */
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() canSelectBlankValue = false;

  private topElementIndex: number;
  private blankElementIndex: number;

  private dropdownlist: HTMLElement;

  @bindable required: boolean;

  @computedFrom('topElement', 'query')
  get _shouldShowTopElement() {
    if (isNone(this.topElement)) return false;

    if (this.topElement === 'IF_QUERY') {
      if(isEmpty(this.query)) return false;
      const lowerCasedQuery = this.query.toLocaleLowerCase().trim();
      return this.items.every(item => {
        const display = this.getDisplayValue(item);
        return isNone(display)? 
          true : 
          display.toLocaleLowerCase().trim() !== lowerCasedQuery;
      });
    }

    return true;
  }

  /**
   * Automaticly translates the display values
   */
  @bindable() translate: boolean = false;

  /**
   * Sets if the dropdown is expanded or not. If this is undefined, the component handles this itself
   */
  @bindable() expanded: boolean | undefined;

  isMobile: boolean = false;
  private unsubscribeWatcher: Function;

  constructor(private taskQueue: TaskQueue, private i18n: I18N) {
    this.unsubscribeWatcher = isMobileWatcher((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  private containerElement: HTMLElement;
  private inputElement: HTMLElement;

  detached() {
    dispatchEvent(
      createCustomEvent('inputremoved', this.dropdownlist)
    );
  }

  // Internal properties
  _focusedItemIndex: number = 0;
  @observable()
  _isExpanded = false;
  _isExpandedChanged(newValue: boolean) {
    if (newValue && this.searchable)
      this.taskQueue.queueTask(
        () => this.inputElement && this.inputElement.focus()
      );
  }

  expandedChanged(newValue: boolean | undefined) {
    this._isExpanded = !!newValue;
  }

  @computedFrom('selected', 'items')
  get item() {
    if (isNone(this.selected)) return this.selected;
    const value =
      typeof this.selected === 'object'
        ? this.getValueFromItem(this.selected)
        : this.selected;
    return typeof this.selected === 'object'
      ? this.selected
      : (this.items || []).find(i =>
          compareReduxAndGraphQLIds(this.getValueFromItem(i), value)
        ) || value;
  }

  @computedFrom('filter', 'items', 'query')
  get filteredList() {
    const { filter, query } = this;
    if (isEmpty(query) || isNone(this.items)) return this.items;
    if (!isNone(filter)) return this.items.filter(item => filter(item, query));
    const lowerCasedQuery = query.toLocaleLowerCase().trim();
    return this.items.filter(item => {
      const display = this.getDisplayValue(item);
      return isNone(display)
        ? false
        : display
            .toLocaleLowerCase()
            .trim()
            .includes(lowerCasedQuery);
    });
  }

  inputkeydown = ({ keyCode }: KeyboardEvent) => {
    const minItemIndex = this.getFirstIndex();

    switch (keyCode) {
      case 40: // key down
        this._focusedItemIndex =
          this.filteredList.length - 1 > this._focusedItemIndex
            ? this._focusedItemIndex + 1
            : this._focusedItemIndex;
        this.scrollSelectedItemIntoView();
        return false;
      case 38: // key up
        this._focusedItemIndex =
          this._focusedItemIndex > minItemIndex
            ? this._focusedItemIndex - 1
            : minItemIndex;
        this.scrollSelectedItemIntoView();
        return false;
      case 13: // enter
        // Select item
        this.selectItem(this.filteredList[this._focusedItemIndex]);
        return false;
      case 27: // ESC
        this.closeList();
        return false;
    }
    this.taskQueue.queueTask(() => {
      this.setFirstIndexes();
      this._focusedItemIndex = this.getFirstIndex();
      this.scrollSelectedItemIntoView();
    });
    return true;
  };

  scrollSelectedItemIntoView = () => {
    if (!this.dropdownlist) return;
    const focusedIndex = this._focusedItemIndex;
    const { children } = this.dropdownlist;
    const focusedEl = children[focusedIndex];
    if (!focusedEl) return;
    focusedEl.scrollIntoView(true);
  };

  isNone = isNone;

  getDisplayValue(item: T) {
    if (isNone(item)) return '';
    const path = this.displayPath;
    let displayValue: T | undefined | T[keyof T] = item;
    switch (typeof path) {
      case 'function':
        displayValue = path({ item });
        break;
      case 'string':
        displayValue = item[path];
        break;
    }
    if (isNone(displayValue)) return '';

    return this.translate ? this.i18n.tr(displayValue) : '' + displayValue;
  }

  getValueFromItem(item: T | undefined) {
    return isNone(item) || isNone(this.valuePath) ? item : item[this.valuePath];
  }


  getIconNameFromItem(item: T | undefined) {
    return isNone(item) || isNone(this.iconPath) ? item : item[this.iconPath];
  }

  itemMouseEnter = (index: number) => {
    this._focusedItemIndex = index;
  };

  selectItem = (item: T | undefined) => {
    if (!isNone(this.itemClicked)) {
      if (
        this._focusedItemIndex === this.blankElementIndex &&
        this.canSelectBlankValue
      ) {
        this.itemClicked(); // clear field by setting it to undefined
      } else {
        this.itemClicked({
          value: this.getValueFromItem(item),
          text: this.query,
          iconName: this.getIconNameFromItem(item),
        });
      }
    } else this.selected = item;

    this.closeList();
  };

  toggleList = () => {
    if (!isNone(this.expanded) || this.disabled) return;
    if (this._isExpanded) {
      this.closeList();
    } else {
      this.openList();
    }
  };

  closeList = () => {
    if (!isNone(this.expanded) || this.disabled) return;
    this.taskQueue.queueTask(() => this._isExpanded = false);
    DOM.removeEventListener('click', this.closeList, false);
  };

  openList = () => {
    this.query = '';
    this.setFirstIndexes();
    this._focusedItemIndex = this.getFirstIndex();
    this._isExpanded = true;
    setTimeout(() => DOM.addEventListener('click', this.closeList, false));
  };

  getFirstIndex() {
    return this._shouldShowTopElement
      ? this.topElementIndex
      : this.canSelectBlankValue
      ? this.blankElementIndex
      : 0;
  }

  setFirstIndexes() {
    let index = 0;
    this.blankElementIndex = this.canSelectBlankValue ? --index : 0;
    this.topElementIndex = this._shouldShowTopElement ? --index : 0;
  }

  detach = () => {
    this.unsubscribeWatcher && this.unsubscribeWatcher();
  };

  @computedFrom('error')
  get hasValidationErrors() {
    return typeof this.error === 'boolean' ? this.error : !isEmpty(this.error);
  }

  @computedFrom('error')
  get showValidationErrors() {
    return typeof this.error === 'boolean' ? this.error : !isEmpty(this.error);
  }

  attached() {
    this.checkIfRequiredInputIsFilledIn(); 
  }

  selectedChanged() {
    this.checkIfRequiredInputIsFilledIn();  
  }

  disabledChanged() {
    this.checkIfRequiredInputIsFilledIn();
  }

  requiredChanged() {
    this.checkIfRequiredInputIsFilledIn();
  }

  checkIfRequiredInputIsFilledIn = () => {
    if(isNone(this.containerElement)) {
      return true;
    }
    if (!this.required) {
      this.error = undefined;
      this.dispatchInputIsValid();
      return true;
    }
    if (isSomething(this.selected) || this.disabled) {
      this.error = undefined;
      this.dispatchInputIsValid();
      return true;
    }
    this.dispatchInputIsInvalid(['UI_Common_Fields_RequiredField']);
    return false;
  };

  dispatchInputIsInvalid = (error: string[] | undefined = undefined) => {
    this.containerElement.dispatchEvent(
      createCustomEvent(
        'validationerrors',
        this.containerElement
      )
    );
    if (isNone(this.error)) this.error = error || true;
  };

  dispatchInputIsValid = () => {
    this.containerElement.dispatchEvent(
      createCustomEvent('validationsuccess', this.containerElement)
    );
    if (isNone(this.error)) this.error = undefined;
  };
}
