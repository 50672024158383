import { customElement, computedFrom, autoinject, bindable } from 'aurelia-framework';
import './column-list.css';
import { I18N } from 'aurelia-i18n';
import { IGridColumn, columnKey } from '../../grid'
import { orderByPredicate } from '../../../../utility';

export interface IColumnGroup<T> {
    name: string;
    columns: IGridColumn<T>[];
}

@autoinject()
@customElement('column-list')
export class ColumnList<T> {
    @bindable() columns: IGridColumn<T>[] = [];
    @bindable() selectedColumns: columnKey[] = [];
    @bindable() columnsChanged: Function | undefined;

    private _filterQuery = "";

    constructor(private i18n: I18N){}

    @computedFrom('columns', '_filterQuery')
    get filteredAndSortedColumns () {
        const sanitizedQuery = this._filterQuery.trim().toLocaleUpperCase();
        const filteredColumns = (this.columns || []).filter(column => this.i18n.tr(column.columnTitle).trim().toLocaleUpperCase().includes(sanitizedQuery) || this.i18n.tr(column.managementGroup).trim().toLocaleUpperCase().includes(sanitizedQuery) )
        return orderByPredicate(filteredColumns, column => this.i18n.tr(column.columnTitle), "asc", true)
    }

    @computedFrom('filteredAndSortedColumns')
    get availableColumnGroups () {
        const groups: IColumnGroup<T>[] = [];
        for(const column of this.filteredAndSortedColumns) {
            const groupName = column.managementGroup || 'Other';
            let group = groups.find(g => g.name === groupName);
            if(!group) {
                group = { name: groupName, columns: [] };
                groups.push(group)
            }

            group.columns.push(column)
        }

        return orderByPredicate(groups, group => this.i18n.tr(group.name), "asc", true);
    }

    isChecked(column: IGridColumn<T>, selectedColumns: columnKey[]) {
        return selectedColumns.find(selectedColumn => selectedColumn === column.columnKey)
    }

    toggleSelectedGroup(columnGroup: IColumnGroup<T>) {
        if(!this.columnsChanged) return;
        const allIsChecked = this.allColumnsInGroupSelected(columnGroup, this.selectedColumns);
        const columnKeys = columnGroup.columns.filter(c => !c.required).map(c => c.columnKey);
        const newColumns = allIsChecked ? this.selectedColumns.filter(c => !columnKeys.includes(c)) : Array.from(new Set([...this.selectedColumns, ...columnKeys]));
        this.columnsChanged({ columns: newColumns })
    }

    allColumnsInGroupSelected(columnGroup: IColumnGroup<T>, selectedColumns: columnKey[]) {
        return columnGroup.columns.every(c => selectedColumns.includes(c.columnKey));
    }

    toggleSelectedColumn(column: IGridColumn<T>) {
        if(!this.columnsChanged) return; 
        const isChecked = this.isChecked(column, this.selectedColumns);
        const newColumns = isChecked ? this.selectedColumns.filter(c => c !== column.columnKey) : [...this.selectedColumns, column.columnKey];

        this.columnsChanged({ columns: newColumns })
    }
}