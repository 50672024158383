import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { I18N } from 'aurelia-i18n';
import XhrBackend from 'i18next-xhr-backend';
import endpoints from './endpoints';
import { AppRouter } from 'aurelia-router';
import Cache from 'i18next-localstorage-backend';
import LngDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import { isEmpty, isNone } from '../utility/helpers';
import { TIME } from './constants';
import { isLocalStorageAvailable } from '../utility/localStorageHelpers';
import { Logger, getLogger } from 'aurelia-logging';
import { getSession } from './sessionService';
import { initReactI18next } from 'react-i18next';


import { I18nextKeysOnDemand, TranslationMap } from './i18n-keysondemand';

import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '.';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const cachePrefix = 'i18next_res_';

export const flushLanguageCache = () => {
  try {
    if (!isLocalStorageAvailable) return;

    for (let i = 0 ; i < window.localStorage.length; i++) {
      if (window.localStorage.key(i)) {
        let key = window.localStorage.key(i);
        if (key?.startsWith(cachePrefix)) {
          window.localStorage.removeItem(key);
        }
      }
    }
  } catch (e) {
    //Ignore exceptions
    console.error("Error removing language cache: " + e);
  }
}

export const fetchNewLanguageTexts = (i18next: any, logger: Logger) => {
  if (!isLocalStorageAvailable) return;
  const getCacheName = (language: string) =>
    cachePrefix + language + '-translation';
  const languages: string[] = i18next.languages;

  const toReload = languages.filter(language => {
    const storedValue = window.localStorage.getItem(getCacheName(language));
    if (storedValue === undefined || storedValue === null) return false;
    const parsed = JSON.parse(storedValue);
    const timeFetched = parsed.i18nStamp;

    const shouldReload =
      timeFetched < new Date().getTime() - TIME.MINUTEINMS * 60;

    i18next.on('failedLoading', (lng: string, ns: string, msg: string) => {
      logger.error(
        'Failed reloading languagetext. Will fall back to cached texts',
        lng,
        ns,
        msg
      );
      if (lng === language)
        window.localStorage.setItem(getCacheName(language), storedValue);
    });

    return shouldReload;
  });

  if (!isEmpty(toReload)) {
    toReload
      .map(getCacheName)
      .forEach(cache => window.localStorage.removeItem(cache));
    i18next.reloadResources(toReload);
  }
};

/**
 * Detection used with a combination of user-profile value and localstorage key
 * If the user logs in to the system, the language from the swt token is used
 * If the user is not logged in, we check if something is saved in localstorage
 * If the user is logged in and a localstorage key is set, use the newest one.
 * (Since the jwt token cannot update, we need to use a combination of localstorage and jwt).
 */
const userdetector = {
  name: 'userdetector',
  localstorageKey: 'user-language',

  languageFromLocalStorage() {
    if (!isLocalStorageAvailable) return;
    const rawValue = localStorage.getItem(this.localstorageKey);
    if (!rawValue) return;
    try {
      const parsed = JSON.parse(rawValue);
      // Check if parsed contains correct fields
      if (!parsed.timestamp || !parsed.value) return;
      return {
        timestamp: parsed.timestamp as number,
        value: parsed.value
      };
    } catch (err) {
      return;
    }
  },

  languageFromJWT() {
    const session = getSession();
    if (!session.userIsLoggedIn) {
      return;
    }
    if (!session.language || session.language.length === 0) return;
    return {
      timestamp: session.auth_time,
      value: session.language
    };
  },

  saveToLocalStorage(value: string) {
    if (!isLocalStorageAvailable) return false;
    try {
      localStorage.setItem(
        this.localstorageKey,
        JSON.stringify({
          timestamp: new Date().getTime(),
          value
        })
      );
      return true;
    } catch (err) {
      return false;
    }
  },

  lookup() {
    const fromLocalstorage = this.languageFromLocalStorage();
    const fromJWT = this.languageFromJWT();

    if (isNone(fromLocalstorage) && isNone(fromJWT)) return null;

    if (!isNone(fromLocalstorage) && !isNone(fromJWT))
      return fromLocalstorage.timestamp > fromJWT.timestamp * 1000
        ? fromLocalstorage.value
        : fromJWT.value;

    if (isNone(fromLocalstorage) && !isNone(fromJWT)) return fromJWT.value;
    if (!isNone(fromLocalstorage) && isNone(fromJWT))
      return fromLocalstorage.value;

    return null;
  },

  cacheUserLanguage(lng: string /*, options: any*/) {
    this.saveToLocalStorage(lng);
  }
};

const isTranslationValue = (possibleKey: string) => possibleKey.includes('_');


// Only check once...
const checkedMissingKeysCache = new Set();

const isNotChecked = (key: string) => !checkedMissingKeysCache.has(key);
const setCacheKey = (key: string) => checkedMissingKeysCache.add(key);

const missingTranslationService = (
  httpClient: HttpClient,
  logger: Logger
) => async (keys: string[], language: string) => {
  let missingTranslations = keys.filter(isTranslationValue).filter(isNotChecked);
  if (!missingTranslations.length) return Promise.resolve<TranslationMap>({});

  const page = window && window.location && window.location.href;
  logger.warn(`Missing languagekeys on page ${page}!`, missingTranslations);
  const response = await httpClient.fetch(
    requests.getMissingTranslations(missingTranslations, language)
  );
  
  missingTranslations.forEach(setCacheKey);

  return (await response.json()) as TranslationMap;
};

interface appTitleObject {
  title: string;
  translate: boolean;
}
type appTitle = string | appTitleObject;
const isTitleObject = (titleobj: appTitle): titleobj is appTitleObject => {
  return typeof titleobj !== 'string';
};

/**
 * Translations initial visit
 * - Fetch language-texts from server
 * - Render
 * - Cache language-texts
 * Translations second visit
 * - Fetch translations from cache
 * - Render
 * - Fetch language-texts from server
 * - Rerender
 * - Cache language-texts
 */
export default (aurelia: Aurelia) => {
  const lngDetector = new LngDetector();
  lngDetector.addDetector(userdetector);

  const httpClient = aurelia.container.get(HttpClient) as HttpClient;

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), (instance: I18N) => {
    // Override aurelia tr with our own case insenstivie tr
    const originaltr = instance.tr.bind(instance);
  
    instance.tr = function(key?: any, options?: any):string {
      const lowercaseKey = key?.toString()?.toLowerCase();
      const translatedText = originaltr(lowercaseKey, options);
      return translatedText === lowercaseKey ? key : translatedText; // Default to text from markup if we have no translation   
    }

    instance.i18next
      .use(Backend)
      .use(lngDetector)
      .use(initReactI18next)
      .use(
        new I18nextKeysOnDemand({
          translationGetter: missingTranslationService(
            httpClient,
            getLogger('Languagetexts')
          ),
          useKeyAsValue: true
        })
      )

    const CacheOptions = {
      enabled: ENV === 'production',
      expirationTime: TIME.DAYINMS * 365,
      prefix: cachePrefix
    };
    const XhrOptions = {
      loadPath: endpoints.translation,
      customHeaders: { 'X-CSRF' : '1' }
    };

    const backend =
      ENV === 'production'
        ? {
            backends: [Cache, XhrBackend],
            backendOptions: [CacheOptions, XhrOptions],
          }
        : {
            backends: [XhrBackend],
            backendOptions: [XhrOptions],     
          };

    return instance
      .setup({
        backend,
        // keySeparator: '_',
        attributes: ['t', 'i18n'],
        debug: ENV !== 'production',
        crossDomain: true,
        fallbackLng: 'en',
        joinArrays: ', ',
        load: 'languageOnly',
        detection: {
          order: ['userdetector', 'navigator', 'htmlTag'],
          caches: ['userdetector'],
          htmlTag: document.documentElement
        }
      })
      .then(() => {
        const approuter = aurelia.container.get(AppRouter);
        approuter.transformTitle = titleOrObject => {
          if (isTitleObject(titleOrObject))
            return titleOrObject.translate
              ? instance.tr(titleOrObject.title)
              : titleOrObject.title;
          return instance.tr(titleOrObject);
        };
        if (ENV === 'production') {
          if (isLocalStorageAvailable())
            fetchNewLanguageTexts(instance.i18next, getLogger('Languagetexts'));
        }
      });
  });
};
