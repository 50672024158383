import { mergeObjects, insertAllFetchedEntities } from '../../utility';
import { IProduct, IAsyncReducerState } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getProducts, updateProduct } from '../../actions/index';
import { createPendingEntity } from '../../types';

export interface IProductsReducerState extends IAsyncReducerState<IProduct> {}

const defaultState: IProductsReducerState = { allIds: undefined, byId: {} };

const reducer = reducerWithInitialState(defaultState)
  .case(getProducts.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )
  .case(getProducts.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, product => product.productId)
    )
  )

  .case(updateProduct.done, _state => defaultState);

export default reducer;
