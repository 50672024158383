import {
  bindable,
  customElement,
  computedFrom,
  autoinject,
  ComponentDetached
} from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { isEmpty, assureArray, emptyArray, isNone } from '../../utility';
import './textbox.css';
import * as maska from 'maska';
import { createCustomEvent } from '../../utility/customEventHelper';

@autoinject()
@customElement('textbox')
export class Textbox implements ComponentDetached {
  // events
  @bindable onBlur: ({}) => void;
  @bindable onFocus: ({}) => void;
  @bindable onChanged: ({}) => void;
  @bindable onKeypress: ({}) => void;

  // properties
  @bindable type: string = 'text';
  @bindable idname: string | undefined;
  @bindable min: number;
  @bindable max: number;
  @bindable name: string;
  @bindable value: any;
  @bindable placeholder: string = '';
  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;
  @bindable noRight: boolean;

  @bindable inverseColor: boolean = false;
  @bindable size: string = 'medium';

  @bindable icon: string;

  @bindable hasFocus: boolean;

  @bindable autofocus: boolean;

  @bindable inline: boolean;
  @bindable noPadding: boolean;

  @bindable error: boolean | string[] | string | undefined;
  _error: boolean | string[] | string | undefined;
  @bindable validateIconEnable: boolean;
  @bindable disabled: boolean = false;

  @bindable maxLength: number = 524288; // default
  @bindable required: boolean;

  @bindable onEnter: Function = PLATFORM.noop;
  @bindable inputmask: string | string[] | undefined;
  @bindable validateInput: undefined | (() => boolean | string | undefined);
  @bindable enableValidateInputOnChange = false;

  private inputelement: HTMLElement;

  constructor(private element: Element) {
    // some ctor code that initializes the textbox would go here.
  }

  bind() {
    /* This is here to restrict the initial valuechanged to execute*/
    this._error = this.error;
  }

  attached() {
    if (!this.disabled) {
      if(this.autofocus) {
        this.inputelement.focus();
      }
      if (this.enableValidateInputOnChange) this.checkIfInputIsValid();
      else this.checkIfRequiredInputIsFilledIn();
    }

    if (!this.inputmask) return;
    maska.create(this.inputelement, {
      mask: this.inputmask,
      tokens: { N: { pattern: /[0-9\ ]/ }, P: { pattern: /[0-9\ \+\(\)\-]/ } }
    });
  }

  detached() {
    dispatchEvent(
      createCustomEvent('inputremoved', this.inputelement)
    );
  }

  errorChanged(newValue: boolean | string[] | string | undefined) {
    this._error = newValue;
  }

  valueChanged(newValue: string, oldValue: string) {
    if (newValue == oldValue) return;
    if (this.onChanged) this.onChanged({ value: newValue });
    if (this.enableValidateInputOnChange) this.checkIfInputIsValid();
    else this.checkIfRequiredInputIsFilledIn();
  }

  disabledChanged(newValue: boolean) {
    if (this.element !== undefined) {
      if (newValue) {
        this.resetValidation();
      } else {
        this.checkIfInputIsValid();
      }
    }
  }

  checkIfRequiredInputIsFilledIn = () => {
    if (this.required && !this.value) {
      this.dispatchInputIsInvalid('UI_Common_Fields_RequiredField');
      return true;
    }
    if (!this.validateInput && this.required && !!this.value) {
      this.dispatchInputIsValid();
      return true;
    }
    return false;
  };

  checkIfInputIsValid = () => {
    if (this.checkIfRequiredInputIsFilledIn()) return;
    if (!this.validateInput) return;
    const validationResult = this.validateInput();
    let validationWasSuccessfull = true;

    if (typeof validationResult === 'boolean')
      validationWasSuccessfull = validationResult;
    if (typeof validationResult === 'string')
      validationWasSuccessfull = !validationResult;

    if (validationWasSuccessfull) return this.dispatchInputIsValid();
    else return this.dispatchInputIsInvalid(validationResult);
  };

  resetValidation = () => {
    this._error = undefined;
    this.dispatchInputIsValid();
  };

  dispatchInputIsInvalid = (
    error: boolean | string[] | string | undefined = undefined
  ) => {
    this.element.dispatchEvent(
      createCustomEvent('validationerrors', this.inputelement)
    );
    if (isNone(this.error)) this._error = error || true;
  };

  dispatchInputIsValid = () => {
    this.element.dispatchEvent(
      createCustomEvent('validationsuccess', this.inputelement)
    );
    if (isNone(this.error)) this._error = undefined;
  };

  callOnBlur() {
    this.checkIfInputIsValid();
    if (!this.onBlur) return;
    this.onBlur({ value: this.value });
  }

  callOnFocus() {
    if (!this.onFocus) return;
    this.onFocus({ value: this.value });
  }

  callOnKeyPress(e: Event) {
    if (!this.onKeypress) return;
    this.onKeypress(e);
  }

  @computedFrom('_error')
  get hasValidationErrors() {
    return typeof this._error === 'boolean'
      ? this._error
      : !isEmpty(this._error);
  }

  @computedFrom('_error')
  get errorTexts() {
    return typeof this._error === 'boolean' || isNone(this._error)
      ? emptyArray
      : assureArray(this._error);
  }

  @computedFrom('_error')
  get inputStatus() {
    return this.hasValidationErrors ? 'cross' : 'tick';
  }

  @computedFrom('_error')
  get inputStatusIconSrc() {
    return `../../icons/${this.inputStatus}.svg`;
  }
}
