export const getEnvironment = (): 'development' | 'test' | 'production' | 'localhost' => {
  const location = window.location;

  if(location.host.includes('localhost') || location.host.includes('127.0.0.1')) {
    return 'localhost';
  }

  if (location.host.includes('yt3-web-dev') || location.host.includes('yt3-dev')) {
    return 'development';
  }

  if (location.host.includes('yt3-web-test') || location.host.includes('yt3-test')) {
    return 'test';
  } 

  return 'production';
};
