import { bindable, customElement, computedFrom } from 'aurelia-framework';
import './colorbox.css';

@customElement('colorbox')
export default class ColorBox {
  @bindable() color: string;

  @computedFrom('color')
  get style() {
    return { 'background-color': this.color || '' };
  }
}
