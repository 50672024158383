import {
  IUnit,
  ILanguage,
  ILoadingPoint,
  IUserProfile
} from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';
import { menuIsOpenForEntity } from '../types';

const actionCreator = actionCreatorFactory('app');

export const bodyClick = actionCreator('BODY_CLICK');
export const fetchingUnits = actionCreator.async<{}, IUnit[]>('FETCHING_UNITS');

export const fetchingLoadingPoints = actionCreator.async<{}, ILoadingPoint[]>(
  'fetchingLoadingPoints'
);

export const getCurrentUser = actionCreator.async<{}, IUserProfile>(
  'getCurrentUser'
);

export const downloadingApplicationUpdate = actionCreator(
  'downloadingApplicationUpdate'
);

export const toggleExpandMenuOnEntity = actionCreator<menuIsOpenForEntity>(
  'ToggleExpandMenuItemOnEntity'
);

export const getLanguages = actionCreator.async<{}, ILanguage[], string>(
  'GET_ALL_LANGUAGES'
);

export const loadSavedUserState = actionCreator('loadSavedUserState');
