import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  addPinnedSite,
  removePinnedSite,
  getPinnedSiteIds,
  unpinAllSites
} from '../actions/index';
import { BaseService } from './baseService';
import { getEntityOrUndefined } from '../utility/index';

@autoinject
export class PinnedSiteService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(PinnedSiteService.name), taskQueue);
  }

  getAllIds = () =>
    this.httpRequestWithDispatchFromState(
      requests.getPinnedSiteIds(),
      getPinnedSiteIds,
      {},
      'Get all pinned sites failed',
      state => state.pinnedSites.all
    );

  getAllIdsAsync = () =>
    this.httpRequestWithDispatch(
      requests.getPinnedSiteIds(),
      getPinnedSiteIds,
      {},
      'Get all pinned sites failed',
      state => getEntityOrUndefined(state.pinnedSites.all)
    );

  unpinAll = () =>
    this.httpRequestWithDispatch(
      requests.unpinAllSites(),
      unpinAllSites,
      undefined,
      'Unpin all sites failed'
    );

  pinAll = () =>
    this.httpRequestWithDispatch(
      requests.pinAllSites(),
      getPinnedSiteIds,
      {},
      'Get all pinned sites failed'
    );

  add = (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.addPinnedSite(siteId),
      addPinnedSite,
      siteId,
      'Add pinned site failed'
    );

  remove = (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.removePinnedSite(siteId),
      removePinnedSite,
      siteId,
      'Remove pinned site failed.'
    );
}
