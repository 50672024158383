import { SecurityLevel } from '../interfaces/enums/securityLevel';
import { isNone } from '../utility';
import { SecurityLevels } from '../config';

export class DisplaySecurityLevelValueConverter {
  toView(value: SecurityLevel | undefined) {
    if (isNone(value)) return '';
    return SecurityLevels[value];
  }
}
