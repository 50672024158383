import { customElement } from 'aurelia-framework';
import './card-tab-bar-item.css';
import { bindable } from 'aurelia-templating';

@customElement('card-tab-bar-item')
export class CardTabBarItem {
  @bindable active = false;

  @bindable disabled = false;
}
