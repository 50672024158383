import { customAttribute, autoinject, PLATFORM } from 'aurelia-framework';

@customAttribute('click-outside')
@autoinject
export class ClickOutside {
  value: Function | undefined;
  clickFunction: (e: MouseEvent) => void;

  constructor(element: Element) {
    this.clickFunction = (e: MouseEvent) => {
      if(e.composedPath) {
        const path = e.composedPath();
        const clickedOutside = !path.includes(element);
        
        this.value && clickedOutside && this.value();
        return;
      }

      if (e.target == null) return;
      const targetElement = e.target as Element;
      const clickedOutside = !element.contains(targetElement);
      this.value && clickedOutside && this.value();
    
    };
  }


  attached() {
      setTimeout(() => PLATFORM.addEventListener('click', this.clickFunction), 0);
  }

  detached() {
    PLATFORM.removeEventListener('click', this.clickFunction);
  }
}
