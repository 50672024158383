import {
  getTrendGroups,
  trendGroupAdded,
  trendGroupRemoved
} from '../../actions';
import { ITrendGroup } from '../../interfaces';
import {
  mergeObjects,
  appendObjectToArray,
  removeObjectFromArray,
  emptyArray
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface IUserTrendGroupsReducerState {
  [siteId: number]: ITrendGroup[];
}

const defaultState: IUserTrendGroupsReducerState = {};

const reducer = reducerWithInitialState(defaultState)
  .case(getTrendGroups.done, (state, payload) =>
    mergeObjects(state, { [payload.params]: payload.result })
  )

  .case(trendGroupAdded.done, (state, payload) =>
    mergeObjects(state, {
      [payload.result.siteId]: appendObjectToArray(
        state[payload.result.siteId] || emptyArray,
        payload.result
      )
    })
  )

  .case(trendGroupRemoved.done, (state, payload) =>
    mergeObjects(state, {
      [payload.params.siteId]: removeObjectFromArray(
        state[payload.params.siteId],
        g => g.userTrendGroupId === payload.params.trendGroupId
      )
    })
  );

export default reducer;
