import {
  customElement,
  bindable,
  PLATFORM,
  containerless
} from 'aurelia-framework';

@containerless()
@customElement('icc-input')
export default class IccInput {
  @bindable value: string | number | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable error: undefined | string | string[] | boolean;
  @bindable required: boolean | undefined;
  inputmask = '####################'; //https://github.com/beholdr/maska

  validateInput = () => {
    if (!this.value && !this.required) return true;
    if (!this.value) return false;
    const { length } = this.value.toString();
    return length === 19 || length === 20
      ? true
      : 'UI_Common_ValidationErrors_ICC';
  };
}
