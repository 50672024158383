import { customElement, bindable } from 'aurelia-templating';
import './time-timezone.css';
import { timezones } from '../../../utility/timezone/timezones';
import { isSomething, orderByPredicate } from '../../../utility';
import { computedFrom } from 'aurelia-binding';

@customElement('time-timezone')
export class TimeTimezone {
  @bindable time: string | undefined;
  @bindable timezone: string | undefined;

  @bindable() timeValueChanged:
    | ((data: { value: string | undefined }) => void)
    | undefined
    | null;

  @bindable() timezoneValueChanged:
    | ((data: { value: string | undefined }) => void)
    | undefined
    | null;

  timeInputMask: string = '##:##';
  allTimezones = orderByPredicate(timezones, t => t.description, 'asc');
  timeInputElement: Element;
  timeValidationError: string | undefined;
  @bindable() disabled: boolean = false;

  pickTimezone(value: string | number | null | undefined) {
    if (isSomething(value)) {
      this.timezone = `${value}`;
      if (isSomething(this.timezoneValueChanged))
        this.timezoneValueChanged({ value: this.timezone });
    }
  }

  attached() {
    this.updateTimeValidationState(this.time);
  }

  validateTimeInput() {
    return this.timeValidationError;
  }

  modifyTime(value: unknown) {
    if (typeof value !== 'string') return;
    let strValue = value as string;
    if (strValue.length >= 5) {
      strValue = strValue.substring(0, 5); // Value can go longer than the mask briefly, but just ignore the extra chars to avoid glitchy interaction. Must run the validation again though, to avoid yet another glitch.
    }

    this.updateTimeValidationState(strValue);
    if (this.timeFieldInvalid) return;

    this.time = strValue;
    if (isSomething(this.timeValueChanged))
      this.timeValueChanged({ value: this.time });
  }

  timeChanged(newValue: string) {
    this.updateTimeValidationState(newValue);
  }

  private updateTimeValidationState(value: unknown) {
    if (typeof value !== 'string' || value.length < 5) {
      this.setTimeError();
      return;
    }
    const [hours, mins] = this.parseHHMM(value);
    if (hours > 23 || mins > 59) {
      this.setTimeError();
      return;
    }
    this.clearTimeError();
  }

  @computedFrom('timeValidationError')
  get timeFieldInvalid(): boolean {
    return isSomething(this.timeValidationError);
  }

  private setTimeError() {
    this.timeValidationError = 'UI_Common_ValidationErrors_Time';
  }

  private clearTimeError() {
    this.timeValidationError = undefined;
  }

  // parse time strings like '12:59'
  private parseHHMM(value: string): [number, number] {
    try {
      const hours = parseInt(value.substring(0, 2), 10);
      const mins = parseInt(value.substring(3, 5), 10);

      return [hours, mins];
    } catch {}
    return [99, 99];
  }
}
