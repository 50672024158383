import 'whatwg-fetch';

const stringPolyfills = async () => {
  (await 'includes') in []
    ? Promise.resolve()
    : import('core-js/features/array/includes');
  (await ''.includes) instanceof Function
    ? Promise.resolve()
    : import('core-js/features/string/includes'),
    (await ''.repeat) instanceof Function
      ? Promise.resolve()
      : import('core-js/features/string/repeat');
  (await 'fill') in []
    ? Promise.resolve
    : import('core-js/features/array/fill');
  (await 'flat') in []
    ? Promise.resolve
    : import('core-js/features/array/flat');
};

const intlPolyfill = (): Promise<unknown> =>
  'Intl' in window ? Promise.resolve() : import('intl');

const formatToPartsPolyFill = async () => {
  (await (Intl &&
    Intl.DateTimeFormat &&
    Intl.DateTimeFormat.prototype &&
    Intl.DateTimeFormat.prototype.formatToParts))
    ? Promise.resolve()
    : import('date-time-format-timezone');
  (await Intl.DateTimeFormat.prototype.formatToParts)
    ? Promise.resolve()
    : import('./polyfills/intl/formatToParts');
};

const elementPolyfills = async () => {
  (await Element.prototype.closest)
    ? Promise.resolve()
    : import('./polyfills/elements/closest');
};

const objectPolyfills = async () => {
  (await Object.entries)
    ? Promise.resolve()
    : import('./polyfills/object/object');
};

const requestIdleCallbackPolyfill = (): Promise<unknown> =>
  'requestIdleCallback' in window
    ? Promise.resolve()
    : import('./polyfills/requestidlecallbackpolyfill');

export const importPolyfills = () =>
  Promise.all([
    stringPolyfills(),
    intlPolyfill().then(formatToPartsPolyFill),
    elementPolyfills(),
    objectPolyfills(),
    requestIdleCallbackPolyfill()
  ]);
