import { isNone } from '../utility/helpers';

export class numberValueConverter {
  toView(
    possibleNumber: string | undefined | number,
    decimals: boolean = false
  ) {
    if (isNone(possibleNumber)) return 0;
    const parsed = decimals
      ? parseFloat(possibleNumber.toString())
      : parseInt(possibleNumber.toString(), 10);
    return isNaN(parsed) ? 0 : parsed;
  }

  fromView(
    possibleNumber: string | undefined | number,
    decimals: boolean = false
  ) {
    if (isNone(possibleNumber)) return 0;
    const parsed = decimals
      ? parseFloat(possibleNumber.toString())
      : parseInt(possibleNumber.toString(), 10);
    return isNaN(parsed) ? 0 : parsed;
  }
}
