import { bindable } from 'aurelia-templating';
import { isNone, formatDate, ensureNumber, isSomething, assureArray, removeNoneFromArray, distinct } from '../../utility';
import { PLATFORM } from 'aurelia-pal';
import { sortDirection } from '../../types';
import { computedFrom, observable } from 'aurelia-binding';
import './grid.css';
import { LocalSettings } from '../../services/localSettingsService';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { IFilterGroup } from '../../interfaces';
import { getUserFeatures, UserFeatures } from '../../config/sessionService';
import { getLogger, Logger } from 'aurelia-logging';
import { NotificationService } from '../../services/notificationService';
import { IListEntriesChanged } from '../../pages/common/GraphQLInfiniteListViewModel';
import { ElasticSearchPage } from '../../../custom_typings/graphql';
import { I18N } from 'aurelia-i18n';
import { DurationValueConverter } from '../../value-converters/durationValueConverter';

export interface IFilterDefinition {
  name: string,
  property: string;
  type: 'ref' | 'string' | 'date' | 'number' | 'tristate' | 'range' | 'bool';
  settings?: Record<string, unknown>;
}

type GridColumnTypes =
  | 'string'
  | 'date'
  | 'datetime'
  | 'integer'
  | { type: 'decimal'; numberOfPoints: number }
  | 'duration'
  | 'icon'
  | { type: 'customElement'; component: {}, settings?: unknown, className?: string };

type propertyValueTypes = string | string[] | null | undefined | number | object; 

export type columnKey = any;

export type widthProperty = number | string | { type: 'flex', grow: number, base?: number } | { type: 'px', value: number } | undefined;

export interface IGridColumn<T> {
  columnKey?: columnKey;
  managementGroup?: string;
  required?: boolean;
  property?: keyof T | ((row: T) => propertyValueTypes);
  columnTitle: string;
  type?: GridColumnTypes;
  filter?: IFilterDefinition;
  sortedByInitially?: sortDirection;
  clickHandler?: (row: T, i: number) => void;
  hasOwnClickHandler?: boolean;
  tooltip?: (row: T, i: number, i18n: I18N) => string | undefined | null
  columnLink?: (row:T, i: number, features: UserFeatures) => string | undefined;
  className?: string;
  order?: number | string;
  width?: widthProperty;
  sort?: (
    list: T[],
    order: sortDirection,
    column: IGridColumn<T>
  ) => T[] | void;
  columnHovered?: (column: IGridColumn<T>) => void;
  classNameIfClickable?: string;    // eg for conditionally applying 'link' class to select cells
}

const flipSortDirection = (sortDirection: sortDirection) =>
  sortDirection.toLocaleLowerCase() === 'asc' ? 'desc' : 'asc';

type rowKey = unknown;
export interface IGridSelectableRowsConfig<T> {
  rowKey: (row: T) => rowKey[];
  selectedRowsChanged: (selectedRows: rowKey[]) => void;
  selectText?: string;
  fetchAllIds: () => Promise<rowKey[]>;
  selectSingleRow?: boolean;
}

@autoinject()
export class Grid<T> {
  @bindable() columns: IGridColumn<T>[];
  @bindable() selectedColumns: columnKey[] | undefined;
  @bindable() showGroupedHeader: false;
  @bindable() data: T[];
  @bindable() page: ElasticSearchPage | undefined;
  @bindable() namespace: string | undefined;
  @bindable() dataLastChange: IListEntriesChanged | undefined;
  @bindable() selectableRowsConfig: IGridSelectableRowsConfig<T> | undefined;
  @bindable() rowClass: string | ((item: unknown) => string | undefined) | undefined;
  @bindable() freeTextQuery: string[] | undefined;
  @bindable() beforeDataChangedText: string = 'UI_Grid_NoMatch';
  public dataHasChangedOnce: boolean = false;

  private logger: Logger;
  private columnWidth: Record<string, number | string> | undefined = {};
  private scrollToPosition: Function | undefined;
  private scrollToRightEnd: Function | undefined;
  protected features = getUserFeatures();
  

  constructor(private taskQueue: TaskQueue, 
      private notification: NotificationService, 
      public i18n: I18N,
      private durationValueConverter: DurationValueConverter){
    this.logger = getLogger(Grid.name);
  }
  
  bind(){
    this.setColumnWidthFromStorage();
    this.sortedData = this.data;
    if(this.sortedColumnKey)
      this.sortedColumnKeyChanged();
  }

  @bindable() initialStart: undefined | number;

  private getColumnWidthCacheKey() {
    return `${this.namespace}-columnWidths`;
  }

  namespaceChanged(){
    if(!this.namespace) return;
    this.setColumnWidthFromStorage();
 }

  clearSelectedRows(){
    this.selectedRows = [];
    if (this.selectableRowsConfig) this.selectableRowsConfig.selectedRowsChanged(this.selectedRows);
  }

  setColumnWidthFromStorage() {
    const cacheKey = this.getColumnWidthCacheKey()
    this.columnWidth = LocalSettings.getSettingParsed<Record<string, number | string> | undefined>(cacheKey, {});
  }

  getClassForRow(row: unknown) {
    if(!this.rowClass) return;
    if(typeof this.rowClass === 'string') return this.rowClass;
    return this.rowClass(row);
  }

  columnWidthDidChange(column: IGridColumn<T>, newWidth: number | undefined) {
    if(!this.namespace) return;
    const widths = this.columnWidth || {};
    if(newWidth)
      widths[column.columnKey] = newWidth;
    else
      delete widths[column.columnKey];
    this.columnWidth = {...widths};
    LocalSettings.setSetting(this.getColumnWidthCacheKey(), JSON.stringify(widths));
  }

  selectedRows: rowKey[] = [];
  selectableRows: rowKey[] | undefined = [];
  lastSelectedRowIndex: number | undefined;


  private async getSelectedRows(key:rowKey, event: MouseEvent, index: number): Promise<rowKey[]> {
    if(!event.shiftKey) return [key];
    if(isNone(this.lastSelectedRowIndex)) return [key];

    
    // Check if we have the data already for this selection
    const from = Math.min(index, this.lastSelectedRowIndex);
    const to = Math.max(index, this.lastSelectedRowIndex) + 1;

    const fromData = this.data.slice(from, to)

    if(fromData.every(isSomething) && this.selectableRowsConfig)
      return fromData.flatMap(this.selectableRowsConfig.rowKey);

    const fromSelectableRows = this.selectableRows?.slice(from, to);

    if(isSomething(fromSelectableRows))
      return fromSelectableRows;
    
    if(!this.fetchingRowsPromise)
      this.fetchSelectableRows();

    if(this.fetchingRowsPromise) {
      const selectableRows = await this.fetchingRowsPromise;
      return selectableRows.slice(from, to);
    }

    throw Error("Could not fetch correct rowkey");
  }

  async toggleSelectedRow(key: rowKey, event: MouseEvent, index: number) {
    if(!this.selectableRowsConfig) return;
    
    if(this.selectableRowsConfig.selectSingleRow){
      this.selectedRows = this.selectedRows.includes(key) ? [] : [key];
      this.selectableRowsConfig.selectedRowsChanged(this.selectedRows);
      return;
    }

    const currentlySelectedRows = await this.getSelectedRows(key, event, index);

    if(this.selectedRows.includes(key)){
      this.selectedRows = this.selectedRows.filter(f => !currentlySelectedRows.includes(f));
    }
    else {
      this.selectedRows = distinct([...this.selectedRows, ...currentlySelectedRows]);
    }
    this.selectableRowsConfig.selectedRowsChanged(this.selectedRows);
    
    this.lastSelectedRowIndex = index;
    if(!this.fetchingRowsPromise)
      this.fetchSelectableRows();
  }

  async toggleAllRows(setToChecked: boolean) {
    if(!this.selectableRowsConfig || !this.selectableRows || this.selectableRowsConfig.selectSingleRow) return;
    if(!this.fetchingRowsPromise)
      await this.fetchSelectableRows();

    if (setToChecked){
      this.selectedRows = distinct([...this.selectedRows, ...this.selectableRows]);
    }
    else
      this.selectedRows = this.selectedRows.filter(f => !this.selectableRows?.includes(f));

    this.selectableRowsConfig.selectedRowsChanged(this.selectedRows);  
  }

  fetchingRows: boolean = false;
  fetchingRowsPromise: Promise<rowKey[]> | undefined;

  async fetchSelectableRows() {
    if(!this.selectableRowsConfig) return;
    
    this.fetchingRows = true;

    try {
      const fetchingRowsPromise = this.fetchingRowsPromise = this.selectableRowsConfig.fetchAllIds();
      const fetchedRows = await fetchingRowsPromise;

      if (!fetchedRows || fetchingRowsPromise !== this.fetchingRowsPromise)
        return;
      this.selectableRows = fetchedRows;
      this.fetchingRows = false;  

    } catch (error) {
      this.logger.error(error);
      this.notification.notify({
        type: 'CUSTOM',
        level: 'error',
        text: 'Failed to determine selectable rows. Global check box is disabled',
        timestamp: new Date().toString(),
        acknowledged: false
      });
      this.selectableRows = undefined;  
    }
  }

  clearRowSelections() {
    this.selectedRows = [];
    if(this.selectableRowsConfig)
      this.selectableRowsConfig.selectedRowsChanged(this.selectedRows);
  }

  isSelected(key: rowKey, selectedRows: rowKey[]) {
    return selectedRows.includes(key)
  }

  @computedFrom('selectedRows', 'selectableRows')
  get isAllSelected() {
    if (!this.selectableRows || this.selectableRows.length < 1)
      return false;
    return this.selectableRows.every(r => this.selectedRows.includes(r));
  }

  // For column editing
  @bindable() editMode: boolean = false;
  @bindable() loading: boolean;
  @bindable() rowLink: (row: T) => void | string | undefined;
  @bindable() rowSize: undefined | ((row: T) => number);
  @bindable() textToHighlight: string | string[] | undefined;

  // Virtual list properties
  @bindable() dataCount: number | undefined;
  
  @bindable() fetchMore: Function | undefined;
  @bindable() columnsChanged: Function = PLATFORM.noop;

  inlineHeaderStyle: string = '';
  
  scrollHeader(left: number){
    this.inlineHeaderStyle = `transform: translateX(-${left}px)`;
  }

  getColumnGroups(columns: IGridColumn<T>[]) {
    const groups: Array<IGridColumn<T>[]> = [];

    for(const column of columns) {
      if(groups.length == 0) {
        groups.push([column]);
        continue;
      }
      const lastGroup = groups[groups.length - 1];
      const [first] = lastGroup
      if(first.managementGroup === column.managementGroup)
        lastGroup.push(column);
      else
        groups.push([column]); 
    }

    return groups;
  }

  getSelectedColumns(columns: IGridColumn<T>[], selectedColumns: columnKey[] | undefined) {
    if(!selectedColumns) return columns;
    return removeNoneFromArray(selectedColumns.map(selectedColumn => columns.find(column => column.columnKey === selectedColumn)));
  }

  columnAddedOrRemoved = (columns: columnKey[]) => {
    if(!this.selectedColumns) return;
    if(columns.length > this.selectedColumns.length) // Scroll to the right when a column is added, not when removed
      this.taskQueue.queueTask(() => this.scrollToRightEnd && this.scrollToRightEnd());
    this.columnsChanged({ columns });
  }

  columnLink(column: IGridColumn<T>, row: T, index: number) {
    if(column.clickHandler || column.hasOwnClickHandler) return undefined;
    if(!this.rowLink && !column.columnLink) return undefined;
    const linkFromColumn = column.columnLink && column.columnLink(row, index, this.features);
    if(linkFromColumn) {
      return linkFromColumn;
    }
    return typeof this.rowLink === 'function' ? this.rowLink(row) : this.rowLink;
  }

  getItemSize(index: number) {
    const item = this.data && this.data[index];
    if(this.rowSize) return this.rowSize(item);
    return 44;
  }

  previousStart: undefined | number;
  previousStop: undefined | number;
  
  onBodyScroll(start: number, stop: number, offset: number, left: number, first: number, firstVisibleItem: number){
    this.scrollHeader(left);
    if(!stop && !start || (this.previousStart === start && this.previousStop === stop)) return;
    this.previousStop = stop;
    this.previousStart = start;
    return this.fetchMore && this.fetchMore({ start, stop, first, offset, firstVisibleItem });
  }
  
  dataChanged() {
    this.fetchingRowsPromise = undefined;
    this.lastSelectedRowIndex = undefined;
    if(this.selectedRows != undefined && this.selectedRows.length) {
       this.fetchSelectableRows();
    }
    this.dataHasChangedOnce = true;

    if (this.sortedColumn) return this.sortByColumn(this.sortedColumn, true);
    const firstDefaultSortable = this.columns.find(c => c.sortedByInitially);
    if (!firstDefaultSortable) {
      this.sortedData = this.data;
      return;
    }
    this.sortByColumn(firstDefaultSortable, true);
  }

  static getWidthCSS(width: widthProperty): string {
    if(isNone(width)) return ``;
    if(typeof width === 'number' || typeof width === 'string') return `min-width: ${width}px; max-width: ${width}px;`;
    switch(width.type){
      case 'flex':
        return `flex: ${width.grow} 0 ${width.base || 0}px;`;
      case 'px':
        return `min-width: ${width.value}px; max-width: ${width.value}px;`;
    }
  }

  groupIndexForColumn(groups: Array<IGridColumn<T>[]>, column: IGridColumn<T>) {
    let index = 0;
    if(!groups) return null;
    for(const group of groups) {
      if(group.includes(column)) return index;
      index++
    }
    return null
  }

  getWidthCSSForGroup(columns: IGridColumn<T>[], widths: Record<string, number | string> | undefined): string {
    if(!widths) return ``;
    let widthInPx: undefined | number;
    let growFlex: undefined | number;

    for(const column of columns) {
      const overriddenWidth = widths[column.columnKey];
      if(overriddenWidth) {
        widthInPx = (widthInPx || 0) + ensureNumber(overriddenWidth);
        continue;
      }

      const width = column.width;
      
      if(!width) {
        growFlex = (growFlex || 0) + 1;
        continue;
      }


      if(typeof width === 'number' || typeof width === 'string') {
        widthInPx = (widthInPx || 0) + ensureNumber(width);
        continue;
      }

      switch(width.type){
        case 'flex':
          growFlex = (growFlex || 0) + width.grow;
          widthInPx = width.base ? (widthInPx || 0) + width.base : widthInPx;
          continue;

        case 'px':
          widthInPx = (widthInPx || 0) + width.value;
          continue;
      }

      // Default of flex-grid-cell is flex: 1 0 0;
    }
    return !isNone(growFlex) ? `flex: ${growFlex} 0 ${widthInPx || 0}px;` : `min-width: ${widthInPx}px; max-width: ${widthInPx}px;`;
  }

  getStyleForColumn(column: IGridColumn<T>, width: widthProperty) {
    const orderCSS = column && column.order ? `order: ${column.order};` : ``;
    const widthCSS = Grid.getWidthCSS(width);
    return orderCSS+widthCSS;
  }

  @bindable() sortedDirection: sortDirection = 'asc';
  @observable() sortedColumn: IGridColumn<T> | undefined;

  @bindable() sortedColumnKey: columnKey | undefined;

  sortedColumnKeyChanged(){
    this.sortedColumn = this.columns.find(c => c.columnKey === this.sortedColumnKey)
  }

  sortedData: T[];
  sortByColumn(column: IGridColumn<T>, becauseOfDataChange = false) {
    if (!column.sort) return;
    this.scrollToPosition && this.scrollToPosition(0);
    const sortDirection = becauseOfDataChange
      ? this.sortedDirection
      : this.sortedColumn === column
      ? flipSortDirection(this.sortedDirection)
      : 'asc';
    this.sortedColumn = column;
    this.sortedDirection = sortDirection;

    if(becauseOfDataChange && (!isNone(this.dataCount) || !isNone(this.fetchMore))){
      this.sortedData = this.data;
      return;
    }

    const maybeSortedData = column.sort(this.data || [], sortDirection, column);

    if (!maybeSortedData) return;

    this.sortedData = maybeSortedData;
  }

  // Column filters
  @bindable activeFilters: IFilterGroup[];
  @bindable() changedFilter: (args: { newfilter: IFilterGroup | undefined }) => void;
  @bindable() clearFilter: Function;

  @observable
  filterToShow: IFilterDefinition | undefined;
  popoverLeftPosition: number;
  arrowLeftPosition: number;

  showFilterPopover(column: IGridColumn<T>, element: HTMLElement){
    this.filterToShow = column.filter;

    const elementLeft = element.offsetLeft;
    const elementWidth = element.getBoundingClientRect().width;
    const elementCenter = elementLeft + elementWidth/2;
    const popoverWidth = 350;

    this.popoverLeftPosition = this.keepElementWithinParentBounds(element, elementCenter, popoverWidth);
    this.arrowLeftPosition = elementCenter - this.popoverLeftPosition;
  }

  keepElementWithinParentBounds = (element: HTMLElement, position: number, width: number) => {
    if (element.parentElement !== undefined){
      const parentStart = 0;
      const parentEnd = element.parentElement!.scrollWidth;
  
      if(position - width/2 < parentStart)
        return parentStart;
      else if(position + width/2 > parentEnd)
        return parentEnd - width;
    }
    return position - width/2;
  }

  filterChanged(newfilter: IFilterGroup) {  
    this.changedFilter && this.changedFilter({ newfilter })
  }

  closeIfClickedOutside() {
    const event = window.event;

    if (event 
        && event.target
        && event.target instanceof Element 
        && this.isFlatPickr(<Element>event.target))
    {
      return;
    }

    //Close filter box
    this.filterToShow = undefined;
  }

  // Returns true if element (or child element) is flatpickr
  isFlatPickr(elem : Element, depthToGo : number = 10) : boolean {        
    if (depthToGo <= 0)
      return false;

    if (elem.id.indexOf('flatpickr') >= 0)
      return true;

    if (elem.parentElement && this.isFlatPickr(elem.parentElement, depthToGo-1))
      return true;

    let found = false;

    elem.classList.forEach(className => {
      if (className.indexOf('flatpickr') >= 0)
        found = true;
    });

    if (found)
      return true;

    if (elem.children) {
      for (let i=0; i < elem.children.length; i++)
      {      
        let child = elem.children.item(i);
        if (child && this.isFlatPickr(child, depthToGo-1))
          return true;
      }
    }

    return false;
  }

  getFilterForDefinition(filterDefinition: IFilterDefinition, filters: IFilterGroup[]): IFilterGroup | undefined {
    if(!filterDefinition) return;
    const property = filterDefinition.property.toLocaleLowerCase()
    return filters.find(f => f.field.toLocaleLowerCase() === property);
  }

  formattedValue(column: IGridColumn<unknown>, property: Exclude<propertyValueTypes, string[]>): string | undefined {
    if(!isSomething(property)) return;

    const { type } = column;
    if(typeof type === 'string' || isNone(type)) {
      switch(type) {
        case 'date':
          return formatDate(property.toString(), false, ".");
        case 'datetime':
          return formatDate(property.toString(), true, ".");
        case 'duration':
          return this.durationValueConverter.toView(property?.toString())
        default:
        case 'string':
          return property?.toString();
      }
    }

    if(type.type === 'decimal' && typeof property  !== 'object') {
      return ensureNumber(property).toFixed(type.numberOfPoints);
    }
    return '';
  }

  reorderedColumns = (columns: IGridColumn<unknown>[]) => {
    if(this.columnsChanged)
      this.columnsChanged({ columns: columns.map(c => c.columnKey) })
  }

  getColumnValues = <T>(row: T, column: IGridColumn<T>) => {
    const { property } = column;
    if (isNone(property)) return;
    const propertyValue =
      typeof property === 'function' ? property(row) : row[property];

    return assureArray(propertyValue);
  };
}
