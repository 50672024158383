import {
  customElement,
  bindable,
  containerless,
  autoinject,
} from 'aurelia-framework';
import SizeAndPositionManager from './SizeAndPositionManager';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import './virtual-list.css';
import { isNone, isSomething } from '../../utility/helpers';
import { IListEntriesChanged } from '../../pages/common/GraphQLInfiniteListViewModel';

const fallBackSize = 44;

//export type DataCallback = (props: {data: unknown[], from: number, to: number}) => void

const requestAnimationFrameDebounce = (fun: (...args: unknown[]) => void) => {
  let scheduled = false;
  let lastArgs: unknown[] | undefined;
  return (...args: unknown[]) => {
    lastArgs = args;
    if(scheduled) return;
    scheduled = true;
    requestAnimationFrame(() => {
      scheduled = false;
      lastArgs ? fun(...lastArgs) : fun();
      lastArgs = undefined;
    });
  }
}

@customElement('virtual-list')
@containerless()
@autoinject()
export class VirtualList {
  sizeAndPositionManager: SizeAndPositionManager;
  @bindable() items: unknown[];
  @bindable() itemCount: number | undefined;
  @bindable() getSize: undefined | ((a: { index: number }) => number);
  @bindable() fixedSize: number | undefined;
  @bindable() listWrapperClass: string | undefined;
  @bindable() dataLastChange: IListEntriesChanged | undefined;
  
  /** Pass in additional CSS classes that are added to the end of the list of classes on the root node element in virtual list */ 
  @bindable() additionalCssClasses: string | undefined;

  @bindable() scrollToPosition = (top: number) => {
    this.rootNode.scrollTop = top;
  }
  @bindable() scrollToRightEnd = () =>{
    this.rootNode.scrollLeft = this.rootNode.scrollWidth;
  }

  @bindable onScrollChanged: (args: {
    position: number;
    start: number;
    stop: number;
    first: number,
    offset: number,
    left: number,
    firstVisibleItem: number;
  }) => void;

  @bindable() keepScrollPosition: boolean = false;

  @bindable initialOffset: number;
  @bindable initialStart: number;
  @bindable overscanCount: number = 20;

  @bindable overflow: 'hidden' | 'visible' = 'hidden';

  onScrollChangedHandler: () => void;

  firstDivHeight = 0;
  
  renderedItems: unknown[] = []; // The elements that gets rendered.
  innerHeight: number;
  offset: number | undefined;
  private rootNode: HTMLElement;
  private containerHeight: number;
  private start: number | undefined;
  private sendScrollLeft = true;
  private sizeChangeSubscription: Subscription;
  
  constructor(eventAggregator: EventAggregator) {
    this.sizeChangeSubscription = eventAggregator.subscribe('recalculate', this.onResize.bind(this));
    
    this.scrolled = requestAnimationFrameDebounce(this.scrolled.bind(this));
    this.onResize = requestAnimationFrameDebounce(this.onResize.bind(this));
  }

  bind() {
    this.sizeAndPositionManager = new SizeAndPositionManager({
      itemCount: this.itemCount || (this.items ? this.items.length : 0),
      itemSizeGetter: index =>
        this.fixedSize ||
        (this.getSize ? this.getSize({ index }) : fallBackSize),
      estimatedItemSize: this.fixedSize || fallBackSize
    });
    this.start = undefined;
    this.stop = undefined;
    this.containerHeight = this.rootNode.clientHeight;
    if (!!this.initialOffset) {
      this.offset = this.initialOffset;
    }
    if (!!this.initialStart) {
      const initialStart = Math.min(this.initialStart, this.itemCount || this.items.length);
      const { offset } = this.sizeAndPositionManager.getSizeAndPositionForIndex(initialStart);
      this.offset = this.calculateOffset(offset);  // new fix for #5274
    }

    this.renderItems(true);
  }

  dataLastChangeChanged(event: IListEntriesChanged | undefined) {
    if(!event) return;
    if(this.items !== event.data) {
      this.items = event.data
    }
    this.sizeAndPositionManager.resetItem(event.from);
    const { scrollTop } = this.rootNode;
    this.offset = scrollTop;        
    this.renderItems(true);
  }

  attached() {
    if (!!this.offset) {
      this.setNodeOffset(this.offset); 
    }
    setTimeout(() => {
      this.rootNode.addEventListener('scroll', this.scrolled, { passive: true });
    }, 0)
  }

  detached() {
    this.sizeChangeSubscription.dispose();
    this.renderedItems = [];
    this.rootNode.removeEventListener('scroll', this.scrolled);
  }

  // TODO: This can be removed when the components that uses the 
  getStyle(index: number) {
    const {
      size
    } = this.sizeAndPositionManager.getSizeAndPositionForIndex(index);
    return { height: size + 'px'};
  }
  
  scrolled = () => {
    if (isNone(this.rootNode)) { // fix 5309
      return;
    }
    const scrollTop = this.rootNode.scrollTop;
    const scrollLeft = this.sendScrollLeft ? this.rootNode.scrollLeft : 0;

    this.offset = scrollTop;

    const newValues = this.renderItems();
    const { start = 0, stop = 0 } = newValues;
    if (!!this.onScrollChanged) {
      const firstVisibleItem = this.sizeAndPositionManager.findNearestItem(this.offset);
      this.onScrollChanged({ start, stop, position: scrollTop, first: stop - start, offset: start, left: scrollLeft, firstVisibleItem });
    }
  }

  onResize() {
    if (!this.rootNode) return;
    this.containerHeight = this.rootNode.clientHeight;
    this.renderItems();
  }

  setNodeOffset(offset: number) {
    this.rootNode.scrollTop = offset;
  }

  // calculateOffset is an attempt at setting offset correctly, so that #5280 and #5274 are fixed, while also not causing #5364.
  calculateOffset(defaultOffset: number): number {
    if (isSomething(this.rootNode) && isSomething(this.rootNode.scrollTop)) 
    {
      return this.rootNode.scrollTop;
    }
    return defaultOffset;
  }


  itemsChanged(newItems: object[]) {        
    this.sizeAndPositionManager.updateConfig({
      itemCount: this.itemCount || newItems.length,
      estimatedItemSize: this.fixedSize || fallBackSize
    });
    
    this.sizeAndPositionManager.resetItem(0);
    this.offset = this.calculateOffset(0);   // new fix for #5280
    this.renderItems(true);
  }

  itemCountChanged(newCount: number | undefined) {
    this.sizeAndPositionManager.updateConfig({
      itemCount: newCount || this.items.length,
      estimatedItemSize: this.fixedSize || fallBackSize
    });
    this.sizeAndPositionManager.resetItem(0);
    
    this.renderItems(true);
  }

  getIndex(index: number) {
    return (this.start || 0) + index;
  }

  initialRender() {
    if (this.initialOffset) this.setNodeOffset(this.initialOffset);
  }

  renderItems(newItems = false) {       
    const { start, stop } = this.sizeAndPositionManager.getVisibleRange({
      containerSize: this.containerHeight,
      offset: this.offset || 0,
      overscanCount: this.overscanCount
    });

    const totalSize = this.sizeAndPositionManager.getTotalSize();
    this.innerHeight = totalSize;
    if(totalSize !== 0 && !!this.items && !!this.items.length) {
      const { offset: startOffset } = this.sizeAndPositionManager.getSizeAndPositionForIndex(start || 0);
      this.firstDivHeight = startOffset;      
    } else {
      this.firstDivHeight = 0;
    }

    if(newItems || start !== this.start || stop !== this.stop) {

      if(newItems || (start || 0) > (this.stop || 0) || (stop || 0) < (this.start || 0))
      {
        this._initialRender(start, stop);
      }
      else
      {        
        this._incrementalRender(start, stop);        
      }
    }

    this.start = start;
    this.stop = stop;

    return {
      offset: this.offset,
      start,
      stop
    };
  }

  stop: number | undefined;
  
  _incrementalRender(start: number | undefined = 0, stop: number | undefined = 0) {
    const prependDiff = start - (this.start || 0);
    const appendDiff = stop - (this.stop || 0);
    
    // The user has scrolled down and we are now showing a slice further down in the array.
    if(prependDiff > 0){
      this.renderedItems.splice(0, prependDiff);
    }
    // The user has scrolled up and we are now showing a slice further up in the array
    if(prependDiff < 0){
      this.renderedItems.splice(0, 0, ...this.items.slice(start, this.start));
    }
    // This user has scrolled down and we are now showing a slice futher down in the array
    if(appendDiff > 0){
      this.renderedItems.splice(this.renderedItems.length, 0, ...this.items.slice((this.stop || 0) + 1, stop + 1));
    }
    // The user has scrolled up and we are now showing a slice further up in the array
    if(appendDiff < 0){
      this.renderedItems.splice(this.renderedItems.length - Math.abs(appendDiff), Math.abs(appendDiff))
    }
    
    for(let k = 0; k < this.renderedItems.length; k++) {
      const itemInRenderedItems = this.renderedItems[k];
      const itemInOriginalArray = this.items[start + k];
      if(itemInRenderedItems === itemInOriginalArray) continue;
      this.renderedItems.splice(k, 1, itemInOriginalArray);
    }
  }
  
  _initialRender(start: number | undefined = 0, stop: number | undefined = 0) {
    this.renderedItems = start === 0 && stop === this.items.length ? this.items : this.items.slice(start, stop + 1);
  }
}
