import { isNone, isEmpty } from './helpers';

export const lowerCaseAndTrimString = (value: string | undefined) =>
  isNone(value) ? '' : value.toLocaleLowerCase().trim();

export const concatWithSeparator = (
  value1: string | undefined,
  value2: string | undefined
) => {
  if (!isEmpty(value1) && isEmpty(value2)) return value1;
  else if (isEmpty(value1) && !isEmpty(value2)) return value2;
  else if (!isEmpty(value1) && !isEmpty(value2)) return `${value1} - ${value2}`;
  else return '';
};

export const getRangeOfCharacters = (from: string, to: string) => {
  const charCodeFrom = from.charCodeAt(0);
  const charCodeTo = to.charCodeAt(0);
  const charCodeRange: string[] = [];
  if (charCodeFrom > charCodeTo) return charCodeRange;
  for (let charcode = charCodeFrom; charcode <= charCodeTo; charcode++) {
    charCodeRange.push(String.fromCharCode(charcode));
  }
  return charCodeRange;
};

export const capitalize = (column: string): string => column.slice(0,1).toUpperCase() + column.slice(1);
