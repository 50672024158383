import { customElement, autoinject, bindable } from 'aurelia-framework';
import { Line, Interpolation, IChartistLineChart } from 'chartist';
import { IPoint } from '../../../models/trend';
import { isNone, isNumber } from '../../../utility';
import 'chartist/dist/chartist.css';
import './linechart.css';

@autoinject
@customElement('line-chart')
export class LineChart {
  @bindable({ changeHandler: 'updateGraphInstance' })
  series: Array<IPoint | number | string> | undefined;
  @bindable showLine: boolean = true;
  @bindable fullWidth: boolean = true;
  @bindable showPoint: boolean = true;
  @bindable showArea: boolean = true;
  @bindable activeSeries?: number;
  @bindable yMax: number | undefined;
  @bindable color: string | undefined;

  private linechart: HTMLDivElement;
  private chart: IChartistLineChart | undefined;
  private defaultColor = '#3887f3';

  attached() {
    this.chart = this.updateChartistChart();
  }

  detached() {
    if (this.chart) this.chart.detach();
  }

  updateGraphInstance() {
    if (!this.linechart) return;
    this.chart = this.updateChartistChart();
  }

  updateChartistChart() {
    if (!this.linechart || !this.series) return;
    const data = this.series.map((d: IPoint | number | string) =>
      typeof d === 'string'
        ? parseFloat(d)
        : isNumber(d)
        ? d
        : { x: new Date(d.ts), y: d.v }
    );

    const yMax = isNone(this.yMax)
      ? undefined
      : Math.max(this.yMax, ...data.map(r => (isNumber(r) ? r : r.y)));
    const series: any = [{ data }];
    return new Line(
      this.linechart,
      {
        series
      },
      {
        fullWidth: this.fullWidth,
        showArea: this.showArea,
        showLine: this.showLine,
        showPoint: this.showPoint,
        lineSmooth: Interpolation.cardinal(),
        chartPadding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 5
        },
        axisX: {
          showLabel: false,
          showGrid: false,
          offset: 0
        },
        axisY: {
          showLabel: false,
          showGrid: false,
          offset: 0,
          ...(isNone(yMax) ? {} : { high: yMax }),
          low: 0
        },
        plugins: [this.graphPlugin.bind(this)]
      }
    );
  }

  graphPlugin(chart: any) {
    chart.on('draw', (data: any) => {
      if (data.type === 'area')
        data.element.attr({
          style: `fill: ${this.color || this.defaultColor}; fill-opacity: 0.2;`
        });

      if (data.type === 'line')
        data.element.attr({
          style: `stroke: ${this.color || this.defaultColor};`
        });
    });
  }
}
