import { defaultMemoize } from 'reselect';
import { Maybe } from 'tsmonad';
import { isNone } from './helpers';

const exitMaybeImp = <T>(maybe: Maybe<T>) =>
  maybe.caseOf({
    just: (t: T) => t,
    nothing: () => undefined
  });

export const exitMaybe = defaultMemoize(exitMaybeImp);

export const allOrNothing = <T>(something: Array<Maybe<T>>): Maybe<T[]> => {
  const justArray: T[] = [];
  for (const maybe of something) {
    const maybeValue = exitMaybe(maybe);
    if (isNone(maybeValue)) return Maybe.nothing();
    justArray.push(maybeValue);
  }
  return Maybe.just(justArray);
};

type MaybeObj<T> = {
  [P in keyof T]: Maybe<T[P]>;
};

export const sequence = <T>(obj: MaybeObj<T>): Maybe<T> => {
  return Maybe.all(obj as any) as any;
};
