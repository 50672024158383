import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  mergeObjects,
  appendOrReplaceObjectInArray,
  defaultRequest,
  requestLoading,
  requestLoaded,
  requestFailed
} from '../../utility/index';
import { ILanguage, IRequestState } from '../../interfaces/index';
import { replaceObjectInArray } from '../../utility/stateHelpers';
import {
  getAllLanguages,
  createOrUpdateLanguageTexts,
  getLanguagesFromSource,
  getAllTranslationKeys,
  textChanged,
  discardChanges
} from '../../actions/index';

export interface ITranslationReducerState {
  languages: ILanguage[];
  languagesRequest: IRequestState;

  keys: {
    items: string[];
    request: IRequestState;
  };
}

const defaultState: ITranslationReducerState = {
  languages: [],
  languagesRequest: defaultRequest,

  keys: {
    items: [],
    request: defaultRequest
  }
};

const reducer = reducerWithInitialState(defaultState)
  .case(textChanged, (state, payload) =>
    mergeObjects(state, {
      languages: replaceObjectInArray(
        state.languages,
        language => language.languageId == payload.languageId,
        language =>
          mergeObjects(language, {
            languageTexts: appendOrReplaceObjectInArray(
              language.languageTexts,
              languageText => languageText.key == payload.key,
              payload
            )
          })
      )
    })
  )

  .case(createOrUpdateLanguageTexts.done, (state, payload) =>
    mergeObjects(state, {
      languages: state.languages.map(l =>
        mergeObjects(l, {
          languageTexts: l.languageTexts.map(lt => {
            const payloadRecord = payload.result.find(plt => plt.languageId === l.languageId && plt.key === lt.key);
            return {
              ...lt,
              // When this is a new record the server returns a new languageTextId, keep it for the next call to the server
              languageTextId: payloadRecord ? payloadRecord.languageTextId : lt.languageTextId
            }
          })
        })
      )
    })
  )

  .case(discardChanges, state =>
    mergeObjects(state, {
      languages: state.languages.map(l =>
        mergeObjects(l, {
          languageTexts: l.languageTexts.map(lt =>
            mergeObjects(lt, {
              text: lt.originalText
            })
          )
        })
      )
    })
  )

  .case(getAllLanguages.started, state =>
    mergeObjects(state, {
      languagesRequest: requestLoading('Loading languages')
    })
  )

  .case(getAllLanguages.done, (state, payload) =>
    mergeObjects(state, {
      languages: payload.result,
      languagesRequest: requestLoaded
    })
  )
  .case(getAllLanguages.failed, (state, payload) =>
    mergeObjects(state, {
      languagesRequest: requestFailed(payload.error)
    })
  )

  .case(getLanguagesFromSource.done, (state, payload) =>
    mergeObjects(state, {
      languages: payload.result
    })
  )

  .case(getAllTranslationKeys.started, state =>
    mergeObjects(state, {
      keys: mergeObjects(state.keys, {
        request: mergeObjects(
          state.keys.request,
          requestLoading('Loading language keys')
        )
      })
    })
  )

  .case(getAllTranslationKeys.done, (state, payload) =>
    mergeObjects(state, {
      keys: mergeObjects(state.keys, {
        items: payload.result,
        request: mergeObjects(state.keys.request, requestLoaded)
      })
    })
  )

  .case(getAllTranslationKeys.failed, (state, payload) =>
    mergeObjects(state, {
      keys: mergeObjects(state.keys, {
        request: mergeObjects(state.keys.request, requestFailed(payload.error))
      })
    })
  );

export default reducer;
