import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { sendPdiMasterData } from '../actions/masterdataExport';

@autoinject()
export class ExportMasterDataService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ExportMasterDataService.name), taskQueue);
  }

  sendPdiMasterData = (siteId: number) => {
    return this.httpRequestWithDispatch(
      requests.sendPdiMasterData(siteId),
      sendPdiMasterData,
      siteId,
      'Could not send master data'
    );
  };
}
