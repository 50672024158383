import { customElement, bindable, computedFrom } from 'aurelia-framework';
import { INote } from '../../../interfaces';

@customElement('note')
export class Note {
  @bindable note: INote;
  @bindable editMode: boolean = false;
  @bindable menuIsOpenForNote?: string;
  @bindable canEditNote: boolean = false;
  @bindable canDeleteNote: boolean = false;
  @bindable dropdownShouldHaveMenuAlignedTop: boolean = false;
  @bindable markNoteForEdit: Function;
  @bindable deleteNote: Function;
  @bindable toggleMenuItemForNote: Function;

  @bindable timezone: string;

  @computedFrom('menuIsOpenForNote', 'note.noteId')
  get showMenuForNote() {
    return (
      !!this.menuIsOpenForNote &&
      'note' + this.note.noteId === this.menuIsOpenForNote
    );
  }

  @computedFrom(
    'note.processing',
    'currentlyEditingNote',
    'canEditNote',
    'canDeleteNote'
  )
  get showNoteActionBar() {
    if (this.note.processing) return false;
    if (this.editMode) return false;

    return this.canEditNote || this.canDeleteNote;
  }

  noteMarked() {
    this.markNoteForEdit({ note: this.note });
  }

  noteDeleted() {
    this.deleteNote({ note: this.note });
  }

  toggleMenuItemForNoteIsClicked() {
    this.toggleMenuItemForNote({ note: 'note' + this.note.noteId });
  }
}
