import { bindable, customElement } from "aurelia-framework";
import { isNone } from "../../../utility";

/**
 * Imitates the "x active filters" + clear filters button (red) on sitelist 2.
 * For use with views that don't use sitelist 2 yet, but want to visually show the user
 * that filters are active (and clear them if buttons are clicked).
 */
@customElement('filter-reset-button')
export class FilterResetButton {
  
  @bindable onClick: Function;

  public callClearFilters(): void {
    if (!isNone(this.onClick)) {
      this.onClick();
    }
  }
} 
