import { customElement, bindable, computedFrom } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import {
  LanguageDropdownQuery,
  LanguageDropdownQuery_languages
} from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';

@customElement('language-dropdown')
export class LanguageDropdown extends GraphQLBaseViewModel<
  void,
  LanguageDropdownQuery,
  void
> {
  @bindable() selectedLanguage: LanguageDropdownQuery_languages | undefined;
  @bindable() selectedCode: string | undefined;
  @bindable() useLanguageCode = false;

  @bindable() itemClicked:
    | undefined
    | ((params: {
        value: LanguageDropdownQuery_languages | string;
        text: string;
      }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() straightRight: boolean | undefined;
  @bindable() straightLeft: boolean | undefined;

  @computedFrom('data', 'selectedLanguage', 'selectedCode', 'useLanguageCode')
  get _selectedObject() {
    if (this.selectedLanguage) return this.selectedLanguage;
    if (this.data && this.data.languages)
      return this.data.languages.find(l => l.fullCode === this.selectedCode);
    return undefined;
  }

  query = gql`
    query LanguageDropdownQuery {
      languages {
        languageId
        code
        fullCode
        description
      }
    }
  `;
}
