const loadedScripts: { [url: string]: Promise<void> | undefined } = {};

export const loadScript = (url: string): Promise<void> => {
  const loadedScript = loadedScripts[url];
  if (loadedScript) return loadedScript;

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = url;

  const promise = new Promise<void>((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = error => {
      reject(error);
    };
  });
  loadedScripts[url] = promise;
  document.body.appendChild(script);

  return promise;
};
