import actionCreatorFactory from 'typescript-fsa';
import { ISite, ISiteChannel } from '../interfaces/index';
import { ISiteAccess } from '../interfaces/application/iSiteAccess';
import { IMultiEditPendingChanges } from '../interfaces/entity/iMultiEdit';

const actionCreator = actionCreatorFactory('site');

export const fetchingSite = actionCreator.async<number, ISite, string>(
  'fetching_site'
);
export const fetchingSites = actionCreator.async<number[], ISite[], string>(
  'fetching_sites'
);
export const fetchingAllSites = actionCreator.async<{}, ISite[], string>(
  'fetching_all_sites'
);
export const persistSite = actionCreator.async<ISite, ISite, string>(
  'persist_site'
);

export const deleteSite = actionCreator.async<
  { siteId: number; nativeControllerIds: number[] },
  void,
  string
>('deleteSite');

export const persistMultipleSites = actionCreator.async<
  { siteIds: number[]; pendingChanges: IMultiEditPendingChanges },
  { sites: ISite[]; channels: ISiteChannel[] },
  string
>('persistMultipleSites');

export const deleteMultipleSites = actionCreator.async<
  { siteIds: number[]; deleteControllers: boolean; alsoSuspendSimCards: boolean },
  void,
  string
>('deleteMultipleSites');

// Get users right to access sites belonging to a site's site_channels' controller's sites
export const fetchSiteAccessForSiteChannelsOnSite = actionCreator.async<
  { siteId: number },
  ISiteAccess[],
  string
>('fetchSiteAccessForSiteChannelsOnSite');
