import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { BaseService } from './baseService';
import { getLogger } from 'aurelia-logging';
import {
  trendChannelDataLoaded,
  userAlarmDataLoaded
} from '../actions/index';
import { IDataProcessRequest } from '../models/trend';
import { getNewId } from '../utility/incrementer';
import { getLocalizedDateRange } from '../utility';
import { IDateRange } from '../interfaces';

@autoinject
export class HistorianService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(HistorianService.name), taskQueue);
  }

  getTrendData = async (
    range: IDateRange,
    request: IDataProcessRequest,
    trendGroupId: number,
    timezone?: string
  ) => {
    const localizedDateRange = getLocalizedDateRange(range, timezone);

    this.logger.debug(
      'Fetching influx data from ' +
        localizedDateRange.queryFrom +
        ' to ' +
        localizedDateRange.queryTo +
        ', showGaps: ' +
        request.showGaps
    );

    return this.httpRequestWithDispatch(
      requests.dataProcessed(
        localizedDateRange.queryFrom,
        localizedDateRange.queryTo,
        request
      ),
      trendChannelDataLoaded,
      { request, trendGroupId, localizedDateRange, internalId: getNewId() },
      'Could not load data for channels'
    );
  };

  getUserAlarmLogs = async (
    range: IDateRange,
    siteId: number,
    siteChannelIds: number[]
  ) =>
    this.httpRequestWithDispatch(
      requests.userAlarmLogs(siteId, {
        siteId,
        fromTime: range.from.toJSON(),
        toTime: range.to.toJSON(),
        siteChannelIds
      }),
      userAlarmDataLoaded,
      undefined,
      'Could not load user alarm logs'
    );

}
