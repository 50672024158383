import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { BaseService } from './baseService';
import { getAsyncEntities } from '../utility/index';
import memoizee from 'memoizee';
import { getContactsByIds } from '../actions/user';

@autoinject
export class ContactService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ContactService.name), taskQueue);
  }

  selectSpecificContacts = memoizee(getAsyncEntities);
  getContacts = (contactIds: number[], tokens: string[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getContactsByIds(tokens),
      getContactsByIds,
      contactIds,
      'Could not fetch contacts',
      state => this.selectSpecificContacts(contactIds, state.contacts.byId)
    );
}
