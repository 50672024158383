export class FilterOnPropertyValueConverter {
  toView(
    array: Array<{}>,
    property: string,
    expression: string,
    startsWithMatch: boolean = false
  ) {
    if (
      array === undefined ||
      array == null ||
      property === undefined ||
      expression === undefined
    ) {
      return array;
    }

    if (startsWithMatch) {
      const items = array.filter(
        (item: any) =>
          item[property] != null &&
          item[property].toLowerCase().startsWith(expression.toLowerCase())
      );
      return items;
    } else {
      return array.filter(
        (item: any) =>
          item[property] != null &&
          item[property].toLowerCase().indexOf(expression.toLowerCase()) >= 0
      );
    }
  }
}
