import {
  ISiteChannel,
  ISite,
  ISiteTankChannel,
  IController,
  ChannelTypes,
  IChannel
} from '../../interfaces/index';
import { ensureDate, isNone, daysBetweenDates, isSomething } from '../index';

export const lastSampleIsOutsideThreshold = (
  minimum: number | undefined,
  lastSample: number | undefined
) => (isNone(minimum) || isNone(lastSample) ? false : minimum >= lastSample);
export const calculateOutsideThreshold = (channel: ISiteChannel) =>
  channel.minimum && channel.lastSample
    ? channel.minimum >= channel.lastSample
    : false;

export const lsdIsOffline = (
  lastSampleDate: string | undefined | null | Date // Site is marked offline after 48 hours of no data
) =>
  !isSomething(lastSampleDate) ||
  ensureDate(lastSampleDate).getTime() <
    new Date().getTime() - 1000 * 60 * 60 * 24 * 2;

export const lsdIsStale = (lastSampleDate: string | undefined | null | Date) =>
  isSomething(lastSampleDate) &&
  ensureDate(lastSampleDate).getTime() >
    new Date().getTime() - 1000 * 60 * 60 * 24 * 2 &&
  ensureDate(lastSampleDate).getTime() <
    new Date().getTime() - 1000 * 60 * 60 * 24 * 1;

export const entityIsOffline = (
  site: ISite | ISiteChannel | IController // Site is marked offline after 48 hours of no data
) => lsdIsOffline(site.lastSampleTime);

export const entityIsStale = (site: ISite | ISiteChannel | IController) =>
  lsdIsStale(site.lastSampleTime);

export const channelPropertiesIsInErrorState = (
  minimum: number | undefined,
  maximum: number | undefined,
  lastSample: number | undefined,
  capacity: number | undefined
) =>
  isNone(minimum) || isNone(maximum) || isNone(lastSample) || isNone(capacity);

export const channelIsInErrorState = (channel: ISiteChannel) =>
  channelPropertiesIsInErrorState(
    channel.minimum,
    channel.maximum,
    channel.lastSample,
    channel.capacity
  );

export const hasNoConsumption = (reachMinimum: undefined | string | Date | null) =>
  isSomething(reachMinimum)
    ? (daysBetweenDates(reachMinimum, new Date()) || 0) > 365 
    : false;

export const channelHasNoConsumption = (channel: ISiteTankChannel) =>
  hasNoConsumption(channel.tankDetails.reachMinimum);

export const channelIsTankChannel = (
  siteChannel: ISiteChannel
): siteChannel is ISiteTankChannel =>
  siteChannel.channelType === ChannelTypes.Tank &&
  !isNone(siteChannel.tankDetails);

export const channelIsDoseChannel = (channel: IChannel): boolean =>
  channel.channelType === ChannelTypes.Dose;

export const channelIsGasChannel = (channel: IChannel): boolean =>
  channel.channelType === ChannelTypes.H2SGas;

export const channelIsAggregatedDoseChannel = (channel: IChannel): boolean =>
  channel.channelType === ChannelTypes.AggregatedDose;

export const channelIsStockVsDoseChannel = (channel: IChannel): boolean =>
  channel.channelType === ChannelTypes.StockVsDoseAccuracy;
