import { bindable, autoinject } from 'aurelia-framework';
import './mobile-modal-topbar.css';

@autoinject()
export class MobileModalTopbarCustomElement {
  @bindable title: string;
  @bindable onClose: Function;

  close = () => {
    if (!!this.onClose) {
      this.onClose();
    }
  };
}
