import { isNone } from './helpers';

export const assureArray = <T>(maybeArray: T[] | T) =>
  Array.isArray(maybeArray) ? maybeArray : [maybeArray];

export const isArray = <T>(maybeArray: T[] | T): maybeArray is T[] =>
  Array.isArray(maybeArray);

export const arrayConcat = <T>(a: T[], b: T[]) => a.concat(b);

export const flatMap = <T, U>(a: T[], func: (t: T) => U[]): U[] =>
  a.reduce((acc, x) => acc.concat(func(x)), [] as U[]);

export const removeNoneFromArray = <T>(a: Array<T | undefined>): T[] => {
  const arr: T[] = [];
  for (const b of a) if (!isNone(b)) arr.push(b);
  return arr;
};

export const removeNoneAndEmptyFromArray = <T>(a: Array<T | undefined>): T[] => {
  const arr: T[] = [];
  const x: any[] = a;
  for (const b of x) { 
    if (!isNone(b) && b.toString?.().trim().length > 0) 
      arr.push(b);
  }
  return arr;
};


export const mapLeftMerge = <T, K>(
  t: T[],
  k: K[],
  comparer: (t: T, k: K) => boolean,
  merger: (t: T, k: K | undefined) => T
) => t.map(st => merger(st, k.find(skf => comparer(st, skf))));

export const distinct = <T>(array: T[], predicate?: (item: T) => any): T[] => {
  if (!array) return array;

  if (!predicate) return Array.from(new Set(array));
  const map = new Map<T[keyof T], T>();

  array.forEach(element => {
    const key = predicate(element);
    if (!map.has(key)) map.set(key, element);
  });

  return Array.from(map.values());
};


export const groupByWithSelect = <T, U>(list: T[], predicate: (item: T) => any, valueSelector: (item: T) => U) => {
  const map: Record<string, U[]> = {};

  list.forEach(item => {
    const value = valueSelector(item);
    const key = predicate(item);
    const exists = map[key];
    if (!exists) map[key] = [value];
    else map[key].push(value);
  });

  return map;
};

export const groupBy = <T>(list: T[], predicate: (item: T) => any) => {
  const map: Record<string, T[]> = {};

  list.forEach(item => {
    const key = predicate(item);
    const exists = map[key];
    if (!exists) map[key] = [item];
    else map[key].push(item);
  });

  return map;
};

export const SortArray = (values: any[], orderType: any) => {
  return values.sort((a, b) => {
    if (a[orderType] < b[orderType]) return -1;

    if (a[orderType] > b[orderType]) return 1;

    return 0;
  });
};

export const parseStringAsIntList = (input: string): number[] =>
  input.split(',').map(n => parseInt(n, 10)).filter(number => !isNaN(number));

export const difference = <T>(arr: T[], arr2: T[]): T[] => {
    return arr.filter(function(i) {return arr2.indexOf(i) < 0; });
};
