import './number-input.css';
import {
  bindable,
  PLATFORM,
  computedFrom,
  ComponentDetached,
  autoinject
} from 'aurelia-framework';
import {
  isEmpty,
  isNone,
  emptyArray,
  assureArray,
  isSomething,
  isFiniteNumber
} from '../../../utility';
import { createCustomEvent } from '../../../utility/customEventHelper';

type errorType = undefined | string | string[] | boolean;

const isValidationError = (error: errorType): boolean => {
  return !(typeof error === 'boolean' || isNone(error));
};

@autoinject()
export class NumberInput implements ComponentDetached {
  @bindable value: number | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable step: number | string | undefined = 1;
  @bindable error: errorType;
  @bindable required: boolean = false;
  @bindable inline: boolean = false;
  @bindable hasFocus: boolean;
  @bindable autofocus: boolean;
  @bindable placeholder: string | undefined;

  inputelement: HTMLElement;

  attached() {
    this.runValidation(this.value);
    if(this.autofocus) {
      this.inputelement.focus();
    }
  }


  detached() {
    dispatchEvent(
      createCustomEvent('inputremoved', this.inputelement)
    );
  }

  valueChanged(newValue: string | number | undefined) {
    this.onChanged && this.onChanged({ value: newValue });

    if (isSomething(this.inputelement)) {
      this.runValidation(newValue);
    }
  }

  keyDown(event: KeyboardEvent) {
    if (!this.step && (event.keyCode === 38 || event.keyCode === 40)) {
      event.preventDefault();
      return;
    }
    return true;
  }

  keyPress({ charCode }: KeyboardEvent) {
    return charCode >= 48 && charCode <= 57;
  }

  @computedFrom('error')
  get hasValidationErrors() {
    return typeof this.error === 'boolean' ? this.error : !isEmpty(this.error);
  }

  @computedFrom('error')
  get errorTexts() {
    return !isValidationError(this.error)
      ? emptyArray
      : assureArray(this.error);
  }

  runValidation(newValue: string | number | undefined) {
    const oldError = this.error;
    this.error = this.requiredValidation(newValue);

    if (isValidationError(this.error) !== isValidationError(oldError)) {
      this.notifyOfValidationErrors();
    }
  }

  requiredValidation(newValue: string | number | undefined): errorType {
    if (this.required && !isFiniteNumber(newValue)) {
      return ['UI_Common_ValidationError_RequiredField'];
    }
    return undefined;
  }

  callOnBlur() {
    this.runValidation(this.value);
    if (!this.onBlur) return;
    this.onBlur({ value: this.value });
  }

  notifyOfValidationErrors() {
    if (isNone(this.inputelement)) {
      return;
    }
    if (isValidationError(this.error)) {
      this.inputelement.dispatchEvent(
        createCustomEvent(
          'validationerrors',
          this.inputelement
        )
      );
    } else if (!isValidationError(this.error)) {
      this.inputelement.dispatchEvent(
        createCustomEvent('validationsuccess', this.inputelement)
      );
    }
  }
}
