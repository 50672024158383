import {
  clearSelection,
  toggleColumnSelector,
  selectAllChanged,
  sortingChanged,
  toggleSiteListVisibility,
  scrollChanged,
  toggleSitelistMap,
  sitelistMapBoundsChanged,
  searchByTerm,
  singleSelectionChanged,
  loadSavedUserState,
  resetFilters,
  selectionChanged,
  saveColumns,
  addMobileTo,
  deleteMultipleSites,
  filterIsOpen,
  applyFilter
} from '../../actions';

import {
  appendObjectToArray,
  removeObjectFromArray,
  emptyArray,
  mergeObjectsWithCache,
  mergeObjects,
  getLocalStorageItemByUser,
  removeNoneFromArray
} from '../../utility';
import { ISiteColumn } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  getSessionUserId,
  getSessionClientEncryptionKey
} from '../../config/sessionService';
import { mapFromColumnIdToISiteColumn } from '../entity/columnsReducer';

export interface ISiteListReducerState {
  mobileTo: number;
  searchTerm: string;
  sortProperty: string;
  sortDirection: string;
  toggleColumnSelector: boolean;
  toggleFilterSelector: boolean;
  showSiteList: boolean;
  selectedSiteIds: number[];
  allSitesSelected: boolean;
  scrollPosition: number;
  showMap: boolean;
  mapBounds?: string;
  mapZoom: number;
  filterIsOpen: boolean;
}

const storageKey = 'sitelist';

const defaultState: ISiteListReducerState = {
  searchTerm: '',
  sortProperty: 'alias',
  sortDirection: 'asc',
  toggleColumnSelector: false,
  toggleFilterSelector: false,
  showSiteList: true,
  selectedSiteIds: [],
  allSitesSelected: false,
  scrollPosition: 0,
  showMap: true,
  mapZoom: 5,
  mobileTo: 100,
  filterIsOpen: false
};

const resetSelectedIds = (state: ISiteListReducerState) =>
  mergeObjects(state, { selectedSiteIds: emptyArray });

const applyColumnSelectionChangedState = (
  state: ISiteListReducerState,
  columns: ISiteColumn[]
) => {
  const sortPropertyExists = columns.some(
    column => column.property === state.sortProperty
  );

  return sortPropertyExists
    ? state
    : mergeObjectsWithCache(storageKey, state, {
        sortProperty: '',
        sortDirection: ''
      });
};

const reducer = reducerWithInitialState(defaultState)
  .case(resetFilters, state =>
    mergeObjectsWithCache(storageKey, state, { searchTerm: '' })
  )
  .case(searchByTerm, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, { searchTerm: payload })
  )
  .case(sortingChanged, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, {
      sortProperty: payload.property,
      sortDirection: payload.direction
    })
  )
  .case(selectAllChanged, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, { selectedSiteIds: payload })
  )
  .case(toggleSiteListVisibility, (state, payload) =>
    mergeObjectsWithCache(storageKey, state, { showSiteList: payload })
  )
  .case(toggleSitelistMap, state =>
    mergeObjectsWithCache(storageKey, state, { showMap: !state.showMap })
  )

  .case(filterIsOpen, (state, payload) => ({ ...state, filterIsOpen: payload }))
  .case(applyFilter, state => ({ ...state, filterIsOpen: false }))

  .case(addMobileTo, (state, payload) => ({ ...state, mobileTo: payload }))

  .case(toggleColumnSelector, state =>
    mergeObjects(state, { toggleColumnSelector: !state.toggleColumnSelector })
  )
  .case(clearSelection, state => resetSelectedIds(state))
  .case(selectionChanged, (state, { value, site }) =>
    mergeObjects(state, {
      selectedSiteIds: value
        ? appendObjectToArray(state.selectedSiteIds, site.siteId)
        : removeObjectFromArray(state.selectedSiteIds, site.siteId)
    })
  )
  .case(singleSelectionChanged, (state, { site }) =>
    mergeObjects(state, { selectedSiteIds: [site.siteId] })
  )
  .case(saveColumns.started, (state, payload) =>
    applyColumnSelectionChangedState(
      state,
      removeNoneFromArray(payload.map(mapFromColumnIdToISiteColumn))
    )
  )
  .case(scrollChanged, (state, payload) =>
    mergeObjects(state, { scrollPosition: payload })
  )
  .case(sitelistMapBoundsChanged, (state, { bounds, zoom }) =>
    mergeObjects(state, { mapBounds: bounds, mapZoom: zoom })
  )

  .case(loadSavedUserState, state =>
    mergeObjects(
      state,
      getLocalStorageItemByUser(
        storageKey,
        getSessionUserId(),
        defaultState,
        getSessionClientEncryptionKey()
      ),
      {
        toggleColumnSelector: false,
        toggleFilterSelector: false,
        selectedSiteIds: [],
        allSitesSelected: false,
        scrollPosition: 0,
        filterIsOpen: false
      }
    )
  )

  .case(deleteMultipleSites.done, state => ({
    ...state,
    selectedSiteIds: []
  }));

export default reducer;
