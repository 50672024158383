import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  navigateToRoute,
  equipmentParameterChanged,
  changeEquipmentAttachment,
  addEquipmentAttachment,
  changeAttachmentBlob,
  toggleExpandedAttachment,
  removeEquipmentAttachment,
  persistingNewSiteEquipment,
  updateSiteEquipment,
  cancelEquipmentEditing
} from '../../actions';
import {
  mergeObjects,
  getNewId,
  emptyArray,
  removeNoneFromArray,
  appendOrReplaceObjectInArray
} from '../../utility';
import { IEquipment, IEquipmentAttachment } from '../../interfaces';

export interface ISiteDetailsAssetReducerState {
  equipmentToEdit: Partial<IEquipment>;
  expandedAttachments: number[];
  attachmentsToDelete: number[];
}

const defaultState: ISiteDetailsAssetReducerState = {
  equipmentToEdit: {},
  expandedAttachments: [],
  attachmentsToDelete: []
};

const emptyEquipmentAttachment = (
  equipmentToEdit: Partial<IEquipment>
): IEquipmentAttachment => ({
  alias: '',
  description: '',
  attachmentType: 6,
  restricted: false,
  uploaded: new Date().toJSON(),
  internalId: getNewId(),
  equipmentId: equipmentToEdit.equipmentId,
  isNew: true,
  expanded: true
});

const closeEquipmentToEdit: Partial<ISiteDetailsAssetReducerState> = {
  equipmentToEdit: {}
};

const checkAttachmentIds = (attachment1: IEquipmentAttachment) => (
  attachment2: IEquipmentAttachment
) =>
  attachment1.internalId && attachment2.internalId
    ? attachment1.internalId === attachment2.internalId
    : attachment1.attachmentId === attachment2.attachmentId;

const getIdOfAttachment = (attachment: IEquipmentAttachment) =>
  attachment.isNew ? attachment.internalId : attachment.attachmentId;

const reducer = reducerWithInitialState(defaultState)
  .case(navigateToRoute, state => ({
    ...state,
    ...closeEquipmentToEdit,
    expandedAttachments: emptyArray
  }))
  /* Assets */
  .case(equipmentParameterChanged, (state, payload) =>
    mergeObjects(state, {
      equipmentToEdit: mergeObjects(state.equipmentToEdit, {
        [payload.property]: payload.value
      })
    })
  )
  .case(
    changeEquipmentAttachment,
    (state, { attachment, property, value }) => ({
      ...state,
      equipmentToEdit: {
        ...state.equipmentToEdit,
        attachments: appendOrReplaceObjectInArray(
          state.equipmentToEdit.attachments || [],
          checkAttachmentIds(attachment),
          { ...attachment, [property]: value }
        )
      }
    })
  )

  .case(addEquipmentAttachment, state => ({
    ...state,
    equipmentToEdit: {
      ...state.equipmentToEdit,
      attachments: [
        ...(state.equipmentToEdit.attachments || []),
        emptyEquipmentAttachment(state.equipmentToEdit)
      ]
    }
  }))
  .case(changeAttachmentBlob.started, (state, { attachment, fileInfo }) => ({
    ...state,
    equipmentToEdit: {
      ...state.equipmentToEdit,
      attachments: appendOrReplaceObjectInArray(
        state.equipmentToEdit.attachments || [],
        checkAttachmentIds(attachment),
        {
          ...attachment,
          fileInfo: {
            ...attachment.fileInfo,
            ...fileInfo,
            uploading: true
          }
        }
      )
    }
  }))
  .case(changeAttachmentBlob.done, (state, { params, result }) => ({
    ...state,
    equipmentToEdit: {
      ...state.equipmentToEdit,
      attachments: appendOrReplaceObjectInArray(
        state.equipmentToEdit.attachments || [],
        checkAttachmentIds(params.attachment),
        {
          ...params.attachment,
          fileInfo: {
            ...params.attachment.fileInfo,
            ...params.fileInfo,
            ...result,
            uploading: false
          }
        }
      )
    }
  }))

  .case(toggleExpandedAttachment, (state, payload) => ({
    ...state,
    expandedAttachments: state.expandedAttachments.find(
      a => a === getIdOfAttachment(payload)
    )
      ? state.expandedAttachments.filter(a => a !== getIdOfAttachment(payload))
      : removeNoneFromArray([
          ...state.expandedAttachments,
          getIdOfAttachment(payload)
        ])
  }))
  .case(removeEquipmentAttachment, (state, payload) => ({
    ...state,
    attachmentsToDelete: payload.attachmentId
      ? [...state.attachmentsToDelete, payload.attachmentId]
      : state.attachmentsToDelete,
    equipmentToEdit: !state.equipmentToEdit.attachments
      ? state.equipmentToEdit
      : {
          ...state.equipmentToEdit,
          attachments: state.equipmentToEdit.attachments.filter(
            a => a !== payload
          )
        }
  }))
  .case(cancelEquipmentEditing, (state) => ({
    ...state,
    attachmentsToDelete: []
  }))
  .cases(
    [persistingNewSiteEquipment.started, updateSiteEquipment.started],
    state => ({
      ...state,
      equipmentToEdit: {
        ...state.equipmentToEdit,
        isSaving: true
      }
    })
  )
  .cases(
    [persistingNewSiteEquipment.done, updateSiteEquipment.done],
    (state, { result }) => ({
      ...state,
      equipmentToEdit: {
        ...state.equipmentToEdit,
        ...result,
        isSaving: false,
        equipmentId: result.equipmentId
      }
    })
  );

export default reducer;
