import actionCreatorFactory from 'typescript-fsa';
import {
  IExportJob,
  IExportField,
  IBuid,
  ICustomer
} from '../interfaces/index';
import { ISiteExport } from '../interfaces/entity/iSiteExport';
import { IExportJobNameAndId } from '../interfaces/entity/iexportjobNameAndId';

const actionCreator = actionCreatorFactory('exportjobs');

export const getExportJobsAsync = actionCreator.async<{}, IExportJob[]>(
  'Fetched_ExportJobs_Async'
);
export const getExportJobAsync = actionCreator.async<
  number,
  IExportJob,
  string
>('fetching_export_job');

export const getExportJobNamesAsync = actionCreator.async<
  {},
  Array<IExportJobNameAndId>
>('getExportJobNamesAsync');
export const exportJobPropertyChanged = actionCreator<{
  property: string;
  value: string;
}>('exportJobPropertyChanged');
export const exportFieldSelectedChanged = actionCreator<IExportField>(
  'exportFieldSelectedChanged'
);
export const moveFieldDownAction = actionCreator<IExportField>(
  'moveFieldDownAction'
);
export const moveFieldUpAction = actionCreator<IExportField>(
  'moveFieldUpAction'
);

export const exportSelectedBuidChanged = actionCreator<{
  buid: IBuid | undefined;
}>('export_selected_buid_changed');

export const exportSelectedCustomerChanged = actionCreator<{
  customer: ICustomer | undefined;
}>('export_selected_customer_changed');

export const getExportedChannelsForSite = actionCreator.async<
  number,
  ISiteExport[]
>('getExportedChannelsForSite');

export const getExportedChannelsForSiteChannel = actionCreator.async<
  number,
  ISiteExport[]
>('getExportedChannelsForSiteChannel');

export const addSiteChannelToExportJob = actionCreator<{
  job: IExportJobNameAndId;
  existing: IExportJobNameAndId[];
}>('addSiteChannelToExportJob');
export const removeSiteChannelFromExportJob = actionCreator<{
  jobId: number;
  existing: IExportJobNameAndId[];
}>('removeSiteChannelFromExportJob');
