import { isObjectGuard } from './objectHelpers';
import { isSomething } from './helpers';
import memoizee from 'memoizee';
import moment from 'moment';

const findValue = (obj: any, path: string) => {
  const parts = path.split('.');
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part in obj) {
      obj = obj[part];
    } else {
      return;
    }
  }
  return obj;
};

export function orderBy<T>(
  array: T[],
  property: string,
  direction: string
): T[] {
  console.warn('Do not use this. Use orderByPredicate instead!');
  const internalArray: T[] = [...array];

  internalArray.sort((a: any, b: any) => {
    const first = findValue(a, property);
    const second = findValue(b, property);

    if (!first) return direction === 'asc' ? -1 : 1;
    if (!second) return direction === 'asc' ? 1 : -1;

    if (first < second) return direction === 'asc' ? -1 : 1;
    else if (first > second) return direction === 'asc' ? 1 : -1;
    else return 0;
  });

  return internalArray;
}

class CustomCache<T, U> {
  private primitiveCache: Record<string, U> = {};
  private objectCache = new WeakMap<object, U>();

  has = (key: T) => {
    return isObjectGuard(key)
      ? this.objectCache.has(key)
      : JSON.stringify(key || '') in this.primitiveCache;
  };

  get = (key: T) => {
    return isObjectGuard(key)
      ? this.objectCache.get(key)
      : this.primitiveCache[JSON.stringify(key || '')];
  };

  set = (key: T, value: U) => {
    if (isObjectGuard(key)) this.objectCache.set(key, value);
    else this.primitiveCache[JSON.stringify(key || '')] = value;
  };

  getOrCreate = (key: T, func: (i: T) => U) => {
    if (this.has(key)) {
      return this.get(key);
    }

    const val = func(key);
    this.set(key, val);
    return val;
  };
}

function isDate(v : string) : boolean {
  const date = toMoment(v);
  return (date != null && date.isValid());
}

function toMoment(v : string)  {
  let date = moment(v, ['DD.MM.YYYY HH:mm', 'DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY'], true);
  
  if (date.isValid())
    return date;
  
  return null;
}

export function orderByPredicate<T>(
  array: T[],
  predicate: (item: T) => T[keyof T] | undefined | null | number | string | T,
  direction: string,
  useCache = false
): T[] {
  const internalArray: T[] = [...array];

  const cache = new CustomCache<T, T[keyof T] | undefined | null | number | string | T>();
  const maybeCachedPredicate = useCache ? cache.getOrCreate : predicate;
  internalArray.sort((a: T, b: T) => {
    let first = maybeCachedPredicate(a, predicate);

    if (!first) {
      return direction === 'asc' ? -1 : 1;
    }

    let second = maybeCachedPredicate(b, predicate);

    if (!second) return direction === 'asc' ? 1 : -1;
    
    //Handle strings and dates as strings
    if (typeof first === 'string' && typeof second === 'string') {
      first = first.toLowerCase().trim();
      second = second.toLowerCase().trim();

      if (isDate(first) || isDate(second)) {
        const firstDate = toMoment(first)?.unix() ?? 0;
        const secondDate = toMoment(second)?.unix() ?? 0;

        if (firstDate < secondDate) return direction === 'asc' ? -1 : 1;
        else if (firstDate > secondDate) return direction === 'asc' ? 1 : -1;
        else return 0;
      }
    }
    
    //Sort numbers or other non-string values
    if (first < second) return direction === 'asc' ? -1 : 1;
    else if (first > second) return direction === 'asc' ? 1 : -1;
    else return 0;
  });
  return internalArray;
}

export interface IStringComparisonOptions {
  direction?: 'asc' | 'desc';
  ignoreCase?: boolean;
  locale?: string;
}

interface IKeyIndex {
  key: string;
  index: number;
}

const innerStringSorter = memoizee(
  (pairs: IKeyIndex[], compareFn: (a: string, b: string) => number) => {
    return pairs.sort((a, b) => compareFn(a.key, b.key));
  },
  { primitive: true }
);

export function orderByString<T>(
  array: T[],
  projection: (item: T) => string,
  options?: IStringComparisonOptions
): T[] {
  const locale =
    isSomething(options) && isSomething(options.locale)
      ? options.locale
      : undefined;
  const ignoreCase =
    isSomething(options) && isSomething(options.ignoreCase)
      ? options.ignoreCase
      : false;
  const sortDirection =
    isSomething(options) && isSomething(options.direction)
      ? options.direction
      : 'asc';

  const getCompareFn = () => {
    if (sortDirection === 'asc')
      return locale
        ? (a: string, b: string) => a.localeCompare(b, locale)
        : (a: string, b: string) => a.localeCompare(b);
    else
      return locale
        ? (a: string, b: string) => b.localeCompare(a, locale)
        : (a: string, b: string) => b.localeCompare(a);
  };

  const getKey = (item: T): string => {
    const key = projection(item);
    if (ignoreCase) return isSomething(key) ? key.toLocaleLowerCase() : key;
    else return key;
  };

  const compareFn = getCompareFn();
  const sortedKeysAndIndexes = innerStringSorter(
    array.map((item, index) => ({ key: getKey(item), index })),
    compareFn
  );
  return sortedKeysAndIndexes.map(pair => array[pair.index]);
}
