import {
  getAllCustomers,
  getCustomerById,
  getCustomerByToken,
  getCustomersByTokens
} from '../../actions';
import {
  mergeObjects,
  insertAllFetchedEntities,
  insertInflightEntity,
  insertFetchedEntities,
  insertInflightEntities,
  insertFetchedEntity
} from '../../utility';
import { ICustomer, IAsyncReducerState } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { createPendingEntity } from '../../types';

export interface ICustomerReducerState extends IAsyncReducerState<ICustomer> {}

const defaultState: ICustomerReducerState = {
  byId: {},
  allIds: undefined
};

const reducer = reducerWithInitialState(defaultState)
  .case(getAllCustomers.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )
  .case(getAllCustomers.done, (state, payload) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, payload.result, r => r.customerId)
    )
  )

  .case(getCustomerByToken.started, (state, params) =>
    insertInflightEntity(state, params)
  )
  .case(getCustomerByToken.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntity(state, result.customerId, result, false)
    )
  )
  .case(getCustomerById.started, (state, params) =>
    insertInflightEntity(state, params)
  )
  .case(getCustomerById.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntity(state, result.customerId, result, false)
    )
  )
  .case(getCustomersByTokens.started, (state, params) =>
    insertInflightEntities(state, params)
  )
  .case(getCustomersByTokens.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, r => r.customerId, false)
  }));
// .case(getAllAllowedCustomers.done, (state, payload) => mergeObjects(state, { allowedCustomers: payload.result }))

export default reducer;
