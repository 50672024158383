import { ICustomerManager, IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertAllFetchedEntities,
  emptyArray,
  assureArray,
  insertFetchedEntity,
  insertFetchedEntities,
  removeAsyncObjects,
  insertInflightEntity,
  insertInflightEntities
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { removeCustomers, navigateToRoute } from '../../actions';
import actionCreatorFactory from 'typescript-fsa';
import {
  createPendingEntity,
  requestError,
  IAsyncDictionary,
  createFetchedEntity
} from '../../types';

const actionCreator = actionCreatorFactory('customermanager');

export const getAllAllowedCustomermanagers = actionCreator.async<
  {},
  ICustomerManager[]
>('CUSTOMERMANAGER_GET_ALL_ALLOWED_CUSTOMER_MANAGERS');
export const getCustomermanagersByIds = actionCreator.async<
  number[],
  ICustomerManager[]
>('getCustomermanagersByIds');
export const getCustomermanagersById = actionCreator.async<
  number,
  ICustomerManager
>('getCustomermanagersById');
export const filterCustomerManagers = actionCreator<string>(
  'CUSTOMERMANAGER_FILTER_USERS'
);
export const toggleCheckedCustomerManagager = actionCreator<{
  action: 'add' | 'remove';
  ids: number[] | number;
}>('toggleCheckedCustomerManagager');
export const clearCustomerManagerFilter = actionCreator(
  'CUSTOMERMANAGER_CLEAR_CUSTOMER_FILTER'
);
export const sortCustomerList = actionCreator<string>(
  'CUSTOMERMANAGER_SORT_CUSTOMER_LIST'
);
export const customerManagerChange = actionCreator<{
  property: string;
  value: string | number;
}>('customerManagerChange');
export const sapLookupCustomerManager = actionCreator.async<
  {},
  ICustomerManager,
  requestError
>('CUSTOMERMANAGER_SAP_LOOKUP');
export const addCustomerManager = actionCreator.async<
  {},
  ICustomerManager,
  requestError
>('CUSTOMERMANAGER_ADD_CUSTOMER');
export const updateCustomerManager = actionCreator.async<
  number,
  ICustomerManager,
  requestError
>('CUSTOMERMANAGER_UPDATE_CUSTOMER');
export const updateCustomerManagers = actionCreator.async<
  { customerManager: ICustomerManager; customerIds: number[] },
  ICustomerManager[],
  requestError
>('CUSTOMERMANAGER_UPDATE_CUSTOMERS');
export const fetchDefaultCustomerUsers = actionCreator.async<
  number,
  IUserTokenAndAccess[],
  requestError
>('fetchDefaultCustomerUsers');
export const fetchAccessCustomerUsers = actionCreator.async<
  string,
  IPendingUserTokenAndAccess,
  requestError
>('fetchAccessCustomerUsers');

interface IUserTokenAndAccess {
  userId: number;
  token: string;
  hasAccess: boolean;
}

export interface IUserTokenAndAccessRemovedAndAdded {
  userId: number;
  token: string;
  hasAccess: boolean;
  removed: boolean;
  added: boolean;
}

export interface IPendingUserTokenAndAccess {
  new: IUserTokenAndAccess[];
  removed: number[];
  current: IUserTokenAndAccess[];
}

export interface ICustomerManagerReducerState
  extends IAsyncReducerState<ICustomerManager> {
  customerFilter: string;
  checkedCustomersIds: number[];
  sortProperty: string;
  sortDirection: string;
  pendingCustomerManager?: Partial<ICustomerManager>;
  defaultCustomerUsers: IAsyncDictionary<IUserTokenAndAccess[]>;
  accessCustomerUsers: IAsyncDictionary<IPendingUserTokenAndAccess>;
}

const defaultState: ICustomerManagerReducerState = {
  allIds: undefined,
  byId: {},
  checkedCustomersIds: [],
  sortDirection: 'asc',
  sortProperty: 'name',
  customerFilter: '',
  pendingCustomerManager: undefined,
  defaultCustomerUsers: {},
  accessCustomerUsers: {}
};

const applySortCustomerList = (
  state: ICustomerManagerReducerState,
  payload: string
) => {
  if (state.sortProperty === payload)
    return mergeObjects(state, {
      sortDirection: state.sortDirection === 'asc' ? 'desc' : 'asc'
    });
  else
    return mergeObjects(state, { sortDirection: 'asc', sortProperty: payload });
};

const customerManagerReducer = reducerWithInitialState(defaultState)
  .case(fetchAccessCustomerUsers.started, (state, params) => ({
    ...state,
    accessCustomerUsers: {
      ...state.accessCustomerUsers,
      [params]: createPendingEntity()
    }
  }))
  .case(fetchAccessCustomerUsers.done, (state, { result, params }) => ({
    ...state,
    accessCustomerUsers: {
      ...state.accessCustomerUsers,
      [params]: createFetchedEntity(result)
    }
  }))
  .case(fetchDefaultCustomerUsers.started, (state, params) => ({
    ...state,
    defaultCustomerUsers: {
      ...state.defaultCustomerUsers,
      [params]: createPendingEntity()
    }
  }))
  .case(fetchDefaultCustomerUsers.done, (state, { result, params }) => ({
    ...state,
    defaultCustomerUsers: {
      ...state.defaultCustomerUsers,
      [params]: createFetchedEntity(result)
    }
  }))
  .case(customerManagerChange, (state, { property, value }) => ({
    ...state,
    pendingCustomerManager: {
      ...state.pendingCustomerManager,
      [property]: value
    }
  }))

  .case(filterCustomerManagers, (state, customerFilter) => ({
    ...state,
    customerFilter
  }))

  .case(sortCustomerList, (state, payload) =>
    applySortCustomerList(state, payload)
  )

  .case(toggleCheckedCustomerManagager, (state, { action, ids }) => {
    const checkedCustomersIds = new Set(state.checkedCustomersIds);
    const arrayOfIds = assureArray(ids);
    for (const id of arrayOfIds)
      if (action === 'remove') checkedCustomersIds.delete(id);
      else if (action === 'add' && !checkedCustomersIds.has(id))
        checkedCustomersIds.add(id);
    return { ...state, checkedCustomersIds: Array.from(checkedCustomersIds) };
  })

  .case(navigateToRoute, state => ({
    ...state,
    pendingCustomerManager: undefined
  }))

  .case(getAllAllowedCustomermanagers.started, state => ({
    ...state,
    allIds: createPendingEntity()
  }))

  .case(getAllAllowedCustomermanagers.done, (state, { result }) => ({
    ...state,
    ...insertAllFetchedEntities(state, result, e => e.customerId)
  }))

  .case(getCustomermanagersById.started, (state, payload) => ({
    ...state,
    ...insertInflightEntity(state, payload)
  }))

  .case(getCustomermanagersByIds.started, (state, payload) => ({
    ...state,
    ...insertInflightEntities(state, payload)
  }))

  .case(getCustomermanagersById.done, (state, { result, params }) => ({
    ...state,
    ...insertFetchedEntity(state, params, result)
  }))

  .case(getCustomermanagersByIds.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, r => r.customerId)
  }))

  .case(removeCustomers.done, (state, { params }) => ({
    ...state,
    ...removeAsyncObjects(state, params),
    checkedCustomersIds: emptyArray
  }))

  .case(addCustomerManager.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntity(state, result.customerId, result),
    pendingCustomerManager: undefined
  }))

  .case(updateCustomerManager.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntity(state, result.customerId, result),
    pendingCustomerManager: undefined,
    checkedCustomersIds:
      state.checkedCustomersIds.length === 1 &&
      result.customerId === state.checkedCustomersIds[0]
        ? emptyArray
        : state.checkedCustomersIds,
    accessCustomerUsers: {},
    defaultCustomerUsers: {}
  }))

  .case(updateCustomerManagers.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, r => r.customerId),
    pendingCustomerManager: undefined,
    checkedCustomersIds: emptyArray,
    accessCustomerUsers: {},
    defaultCustomerUsers: {}
  }));

export default customerManagerReducer;
