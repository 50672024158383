import { bindable, customElement } from 'aurelia-templating';
import { computedFrom } from 'aurelia-framework';
import { assureArray } from '$lib/arrayHelpers';

interface TextWithHighlight {
  text: string;
  highlight: boolean;
}

@customElement('grid-text')
export class GridText {
  @bindable() text?: string;
  @bindable() texthighlight?: string[] | string;

  @computedFrom('text', 'textToHighlight')
  get textArray(): TextWithHighlight[] {
    const trimmedText = this.text?.toString().trim();
    if (!trimmedText) return [{ text: '', highlight: false }];

    if (
      !this.texthighlight ||
      this.texthighlight === '' ||
      this.texthighlight.length === 0 ||
      (this.texthighlight.length === 1 && this.texthighlight[0] === '')
    ) {
      return [{ text: trimmedText, highlight: false }];
    }

    const matchingWords = assureArray(this.texthighlight).join('|');
    const matcher = new RegExp(`(${matchingWords})`, 'gi');

    return trimmedText
      .split(matcher)
      .map((part) => ({ text: part, highlight: matcher.test(part) }));
  }
}
