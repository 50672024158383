import './toaster.css';
import { customElement } from 'aurelia-framework';
import { INotifications } from '../../interfaces';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { notificationNotify } from '../../services/notificationService';
import { isMobileWatcher } from '../../utility';

type subscribeFn = ((n: INotifications) => void);

let listeners: subscribeFn[] = [];

export function subscribeToNotification(subscribeFn: subscribeFn) {
  listeners.push(subscribeFn)

  return () => {
    listeners = listeners.filter(l => l != subscribeFn)
  }
}

export function publishNotification(notification: INotifications) {
  for (const listener of listeners) {
    listener(notification)
  }
}

@customElement('toaster')
export class ToasterCustomElement {
  notifications: INotifications[] = [];
  subscription: Subscription | undefined;
  isMobile: boolean = false;
  private unsubscribeWatcher: Function;

  constructor(eventAggregator: EventAggregator) {
    this.subscription = eventAggregator.subscribe(
      notificationNotify,
      (notification: INotifications) => {
        //Check if notification already is there (or has been updated)
        let canAdd = (notification.type !== "UNSUPPORTED_BROWSER" && !this.notifications.some(n => n === notification));
        
        if (notification.type === "SYSTEM-UPDATED" && this.notifications.some(n => n.type === "SYSTEM-UPDATED")) {
          canAdd = false;        // only one sys update notification at any time
        }

        if (canAdd) {
          this.notifications.push(notification);
        }

        //Remove expired notifications
        this.notifications = this.notifications.filter(n => !n.acknowledged);
      }
    );

    this.unsubscribeWatcher = isMobileWatcher((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  detached() {
    if (this.subscription) this.subscription.dispose();
    this.unsubscribeWatcher && this.unsubscribeWatcher();
  }
}
