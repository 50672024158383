import {
  mergeObjects,
  isNone,
  appendObjectToArray,
  isInvalidNumber,
  appendOrReplaceObjectInArray
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  ICurrentlyEditedNote,
  IUser,
  ISavingParametersStatus,
  ICurrentlyEditingProfile,
  IRequestStateWrapper,
  ISite
} from '../../interfaces';
import {
  cancelNoteEditAction,
  noteValuesEdited,
  postNewNoteAction,
  markNoteForEditAction,
  toggleCommandPallet,
  navigateToRoute,
  editParameters,
  cancelEditParameters,
  persistControllerParameters,
  updateNoteAction,
  fetchingControllerLogsAction,
  profileEditorSetSelectedDay,
  profileEditorValueChanged,
  profileEditorFormulaValueChanged,
  profileEditorFormulaOperationClicked,
  persistingControllerProfile,
  profileEditorCopyWorkingSetToDays,
  profileEditorChangeYScale,
  fetchingTankHistory,
  toggleShowTanks,
  toggleSubMenuSiteDetails,
  modifyPropertyOnSite,
  toggleEditSiteChannelStatus,
  setEditSiteChannelStatus,
  setEditSiteChannelParkedDescription,
  updateSiteChannels,
  toggleEditProfileSecurityLevel,
  persistControllerProfiles,
  toggleEditSiteDetails,
  openParameterHistory,
  closeParameterHistory
} from '../../actions';
import { IPoint } from '../../models';

export type ControllerParametersViewMode = 'read' | 'edit' | 'read_history';
export interface ISiteDetailsReducerState {
  commandPalletIsExpanded: boolean;
  currentlyEditedNote: ICurrentlyEditedNote;
  pendingCustomerContact?: IUser;
  controllerParametersViewMode: ControllerParametersViewMode;
  selectedControllerParameterId?: number; 
  savingParameters: ISavingParametersStatus;
  allHistoryHasBeenLoaded: number[]; // controllerIds
  currentlyLoadingHistory: number[]; // controllerIds
  currentlyEditingProfile: ICurrentlyEditingProfile;
  currentlyEditingProfileSecurityLevel: boolean;
  savingProfiles: ISavingParametersStatus;
  loadingProfileForEdit?: number;
  loadingProfileForEditError?: string;
  uploadingFromExcel?: boolean;
  uploadingFromExcelError?: string;
  smallTankGraphs: Array<IRequestStateWrapper<IPoint[], number>>;
  showTanks: boolean;
  submenuIsShown: boolean;
  pendingSiteDetails?: Partial<ISite>;
  statusEdit: boolean;
  statusEditParkedDescription: string | undefined;
  statusEditParked: boolean | undefined;
}

const createSavingParameters = (
  isSaving: boolean,
  error?: string
): ISavingParametersStatus => ({ isSaving, error });

const defaultStateForNote = {
  noteType: 0,
  text: ''
};

const defaultStateForEditingProfile: ICurrentlyEditingProfile = {
  selectedDay: 0
};

const defaultState: ISiteDetailsReducerState = {
  commandPalletIsExpanded: false,
  currentlyEditedNote: defaultStateForNote,
  controllerParametersViewMode: 'read',
  selectedControllerParameterId: undefined,
  savingParameters: createSavingParameters(false),
  allHistoryHasBeenLoaded: [],
  currentlyLoadingHistory: [],
  smallTankGraphs: [],
  showTanks: false,
  submenuIsShown: false,
  statusEdit: false,
  statusEditParkedDescription: undefined,
  statusEditParked: undefined,
  currentlyEditingProfileSecurityLevel: true,
  savingProfiles: createSavingParameters(false),
  currentlyEditingProfile: defaultStateForEditingProfile
};

const setDefaultStateForNote: Partial<ISiteDetailsReducerState> = {
  currentlyEditedNote: defaultStateForNote
};
const closeParameterModal: Partial<ISiteDetailsReducerState> = {
  controllerParametersViewMode: 'read'
};

const mergeCurrentlyEditingProfile = (
  state: ISiteDetailsReducerState,
  partOfCurrentlyEditingProfile: Partial<ICurrentlyEditingProfile>
) =>
  mergeObjects(state, {
    currentlyEditingProfile: mergeObjects(
      state.currentlyEditingProfile,
      partOfCurrentlyEditingProfile
    )
  });

const setStateForEditNote = (
  currentlyEditedNote: ICurrentlyEditedNote,
  state: ISiteDetailsReducerState
) => mergeObjects(state, { currentlyEditedNote });

const setDefaultStateForEditedNote: (
  state: ISiteDetailsReducerState
) => ISiteDetailsReducerState = setStateForEditNote.bind(
  null,
  defaultStateForNote
);

const mergeState = (
  ...toMergeWith: Array<Partial<ISiteDetailsReducerState>>
) => (state: ISiteDetailsReducerState) => mergeObjects(state, ...toMergeWith);

const reducer = reducerWithInitialState(defaultState)
  // Small graphs
  .case(fetchingTankHistory.done, (state, payload) =>
    payload.params.fromTankGraph
      ? state
      : mergeObjects(state, {
          smallTankGraphs: appendOrReplaceObjectInArray(
            state.smallTankGraphs,
            t => t.query === payload.params.tankId,
            {
              type: 'done',
              loaded: true,
              entity: payload.result,
              query: payload.params.tankId
            }
          )
        })
  )

  .case(toggleEditProfileSecurityLevel, state =>
    mergeObjects(state, {
      currentlyEditingProfileSecurityLevel: !state.currentlyEditingProfileSecurityLevel
    })
  )

  .case(toggleShowTanks, state =>
    mergeObjects(state, { showTanks: !state.showTanks })
  )

  .case(updateSiteChannels.done, state =>
    mergeObjects(state, {
      statusEdit: false,
      statusEditParked: undefined,
      statusEditParkedDescription: undefined
    })
  )
  .case(toggleEditSiteChannelStatus, state =>
    mergeObjects(state, {
      statusEdit: !state.statusEdit,
      statusEditParkedDescription: undefined,
      statusEditParked: undefined
    })
  )
  .case(setEditSiteChannelStatus, (state, statusEditParked) =>
    mergeObjects(state, { statusEditParked })
  )
  .case(
    setEditSiteChannelParkedDescription,
    (state, statusEditParkedDescription) =>
      mergeObjects(state, { statusEditParkedDescription })
  )
  // Notes

  .case(updateNoteAction.started, mergeState(setDefaultStateForNote))
  .case(cancelNoteEditAction, mergeState(setDefaultStateForNote))

  .case(noteValuesEdited, (state, payload) =>
    mergeObjects(state, {
      currentlyEditedNote: mergeObjects(state.currentlyEditedNote, payload)
    })
  )
  .case(postNewNoteAction.started, state =>
    mergeObjects(state, setDefaultStateForEditedNote(state))
  )
  .case(markNoteForEditAction, (state, { noteId, noteType, text }) =>
    setStateForEditNote({ noteId, noteType, text }, state)
  )

  .case(persistControllerProfiles.started, state =>
    mergeObjects(state, {
      savingProfiles: createSavingParameters(true)
    })
  )
  .case(persistControllerProfiles.failed, (state, { error }) =>
    mergeObjects(state, {
      savingProfiles: createSavingParameters(false, error)
    })
  )
  .case(persistControllerProfiles.done, state =>
    mergeObjects(state, {
      savingProfiles: createSavingParameters(false),
      currentlyEditingProfileSecurityLevel: false
    })
  )

  // Common
  .case(navigateToRoute, state => ({
    ...state,
    ...setDefaultStateForEditedNote,
    ...closeParameterModal,
    showTanks: false,
    submenuIsShown: false,
    statusEdit: false,
    statusEditParked: undefined,
    statusEditParkedDescription: undefined,
    currentlyEditingProfileSecurityLevel: false,
    pendingSiteDetails: undefined,
    currentlyEditedContact: undefined,
    currentlyEditingProfile: defaultStateForEditingProfile
  }))

  .case(toggleSubMenuSiteDetails, (state, value) =>
    mergeObjects(state, { submenuIsShown: value })
  )

  // Controller commands
  .case(toggleCommandPallet, state =>
    mergeObjects(state, {
      commandPalletIsExpanded: !state.commandPalletIsExpanded
    })
  )

  // Controller parameters
  .case(editParameters, state =>
    mergeObjects(state, { controllerParametersViewMode: 'edit' })
  )
  .case(cancelEditParameters, state =>
    mergeObjects(state, {
      controllerParametersViewMode: 'read',
      savingParameters: createSavingParameters(false, undefined)
    })
  )
  .case(persistControllerParameters.started, state =>
    mergeObjects(state, { savingParameters: createSavingParameters(true) })
  )
  .case(persistControllerParameters.failed, (state, payload) =>
    mergeObjects(state, {
      savingParameters: createSavingParameters(false, payload.error)
    })
  )
  .case(
    persistControllerParameters.done,
    mergeState({
      savingParameters: createSavingParameters(false),
      controllerParametersViewMode: 'read'
    })
  )
  .case(openParameterHistory, (state, { parameterId }) => (
    {
      ...state,
      ...{
        controllerParametersViewMode: 'read_history',
        selectedControllerParameterId: parameterId        
      }
    }))
  .case(closeParameterHistory, (state) => (
    {
      ...state,
      ...{
        controllerParametersViewMode: 'read',
        selectedControllerParameterId: undefined
      }
    }))

  // Controller logs
  .case(fetchingControllerLogsAction.done, (state, payload) =>
    mergeObjects(state, {
      allHistoryHasBeenLoaded: appendObjectToArray(
        state.allHistoryHasBeenLoaded,
        payload.params.controllerId
      ),
      currentlyLoadingHistory: state.currentlyLoadingHistory.filter(
        cid => cid !== payload.params.controllerId
      )
    })
  )
  .case(fetchingControllerLogsAction.started, (state, payload) =>
    mergeObjects(state, {
      currentlyLoadingHistory: appendObjectToArray(
        state.currentlyLoadingHistory,
        payload.controllerId
      )
    })
  )

  // Controller profiles
  .case(profileEditorChangeYScale, (state, { ymax, ymin }) => ({
    ...state,
    currentlyEditingProfile: {
      ...state.currentlyEditingProfile,
      maxScale: ymax,
      minScale: ymin
    }
  }))

  .case(toggleEditSiteDetails, state => ({
    ...state,
    pendingSiteDetails: isNone(state.pendingSiteDetails) ? {} : undefined
  }))
  .case(modifyPropertyOnSite, (state, payload) =>
    mergeObjects(state, {
      pendingSiteDetails: mergeObjects(state.pendingSiteDetails, {
        [payload.property]: payload.value
      })
    })
  )
  // Persist controller profiles from excel-sheet
  .case(persistingControllerProfile.started, (state, payload) =>
    payload.persistingThroughExcel
      ? mergeObjects(state, {
          uploadingFromExcel: true,
          uploadingFromExcelError: undefined
        })
      : mergeCurrentlyEditingProfile(state, {
          savingProfile: true,
          savingProfileError: undefined
        })
  )
  .case(persistingControllerProfile.failed, (state, payload) =>
    payload.params.persistingThroughExcel
      ? mergeObjects(state, {
          uploadingFromExcel: false,
          uploadingFromExcelError: payload.error
        })
      : mergeCurrentlyEditingProfile(state, {
          savingProfile: false,
          savingProfileError: payload.error
        })
  )
  .case(persistingControllerProfile.done, (state, payload) =>
    payload.params.persistingThroughExcel
      ? mergeObjects(state, { uploadingFromExcel: false })
      : mergeObjects(state, {
          currentlyEditingProfile: defaultStateForEditingProfile
        })
  )

  .case(profileEditorSetSelectedDay, (state, selectedDay) =>
    mergeCurrentlyEditingProfile(state, { selectedDay })
  )
  .case(profileEditorFormulaValueChanged, (state, formulaQuery) =>
    mergeCurrentlyEditingProfile(state, { formulaQuery })
  )

  .case(
    profileEditorFormulaOperationClicked,
    (state, { operation, values }) => {
      const { currentlyEditingProfile } = state;
      if (isNone(currentlyEditingProfile)) return state;
      const { selectedDay, formulaQuery } = currentlyEditingProfile;
      if (isNone(formulaQuery)) return state;
      const formulaQueryAsNumber = parseFloat(formulaQuery);
      if (isNaN(formulaQueryAsNumber)) return state;

      const indexForStartOfDay = selectedDay * 24;
      const indexForEndOfDay = (selectedDay + 1) * 24;

      const pendingValuesForCurrentDay = values
        .filter((_, i) => i >= indexForStartOfDay && i < indexForEndOfDay)
        .map(parseFloat);
      const sumValuesOnCurrentDay = pendingValuesForCurrentDay.reduce(
        (prev, curr) => prev + curr,
        0
      );

      const doOperationOnPendingValues = (
        pendingValuesForDayAsNumber: number[],
        query: number
      ) =>
        pendingValuesForDayAsNumber
          .map((value: number) => {
            switch (operation) {
              case '=':
                return query;
              case '+':
                return query + value;
              case '%':
                return query === 0 ? 0 : value / query;
              case '*':
                return value * query;
              case '-':
                return value - query;
              case 'scale':
                return value === 0 || query === 0
                  ? 0
                  : (value / sumValuesOnCurrentDay) * query;
            }
            return value;
          })
          .map(value => value.toString());

      const newPendingValues = values
        .slice(0, indexForStartOfDay)
        .concat(
          doOperationOnPendingValues(
            pendingValuesForCurrentDay,
            formulaQueryAsNumber
          )
        )
        .concat(values.slice(indexForEndOfDay));

      return mergeCurrentlyEditingProfile(state, {
        pendingValues: newPendingValues
      });
    }
  )
  .case(
    profileEditorValueChanged,
    (state, { index, value, existingValues }) => {
      const { currentlyEditingProfile } = state;

      const { selectedDay } = currentlyEditingProfile;
      const indexChanged = selectedDay * 24 + index;
      const newPendingValues = existingValues.map((pendingValue, i) =>
        i === indexChanged ? value : pendingValue
      );

      return mergeCurrentlyEditingProfile(state, {
        pendingValues: newPendingValues,
        hasInvalidValues: newPendingValues.some(pendingValue =>
          isInvalidNumber(pendingValue, true)
        ),
        savingProfileError: undefined
      });
    }
  )

  .case(profileEditorCopyWorkingSetToDays, (state, { days, values }) => {
    const { currentlyEditingProfile } = state;
    if (isNone(currentlyEditingProfile)) return state;
    const { selectedDay } = currentlyEditingProfile;

    const indexForStartOfWorkingSetDay = selectedDay * 24;
    const indexForEndOfWorkingSetDay = (selectedDay + 1) * 24;

    const workingSetArray = values.slice(
      indexForStartOfWorkingSetDay,
      indexForEndOfWorkingSetDay
    );

    let pendingValues = values;

    for (const day of days) {
      pendingValues = pendingValues
        .slice(0, day * 24)
        .concat(workingSetArray)
        .concat(pendingValues.slice((day + 1) * 24));
    }

    return mergeCurrentlyEditingProfile(state, {
      pendingValues
    });
  });

export default reducer;
