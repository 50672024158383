import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  getSiteChannelsForSite,
  getAllSiteChannels,
  getAllSiteChannelsOfTanks,
  deleteSiteChannel,
  updateSiteChannel,
  createSiteChannel,
  getAllChannelsForController,
  deleteChannel,
  updateChannel,
  fetchChannel,
  getSiteChannel,
  updateSiteChannels,
  createChannel,
  createSiteChannelAndChannel,
  fetchChannelHistoryForSiteChannel,
  fetchChannelHistoryForSiteChannels,
  getChannelByToken,
  getChannelsThatTrackTank
} from '../actions';
import { BaseService } from './baseService';
import {
  emptyObject,
  getEntityOrUndefined,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  toUtcByLocalTime,
  getAsyncEntitiesMemoized,
  getAsyncEntitiesByAsyncArrayMemoized
} from '../utility/index';
import { selectChannelsOrUndefined } from '../pages/common/index';
import { IChannel, IMultiSiteChannelEdit, ISiteChannel } from '../interfaces/index';

@autoinject
export class ChannelService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ChannelService.name), taskQueue);
  }

  getAllSiteChannels = async () =>
    this.httpRequestWithDispatch(
      requests.getAllSiteChannels(),
      getAllSiteChannels,
      emptyObject,
      'Failed fetching all channels',
      selectChannelsOrUndefined
    );

  getAllSiteChannelsOfTypeTank = async () =>
    this.httpRequestWithDispatch(
      requests.getAllChannelsOfTypeTank(),
      getAllSiteChannelsOfTanks,
      emptyObject,
      'Failed fetching all channels of type tank',
      state =>
        getEntityOrUndefined(
          getAsyncEntitiesByAsyncArray(
            state.channels.allTankIds,
            state.channels.byId
          )
        )
    );

  getSiteChannelsForSiteAsync = async (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.getSiteChannelsForSite(siteId),
      getSiteChannelsForSite,
      siteId,
      'Could not fetch channels for site',
      state =>
        getEntityOrUndefined(
          getAsyncEntitiesByAsyncArray(
            getAsyncEntity(state.channels.siteChannelsForSites, siteId),
            state.channels.byId
          )
        )
    );

  getChannelByChannelToken = (channelId: number, token: string) =>
    this.httpRequestWithDispatchFromState(
      requests.getChannelByChannelToken(token),
      getChannelByToken,
      channelId,
      'Could not fetch channel by token',
      state => getAsyncEntity(state.channels.controllerChannelsById, channelId)
    );

  getSiteChannelsForSite = (siteId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteChannelsForSite(siteId),
      getSiteChannelsForSite,
      siteId,
      'Could not fetch channels for site',
      state =>
        getAsyncEntitiesByAsyncArrayMemoized(
          getAsyncEntity(state.channels.siteChannelsForSites, siteId),
          state.channels.byId
        )
    );

  getChannelsThatTrackChannel = (
    channelId: number,
    siteChannelId: number | undefined
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.getChannelsThatTrackChannel(channelId, siteChannelId),
      getChannelsThatTrackTank,
      channelId,
      'Could not fetch channel information about channels that tracks a specific channel',
      state =>
        getAsyncEntity(state.channels.channelsThatTrackChannels, channelId)
    );

  getSiteChannelBySiteChannelId = (siteChannelId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteChannelBySiteChannelId(siteChannelId),
      getSiteChannel,
      siteChannelId,
      'Could not fetch sitechannel',
      state => getAsyncEntity(state.channels.byId, siteChannelId)
    );

  deleteSiteChannel = (
    siteChannelId: number,
    siteId: number,
    beforeSuccessCallback?: Function
  ) =>
    this.httpRequestWithDispatch(
      requests.deleteSiteChannel(siteChannelId),
      deleteSiteChannel,
      { siteChannelId, siteId },
      'Could not delete site channel.',
      undefined,
      beforeSuccessCallback
    );

  deleteChannel = (
    channelId: number,
    controllerId: number,
    dependentChannelId?: number,
    beforeSuccessCallback?: Function
  ) =>
    this.httpRequestWithDispatch(
      requests.deleteChannelById(channelId),
      deleteChannel,
      { channelId, controllerId, dependentChannelId },
      'Could not delete channel',
      undefined,
      beforeSuccessCallback
    );

  updateSiteChannel = (siteChannelId: number, siteChannel: IChannel) =>
    this.httpRequestWithDispatch(
      requests.updateSiteChannel(siteChannelId, siteChannel),
      updateSiteChannel,
      siteChannel,
      'Could not update site channel.'
    );

  updateSiteChannels = (siteId: number, multiEdit: IMultiSiteChannelEdit) =>
    this.httpRequestWithDispatch(
      requests.updateSiteChannels(multiEdit),
      updateSiteChannels,
      { siteChannelIds: multiEdit.siteChannelIds, siteId },
      'Could not update sitechannels.'
    );

  updateSiteChannelsOrder = (siteChannels: ISiteChannel[]) =>
    this.httpRequestWithDispatch(
      requests.updateSiteChannelsOrder(siteChannels.map(s => s.siteChannelId)),
      updateSiteChannels,
      { siteChannelIds: siteChannels.map(s => s.siteChannelId) },
      'Could not update sitechannels.'
    );

  updateChannel = (channel: IChannel) =>
    this.httpRequestWithDispatch(
      requests.updateChannel(channel),
      updateChannel,
      channel,
      'Could not update channel.'
    );

  createChannel = (channel: IChannel) =>
    this.httpRequestWithDispatch(
      requests.createChannel(channel),
      createChannel,
      channel,
      'Could not create channel'
    );

  createSiteChannel = (siteId: number, channelId: number) =>
    this.httpRequestWithDispatch(
      requests.createSiteChannel(siteId, channelId),
      createSiteChannel,
      { siteId, channelId },
      'Could not create site channel.'
    );

  createSiteChannelAndChannel = (channel: IChannel) =>
    this.httpRequestWithDispatch(
      requests.createSiteChannelAndChannel(channel),
      createSiteChannelAndChannel,
      channel,
      'Could not create channel and sitechannel'
    );

  getControllerChannelsByControllerId = (controllerId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getChannelsByControllerId(controllerId),
      getAllChannelsForController,
      controllerId,
      'Could not fetch channels for the controller',
      state =>
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(
            state.channels.controllerChannelsForControllers,
            controllerId
          ),
          state.channels.controllerChannelsById
        )
    );

  getControllerChannelByChannelId = (channelId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getChannelsByChannelId(channelId),
      fetchChannel,
      channelId,
      'Could not fetch channel',
      state => getAsyncEntity(state.channels.controllerChannelsById, channelId)
    );

  getChannelHistoryForSiteChannel = (siteChannelId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getChannelHistoryBySiteChannelId(siteChannelId),
      fetchChannelHistoryForSiteChannel,
      siteChannelId.toString(),
      'Could not fetch history for channel',
      state => getAsyncEntity(state.channelHistory.byId, siteChannelId)
    );

  getChannelHistoryForSiteChannels = (siteChannelIds: number[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getChannelHistoriesBySiteChannelIds(siteChannelIds),
      fetchChannelHistoryForSiteChannels,
      siteChannelIds.map(t => t.toString()),
      'Could not fetch history for channel',
      state =>
        getAsyncEntitiesMemoized(siteChannelIds, state.channelHistory.byId)
    );

  getChannelHistoryForSiteChannelForRange = (
    siteChannelId: number,
    from: Date,
    to: Date,
    timezoneToOffsetBy: string | undefined,
    useCache = true
  ) => {
    const shiftedFrom = toUtcByLocalTime(from, timezoneToOffsetBy);
    const shiftedTo = toUtcByLocalTime(to, timezoneToOffsetBy);
    return this.httpRequestWithDispatchFromState(
      requests.getChannelHistoryForSiteChannel(
        siteChannelId,
        shiftedFrom,
        shiftedTo
      ),
      fetchChannelHistoryForSiteChannel,
      `channel_${siteChannelId} + ${shiftedFrom} + ${shiftedTo}`,
      'Could not fetch history for channel',
      state =>
        !useCache
          ? undefined
          : getAsyncEntity(
              state.channelHistory.byId,
              `channel_${siteChannelId} + ${shiftedFrom} + ${shiftedTo}`
            )
    );
  };
}
