import { menuIsOpenForEntity } from '../../types';
import { mergeObjects, isNone } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  downloadingApplicationUpdate,
  toggleDrawer,
  toggleExpandMenuOnEntity,
  bodyClick
} from '../../actions';

export interface IApplicationReducerState {
  readonly downloadingApplicationUpdate: boolean;
  readonly toggleDrawer: boolean;
  readonly menuIsOpenForEntity?: menuIsOpenForEntity;
  showReports: boolean;
  showManagement: boolean;
  updatedServiceWorkerReady: boolean;
}

const applicationState: IApplicationReducerState = {
  downloadingApplicationUpdate: false,
  toggleDrawer: false,
  showReports: false,
  showManagement: false,
  updatedServiceWorkerReady: false
};

const closeMenu: Partial<IApplicationReducerState> = {
  menuIsOpenForEntity: undefined
};

const reducer = reducerWithInitialState(applicationState)

  .case(downloadingApplicationUpdate, state =>
    mergeObjects(state, { downloadingApplicationUpdate: true })
  )
  .case(toggleDrawer, state =>
    mergeObjects(state, { toggleDrawer: !state.toggleDrawer })
  )

  .case(toggleExpandMenuOnEntity, (state, entity) =>
    mergeObjects(state, {
      menuIsOpenForEntity:
        state.menuIsOpenForEntity === entity ? undefined : entity
    })
  )
  .case(bodyClick, state =>
    isNone(state.menuIsOpenForEntity) ? state : mergeObjects(state, closeMenu)
  );

export default reducer;
