import { bindable, computedFrom } from 'aurelia-framework';
import { ISiteSignal } from '../../interfaces/index';
import './site-signal.css';
import { lsdIsOffline, lsdIsStale } from '../../utility';
import { SiteSignal } from '../../models';

export class SiteSignalCustomElement {
  @bindable site: ISiteSignal | undefined;

  @bindable lastSampleTime: string | undefined;
  @bindable controllerType: string | undefined;

  @computedFrom(
    'site.isVirtual',
    'site.signal',
    'lastSampleTime',
    'controllerType'
  )
  get siteSignal() {
    if (this.site) {
      if (this.site.isVirtual) return 'virtual';
      if (this.siteStringToEnum(this.site.signal) == SiteSignal.Offline) return 'badsignal';
      if (this.siteStringToEnum(this.site.signal) == SiteSignal.Stale) return 'poorsignal';

      return 'goodsignal';
    }
    if (this.controllerType === 'virtual') {
      return 'virtual';
    }
    if (this.lastSampleTime) {
      if (lsdIsOffline(this.lastSampleTime)) return 'badsignal';
      if (lsdIsStale(this.lastSampleTime)) return 'poorsignal';
      return 'goodsignal';
    }
    return 'badsignal';
  }

  siteStringToEnum(value: string | SiteSignal | undefined){
    if (typeof value === 'string'){
      switch (value) {
        case "Online":
          return SiteSignal.Online;
        case "Stale":
          return SiteSignal.Stale;
        case "Offline":
          return SiteSignal.Offline;
        case "Virtual":
          return SiteSignal.Virtual;
        default:
          return undefined;
      }
    }
    return value
  }
}

