import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import { getLogger } from 'aurelia-logging';
import {
  getExportedChannelsForSite,
  getExportJobsAsync,
  getExportedChannelsForSiteChannel,
  getExportJobNamesAsync,
  getExportJobAsync
} from '../actions';
import { BaseService } from './baseService';
import {
  emptyObject,
  getAllEntities,
  getAsyncEntity,
  getEntityOrUndefined,
  getAsyncEntitiesByAsyncArray
} from '../utility';

@autoinject()
export class ExportJobService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ExportJobService.name), taskQueue);
  }

  getExportJobs = () =>
    this.httpRequestWithDispatchFromState(
      requests.exportJobs(),
      getExportJobsAsync,
      emptyObject,
      'Could not fetch all export jobs',
      state => getAllEntities(state.exportJobs)
    );

  getExportJobNames = () =>
    this.httpRequestWithDispatchFromState(
      requests.exportJobNames(),
      getExportJobNamesAsync,
      emptyObject,
      'Could not fetch export job names',
      state => state.siteExports.exportJobNames
    );

  getExportJob = (jobId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.exportJobs(),
      getExportJobAsync,
      jobId,
      'Could not fetch export job',
      state => getAsyncEntity(state.exportJobs.byId, jobId)
    );

  getExportedChannelsForSite = (siteId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteExportedChannels(siteId),
      getExportedChannelsForSite,
      siteId,
      'Get exported channels for site failed',
      state =>
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(state.siteExports.bySiteId, siteId),
          state.siteExports.byId
        )
    );

  getExportedChannelsForSiteAsync = async (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.getSiteExportedChannels(siteId),
      getExportedChannelsForSite,
      siteId,
      'Get exported channels for site failed',
      state =>
        getEntityOrUndefined(
          getAsyncEntitiesByAsyncArray(
            getAsyncEntity(state.siteExports.bySiteId, siteId),
            state.siteExports.byId
          )
        )
    );

  getExportedChannelsForSiteChannel = (siteChannelId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteChannelExportedChannels(siteChannelId),
      getExportedChannelsForSiteChannel,
      siteChannelId,
      'Get all filters failed',
      state =>
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(state.siteExports.bySiteChannelId, siteChannelId),
          state.siteExports.byId
        )
    );
}
