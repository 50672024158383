import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { isNone } from '../../../utility';
import { NotificationService } from '../../../services/notificationService';

@autoinject()
export class FormGroup {
  @bindable() submit: undefined | (() => Promise<any> | void);
  @bindable() showServerErrors = false;
  @bindable() showToastNotification = true;
  @bindable() cancel: undefined | (() => void);
  @bindable() formIsValid: boolean | undefined = undefined;
  @bindable() class: string = '';
  submitting = false;
  showValidationErrors = false;
  _accValidationErrors = new Set<Element>();
  _formIsValid = true;
  element: Element;

  // formValidationChangedEscapeHatch is added so that form validation changes can be observed even when the card-form-group is placed inside a modal dialog,
  // and hence taken out of the normal placement in the DOM. In this case the modal dialogs parent (page or component) can't catch validation custom events coming from the card-form-group.
  @bindable() formValidationChangedEscapeHatch:
    | undefined
    | ((params: { formIsValid: boolean }) => void);

  constructor(private notification: NotificationService) {}

  bind() {
    this.showValidationErrors = false;
  }

  attached() {
    this.addFormValidationlisteners(this.element);
  }

  formIsValidChanged(newValue: boolean | undefined) {
    if (!isNone(newValue)) this._formIsValid = !!newValue;
  }

  addFormValidationlisteners = (thisel: Element) => {
    thisel.addEventListener('validationsuccess', (event: CustomEvent) => {
      const { element } = event.detail;
      this._accValidationErrors.delete(element);
      this.setValidationStatus();
    });
    
    addEventListener('inputremoved', (event: CustomEvent) => {
      const { element } = event.detail;
      this._accValidationErrors.delete(element);
      this.setValidationStatus();
    });

    thisel.addEventListener('validationerrors', (event: CustomEvent) => {
      const { element } = event.detail;
      this._accValidationErrors.add(element);
      this.setValidationStatus();
    });
  };

  setValidationStatus() {
    if (isNone(this.formIsValid)) {
      this._formIsValid = !this._accValidationErrors.size;
    }
    if (!isNone(this.formValidationChangedEscapeHatch)) {
      this.formValidationChangedEscapeHatch({
        formIsValid: !this._accValidationErrors.size
      });
    }
  }

  _submitForm() {
    if (!this.submit) return;

    const maybePromise = this.submit();
    if (!maybePromise || isNone(maybePromise.then)) return;
    this.submitting = true;
    maybePromise
      .then(() => {
        this.submitting = false;
      })
      .catch(() => {
        this.submitting = false;
      });
    return maybePromise;
  }

  submitForm() {
    this.showValidationErrors = true;

    if (!this._formIsValid) return;

    let promise = this._submitForm();
    if (promise === undefined) return undefined;
    if (this.showToastNotification) {
      promise.catch((reason: any) => {
        this.notification.notify({
          type: 'CUSTOM-MULTILINE',
          level: 'error',
          textlines: this.getServerErrorNotificationText(reason),
          timestamp: new Date().toString(),
          acknowledged: false
        });
      });
    }

    return promise;
  }

  protected calculatePrimaryButtonDisabled() {
    return this.showValidationErrors && !this._formIsValid;
  }

  protected getServerErrorNotificationText(promiseReason: any): string[] {
    const defaultText = 'UI_Common_Error_Save_Item_Failed';
    if (!this.showServerErrors || isNone(promiseReason)) {
      return [defaultText];
    }
    if (typeof promiseReason === 'string') {
      return [promiseReason];
    }
    const keys = Object.keys(promiseReason);
    if (keys.length < 1) {
      return [defaultText];
    }

    let textlines = [];
    for (const key of keys) {
      if (Array.isArray(promiseReason[key])) {
        for (const error of promiseReason[key]) {
          textlines.push(error);
        }
      } else if (typeof promiseReason[key] === 'string') {
        textlines.push(promiseReason[key]);
      } else {
        textlines.push(JSON.stringify(promiseReason[key]));
      }
    }
    return textlines;
  }

  @computedFrom('_formIsValid', 'showValidationErrors')
  get primaryButtonDisabled() {
    return this.calculatePrimaryButtonDisabled();
  }
}
