import { customElement, bindable } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import {
  HauliersQuery,
  HauliersQuery_hauliers
} from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';

@customElement('haulier-dropdown')
export class HaulierDropdown extends GraphQLBaseViewModel<
  void,
  HauliersQuery,
  void
> {
  @bindable() selected: Array<HauliersQuery_hauliers>;
  @bindable() itemClicked:
    | undefined
    | ((params: { value: HauliersQuery_hauliers; text: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() valuePath: string | undefined;
  @bindable() placeholder: string;

  query = gql`
    query HauliersQuery {
      hauliers {
        haulierId
        name
        haulierTag
      }
    }
  `;

  getDisplayValue(haulier: HauliersQuery_hauliers) {    
    return `${haulier.name} (${haulier.haulierTag})`;
  }
}
