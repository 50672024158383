import { fetchingLoadingPoints } from '../../actions';
import { ILoadingPoint, IAsyncReducerState } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects, insertAllFetchedEntities } from '../../utility/index';
import { createPendingEntity } from '../../types';

export interface ILoadingPointsReducerState
  extends IAsyncReducerState<ILoadingPoint> {}

const defaultState: ILoadingPointsReducerState = {
  byId: {},
  allIds: undefined
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingLoadingPoints.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )
  .case(fetchingLoadingPoints.done, (state, payload) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, payload.result, lp => lp.loadingPointId)
    )
  );

export default reducer;
