import { BaseViewModel } from './BaseViewModel';
import { observable } from 'aurelia-framework';
import { isNone } from '../../utility';
import { Logger } from 'aurelia-logging';
import { rootState } from '../../reducers';
import { subscribeQuery, unsubscribeFn, revalidateAllActiveQueries, IFetchOptions, runSingleQuery, revalidateFn } from './GraphQLFetcher';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export abstract class GraphQLBaseViewModel<
  T,
  UData,
  UVariables
> extends BaseViewModel<T> {
  constructor(
    protected logger: Logger,
    mapState?: (state: rootState) => T,
    shouldMapState?: (state: rootState) => boolean
  ) {
    super(logger, mapState, shouldMapState);
  }

  /**
   * The latest data
   */
  data: UData | undefined;

  /**
   * The latest error
   */
  error: any | undefined;

  /**
   * If the query is currently in-flight.
   */
  // TODO: Maybe deprecate this....
  loading: boolean;

  isRevalidating: boolean;

  dataChanged: ((data: UData | undefined, variables: UVariables | undefined) => void) | undefined;

  @observable({ changeHandler: 'subscribe' })
  query: DocumentNode | string;
  @observable({ changeHandler: 'subscribe' })
  variables: UVariables | undefined;

  @observable({ changeHandler: 'subscribe' })
  fetchOptions: IFetchOptions | undefined;

  private queryRequireVariables () {
    const documentNode = typeof this.query === 'string' ? gql(this.query) : this.query;
    return documentNode.definitions.some(
      (def: any) => def.variableDefinitions && !!def.variableDefinitions.length
    );
  }

  private queryUnsubscription: unsubscribeFn | undefined;
  removeObservableQuery () {
    if (this.queryUnsubscription) {
      this.queryUnsubscription();
      this.queryUnsubscription = undefined;
    }
  }

  runQuery<T, TV>(query: DocumentNode, variables: TV) {
    const { promise } = runSingleQuery<T, TV>(query, variables);
    return promise;
  }

  revalidateAllActiveQueries = revalidateAllActiveQueries
  revalidateQuery: revalidateFn | undefined;

  subscribe() {
    if (isNone(this.query)) return;
    if (this.queryRequireVariables() && isNone(this.variables)) return;
    
    this.queryUnsubscription && this.queryUnsubscription();
    const { query, variables } = this;

    const { unsubscribe, revalidate } = subscribeQuery<UData, UVariables>(this.query, this.variables, this.fetchOptions, (result) => {
      if(query !== this.query || variables !== this.variables) return;
      if(this.data !== result.data) {
        this.data = result.data;
        this.dataChanged && this.dataChanged(result.data, variables);
      }
      this.error = result.error;
      this.isRevalidating = result.isRevalidating;
      this.loading = !result.data && result.isRevalidating; // TODO: Maybe deprecate this...
    })
    this.revalidateQuery = revalidate;
    
    this.queryUnsubscription = unsubscribe;
  }

  detached() {
    super.detached();
    this.removeObservableQuery();
  }
}
