import { customElement, bindable } from 'aurelia-templating';
import './banner.css';

@customElement('banner')
export class Banner {
  @bindable() type: 'info' | 'error' | 'success' | 'warning' = 'info';
  @bindable() icon: string | undefined;
  @bindable() svg: string | undefined;
  @bindable() close: Function | undefined;
}
