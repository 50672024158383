import {
  IControllerCommandResult,
  IControllerParameter,
  IControllerLog,
  IController,
  ISite,
  IChannel,
  ITankDetails,
  ChannelTypes,
  IRemoteUrl,
  IProduct,
  IControllerProfile,
  IRetentionTimeDetails,
  IAggregatedTankUsageDetails,
  IDoseChannelDetails,
  IAggregatedDoseChannelDetails,
  IUnit,
  IStockVsDoseAccuracyChannelDetails
} from '../interfaces';
import actionCreatorFactory from 'typescript-fsa';
import { ISimDetail } from '../interfaces/entity/iSimDetail';
import { IUserTokenAndAccessRemovedAndAdded } from '../pages/customermanager/customermanagerReducer';
import { IExportJobNameAndId } from '$interfaces/entity/iexportjobNameAndId';

const actionCreator = actionCreatorFactory('controllercommands');

export const sendControllerCommandAction = actionCreator.async<
  {
    controllerId: number;
    command: string;
    internalId: number;
    slaveNumber: number | undefined;
  },
  IControllerCommandResult
>('CONTROLLER_COMMAND');

export const cleanupCommands = actionCreator<{ controllerId: number }>(
  'clean_up_commands_for_controller'
);

export const selectedControllerChanged = actionCreator<{
  controllerId: number;
  slaveNumber: number;
}>('SelectedControllerChanged');

export const updateController = actionCreator.async<
  IController,
  { controller: IController; deletedController: number | undefined },
  string
>('persist_controller');
export const modifyPropertyOnController = actionCreator<{
  property: keyof IController;
  value: IController[keyof IController];
}>('modify_property_on_controller');
export const modifyPropertyOnSimCard = actionCreator<{
  property: keyof ISimDetail;
  value: ISimDetail[keyof ISimDetail];
}>('modify_property_on_controller_sim');
export const cancelEditController = actionCreator('cancelEditController');

// Async actions
export const persistControllerParameters = actionCreator.async<
  IControllerParameter[],
  boolean
>('persist_controller_parameters');
export const persistControllerProfiles = actionCreator.async<
  { controllerId: number },
  IControllerProfile[]
>('persist_controller_profiles');

export const getControllerParameters = actionCreator.async<
  number,
  IControllerParameter[]
>('getControllerParameters');

export const getControllerTypes = actionCreator.async<{}, string[], string>(
  'get_controller_types'
);

export const fetchingControllerLogsAction = actionCreator.async<
  { controllerId: number; fetchAll: boolean },
  IControllerLog[],
  string
>('fetching_controller_logs');
export const fetchingControllersForSite = actionCreator.async<
  number,
  IController[],
  string
>('fetching_controllers_for_site');
export const fetchingControllersForSites = actionCreator.async<
  number[],
  IController[],
  string
>('fetching_controllers_for_sites');
export const fetchingAllControllers = actionCreator.async<
  number,
  IController[],
  string
>('fetching_all_controllers');
export const fetchingController = actionCreator.async<
  number,
  IController,
  string
>('fetching_controller');
export const fetchingControllers = actionCreator.async<
  number[],
  IController[],
  string
>('fetching_controllers');

export const fetchingAllRemoteControllers = actionCreator.async<
  number,
  IController[],
  string
>('fetchingAllRemoteControllers');

/* Controller manager*/
export const toggleEditControllerSerial = actionCreator('editControllerSerial');
export const replacedControllerSerial = actionCreator<{ controllerId: number }>(
  'replacedControllerSerial'
);

export const editChannelProductProperty = actionCreator<IProduct | undefined>(
  'editChannelProductProperty'
);
export const editChannel = actionCreator<{
  key: keyof IChannel;
  value: string | number | undefined;
}>('edit_channel');
export const setPendingExportJobs = actionCreator<{
  exportJobs: IExportJobNameAndId[]  
}>('edit_channel_set_pending_export_jobs');
export const editChannelType = actionCreator<{
  channelType: ChannelTypes;
  channel: IChannel;
  units: IUnit[];
}>('edit_channel_type');
export const editTankChannel = actionCreator<{
  key: keyof ITankDetails;
  value: string | number | undefined;
}>('edit_channel_tankdetails');
export const validateChannel = actionCreator<IChannel>('validate_channel');
export const editAggregatedTankUsage = actionCreator<{
  key: keyof IAggregatedTankUsageDetails;
  value: IAggregatedTankUsageDetails[keyof IAggregatedTankUsageDetails];
}>('editAggregatedTankUsageProperty');
export const editStockVsDoseAccuracyChannelProperty = actionCreator<{
  key: keyof IStockVsDoseAccuracyChannelDetails;
  value: IStockVsDoseAccuracyChannelDetails[keyof IStockVsDoseAccuracyChannelDetails];
}>('editStockVsDoseAccuracyChannelProperty');
export const editDoseChannelProperty = actionCreator<{
  key: keyof IDoseChannelDetails;
  value: IDoseChannelDetails[keyof IDoseChannelDetails];
}>('editDoseChannelProperty');
export const editAggregatedDoseChannelProperty = actionCreator<{
  key: keyof IAggregatedDoseChannelDetails;
  value: IAggregatedDoseChannelDetails[keyof IAggregatedDoseChannelDetails];
}>('editAggregatedDoseChannelProperty');
export const editRetentionTimeDetails = actionCreator<{
  key: keyof IRetentionTimeDetails;
  value: number;
}>('editRetentionTimeDetails');
export const editRetentionTimeParameterIdDetails = actionCreator<
  IControllerParameter
>('editRetentionTimeParameterIdDetails');
export const selectedBuidForCreateSite = actionCreator<number | undefined>(
  'selectedBuidForCreateSite'
);
export const selectedCustomerForCreateSite = actionCreator<number | undefined>(
  'selectedCustomerForCreateSite'
);
export const fetchingUnassignedControllers = actionCreator.async<
  number,
  IController[],
  string
>('fetching_unassigned_controllers');
export const deleteControllers = actionCreator.async<number[], void>(
  'unassigned_controllers_delete'
);
export const fetchRemoteControllUrlForController = actionCreator.async<
  string,
  IRemoteUrl,
  string
>('fetchRemoteControllUrlForController');

export const unassigedControllersSiteCreate = actionCreator.async<
  { controllerIds: number[] },
  { sites: ISite[]; controllers: IController[] }
>('create_site_from_unassigned_controller');
export const unassigedControllersSiteAttach = actionCreator.async<
  { controllerIds: number[]; siteId: number },
  IController[],
  string
>('attach_to_site_from_unassigned_controller');
export const detachControllers = actionCreator.async<number[], void, string>(
  'detach_controllers'
);
export const changeSerialOnController = actionCreator.async<
  { controllerId: number; newSerial: string },
  IController,
  string
>('change_serial_on_controller');
export const getUserAccessToController = actionCreator.async<
  string,
  IUserTokenAndAccessRemovedAndAdded[],
  string
>('getUserAccessToController');
