import {
  bindable,
  containerless,
  autoinject,
  computedFrom
} from 'aurelia-framework';
import './card-form-group.css';
import { FormGroup } from '../../forms/form-group/form-group';
import { NotificationService } from '../../../services/notificationService';

@autoinject()
@containerless()
export class CardFormGroup extends FormGroup {
  // Card specific
  @bindable() showFooter: boolean = true;
  @bindable() cancelText = 'UI_Common_Cancel';
  @bindable() submitText = 'UI_Common_SaveChanges';

  // Both form-group and card
  @bindable() submit: undefined | (() => Promise<any> | void);
  @bindable() cancel: undefined | (() => void);
  @bindable() formIsValid: boolean | undefined = undefined;
  @bindable() class: string = '';
  @bindable() showServerErrors = false;
  @bindable() showToastNotification = true;

  // formValidationChangedEscapeHatch is added so that form validation changes can be observed even when the card-form-group is placed inside a modal dialog,
  // and hence taken out of the normal placement in the DOM. In this case the modal dialogs parent (page or component) can't catch validation custom events coming from the card-form-group.
  @bindable() formValidationChangedEscapeHatch:
    | undefined
    | ((params: { formIsValid: boolean }) => void);

  constructor(notification: NotificationService) {
    super(notification);
  }

  @computedFrom('_formIsValid', 'showValidationErrors')
  get primaryButtonDisabled(): boolean {
    return this.calculatePrimaryButtonDisabled();
  }
}
