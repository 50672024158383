import {
  customElement,
  bindable,
  computedFrom,
  autoinject
} from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import {
  VehicleQuery,
  VehicleQuery_vehicles
} from '../../../../custom_typings/graphql';
import gql from 'graphql-tag';
import { orderByPredicate } from '../../../utility';

@autoinject()
@customElement('vehicle-dropdown')
export class VehicleDropdown extends GraphQLBaseViewModel<
  void,
  VehicleQuery,
  void
> {
  @bindable() selected: VehicleQuery_vehicles | string | undefined;
  @bindable() valuePath: string = 'vehicleId';
  @bindable() itemClicked:
    | undefined
    | ((params: { value: VehicleQuery_vehicles; text: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  query = gql`
    query VehicleQuery {
      vehicles {
        vehicleId
        codeAndName
      }
    }
  `;

  @computedFrom('data')
  get sortedVehicles() {
    if (!this.data) return this.data;
    return orderByPredicate(this.data.vehicles, u => u.codeAndName, 'asc');
  }
}
