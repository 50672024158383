import { importPolyfills } from './polyfills';
import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import {
  configureLanguage,
  configureLogging,
  configureBrowserCache,
  setSentryUserContext
} from './config';
import { FetchConfig } from 'aurelia-authentication';
import {
  getDeviceSize,
  configureLocalStorageAvailability
} from './utility';

import { store } from './reducers/store';
import { deviceSizeChanged } from './actions/device';

import {
  resetSession,
  setSession,
  getSessionClientEncryptionKey,
  getSessionUserId
} from './config/sessionService';
import { loadSavedUserState } from './actions';
import { isLegacyStyleUrl, convertOldQrURI } from './utility/uriHelpers';
import { HttpClient } from 'aurelia-fetch-client';
import { LocalSettings } from './services/localSettingsService';
import './components/avatar-image/avatar-image';
import appInsights from './config/events';
import { AuthenticationService } from '$services/authenticationService';

/*
import {getCLS, getFCP, getFID, getLCP} from 'web-vitals';
getCLS(console.log);
getFID(console.log);
getLCP(console.log); // Largest contentful paint. Below 2.5 sec
getFCP(console.log); // First contentful paint
*/

// check if localstorage is available
configureLocalStorageAvailability();

if (isLegacyStyleUrl(PLATFORM.location.toString())) {
  window.location.href = PLATFORM.location.origin;
}

const qrURI = convertOldQrURI(
  PLATFORM.location.toString().replace(PLATFORM.location.origin, '')
);

if (qrURI.length > 0) {
  window.location.href = qrURI;
}

export async function configure(aurelia: Aurelia) {
  await importPolyfills();
  configureLogging(aurelia);

  aurelia.use.standardConfiguration().router();

  //configureAuth(aurelia);

  const authService = aurelia.container.get(AuthenticationService) as AuthenticationService;
  let localSettingsInitialized = Promise.resolve();
  if (await authService.isAuthenticated()) {
    appInsights?.setAuthenticatedUserContext(getSessionUserId().toString());
    const claims = await authService.getUserClaims();
    setSession(claims);
    const encryptionKey = getSessionClientEncryptionKey();
    if (encryptionKey)
      localSettingsInitialized = LocalSettings.initializeLocalSettings(
        getSessionUserId(),
        encryptionKey
      );
  }

  configureLanguage(aurelia);

  /* Global internal components */
  aurelia.use
    .globalResources(
      PLATFORM.moduleName(
        'components/radio-buttons/radio-button-item/radio-button-item'
      )
    )
    .globalResources(PLATFORM.moduleName('components/pages/page/page'))
    .globalResources(
      PLATFORM.moduleName('components/animated-number/animated-number')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/empty-component/empty-component/empty-component'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/empty-component/empty-component-header/empty-component-header'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/empty-component/empty-component-description/empty-component-description'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/charts/linechart/linechart')
    )
    .globalResources(PLATFORM.moduleName('components/colorbox/colorbox'))
    .globalResources(
      PLATFORM.moduleName('components/color-picker/color-picker')
    )
    .globalResources(
      PLATFORM.moduleName('components/charts/tanktrendchart/tanktrendchart')
    )
    .globalResources(PLATFORM.moduleName('components/datetime/datetime'))
    .globalResources(PLATFORM.moduleName('components/headers/header1/header1'))
    .globalResources(PLATFORM.moduleName('components/headers/header2/header2'))
    .globalResources(PLATFORM.moduleName('components/headers/header3/header3'))
    .globalResources(
      PLATFORM.moduleName(
        'components/banners/banner-table-header/banner-table-header'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/banners/banner-warning/banner-warning')
    )
    .globalResources(
      PLATFORM.moduleName('components/banners/banner-error/banner-error')
    )
    .globalResources(
      PLATFORM.moduleName('components/banners/banner-info/banner-info')
    )
    .globalResources(
      PLATFORM.moduleName('components/banners/banner-parked/banner-parked')
    )
    .globalResources(
      PLATFORM.moduleName('components/banners/banner-success/banner-success')
    )
    .globalResources(PLATFORM.moduleName('components/pill/pill'))
    .globalResources(PLATFORM.moduleName('components/tooltip/tooltip'))
    .globalResources(PLATFORM.moduleName('components/topbar-sub/topbar-sub'))
    .globalResources(PLATFORM.moduleName('components/help/help'))
    .globalResources(
      PLATFORM.moduleName('components/modals/modal-card/modal-card')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/modals/modal-card-form-group/modal-card-form-group'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/icons/icon-clickable/icon-clickable')
    )
    .globalResources(PLATFORM.moduleName('components/icons/icon/icon'))
    .globalResources(
      PLATFORM.moduleName('components/icons/sort-icon/sort-icon')
    )
    .globalResources(
      PLATFORM.moduleName('components/icons/icon-async/icon-async')
    )
    .globalResources(PLATFORM.moduleName('components/cards/card/card'))
    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-with-statusicon/card-with-statusicon'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/cards/card-content/card-content')
    )
    .globalResources(
      PLATFORM.moduleName('components/cards/card-header/card-header')
    )
    .globalResources(
      PLATFORM.moduleName('components/cards/card-tab-bar/card-tab-bar')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-tab-bar/card-tab-bar-item/card-tab-bar-item'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/cards/card-footer/card-footer')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-header-with-menu/card-header-with-menu'
      )
    )

    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-sticker-error/card-sticker-error'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-sticker-success/card-sticker-success'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-sticker-parked/card-sticker-parked'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/cards/card-sticker/card-sticker'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/bars/site-channel-bar/site-channel-bar')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/refill-attachment-modal/refill-attachment-modal'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/bars/site-status-bar/site-status-bar')
    )
    .globalResources(PLATFORM.moduleName('components/site-signal/site-signal'))
    .globalResources(
      PLATFORM.moduleName('components/buttons/fab-button/fab-button')
    )
    .globalResources(
      PLATFORM.moduleName('components/buttons/fab-button-item/fab-button-item')
    )
    .globalResources(
      PLATFORM.moduleName('components/buttons/danger-button/danger-button')
    )
    .globalResources(
      PLATFORM.moduleName('components/buttons/regular-button/regular-button')
    )
    .globalResources(
      PLATFORM.moduleName('components/buttons/primary-button/primary-button')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/buttons/secondary-button/secondary-button'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/buttons/tertiary-button/tertiary-button')
    )
    .globalResources(
      PLATFORM.moduleName('components/buttons/filter-reset-button/filter-reset-button')
    )
    .globalResources(PLATFORM.moduleName('components/textbox/textbox'))
    .globalResources(
      PLATFORM.moduleName('components/inputs/number-input/number-input')
    )
    .globalResources(
      PLATFORM.moduleName('components/inputs/decimal-input/decimal-input')
    )
    .globalResources(
      PLATFORM.moduleName('components/inputs/ipaddress-input/ipaddress-input')
    )
    .globalResources(
      PLATFORM.moduleName('components/inputs/msisdn-input/msisdn-input')
    )
    .globalResources(
      PLATFORM.moduleName('components/inputs/icc-input/icc-input')
    )
    .globalResources(
      PLATFORM.moduleName('components/inputs/email-input/email-input')
    )
    .globalResources(
      PLATFORM.moduleName('components/inputs/username-input/username-input')
    )
    .globalResources(PLATFORM.moduleName('components/checkbox/checkbox'))
    .globalResources(PLATFORM.moduleName('components/topbar/topbar'))
    .globalResources(PLATFORM.moduleName('components/placeholder/placeholder'))
    .globalResources(
      PLATFORM.moduleName(
        'components/placeholders/placeholder-text/placeholder-text'
      )
    )
    .globalResources(PLATFORM.moduleName('components/tab-bar/tab-bar'))
    .globalResources(PLATFORM.moduleName('components/tab-bar/tab-bar-item'))
    .globalResources(PLATFORM.moduleName('components/tool-bar/tool-bar'))
    .globalResources(PLATFORM.moduleName('components/tool-bar/tool-bar-item'))
    .globalResources(PLATFORM.moduleName('components/translation/translation'))
    .globalResources(
      PLATFORM.moduleName('components/filters/filter-widget/filter-widget')
    )
    .globalResources(
      PLATFORM.moduleName('components/filters/text-filter/text-filter')
    )
    .globalResources(PLATFORM.moduleName('components/drawer/drawer'))
    .globalResources(PLATFORM.moduleName('components/slider/slider'))
    .globalResources(PLATFORM.moduleName('components/sitecard/sitecard'))
    .globalResources(
      PLATFORM.moduleName('components/column-selector/column-selector')
    )
    .globalResources(PLATFORM.moduleName('components/thumbtrend/thumbtrend'))
    .globalResources(
      PLATFORM.moduleName('components/contacts/contact-row/contact-row')
    )
    .globalResources(
      PLATFORM.moduleName('components/contacts/contact-reuse-modal/contactreusemodal')
    )    
    .globalResources(
      PLATFORM.moduleName('components/filters/number-filter/number-filter')
    )
    .globalResources(
      PLATFORM.moduleName('components/filters/date-filter/date-filter')
    )
    .globalResources(
      PLATFORM.moduleName('components/texts/error-text/error-text')
    )
    .globalResources(
      PLATFORM.moduleName('components/texts/warning-text/warning-text')
    )
    .globalResources(
      PLATFORM.moduleName('components/actions/action-button/action-button')
    )
    .globalResources(
      PLATFORM.moduleName('components/actions/action-icon/action-icon')
    )
    .globalResources(
      PLATFORM.moduleName('components/actions/action-text/action-text')
    )
    .globalResources(
      PLATFORM.moduleName('components/actions/action-content/action-content')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/actions/action-content-item/action-content-item'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/customer-dropdown/customer-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/dropdowns/buid-dropdown/buid-dropdown')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/product-dropdown/product-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/haulier-dropdown/haulier-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/dropdowns/unit-dropdown/unit-dropdown')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/country-dropdown/country-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/dropdowns/month-dropdown/month-dropdown')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/countrycode-dropdown/countrycode-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/vehicle-dropdown/vehicle-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/language-dropdown/language-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/staalduinen-dropdown/staalduinen-pickup-point-dropdown'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/dropdowns/dropdown/dropdown')
    )
    .globalResources(
      PLATFORM.moduleName('components/dropdowns/dropdown-list/dropdown-list')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/dropdown-content-item/dropdown-content-item'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/dropdowns/sap-shipto-dropdown/sap-shipto-dropdown'
      )
    )
    .globalResources(PLATFORM.moduleName('components/output-box/output-box'))
    .globalResources(
      PLATFORM.moduleName(
        'components/tables/simple-table/simple-table/simple-table'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/tables/sortable-cell/sortable-cell')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/tables/simple-table/simple-table-row/simple-table-row'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/tables/simple-table/simple-table-cell/simple-table-cell'
      )
    )
    .globalResources(PLATFORM.moduleName('components/grid/grid'))
    .globalResources(
      PLATFORM.moduleName('components/button-group/button-group')
    )

    .globalResources(
      PLATFORM.moduleName('components/textbox-multiline/textbox-multiline')
    )
    .globalResources(PLATFORM.moduleName('components/date-picker/date-picker'))
    .globalResources(
      PLATFORM.moduleName('components/loading-bars/loading-bars')
    )
    //test
    .globalResources(PLATFORM.moduleName('components/map/map'))
    .globalResources(PLATFORM.moduleName('components/notes/note/note'))
    .globalResources(
      PLATFORM.moduleName('components/expandable-panel/expandable-panel')
    )
    .globalResources(
      PLATFORM.moduleName('components/virtual-list/virtual-list')
    )
    .globalResources(
      PLATFORM.moduleName('components/history-list/history-list/history-list')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/history-list/history-list-item/history-list-item'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/history-list/history-list-separator/history-list-separator'
      )
    )

    .globalResources(
      PLATFORM.moduleName('components/mobile-modal-topbar/mobile-modal-topbar')
    )

    .globalResources(PLATFORM.moduleName('components/switch/switch'))
    .globalResources(
      PLATFORM.moduleName(
        'components/require-confirmation/require-confirmation'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/information-dialog/information-dialog')
    )
    .globalResources(
      PLATFORM.moduleName('components/install-to-device-prompt/install-to-device-prompt')
    )
    .globalResources(
      PLATFORM.moduleName('components/time/time-timezone/time-timezone')
    )

    // Flex grid
    .globalResources(
      PLATFORM.moduleName('components/flex-grid/flex-grid/flex-grid')
    )
    .globalResources(
      PLATFORM.moduleName('components/flex-grid/flex-grid-cell/flex-grid-cell')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/flex-grid/flex-grid-content/flex-grid-content'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/flex-grid/flex-grid-header-row/flex-grid-header-row'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/flex-grid/flex-grid-row/flex-grid-row')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/flex-grid/flex-grid-actionbar/flex-grid-actionbar'
      )
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/flex-grid/flex-grid-header-cell/flex-grid-header-cell'
      )
    )

    // Forms
    .globalResources(
      PLATFORM.moduleName('components/forms/form-group/form-group')
    )
    .globalResources(
      PLATFORM.moduleName('components/cards/card-form-group/card-form-group')
    )
    .globalResources(
      PLATFORM.moduleName('components/forms/form-element/form-element')
    )
    .globalResources(
      PLATFORM.moduleName(
        'components/filepicker/filepicker-overlay/filepicker-overlay'
      )
    )
    .globalResources(
      PLATFORM.moduleName('components/system-message-display/system-message-display-wrapper')
    )

    // Notification
    .globalResources(PLATFORM.moduleName('components/toaster/toaster'))
    .globalResources(PLATFORM.moduleName('components/toast/toast'));

  /* Global internal attributes */
  aurelia.use
    .globalResources(PLATFORM.moduleName('attributes/enterPress'))
    .globalResources(PLATFORM.moduleName('attributes/mouseOver'))
    .globalResources(PLATFORM.moduleName('attributes/whenVisible'))
    .globalResources(PLATFORM.moduleName('attributes/clickOutside'))
    .globalResources(PLATFORM.moduleName('attributes/whenResized'))
    .globalResources(PLATFORM.moduleName('attributes/keepScroll'))
    .globalResources(PLATFORM.moduleName('attributes/tooltip'))
    .globalResources(PLATFORM.moduleName('attributes/attached'));

  /* Global value converters */
  aurelia.use
    .globalResources(
      PLATFORM.moduleName('value-converters/orderByValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/highlightValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/clampValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/absValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/isArrayValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/dateFormatValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/pureDateFormatValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/pureTimeFormatValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/pureFormatDayAsNumberValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/rangeValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/unitConvertValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/timeFormatValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/durationValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/leftpadNumberValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/shortMonthValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/monthValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/isSomethingValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/numberValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/dayFormatAsNumberValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/invalidNumberValueConverter')
    )
    .globalResources(PLATFORM.moduleName('value-converters/jsonValueConverter'))
    .globalResources(
      PLATFORM.moduleName('value-converters/bytesValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/timeBetweenValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/decimalValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/excludeWithPropertyValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/columnAccessValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/isEmptyValueConverter')
    )
    .globalResources(PLATFORM.moduleName('value-converters/keysValueConverter'))
    .globalResources(
      PLATFORM.moduleName('value-converters/inArrayValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/navFilterValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/entriesValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/measurementFormatValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/displaySecurityLevelValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/displayChannelTypeValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/timeUntillValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName(
        'value-converters/filterOutSecurityLevelsValueConverter'
      )
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/arrayHasValuesValueConverter')
    )
    .globalResources(
      PLATFORM.moduleName('value-converters/contactIsEmptyValueConverter')
    );

  /* Global bindingBehavior */
  aurelia.use.globalResources(PLATFORM.moduleName('bindings/stopPropagation'));

  /* Svg icons */
  aurelia.use
    .globalResources(PLATFORM.moduleName('components/icons/svg/pin-icon'))
    .globalResources(
      PLATFORM.moduleName('components/icons/svg/alignment-none-icon')
    )
    .globalResources(
      PLATFORM.moduleName('components/icons/svg/alignment-bottom-icon')
    )
    .globalResources(
      PLATFORM.moduleName('components/icons/svg/alignment-right-icon')
    )

  /* External plugins */
  // Uncomment the line below to enable animation.
  // if the css animator is enabled, add swap-order="after" to all router-view elements
  aurelia.use
    .plugin(PLATFORM.moduleName('bcx-aurelia-reorderable-repeat'))
    .plugin(PLATFORM.moduleName('aurelia-portal-attribute'))
    .plugin(PLATFORM.moduleName('aurelia-deep-computed'));

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin('aurelia-html-import-template-loader')

  await aurelia.start();

  const isAuthenticated = await authService.isAuthenticated();
  // This should be set in the store creator as defaultstate.
  if (isAuthenticated) {

    setSentryUserContext();

    store.dispatch(loadSavedUserState());

    store.dispatch(deviceSizeChanged(getDeviceSize()));
    const fetchConfig = aurelia.container.get(FetchConfig) as FetchConfig;
    const httpClient = aurelia.container.get(HttpClient) as HttpClient;

   

    httpClient.configure(config => {
      config.withInterceptor({
        response: response => {
          if (response.status === 401) authService.login();
          return response;
        }
      })
      .withDefaults(
        {
          credentials: 'same-origin',
          headers: {
            'X-CSRF': "1",
          }
        })


     
    });
    
    fetchConfig.configure(httpClient);
  } else resetSession();

  await localSettingsInitialized;

  function IsQrAppMainPage(href: string) {
    const url = new URL(href);
    return url.pathname.indexOf('/qr/') >= 0 && url.pathname.indexOf('/activate/') < 0;
  }

  if (IsQrAppMainPage(window.location.href)) {
      aurelia.setRoot(PLATFORM.moduleName('pages/qr/qr', 'qr'));
  } else if (isAuthenticated) {
      aurelia.setRoot(PLATFORM.moduleName('pages/app/app', 'app'));
  } else {     
      authService.login();
  }

  configureBrowserCache(aurelia);
}
