import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import {
  trendChannelAdded,
  trendChannelsRemoved,
  trendChannelsUpdated
} from '../actions/index';
import { BaseService } from './baseService';
import { ITrendChannel, ITrendGroup } from '../interfaces/index';

@autoinject
export class UserTrendChannelService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(UserTrendChannelService.name), taskQueue);
  }

  add = async (trendGroup: ITrendGroup, trendChannel: Partial<ITrendChannel>) =>
    this.httpRequestWithDispatch(
      requests.addUserTrendChannel(trendGroup.userTrendGroupId, trendChannel),
      trendChannelAdded,
      { trendGroup, trendChannel },
      'Add user trend channel failed'
    );

  update = async (trendChannels: ITrendChannel[]) =>
    this.httpRequestWithDispatch(
      requests.updateUserTrendChannels(trendChannels),
      trendChannelsUpdated,
      trendChannels,
      'Update user trend channels failed'
    );

  remove = async (trendChannels: ITrendChannel[]) =>
    this.httpRequestWithDispatch(
      requests.removeUserTrendChannel(trendChannels),
      trendChannelsRemoved,
      trendChannels,
      'Remove user trend channels failed.'
    );

  removeDefault = async (trendChannels: ITrendChannel[]) =>
    this.httpRequestWithDispatch(
      requests.deleteUserTrendChannel(trendChannels),
      trendChannelsRemoved,
      trendChannels,
      'Remove user trend channels failed.'
    );
}
