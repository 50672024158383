import {
  customElement,
  bindable,
  PLATFORM,
  containerless
} from 'aurelia-framework';

@containerless()
@customElement('msisdn-input')
export default class MsisdnInput {
  @bindable value: number | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable error: undefined | string | string[] | boolean;
  inputmask = ['P*']; //https://github.com/beholdr/maska
}
