import { bindable, customElement } from 'aurelia-templating';
import { computedFrom } from 'aurelia-binding';
import { sortDirection } from '../../../types';
import './sort-icon.css';

@customElement('sort-icon')
export class SortIcon {
  @bindable() sortProperty: string;
  @bindable() sortDirection: sortDirection;

  @bindable() property: string;

  @computedFrom('sortProperty', 'sortDirection', 'property')
  get iconName() {
    if (this.sortProperty !== this.property) return 'fa-sort-amount-asc';
    return this.sortDirection === 'asc'
      ? 'fa-sort-amount-asc'
      : 'fa-sort-amount-desc';
  }
}
