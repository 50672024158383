import React, {FC} from "react";
import { filterXSS } from 'xss';
import { ISystemMessageToDisplay } from './system-message-display-datatypes';

export interface ISystemMessageDisplayProps {
  systemMessage: ISystemMessageToDisplay
}

const systemMessageXssWhitelist =  {
  a: ["href", "title", "target", "rel"],
  p: [],
  u: [],
  i: [],
  br: [],
  h1: [],
  h2: [],
  h3: [],
  ul: [],
  ol: [],
  li: ["class"],
  em: [],
  strong: []
};

const SystemMessageDisplay : FC<ISystemMessageDisplayProps> = ({
    systemMessage
  }) => {

  const sanitizedMessageHTML = {
    __html: filterXSS(
      systemMessage.message, 
      {
        whiteList: systemMessageXssWhitelist
      })
  };
    
  return (
    <div>
      {systemMessage.title && <h3>{systemMessage.title}</h3>}
      <div dangerouslySetInnerHTML={sanitizedMessageHTML}></div>
    </div>);
};

export default SystemMessageDisplay;
