import { insertAllFetchedEntities, insertFetchedEntities } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  fetchingImportJobs,
  getImportTypes,
  savedImportJob,
  importJobSortingChanged,
  importJobFilterChanged
} from '../../actions/importjobs';
import { ImportJob } from '../../models/importJob';
import { ImportType } from '../../models/importType';
import { IAsyncReducerState } from '../../interfaces';
import {
  createFetchedEntity,
  createPendingEntity,
  IAsyncEntity,
  sortDirection
} from '../../types';

export interface IImportJobState extends IAsyncReducerState<ImportJob> {
  importTypes: IAsyncEntity<ImportType[]>;
  sortDirection: sortDirection;
  sortProperty: string;
  filter: string;
}

const defaultState: IImportJobState = {
  allIds: undefined,
  byId: {},
  importTypes: undefined,
  sortDirection: 'asc',
  sortProperty: 'name',
  filter: '',
};

const reducer = reducerWithInitialState(defaultState)
  .case(importJobSortingChanged, (state, { sortDirection, sortProperty }) => ({
    ...state,
    sortDirection,
    sortProperty
  }))
  .case(importJobFilterChanged, (state, { filter }) => ({
    ...state,
    filter,
  }))
  .case(fetchingImportJobs.started, state => ({
    ...state,
    ...{ allIds: createPendingEntity() }
  }))
  .case(fetchingImportJobs.done, (state, { result }) => ({
    ...state,
    ...insertAllFetchedEntities(state, result, job => job.importJobId)
  }))
  .case(getImportTypes.started, state => ({
    ...state,
    ...{ importTypes: createPendingEntity() }
  }))
  .case(getImportTypes.done, (state, { result }) => ({
    ...state,
    ...{ importTypes: createFetchedEntity(result) }
  }))
  .case(savedImportJob.done, (state, payload) => ({
    ...state,
    ...insertFetchedEntities(state, [payload.result], job => job.importJobId)
  }));

export default reducer;
