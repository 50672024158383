import { bindable, customElement, computedFrom } from 'aurelia-framework';
import {
  channelPropertiesIsInErrorState,
  lastSampleIsOutsideThreshold,
  lsdIsOffline,
  hasNoConsumption,
  isNone
} from '../../../utility';
import './site-channel-bar.css';

@customElement('site-channel-bar')
export class SiteChannelBar {
  @bindable onClick: () => void;

  @bindable minimum: number | undefined; // tank.minimum
  @bindable maximum: number | undefined; // tank.maximum
  @bindable lastSample: number | undefined; // tank.lastSample
  @bindable percentage: number | undefined; // tank.percentage
  @bindable lastSampleTime: string | undefined | Date; // tank.lastSampleTime
  @bindable reachMinimum: string | undefined | Date; // tank.tankDetails.reachMinimum
  @bindable capacity: number | undefined; // tank.capacity
  @bindable direction: string = 'horizontal';

  @computedFrom('percentage', 'minimum', 'maximum', 'lastSample', 'capacity')
  get faulty() {
    return (
      isNone(this.percentage) ||
      this.percentage < 0 ||
      this.percentage > 100 ||
      channelPropertiesIsInErrorState(
        this.minimum,
        this.maximum,
        this.lastSample,
        this.capacity
      )
    );
  }

  @computedFrom('minimum', 'lastSample', 'lastSampleTime', 'reachMinimum')
  get wrapperClassName() {
    const outsideThreshold = lastSampleIsOutsideThreshold(
      this.minimum,
      this.lastSample
    );
    const isOffline = lsdIsOffline(this.lastSampleTime);
    const noConsumption = hasNoConsumption(this.reachMinimum);

    if (outsideThreshold)
      return isOffline || noConsumption ? 'noconsumption-lowlevel' : 'lowlevel';

    return isOffline || noConsumption ? 'noconsumption' : 'normal';
  }

  @computedFrom('faulty', 'reachMinimum', 'lastSampleTime')
  get fillClassName() {
    if (this.faulty) return 'faulty';
    const noConsumption = hasNoConsumption(this.reachMinimum);
    const isOffline = lsdIsOffline(this.lastSampleTime);
    if (noConsumption || isOffline) return 'noconsumption';

    return 'freshdata';
  }

  @computedFrom('faulty', 'percentage')
  get fillSize() {
    return this.faulty || isNone(this.percentage)
      ? 100
      : Math.max(Math.min(this.percentage, 100), 0);
  }

  @computedFrom('percentage')
  get fillText() {
    return !isNone(this.percentage) ? `${this.percentage} %` : '';
  }
}
