import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { getEnvironment } from '../utility';

const ApplicationInsightsInstrumentationKey = () => {
    const environment = getEnvironment();
    switch (environment) {
      case 'development':
        return 'c4ccde76-65d1-40b1-99d9-f9fd643cdf65'; //yt3-web-dev
      case 'production':
        return '003dc0a6-04ac-47f0-bb59-10ce0569897f'; //yt3-web
      case 'test':
        return '65b80668-35d6-45aa-95e9-e28e8276b884'; //yt3-web-test
    }
    return undefined;
};

const key = ApplicationInsightsInstrumentationKey()

const appInsights = key ? new ApplicationInsights({ config: {
    instrumentationKey: key,
} }) : null;

if(appInsights) {
    appInsights.loadAppInsights();
}
//appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

export default appInsights;