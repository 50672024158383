import {
  persistMapMarkerChanges,
  fetchingSites,
  fetchingAllSites,
  fetchingSite,
  unassigedControllersSiteCreate,
  persistSite,
  deleteSite,
  persistMultipleSites,
  deleteMultipleSites,
  fetchSiteAccessForSiteChannelsOnSite
} from '../../actions';
import { ISite, IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  insertAllFetchedEntities,
  insertFetchedEntity,
  mergeDoneEntityInState,
  markEntityAsSavingInState,
  insertInflightEntity,
  insertInflightEntities,
  removeAsyncObject,
  removeAsyncObjects
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  createPendingEntity,
  IAsyncDictionary,
  createFetchedEntity,
  createFailedFetchingEntity
} from '../../types';
import { ISiteAccess } from '../../interfaces/application/iSiteAccess';

export interface ISiteReducerState extends IAsyncReducerState<ISite> {
  userAccessToSiteChannelSites: IAsyncDictionary<ISiteAccess[]>; // user's access to a site's site_channels' controller's site
}

const defaultState: ISiteReducerState = {
  allIds: undefined,
  byId: {},
  userAccessToSiteChannelSites: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingSite.started, (state, siteId) =>
    mergeObjects(state, insertInflightEntity(state, siteId))
  )
  .case(fetchingSite.done, (state, { result }) =>
    mergeObjects(state, insertFetchedEntity(state, result.siteId, result))
  )
  .case(fetchingSites.started, (state, params) => ({
    ...state,
    ...insertInflightEntities(state, params)
  }))
  .case(fetchingSites.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, s => s.siteId)
  }))
  .case(fetchingAllSites.done, (state, { result }) =>
    mergeObjects(state, insertAllFetchedEntities(state, result, r => r.siteId))
  )
  .case(fetchingAllSites.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )

  .case(persistSite.done, (state, { params }) => ({
    ...state,
    ...insertFetchedEntity(state, params.siteId, params)
  }))
  .case(persistSite.started, (state, site) => ({
    ...state,
    ...markEntityAsSavingInState(state, site.siteId)
  }))
  .case(persistSite.failed, (state, {}) => state)

  .case(persistMultipleSites.done, (state, payload) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, payload.result.sites, site => site.siteId)
    )
  )

  .case(persistMultipleSites.failed, (state, {}) => state)

  .case(persistMapMarkerChanges.done, (state, { params }) =>
    mergeObjects(
      state,
      mergeDoneEntityInState(state, params.siteId, _ => ({
        addressLine1: params.address.addressLine1,
        addressLine2: params.address.addressLine2,
        city: params.address.city,
        country: params.address.country,
        postcode: params.address.postcode
      }))
    )
  )

  .case(unassigedControllersSiteCreate.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result.sites, s => s.siteId)
    )
  )

  .case(deleteSite.done, (state, payload) =>
    mergeObjects(state, removeAsyncObject(state, payload.params.siteId))
  )

  .case(deleteMultipleSites.done, (state, payload) =>
    mergeObjects(state, removeAsyncObjects(state, payload.params.siteIds))
  )

  .case(fetchSiteAccessForSiteChannelsOnSite.started, (state, payload) => ({
    ...state,
    userAccessToSiteChannelSites: {
      ...state.userAccessToSiteChannelSites,
      [payload.siteId]: createPendingEntity()
    }
  }))

  .case(fetchSiteAccessForSiteChannelsOnSite.done, (state, payload) => ({
    ...state,
    userAccessToSiteChannelSites: {
      ...state.userAccessToSiteChannelSites,
      [payload.params.siteId]: createFetchedEntity(payload.result)
    }
  }))

  .case(fetchSiteAccessForSiteChannelsOnSite.failed, (state, payload) => ({
    ...state,
    userAccessToSiteChannelSites: {
      ...state.userAccessToSiteChannelSites,
      [payload.params.siteId]: createFailedFetchingEntity(payload.error)
    }
  }));

export default reducer;
