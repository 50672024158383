import {
  IDataProcessRequest,
  IDataProcessChannelResponse
} from '../models/trend';
import actionCreatorFactory from 'typescript-fsa';
import {
  ISiteChannel,
  ITrendChannel,
  ITrendGroup,
  ILocalizedDateRange,
  IAlarmLogExtended
} from '../interfaces/index';
import { DeepPartial } from '../types/deepPartial';
import { SecurityLevel } from "../interfaces/enums/securityLevel";

const actionCreator = actionCreatorFactory('dataanalysis');

export const trendChannelAdded = actionCreator.async<
  { trendGroup: ITrendGroup; trendChannel: Partial<ITrendChannel> },
  ITrendChannel
>('trendChannelAdded');
export const trendChannelsRemoved = actionCreator.async<ITrendChannel[], void>(
  'trendChannelsRemoved'
);
export const trendChannelsUpdated = actionCreator.async<
  ITrendChannel[],
  ITrendChannel[]
>('trendChannelsUpdated');

export const trendChannelDataLoaded = actionCreator.async<
  {
    internalId: number;
    request: IDataProcessRequest;
    trendGroupId: number;
    localizedDateRange: ILocalizedDateRange;
  },
  IDataProcessChannelResponse[]
>('trendChannelDataLoaded');
export const userAlarmDataLoaded = actionCreator.async<
  undefined,
  IAlarmLogExtended[],
  string
>('userAlarmDataLoaded');

export const toggleTrendEditDialog = actionCreator('toggleTrendEditDialog');
export const toggleTrendNewGroupDialog = actionCreator(
  'toggleTrendNewGroupDialog'
);
export const editTrendChannel = actionCreator<{
  trendChannel: DeepPartial<ITrendChannel>;
  trendChannelIds: number[] | undefined;
}>('editTrendChannel');

export const showGapsChanged = actionCreator<boolean>('showGapsChanged');
export const showRawChanged = actionCreator<boolean>('showRawChanged');
export const selectedChannelChanged = actionCreator<ISiteChannel>(
  'selectedChannelChanged'
);
export const securityLevelChanged = actionCreator<SecurityLevel | undefined>(
  'securityLevelChanged'
);
export const selectedOperatorChanged = actionCreator<number>(
  'selectedOperatorChanged'
);
export const selectedVisibilityChanged = actionCreator<boolean>(
  'selectedVisibilityChanged'
);
export const selectedAxisTypeChanged = actionCreator<number>(
  'selectedAxisTypeChanged'
);
export const selectedAxisLocationChanged = actionCreator<number>(
  'selectedAxisLocationChanged'
);
export const selectedAxisAutoScaleChanged = actionCreator<boolean>(
  'selectedAxisAutoScaleChanged'
);
export const axisPropertyChanged = actionCreator<{
  property: string;
  value: string;
}>('axisPropertyChanged');
export const trendGroupPropertyChanged = actionCreator<{
  property: string;
  value: string;
}>('trendGroupPropertyChanged');

export const setTrendChannelColor = actionCreator<string>(
  'setTrendChannelColor'
);

export const trendGroupChanged = actionCreator<ITrendGroup>(
  'trendGroupChanged'
);

export const getTrendGroups = actionCreator.async<number, ITrendGroup[]>(
  'getTrendGroups'
);
export const trendGroupAdded = actionCreator.async<ITrendGroup, ITrendGroup>(
  'trendGroupAdded'
);
export const trendGroupRemoved = actionCreator.async<
  { siteId: number; trendGroupId: number },
  void
>('trendGroupRemoved');

export const exportPropertyChanged = actionCreator<{
  property: string;
  value: string | number | Date;
}>('exportPropertyChanged');
export const exportDatePropertyChanged = actionCreator<{
  property: string;
  value: Date;
}>('exportDatePropertyChanged');
export const exportToggleSiteChannel = actionCreator<{
  siteChannelId: number;
  checked: boolean;
}>('exportToggleSiteChannel');
export const toggleExport = actionCreator<ITrendChannel[]>('toggleExport');
export const toggleSelectedTrendChannels = actionCreator<{
  add: boolean;
  ids: number[];
}>('toggleSelectedTrendChannels');
export const getTrendExport = actionCreator.async<{ siteId: number }, void>(
  'getTrendExport'
);

export const exportToggleAllSiteChannels = actionCreator<{
  ids: number[];
  checked: boolean;
}>('exportToggleAllSiteChannels');

export const showAlarmsChanged = actionCreator<{ value: boolean }>(
  'showAlarmsChanged'
);
