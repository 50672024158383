import {
  ISiteColumn,
  IRequestStateWrapper,
  IRequestState,
  IAsyncReducerState,
  IServerSiteColumn
} from '../../interfaces';
import {
  mergeObjects,
  requestLoadingWrapper,
  requestFailedWrapper,
  requestLoadedWrapper,
  defaultRequest,
  insertAllFetchedEntities,
  removeAsyncObjects,
  appendOrReplaceObjectInArray,
  insertFetchedEntity,
  mergeEntity,
  getEntityOrUndefined,
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IRoleTemplate } from '../../interfaces/entity/iRoleTemplate';
import actionCreatorFactory from 'typescript-fsa';
import {
  sendInvite,
  navigateToRoute,
  updateAlarm,
  deleteAlarm,
  addAlarmRecipient,
  removeAlarmRecipient,
  updateAlarmRecipient
} from '../../actions';
import {
  createPendingEntity,
  createFetchedEntity,
  requestError,
  IAsyncDictionary,
  IAsyncEntity
} from '../../types';
import { SecurityLevel } from '../../interfaces/enums/securityLevel';
import { IUserManagerListUser } from './modules/usermanagerlist/usermanagerlist';
import { SiteDetailsTabEnum } from '$typings/graphql-codegen';

const actionCreator = actionCreatorFactory('usermanager');

export const userManagerInvitationChanged= actionCreator<{userId: number, language: string}>('userManagerInvitationChanged');

export const userManagerUserModified = actionCreator<IUserManagerListUser>('userManagerUserModified');


export const getAllUserManagerUsers = actionCreator.async<
  {},
  IUserManagerUser[]
>('getAllUserManagerUsers');
export const getUserManagerUser = actionCreator.async<number, IUserManagerUser>(
  'getUserManagerUser'
);
export const getUserManagerAccessForUser = actionCreator.async<
  number,
  IUserAccess,
  requestError
>('getUserManagerAccessForUser');
export const getUserManagerAccessDescriptionForUser = actionCreator.async<
  string,
  IUserAccessStats,
  requestError
>('getUserManagerAccessDescriptionForUser');
export const getUserManagerSiteAccessForUser = actionCreator.async<
  string,
  IUserEntityAccess[],
  requestError
>('getUserManagerSiteAccessForUser');
export const getUserManagerAlarmConnectionForUser = actionCreator.async<
  string,
  IUserAlarmConnection[],
  requestError
>('getUserManagerAlarmConnectionForUser');
export const getUserManagerCustomerAccessForUser = actionCreator.async<
  string,
  IUserEntityAccess[],
  requestError
>('getUserManagerCustomerAccessForUser');
export const persistUserManagerAccessForUser = actionCreator.async<
  number,
  IUserAccess,
  requestError
>('persistUserManagerAccessForUser');
export const getAllBuidAndCustomerFilters = actionCreator.async<
  {},
  { buidFilter: string[]; customerFilter: string[]; haulierFilter: string[] }
>('getAllBuidAndCustomerFilters');
export const userManagerAddBuid = actionCreator<string>(
  'USERMANAGER_BUID_PARAMETER_ADD'
);
export const userManagerAddCustomer = actionCreator<string>(
  'USERMANAGER_CUSTOMER_PARAMETER_ADD'
);
export const userManagerTriedToAddUnauthorizedCustomer = actionCreator(
  'userManagerTriedToAddUnauthorizedCustomer'
);
export const userManagerTriedToAddUnauthorizedBuid = actionCreator(
  'userManagerTriedToAddUnauthorizedBuid'
);
export const removeUsers = actionCreator.async<number[], void>(
  'USERMANAGER_REMOVE_USERS'
);
export const userManagerRemoveBuid = actionCreator<string>(
  'USERMANAGER_BUID_PARAMETER_REMOVE'
);
export const userManagerRemoveCustomer = actionCreator<string>(
  'USERMANAGER_CUSTOMER_PARAMETER_REMOVE'
);
export const userManagerUserInfoChanged = actionCreator<{
  property: keyof IUserManagerListUser;
  value: IUserManagerListUser[keyof IUserManagerListUser];
}>('userManagerUserInfoChanged');
export const userManagerUserInfoPersisting = actionCreator.async<
  IUserManagerListUser,
  IUserManagerUser,
  requestError
>('userManagerUserInfoPersisting');
export const userManagerUserInfoCreate = actionCreator.async<
  IUserManagerListUser,
  IUserManagerUser,
  requestError
>('userManagerUserInfoCreate');

export const setDefaultCustomer = actionCreator<{ customerId: number }>(
  'setDefaultCustomer'
);

export const cancelEditUserManager = actionCreator(
  'USERMANAGER_CANCEL_EDIT_USER'
);

export const filterBuidDropdown = actionCreator<string>(
  'USERMANAGER_FILTER_BUID_LIST'
);
export const filterCustomerDropdown = actionCreator<string>(
  'USERMANAGER_FILTER_CUSTOMERS_LIST'
);
export const filterDefaultCustomerDropdown = actionCreator<string>(
  'filterDefaultCustomerDropdown'
);

export const getUserColumns = actionCreator.async<
  { userId: number },
  ISiteColumn[]
>('getUserColumns');
export const addOrRemoveUserColumn = actionCreator<{
  column: ISiteColumn;
  checked: boolean;
}>('addOrRemoveUserColumn');
export const addOrRemoveMultipleUserColumns = actionCreator<{
  columns: ISiteColumn[];
  checked: boolean;
}>('addOrRemoveMultipleUserColumns');

export const getRoleTemplates = actionCreator.async<{}, IRoleTemplate[]>(
  'getRoleTemplates'
);
export const selectRoleTemplate = actionCreator<IRoleTemplate | undefined>(
  'selectRoleTemplate'
);
export const unSelectRoleTemplate = actionCreator(
  'unSelectRoleTemplate'
);
export const changeUserFeature = actionCreator<IPendingUserFeatures>(
  'changeUserFeature'
);
export const changeUserFeatures = actionCreator<IPendingUserFeatures[]>(
  'changeUserFeatures'
);
export const changeUserColumn = actionCreator<IServerSiteColumn>(
  'changeUserColumn'
);
export const changeUserColumns = actionCreator<IServerSiteColumn[]>(
  'changeUserColumns'
);
export const changeUserAccess = actionCreator<{
  key: keyof IUserAccessDetails;
  value: IUserAccessDetails[keyof IUserAccessDetails];
}>('changeUserAccess');

export const changePendingUserAccessDetails = actionCreator<Partial<IUserAccessDetails>>("changePendingUserAccessDetails")

export interface IUserManagerUser {
  userId: number;
  userToken: string;
  buidId: number | undefined;
  customerId: number | undefined;
  customerToken: string;
  description: string;
  neverExpires: boolean | undefined;
  lastLogin: string | undefined;
  loginName: string;
  invitationSentDate: string | Date | undefined;
  invitationLanguage : string | undefined;
  securityLevel: SecurityLevel | undefined;
  customerTags: string[];
  buidTags: string[];
  haulierTags: string[];
  internalUser: boolean | undefined;
  serviceNowTicket: string | undefined;
  lastAppliedRoleTemplate?: IAppliedRoleTemplateInfo;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
  inactivityExpirationDays: number | undefined
}

export interface IUserAccessFeature {
  translationKey: string;
  featureId: number;
  readEnabled: boolean;
  canRead: boolean;
  writeEnabled: boolean;
  canWrite: boolean;
  deleteEnabled: boolean;
  canDelete: boolean;
}

export interface IUserAccessFeatureGroup {
  groupKey: string;
  features: IUserAccessFeature[];
}

export interface IUserAccessDetails {
  buidTags: string[];
  customerTags: string[];
  haulierTags: string[];
  hasAccessToAllBuids: boolean;
  hasAccessToAllCustomers: boolean;
  isSuperUser: boolean | undefined;
  internalUser: boolean | undefined;
  securityLevel: SecurityLevel;
}

export interface IUserAccessStats {
  alarms: number;
  sites: number;
  customers: number;
  allCustomersAndSites: boolean;
}

export interface IUserEntityAccess {
  id: number;
  name: string;
  hasAccess: boolean;
  removed: boolean;
  added: boolean;
}

export interface IUserAlarmConnection {
  alarmId: number;
  alarmName: string;
  siteName: string;
  siteId: number;
  hasAccess: boolean;
  removed: boolean;
  added: boolean;
}

export interface IUserAccess {
  userId: number;
  userFeatures: IUserAccessFeatureGroup[];
  userColumns: IServerSiteColumn[];
  details: IUserAccessDetails;
  lastAppliedRoleTemplate?: IAppliedRoleTemplateInfo
}

export interface IAppliedRoleTemplateInfo {
  name: string,
  time?: string
}

export interface IPendingUserFeatures {
  featureId: number;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
}

export interface IUserAccessDescriptionRequest {
  userId: number;
  hasAccessToAllBuids?: boolean;
  hasAccessToAllCustomers?: boolean;
  isSuperUser?: boolean;
  buidAccessFilters?: string[];
  customerAccessFilters?: string[];
  haulierAccessFilters?: string[];
  email?: string;
}

export interface IUserManagerReducerState
  extends IAsyncReducerState<IUserManagerUser> {
  addUserRequest: IRequestState;
  invitationRequest?: IRequestStateWrapper<boolean, number>;
  roleTemplates: IAsyncEntity<IRoleTemplate[]>;
  selectedRoleTemplate?: IRoleTemplate;
  currentUserBuidAndCustomerFilter: IAsyncEntity<{
    buidFilter: string[];
    customerFilter: string[];
    haulierFilter: string[];
  }>;
  pendingUsermanager: Partial<IUserManagerListUser>;
  pendingUsermanagerValidationErrors: Record<string, string[]>;
  userAccess: IAsyncDictionary<IUserAccess>;
  pendingUserFeatures: IPendingUserFeatures[];
  pendingUserColumns: IServerSiteColumn[];
  pendingUserAccessDetails: Partial<IUserAccessDetails>;
  userAccessDescription: IAsyncDictionary<IUserAccessStats>;
  userSiteAccess: IAsyncDictionary<IUserEntityAccess[]>;
  userCustomerAccess: IAsyncDictionary<IUserEntityAccess[]>;
  userHaulierAccess: IAsyncDictionary<IUserEntityAccess[]>;
  userAlarmConnection: IAsyncDictionary<IUserAlarmConnection[]>;
}

const defaultState: IUserManagerReducerState = {
  allIds: undefined,
  byId: {},
  addUserRequest: defaultRequest,
  roleTemplates: undefined,
  currentUserBuidAndCustomerFilter: undefined,
  pendingUsermanager: {},
  pendingUsermanagerValidationErrors: {},
  userAccess: {},
  pendingUserFeatures: [],
  pendingUserColumns: [],
  pendingUserAccessDetails: {},
  userAccessDescription: {},
  userSiteAccess: {},
  userCustomerAccess: {},
  userHaulierAccess: {},
  userAlarmConnection: {}
};

const userManagerReducer = reducerWithInitialState(defaultState)
  .cases(
    [userManagerUserInfoPersisting.failed, userManagerUserInfoCreate.failed],
    (state, { error }) => ({
      ...state,
      pendingUsermanagerValidationErrors:
        typeof error === 'string'
          ? state.pendingUsermanagerValidationErrors
          : error
    })
  )

  .case(userManagerUserInfoPersisting.done, (state, { params }) => ({
    ...state,
    byId: {
      ...state.byId,
      [params.userId]: createFetchedEntity<IUserManagerUser>({
        buidId: params.buidId,
        neverExpires: params.neverExpires,
        customerId: params.customerId,
        customerToken: '',
        description: params.description,
        lastLogin: params.lastLogin,
        loginName: params.loginName,
        internalUser: params.internalUser,
        invitationSentDate: params.invitationSentDate,
        invitationLanguage: params.invitationLanguage,
        securityLevel: params.securityLevel,
        userId: params.userId,
        userToken: '',
        customerTags: params.customerTags,
        buidTags: params.buidTags,
        haulierTags: params.haulierTags,
        lastAppliedRoleTemplate: params.lastAppliedRoleTemplate,
        serviceNowTicket: params.serviceNowTicket,
        siteDetailsDefaultTab: params.siteDetailsDefaultTab,
        inactivityExpirationDays: params.inactivityExpirationDays,
      })
    },
    pendingUsermanagerValidationErrors: {},
    pendingUsermanager: {}
  }))

  .case(navigateToRoute, state => ({
    ...state,
    pendingUsermanager: {},
    pendingUsermanagerValidationErrors: {},
    pendingUserFeatures: [],
    pendingUserColumns: [],
    pendingUserAccessDetails: {}
  }))

  .case(getAllUserManagerUsers.started, state => ({
    ...state,
    allIds: createPendingEntity()
  }))

  .case(userManagerInvitationChanged, (state, {userId, language}) => {
    const userAsyncEntity = state.byId[userId];
    const user = getEntityOrUndefined(userAsyncEntity);
    
    if (!user)
      return state;

    return {
      ...state,    
      byId: {
        ...state.byId,
        [userId]: createFetchedEntity({
            ...user,            
            invitationLanguage: language,
            invitationSentDate: new Date().toLocaleString()
        })                  
      }
    }          
  })


  .case(userManagerUserModified, (state, user) => {
    return {
      ...state,    
      byId: {
        ...state.byId,
        [user.userId]: createFetchedEntity(user)                  
      }
    }          
  })

  .case(getAllUserManagerUsers.done, (state, { result }) => ({
    ...state,
    ...insertAllFetchedEntities(state, result, u => u.userId)
  }))

  .case(getUserManagerUser.started, (state, userId) => ({
    ...state,
    byId: {
      ...state.byId,
      [userId]: createPendingEntity()
    }
  }))

  .case(getUserManagerUser.done, (state, { result, params }) => ({
    ...state,
    byId: {
      ...state.byId,
      [params]: createFetchedEntity(result)
    }
  }))

  .case(getUserManagerSiteAccessForUser.started, (state, params) => ({
    ...state,
    userSiteAccess: {
      ...state.userSiteAccess,
      [params]: createPendingEntity()
    }
  }))
  .case(updateAlarmRecipient.done, state => ({
    ...state,
    userAlarmConnection: {}
  }))
  .case(removeAlarmRecipient.done, state => ({
    ...state,
    userAlarmConnection: {}
  }))
  .case(addAlarmRecipient.done, state => ({
    ...state,
    userAlarmConnection: {}
  }))
  .case(deleteAlarm.done, state => ({ ...state, userAlarmConnection: {} }))
  .case(updateAlarm.done, state => ({ ...state, userAlarmConnection: {} }))

  .case(getUserManagerSiteAccessForUser.done, (state, { result, params }) => ({
    ...state,
    userSiteAccess: {
      ...state.userSiteAccess,
      [params]: createFetchedEntity(result)
    }
  }))

  .case(getUserManagerAlarmConnectionForUser.started, (state, params) => ({
    ...state,
    userAlarmConnection: {
      ...state.userAlarmConnection,
      [params]: createPendingEntity()
    }
  }))

  .case(
    getUserManagerAlarmConnectionForUser.done,
    (state, { result, params }) => ({
      ...state,
      userAlarmConnection: {
        ...state.userAlarmConnection,
        [params]: createFetchedEntity(result)
      }
    })
  )

  .case(getUserManagerCustomerAccessForUser.started, (state, params) => ({
    ...state,
    userCustomerAccess: {
      ...state.userCustomerAccess,
      [params]: createPendingEntity()
    }
  }))

  .case(
    getUserManagerCustomerAccessForUser.done,
    (state, { result, params }) => ({
      ...state,
      userCustomerAccess: {
        ...state.userCustomerAccess,
        [params]: createFetchedEntity(result)
      }
    })
  )

  .case(getRoleTemplates.done, (state, payload) =>
    mergeObjects(state, {
      roleTemplates: createFetchedEntity(payload.result)
    })
  )
  .case(getRoleTemplates.started, state =>
    mergeObjects(state, {
      roleTemplates: createPendingEntity()
    })
  )

  .case(userManagerUserInfoCreate.done, (state, { result }) => ({
    ...state,
    ...insertFetchedEntity(state, result.userId, result)
  }))

  .case(changeUserFeature, (state, payload) => ({
    ...state,
    pendingUserFeatures: appendOrReplaceObjectInArray(
      state.pendingUserFeatures,
      p => p.featureId === payload.featureId,
      payload
    )
  }))

  .case(changeUserFeatures, (state, payload) => {
    let pendingUserFeatures = state.pendingUserFeatures;
    for (const i of payload) {
      pendingUserFeatures = appendOrReplaceObjectInArray(
        pendingUserFeatures,
        p => p.featureId === i.featureId,
        i
      );
      
    }
    return {
      ...state,
      pendingUserFeatures: pendingUserFeatures
    };
  })

  .case(changeUserAccess, (state, { key, value }) => ({
    ...state,
    pendingUserAccessDetails: {
      ...state.pendingUserAccessDetails,
      [key]: value
    }
  }))

  .case(changePendingUserAccessDetails, (state, payload) => (
    {
      ...state,
      pendingUserAccessDetails: payload
    }))

  .case(changeUserColumn, (state, payload) => ({
    ...state,
    pendingUserColumns: appendOrReplaceObjectInArray(
      state.pendingUserColumns,
      p => p.columnId === payload.columnId,
      payload
    )
  }))

  .case(changeUserColumns, (state, payload) => {
    let pendingUserColumns = state.pendingUserColumns;
    for (const i of payload) {
      pendingUserColumns = appendOrReplaceObjectInArray(
        pendingUserColumns,
        p => p.columnId === i.columnId,
        i
      );
    }
    return {
      ...state,
      pendingUserColumns: pendingUserColumns
    };
  })

  .case(getAllBuidAndCustomerFilters.started, state =>
    mergeObjects(state, {
      currentUserBuidAndCustomerFilter: createPendingEntity()
    })
  )

  .case(getAllBuidAndCustomerFilters.done, (state, payload) =>
    mergeObjects(state, {
      currentUserBuidAndCustomerFilter: createFetchedEntity(payload.result)
    })
  )

  .case(userManagerUserInfoChanged, (state, { property, value }) => ({
    ...state,
    pendingUsermanager: {
      ...state.pendingUsermanager,
      [property]: value
    },
    pendingUsermanagerValidationErrors: {
      ...state.pendingUsermanagerValidationErrors,
      [property]: []
    }
  }))

  .case(sendInvite.started, (state, params) =>
    mergeObjects(state, {
      invitationRequest: requestLoadingWrapper(params)
    })
  )

  .case(sendInvite.failed, (state, payload) =>
    mergeObjects(state, {
      invitationRequest:
        state.invitationRequest &&
        state.invitationRequest.query === payload.params
          ? requestFailedWrapper(payload.params, payload.error)
          : state.invitationRequest
    })
  )

  .case(sendInvite.done, (state, payload) =>
    mergeObjects(state, {
      invitationRequest:
        state.invitationRequest &&
        state.invitationRequest.query === payload.params
          ? requestLoadedWrapper(payload.params, true)
          : state.invitationRequest
    })
  )

  .case(removeUsers.done, (state, { params }) => ({
    ...state,
    ...removeAsyncObjects(state, params),
    checkedUserIds: []
  }))
  .case(getUserManagerAccessForUser.started, (state, params) => ({
    ...state,
    userAccess: {
      ...state.userAccess,
      [params]: createPendingEntity()
    }
  }))
  .case(getUserManagerAccessDescriptionForUser.started, (state, params) => ({
    ...state,
    userAccessDescription: {
      ...state.userAccessDescription,
      [params]: createPendingEntity()
    }
  }))
  .case(
    getUserManagerAccessDescriptionForUser.done,
    (state, { result, params }) => ({
      ...state,
      userAccessDescription: {
        ...state.userAccessDescription,
        [params]: createFetchedEntity(result)
      }
    })
  )

  .case(getUserManagerAccessForUser.done, (state, { params, result }) => ({
    ...state,
    userAccess: {
      ...state.userAccess,
      [params]: createFetchedEntity(result)
    }
  }))
  .case(persistUserManagerAccessForUser.done, (state, { params, result }) => ({
    ...state,
    userAccessDescription: {},
    pendingUserAccessDetails: {},
    userSiteAccess: {},
    userCustomerAccess: {},
    userAlarmConnection: {},
    userAccess: {
      ...state.userAccess,
      [params]: createFetchedEntity(result)
    },
    byId: {
      ...state.byId,
      [params]: mergeEntity(state.byId[params], _ => ({
        buidTags: result.details.buidTags,
        customerTags: result.details.customerTags,
        lastAppliedRoleTemplate: result.lastAppliedRoleTemplate
      }))
    }
  }))
  .case(selectRoleTemplate, (state, template) => {
    return {
      ...state,
      selectedRoleTemplate: template
    };
  })
  .case(unSelectRoleTemplate, (state) => {
    return {
      ...state,
      selectedRoleTemplate: undefined
    };
  });

export default userManagerReducer;
