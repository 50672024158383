import { customElement, containerless, bindable } from 'aurelia-framework';
import './flex-grid.css';

@customElement('flex-grid')
@containerless()
export class FlexGrid {
  @bindable() stretch: 'fit' | 'needed' = 'fit';
  @bindable() class: string | undefined;

}
