import { SiteStatus, SiteSignal } from '../models/index';
import {
  ISiteSignal,
  IAlarm,
  ISiteChannel,
  ISite,
  IController
} from '../interfaces';
import { isEmpty } from '.';

export const getSiteSignal = (
  alarms: IAlarm[],
  site: ISite,
  channels: ISiteChannel[],
  controllers: IController[]
): ISiteSignal => ({
  hasAlarm: alarms.some(a => a.alarmType === 1 && a.active), //!isNone(a.siteChannelId)
  isParked: !isEmpty(channels) && channels.every(ch => ch.isParked),
  isVirtual: controllers.every(c => c.isVirual),
  signal: site.signal
});

export const getSiteHealthEnum = (
  isVirtual: boolean,
  isParked: boolean,
  hasAlarm: boolean,
  signal: SiteSignal
) => {
  if (signal == SiteSignal.Stale && isParked && !hasAlarm)
    return SiteStatus.StaleParked;
  else if (signal == SiteSignal.Offline && isParked && !hasAlarm)
    return SiteStatus.OfflineParked;
  else if (hasAlarm && isParked) return SiteStatus.AlarmParked;
  else if (isVirtual) return SiteStatus.Virtual;
  else if (hasAlarm) return SiteStatus.Alarm;
  else if (signal == SiteSignal.Stale) return SiteStatus.Stale;
  else if (signal == SiteSignal.Offline) return SiteStatus.Offline;
  else if (isParked) return SiteStatus.Parked;
  else return SiteStatus.Normal;
};

export const getSiteSignalEnum = (isVirtual: boolean, signal: SiteSignal) => {
  if (isVirtual) return SiteSignal.Virtual;

  return signal;
};
