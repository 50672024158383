export const lastTwo = (v: number) => ('0' + v).slice(-2);
export const isSomething = <T>(t: T | undefined | null): t is T =>
  t !== null && t !== undefined;
export const isNone = <T>(t: T | null | undefined): t is undefined =>
  t === null || t === undefined;
export const isEmpty = <T>(t?: string | T[]) => isNone(t) || t.length === 0;
export const isNotEmpty = <T>(t?: string | T[]) => isSomething(t) && t.length !== 0;

export const emptyObject = {};
export const emptyArray = [];

/**
 * # Round
 * @param float Decimal number
 * @param decimalPlaces Number of decimals (default: 0)
 * @returns A decimal number with the given number of decimals.
 */
export const round = (float: number, decimalPlaces?: number): number => {
  const int = (n: number) => parseInt('' + n)
  const RoundingFactor = 0.5
  const n = decimalPlaces ?? 0 // Default to zero
  
  const coefficient = Math.pow(10, n)
  const bloated = float * coefficient + RoundingFactor
  return int(bloated) / coefficient
}
