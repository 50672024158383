import { customElement, bindable } from 'aurelia-framework';
import './textbox-multiline.css';

@customElement('textbox-multiline')
export class TextboxMultiline {

  @bindable placeholder: string | undefined;
  @bindable onEnter: Function | undefined;
  @bindable restrictResizeTo: 'horizontal' | 'vertical' | 'no-resize' | undefined;
  @bindable shiftEnterAsNewline = false;

  @bindable minHeight: string | undefined;
  @bindable autofocus: boolean;

  @bindable onChanged: Function;
  @bindable onBlur: ({}) => void;

  @bindable value: string = '';

  @bindable rows: number | undefined;

  @bindable inline: boolean;

  @bindable straightRight: boolean;

  @bindable disabled: boolean;

  private inputelement: HTMLElement;

  valueChanged(_newValue: string, _oldValue: string) {
    !!this.onChanged && this.onChanged({ value: this.value });
  }

  clearSearch() {
    this.value = '';    
    this.valueChanged(this.value, '');
  }

  callOnBlur() {
    if (!this.onBlur) return;
    this.onBlur({ value: this.value });
  }

  keyPress = (event: KeyboardEvent) => {
    if(this.shiftEnterAsNewline && !event.shiftKey && event.keyCode === 13) {
      event.preventDefault();
      this.onEnter && this.onEnter();
      return false;
    }
    return true;
  }

  attached() {
    if (!this.disabled) {
      if(this.autofocus) {
        this.inputelement.focus();
      }
    }

    this.inputelement.addEventListener('keypress', this.keyPress);
  }

  detached() {
    this.inputelement.removeEventListener('keypress', this.keyPress);
  }
}
