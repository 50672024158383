export class ExcludeWithPropertyValueConverter {
  toView(array: Array<{}>, property: string, doFilter: boolean) {
    if (
      array === undefined ||
      array == null ||
      property === undefined ||
      doFilter === false
    ) {
      return array;
    }

    return array.filter((item: any) => !item[property]);
  }
}
