import { customElement, bindable } from 'aurelia-framework';
import { orderByPredicate } from '../../../utility/sorting';
import { IHistoryItem } from '../../../interfaces';
import { toLocalTimeFromUtc } from '../../../utility';
import './history-list.css';

interface IHistoryItemGroupedByYear {
  year: number;
  items: IHistoryItem[];
}

@customElement('history-list')
export class HistoryList {
  @bindable items: IHistoryItem[];
  @bindable timezone: string | undefined;

  grouped: IHistoryItemGroupedByYear[];

  itemsChanged(newItems: IHistoryItem[]) {
    this.grouped = this.groupItems(newItems);
  }

  timezoneChanged() {
    if (this.items !== undefined) this.grouped = this.groupItems(this.items);
  }

  groupItems(items: IHistoryItem[]) {
    const sorted = orderByPredicate(items, item => item.timeStamp, 'desc');

    const grouped: IHistoryItemGroupedByYear[] = [];

    for (const k of sorted) {
      const year = toLocalTimeFromUtc(
        new Date(k.timeStamp),
        this.timezone
      ).getFullYear();
      let found = grouped.find(g => g.year === year);

      if (!found) {
        found = { year, items: [] };
        grouped.push(found);
      }
      found.items.push(k);
    }

    return grouped;
  }
}
