import { autoinject, TaskQueue } from "aurelia-framework";
import { getLogger } from "aurelia-logging";
import { BaseService } from "./baseService";
import { HttpClient } from "aurelia-fetch-client";
import { getSession, setSession } from "../config/sessionService"
import { IClaims, IClaimsPair, mapClaims } from "$interfaces/application/iClaims";
import { BroadcastManagerChannelService } from "./broadcastManagerChannelService";

@autoinject
export class AuthenticationService extends BaseService {
  private broadcastManager: BroadcastManagerChannelService;
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(AuthenticationService.name), taskQueue);
    this.broadcastManager = BroadcastManagerChannelService.getInstance();
  }

  isAuthenticated = async () => { 
    const response = await fetch('bff/user', {
      headers: {
        "x-csrf": "1",
      },
    });

    if(response.ok)
      this.broadcastManager.setupListener('logout', this.logout)
    
    
    const session = getSession();
    if(response.status == 401 && session.userIsLoggedIn){
      this.logout();
    }
  
    return response.ok;

  }

  login = () => {
    const returnUrl = encodeURIComponent(window.location.pathname + window.location.search);
    window.location.href = `/bff/login?returnUrl=${returnUrl}`;
  }

  logout = () => {
    const session = getSession();
    if(session.logoutUrl){
      this.broadcastManager.sendMessage('logout');
      this.broadcastManager.close();
      window.location.href = session.logoutUrl;
    }
     
  }

  getUserClaims = async () : Promise<IClaims> => {

    const response = await fetch('bff/user', {
        headers: {
          "x-csrf": "1",
        },
      });

      if(!response.ok) this.login();
      
      const claimPairs : IClaimsPair[] = await response.json() as IClaimsPair[];
      const claims = mapClaims(claimPairs);

      return claims;
  }

  setClaims = (claims: IClaims) => {
    setSession(claims);
  }

}

