import { fetchingControllerLogsAction } from '../../actions';
import { IControllerLog, IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  appendObjectToArray,
  getEntityOrDefault,
  getAsyncEntity,
  asyncEntityIsFetched
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  IAsyncDictionary,
  createFetchedEntity,
  createPendingEntity
} from '../../types';

export interface IControllerLogsReducerState
  extends IAsyncReducerState<IControllerLog> {
  byControllerId: IAsyncDictionary<number[]>;
  fetchedAllLogsForControllers: number[];
}

const defaultState: IControllerLogsReducerState = {
  byId: {},
  allIds: undefined,
  byControllerId: {},
  fetchedAllLogsForControllers: []
};

const reducer = reducerWithInitialState(defaultState)
  .case(fetchingControllerLogsAction.done, (state, { result, params }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, c => c.controllerLogId),
      {
        byControllerId: mergeObjects(state.byControllerId, {
          [params.controllerId]: params.fetchAll
            ? createFetchedEntity(result.map(r => r.controllerLogId))
            : createFetchedEntity([
                ...result.map(r => r.controllerLogId),
                ...getEntityOrDefault(
                  getAsyncEntity(state.byControllerId, params.controllerId),
                  []
                )
              ])
        }),
        fetchedAllLogsForControllers: !params.fetchAll
          ? state.fetchedAllLogsForControllers
          : appendObjectToArray(
              state.fetchedAllLogsForControllers,
              params.controllerId
            )
      }
    )
  )
  .case(fetchingControllerLogsAction.started, (state, params) =>
    mergeObjects(state, {
      byControllerId: mergeObjects(state.byControllerId, {
        [params.controllerId]: asyncEntityIsFetched(
          getAsyncEntity(state.byControllerId, params.controllerId)
        )
          ? getAsyncEntity(state.byControllerId, params.controllerId)
          : createPendingEntity()
      })
    })
  );

export default reducer;
