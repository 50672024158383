import { reducerWithInitialState } from 'typescript-fsa-reducers';
import actionCreatorFactory from 'typescript-fsa';
import { IBuid, ICustomer } from '../../../../interfaces';
import { mergeObjects } from '../../../../utility';
import { persistMultipleSites } from '../../../../actions';
import { IMultiEditPendingChanges } from '../../../../interfaces/entity/iMultiEdit';
import { ContactType } from '../../../../../custom_typings/graphql';
import { IContact } from '../../../../interfaces/entity/icontact';

// Multi edit actions.
const actionCreator = actionCreatorFactory('multiedit');

export const propertyChanged = actionCreator<{
  property: keyof IMultiEditPendingChanges;
  value: IBuid | ICustomer | boolean;
}>('propertyChanged');
export const toggleDialog = actionCreator('toggleDialog');

export const cancelEdit = actionCreator('cancelEdit');

export const assignContactToSites = actionCreator<{
  contact: IContact | undefined;
  contactType: ContactType;
}>('assignContactToSites');
export const removeContactToSites = actionCreator<{
  contactType: ContactType;
  contactEditProperty: ContactEditType;
}>('removeContactToSites');
export const clearContactFromSites = actionCreator<{
  contactType: ContactType;
  contactEditProperty: ContactEditType;
}>('clearContactFromSites');

export type ContactEditType =
  | 'editOrderContact'
  | 'editTechContact'
  | 'editSalesContact'
  | 'editCustomerContact';

export interface IMultiEditReducerState {
  pendingChanges?: IMultiEditPendingChanges;
}

const defaultState: IMultiEditReducerState = {
};

const multiEditReducer = reducerWithInitialState(defaultState)
  .case(propertyChanged, (state, { property, value }) =>
    mergeObjects(state, {
      pendingChanges: mergeObjects(state.pendingChanges, {
        [property]: value
      })
    })
  )

  .case(toggleDialog, state =>
    mergeObjects(state, {
      pendingChanges: {
        editBuid: false,
        editCustomer: false,
        editSoldTo: false,
        editParked: false,

        editOrderContact: false,
        editTechContact: false,
        editSalesContact: false,
        editCustomerContact: false,
        editAlarmMuted: false,
        contacts: {}
      }
    })
  )

  .cases([cancelEdit, persistMultipleSites.done], state =>
    mergeObjects(state, {
      pendingChanges: undefined
    })
  )

  .case(assignContactToSites, (state, payload) =>
    mergeObjects(state, {
      pendingChanges: mergeObjects(state.pendingChanges, {
        contacts: mergeObjects(state.pendingChanges!.contacts, {
          [payload.contactType.toLowerCase()]: payload.contact
        })
      })
    })
  )

  .case(removeContactToSites, (state, payload) =>
    mergeObjects(state, {
      pendingChanges: mergeObjects(state.pendingChanges, {
        contacts: mergeObjects(state.pendingChanges!.contacts, {
          [payload.contactType.toLowerCase()]: undefined
        }),
        [payload.contactEditProperty]: false
      })
    })
  )

  .case(clearContactFromSites, (state, payload) =>
    mergeObjects(state, {
      pendingChanges: mergeObjects(state.pendingChanges, {
        contacts: mergeObjects(state.pendingChanges!.contacts, {
          [payload.contactType.toLowerCase()]: undefined
        }),
        [payload.contactEditProperty]: true
      })
    })
  );

export default multiEditReducer;
