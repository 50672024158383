import { isNone } from '../utility/helpers';
import { mergeObjects } from '../utility/stateHelpers';
import {
  asyncEntityIsFetched,
  mapFetchedAsyncEntity,
  getEntityOrDefault,
  getEntityOrUndefined
} from '../utility/asyncHelpers';

export interface IObjectDictionary<T> {
  [index: string]: T | undefined;
}

export interface IAsyncDictionary<T> {
  [index: string]: IAsyncEntity<T>;
}

export interface IAsyncFetchingEntityState {
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  fetched: false;
}

export interface IAsyncFetchedEntityState<T> {
  fetched: true;
  entity: T;
  pendingEntity?: Partial<T>;
  saving: boolean;
  refreshing: boolean;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const pendingEntity: IAsyncFetchingEntityState = {
  error: false,
  fetched: false,
  loading: true,
  errorMessage: undefined
};

export const createPendingEntity = (): IAsyncFetchingEntityState =>
  pendingEntity;

export const createFetchedEntity = <T>(
  entity: T
): IAsyncFetchedEntityState<T> => ({
  fetched: true,
  entity,
  error: false,
  refreshing: false,
  saving: false,
  loading: false
});

export const createFailedFetchingEntity = (
  errorMessage: string
): IAsyncFetchingEntityState => ({
  loading: false,
  error: true,
  fetched: false,
  errorMessage
});

export const setPendingFetchedEntity = <T>(
  fetchedEntity: IAsyncFetchedEntityState<T>,
  pendingEntity: Partial<T>
): IAsyncFetchedEntityState<T> =>
  mergeObjects(fetchedEntity, {
    pendingEntity
  });

export const changeEntityOfFetchedEntity = <T, E>(
  fetchedEntity: IAsyncFetchedEntityState<T>,
  entity: E
): IAsyncFetchedEntityState<E> => ({
  entity,
  error: fetchedEntity.error,
  errorMessage: fetchedEntity.errorMessage,
  fetched: fetchedEntity.fetched,
  loading: fetchedEntity.loading,
  saving: fetchedEntity.saving,
  pendingEntity: undefined,
  refreshing: fetchedEntity.refreshing
});

export type IAsyncEntity<T> =
  | IAsyncFetchingEntityState
  | IAsyncFetchedEntityState<T>
  | undefined;

const emCache = new WeakMap();

const tryFetchFromCache = <T>(
  cache: WeakMap<any, T>,
  key: any,
  operationIfNull: () => T
) => {
  if (cache.has(key)) return cache.get(key);
  const value = operationIfNull();
  cache.set(key, value);
  return value;
};

export class IAsyncEntityManager<T> {
  static Create = <T>(
    entity: IAsyncEntity<T> | IAsyncEntityManager<T>
  ): IAsyncEntityManager<T> =>
    entity instanceof IAsyncEntityManager
      ? entity
      : isNone(entity)
      ? IAsyncEntityManager.emptyManager
      : tryFetchFromCache(
          emCache,
          entity,
          () => new IAsyncEntityManager(entity)
        );

  static emptyManager = new IAsyncEntityManager(undefined);

  constructor(private entity: IAsyncEntity<T>) {}

  map<E>(mapFunc: (entity: T) => E): IAsyncEntityManager<E> {
    return IAsyncEntityManager.Create(
      mapFetchedAsyncEntity(this.entity, mapFunc)
    );
  }

  bind<E>(
    bindFunc: (entity: T) => IAsyncEntityManager<E> | IAsyncEntity<E>
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity)
      ? IAsyncEntityManager.Create(bindFunc(this.entity.entity))
      : IAsyncEntityManager.Create(undefined);
  }

  bindWithDep<E, D1>(
    dep1: IAsyncEntityManager<D1>,
    bindFunc: (entity: T, entity2: D1) => IAsyncEntityManager<E>
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity) &&
      asyncEntityIsFetched(dep1.entity)
      ? IAsyncEntityManager.Create(
          bindFunc(this.entity.entity, dep1.entity.entity)
        )
      : IAsyncEntityManager.Create(undefined);
  }

  map2<E, D1>(
    dep1: IAsyncEntityManager<D1>,
    mapFunc: (entity: T, entity2: D1) => E
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity) &&
      asyncEntityIsFetched(dep1.entity)
      ? IAsyncEntityManager.Create<E>(
          changeEntityOfFetchedEntity(
            this.entity,
            mapFunc(this.entity.entity, dep1.entity.entity)
          )
        )
      : IAsyncEntityManager.Create<E>(undefined);
  }

  map3<E, D1, D2>(
    dep1: IAsyncEntityManager<D1>,
    dep2: IAsyncEntityManager<D2>,
    mapFunc: (entity: T, entity2: D1, entity3: D2) => E
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity) &&
      asyncEntityIsFetched(dep1.entity) &&
      asyncEntityIsFetched(dep2.entity)
      ? IAsyncEntityManager.Create<E>(
          changeEntityOfFetchedEntity(
            this.entity,
            mapFunc(this.entity.entity, dep1.entity.entity, dep2.entity.entity)
          )
        )
      : IAsyncEntityManager.Create<E>(undefined);
  }

  map4<E, D1, D2, D3>(
    dep1: IAsyncEntityManager<D1>,
    dep2: IAsyncEntityManager<D2>,
    dep3: IAsyncEntityManager<D3>,
    mapFunc: (entity: T, entity2: D1, entity3: D2, entity4: D3) => E
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity) &&
      asyncEntityIsFetched(dep1.entity) &&
      asyncEntityIsFetched(dep2.entity) &&
      asyncEntityIsFetched(dep3.entity)
      ? IAsyncEntityManager.Create<E>(
          changeEntityOfFetchedEntity(
            this.entity,
            mapFunc(
              this.entity.entity,
              dep1.entity.entity,
              dep2.entity.entity,
              dep3.entity.entity
            )
          )
        )
      : IAsyncEntityManager.Create<E>(undefined);
  }

  map5<E, D1, D2, D3, D4>(
    dep1: IAsyncEntityManager<D1>,
    dep2: IAsyncEntityManager<D2>,
    dep3: IAsyncEntityManager<D3>,
    dep4: IAsyncEntityManager<D4>,
    mapFunc: (
      entity: T,
      entity2: D1,
      entity3: D2,
      entity4: D3,
      entity5: D4
    ) => E
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity) &&
      asyncEntityIsFetched(dep1.entity) &&
      asyncEntityIsFetched(dep2.entity) &&
      asyncEntityIsFetched(dep3.entity) &&
      asyncEntityIsFetched(dep4.entity)
      ? IAsyncEntityManager.Create<E>(
          changeEntityOfFetchedEntity(
            this.entity,
            mapFunc(
              this.entity.entity,
              dep1.entity.entity,
              dep2.entity.entity,
              dep3.entity.entity,
              dep4.entity.entity
            )
          )
        )
      : IAsyncEntityManager.Create<E>(undefined);
  }

  map6<E, D1, D2, D3, D4, D5>(
    dep1: IAsyncEntityManager<D1>,
    dep2: IAsyncEntityManager<D2>,
    dep3: IAsyncEntityManager<D3>,
    dep4: IAsyncEntityManager<D4>,
    dep5: IAsyncEntityManager<D5>,
    mapFunc: (
      entity: T,
      entity2: D1,
      entity3: D2,
      entity4: D3,
      entity5: D4,
      entity6: D5
    ) => E
  ): IAsyncEntityManager<E> {
    return asyncEntityIsFetched(this.entity) &&
      asyncEntityIsFetched(dep1.entity) &&
      asyncEntityIsFetched(dep2.entity) &&
      asyncEntityIsFetched(dep3.entity) &&
      asyncEntityIsFetched(dep4.entity) &&
      asyncEntityIsFetched(dep5.entity)
      ? IAsyncEntityManager.Create<E>(
          changeEntityOfFetchedEntity(
            this.entity,
            mapFunc(
              this.entity.entity,
              dep1.entity.entity,
              dep2.entity.entity,
              dep3.entity.entity,
              dep4.entity.entity,
              dep5.entity.entity
            )
          )
        )
      : IAsyncEntityManager.Create<E>(undefined);
  }

  getEntityOrDefault(defaultValue: T) {
    return getEntityOrDefault(this.entity, defaultValue);
  }

  getEntityOrUndefined() {
    return getEntityOrUndefined(this.entity);
  }

  getAsyncEntity() {
    return this.entity;
  }

  isFetched() {
    return asyncEntityIsFetched(this.entity);
  }
}

/*
export interface IAsyncEntityDone<T> {
    type: 'done';
    entity: T;
}

export interface IAsyncEntityProcessing {
    type: 'processing';
}

export interface IAsyncEntityError {
    type: 'error';
    message: string;
}

export const asyncEntityError = (message: string): IAsyncEntityError => ({ message, type: 'error' });
export const asyncEntityDone = <T>(entity: T): IAsyncEntityDone<T> => ({ type: 'done', entity });
export const asyncEntityProcessing: IAsyncEntityProcessing = { type: 'processing' };

export type IAsyncEntity<T> = IAsyncEntityDone<T> | IAsyncEntityError | IAsyncEntityProcessing | undefined;
*/
