import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects, insertAllFetchedEntities } from '../../utility';
import { IHaulier, IAsyncReducerState } from '../../interfaces';
import { getAllHauliers } from '../../actions';
import { createPendingEntity } from '../../types';

export interface IHaulierReducerState extends IAsyncReducerState<IHaulier> { }

const defaultState: IHaulierReducerState = {
  allIds: undefined,
  byId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(getAllHauliers.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )  
  .case(getAllHauliers.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, haulier => haulier.haulierId)
    )
  )
 
export default reducer;
