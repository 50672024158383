import {
  customElement,
  bindable,
  autoinject,
  observable,
  computedFrom
} from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { isNone } from '../../../utility';
import { TankService } from '../../../services';
import { getUserFeatures } from '../../../config/sessionService';

@autoinject()
@customElement('sap-shipto-dropdown')
export class SapShiptoDropdown {
  constructor(private tankService: TankService, private logger: Logger) {}

  @bindable() soldTo: string | undefined;
  @bindable() alignTop = false;
  @bindable() valuePath: string;
  @bindable() selected: string;
  @bindable() itemClicked: undefined | ((params: { value: string }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() canSelectBlankValue = false;
  @observable() dataLoading: boolean = false;
  data: string[] = [];
  protected features = getUserFeatures();

  bind() {
    if (!isNone(this.soldTo) && this.features.sapLookup.read)
      this.doDataLoad(this.soldTo);
  }

  soldToChanged() {
    if (!isNone(this.soldTo) && this.features.sapLookup.read)
      this.doDataLoad(this.soldTo);
  }

  _itemClicked(value: string, text: string) {
    if (this.itemClicked) this.itemClicked({ value: value || text?.trim() });
  }

  @computedFrom('data', 'dataLoading')
  get noShipTosFound() {
    return !this.dataLoading && this.data.length === 0;
  }

  private doDataLoad(soldTo: string) {
    this.dataLoading = true;

    this.getShipTos(soldTo)
      .then(shipTos => {
        this.data = shipTos;
        this.dataLoading = false;
      })
      .catch(reason => {
        this.dataLoading = false;
        this.logger.warn(`loading shipTos failed: ${reason}`);
      });
  }

  private getShipTos(soldTo: string) {
    if (soldTo.length < 1) return Promise.reject('no soldTo');
    return this.tankService.getShipTos(soldTo);
  }
}
