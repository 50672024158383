import { customElement, bindable, autoinject, DOM, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { isTouchDeviceWatcher } from '../../utility';
import './tooltip.css';

@autoinject()
@customElement('tooltip')
export class Tooltip {
  @bindable text: string;
  @bindable textVariables: object;
  @bindable placement: 'left' | 'top' = 'top';
  @bindable fill: 'needed' | 'stretch' = 'needed';
  @bindable customClass: string | undefined;
  @bindable enabled: boolean = true;

  isTouchDevice: boolean = false;
  private unsubscribeWatcher: Function;

  constructor(private element: Element, private i18n: I18N) {
    this.unsubscribeWatcher = isTouchDeviceWatcher((isTouchDevice: boolean) => {
      this.isTouchDevice = isTouchDevice;
    });
  }

  detached() {
    this.unsubscribeWatcher && this.unsubscribeWatcher();
  }

  @computedFrom('text','textVariables', 'enabled')
  get tooltipText(): string | undefined {
    if(!this.enabled) return;
    return this.i18n.tr(this.text, this.textVariables);
  }

  tooltipCss: object | undefined;
  clickTriggered = false;
  
  showTooltip (fromClick = false) {
    if(!this.enabled) return true;
    if(fromClick) {
      this.clickTriggered = true;
        setTimeout(() => DOM.addEventListener('click', this.dismissTooltip, false));
    }
    const { top, left } = this.element.getBoundingClientRect();
    if(this.placement === 'top') {
      this.tooltipCss = { bottom: `calc(100% - ${top}px + 0.5rem)`, left: left +'px' };
      return true;
    }
    if(this.placement === 'left') {
      this.tooltipCss = { top: top + 'px', right: `calc(100% - ${left}px)` };
      return true;
    }
    return true;
  }

  dismissTooltip = () => {
    this.tooltipCss = undefined;
    this.clickTriggered = false;
    DOM.removeEventListener('click', this.dismissTooltip, false);
  }
}
