import { css, html, LitElement } from "lit-element";
import { property } from 'lit-element/decorators';

class AvatarImage extends LitElement {
  @property() url: string;
  
  static styles = css`
    img {
      border-radius: 100%;
      height: 100%;
      width: 100%;
    }
  `;
  
  render(){
    return html`<img src="${this.url}"/>`
  }
}

customElements.define('avatar-image', AvatarImage)