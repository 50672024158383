import actionCreatorFactory from 'typescript-fsa';
import {
  IEquipment,
  IEquipmentAttachment,
  IBlob,
  IEquipmentAttachmentFileInfo
} from '../interfaces';

const actionCreator = actionCreatorFactory('equipment');

export const equipmentParameterChanged = actionCreator<{
  property: string;
  value: string;
}>('equipment_parameter_changed');
export const deletedSiteEquipment = actionCreator.async<
  { siteId: number; equipmentId: number },
  boolean
>('deleted_site_equipment');
export const updateSiteEquipment = actionCreator.async<
  { siteId: number; equipmentId: number },
  IEquipment
>('update_site_equipment');
export const getSiteEquipment = actionCreator.async<
  { siteId: number },
  IEquipment[]
>('get_site_equipment');
export const persistingNewSiteEquipment = actionCreator.async<
  number,
  IEquipment
>('persisting_new_site_equipment');

export const changeEquipmentAttachment = actionCreator<{
  attachment: IEquipmentAttachment;
  property: string;
  value: string;
}>('equipment_attachment_changed');
export const addEquipmentAttachment = actionCreator('add_equipment_attachment');
export const removeEquipmentAttachment = actionCreator<IEquipmentAttachment>(
  'remove_equipment_attachment'
);
export const cancelEquipmentEditing = actionCreator('cancel_equipment_editing');

export const changeAttachmentBlob = actionCreator.async<
  { attachment: IEquipmentAttachment; fileInfo: IEquipmentAttachmentFileInfo },
  IBlob
>('change_attachment_blob');
export const toggleExpandedAttachment = actionCreator<IEquipmentAttachment>(
  'toggle_expanded_attachment'
);
