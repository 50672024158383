import { bindable } from 'aurelia-framework';
import './drawer.css';

export class DrawerCustomElement {
  @bindable isOpen: boolean;

  @bindable onToggle: () => void;

  toggle(): void {
    this.onToggle();
  }
}
