import { LogManager, Aurelia } from 'aurelia-framework';
import { SentryAppender, getEnvironment } from '../utility';
import { getSessionUser } from './sessionService';

export const loggingConfig = {
  configurationString:
    'https://b4bc8c835ae04ed3a2fe4ce818762294@sentry.io/132343'
};

let sentryAppender: SentryAppender | undefined = undefined;
const environment = getEnvironment();

export const setSentryUserContext = () => {
  if (sentryAppender) sentryAppender.setUserContext(getSessionUser());
};

export const configureLogging = (aurelia: Aurelia) => {
  if (ENV === 'production') {
    sentryAppender = new SentryAppender({
      dsn: loggingConfig.configurationString,
      environment
    });
    LogManager.addAppender(sentryAppender);
    LogManager.setLevel(LogManager.logLevel.error);
  } else aurelia.use.developmentLogging();
};
