import { customElement, bindable, PLATFORM } from 'aurelia-framework';
import './color-picker.css';

@customElement('color-picker')
export class ColorPicker {
  @bindable color: string | undefined;
  @bindable placeholder: string = 'UI_Common_ColorPicker_TextArea_Placeholder';
  @bindable changed: Function;
  @bindable disabled: boolean = false;

  //private picker: HTMLElement;
  pickerIsShown = false;

  setColor = (value: string) => {
    if (this.changed) this.changed({ value });
    this.pickerIsShown = false;
    PLATFORM.removeEventListener('click', this.clickHandler);
  };
  /*presets = [
        "#000000",
        "#5f5f5f",
        "#9a9a9a",
        "#cbcbcb",
        "#e1e1e1",
        "#f3f3f3",
        "#f51800",
        "#f78d00",
        "#3fa774",
        "#3365fc",
        "#8200ff",
        "#eb1a9c",
    ];*/

  // presets = [
  //   '#FFFF99',
  //   '#FFC91C',
  //   '#f96302',
  //   '#e60000',
  //   '#E0219E',
  //   '#D81E05',
  //   '#D7CFCF',
  //   '#CEE007',
  //   '#C2CC23',
  //   '#AF72C1',
  //   '#A8E6D6',
  //   '#9b4f19',
  //   '#919693',
  //   '#8CC317',
  //   '#86a10b',
  //   '#78A22F',
  //   '#752828',
  //   '#69BF9E',
  //   '#56575a',
  //   '#4f84a0',
  //   '#4F2170',
  //   '#4f008a',
  //   '#45637A',
  //   '#3BA379',
  //   '#00ADC6',
  //   '#009977',
  //   '#008FC1',
  //   '#008751',
  //   '#008000',
  //   '#006b75',
  //   '#005b99',
  //   '#005951',
  //   '#000000'
  // ];

  //New presets according to DevOps #4670
  presets = [
    '#00FF00',
    '#0000FF',
    '#00FFFF',
    '#86a10b',
    '#FF0000',
    '#000000',
    '#AF3000',
    '#F09000',
    '#FF00FF',
    '#878787',
    '#E0219E',
    '#10A000',
    '#EE6500',
    '#0000D0',
    '#008000'
  ]; 
  

  showPicker = () => {
    if (this.disabled) return;
    this.pickerIsShown = true;
    PLATFORM.addEventListener('click', this.clickHandler);
  };

  clickHandler = (e: MouseEvent) => {
    if (e.target == null) return;
    const targetElement = e.target as Element;
    if (targetElement.closest('.color-picker-component')) return; // User clicked inside the color-picker.
    this.pickerIsShown = false;
    PLATFORM.removeEventListener('click', this.clickHandler);
  };
}
