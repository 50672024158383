import {
  insertFetchedEntity,
  insertFetchedEntities,
  insertInflightEntity,
  insertInflightEntities,
  removeAsyncObjects
} from '../../utility';
import { IAsyncReducerState, IUser } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getUser, getUsersByIds } from '../../actions/user';
import { addRecipient } from '../../actions';
import {
  userManagerUserInfoPersisting,
  removeUsers
} from '../../pages/usermanager/usermanagerReducer';

export interface IUserReducerState extends IAsyncReducerState<IUser> {}

const defaultState: IUserReducerState = {
  allIds: undefined,
  byId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(userManagerUserInfoPersisting.done, (state, { params }) =>
    insertFetchedEntity<IUser>(state, params.userId, {
      avatarUrl: params.avatarUrl,
      email: params.email,
      name: params.name,
      telephoneNumber: params.telephoneNumber,
      userId: params.userId
    })
  )
  .case(getUser.started, (state, userId) => insertInflightEntity(state, userId))
  .case(getUser.done, (state, { result, params }) =>
    insertFetchedEntity(state, params, result)
  )
  .case(getUsersByIds.done, (state, { result }) =>
    insertFetchedEntities(state, result, u => u.userId)
  )
  .case(getUsersByIds.started, (state, params) => ({
    ...state,
    ...insertInflightEntities(state, params)
  }))
  .case(removeUsers.done, (state, { params }) => ({
    ...state,
    ...removeAsyncObjects(state, params)
  }))
  .case(addRecipient, (state, payload) =>
    payload.user
      ? insertFetchedEntity(state, payload.user.userId, payload.user)
      : state
  );

export default reducer;
