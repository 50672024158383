import { insertFetchedEntities } from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ImportJobRun } from '../../models/importJobRun';
import { getImportJobRuns } from '../../actions/importjobruns';
import { IAsyncReducerState } from '../../interfaces';
import {
  IAsyncDictionary,
  createPendingEntity,
  createFetchedEntity
} from '../../types';

export interface IImportJobRunsState extends IAsyncReducerState<ImportJobRun> {
  byImportJob: IAsyncDictionary<number[]>;
}

const defaultState: IImportJobRunsState = {
  allIds: undefined,
  byId: {},
  byImportJob: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(getImportJobRuns.started, (state, importJobId) => ({
    ...state,
    byImportJob: {
      ...state.byImportJob,
      [importJobId]: createPendingEntity()
    }
  }))
  .case(getImportJobRuns.done, (state, { params, result }) => ({
    ...state,
    ...insertFetchedEntities(state, result, r => r.importJobRunId),
    byImportJob: {
      ...state.byImportJob,
      [params]: createFetchedEntity(result.map(r => r.importJobRunId))
    }
  }))
  .case(getImportJobRuns.failed, state => ({ ...state }));

export default reducer;
