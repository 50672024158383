import { customElement, bindable } from 'aurelia-framework';
import './empty-component.css';

@customElement('empty-component')
export class EmptyComponent {
  @bindable icon: string;
  @bindable image: string;
  @bindable loadingicon: boolean = false;
  @bindable animated: boolean = true;
  @bindable logoStretchesVertically: boolean = true;
}
