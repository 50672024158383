import { customElement, bindable, computedFrom } from 'aurelia-framework';
import { sortDirection } from '../../../types';
import { PLATFORM } from 'aurelia-pal';
import './flex-grid-header-cell.css';
import { Grid, widthProperty } from '../../grid/grid';
import { I18N } from 'aurelia-i18n';

@customElement('flex-grid-header-cell')
export class FlexGridHeader {
  @bindable sortProperty: string | undefined;
  @bindable sortDirection: sortDirection | undefined;
  @bindable property: string | undefined;
  @bindable editMode: boolean = false;
  @bindable tooltipText: string | undefined;

  @bindable hasFilter: boolean = false;
  @bindable filterIsActive: boolean = false;
  @bindable onShowFiltersChanged: Function | undefined
  
  @bindable width: widthProperty; 
  @bindable onWidthChanged: Function | undefined;

  _showTooltip: boolean = true;
  _pendingWidth: number | undefined;
  _minWidth = 100;
  constructor(private el: Element, private i18n: I18N){}

  @computedFrom('_showTooltip', 'tooltipText')
  get tooltipTextToShow() {
    if(!this._showTooltip || !this.tooltipText) return;
    return this.i18n.tr(this.tooltipText);
  }

  widthChanged() {
    this._pendingWidth = undefined; 
  }

  setTooltipStatus(allTextVisible: boolean){
    this._showTooltip = !allTextVisible
  }


  @computedFrom('_pendingWidth', 'width')
  get innerStyle() {
      if(!this._pendingWidth && !this.width) return;
      return Grid.getWidthCSS(this._pendingWidth || this.width);
  }

  filterButtonClicked(event: MouseEvent) {
    event.stopPropagation(); // Filter and sort buttons share a header cell. Stop event bubbling here to avoid sorting column when clicking filter button 
    event.preventDefault();
    this.onShowFiltersChanged && this.onShowFiltersChanged({element: this.el});
  }

  headerTouchStart = ({ touches }: TouchEvent) => {
    const x = touches[0].clientX;
    let initialWidth = this.el.clientWidth;
    const touchMove = this.headerTouchMove.bind(this, x, initialWidth);

    const removeEventListener = (mouseUpEvent: MouseEvent) => {
        PLATFORM.removeEventListener('touchmove', touchMove);
        PLATFORM.removeEventListener('touchend', removeEventListener);

        mouseUpEvent.stopPropagation();
        mouseUpEvent.preventDefault();

        this.onWidthChanged && this.onWidthChanged({ width: this._pendingWidth });
    };

    PLATFORM.addEventListener('touchmove', touchMove);
    PLATFORM.addEventListener('touchend', removeEventListener);
  }

  headerTouchMove = (initialX: number, initialWidth: number, { touches }: TouchEvent) => {
    const x = touches[0].clientX;
    const diffX = x - initialX;
    const newWidth = Math.max(initialWidth + diffX, 90);
    this._pendingWidth = newWidth;
    //this.onWidthChanged && this.onWidthChanged({ width: this._pendingWidth });
  }


  headerMousedown = ({ x }: MouseEvent) => {
    let initialWidth = this.el.clientWidth;
    const mouseMove = this.headerMousemove.bind(this, x, initialWidth);

    const removeEventListener = (mouseUpEvent: MouseEvent) => {
        PLATFORM.removeEventListener('mousemove', mouseMove);
        PLATFORM.removeEventListener('mouseup', removeEventListener);

        mouseUpEvent.stopPropagation();
        mouseUpEvent.preventDefault();

        this.onWidthChanged && this.onWidthChanged({ width: this._pendingWidth });
    };

    PLATFORM.addEventListener('mousemove', mouseMove);
    PLATFORM.addEventListener('mouseup', removeEventListener);
  }

  noop = PLATFORM.noop;

  headerMousemove = (initialX: number, initialWidth: number, { x }: MouseEvent) => {
    const diffX = x - initialX;
    const newWidth = Math.max(initialWidth + diffX, 90);
    this._pendingWidth = newWidth;
    //this.onWidthChanged && this.onWidthChanged({ width: this._pendingWidth });
  }
}
