import './email-input.css';
import {
  bindable,
  PLATFORM,
  computedFrom,
  customElement, 
  ComponentDetached,
  autoinject
} from 'aurelia-framework';
import { isEmpty, isNone, emptyArray, assureArray, isSomething } from '../../../utility';
import { createCustomEvent } from '../../../utility/customEventHelper';

type errorType = undefined | string | string[] | boolean;
const isValidationError = (error: errorType): boolean => {
  return !(typeof error === 'boolean' || isNone(error));
};

@autoinject()
@customElement('email-input')
export class EmailInput implements ComponentDetached {
  @bindable placeholder: string | undefined;
  @bindable value: string | undefined;
  @bindable onBlur = PLATFORM.noop;
  @bindable onChanged = PLATFORM.noop;
  @bindable disabled: boolean = false;
  @bindable error: errorType;
  @bindable isValid: boolean = true;
  @bindable required: boolean;
  inputelement: Element;

  attached() {
    this.runValidation(this.value);
    
  }
  detached() {
    dispatchEvent(
      createCustomEvent('inputremoved', this.inputelement)
    );
  }

  valueChanged(newValue: string | undefined) {
    this.onChanged && this.onChanged({ value: newValue });

    if (isSomething(this.inputelement)) {
      this.runValidation(newValue);
    }    
  }

  runValidation(newValue: string | undefined) {
    const oldError = this.error;
    this.error = this.requiredValidation(newValue);
    if(!this.error){
      const isEmailValid = validateEmailAddress(newValue);
      this.error = isEmailValid ? undefined : ['UI_UserManager_InvalidEmailAddress'];
    }

    if (isValidationError(this.error) !== isValidationError(oldError)) {
      this.notifyOfValidationErrors();
    }
  }

  requiredValidation(newValue: string | undefined): errorType {
    if (this.required && isEmpty(newValue)) {
      return ['UI_Common_ValidationError_RequiredField'];
    }
    return undefined;
  }

  notifyOfValidationErrors() {
    if (isNone(this.inputelement)) {
      return;
    }
    if (isValidationError(this.error)) {
      this.inputelement.dispatchEvent(
        createCustomEvent('validationerrors', this.inputelement)
      );
    } else if (!isValidationError(this.error)) {
      this.inputelement.dispatchEvent(
        createCustomEvent('validationsuccess', this.inputelement)
      );
    }
  }

  @computedFrom('error')
  get hasValidationErrors() {
    return typeof this.error === 'boolean' ? this.error : !isEmpty(this.error);
  }

  @computedFrom('error')
  get errorTexts() {
    return typeof this.error === 'boolean' || isNone(this.error)
      ? emptyArray
      : assureArray(this.error);
  }

}
/**
 * Supports "normal" email addresses on the form something@somewhere.tld
 * Allows multiple subdomains, and dotted parts in front of the @
 * 
 * Does not support email addresses having:
 * - Prefix/name in quotation marks  (technically legal, but won't support it)
 * - Spaces in the prefix/name
 * - Unicode chars in the prefix/name
 * - IP4/6 address instead of hostname
 * - Hostnames without top level domain (tld)
 * - Encoded text in the prefix and other oddities
 * 
 * This is done intentionally. See bug #5762
 */

  const hostNameLabelRegEx = /^[a-zA-Z0-9\-]{1,63}$/;
  const topLevelDomainRegEx = /^[a-zA-Z]{2,63}$/;
  const emailPrefixPartRegEx = /^[a-zA-Z0-9_\-]+$/;  
  export function validateEmailAddress(emailInput: string | undefined): boolean {
    let email = emailInput?.trim();
    if (!email) {
      return true;  // allow blanks
    }
    // Do some overall checks:

    if (email.length > 254) return false;                            // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
    const prefixAndHost = email.split('@');

    if (prefixAndHost.length !== 2) return false;                    // don't allow multiple @ 
    const prefix = prefixAndHost[0];
    const host = prefixAndHost[1];
    if (host.length === 0 || host.length > 255) return false;

    if (prefix.length === 0 || prefix.length > 64) return false;     // Don't allow empty prefix, although it may actually be standard compliant
    // Check the host part, after the @:
    const hostnameLabels = host.split('.');

    if (hostnameLabels.length < 2) return false;                     // Don't allow hosts without top level domains
    const topLevelDomain = hostnameLabels.slice(-1)[0];

    if (!topLevelDomainRegEx.test(topLevelDomain)) return false;
    const hostNameLabels = hostnameLabels.slice(0, -1);   
    for (const hostNameLabel of hostNameLabels) {
      if (!hostNameLabelRegEx.test(hostNameLabel)) return false;   // check subdomains and domains for invalid chars
    }
    // Check the prefix part, in front of the @:
    const prefixParts = prefix.split('.');
    for (const prefixPart of prefixParts) {
      if (prefixPart.length === 0) return false;                   // don't allow leading, trailing og sequential dots
      if (!emailPrefixPartRegEx.test(prefixPart)) return false;    // each part between dots must only contain allowed chars
    }

    return true;    
  }  
