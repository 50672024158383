import { customElement, bindable, autoinject } from 'aurelia-framework';
import { BlobService } from '../../services'
import { showPdfViewer } from '../../utility/pdfHelpers';
import './refill-attachment-modal.css';

interface IRefillAttachmentModalData {
    attachmentName: string;
    attachmentUrl: string;
}

@autoinject()
@customElement('refill-attachment-modal')
export class RefillAttachmentModal {
    @bindable() attachment: IRefillAttachmentModalData;
    @bindable() attachments: IRefillAttachmentModalData[];
    @bindable() closeModal: Function;
    pdfLoaded: Boolean;
    
    constructor(private blobService: BlobService){
    }

    attachmentChanged() {
        this.showAttachment();    
    }

    setAttachment(attachment : IRefillAttachmentModalData) {        
        if (attachment) {
            this.attachment = attachment;
        }
    }

    showAttachment(){
        //Show PDF viewer if attachment is selected
        if (this.attachment && this.attachment.attachmentUrl && this.isPdf(this.attachment))
        {
            //Load PDF viewer            
            this.pdfLoaded = false;
            showPdfViewer('pdf-viewer', this.attachment.attachmentUrl).then(
                () => this.pdfLoaded = true
            );            
        }
    }

    isPdf(attachment: IRefillAttachmentModalData) {
        return attachment.attachmentName.toLocaleLowerCase().endsWith('.pdf')
    }

    isImage(attachment: IRefillAttachmentModalData) {
        return attachment.attachmentName.toLocaleLowerCase().endsWith('.jpg') || attachment.attachmentName.toLocaleLowerCase().endsWith('.jpeg') 
    }

    attached() {
        //Set first attachement as selected.
        if (this.attachments && this.attachments[0]) {
            this.attachment = this.attachments[0];
        }                
    }

    downloadImage(attachment: IRefillAttachmentModalData) {
        if (!attachment.attachmentUrl || !attachment.attachmentName) return;
        return this.blobService.downloadFileFromUrl(
            attachment.attachmentUrl,
            attachment.attachmentName
        );
    }
}