import { customElement, bindable, computedFrom } from 'aurelia-framework';
import './regular-button.css';
import { ProcessingElement } from '../../bases/processing-element';
import { I18N } from 'aurelia-i18n';

@customElement('regular-button')
export class RegularButton extends ProcessingElement {
  @bindable text: string;

  @bindable disabled: boolean | undefined;
  @bindable processing: boolean | string | undefined;
  @bindable error: boolean | string | undefined;

  @bindable icon: string | undefined;
  @bindable tooltip : string | undefined;

  @bindable iconPlacement: 'right' | 'left' | undefined;

  @bindable straightLeft: boolean;
  @bindable straightRight: boolean;

  @bindable size: string = 'medium';
  @bindable colortype: 'color-regular' | 'color-danger' = 'color-regular';
  @bindable type: 'primary' | 'secondary' | 'danger' = 'primary';

  @bindable onClick: undefined | (() => Promise<any> | void);

  @computedFrom('text', '_processing', 'error')
  get textToShow() {
    if (typeof this._processing === 'string') return this._processing;
    if (typeof this._error === 'string') return this._error;
    return this.text;
  }

  @computedFrom('text', 'tooltip')
  get textTooltip() {    
    return this.i18n.tr(this.tooltip ?? this.text);
  }

  constructor(private i18n: I18N)  {
    super();
  }
}
