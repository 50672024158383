import { createStore, applyMiddleware, compose } from 'redux';
import { default as rootReducer, rootState } from '../reducers';
import { createLogger } from 'redux-logger';

const createEnhancers = () => {
  if (ENV === `development`) {
    const composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldRecordChanges: false
        })) ||
      compose;

    const logger = createLogger({
      duration: true
    });
    return composeEnhancers(applyMiddleware(logger));
  } else return compose;
};

export const store = createStore<rootState>(rootReducer, createEnhancers());
