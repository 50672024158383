import { assureArray } from '../utility/arrayHelpers'
export class HighlightValueConverter {
  toView(value: string, textToHightlight: string | string[] | undefined) {
    try {
      if(!value) return '';
      if(!textToHightlight || !textToHightlight.length) return value;
      const matcher = new RegExp(assureArray(textToHightlight).join('|'), 'gi')
      return value.replace(matcher, function (hit) {
        return `<span class="highlighted">${hit}</span>`;
      })

    }
    catch(err) {
      return value;
    }
  }
}
