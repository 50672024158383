import { bindable, customElement } from 'aurelia-framework';
import './switch.css';

@customElement('switch')
export class SwitchCustomComponent {
  @bindable onChanged: ({}) => void;

  @bindable disabled: boolean = false;

  @bindable checked: boolean = false;

  @bindable size: string = 'medium';

  @bindable text: string;

  constructor() {
    // some ctor code that initializes the textbox would go here.
  }

  valueChanged(checked: boolean) {
    if (!this.onChanged || this.disabled) return;

    this.onChanged({ value: checked });
  }
}
