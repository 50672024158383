import { getLanguages } from '../../actions';
import { mergeObjects, insertAllFetchedEntities } from '../../utility';
import { ILanguage, IAsyncReducerState } from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { createPendingEntity, createFailedFetchingEntity } from '../../types';

export interface ILanguageReducerState extends IAsyncReducerState<ILanguage> {}

const defaultState: ILanguageReducerState = {
  allIds: undefined,
  byId: {}
};

const reducer = reducerWithInitialState(defaultState)
  .case(getLanguages.started, state =>
    mergeObjects(state, {
      allIds: createPendingEntity()
    })
  )

  .case(getLanguages.failed, (state, payload) =>
    mergeObjects(state, {
      allIds: createFailedFetchingEntity(payload.error)
    })
  )

  .case(getLanguages.done, (state, { result }) => ({
    ...state,
    ...insertAllFetchedEntities(state, result, r => r.languageId)
  }));

export default reducer;
