import { HttpClient } from 'aurelia-fetch-client';
import { Logger } from 'aurelia-logging';
import { AsyncActionCreators } from 'typescript-fsa';
import { store } from '../reducers/store';
import {
  isSomething,
  asyncEntityHasBeenTriedToFetchOrIsFetching
} from '../utility';
import { rootState } from '../reducers/index';
import { IAsyncEntity, IAsyncEntityManager, requestError } from '../types';
import { TaskQueue } from 'aurelia-framework';

export abstract class BaseService {
  protected store = store;

  constructor(
    protected httpClient: HttpClient,
    protected logger: Logger,
    private taskQueue: TaskQueue
  ) {}

  protected async httpRequest<S>(request: Request) {
    try {
      const response = await this.httpClient.fetch(request);
      if (response.ok)
        return (response.status === 204
          ? undefined
          : await response.json()) as S;
      throw Error(await response.text());
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  protected async httpRequestBlob(request: Request) {
    try {
      const response = await this.httpClient.fetch(request);
      if (response.ok)
        return (response.status === 204
          ? undefined
          : await response.blob());
      throw Error(await response.text());
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  protected async httpRequestText(request: Request): Promise<string> {
    try {
      const response = await new HttpClient().fetch(request);
      if (response.ok)
        return response.status === 204 ? '' : await response.text();
      throw Error(await response.text());
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  protected httpRequestWithDispatchFromState<P, S>(
    request: Request,
    actionCreator: AsyncActionCreators<P, S, requestError>,
    params: P,
    fallbackError: string,
    fetchValueFromState: (state: rootState) => IAsyncEntity<S>,
    beforeSuccessCallback?: Function,
    onError?: Function
  ) {
    const valueFromState = fetchValueFromState(this.store.getState());
    if (asyncEntityHasBeenTriedToFetchOrIsFetching(valueFromState))
      return IAsyncEntityManager.Create(valueFromState);
    this._httpRequestWithDispatch(
      request,
      actionCreator,
      params,
      fallbackError,
      beforeSuccessCallback,
      onError
    );
    return IAsyncEntityManager.Create<S>(undefined);
  }

  protected async httpRequestWithDispatch<P, S>(
    request: Request,
    actionCreator: AsyncActionCreators<P, S, requestError>,
    params: P,
    fallbackError: string,
    cachedValueFunction?: (state: rootState) => S | undefined,
    beforeSuccessCallback?: Function,
    onError?: Function
  ): Promise<S> {
    if (isSomething(cachedValueFunction)) {
      const cachedValue = cachedValueFunction(this.store.getState());
      if (isSomething(cachedValue)) return Promise.resolve(cachedValue);
    }
    if (request.method === 'GET')
      this.logger.warn(
        'httpRequestWithDispatch() for GET requests are deprecated in favor of httpRequestWithDispatchFromState().'
      );
    return this._httpRequestWithDispatch(
      request,
      actionCreator,
      params,
      fallbackError,
      beforeSuccessCallback,
      onError
    );
  }

  private async getErrorResponse(
    response: Response
  ): Promise<requestError | undefined | string> {
    let errorText: string | undefined;

    try {
      errorText = await response.text();
      const json = JSON.parse(errorText);
      return json as requestError;
    } catch (ejson) {
      return errorText;
    }
  }

  private async _httpRequestWithDispatch<P, S>(
    request: Request,
    actionCreator: AsyncActionCreators<P, S, requestError>,
    params: P,
    fallbackError: string,
    beforeSuccessCallback?: Function,
    onError?: Function
  ): Promise<S> {
    store.dispatch(actionCreator.started(params));
    const response = await this.httpClient.fetch(request);
    if (response.ok) {
      const result = (response.status === 204
        ? undefined
        : await response.json()) as S;

      if (beforeSuccessCallback) {
        await beforeSuccessCallback();
        this.taskQueue.queueTask(() => {
          store.dispatch(actionCreator.done({ params, result }));
        });
      } else {
        store.dispatch(actionCreator.done({ params, result }));
      }
      return result;
    } else {
      let error = await this.getErrorResponse(response);
      error = error || fallbackError;      
      
      onError && onError(response.status, error)
      store.dispatch(actionCreator.failed({ params, error }));
      this.logger.error(fallbackError || 'Request failed', error);
      throw error;
    }
  }
}
