import {
  IReportSiteFilter,
  IReportDeviceType,
  IBuid,
  IReportControllerType,
  IReportRefillsForPeriod
} from './../interfaces';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('reports');

export const getDeviceTypeReport = actionCreator.async<{}, IReportDeviceType[]>(
  'REPORTS_GET_DEVICE_TYPE_REPORT'
);
export const getRefillsForPeriodReport = actionCreator.async<
  {},
  IReportRefillsForPeriod[]
>('REPORTS_GET_REFILLS_FOR_PERIOD_REPORT');

export const filterBuidDeviceTypes = actionCreator<string>(
  'REPORTS_FILTER_BUID_DEVICE_TYPES'
);
export const filterControllerDeviceTypes = actionCreator<string>(
  'REPORTS_FILTER_CONTROLLER_DEVICE_TYPES'
);
export const reportBuidFilterChange = actionCreator<IBuid | undefined>(
  'REPORTS_BUID_FILTER_CHANGED'
);

export const reportsSortingChangedSiteRefills = actionCreator<{
  property: string;
  direction: string;
}>('REPORTS_SORTING_CHANGED_SITE_REFILLS');
export const reportsSortingChangedDeviceType = actionCreator<{
  property: string;
  direction: string;
}>('REPORTS_SORTING_CHANGED_DEVICE_TYPE');
export const getAllControllerTypes = actionCreator.async<
  {},
  IReportControllerType[]
>('REPORTS_GET_ALL_CONTROLLERS');
export const getAllSiteFilters = actionCreator.async<{}, IReportSiteFilter[]>(
  'REPORTS_GET_ALL_SITES'
);
export const reportControllerTypeFilterChange = actionCreator<
  IReportControllerType | undefined
>('REPORTS_CONTROLLER_FILTER_CHANGED');
export const reportSiteFilterChange = actionCreator<
  IReportSiteFilter | undefined
>('REPORTS_SITE_FILTER_CHANGED');
export const reportSiteRefillsFromDateChange = actionCreator<Date>(
  'REPORTS_SITE_REFILLS_FROM_DATE_CHANGED'
);
export const reportSiteRefillsToDateChange = actionCreator<Date>(
  'REPORTS_SITE_REFILLS_TO_DATE_CHANGED'
);
export const reportFilterChanged = actionCreator<boolean>(
  'REPORTS_FILTER_CHANGED'
);
export const reportSoldToFilterChanged = actionCreator<string>(
  'REPORTS_SOLD_TO_FILTER_CHANGED'
);

