import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { ISite } from '../interfaces';
import { requests } from '../config';
import {
  fetchingSite,
  fetchingSites,
  fetchingAllSites,
  persistSite,
  deleteSite,
  persistMultipleSites,
  deleteMultipleSites,
  fetchSiteAccessForSiteChannelsOnSite
} from '../actions';
import {
  emptyObject,
  getAsyncEntity,
  getEntityOrUndefined,
  getAllEntities,
  getAsyncEntities
} from '../utility';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { IMultiEditDTO, IMultiEditPendingChanges } from '../interfaces/entity/iMultiEdit';

export class SitesDeleteDialogInfo {
  canSuspendSims: boolean;
}

@autoinject()
export class SiteService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(SiteService.name), taskQueue);
  }

  private lastSiteIdVisited: string | undefined;
  setSiteDetailsVisited(siteId: string){
    this.lastSiteIdVisited = siteId;
  }

  getLastSiteDetailsVisited(): string | undefined {
    return this.lastSiteIdVisited;
  }

  getAllSitesAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getSites,
      fetchingAllSites,
      emptyObject,
      'Could not fetch all sites',
      state => getEntityOrUndefined(getAllEntities(state.site))
    );

  getSiteById = (siteId: number, onError?: Function) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteById(siteId),
      fetchingSite,
      siteId,
      'Could not fetch site',
      state => getAsyncEntity(state.site.byId, siteId),
      undefined,
      onError
  );

  getSiteByIdAsync = (siteId: number) =>
    this.httpRequestWithDispatch(
      requests.getSiteById(siteId),
      fetchingSite,
      siteId,
      'Could not fetch site',
      state => getEntityOrUndefined(getAsyncEntity(state.site.byId, siteId))
    );

  getSitesByIdsAsync = (siteIds: number[]) =>
    this.httpRequestWithDispatch(
      requests.getSitesByIds(siteIds),
      fetchingSites,
      siteIds,
      'Could not fetch sites'
    );

  getSitesByIds = (siteIds: number[]) =>
    this.httpRequestWithDispatchFromState(
      requests.getSitesByIds(siteIds),
      fetchingSites,
      siteIds,
      'Could not fetch sites',
      state => getAsyncEntities(siteIds, state.site.byId)
    );

  getSiteAccessForSiteChannelsOnSite = (siteId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getSiteAccessForSiteChannelsOnSite(siteId),
      fetchSiteAccessForSiteChannelsOnSite,
      { siteId },
      'Could not fetch user access to sites',
      state => getAsyncEntity(state.site.userAccessToSiteChannelSites, siteId)
    );

  updateSite = (site: ISite) =>
    this.httpRequestWithDispatch(
      requests.updateSite(site),
      persistSite,
      site,
      'Could not persist site'
    );

  deleteSite = (
    siteId: number,
    nativeControllerIds: number[],
    deleteControllers: boolean,
    alsoSuspendSimCards: boolean
  ) =>
    this.httpRequestWithDispatch(
      requests.deleteSite(siteId, deleteControllers, alsoSuspendSimCards),
      deleteSite,
      { siteId, nativeControllerIds },
      'Could not delete site'
    );

  updateSites = (siteIds: number[], pendingChanges: IMultiEditPendingChanges) =>
    this.httpRequestWithDispatch(
      requests.updateSites(siteIds, this.convertToMultiEditDTO(pendingChanges)),
      persistMultipleSites,
      { siteIds, pendingChanges },
      'Could not update sites'
    );

  async getDeleteDialogInfo(siteIds: number[]) {
    const request = requests.getSitesDeleteDialogInfo(siteIds);
    const response = this.httpRequest<SitesDeleteDialogInfo>(request);
    return response;
  }

  deleteSites = (siteIds: number[], deleteControllers: boolean, alsoSuspendSimCards: boolean) =>
    this.httpRequestWithDispatch(
      requests.deleteSites(siteIds, deleteControllers, alsoSuspendSimCards),
      deleteMultipleSites,
      { siteIds, deleteControllers, alsoSuspendSimCards },
      'Could not delete sites'
    );

  private convertToMultiEditDTO = (
    pendingChanges: IMultiEditPendingChanges
  ): IMultiEditDTO => {
    const { buid, customer, contacts } = pendingChanges;

    return {
      ...pendingChanges,
      buidId: buid && buid.buidId,
      customerId: customer && customer.customerId,
      orderContactId: contacts.order && contacts.order.contactId,
      techContactId: contacts.tech && contacts.tech.contactId,
      salesContactId: contacts.sales && contacts.sales.contactId,
      customerContactId: contacts.customer && contacts.customer.contactId,
    };
  };
}
