import { customElement, bindable } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../pages/common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import {
  StaalduinenPickupPointsQuery_staalduinenPickupPoints,
  StaalduinenPickupPointsQuery
} from '../../../../custom_typings/graphql';

@customElement('staalduinen-pickup-point-dropdown')
export class StaalduinenPickupPointsDropdown extends GraphQLBaseViewModel<
  void,
  StaalduinenPickupPointsQuery,
  void
> {
  @bindable() selected: Array<
    StaalduinenPickupPointsQuery_staalduinenPickupPoints
  >;
  @bindable() itemClicked:
    | undefined
    | ((params: {
        value: StaalduinenPickupPointsQuery_staalduinenPickupPoints;
        text: string;
      }) => void);
  @bindable() topElement: undefined | string | boolean | 'IF_QUERY' = undefined;
  @bindable() required: boolean;
  @bindable() disabled: boolean;

  displayName(item : StaalduinenPickupPointsQuery_staalduinenPickupPoints): string {

    if (item)
      return item.code + ' - ' + item.name;
    
    return "";
  } 

  query = gql`
    query StaalduinenPickupPointsQuery {
      staalduinenPickupPoints {
        code
        name
        staalduinenPickupPointId
      }
    }
  `;
}
