import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { mergeObjects, insertAllFetchedEntities } from '../../utility';
import { IBuid, IAsyncReducerState } from '../../interfaces';
import { getAllBuids, getAllAllowedBuids } from '../../actions';
import { createPendingEntity } from '../../types';

export interface IBuidReducerState extends IAsyncReducerState<IBuid> {
  allowedBuids: IBuid[];
}

const defaultState: IBuidReducerState = {
  allIds: undefined,
  byId: {},
  allowedBuids: []
};

const reducer = reducerWithInitialState(defaultState)
  .case(getAllBuids.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, buid => buid.buidId)
    )
  )
  .case(getAllBuids.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )

  .case(getAllAllowedBuids.done, (state, { result }) =>
    mergeObjects(state, { allowedBuids: result })
  );

export default reducer;
