import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { requests } from '../config';
import {
  fetchingAlarmsForSite,
  fetchingAlarmLogsAction,
  updateAlarm,
  fetchingAlarmRecipients,
  addAlarmRecipient,
  findAlarmRecipient,
  removeAlarmRecipient,
  createAlarm,
  updateAlarmRecipient,
  deleteAlarm,
  fetchingAllAlarms,
  fetchingAllCustomAndActive,
  acknowledgeAlarm
} from '../actions';

import {
  getAsyncEntity,
  getAsyncEntitiesByAsyncArray,
  mapFetchedAsyncEntity,
  isNone,
  getAsyncEntitiesByAsyncArrayMemoized
} from '../utility';

import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { IAlarm, IAlarmRecipient } from '../interfaces';

@autoinject()
export class AlarmService extends BaseService {
  constructor(protected httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(AlarmService.name), taskQueue);
  }

  getAll = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllAlarms(),
      fetchingAllAlarms,
      undefined,
      'Could not fetch all alarms',
      state =>
        getAsyncEntitiesByAsyncArray(state.alarms.allIds, state.alarms.byId)
    );

  getAllActiveCustomAlarms = () =>
    this.httpRequestWithDispatchFromState(
      requests.getAllActiveCustomAlarms(),
      fetchingAllCustomAndActive,
      undefined,
      'Could not fetch all custom and active alarms',
      state =>
        getAsyncEntitiesByAsyncArray(
          state.alarms.activeAndCustomAlarms,
          state.alarms.byId
        )
    );

  getAlarmsBySiteId = (siteId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getAlarmsBySiteId(siteId),
      fetchingAlarmsForSite,
      siteId,
      'Could not fetch alarms for site',
      state =>
        getAsyncEntitiesByAsyncArrayMemoized(
          getAsyncEntity(state.alarms.alarmsForSites, siteId),
          state.alarms.byId
        )
    );

  getAlarmLogEntriesForAlarm = (
    siteId: number,
    alarmId: number,
    fetchAll: boolean,
    useCache: boolean,
    since?: number
  ) =>
    this.httpRequestWithDispatchFromState(
      requests.alarmLogs(siteId, alarmId, fetchAll, since),
      fetchingAlarmLogsAction,
      { alarmId, fetchAll },
      'An unexpected error occurred trying to fetch the history. Try again in a while.',

      state => {
        if (!useCache) return;

        const alarmLogIds = getAsyncEntity(state.alarmLogs.byAlarmId, alarmId);

        const allFetchedAlarmLogs = getAsyncEntitiesByAsyncArray(
          alarmLogIds,
          state.alarmLogs.byId
        );

        if (!fetchAll)
          return mapFetchedAsyncEntity(allFetchedAlarmLogs, logs =>
            logs
              .filter(log => isNone(since) || log.alarmLogId > since)
              .slice(0, 10)
          );

        return state.alarmLogs.fetchedAllLogsForAlarms.some(
          cid => cid === alarmId
        )
          ? allFetchedAlarmLogs
          : undefined;
      }
    );

  updateAlarm = (
    siteId: number,
    alarm: IAlarm,
    recipients: IAlarmRecipient[]
  ) =>
    this.httpRequestWithDispatch(
      requests.updateAlarm(siteId, alarm, recipients),
      updateAlarm,
      alarm,
      'An unexpected error occured while trying to update the alarm'
    );

  acknowledgeAlarm = (siteId: number, alarm: IAlarm) =>
      this.httpRequestWithDispatch(
        requests.acknowledgeAlarm(siteId, alarm.alarmId),
        acknowledgeAlarm,
        { siteId, alarm },
        'An unexpected error occured while trying to acknowledge the alarm'
      );

  saveAlarm = (siteId: number, alarm: IAlarm, recipients: IAlarmRecipient[]) =>
    this.httpRequestWithDispatch(
      requests.saveAlarm(siteId, alarm, recipients),
      createAlarm,
      { siteId, alarm, recipients },
      'An unexpected error occured while trying to create the alarm'
    );

  deleteAlarm = (siteId: number, alarm: IAlarm) =>
    this.httpRequestWithDispatch(
      requests.deleteAlarm(siteId, alarm),
      deleteAlarm,
      { siteId, alarm },
      'An unexpected error occured while trying to delete the alarm'
    );

  getAlarmRecipientsForAlarm = (siteId: number, alarmId: number) =>
    this.httpRequestWithDispatchFromState(
      requests.getAlarmRecipients(siteId, alarmId),
      fetchingAlarmRecipients,
      { alarmId },
      'An unexpected error occurred trying to fetch the history. Try again in a while.',
      state =>
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(state.alarmRecipients.byAlarmId, alarmId),
          state.alarmRecipients.byId
        )
    );

  findUser = async (siteId: number, alarmId: number, query: string) =>
    this.httpRequestWithDispatch(
      requests.searchForAlarmRecipients(siteId, alarmId, query),
      findAlarmRecipient,
      { query },
      'Get all users failed'
    );

  removeRecipient = (siteId: number, alarmId: number, recipientId: number) =>
    this.httpRequestWithDispatch(
      requests.removeAlarmRecipient(siteId, alarmId, recipientId),
      removeAlarmRecipient,
      { alarmId, recipientId },
      'An unexpected error occurred trying to remove the recipient. Try again in a while.'
    );

  addExternalRecipient = (siteId: number, alarmId: number, email: string) =>
    this.addRecipient(siteId, alarmId, email, undefined);
  addInternalRecipient = (siteId: number, alarmId: number, userId: number) =>
    this.addRecipient(siteId, alarmId, undefined, userId);

  private addRecipient = (
    siteId: number,
    alarmId: number,
    externalEmail?: string,
    userId?: number
  ) =>
    this.httpRequestWithDispatch(
      requests.addAlarmRecipient(siteId, alarmId, externalEmail, userId),
      addAlarmRecipient,
      { alarmId, externalEmail, userId },
      'An unexpected error occurred trying to fetch the history. Try again in a while.'
    );

  updateRecipient = (
    siteId: number,
    alarmId: number,
    recipient: IAlarmRecipient
  ) =>
    this.httpRequestWithDispatch(
      requests.updateAlarmRecipient(siteId, alarmId, recipient),
      updateAlarmRecipient,
      recipient,
      'An unexpected error occurred trying to update the recipient. Try again in a while.'
    );

  testRecipient = (
    siteId: number,
    alarmId: number,
    recipient: IAlarmRecipient
  ) =>
    this.httpRequest(requests.testAlarmRecipient(siteId, alarmId, recipient));
}
