import { customElement, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import './columnfilternumber.css';
import { IFilterDefinition } from "$components/grid/grid";
import { ConditionalOperator, IFilterGroup, INumberFilter } from "$interfaces/iFilter";
import { isNone } from "$lib/helpers";
import { ensureNumber } from "$lib/numberHelpers";

type operations = 'between' | 'gt' | 'lt' | 'equals';
@customElement('column-filter-number')
export class ColumnFilterNumber {
    @bindable() filter: IFilterGroup | undefined;
    @bindable() definition: IFilterDefinition;
    @bindable() changedFilter: Function
    
    value1: number | undefined;
    value2: number | undefined;

    operation: operations = 'equals';

    constructor(private i18n: I18N) {}

    removeFilter(filterToRemove: INumberFilter, event: Event) {
        if(!this.filter) return;
        event.preventDefault();
        const newfilter: IFilterGroup = {...this.filter, filters: (this.filter.filters as INumberFilter[]).filter(f => f !== filterToRemove) };
        this.changedFilter && this.changedFilter({ newfilter })
    }

    validateValues(value1: number | string | undefined, value2: number | string | undefined, operation: operations): boolean {
        const value1IsNotDefined = isNone(value1) || value1.toString() === '';
        const value2IsNotDefined = isNone(value2) || value2.toString() === '';
        return operation === 'between' ? !value1IsNotDefined && !value2IsNotDefined : !value1IsNotDefined;
    }

    getNewFilter(): INumberFilter {
        if(!this.validateValues(this.value1, this.value2, this.operation) || isNone(this.value1))
            throw Error('Value1 or value2 is not defined.');
        switch(this.operation) {
            case 'equals':
                return { operator: ConditionalOperator.Equals, value: ensureNumber(this.value1), symbol: '=' };
            case 'gt':
                return { operator: ConditionalOperator.GreaterThan, value: ensureNumber(this.value1), symbol: '>' };
            case 'lt':
                return { operator: ConditionalOperator.LesserThan, value: ensureNumber(this.value1), symbol: '<' };
            case 'between':
                return { operator: ConditionalOperator.Between, value: ensureNumber(this.value1), secondValue: isNone(this.value2) ? undefined : ensureNumber(this.value2), symbol: '&&' }
        }
    }

    getTextForFilter(filter: INumberFilter){
        switch(filter.operator) {
            case ConditionalOperator.Between:
                return this.i18n.tr('UI_SiteList_Filter_Number_Between') + ` ${filter.value} and ${filter.secondValue}`;
            case ConditionalOperator.Equals:
                return this.i18n.tr('UI_SiteList_Filter_Number_Equals') +` ${filter.value}`;
            case ConditionalOperator.GreaterThan:
                return this.i18n.tr('UI_SiteList_Filter_Number_GreaterThan') + ` ${filter.value}`;
            case ConditionalOperator.LesserThan:
                return this.i18n.tr('UI_SiteList_Filter_Number_LessThan') + ` ${filter.value}`;
        }
    }

    addFilter(){
        const filter = this.getNewFilter();

        const newfilter: IFilterGroup = {
            ...(this.filter || { exclude: false, field: this.definition.property, type: 'number', filters: [], partialMatch: true }), 
            filters: this.filter ? [...(this.filter.filters as INumberFilter[]), filter] : [filter]
        };

        this.changedFilter && this.changedFilter({ newfilter })
        this.value1 = undefined;
        this.value2 = undefined;
    }
}