import {
  mergeObjects,
  replaceObjectInArray,
  insertAllFetchedEntities
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// import { getExportJob, exportJobPropertyChanged, exportFieldSelectedChanged, moveFieldUpAction, moveFieldDownAction,
//          exportSelectedCustomerChanged, exportSelectedBuidChanged, getExportJobsAsync } from '../../actions';
import {
  exportFieldSelectedChanged,
  exportSelectedCustomerChanged,
  exportSelectedBuidChanged,
  getExportJobsAsync
} from '../../actions';
import {
  IExportJob,
  IExportField,
  ICustomer,
  IBuid,
  IAsyncReducerState
} from '../../interfaces/index';
import { createPendingEntity } from '../../types';

export interface IExportJobReducer extends IAsyncReducerState<IExportJob> {
  loading: boolean;
  // editingExportJob?: IExportJob;
  // exportJobId: number;
  selectedCustomer?: ICustomer;
  customers?: ICustomer[];
  allCustomers?: ICustomer[];
  selectedBuid?: IBuid;
  exportFields?: IExportField[];
}

const defaultState: IExportJobReducer = {
  loading: true,
  // editingExportJob: undefined,
  // exportJobId: 0,
  exportFields: undefined,
  selectedCustomer: undefined,
  byId: {},
  allIds: undefined
};

// const swap = (fields: IExportField[], a: number, b: number) => {
//     const temp = fields[b];
//     fields.splice(b, 1, fields[a]);
//     fields.splice(a, 1, temp);
// };

// const moveFieldDown = (fields: IExportField[], field: IExportField) => {
//     for (let i = 0; i < fields.length - 1; i++)
//         if (fields[i].field === field.field) {
//             swap(fields, i, i + 1);
//             break;
//         }
//     return fields;
// };

// const moveFieldUp = (fields: IExportField[], field: IExportField) => {
//     for (let i = 1; i < fields.length; i++)
//         if (fields[i].field === field.field) {
//             swap(fields, i, i - 1);
//             break;
//         }
//     return fields;
// };

const reducer = reducerWithInitialState(defaultState)
  .case(getExportJobsAsync.done, (state, { result }) =>
    mergeObjects(
      state,
      insertAllFetchedEntities(state, result, e => e.exportJobId)
    )
  )
  .case(getExportJobsAsync.started, state =>
    mergeObjects(state, { allIds: createPendingEntity() })
  )
  // .case(exportJobPropertyChanged, (state, payload) => mergeObjects(state, { editingExportJob: mergeObjects(state.editingExportJob, { [payload.property]: payload.value }) }))
  // .case(moveFieldDownAction, (state, payload) => mergeObjects(state, { editingExportJob: mergeObjects(state.editingExportJob, { fields: moveFieldDown(state.editingExportJob!.fields, payload) })}))
  // .case(moveFieldUpAction, (state, payload) => mergeObjects(state, { editingExportJob: mergeObjects(state.editingExportJob, { fields: moveFieldUp(state.editingExportJob!.fields, payload) })}))
  .case(exportFieldSelectedChanged, (state, payload) =>
    mergeObjects(state, {
      exportFields: replaceObjectInArray(
        state.exportFields || [],
        f => f.name == payload.name,
        mergeObjects(payload, { selected: !payload.selected })
      )
    })
  )
  .case(exportSelectedCustomerChanged, (state, payload) =>
    mergeObjects(state, { selectedCustomer: payload.customer })
  )
  .case(exportSelectedBuidChanged, (state, payload) =>
    mergeObjects(state, {
      selectedBuid: payload.buid,
      customers: payload.buid
        ? state.allCustomers!.filter(c => c.buidId === payload.buid!.buidId)
        : state.allCustomers
    })
  );

export default reducer;
