import Worker from 'worker-loader!../workers/localsettings.worker';
import { getSettingsAction, saveSettingsAction } from '../workers/localsettings.worker';
import { isType } from 'typescript-fsa';

let _cache: Record<string,string> = {};
const subscriptions = new Map<string, Function[]>();

/**
 * The settings in this manager gets persisted to the currentuser's browser.
 * The settings is namespaced for the current user.
 */

const localSettingsWorker = new Worker();

export class LocalSettings {

    private static _userId: undefined | number;
    private static _encryptionKey: string | undefined;

    private static enqueueSaveOperation() {
        if(!this._userId || !this._encryptionKey) {
            throw new Error('No userId or encryptionkey is defined in LocalSettings. Is initializeLocalSettings called when app boots up?');
        }
        localSettingsWorker.postMessage(saveSettingsAction.started({ encryptionKey: this._encryptionKey, userId: this._userId, settings: _cache }));
        //localSettingsWorker.storeSettings(_cache, this._userId, this._encryptionKey);
    }

    static async initializeLocalSettings (userId: number, encryptionKey: string): Promise<void> {
        this._userId = userId;
        this._encryptionKey = encryptionKey;

        localSettingsWorker.postMessage(getSettingsAction.started({ userId, encryptionKey }));

        return new Promise((resolve) => {
            localSettingsWorker.onmessage = ((message: any) => {
                const { data } = message;
                if(isType(data, getSettingsAction.done)) {
                    if(data.payload.result) _cache = data.payload.result;
                    resolve();
                }
            });
        });
    }

    static getSetting<T extends string | undefined>(key: string, defaultvalue: T): T {
        return (_cache[key] || defaultvalue) as T;
    }

    static getSettingParsed<T>(key: string, defaultvalue: T) {
        const fromCache = _cache[key];
        if(!fromCache) return defaultvalue;
        return JSON.parse(fromCache) as T;
    }

    /**
     * 
     * @param key setting key to fetch
     * @param valueCallback Callback that are called each time the value updates
     * @returns An unsubscribe function to remove the callback from the subscriptions
     */
    static subscribeSetting(key: string, valueCallback: (val: string | undefined) => void): () => void {
        const valueNow = _cache[key];
        valueCallback(valueNow);
        const subs = subscriptions.get(key) || [];
        subscriptions.set(key, [...subs, valueCallback]);
        return () => {
            const subsafter = subscriptions.get(key) || [];
            subscriptions.set(key, subsafter.filter(s => s !== valueCallback));
        }
    }

    static getSettingAsBoolean(key: string, defaultvalue: boolean) {
        return JSON.parse(LocalSettings.getSetting(key, defaultvalue.toString())) as boolean;
    }

    static setSetting(key: string, value: string) {
        _cache[key] = value;
        const subs = subscriptions.get(key) || [];
        subs.forEach(i => i(value));
        this.enqueueSaveOperation();
    }
}
