import {
  ICurrentUser,
  ITokenPayload,
  ISession,
  FeatureAccess,
  AccessLevel
} from '../interfaces';
import { parseStringAsIntList, userHasFeature } from '../utility';
import { features } from '.';
import { defaultMemoize } from 'reselect';
import { IClaims } from '$interfaces/application/iClaims';

const defaultUser: ICurrentUser = {
  userId: 0,
  name: '',
  email: '',
  buidId: 0,
  avatarUrl: '/images/profile/default.jpg',
  username: '',
  clientEncryptionKey: undefined
};

const defaultSession: ISession = {
  userIsLoggedIn: false,
  currentUser: defaultUser,
  features: {
    delete_access: [],
    read_access: [],
    write_access: []
  },
  allowedBuids: [],
  securityLevel: 0,
  columns: [],
  allowedCustomers: [],
  auth_time: 0,
  language: '',
  onlyAccessToOneSiteId: '',
  logoutUrl: ''
};

let _session = defaultSession;

const getCurrentUserFromClaims = (claims: IClaims): ICurrentUser => ({
  username: claims.name,
  userId: parseInt(claims.sub, 10),
  allBuidAllowed: claims.allBuidAllowed === 'true',
  allCustomerAllowed: claims.allCustomerAllowed === 'true',
  isSuperUser: claims.role === 'superuser',
  securityLevel: +claims.securityLevel,
  buidId: claims.buidId ? +claims.buidId : 0,
  clientEncryptionKey: claims.clientEncryptionKey,
});

const getFeaturesFromToken = (token: ITokenPayload): FeatureAccess => ({
  write_access: parseStringAsIntList(token.fw),
  read_access: parseStringAsIntList(token.fr),
  delete_access: parseStringAsIntList(token.fd)
});

const getFeatureAccessesByFeature = (
  session: ISession,
  feature: number
): ICRUD => ({
  write: userHasFeature(session, feature, AccessLevel.Write),
  read: userHasFeature(session, feature, AccessLevel.Read),
  delete: userHasFeature(session, feature, AccessLevel.Delete)
});

/* export const setSession = (payload: ITokenPayload) => {
  const currentUser = getCurrentUserFromToken(payload);  

  _session = {
    userIsLoggedIn: true,
    currentUser,
    features: getFeaturesFromToken(payload),
    allowedBuids: parseStringAsIntList(payload.allowedBuids),
    securityLevel: parseInt(payload.securityLevel, 10),
    columns: parseStringAsIntList(payload.columns),
    allowedCustomers: parseStringAsIntList(payload.allowedCustomers),
    auth_time: payload.auth_time,
    language: payload.locale || '',
    onlyAccessToOneSiteId: payload.onlyAccessToOneSiteId,
    logoutUrl: 
  };
}; */

export const setSession = (claims: IClaims) => {
  const currentUser = getCurrentUserFromClaims(claims);

  _session = {
    userIsLoggedIn: true,
    currentUser,
    features: getFeaturesFromToken(claims),
    allowedBuids: parseStringAsIntList(claims.allowedBuids),
    securityLevel: parseInt(claims.securityLevel, 10),
    columns: parseStringAsIntList(claims.columns),
    allowedCustomers: parseStringAsIntList(claims.allowedCustomers),
    auth_time: claims.auth_time,
    language: claims.locale || '',
    onlyAccessToOneSiteId: claims.onlyAccessToOneSiteId,
    logoutUrl: claims.logoutUrl
  };

};

export const resetSession = () => (_session = defaultSession);

export const getSessionUserId = () => _session.currentUser.userId;

export const getSessionUser = () => _session.currentUser;

export const getSessionColumns = () => _session.columns;

export const getSession = () => _session;

export const getOnlyAccessToOneSiteId = () => getSession().onlyAccessToOneSiteId

export const getSessionClientEncryptionKey = () =>
  _session.currentUser.clientEncryptionKey;
export type UserFeatures = Record<keyof typeof features, ICRUD>;
const mapUserFeatures = defaultMemoize(
  (session: ISession): UserFeatures => ({
    alarms: getFeatureAccessesByFeature(session, features.alarms),
    allSiteNotes: getFeatureAccessesByFeature(session, features.allSiteNotes),
    controllerCommands: getFeatureAccessesByFeature(
      session,
      features.controllerCommands
    ),
    controllerLog: getFeatureAccessesByFeature(session, features.controllerLog),
    controllerManagement: getFeatureAccessesByFeature(
      session,
      features.controllerManagement
    ),
    controllerOutputCalibrations: getFeatureAccessesByFeature(
      session,
      features.controllerOutputCalibrations
    ),    
    controllerParameters: getFeatureAccessesByFeature(
      session,
      features.controllerParameters
    ),
    controllerProfiles: getFeatureAccessesByFeature(
      session,
      features.controllerProfiles
    ),
    detailedTankUsage: getFeatureAccessesByFeature(
      session,
      features.detailedTankUsage
    ),
    exportJobChannels: getFeatureAccessesByFeature(
      session,
      features.exportJobChannels
    ),
    exportManagement: getFeatureAccessesByFeature(
      session,
      features.exportManagement
    ),
    importManagement: getFeatureAccessesByFeature(
      session,
      features.importManagement
    ),
    manageCustomers: getFeatureAccessesByFeature(
      session,
      features.manageCustomers
    ),
    manageUsers: getFeatureAccessesByFeature(session, features.manageUsers),
    ownSiteNotes: getFeatureAccessesByFeature(session, features.ownSiteNotes),
    productEditor: getFeatureAccessesByFeature(session, features.productEditor),
    publicFilters: getFeatureAccessesByFeature(session, features.publicFilters),
    qrApp: getFeatureAccessesByFeature(session, features.qrApp),
    replaceController: getFeatureAccessesByFeature(
      session,
      features.replaceController
    ),
    reportsDeviceType: getFeatureAccessesByFeature(
      session,
      features.reportsDeviceType
    ),
    reportsProductStock: getFeatureAccessesByFeature(
      session,
      features.reportsProductStock
    ),
    reportNitratesMonthly: getFeatureAccessesByFeature(
      session, 
      features.reportNitratesMonthly
    ),
    reportsAlarm: getFeatureAccessesByFeature(
      session,
      features.reportsAlarm
    ),
    userStatisticsReport: getFeatureAccessesByFeature(
      session,
      features.userStatisticsReport
    ),
    customerManagerEditName: getFeatureAccessesByFeature(
      session,
      features.customerManagerEditName
    ),
    reportsNewSites : getFeatureAccessesByFeature(
      session,
      features.reportsNewSites
    ),
    reportDailyDose : getFeatureAccessesByFeature(
      session,
      features.reportDailyDose
    ),
    reportDosing : getFeatureAccessesByFeature(
      session,
      features.reportDosing
    ),
    reportsSiteRefills: getFeatureAccessesByFeature(
      session,
      features.reportsSiteRefills
    ),
    restrictedAttachments: getFeatureAccessesByFeature(
      session,
      features.restrictedAttachments
    ),
    routePlanning: getFeatureAccessesByFeature(session, features.routePlanning),
    deliveryNoteManager: getFeatureAccessesByFeature(session, features.deliveryNoteManager),
    siteDetails: getFeatureAccessesByFeature(session, features.siteDetails),
    siteDetailsChannels: getFeatureAccessesByFeature(
      session,
      features.siteDetailsChannels
    ),
    siteDetailsController: getFeatureAccessesByFeature(
      session,
      features.siteDetailsController
    ),
    siteEquipment: getFeatureAccessesByFeature(session, features.siteEquipment),
    siteGallery: getFeatureAccessesByFeature(session, features.siteGallery),
    siteTanks: getFeatureAccessesByFeature(session, features.siteTanks),
    staalduinenExport: getFeatureAccessesByFeature(
      session,
      features.staalduinenExport
    ),
    translations: getFeatureAccessesByFeature(session, features.translations),
    trendTool: getFeatureAccessesByFeature(session, features.trendTool),
    contactManager: getFeatureAccessesByFeature(
      session,
      features.contactManager
    ),
    idacs: getFeatureAccessesByFeature(session, features.idacs),
    stockVsDose: getFeatureAccessesByFeature(session, features.stockVsDose),
    reportsSitesStatus: getFeatureAccessesByFeature(
      session,
      features.reportsSitesStatus
    ),
    simManagement: getFeatureAccessesByFeature(session, features.simManagement),
    sapLookup: getFeatureAccessesByFeature(session, features.sapLookup),
    createChannel: getFeatureAccessesByFeature(session, features.createChannel),
    scheduledReports: getFeatureAccessesByFeature(
      session,
      features.scheduledReports
    ),
    sendPdiMasterData: getFeatureAccessesByFeature(session, features.sendPdiMasterData),
    rawGraphValues: getFeatureAccessesByFeature(session, features.rawGraphValues),
    graphSecurityLevels: getFeatureAccessesByFeature(session, features.graphSecurityLevels),
    easyFeedRecipe: getFeatureAccessesByFeature(session, features.easyFeedRecipe),
    requestRefill: getFeatureAccessesByFeature(session, features.requestRefill),
    dashboard: getFeatureAccessesByFeature(session, features.dashboard),
    createController: getFeatureAccessesByFeature(session, features.createController),
    unassignedDeliveryNotes: getFeatureAccessesByFeature(session, features.unassignedDeliveryNotes),
    decommissionedControllers: getFeatureAccessesByFeature(session, features.decommissionedControllers),
    haulierManager: getFeatureAccessesByFeature(session, features.haulierManager),
    releaseNoteEditor: getFeatureAccessesByFeature(session, features.releaseNoteEditor),
    liveMap: getFeatureAccessesByFeature(session, features.liveMap),
    dashboardWidgetNewSites: getFeatureAccessesByFeature(session, features.dashboardWidgetNewSites),
    dashboardWidgetLowestLevels: getFeatureAccessesByFeature(session, features.dashboardWidgetLowestLevels),
    dashboardWidgetNextReachMinimum: getFeatureAccessesByFeature(session, features.dashboardWidgetNextReachMinimum),
    dashboardWidgetRecentlyViewedSites: getFeatureAccessesByFeature(session, features.dashboardWidgetRecentlyViewedSites),
    dashboardWidgetDeliveriesLast: getFeatureAccessesByFeature(session, features.dashboardWidgetDeliveriesLast),
    dashboardWidgetDeliveriesYTD: getFeatureAccessesByFeature(session, features.dashboardWidgetDeliveriesYTD),
    dashboardWidgetDataSources: getFeatureAccessesByFeature(session, features.dashboardWidgetDataSources),
    dashboardWidgetUserStatistics: getFeatureAccessesByFeature(session, features.dashboardWidgetUserStatistics),
    dashboardWidgetControllers: getFeatureAccessesByFeature(session, features.dashboardWidgetControllers),
    dashboardWidgetLifecycle: getFeatureAccessesByFeature(session, features.dashboardWidgetLifecycle),
    reportTemperature: getFeatureAccessesByFeature(session, features.reportTemperature),
    reportIdaDeliveries : getFeatureAccessesByFeature(session, features.reportIdaDeliveries),
    reportIdaLifecycle : getFeatureAccessesByFeature(session, features.reportIdaLifecycle),
    reportDryRuns :  getFeatureAccessesByFeature(session, features.reportDryRuns),
    reportDryRunsLostSales: getFeatureAccessesByFeature(session, features.reportDryRunsLostSales),
    controllerUploadConfig: getFeatureAccessesByFeature(session, features.controllerUploadConfig),
    controllerFreeTextCommand: getFeatureAccessesByFeature(session, features.controllerFreeTextCommand),
    scriptConfigs: getFeatureAccessesByFeature(session, features.scriptConfigs),
  })
);

export const getUserFeatures = () => mapUserFeatures(getSession());

export interface ICRUD {
  write: boolean;
  delete: boolean;
  read: boolean;
}
