import actionCreatorFactory from 'typescript-fsa';
import { IProduct } from '../interfaces/entity/iProduct';
import { ProductEditorDetailsQuery_product } from '../../custom_typings/graphql';

const actionCreator = actionCreatorFactory('product');

export const getProducts = actionCreator.async<{}, IProduct[]>('getProducts');

export const updateProduct = actionCreator.async<
  ProductEditorDetailsQuery_product,
  void
>('updateProduct');
