import { customElement } from 'aurelia-framework';
import './install-to-device-prompt.css';
import cookie from 'js-cookie';
import { ConsentKeys } from '../../config';

const isiOSInStandalone = () => {
  const ua = window.navigator.userAgent;
  const isiOS = /iphone|ipad|ipod/i.test(ua);
  const isiPadOS =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
  const isStandalone =
    "standalone" in window.navigator && window.navigator.standalone;
  const isSafari = ua.match(/WebKit/i) && (!ua.match(/CriOS|FxiOS|EdgiOS/i));
  return (isiOS || isiPadOS) && isSafari && !isStandalone;
}

const isAndroidInStandalone = () => {
  const ua = window.navigator.userAgent;
  const isAndroid = /android/i.test(ua);
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  return isAndroid && !isStandalone;
}

@customElement('install-to-device-prompt')
export class InstallToDevicePrompt {
  beforeinstallpromptEvent: any;
  
  showiOSMessage: boolean = false;
  showAndroidMessage: boolean = false;
  showInstallPrompt: boolean = false;
  
  hasHiddenInstallPrompt: boolean = false;

  bind() {
    this.hasHiddenInstallPrompt = cookie.get(ConsentKeys.HideInstallPrompt) === 'true';
  }
  
  setHideInstallPromptCookie() {
    if (cookie.get(ConsentKeys.Cookies) === 'true') {
      cookie.set(ConsentKeys.HideInstallPrompt, 'true', { expires: 7 });
    }
  }

  promptInstallToDevice() {
    this.beforeinstallpromptEvent?.prompt?.();
    
    this.beforeinstallpromptEvent.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          this.hideInstallBanner();
        } else {
          this.hideInstallBanner(false)
        }
        this.beforeinstallpromptEvent = null;
      });
  }

  hideInstallBanner(setCookie: boolean = true) {
    this.showInstallPrompt = false;
    if (setCookie) this.setHideInstallPromptCookie();
  }

  hideIOSMessage() {
    this.showiOSMessage = false;
    this.setHideInstallPromptCookie();
  }

  hideAndroidChromeMessage() {
    this.showAndroidMessage = false;
    this.setHideInstallPromptCookie();
  }

  attached() {
    if (this.hasHiddenInstallPrompt) {
      return;
    }
    // iOS
    const isIOS = isiOSInStandalone();
    const isAndroid = isAndroidInStandalone();
    if (isIOS) {
      this.showiOSMessage = true;
    } else {
      if (isAndroid) { // Android
        this.showAndroidMessage = true;
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          this.beforeinstallpromptEvent = e;
          this.showAndroidMessage = false; // hide if event is fired
          this.showInstallPrompt = true;
        });
      }
    }
  }
}
