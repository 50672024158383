import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { requests } from '../config';
import { getProducts, updateProduct } from '../actions/product';
import { BaseService } from './baseService';
import { getEntityOrUndefined } from '../utility/index';
import { selectProducts } from '../pages/common';
import { ProductEditorDetailsQuery_product } from '../../custom_typings/graphql';

@autoinject
export class ProductService extends BaseService {
  constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
    super(httpClient, getLogger(ProductService.name), taskQueue);
  }

  getAllAsync = async () =>
    this.httpRequestWithDispatch(
      requests.getProducts(),
      getProducts,
      {},
      'Get all products failed',
      state => getEntityOrUndefined(selectProducts(state))
    );

  getAll = () =>
    this.httpRequestWithDispatchFromState(
      requests.getProducts(),
      getProducts,
      {},
      'Get all products failed',
      selectProducts
    );

  update = async (product: ProductEditorDetailsQuery_product) =>
    this.httpRequestWithDispatch(
      requests.updateProduct(product),
      updateProduct,
      product,
      'Update product failed.'
    );
}
