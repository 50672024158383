import {
  getDeviceTypeReport,
  reportBuidFilterChange,
  reportsSortingChangedSiteRefills,
  getAllControllerTypes,
  reportControllerTypeFilterChange,
  reportSiteFilterChange,
  getAllSiteFilters,
  getRefillsForPeriodReport,
  reportSiteRefillsFromDateChange,
  reportSiteRefillsToDateChange,
  reportFilterChanged,
  reportsSortingChangedDeviceType,
  reportSoldToFilterChanged
} from '../../actions';
import { mergeObjects } from '../../utility';
import {
  IReportDeviceType,
  IReportControllerType,
  IReportSiteFilter,
  IReportRefillsForPeriod,
  IBuid
} from '../../interfaces';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export interface IReportReducerState {
  sortPropertyDeviceType: string;
  sortDirectionDeviceType: string;
  sortPropertySiteRefills: string;
  sortDirectionSiteRefills: string;
  deviceTypesPerBuid: IReportDeviceType[];
  controllerTypes: IReportControllerType[];
  buidFilter: IBuid;
  controllerTypeFilter: IReportControllerType;
  refillsForPeriod: IReportRefillsForPeriod[];
  siteFiltersDropdown: IReportSiteFilter[];
  siteFilter: IReportSiteFilter;
  soldToFilter: string;
  filterChanged: boolean;
  refillsFromDate: Date;
  refillsToDate: Date;
  requesting: boolean;
}

const setDateTimeZero = (date: Date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};

const setDateTimeMax = (date: Date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
};

const defaultBuid: IBuid = { buidId: 0, name: '', maingateCostCenterId: '', pdiMasterDataExportEnabled: false };
const defaultSiteFilter: IReportSiteFilter = { siteId: 0, siteName: '' };
const defaultControllerType: IReportControllerType = { name: '-- Select --' };
const dateNow = new Date();
const dateFrom = new Date(dateNow);
dateFrom.setDate(dateFrom.getDate() - 30);

const defaultState: IReportReducerState = {
  sortPropertyDeviceType: '',
  sortDirectionDeviceType: '',
  sortPropertySiteRefills: '',
  sortDirectionSiteRefills: '',
  deviceTypesPerBuid: new Array<IReportDeviceType>(),
  controllerTypes: new Array<IReportControllerType>(),
  buidFilter: defaultBuid,
  controllerTypeFilter: defaultControllerType,
  refillsForPeriod: new Array<IReportRefillsForPeriod>(),
  siteFiltersDropdown: new Array<IReportSiteFilter>(),
  siteFilter: defaultSiteFilter,
  filterChanged: false,
  refillsFromDate: setDateTimeZero(dateFrom),
  refillsToDate: setDateTimeMax(dateNow),
  soldToFilter: '',
  requesting: false
};

const reducer = reducerWithInitialState(defaultState)
  .case(getDeviceTypeReport.started, state =>
    mergeObjects(state, { requesting: true })
  )

  .case(getDeviceTypeReport.done, (state, payload) =>
    mergeObjects(state, {
      deviceTypesPerBuid: payload.result,
      requesting: false
    })
  )

  .case(getRefillsForPeriodReport.started, state =>
    mergeObjects(state, { requesting: true })
  )

  .case(getRefillsForPeriodReport.done, (state, payload) =>
    mergeObjects(state, { refillsForPeriod: payload.result, requesting: false })
  )

  .case(reportsSortingChangedSiteRefills, (state, payload) =>
    mergeObjects(state, {
      sortPropertySiteRefills: payload.property,
      sortDirectionSiteRefills: payload.direction
    })
  )

  .case(reportsSortingChangedDeviceType, (state, payload) =>
    mergeObjects(state, {
      sortPropertyDeviceType: payload.property,
      sortDirectionDeviceType: payload.direction
    })
  )

  .case(reportBuidFilterChange, (state, payload) =>
    mergeObjects(state, {
      buidFilter: payload !== undefined ? payload : defaultBuid
    })
  )

  .case(getAllControllerTypes.started, state =>
    mergeObjects(state, { requesting: true })
  )

  .case(getAllControllerTypes.done, (state, payload) =>
    mergeObjects(state, { controllerTypes: payload.result, requesting: false })
  )

  .case(getAllSiteFilters.started, state =>
    mergeObjects(state, { requesting: true })
  )

  .case(getAllSiteFilters.done, (state, payload) =>
    mergeObjects(state, {
      siteFiltersDropdown: payload.result,
      requesting: false
    })
  )

  .case(reportControllerTypeFilterChange, (state, payload) =>
    mergeObjects(state, { controllerTypeFilter: payload })
  )

  .case(reportSiteFilterChange, (state, payload) =>
    mergeObjects(state, {
      siteFilter: payload !== undefined ? payload : defaultSiteFilter
    })
  )

  .case(reportSiteRefillsFromDateChange, (state, payload) =>
    mergeObjects(state, { refillsFromDate: setDateTimeZero(payload) })
  )

  .case(reportSiteRefillsToDateChange, (state, payload) =>
    mergeObjects(state, { refillsToDate: setDateTimeMax(payload) })
  )

  .case(reportFilterChanged, (state, payload) =>
    mergeObjects(state, { filterChanged: payload })
  )

  .case(reportSoldToFilterChanged, (state, payload) =>
    mergeObjects(state, { soldToFilter: payload })
  );

export default reducer;
