import countries from './countries';

export const getCodes = (): string[] => countries.map(mapping => mapping.code);

export const getName = (code: string): string | undefined => {
  const preppedCode = code.trim().toLocaleLowerCase();
  const found = countries.find(
    c => c.code.toLocaleLowerCase().trim() == preppedCode
  );
  return found ? found.name : undefined;
};

export const getNames = (): string[] => countries.map(mapping => mapping.name);
