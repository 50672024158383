import CryptoJS from "crypto-js";

export interface ITextFilter {
  value: number | string;
  selected?: boolean;
}

export interface INumberFilter {
  value: number;
  operator: ConditionalOperator;
  secondValue?: number;
  symbol: string;
}

export interface IDateFilter {
  value: string | Date;
  operator: ConditionalOperator;
  secondValue?: string | Date;
  symbol: string;
}

export interface IBooleanFilter {
  value: boolean;
}

export interface IFilterGroup {
  field: string;
  type: string;
  filters: ITextFilter[] | INumberFilter[] | IDateFilter[] | IBooleanFilter[];
  exclude: boolean;
  partialMatch?: boolean;
  showEmpty?: boolean;
}

export enum ConditionalOperator {
  Equals = 0,
  GreaterThan = 1,
  LesserThan = 2,
  Between = 3
}

export interface IApplyFilterPayload {
  filterType: string;
  field: string;
  filters: ITextFilter[] | INumberFilter[] | IDateFilter[];
  exclude: boolean;
}

export interface IFilterDTO {
  name: string;
  buidId?: number;
  customerId?: number;
  definition: string;
  isPublic: boolean;
  filterId: number;

  enabled: boolean;
}

/**
 * Creates a hash for comparing IFilterGroups with each other. 
 * Should be more precise than JSON.stringify, where the order of child objects and
 * properties can be anything (non-deterministic).
 * @param fg 
 * @returns hex hash string if successfull, or "0" if the parameter was null | undefined
 */
export function hashFilterGroup(fg?: IFilterGroup | null): string {
  if (fg === undefined || fg === null) return "0";

  let filterGroupStringTerms = [ ...fg.filters ].map(stringifyFilter).sort();
  
  filterGroupStringTerms.unshift(`${fg.field}|${fg.type}|${fg.exclude}|${fg.partialMatch}|${fg.showEmpty}`);

  const filterGroupAsString = filterGroupStringTerms.join("|")

  const hash = CryptoJS.SHA1(filterGroupAsString);
  return hash.toString(CryptoJS.enc.Hex);
}

/**
 * JSON.stringify makes no guarantees about the order of fields in the generated JSON. This has been an 
 * actual problem in YT3. This function stringifies IFilterGroup filters for hashing, in a simple way.
 */
function stringifyFilter(filter: any) : string {
  return `${filter.value}|${filter.selected}|${filter.operator}|${filter.secondValue}|${filter.symbol}`;
}
