import {
  postNewNoteAction,
  deleteNoteAction,
  getNotesForSite,
  getAllDisplayNotes,
  updateNoteAction,
  deleteSite,
  deleteMultipleSites
} from '../../actions';
import { INote, IAsyncReducerState } from '../../interfaces';
import {
  mergeObjects,
  insertFetchedEntities,
  removeAsyncObject,
  insertFetchedEntity,
  setDoneEntityInDictionary,
  toObjectAndMap,
  getAsyncEntity,
  getAsyncEntitiesByAsyncArray,
  asyncEntityIsFetched,
  getEntity,
  isNone,
  mergeDoneEntitiesInDictionary,
  distinct,
  mapFetchedAsyncEntity,
  getEntityOrDefault,
  removeEntityInDictionary,
  removeAsyncObjects,
  removeNoneFromArray,
  isSomething,
  removeAsyncEntityInDictionary
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IAsyncDictionary, createFetchedEntity } from '../../types';

export interface INoteReducerState extends IAsyncReducerState<INote> {
  notesForSites: IAsyncDictionary<number[]>;
  displayNoteForSites: IAsyncDictionary<number | undefined>;
  allDisplayNotesAreFetched: boolean;
  allDisplayNoteIds: number[];
}

const defaultState: INoteReducerState = {
  byId: {},
  allIds: undefined,
  notesForSites: {},
  displayNoteForSites: {},
  allDisplayNoteIds: [],
  allDisplayNotesAreFetched: false
};

export const isDisplayNote = (note: INote) => note.noteType === 2;

const wasDisplayNote = (state: INoteReducerState, noteId: number): boolean =>
  getEntityOrDefault(
    mapFetchedAsyncEntity(getAsyncEntity(state.byId, noteId), isDisplayNote),
    false
  );

const revertPreviousDisplayNote = (
  state: INoteReducerState,
  siteId: number
): INoteReducerState => {
  const siteNotesForSite = getAsyncEntitiesByAsyncArray(
    getAsyncEntity(state.notesForSites, siteId),
    state.byId
  );
  if (!asyncEntityIsFetched(siteNotesForSite)) return state;
  const previousDisplayNotes = getEntity(siteNotesForSite).filter(
    n => n.noteType === 2
  );
  if (isNone(previousDisplayNotes)) return state;
  return mergeObjects(state, {
    byId: mergeDoneEntitiesInDictionary(
      state.byId,
      previousDisplayNotes.map(n => n.noteId!),
      n => mergeObjects(n, { noteType: 0 })
    )
  });
};

const removeSiteNotesForSite = (state: INoteReducerState, siteId: number) => {
  const siteNotesForSite = getEntityOrDefault(
    getAsyncEntitiesByAsyncArray(
      getAsyncEntity(state.notesForSites, siteId),
      state.byId
    ),
    undefined
  );

  return isSomething(siteNotesForSite)
    ? mergeObjects(
        state,
        removeAsyncObjects(
          state,
          removeNoneFromArray(siteNotesForSite.map(notes => notes.noteId))
        ),
        {
          notesForSites: mergeObjects(
            state.notesForSites,
            removeAsyncEntityInDictionary(state.notesForSites, siteId)
          ),
          displayNoteForSites: mergeObjects(
            state.displayNoteForSites,
            removeAsyncEntityInDictionary(state.displayNoteForSites, siteId)
          )
        }
      )
    : state;
};

const reducer = reducerWithInitialState(defaultState)
  .case(deleteSite.done, (state, payload) =>
    removeSiteNotesForSite(state, payload.params.siteId)
  )

  .case(deleteMultipleSites.done, (state, payload) => {
    let mappedState = mergeObjects(state);

    payload.params.siteIds.forEach(
      id => (mappedState = removeSiteNotesForSite(mappedState, id))
    );

    return mappedState;
  })

  .case(getAllDisplayNotes.done, (state, { result }) =>
    mergeObjects(
      state,
      insertFetchedEntities(state, result, n => n.noteId || 0),
      {
        allDisplayNotesAreFetched: true,
        allDisplayNoteIds: result.map(n => n.noteId || 0),
        displayNoteForSites: mergeObjects(
          state.displayNoteForSites,
          toObjectAndMap(
            result,
            n => n.siteId,
            n => createFetchedEntity(n.noteId || 0)
          )
        )
      }
    )
  )

  .case(deleteNoteAction.done, (state, { params }) =>
    mergeObjects(state, removeAsyncObject(state, params.noteId), {
      allDisplayNoteIds: state.allDisplayNoteIds
        ? state.allDisplayNoteIds.filter(id => id !== params.noteId)
        : undefined,
      notesForSites: setDoneEntityInDictionary(
        state.notesForSites,
        params.siteId,
        ids => ids.filter(id => id !== params.noteId)
      )
    })
  )

  .cases(
    [postNewNoteAction.done, updateNoteAction.done],
    (state, { params, result }) =>
      mergeObjects(
        state,
        insertFetchedEntity(
          isDisplayNote(result)
            ? revertPreviousDisplayNote(state, result.siteId)
            : state,
          result.noteId!,
          result
        ),
        {
          notesForSites: setDoneEntityInDictionary(
            state.notesForSites,
            params.siteId,
            ids => distinct([...ids, result.noteId!])
          ),
          displayNoteForSites: isDisplayNote(result)
            ? mergeObjects(state.displayNoteForSites, {
                [params.siteId]: createFetchedEntity(result.noteId || 0)
              })
            : wasDisplayNote(state, result.noteId || 0)
            ? removeEntityInDictionary(state.displayNoteForSites, params.siteId)
            : state.displayNoteForSites
        }
      )
  )

  .case(getNotesForSite.done, (state, { result, params }) =>
    mergeObjects(state, insertFetchedEntities(state, result, n => n.noteId!), {
      notesForSites: mergeObjects(state.notesForSites, {
        [params.siteId]: createFetchedEntity(result.map(note => note.noteId!))
      })
    })
  );

export default reducer;
