import { orderBy } from '../utility/sorting';

export class orderByValueConverter {
  toView(array: Array<{}>, property: string, direction: string) {
    if (array === undefined || array == null || property === undefined) {
      return array;
    }
    return orderBy(array, property, direction);
  }
}
