import { IFilterDefinition } from "$components/grid/grid";
import { IFilterGroup, ITextFilter } from "$interfaces/iFilter";
import { isTouchDeviceWatcher } from "$lib/deviceHelpers";
import { isEmpty } from "$lib/helpers";
import { bindable, computedFrom, customElement } from "aurelia-framework";

@customElement('column-filter-string')
export class ColumnFilterString {
    @bindable({ changeHandler: 'filterChanged' }) filter: IFilterGroup | undefined;
    @bindable() definition: IFilterDefinition;
    @bindable() changedFilter: Function;
    
    autofocus: boolean = false;
    private unsubscribeWatcher: Function; 

    constructor() {
        this.unsubscribeWatcher = isTouchDeviceWatcher((isTouchDevice: boolean) => {
            this.autofocus = !isTouchDevice;
        });        
    }

    detached() {
        this.unsubscribeWatcher();
    }

    textQuery = '';

    @computedFrom('textQuery')
    get rowsInTextArea() {
        const numberOfNewlines = (this.textQuery && this.textQuery.match(/\n/g)?.length) || 0;
        return Math.min(4, numberOfNewlines + 1);
    }

    removeFilter(filterToRemove: ITextFilter, event: Event) {
        if(!this.filter) return;
        event.preventDefault();
        const newfilter: IFilterGroup = {...this.filter, filters: (this.filter.filters as ITextFilter[]).filter(f => f !== filterToRemove) };
        this.changedFilter && this.changedFilter({ newfilter })
    }

    exactMatch: boolean = false;

    addFilter(){
        if(!this.textQuery) return;
        const filters = this.textQuery.split('\n')
            .filter(query => !isEmpty(query))
            .map<ITextFilter>(value => ({ value, selected: true }));

        const newfilter: IFilterGroup = {
            ...(this.filter || { exclude: false, field: this.definition.property, type: 'string', filters: [], partialMatch: !this.exactMatch }), 
            filters: this.filter ? [...(this.filter.filters as ITextFilter[]), ...filters] : filters
        };

        this.changedFilter && this.changedFilter({ newfilter })
        this.textQuery = '';
    }

    exactMatchToggled(exactMatch: boolean, event: Event): void {
        this.exactMatch = exactMatch;

        if (!this.filter) return;
        if (event) event.preventDefault();
        const newfilter: IFilterGroup = ({ ...this.filter, partialMatch: !this.exactMatch });
        this.changedFilter && this.changedFilter({ newfilter });
    }

    filterChanged(newValue: IFilterGroup | undefined): void {
        this.exactMatch = (newValue !== undefined ? !newValue.partialMatch : false);
    }
}