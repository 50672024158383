import { bindable, computedFrom, PLATFORM } from 'aurelia-framework';
import './icon.css';
import 'font-awesome/css/font-awesome.css';

export class IconCustomElement {
  @bindable name: string;
  @bindable title: string;

  @computedFrom('name')
  get customComponent(): string | undefined {
    switch(this.name) {
      case 'arrow-right':
        return PLATFORM.moduleName('../svg/arrow-right.html');
      case 'muted':
        return PLATFORM.moduleName('../svg/muted.html');
      case 'volume-up':
        return PLATFORM.moduleName('../svg/volume-up.html');
      case 'archive':
        return PLATFORM.moduleName('../svg/archive.html');
      case 'file':
        return PLATFORM.moduleName('../svg/file.html');
      case 'file-text':
        return PLATFORM.moduleName('../svg/file-text.html');
      case 'image':
        return PLATFORM.moduleName('../svg/image.html');
      case 'columns':
        return PLATFORM.moduleName('../svg/columns.html');
      case 'download':
        return PLATFORM.moduleName('../svg/download.html');
      case 'signal-good':
        return PLATFORM.moduleName('../svg/signal-good.html');
      case 'signal-poor':
        return PLATFORM.moduleName('../svg/signal-poor.html');
      case 'signal-bad':
        return PLATFORM.moduleName('../svg/signal-bad.html');
      case 'offline-cross':
        return PLATFORM.moduleName('../svg/cross.html');
      case 'online-tick': 
        return PLATFORM.moduleName('../svg/tick.html');
      case 'parked':
        return PLATFORM.moduleName('../svg/parked.html');
      case 'parked-p':
        return PLATFORM.moduleName('../svg/parked-p.html');
      case 'bell':
        return PLATFORM.moduleName('../svg/bell.html');
      case 'menu':
        return PLATFORM.moduleName('../svg/menu.html');
      case 'alert-triangle':
        return PLATFORM.moduleName('../svg/alert-triangle.html');
      case 'virtual':
        return PLATFORM.moduleName('../svg/virtual.html');
      case 'bag':
        return PLATFORM.moduleName('../svg/bag.html');
      case 'map':
        return PLATFORM.moduleName('../svg/map.html');
      case 'cloud':
        return PLATFORM.moduleName('../svg/cloud.html');
      case 'bar-chart':
        return PLATFORM.moduleName('../svg/bar-chart.html');
      case 'bar-chart-2':
        return PLATFORM.moduleName('../svg/bar-chart-2.html');
      case 'ios-share':
        return PLATFORM.moduleName('../svg/ios-share.html');
      case 'ios-home-screen':
        return PLATFORM.moduleName('../svg/ios-home-screen.html');
    }
    return undefined;
  }
}
