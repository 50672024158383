import * as Sentry from '@sentry/browser';
import { Logger, Appender } from 'aurelia-logging';
import { ICurrentUser } from '../interfaces';

export class SentryAppender implements Appender {

  private NO_LOGSTRING_GIVEN = 'No logstring given';
  constructor(options: Sentry.BrowserOptions) {
    Sentry.init(options);
  }

  error(logger: Logger, ...rest: any[]): void {
    const err = this.getLogError(rest);
    if (err) Sentry.captureException(err,);
    else this.logMessage(logger, Sentry.Severity.Error, rest);
  }

  info(logger: Logger, ...rest: any[]): void {
    this.logMessage(logger, Sentry.Severity.Info, rest);
  }

  warn(logger: Logger, ...rest: any[]): void {
    this.logMessage(logger, Sentry.Severity.Warning, rest);
  }

  debug(logger: Logger, ...rest: any[]): void {
    this.logMessage(logger, Sentry.Severity.Debug, rest);
  }

  private logMessage(
    _logger: Logger,
    level: Sentry.Severity,
    argumentsArray: unknown[]
  ): void {
    const message = this.getLogMessage(argumentsArray);
    Sentry.captureMessage(
      message,
      {
        level,
      }
    );
  }

  setUserContext(userContext: ICurrentUser) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: userContext.userId.toString(),
        name: userContext.username,
      })
    })
  }

  private getLogMessage(rest: any[]): string {
    return (
      (rest.find(argument => typeof argument === 'string') as string) ||
      this.NO_LOGSTRING_GIVEN
    );
  }

  private getLogError(rest: any[]): Error | void {
    return rest.find(argument => argument instanceof Error) as Error;
  }

}
