import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  editSiteDetailsMap,
  editSiteAddressPropertyForMap,
  editMapMarkerProperty,
  persistMapMarkerChanges,
  updateMarkerLocationFromCurrentPosition,
  updateSiteAddressForMap,
  updateMapMarker,
  cancelSiteAddressForMapEdit,
  setSiteMapMarkerMode,
  setSiteMapMarkerModeForSite,
  confirmMapMarkerMode,
  navigateToRoute,
  updateMarkerLocationFromControllerPosition
} from '../../actions';
import {
  mergeObjects,
  isNone,
  appendOrReplaceObjectInArray,
  emptyArray
} from '../../utility';
import {
  ISiteDetailsMapAddress,
  ISite,
  IControllerMapMarker,
  ILatLng,
  IControllerMapMarkerWithAutoUpdate
} from '../../interfaces';

export type setMarkerMode =
  | undefined
  | { type: 'site'; position?: ILatLng; address?: ISiteDetailsMapAddress }
  | { type: 'marker'; controllerId: number; position?: IControllerMapMarker };

export interface ISiteDetailsMapReducerState {
  pendingSite?: Partial<ISite>;
  pendingMarkers?: Array<Partial<IControllerMapMarkerWithAutoUpdate>>;
  persisting: boolean;
  error?: string;
  setMarkerMode: setMarkerMode;
}

const defaultState: ISiteDetailsMapReducerState = {
  setMarkerMode: undefined,
  persisting: false
};

const removePendingChanges: Partial<ISiteDetailsMapReducerState> = {
  pendingMarkers: undefined,
  pendingSite: undefined,
  persisting: undefined,
  error: undefined,
  setMarkerMode: undefined
};

const reducer = reducerWithInitialState(defaultState)
  .cases(
    [setSiteMapMarkerMode, setSiteMapMarkerModeForSite.started],
    (state, setMarkerMode) => ({ ...state, setMarkerMode })
  )
  .case(setSiteMapMarkerModeForSite.done, (state, { params, result }) =>
    mergeObjects(state, {
      setMarkerMode:
        state.setMarkerMode === params
          ? mergeObjects(state.setMarkerMode, { address: result })
          : state.setMarkerMode
    })
  )
  .case(confirmMapMarkerMode, state =>
    isNone(state.setMarkerMode)
      ? state
      : {
          ...state,
          setMarkerMode: undefined,
          pendingSite:
            state.setMarkerMode.type !== 'site' || !state.setMarkerMode.address
              ? state.pendingSite
              : { ...state.pendingSite, ...state.setMarkerMode.address },
          pendingMarkers:
            state.setMarkerMode.type !== 'marker'
              ? state.pendingMarkers
              : appendOrReplaceObjectInArray(
                  state.pendingMarkers || emptyArray,
                  marker =>
                    !isNone(state.setMarkerMode) &&
                    state.setMarkerMode.type === 'marker' &&
                    state.setMarkerMode.controllerId === marker.controllerId,
                  {
                    controllerId:
                      !isNone(state.setMarkerMode) &&
                      state.setMarkerMode.type === 'marker'
                        ? state.setMarkerMode.controllerId
                        : undefined,
                    ...state.setMarkerMode.position,
                    autoUpdateLocation: false
                  }
                )
        }
  )
  .case(navigateToRoute, state => ({ ...state, ...removePendingChanges }))
  .case(editSiteDetailsMap, state => ({
    ...state,
    pendingMarkers: [],
    pendingSite: {},
    persisting: false
  }))
  .case(editSiteAddressPropertyForMap, (state, { property, value }) => ({
    ...state,
    pendingSite: { ...state.pendingSite, [property]: value }
  }))
  .case(updateSiteAddressForMap.done, (state, { result }) => ({
    ...state,
    pendingSite: { ...state.pendingSite, ...result }
  }))
  .case(editMapMarkerProperty, (state, { property, markerToEdit, value }) => ({
    ...state,
    pendingMarkers: appendOrReplaceObjectInArray(
      state.pendingMarkers || emptyArray,
      marker => marker.controllerId === markerToEdit.controllerId,
      { ...markerToEdit, autoUpdateLocation: false, [property]: value }
    )
  }))
  .cases(
    [updateMapMarker.done, updateMarkerLocationFromControllerPosition.done],
    (state, { params, result }) => ({
      ...state,
      pendingMarkers: appendOrReplaceObjectInArray(
        state.pendingMarkers || emptyArray,
        marker => marker.controllerId === params.controllerId,
        { ...params, ...result, autoUpdateLocation: false }
      )
    })
  )
  .case(persistMapMarkerChanges.started, state => ({
    ...state,
    persisting: true,
    error: undefined
  }))
  .cases(
    [persistMapMarkerChanges.done, cancelSiteAddressForMapEdit],
    state => ({ ...state, ...removePendingChanges })
  )
  .case(persistMapMarkerChanges.failed, (state, { error }) => ({
    ...state,
    error
  }))
  .case(
    updateMarkerLocationFromCurrentPosition.done,
    (state, { params, result }) => ({
      ...state,
      pendingMarkers: appendOrReplaceObjectInArray(
        state.pendingMarkers || emptyArray,
        marker => marker.controllerId === params.controllerId,
        { ...params, ...result, autoUpdateLocation: false }
      )
    })
  );

export default reducer;
