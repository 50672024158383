import {
  editChannel,
  navigateToRoute,
  editTankChannel,
  editChannelType,
  updateChannel,
  deleteSiteChannel,
  deleteChannel,
  createChannel,
  updateSiteChannel,
  createSiteChannelAndChannel
} from './../../actions';
import {
  IChannel,
  ITankDetails,
  IRetentionTimeDetails,
  IRequestState,
  IAggregatedTankUsageDetails,
  IDoseChannelDetails,
  IAggregatedDoseChannelDetails,
  ChannelTypes,
  IStockVsDoseAccuracyChannelDetails,
  TankPredictionEnum,
  IUnit
} from '../../interfaces';
import {
  mergeObjects,
  updateProperty,
  isNone,
  emptyObject,
  defaultRequest,
  requestLoading,
  requestFailed,
  isSomething,
  ensureNumber
} from '../../utility';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  selectedBuidForCreateSite,
  selectedCustomerForCreateSite,
  toggleEditControllerSerial,
  updateController,
  editChannelProductProperty,
  editRetentionTimeDetails,
  editRetentionTimeParameterIdDetails,
  changeSerialOnController,
  replacedControllerSerial,
  editAggregatedTankUsage,
  editStockVsDoseAccuracyChannelProperty,
  editDoseChannelProperty,
  editAggregatedDoseChannelProperty,
  selectedControllerChanged,
  validateChannel
} from '../../actions/controllers';
import actionCreatorFactory from 'typescript-fsa';
import { TankTypesEnum, OrderProcessEnum, unitSymbols } from '../../config';
import { SecurityLevel } from '../../interfaces/enums/securityLevel';
import { UnitDropdownFilter } from '../../components/dropdowns/unit-dropdown/unit-dropdown';

const actionCreator = actionCreatorFactory('channelEditor');

export const toggleConfirmDeleteChannel = actionCreator(
  'toggleConfirmDeleteChannel'
);

export interface IControllerManagerReducerState {
  editChannel: Partial<IChannel>;
  editTankDetails: Partial<ITankDetails>;
  editRetentionTimeDetails: Partial<IRetentionTimeDetails>;
  editAggregatedTankUsageDetails: Partial<IAggregatedTankUsageDetails>;
  editDoseDetails: Partial<IDoseChannelDetails>;
  editAggregatedDoseChannelDetails: Partial<IAggregatedDoseChannelDetails>;
  editStockVsDoseAccuracyChannelDetails: Partial<
    IStockVsDoseAccuracyChannelDetails
  >;
  selectedBuidIdForCreateSite: number | undefined;
  selectedCustomerIdForCreateSite: number | undefined;
  controllerTypeFilter: string | undefined;
  editSerial: boolean;
  deleteChannelConfirmation: boolean;
  editChannelRequest: IRequestState;
  channelValidationErrors: Record<string, string[]> | undefined;
  selectedSlave: Record<number, number | undefined>;
}

const defaultState: IControllerManagerReducerState = {
  editChannel: {},
  editTankDetails: {},
  editDoseDetails: {},
  editAggregatedDoseChannelDetails: {},
  editStockVsDoseAccuracyChannelDetails: {},
  editRetentionTimeDetails: {},
  editAggregatedTankUsageDetails: {},
  selectedBuidIdForCreateSite: undefined,
  selectedCustomerIdForCreateSite: undefined,
  controllerTypeFilter: undefined,
  editSerial: false,
  deleteChannelConfirmation: false,
  editChannelRequest: defaultRequest,
  channelValidationErrors: undefined,
  selectedSlave: {}
};

const defaultTankDetails = (): ITankDetails => ({
  tankId: 0,
  error: false,
  shipTo: '',
  tankType: TankTypesEnum.Inbound,
  noConsumption: false,
  percentage: 0,
  outsideThreshold: false,
  freeCapacity: 0,
  orderProcess: OrderProcessEnum.Auto,
  predictionType: TankPredictionEnum.TankCapacity
});

export const getUnitDropdownFilter = (
  channelType: ChannelTypes
): UnitDropdownFilter => {
  switch (channelType) {
    case ChannelTypes.Dose:
      return 'ONLY_INTERVAL';
    case ChannelTypes.Tank:
      return 'NO_INTERVAL';
    default:
      return undefined;
  }
};

/**
 * Replaces all generic Json.NET errors etc with one of our own language text keys
 */
const translateErrors = (
  errors: Record<string, string[]>
): Record<string, string[]> => {
  const badDoubleError =
    'UI_Common_ValidationError_Only_Positive_Numbers_Allowed';
  const genericJsonNetError = 'The input was not valid';
  const errorsCopy = { ...errors };

  for (const key of ['capacity', 'maximum', 'minimum']) {
    if (!errorsCopy[key]) continue;
    const errorIndex = errorsCopy[key].findIndex(x =>
      x.startsWith(genericJsonNetError)
    );
    if (errorIndex >= 0) errorsCopy[key][errorIndex] = badDoubleError;
  }
  return errorsCopy;
};

const validateCapacity = (capacity?: number) => {
  return isSomething(capacity) && capacity < 0
    ? ['UI_Common_ValidationError_Only_Positive_Numbers_Allowed']
    : [];
};

const validateMaximum = (
  maximum?: number,
  capacity?: number,
  minimum?: number
) => {
  if (isNone(maximum)) return [];
  if (maximum < 0)
    return ['UI_Common_ValidationError_Only_Positive_Numbers_Allowed'];
  else if (isSomething(capacity) && maximum > capacity)
    return ['UI_Channel_ValidationErrors_MaximumLargerThanCapacity'];
  else if (isSomething(minimum) && maximum < minimum)
    return ['UI_Channel_ValidationErrors_MaximumLessThanMinimum'];
  else return [];
};

const validateMinimum = (
  minimum?: number,
  capacity?: number,
  maximum?: number
) => {
  if (isNone(minimum)) return [];
  // if (minimum < 0)
  //   return ['UI_Common_ValidationError_Only_Positive_Numbers_Allowed'];
  // else 
  if (isSomething(capacity) && minimum > capacity)
    return ['UI_Channel_ValidationErrors_MinimumLargerThanCapacity'];
  else if (isSomething(maximum) && minimum > maximum)
    return ['UI_Channel_ValidationErrors_MinimumLargerThanMaximum'];
  else return [];
};

const toNumberOrUndefined = (value?: number | string) => {
  return isSomething(value) ? ensureNumber(value) : undefined;
};

const updateChannelValidationErrors = (
  channel: IChannel,
  channelValidationErrors: Record<string, string[]> | undefined
) => {
  if (isNone(channel)) return channelValidationErrors;

  const capacity = toNumberOrUndefined(channel.capacity);
  const maximum = toNumberOrUndefined(channel.maximum);
  const minimum = toNumberOrUndefined(channel.minimum);

  return {
    ...channelValidationErrors,
    capacity: validateCapacity(capacity),
    maximum: validateMaximum(maximum, capacity, minimum),
    minimum: validateMinimum(minimum, capacity, maximum)
  };
};

const correctUnitIdAfterChannelTypeSwap = (
  channelType: ChannelTypes,
  units: IUnit[],
  selectedUnitId: number | string | undefined
): number | string | undefined => {
  if (isNone(selectedUnitId)) return undefined;

  // Check whether change from an interval type to value type or visa-versa has been done.
  const unitFilter = getUnitDropdownFilter(channelType);
  const selectedUnit = units.find(u => u.unitId == selectedUnitId);
  if (!selectedUnit) return undefined;
  if (unitFilter === 'NO_INTERVAL' && selectedUnit.unitIdWithoutInterval)
    return undefined;
  if (unitFilter === 'ONLY_INTERVAL' && !selectedUnit.unitIdWithoutInterval)
    return undefined;
  return selectedUnit.unitId;
};

export default reducerWithInitialState(defaultState)
  .case(editChannelProductProperty, (state, product) =>
    mergeObjects(state, {
      editChannel: updateProperty(
        updateProperty(
          state.editChannel,
          'productId',
          product !== undefined ? product.productId : undefined
        ),
        'color',
        product !== undefined ? product.color : undefined
      )
    })
  )

  .case(editChannel, (state, { key, value }) => ({
    ...state,
    editChannel: updateProperty(state.editChannel, key, value),
    channelValidationErrors: {}
  }))

  .case(validateChannel, (state, channel) => ({
    ...state,
    channelValidationErrors: updateChannelValidationErrors(
      channel,
      state.channelValidationErrors
    )
  }))

  .case(toggleConfirmDeleteChannel, state =>
    updateProperty(
      state,
      'deleteChannelConfirmation',
      !state.deleteChannelConfirmation
    )
  )

  .cases([deleteChannel.started, deleteSiteChannel.started], state =>
    updateProperty(state, 'deleteChannelConfirmation', false)
  )

  .case(editChannelType, (state, { channel, channelType, units }) => {
    if (channelType === ChannelTypes.Tank && isNone(channel.tankDetails)) {
      const litersUnit = units.find(u => u.symbol == unitSymbols.liters);
      state = mergeObjects(state, {
        editTankDetails: defaultTankDetails(),
        editChannel: {
          ...state.editChannel,
          securityLevel: SecurityLevel.Low,
          unitId:
            correctUnitIdAfterChannelTypeSwap(
              channelType,
              units,
              state.editChannel.unitId
            ) ||
            (litersUnit && litersUnit.unitId)
        }
      });
    }
    if (
      channelType === ChannelTypes.AggregatedDose &&
      isNone(channel.aggregatedDoseChannelDetails)
    ) {
      state = {
        ...state,
        editChannel: {
          ...state.editChannel,
          unitId: correctUnitIdAfterChannelTypeSwap(
            channelType,
            units,
            state.editChannel.unitId
          )
        },
        editAggregatedDoseChannelDetails: {
          ...state.editAggregatedDoseChannelDetails,
          calibrationAlarmThreshold: 20,
          seriousCalibrationAlarmThreshold: 30
        }
      };
    }

    return mergeObjects(state, {
      editChannel: mergeObjects(state.editChannel, {
        channelType,
        unitId: correctUnitIdAfterChannelTypeSwap(
          channelType,
          units,
          state.editChannel.unitId
        )
      })
    });
  })
  .case(editTankChannel, (state, { key, value }) =>
    isNone(state.editTankDetails)
      ? state
      : mergeObjects(state, {
          editTankDetails: updateProperty(state.editTankDetails, key, value)
        })
  )
  .case(editRetentionTimeDetails, (state, { key, value }) => ({
    ...state,
    editRetentionTimeDetails: {
      ...state.editRetentionTimeDetails,
      [key]: value
    }
  }))
  .case(editRetentionTimeParameterIdDetails, (state, parameter) => ({
    ...state,
    editRetentionTimeDetails: {
      ...state.editRetentionTimeDetails,
      volume: parameter.value
    }
  }))
  .case(editAggregatedDoseChannelProperty, (state, { key, value }) => ({
    ...state,
    editAggregatedDoseChannelDetails: {
      ...state.editAggregatedDoseChannelDetails,
      [key]: value
    }
  }))
  .case(editAggregatedTankUsage, (state, { key, value }) => ({
    ...state,
    editAggregatedTankUsageDetails: {
      ...state.editAggregatedTankUsageDetails,
      [key]: value
    }
  }))
  .case(editDoseChannelProperty, (state, { key, value }) => ({
    ...state,
    editDoseDetails: {
      ...state.editDoseDetails,
      ...(key === 'addNewTrackedChannel' &&
      (!state.editDoseDetails.newTrackedChannelCalibrationAlarmThreshold ||
        !state.editDoseDetails
          .newTrackedChannelSeriousCalibrationAlarmThreshold)
        ? {
            newTrackedChannelCalibrationAlarmThreshold: 20,
            newTrackedChannelSeriousCalibrationAlarmThreshold: 30
          }
        : {}),
      [key]: value
    }
  }))
  .case(editStockVsDoseAccuracyChannelProperty, (state, { key, value }) => ({
    ...state,
    editStockVsDoseAccuracyChannelDetails: {
      ...state.editStockVsDoseAccuracyChannelDetails,
      [key]: value
    }
  }))
  .case(toggleEditControllerSerial, state =>
    mergeObjects(state, {
      editSerial: !state.editSerial
    })
  )
  .case(replacedControllerSerial, state => ({
    ...state,
    editSerial: !state.editSerial
  }))
  .case(navigateToRoute, state =>
    mergeObjects(state, {
      editChannel: emptyObject,
      editTankDetails: emptyObject,
      editRetentionTimeDetails: emptyObject,
      editAggregatedTankUsageDetails: emptyObject,
      editDoseDetails: emptyObject,
      editAggregatedDoseChannelDetails: emptyObject,
      editStockVsDoseAccuracyChannelDetails: emptyObject,
      editSerial: false,
      channelValidationErrors: undefined,
      editChannelRequest: undefined,
      selectedBuidIdForCreateSite: undefined,
      selectedCustomerIdForCreateSite: undefined
    })
  )
  .cases(
    [
      updateChannel.done,
      createChannel.done,
      updateSiteChannel.done,
      createSiteChannelAndChannel.done
    ],
    state =>
      mergeObjects(state, {
        editChannel: emptyObject,
        editTankDetails: emptyObject,
        editRetentionTimeDetails: emptyObject,
        editAggregatedTankUsageDetails: emptyObject,
        editDoseDetails: emptyObject,
        editAggregatedDoseChannelDetails: emptyObject,
        editStockVsDoseAccuracyChannelDetails: emptyObject
      })
  )

  .case(selectedControllerChanged, (state, action) => ({
    ...state,
    selectedSlave: {
      ...state.selectedSlave,
      [action.controllerId]: action.slaveNumber
    }
  }))

  .cases(
    [
      updateChannel.started,
      createChannel.started,
      updateSiteChannel.started,
      createSiteChannelAndChannel.started
    ],
    state => ({
      ...state,
      editChannelRequest: requestLoading()
    })
  )

  .cases(
    [
      updateChannel.failed,
      createChannel.failed,
      updateSiteChannel.failed,
      createSiteChannelAndChannel.failed
    ],
    (state, { error }) => ({
      ...state,
      editChannelRequest: requestFailed(typeof error === 'string' ? error : ''),
      channelValidationErrors:
        typeof error === 'string'
          ? state.channelValidationErrors
          : translateErrors(error)
    })
  )

  .case(updateController.done, state =>
    mergeObjects(state, {
      editTankDetails: emptyObject,
      editRetentionTimeDetails: emptyObject,
      editAggregatedTankUsageDetails: emptyObject,
      editDoseDetails: emptyObject,
      editAggregatedDoseChannelDetails: emptyObject,
      editStockVsDoseAccuracyChannelDetails: emptyObject,
      editChannelRequest: defaultRequest
    })
  )
  .case(selectedBuidForCreateSite, (state, selectedBuidIdForCreateSite) =>
    mergeObjects(state, { selectedBuidIdForCreateSite })
  )
  .case(
    selectedCustomerForCreateSite,
    (state, selectedCustomerIdForCreateSite) =>
      mergeObjects(state, { selectedCustomerIdForCreateSite })
  )
  .case(changeSerialOnController.done, state =>
    mergeObjects(state, {
      editSerial: false
    })
  );
