import { customElement, bindable } from 'aurelia-framework';
import './icon-clickable.css';
import { computedFrom } from 'aurelia-binding';
import { isNone } from '../../../utility';

@customElement('icon-clickable')
export class IconClickable {
  @bindable icon: string = '';
  @bindable size: 'medium' | 'small' = 'medium';
  @bindable title: string = '';
  @bindable notificationCount: number | undefined;

  @computedFrom('notificationCount')
  get computedCount(){
    if(isNone(this.notificationCount)) return this.notificationCount;

    if(this.notificationCount > 1000) return Math.floor(this.notificationCount / 1000) + 'k';
    return this.notificationCount;
  }
}
