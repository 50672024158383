import { bindable, inject, computedFrom } from 'aurelia-framework';
import { DOM } from 'aurelia-pal';
import './filter-widget.css';

@inject(DOM.Element)
export class FilterWidgetCustomElement {
  @bindable onBackdropClick: Function;
  @bindable showBackDrop: true;

  alignRight: boolean;
  maxHeight: number;

  /**
   *
   */
  constructor(private element: HTMLElement) {}

  close() {
    this.onBackdropClick();
  }

  attached() {
    const { right, top } = this.element.getBoundingClientRect();
    const element = document.documentElement;
    this.alignRight =
      right >= (window.innerWidth || (element && element.clientWidth) || 0);

    this.maxHeight = ((element && element.clientHeight) || 0) - top;
  }

  detached() {
    this.alignRight = false;
  }

  @computedFrom('maxHeight')
  get style() {
    return {
      'max-height': `${this.maxHeight}px`
    };
  }
}
