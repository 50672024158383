import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IAlarm, IUser, IAlarmRecipient } from '../../interfaces';
import {
  mergeObjects,
  appendOrReplaceObjectInArray,
  isNone,
  emptyArray,
  removeObjectFromArray,
  replaceObjectInArray
} from '../../utility';
import {
  editAlarm,
  alarmPropertyChanged,
  cancelEdit,
  updateAlarm,
  toggleAddRecipientDialog,
  changeRecipientType,
  externalRecipientAddressChanged,
  addAlarmRecipient,
  findAlarmRecipient,
  createAlarm,
  editExternalRecipient,
  recipientPropertyChanged,
  updateAlarmRecipient,
  addRecipient,
  navigateToRoute,
  removeRecipient,
  updateRecipient
} from '../../actions';

export interface ISiteDetailsAlarmsReducerState {
  currentlyEditingAlarm?: IAlarm;

  currentlyEditingAlarmRecipients?: IAlarmRecipient[];

  addRecipientDialogOpen: boolean;
  addRecipientType?: 'email' | 'user';

  externalRecipientAddress?: string;

  currentlyEditingRecipient?: IAlarmRecipient;

  alarmValidationErrors: Record<string, string[]> | undefined;

  userSearchQuery?: string;
  searchResults: IUser[];
  isSearching: boolean;
}

const defaultState: ISiteDetailsAlarmsReducerState = {
  addRecipientDialogOpen: false,
  searchResults: [],
  isSearching: false,
  alarmValidationErrors: undefined
};

const resetAddRecipient = () => ({
  addRecipientDialogOpen: false,
  externalRecipientAddress: undefined,
  addRecipientType: undefined,
  searchResults: []
});

const resetCreateAlarm = () => ({
  currentlyEditingAlarm: undefined,
  createAlarmRecipients: emptyArray,
  currentlyEditingRecipient: undefined,
  addRecipientType: undefined,
  externalRecipientAddress: undefined,

  currentlyEditingAlarmRecipients: undefined,
  alarmValidationErrors: undefined
});

const lowerCaseFirstLetter = (s: string): string => {
  return s.charAt(0).toLowerCase() + s.slice(1);
};

/**  Create a new record from an existing, that only contains properties starting with a prefix
 */
const getAllPropertiesStartingWith = (
  prefix: string,
  input: Record<string, string[]>
): Record<string, string[]> => {
  let output: Record<string, string[]> = {};

  Object.keys(input)
    .filter(x => x.startsWith(prefix))
    .forEach(inputKey => {
      let outputKey = lowerCaseFirstLetter(inputKey.substring(prefix.length));
      output[outputKey] = input[inputKey];
    });
  return output;
};

/**  Validation errors from server arrive in format { 'alarm.name': '..', 'alarm.x': '..', 'recipients.y': '..' } due to shape of EditAlarmDTO.
 * This function splits that into two objects, without the 'alarm' and 'recipients' prefixes.
 */

const extractValidationErrors = (
  validationErrors: Record<string, string[]>
): [Record<string, string[]>, Record<string, string[]>] => {
  return [
    getAllPropertiesStartingWith('alarm.', validationErrors),
    getAllPropertiesStartingWith('recipients.', validationErrors)
  ];
};

const reducer = reducerWithInitialState(defaultState)
  .case(addRecipient, (state, payload) => {
    const recipients =
      state.currentlyEditingAlarmRecipients || payload.existingRecipients;

    if (
      !isNone(payload.recipient.externalEmail) &&
      isNone(payload.recipient.userId)
    ) {
      return mergeObjects(
        state,
        {
          currentlyEditingAlarmRecipients: appendOrReplaceObjectInArray(
            recipients,
            x => x.externalEmail === payload.recipient.externalEmail,
            payload.recipient
          )
        },
        resetAddRecipient()
      );
    }

    return mergeObjects(
      state,
      {
        currentlyEditingAlarmRecipients: appendOrReplaceObjectInArray(
          recipients,
          x => x.userId === payload.recipient.userId,
          payload.recipient
        )
      },
      resetAddRecipient()
    );
  })

  .case(removeRecipient, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingAlarmRecipients: removeObjectFromArray(
        state.currentlyEditingAlarmRecipients || payload.existingRecipients,
        r => r.alarmRecipientId === payload.recipient.alarmRecipientId
      )
    })
  )

  .case(updateRecipient, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingAlarmRecipients: replaceObjectInArray(
        state.currentlyEditingAlarmRecipients || payload.existingRecipients,
        r => r.alarmRecipientId === payload.recipient.alarmRecipientId,
        payload.recipient
      ),
      currentlyEditingRecipient: undefined
    })
  )

  .case(updateAlarmRecipient.done, state =>
    mergeObjects(state, { currentlyEditingRecipient: undefined })
  )

  .case(recipientPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingRecipient: mergeObjects(state.currentlyEditingRecipient, {
        [payload.name]: payload.value
      })
    })
  )
  .case(editExternalRecipient, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingRecipient: payload
    })
  )

  .case(editAlarm, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingAlarm: payload
    })
  )

  .case(alarmPropertyChanged, (state, payload) =>
    mergeObjects(state, {
      currentlyEditingAlarm: mergeObjects(state.currentlyEditingAlarm, {
        [payload.name]: payload.value
      })
    })
  )

  .cases(
    [cancelEdit, navigateToRoute, updateAlarm.done, createAlarm.done],
    state => mergeObjects(state, resetCreateAlarm())
  )

  .cases([createAlarm.failed, updateAlarm.failed], (state, { error }) => {
    if (typeof error === 'string') return state;
    else {
      const split = extractValidationErrors(error);
      return {
        ...state,
        alarmValidationErrors: split[0]
      };
    }
  })

  .case(toggleAddRecipientDialog, state =>
    mergeObjects(state, {
      addRecipientDialogOpen: !state.addRecipientDialogOpen,
      addRecipientType: undefined,
      searchResults: [],
      userSearchQuery: ''
    })
  )

  .case(addAlarmRecipient.done, state =>
    mergeObjects(state, resetAddRecipient())
  )

  .case(changeRecipientType, (state, payload) =>
    mergeObjects(state, { addRecipientType: payload })
  ) // reset when recipient is added.

  .case(externalRecipientAddressChanged, (state, payload) =>
    mergeObjects(state, {
      externalRecipientAddress: payload
    })
  )

  .case(findAlarmRecipient.started, (state, payload) =>
    mergeObjects(state, {
      isSearching: true,
      userSearchQuery: payload.query
    })
  )

  .case(findAlarmRecipient.done, (state, payload) =>
    mergeObjects(state, {
      searchResults: payload.result,
      isSearching: false
    })
  );

export default reducer;
