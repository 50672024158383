import { customAttribute, autoinject, bindable, DOM } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { getNewId, isTouchDeviceWatcher } from '../utility';
import { TooltipEvent, tooltipEvent } from '../models/tooltip'
@customAttribute('tooltip')
@autoinject()
export class Tooltip {
    @bindable({ primaryProperty: true }) value: string | undefined | null;
    @bindable() placement: 'left' | 'top' = 'top';
    @bindable() placementElement: HTMLElement | undefined;

    id = getNewId()
    private unsubscribeWatcher: () => void | undefined;
    isTouchDevice: boolean;
    
    constructor(private element: Element, private eventAggregator: EventAggregator) {
        this.unsubscribeWatcher = isTouchDeviceWatcher((isTouchDevice: boolean) => {
            this.isTouchDevice = isTouchDevice;
        });
        this.onEnter = this.onEnter.bind(this);
        this.onLeave = this.onLeave.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    clickTriggered = false;

    mouseIsInsideElement = false;

    valueChanged(){
        if(this.mouseIsInsideElement)
        this.onEnter();
    }

    onEnter () {
        this.mouseIsInsideElement = true;
        if(!this.value) return;
        this.showTooltip();
        this.element.addEventListener('mouseleave', this.onLeave);
    }

    showTooltip () {
        if(!this.value) return;
        const attachedElement = (this.placementElement || this.element).getBoundingClientRect()
        const event: TooltipEvent = {
            type: 'show',
            id: this.id,
            properties: {
                attachedElement,
                placement: this.placement,
                text: this.value
            }
        }
        setTimeout(() => DOM.addEventListener('click', this.dismissTooltip, false));
        document.addEventListener('scroll', () => {
            this.dismissTooltip()
        }, true)
        if (this.isTouchDevice) { 
            setTimeout(() => this.dismissTooltip(), 2000);
        }
        this.eventAggregator?.publish(tooltipEvent, event)
    }

    onClick() {
        if (!this.value) return;
        this.clickTriggered = true;
        if(this.clickTriggered) this.dismissTooltip();
        this.showTooltip();
    }

    onLeave () {
        this.mouseIsInsideElement = false;
        if(this.clickTriggered) return;
        this.dismissTooltip()
    }

    attached() {
        this.element.addEventListener('mouseenter', this.onEnter)
        if (this.isTouchDevice)
            this.element.addEventListener('click', this.onClick);
    }
  
    detached() {
        this.element.removeEventListener('mouseenter', this.onEnter);
        this.element.removeEventListener('mouseleave', this.onLeave);
        this.element.removeEventListener('click', this.onClick);
        document.removeEventListener('scroll', this.dismissTooltip, true);
        this.unsubscribeWatcher && this.unsubscribeWatcher();
        this.dismissTooltip();
    }

    dismissTooltip () {
        this.clickTriggered = false;
        const event: TooltipEvent = {
            type: 'hide',
            id: this.id
        }
        this.eventAggregator?.publish(tooltipEvent, event)
        DOM.removeEventListener('click', this.dismissTooltip, false);
    }
}
