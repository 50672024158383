import { inject, bindable } from 'aurelia-framework';
import { ITextFilter, ISiteColumn } from '../../../interfaces';
import { FilterOnPropertyValueConverter } from './../../../value-converters/filterOnPropertyValueConverter';
import { orderByPredicate } from '../../../utility/index';
import './text-filter.css';

@inject(FilterOnPropertyValueConverter)
export class TextFilterCustomElement {
  // bindables - exposed properties
  @bindable inputText: string;
  @bindable buttonText: string;

  @bindable size: string = 'medium';

  @bindable items: Set<any>;
  @bindable currentFilters: any[];

  @bindable currentValue: string;
  @bindable column: ISiteColumn;

  @bindable onApplyFilter: Function;
  @bindable onCancelFilter: Function;

  @bindable applyOnClose: boolean;

  @bindable isExclude: boolean;

  @bindable startsWithMatch: boolean;

  // internal properties
  private internalItems: ITextFilter[] = new Array<ITextFilter>(); // used to preserve raw input.
  private filteredItems: ITextFilter[] = new Array<ITextFilter>(); // used to show active elements, might be narrowed down by filter.

  private isAllSelected: boolean;

  constructor(private filterOnProperty: FilterOnPropertyValueConverter) {}

  bind() {
    this.currentValue = '';
  }

  attached() {
    this.arrangeItems();
  }

  detached() {
    if (this.applyOnClose) this.apply();
  }

  itemsChanged() {
    this.arrangeItems();
  }

  arrangeItems() {
    if (!this.items) {
      this.filteredItems = [];
      return;
    }

    this.internalItems = new Array<ITextFilter>();

    if (this.currentFilters) {
      this.internalItems = this.currentFilters;
    } else {
      this.items.forEach((item: string) => {
        let filter = { value: item } as ITextFilter;
        filter.selected = false;
        this.internalItems.push(filter);
      });
    }

    this.filteredItems = orderByPredicate(
      this.internalItems,
      item => item.value,
      'asc'
    );

    if (this.currentValue)
      this.filteredItems = this.filterOnProperty.toView(
        this.filteredItems,
        'value',
        this.currentValue.trim(),
        this.startsWithMatch
      ) as ITextFilter[];
  }

  currentValueChanged(value: string): void {
    this.filteredItems = this.filterOnProperty.toView(
      this.internalItems,
      'value',
      value.trim(),
      this.startsWithMatch
    ) as ITextFilter[];
  }

  toggleSelectAll() {
    if (!this.isAllSelected) {
      this.selectAll();
      this.isAllSelected = true;
    } else {
      this.clearAll();
      this.isAllSelected = false;
    }
  }

  toggleExclude() {
    this.isExclude = !this.isExclude;
  }

  selectAll(): void {
    this.filteredItems.forEach(item => (item.selected = true));
  }

  clearAll(): void {
    this.filteredItems.forEach(item => (item.selected = false));
  }

  reset() {
    this.clearAll();
    this.apply();
  }

  cancel() {
    if (this.onCancelFilter) this.onCancelFilter();
  }

  apply() {
    if (this.onApplyFilter) {
      const selected = this.internalItems.filter(item => {
        return item.selected;
      });

      this.onApplyFilter({
        column: this.column,
        filters: selected,
        exclude: this.isExclude
      });
    } else {
    }
  }
}
