import { GraphQLClient } from 'graphql-request';
import { baseConfig } from '../../config/endpoints';
import { RequestDocument } from 'graphql-request/dist/types';
import { isSomething } from '../../utility';
import stringify from 'json-stable-stringify'
import { resolveRequestDocument } from '../../utility/graphqlHelpers';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { GraphQLError } from 'graphql-request/dist/types';
import { BroadcastManagerChannelService } from '$services/broadcastManagerChannelService';
import { getSession } from "../../config/sessionService"

// Caches
interface ICacheEntry {
    data: unknown | undefined;
    error: unknown | undefined;
    revalidatingPromise: Promise<void> | undefined;
    abortController: AbortController | undefined;
    fetchedDate: number | undefined;
    etag: string | undefined | null;
}

const DEFAULT_CACHE_ENTRY: ICacheEntry = {
    data: undefined,
    error: undefined,
    revalidatingPromise: undefined,
    abortController: undefined,
    fetchedDate: undefined,
    etag: undefined,
}

const CACHE = new Map<string, ICacheEntry>();
//const CACHE = new Map<string, Map<string | undefined, ICacheEntry>>();
(window as any).CACHERECORDS = CACHE;
(window as any).LogCacheRecords = function() {
    const hashCode = (s: string) => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)
    const table: any[] = [];
    CACHE.forEach((value, key) => {
        const dataSubscriptions = DATA_SUBSCRIPTIONS.get(key)
        table.push({ 
            id:hashCode(key), 
            ...value, 
            fetchedDate: value.fetchedDate ? new Date(value.fetchedDate) : undefined, 
            lengthAsString: value.data ? JSON.stringify(value.data).length : undefined,
            dataSubscriptions: dataSubscriptions?.length,
            intervalSubscriptions: dataSubscriptions?.filter(a => a && INTERVAL_SUBSCRIPTIONS.has(a)).length,
            eventSubscription: EVENT_SUBSCRIPTIONS.has(key) ? true : false
        })
    })
}

const DATA_SUBSCRIPTIONS = new Map<string, subscribeFn<unknown>[]>();
const EVENT_SUBSCRIPTIONS = new Map<string, { query: RequestDocument, variables?: unknown, revalidateOnEvents: boolean }>();
const INTERVAL_SUBSCRIPTIONS = new WeakMap<subscribeFn<unknown>, number>();

const broadcastManager = BroadcastManagerChannelService.getInstance();

export const getCacheKey = (query: unknown, variables: unknown) => `${JSON.stringify(query)}_${stringify(variables)}`;

// Caches end

const isOnline = () => navigator.onLine;
const isDocumentVisible = () => document.visibilityState === 'visible';

const headers: Record<string, string> = { 'X-CSRF': '1' }; 

export type QueryResult<T> = { promise: Promise<T>; abortController: AbortController };

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

type QueryNetworkError = unknown | 'Aborted';
type QueryHttpResult<T> = 'No_Change' | {
    data: T;
    etag: string | null;
}

export const isClientError = (errorCode: Number, errors: GraphQLError[] | undefined) => {
    try {
        if (!errors || errors.length == 0)
          return false;

        for (let i = 0 ; i < errors.length; i++) {
          const error = errors[i];
          if (error.message?.indexOf('ClientError: ' + errorCode.toString()) >= 0)
            return true;
        }
    } catch {
        //Do nothing
    }
    
    return false;
}
  
export const getErrorCode = (error: GraphQLError) =>{
    const searchTerm = 'ClientError: '
    const sliceIndex = error.message?.indexOf(searchTerm)
    return error.message?.slice(searchTerm.length + sliceIndex, searchTerm.length + sliceIndex + 3);
    };

type RawRequestResponse<T> = {
    data?: T | undefined;
    extensions?: any;
    headers: Headers;
    status: number;
    errors?: GraphQLError[] | undefined;
}

const DEFAULT_MAX_RETRIES = 5;
const queryWithRetry = async <T, TV>(client: GraphQLClient, abortController: AbortController, document: RequestDocument, variables: TV | undefined, _etagToSend: string | undefined | null, numberOfRetries: number | undefined): Promise<QueryHttpResult<T>> => {
    let attempt = 1;
    const { query, operationName } = resolveRequestDocument(document)

    while(true) {
        let response : RawRequestResponse<T> | undefined = undefined;

        try {
            response = await client.rawRequest<T, TV>(query, variables)

            //Send 401 back to client
            if (response.status === 401) {
              if (response.errors) 
                throw response.errors[0];
              else 
                throw { message: 'ClientError: 401' };
            }
          
            const etagHit = response.headers.get('x-nochange')

            if (etagHit)
                return 'No_Change'

            if (!response.data)
                throw 'No data given';
            
            const etag = response.headers.get('etag')
            return {
                data: response.data,
                etag
            }
        } catch (err) {           
            if (abortController.signal.aborted) {
                throw "Aborted"
            }
            
            if (attempt >= (numberOfRetries ?? DEFAULT_MAX_RETRIES) || isClientError(401, [err]) || isClientError(404, [err]) || isClientError(422, [err])) {                
                throw err;
            }
            
            attempt++;
            console.log(`Retrying graphql-query because of error in previous query for operation ${operationName}. This is attempt ${attempt}/${numberOfRetries ?? DEFAULT_MAX_RETRIES}`)
            await wait(100 * attempt);
        }
    }
}

const doQuery = <T, TV>(query: RequestDocument, variables: TV | undefined, etag: string | undefined | null, numberOfRetries?: number): QueryResult<QueryHttpResult<T>> => {    
    const abortController = new AbortController();
    const { signal } = abortController;
    const headersForRequest = etag ? { ...headers, 'If-None-Match': etag } : headers
    
    const newClient = new GraphQLClient(baseConfig.graphql, { headers: headersForRequest, signal });

    const promise = queryWithRetry<T, TV>(newClient, abortController, query, variables, etag, numberOfRetries);
    return { promise, abortController };
}

export interface IFetchResult<T> {
    data: T | undefined;
    error: unknown;
    isRevalidating: boolean;
}

export interface IFetchOptions {
    /*
        A boolean indicating if the query should be revalidated based on browser events.
        This is if the browser has been out of focus, then regained focus.
        If the browser was offline, then came online again.
    */
    revalidateOnEvents?: boolean;
    refreshInterval?: number;
    /*
        Indicate if the query should be run initially when subscribed to.
    */
    revalidateOnAttach?: boolean;
    /** Number of ms to cache the data before deleting */
    //cacheTime: number;
    /** Number of ms before the data becomes stale and can be revalidated by events, attached etc. */
    staleTime?: number;
}

const DEFAULT_FETCH_OPTIONS: IFetchOptions = {
    revalidateOnEvents: true,
    revalidateOnAttach: true,
    refreshInterval: undefined,
    //cacheTime: 5 * 60 * 1000,
    staleTime: undefined,
}

export type unsubscribeFn = () => void;
export type revalidateFn = () => void;
export type subscribeFn<T> = (result: IFetchResult<T>) => void;

const toFetchResult = <T>({ data, error, revalidatingPromise }: ICacheEntry): IFetchResult<T> => ({
    isRevalidating: !!revalidatingPromise,
    data: data as T,
    error
});

const updateCache = (cacheKey: string, updater: (existingEntry: ICacheEntry) => ICacheEntry ) => {
    const cacheEntry = CACHE.get(cacheKey) || DEFAULT_CACHE_ENTRY;
    const updatedEntry = updater(cacheEntry);    
    CACHE.set(cacheKey, updatedEntry);
    return updatedEntry;
}

const updateCacheAndNotifySubscriptions = (cacheKey: string, updater: (existingEntry: ICacheEntry) => ICacheEntry) => {
    const updatedEntry = updateCache(cacheKey, updater);

    const fetchResult = toFetchResult(updatedEntry);
    for(const subscription of DATA_SUBSCRIPTIONS.get(cacheKey) || [])
        subscription(fetchResult);
}

const setRevalidating = (cacheKey: string, promise: Promise<any>, abortController: AbortController) => 
    updateCacheAndNotifySubscriptions(cacheKey, entry => ({ ...entry, revalidatingPromise: promise, abortController }))

const setFetchIsAborted = (cacheKey: string) => updateCache(cacheKey, entry => ({ ...entry, abortController: undefined, revalidatingPromise: undefined }));

const setData = (cacheKey: string) => <T>(response: QueryHttpResult<T>) => {
    if(response == 'No_Change') {
        updateCacheAndNotifySubscriptions(cacheKey, entry => ({ ...entry, abortController: undefined, revalidatingPromise: undefined }))
    } else {
        updateCacheAndNotifySubscriptions(cacheKey, entry => ({ ...entry, data: response.data, etag: response.etag, error: undefined, revalidatingPromise: undefined, abortController: undefined, fetchedDate: new Date().getTime() }))
    }
}

const setError = (cacheKey: string) => (error: QueryNetworkError) => {
    if(error === 'Aborted') {
        setFetchIsAborted(cacheKey);
    } else {
        updateCacheAndNotifySubscriptions(cacheKey, entry => ({ ...entry, error, data: undefined, etag: undefined, revalidatingPromise: undefined, abortController: undefined }))
    }
}

const _revalidateSingleEntry = (cacheKey: string, query: RequestDocument, variables?: unknown): Promise<void> => {
    const cacheEntry = CACHE.get(cacheKey) || DEFAULT_CACHE_ENTRY;
    if(cacheEntry.revalidatingPromise) return cacheEntry.revalidatingPromise;

    //If not logged in, refresh page or redirect to login page 
    if (!isAuthenticated()) {
        logout();
        Promise.reject('Not authenticated');
    }

    const { promise, abortController } = doQuery(query, variables, cacheEntry.etag);
    const handledPromise = promise.then(setData(cacheKey)).catch(setError(cacheKey)); // TODO: Check if the catched promise should be returned here.

    setRevalidating(cacheKey, handledPromise, abortController);
    return handledPromise
}

//this needs to send message to other tabs to log out
const isAuthenticated = async () => { 
    const response = await fetch('bff/user', {
      headers: {
        "x-csrf": "1",
      },
    });

    if(response.ok)
      broadcastManager.setupListener('logout', logout)
    
    const session = getSession();
    if(response.status == 401 && session.userIsLoggedIn){
      logout();
    }
  
    return response.ok;
  }

  const logout = () => {
    const session = getSession();
    if(session?.logoutUrl){
      broadcastManager.sendMessage('logout');
      broadcastManager.close();
      window.location.href = session.logoutUrl;
    }
    else {
      login();
    }     
  }


const login = () => {
  const returnUrl = encodeURIComponent(window.location.pathname + window.location.search);
  window.location.href = `/bff/login?returnUrl=${returnUrl}`;
  window.location.reload();
}

const revalidate = (fromEvent: boolean) => {
  if(!isOnline() || !isDocumentVisible()) return Promise.resolve();
    const promises: Promise<unknown>[] = [];
    EVENT_SUBSCRIPTIONS.forEach(({ query, variables, revalidateOnEvents }, cacheKey) => {
        if(fromEvent && !revalidateOnEvents) return;
       
        promises.push(_revalidateSingleEntry(cacheKey, query, variables))
    })

    return Promise.all(promises);
}

const addSubscription = <T>(cacheKey: string, query: RequestDocument, variables: unknown, options: IFetchOptions, subscription: subscribeFn<T>) => {
    const existing = DATA_SUBSCRIPTIONS.get(cacheKey) || [];
    DATA_SUBSCRIPTIONS.set(cacheKey, [...existing, subscription]);

    EVENT_SUBSCRIPTIONS.set(cacheKey, { query, variables, revalidateOnEvents: !!options.revalidateOnEvents })

    if(options.refreshInterval) {
        const intervalId = window.setInterval(() => {
            _revalidateSingleEntry(cacheKey, query, variables);
        }, options.refreshInterval)
        INTERVAL_SUBSCRIPTIONS.set(subscription, intervalId)
    }
}

const removeSubscription = <T>(cacheKey: string, subscription: subscribeFn<T>) => {
    const existing = DATA_SUBSCRIPTIONS.get(cacheKey) || [];
    const newDatasubscriptions = existing.filter(cb => cb !== subscription);
    DATA_SUBSCRIPTIONS.set(cacheKey, newDatasubscriptions);
    EVENT_SUBSCRIPTIONS.delete(cacheKey);
    const interval = INTERVAL_SUBSCRIPTIONS.get(subscription);
    if(interval) window.clearInterval(interval)
    INTERVAL_SUBSCRIPTIONS.delete(subscription);

    if(newDatasubscriptions.length) return;
    // Abort all pending requests
    const inCache = CACHE.get(cacheKey);
    if(inCache && inCache.abortController) {
        if(!inCache.abortController.signal.aborted)
            inCache.abortController.abort();
        updateCacheAndNotifySubscriptions(cacheKey, entry => ({ ...entry, abortController: undefined, revalidatingPromise: undefined }))
    }
}

window.addEventListener('visibilitychange', () => { revalidate(true) }, false);
window.addEventListener('focus', () => { revalidate(true) }, false);
window.addEventListener('online', () => { revalidate(true) }, false);

export interface SubscribeQueryResult {
    unsubscribe: unsubscribeFn;
    revalidate: revalidateFn;
}

/**
 * 
 * @param query The graphql query definition to run
 * @param variables The graphql variables to run
 * @param fetchOptions The fetchoptions
 * @param cb A function that is notified when the query updates
 * @param numberOfRetries number of query retries before error is accepted
 */

export function subscribeQuery <T, TV>(query: TypedDocumentNode<T, TV> | RequestDocument, variables: TV | undefined, fetchOptions: IFetchOptions | undefined, cb: subscribeFn<T>, numberOfRetries?: number, onError?: (e?: string, code?: string) => void): SubscribeQueryResult {
    const options = { ...DEFAULT_FETCH_OPTIONS, ...fetchOptions };
    const cacheKey = getCacheKey(query, variables);
    addSubscription(cacheKey, query, variables, options, cb);
    const fromCache = CACHE.get(cacheKey);
    
    const dataIsInflight = fromCache && fromCache.revalidatingPromise;
    const dataIsFresh = fromCache && isSomething(options.staleTime) && isSomething(fromCache.fetchedDate) && fromCache.fetchedDate > new Date().getTime() - options.staleTime;

    if(!fromCache || (!dataIsInflight && options.revalidateOnAttach && !dataIsFresh)) {
        const { promise, abortController } = doQuery<T, TV>(query, variables, fromCache?.etag, numberOfRetries); // This should maybe be done in requestIdleCallback

        const handledPromise = promise.then(setData(cacheKey)).catch((e) => { 
            setError(cacheKey)(e);
            onError && onError(e, getErrorCode(e))
        })
        setRevalidating(cacheKey, handledPromise, abortController);
    } else {
        cb(toFetchResult(fromCache));
    }

    return {
        unsubscribe: () => removeSubscription(cacheKey, cb),
        revalidate: () => _revalidateSingleEntry(cacheKey, query, variables)
    }
}

export const preloadSingleQuery = <T, TV>(query: RequestDocument, variables: TV | undefined, fetchOptions: IFetchOptions | undefined): Partial<QueryResult<T>> => {
    const options = { ...DEFAULT_FETCH_OPTIONS, ...fetchOptions };
    const cacheKey = getCacheKey(query, variables);
    const fromCache = CACHE.get(cacheKey);
    const dataIsInflight = fromCache && fromCache.revalidatingPromise;
    const dataIsFresh = fromCache && isSomething(options.staleTime) && isSomething(fromCache.fetchedDate) && fromCache.fetchedDate > new Date().getTime() - options.staleTime;

    if(!fromCache || (!dataIsInflight && !dataIsFresh)) {
        const { promise, abortController } = doQuery<T, TV>(query, variables, fromCache?.etag); // This should maybe be done in requestIdleCallback
        const handledPromise = promise.then(setData(cacheKey)).catch(setError(cacheKey))
        setRevalidating(cacheKey, handledPromise, abortController);
        return { promise: promise.then(p => p === 'No_Change' ? fromCache?.data as T : p.data), abortController };
    }
    return { promise: Promise.resolve<T>(fromCache?.data as T), abortController: fromCache?.abortController };
}

export const getCacheValue = <T, TV>(query: RequestDocument, variables: TV | undefined): T | undefined => {
    const cacheKey = getCacheKey(query, variables);
    const fromCache = CACHE.get(cacheKey);

    return fromCache?.data as T;
}

export const getCachedFetchResult = <T, TV>(query: RequestDocument, variables: TV | undefined): IFetchResult<T> | undefined => {
    const cacheKey = getCacheKey(query, variables);
    const fromCache = CACHE.get(cacheKey);

    return fromCache ? toFetchResult(fromCache) : undefined;
}

export const deleteCachedFetchResult = <TV>(query: RequestDocument, variables: TV | undefined): boolean => {    
    const cacheKey = getCacheKey(query, variables);
    if (CACHE.has(cacheKey)) {
        CACHE.delete(cacheKey);
        return true;
    }
    return false;
}

export const runSingleQuery = <T, TV>(query: RequestDocument, variables: TV, numberOfRetries?: number): QueryResult<T> => {
    const cacheKey = getCacheKey(query, variables);
    const fromCache = CACHE.get(cacheKey);

    const { promise, abortController } = doQuery<T, TV>(query, variables, fromCache?.etag, numberOfRetries)
    return { promise: promise.then(p => p === 'No_Change' ? fromCache?.data as T : p.data), abortController };
};

export const revalidateAllActiveQueries = () => revalidate(false);
