import { customElement, bindable } from 'aurelia-framework';
import './tab-bar-item.css';

@customElement('tab-bar-item')
export class TabBarItem {
  @bindable onItemClick: () => void = () => {};
  @bindable active: boolean;
  @bindable iconName: string;
  @bindable title: string;
  @bindable dropdownIconOnDesktop: boolean;

  @bindable highlightCount: number;
}
