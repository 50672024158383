import { customElement, bindable, computedFrom } from 'aurelia-framework';
import './datetime.css';
import { formatDate } from '../../utility';

@customElement('date-time')
export class DateTime {
  @bindable() time: string | undefined;

  @computedFrom('time')
  get dateWithoutOffset() {
    if (!this.time) return;
    // Remove offset from string.
    const timeWithoutOffset = this.time.substring(0, this.time.indexOf('+'));
    return formatDate(timeWithoutOffset, true, '.');
  }
}
