import { customAttribute, autoinject, bindable } from 'aurelia-framework';

@customAttribute('mouse-over')
@autoinject
export class MouseOver {
  @bindable({ primaryProperty: true }) callback: Function | undefined;
  @bindable() delay: number | undefined;

  private onEnter: EventListenerOrEventListenerObject;
  private onLeave: EventListenerOrEventListenerObject;

  private onEnterScheduled: number | undefined;
  constructor(private element: Element) {
      this.onEnter = () => {
        if(!this.callback) return;
        if(this.delay && this.onEnterScheduled) return;
        if(!this.delay && this.callback) {
            this.callback();
            return;
        }
        this.onEnterScheduled = setTimeout(this.callback, this.delay);
      }

      this.onLeave = () => {
        if(this.onEnterScheduled)
          clearTimeout(this.onEnterScheduled);
        this.onEnterScheduled = undefined;
      }
  }

  attached() {
    this.element.addEventListener('mouseenter', this.onEnter);
    this.element.addEventListener('mouseleave', this.onLeave);
  }

  detached() {
    this.element.removeEventListener('mouseenter', this.onEnter);
    this.element.removeEventListener('mouseleave', this.onLeave);
  }
}
